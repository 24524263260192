import React from 'react';
import compose from 'lodash/fp/compose';
import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';
import {
  withTranslation,
  // withFieldError,
} from 'components/hoc';
import customStyle from './index.css';
import { inputStyle } from '../Input';

const getColor = ({ disabled }) => ({ color: disabled ? '#444' : '#000' });

const InputDropdown = styled.div`
  position: relative;
  width: 100% !important;
  border-radius: 3px;
  padding: 7px;
  height: 40px;
  padding-left: 10px;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  outline: none;
  overflow: hidden;
`;

const BaseColumnalSelectWrapper = styled.div`
  border: ${({ error }) =>
    error ? '1px solid red !important' : '1px solid #e0e5ec;'};
  ${inputStyle}
  position: relative;
  ${getColor};
  padding: 0;
  background: var(--admincat-color-grey-6);
  color: var(--admincat-color-grey-2);
`;

const StyledIcon = styled(Icon)`
  && {
    position: absolute;
    right: 8px;
    ${getColor}
  }
`;

// style={{
//   background: 'var(--admincat-color-grey-6)',
//   color: 'var(--admincat-color-grey-2)',
// }}

const DropdownListWrapper = styled.div`
  width: 100%;
  position: absolute;
  // background: #fff;
  // background: var(--admincat-color-grey-5);
  // background: var(--admincat-color-grey-6);
  // color: var(--admincat-color-grey-2);
  z-index: 20;
  border: 1px solid #ccc;
  // color: #777;
  border-radius: 4px;
  margin-top: 1px;
  height: auto;
  overflow: hidden;
  overflow-y: auto;
  box-shadow: 0px 1px 13px 0 #bbb;
  display: ${({ disabled }) => (disabled ? 'none' : 'block')};
  left: 0;
  min-width: 45px;
  max-height: 300px;
`;

const StyledSpan = styled.span`
  padding-right: 5px;
  display: inline-block;
  user-select: none;
`;

const DropdownList = styled.div`
  display: flex;
  padding: ${({ group }) => (group ? '0px' : '12px')};
  padding-left: 20px;
  padding-right: 15px;
  flex-wrap: wrap;
  cursor: pointer;
  border-bottom: 1px solid #eee;
  // background: var(--admincat-color-grey-6);
  color: var(--admincat-color-grey-2);
  transition: all 0.6s;
  background: ${({ selected }) =>
    selected ? 'var(--admincat-color-grey-6)' : 'var(--admincat-color-grey-1)'};

  &:hover {
    background: var(--admincat-color-grey-1);
  }
  &:last-child {
    border-bottom: 0;
  }
`;

const DropDownGroupHeader = styled.div`
  background: #739bcb;
  color: #fff;
  padding: 10px 0.5em;
  font-size: 18px;
`;

const Col = (props) => (
  <StyledSpan className={props.className} disabled={props.disabled}>
    {props.children}
  </StyledSpan>
);


const List = ({ tr, value, disabled, columns, options, ...props }) =>
  options.map((option, index) => (
    <>
      {option.header ? (
        <DropDownGroupHeader key={`${index}-1`} onClick={(e) => e.stopPropagation()}>
          {columns.map((column, colIndex) => (
            <span key={`${colIndex}-2`}>{tr(option[column]) || '-'}</span>
          ))}
        </DropDownGroupHeader>
      ) : (
        <DropdownList
          key={`${index}-3`}
          onClick={(e) => {
            !disabled &&
              props.onChange({
                target: {
                  name: props.name,
                  value: option.id,
                },
              });
          }}
          selected={value === option.id}
          className="DropdownList"
        >
          {columns.map((column, colIndex) => (
            <Col key={`${colIndex}-4`} disabled={option.disabled}>
              {column === '_street' 
                ? `${tr(option._street_name) || ''} ${tr(option._street_no) || ''} ${tr(option._street_no_suffix) || ''}` 
                : tr(option[column]) || '-'}
              {colIndex !== columns.length - 1 && ', '}
            </Col>
          ))}
        </DropdownList>
      )}
    </>
  ));

// const List = ({ tr, value, disabled, columns, options, ...props }) =>
//   options.map((option, index) => (
//     <>
//       {option.header ? (
//         <DropDownGroupHeader key={`${index}-1`} onClick={(e) => e.stopPropagation()}>
//           {columns.map((column, index) => (
//             <span key={`${index}-2`}>{tr(option[column]) || '-'}</span>
//           ))}
//         </DropDownGroupHeader>
//       ) : (
//         <DropdownList
//           key={`${index}-3`}
//           onClick={(e) => {
//             !disabled &&
//               props.onChange({
//                 target: {
//                   name: props.name,
//                   value: option.id,
//                 },
//               });
//           }}
//           selected={value === option.id}
//           className="DropdownList"
//         >
//           {columns.map((column, colIndex) => (
//             <Col key={`${index}-${colIndex}-4`} disabled={option.disabled}>
//               {tr(option[column]) || '-'}
//               {column === '_street_name' ? '' : ', '}
//             </Col>
//           ))}
//         </DropdownList>
//       )}
//     </>
//   ));

// const List = ({ tr, value, disabled, columns, options, ...props }) =>
//   options.map((option, index) => (
//     <>
//       {option.header ? (
//         <DropDownGroupHeader key={`${index}-1`} onClick={(e) => e.stopPropagation()}>
//           {columns.map((column, index) => (
//             <span key={`${index}-2`}>{tr(option[column]) || '-'}</span>
//           ))}
//         </DropDownGroupHeader>
//       ) : (
//         <DropdownList
//           key={`${index}-3`}
//           onClick={(e) => {
//             !disabled &&
//               props.onChange({
//                 target: {
//                   name: props.name,
//                   value: option.id,
//                 },
//               });
//           }}
//           selected={value === option.id}
//           className="DropdownList"
//         >
//           {columns.map((column, index) => (
//             <Col key={`${index}-4`} disabled={option.disabled}>
//               {tr(option[column]) || '-'}
//               {index !== columns.length - 1 && ', '}
//             </Col>
//           ))}
//         </DropdownList>
//       )}
//     </>
//   ));

List.defaultProps = {
  disabled: false,
};

class BaseColumnalSelectComponent extends React.Component {
  state = { showDropdown: false };

  customDropdown = null;

  componentDidMount() {
    this.setEventListener();
  }

  componentWillUnmount() {
    this.setEventListener('removeEventListener');
  }

  setEventListener(type) {
    document.body[type || 'addEventListener']('click', (e) => {
      if (this.customDropdown && !this.customDropdown.contains(e.target)) {
        this.setState({ showDropdown: false });
      }
    });
  }

  toggleDropdown = () => {
    const { disabled } = this.props;
    if (disabled) return;
    this.setState({ showDropdown: !this.state.showDropdown });
  };

  render() {
    /*
     * Example:
     *
     * labels => [
     *   {
     *      first_name: "First Name",
     *   },
     *   {
     *     last_name: "Last Name",
     *   }
     * ]
     *
     * options => [
     *   {
     *     id: 1,
     *     first_name: "you",
     *     last_name: "me",
     *   },
     *   {
     *     id: 1,
     *     first_name: "me",
     *     last_name: "you",
     *   },
     * ]
     *
     * // not a prop
     * columns => [ "first_name", "last_name" ]
     *
     */

    const { showDropdown } = this.state;
    const { props } = this;

    const { labels, value, tr, error } = props;

    const columns = labels.map(Object.keys).map((d) => d[0]);

    const valueObj = props.options.find((_data) => _data.id === value);
    const { disabled } = props;
    return (
      <BaseColumnalSelectWrapper
        disabled={disabled}
        ref={(e) => (this.customDropdown = e)}
        onClick={this.toggleDropdown}
        className="BaseColumnalSelectWrapper"
        error={error}
      >
        {!showDropdown ? (
          <InputDropdown>
            {columns.map((column, index) =>
              !valueObj ? (
                labels.map((label, index) => {
                  const text = label[column];
                  return (
                    !!text && (
                      <Col key={index}>
                        {tr(text)}
                        {index !== columns.length - 1 && ', '}
                      </Col>
                    )
                  );
                })
              ) : (
                // <Col key={index}>
                //   {tr(valueObj[column]) || '-'}
                //   {index !== columns.length - 1 && ', '}
                // </Col>
                <Col key={index}>
                {column === '_street' 
                  ? `${tr(valueObj._street_name) || ''} ${tr(valueObj._street_no) || ''} ${tr(valueObj._street_no_suffix) || ''}` 
                  : tr(valueObj[column]) || '-'}
                {index !== columns.length - 1 && ', '}
              </Col>
              ),
            )}
            <Col />
            <StyledIcon disabled={disabled} name="dropdown" />
          </InputDropdown>
        ) : (
          <DropdownListWrapper {...props}>
            <List {...props} columns={columns} />
          </DropdownListWrapper>
        )}
      </BaseColumnalSelectWrapper>
    );
  }
}

const BaseColumnalSelect = compose(
  withTranslation,
  // withFieldError,
)(BaseColumnalSelectComponent);
export { BaseColumnalSelect };
