import React from 'react';
import styled from 'styled-components';
import { passwordStrength } from '../../utils';
import { Switchery, RadioButtonArray } from '../FormItems';
import Personal from './Personal';
import Organisation from './Organisation';

export const StyledSwitchery = styled(Switchery)`
  span.RightLabel {
    color: #666;
    font-weight: 400;
    font-size: 13px;
  }
`;

const workspaceTypes = {
  OU: 'Organisation User',
  PU: 'Personal User',
  JOU: 'Join Organisation User',
  COU: 'Create Organisation User',
  JPU: 'Join Personal User',
  CPU: 'Create Personal User',
};

const StyledRadioButtonArray = styled(RadioButtonArray)`
  margin: 0 10px;
`;

class BaseWorkspaceComponent extends React.Component {
  constructor() {
    super();
    this.state = {
      isLoading: false,
      spaceType: workspaceTypes.PU,
      newOrganisationUserType: false,
      newPersonalUserType: false,
      showNewOrgForm: false,
      ...(this.isRegistrationPage ? this.registrationPageState : {}),
    };
  }

  isRegistrationPage = false;

  workspaceTypes = workspaceTypes;

  registrationPageState = {
    termsAgreed: false,
    passwordStrength: '',
    termChecked: false,
  };

  hideOrgForm = () => {
    this.setState((state) => ({
      ...state,
      showNewOrgForm: false,
    }));
  };

  onSubmit = (e, newOrganisationData) => {
    const {
      isLoading,
      create_org_workspace,
      join_org_workspace,
      create_personal_workspace,
      join_personal_workspace,
      newOrganisationUserType,
      newPersonalUserType,
      spaceType,
      password,
      showNewOrgForm,
    } = this.state;

    if (this.isRegistrationPage && passwordStrength(password).hasError) {
      return;
    }

    const isOrgUser = spaceType === workspaceTypes.OU;

    const submitType = {
      JOU: isOrgUser && !newOrganisationUserType,
      COU: isOrgUser && newOrganisationUserType,
      JPU: !isOrgUser && !newPersonalUserType,
      CPU: !isOrgUser && newPersonalUserType,
    };

    if (submitType.COU && !showNewOrgForm) {
      this.setState({
        showNewOrgForm: true,
      });
      return;
    }

    if (isLoading) return;

    let payload = {};

    Object.keys(this.state).forEach((key) => {
      let value = this.state[key];
      if (typeof value === 'string') {
        Object.assign(payload, { [key]: value.trim() });
      }
      if (typeof value === 'object') {
        Object.assign(payload, { [key]: value });
      }
    });

    // add the language to the payload
    payload['language'] = localStorage.getItem('i18nextLng');

    const { joinOrg, createOrg, joinPersonal, createPersonal } = this;

    if (submitType.JOU) {
      const space_name = join_org_workspace;
      payload.space_name = space_name;
      this.handleSubmit(joinOrg, space_name, payload);
    } else if (submitType.COU) {
      const space_name = create_org_workspace;
      payload.space_name = space_name;
      payload.org_data = newOrganisationData;

      const formData = new FormData();
      Object.keys(payload).forEach((key) => {
        if (key === 'org_data') {
          Object.keys(newOrganisationData).forEach((orgDataKey) => {
            formData.append(
              `org_data[${orgDataKey}]`,
              newOrganisationData[orgDataKey],
            );
          });
        } else {
          formData.append(key, payload[key]);
        }
      });

      this.handleSubmit(createOrg, formData);
    } else if (submitType.JPU) {
      const space_name = join_personal_workspace;
      payload.space_name = space_name;
      this.handleSubmit(joinPersonal, space_name, payload);
    } else if (submitType.CPU) {
      const space_name = create_personal_workspace;
      payload.space_name = space_name;
      this.handleSubmit(createPersonal, payload);
    }
  };

  joinOrg = () => {};

  createOrg = () => {};

  joinPersonal = () => {};

  createPersonal = () => {};

  handleSubmit = async (action, ...payload) => {
    const { history } = this.props;
    this.setState({ isLoading: true });
    try {
      await action(...payload);
      this.setState({ isLoading: false });
      this.isRegistrationPage && history.replace('/register/success');
    } catch (err) {
      this.setState({ isLoading: false });
    }
  };

  onChange = (e) => {
    if (e) {
      const { name, value } = e.target;
      this.setState({ [name]: value });
    }
    return (data) => this.setState(data);
  };

  PersonalComponent = Personal;

  OrganisationComponent = Organisation;

  asyncAction = (actionType) => {
    return (...payload) => this.props[actionType](...payload);
  };

  renderPersonalOrOrganisationForm = () => {
    const { state, onChange, PersonalComponent, OrganisationComponent } = this;
    return state.spaceType === workspaceTypes.PU ? (
      <PersonalComponent onChange={onChange} {...state} />
    ) : (
      <OrganisationComponent onChange={onChange} {...state} />
    );
  };

  renderRadioButtonArray = () => {
    const { state, onChange } = this;
    const { t, i18n } = this.props;
    return (
      <StyledRadioButtonArray
        name="spaceType"
        onChange={onChange}
        selectableItems={[
          { label: t('Personal User'), value: workspaceTypes.PU },
          { label: t('Organisation User'), value: workspaceTypes.OU },
        ]}
        value={state.spaceType}
      />
    );
  };

  render() {
    return null;
  }
}

const BaseWorkspace = BaseWorkspaceComponent;

export { BaseWorkspace, Personal, Organisation };
