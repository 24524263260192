import React from "react"
import { LinkDiv } from "./style";
import { Link } from "react-router-dom";



const MyLink = (props)=>{
    const {children, to, ...rest } = props
    return (
        <LinkDiv {...rest}>
            <Link to={to} >
                {children}
            </Link>
    </LinkDiv>
    )
}

export default MyLink