import check from "check-types"; 

export const isNumber = (value) => check.number(Number(value))

export const isEmptyValue = (value) => {
  return [null, undefined, "", "N/A", "null", "undefined", "-"].includes(value) ||
    check.emptyObject(value) ||
    check.emptyArray(value);
}

export const stringify = (data) => JSON.stringify(data);

