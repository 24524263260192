import React from 'react';
import compose from 'lodash/fp/compose';
import { useTranslation } from 'react-i18next';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { withUserPreferences, withTranslation, withAccount } from 'components/hoc';
import HereForYou from '../../HomePage/hereForYou/index';
import H3 from '../../HomePage/typography/h3';
import styled from 'styled-components';
import 'react-accessible-accordion/dist/fancy-example.css';

const AccordionWrapper = styled.div`
  max-width: 60%;
  margin: 80px auto;
`;

const Faq = (props) => {
  // const { t } = useTranslation();
  // const { userPreLang, tr, account: {user: { isAuth } }} = props;

  const { tr } = props;
  const { t } = useTranslation();
  const {
    account: {
      user: { isAuth },
    },
  } = props;

  const style = { 
    // fontWeight: 'bold',
    color: 'var(--admincat-color-grey-2)',
   };

  // const userLanguage = JSON.parse(localStorage.getItem("userPreferences"))
  // // This change the language to default langage after login
  // const changeLanguageToDefault = (value) => {
  //   if(userLanguage && userLanguage.language === "deu"){
  //       localStorage.setItem("i18nextLng", "deu")
  //   }else{
  //       localStorage.setItem("i18nextLng", "eng")
  //   }
  // };

  React.useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const colorModeStyle = {
    background: 'var(--admincat-color-grey-6)',
    color: 'var(--admincat-color-grey-2)',
};


const colorStyle = {
  background: '#eaedf1',
  // color: 'var(--admincat-color-grey-2)',
};

  return (
    <>
      {isAuth ? (
        <div style={{ backgroundColor: 'var(--admincat-color-grey-4)'}}>
          <AccordionWrapper>
            <H3 align="center" style={style}>
              FAQs
            </H3>
          </AccordionWrapper>
          <AccordionWrapper>
            <Accordion>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton style={colorModeStyle}>
                    1. {tr('What is AdminCat? And why should I use AdminCat?')}
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p style={style} >
                    {tr(
                      'AdminCat is a unique internet service solution for private households striving to completely digitalize and interconnect your paperwork and personal data. This way you can save administrative time and get a more powerful control over your entire life.',
                    )}
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton style={colorModeStyle}>
                    2. {tr('Which key features does AdminCat offer?')}
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p style={style}>
                    {tr(
                      'With the integrated Email-Service, attached documents can get saved automatically where they belong and with the right categorization. An OCR text extraction allows a quick and easy full-text search. AdminCat also allows managing contracts and financial data in over 50 currencies with daily exchange rate updates. Reminders make you aware of upcoming expiration of contracts and important documents. And as a cloud service you can reach AdminCat from anywhere and at anytime. But the best is still to come as AdminCat is seeking to be your all-in-one admin solution.',
                    )}
                  </p>
                </AccordionItemPanel>
              </AccordionItem>

              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton style={colorModeStyle}>
                    3. {tr('Which limitations at AdminCat do I need to know?')}
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p style={style}>
                    {tr(
                      'AdminCat currently offers a data storage of up to 1 GB per workspace. Uploads are possible for documents not greater than 10 MB. And excessively sending emails (>300 a day) are not allowed. For this please also see our Terms and Conditions.',
                    )}
                  </p>
                </AccordionItemPanel>
              </AccordionItem>

              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton style={colorModeStyle}>
                    4. {tr('How much does it cost to use AdminCat?')}
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p style={style}>
                    {tr(
                      'We offer a 30-days free of charge trial period to every user initially. After that all of the AdminCat services and features are offered for a monthly rate of 2 € or a yearly rate of only 19 €.',
                    )}
                  </p>
                </AccordionItemPanel>
              </AccordionItem>

              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton style={colorModeStyle}>
                    5. {tr('Is there a notice period for cancelling the contract?')}
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p style={style}>
                    {tr(
                      'No, the contract is valid until the end of the prepayment period. This means that your payment alone defines the end of the contract. All user accounts are kept anonymous and hence it is technically not foreseen to offer a premature contract cancellation and payback option.',
                    )}
                  </p>
                </AccordionItemPanel>
              </AccordionItem>

              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton style={colorModeStyle}>
                    6.{' '}
                    {tr(
                      'Does AdminCat automatically delete my data after the contract has ended?',
                    )}
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p style={style}>
                    {tr(
                      'No. All your workspaces that still have at least one valid and subscribed user will continue to exist. If you haven’t assigned a new admin for them, the system will choose a subscribed successor automatically. Workspaces, which are assigned to inactive users only, will also remain in the system - for another two months. During that time, the first user to renew his or her subscription will become the new admin of such a space.',
                    )}
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton style={colorModeStyle}>
                    7.{' '}
                    {tr(
                      'What happens with spaces that have no admin for more than two months?',
                    )}
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p style={style}>
                    {tr(
                      'Spaces abandoned for more than two months will get deleted automatically. An automatic and immediate deletion also takes place, if all assigned users have already deleted their accounts.',
                    )}
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton style={colorModeStyle}>
                    8. {tr('Are users also getting deleted automatically?')}
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p style={style}>
                    {tr(
                      'Inactive (unsubscribed) users, that have no active space assignment, will get deleted automatically after one month. This concerns users for instance, which either never completed the registration or space acceptance process or whose spaces got deleted because of the two months inactivity described above.',
                    )}
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton style={colorModeStyle}>
                    9. {tr('Is my data safe on AdminCat?')}
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p style={style}>
                    {tr(
                      'For security reasons we constantly keep our software packages up-to-date to avoid exploits. Additionally to the standard encryption of our server provider, we encrypt your data a second time, such that even our staff can’t read it. High password standards, a Two-Factor-Authentication (TOTP), and the automatic session expiration after inactivity, protects all user accounts. We use the secure HTTPS protocol for data transmissions through the internet of course and daily backups are stored for the unlikely event of a major data loss.',
                    )}
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton style={colorModeStyle}>
                    10.{' '}
                    {tr('Do you recommend additional security measures for users?')}
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p style={style}>
                    {tr(
                      'We recommend users to obey general security rules concerning password protection and special prudence against malicious phishing mails. Additionally you can export your emails from AdminCat to your local machine manually or establish a synchronization with your Email program as an own backup.',
                    )}
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton style={colorModeStyle}>
                    11. {tr('Are you subject to the GDPR standard in the EU?')}
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p style={style}>
                    {tr(
                      'Yes, AdminCat exclusively runs on servers located in Frankfurt (Germany) and is therefore subject to one of the highest privacy-protecting regulations in the world – the GDPR of the European Union. For this reason we have contracted an external data security officer, which is regularly checking, monitoring and improving our security processes.',
                    )}
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton style={colorModeStyle}>
                    12. {tr('Does AdminCat also offer access via App?')}
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p style={style}>
                    {tr(
                      'Not yet. At first we want you to enjoy the generous user experience that our website offers for large-screen devices. But we are planning to introduce a mobile app in the future as well.',
                    )}
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton style={colorModeStyle}>
                    13. {' '}
                    {tr('How should I get started with my new account and Space?')}
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p style={style}>
                    {tr(
                      'Add persons, person groups and favorite organizations. Once defined, these units can get assigned to your Space, your documents and data like contracts and financial transactions. Once logged in you can also check out our Quick-Tour videos for more recommendations. Or you visit our channel series on YouTube.',
                    )}
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton style={colorModeStyle}>
                    14. {' '}
                    {tr(
                      'Does every person as member of a Space need a user account?',
                    )}
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p style={style}>
                    {tr(
                      'No – like in a household, where only one or two people actively do the paperwork for all. In fact, a user may even be a third person taking care for the household’s administration.',
                    )}
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton style={colorModeStyle}>
                    15. {tr('What is the basis for the data on the Dashboard?')}
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p style={style}>
                    {tr(
                      'Each Space represents a person group, which may be the members of a household or another type of personal economic unit. The dashboard displays data corresponding to this person group. If the person group members change, the financial data on the dashboard changes too.',
                    )}
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton style={colorModeStyle}>
                    16. {' '}
                    {tr(
                      'How can I contact AdminCat in case of questions or feedback?',
                    )}
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p style={style}>
                    {tr(
                      'As an active user you can find the option "Contact us" in the user menu on the top right. Else you can also write to support@admincat.net directly. We appreciate your valuable comments and feedback, which will of course play an important role in our further product development. But please be aware, that we can only give general support and guidance, as your data is invisible for us on the database.',
                    )}
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton style={colorModeStyle}>
                    17. {tr('Could I join the AdminCat team?')}
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p style={style}>
                    {tr(
                      'Of course! We are honored by your interest. Just send us an email and describe your main experiences and biggest strengths and we will come back to you.',
                    )}
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          </AccordionWrapper>
          <HereForYou />
        </div>
      ) : (
        <div style={{ backgroundColor: '#fff'}}>
          <AccordionWrapper>
            <H3 align="center" color="#000">
              FAQs
            </H3>
          </AccordionWrapper>
          <AccordionWrapper>
            <Accordion>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton >
                    1 {t('What is AdminCat? And why should I use AdminCat?')}
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    {t(
                      'AdminCat is a unique internet service solution for private households striving to completely digitalize and interconnect your paperwork and personal data. This way you can save administrative time and get a more powerful control over your entire life.',
                    )}
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton >
                    2 {t('Which key features does AdminCat offer?')}
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    {t(
                      'With the integrated Email-Service, attached documents can get saved automatically where they belong and with the right categorization. An OCR text extraction allows a quick and easy full-text search. AdminCat also allows managing contracts and financial data in over 50 currencies with daily exchange rate updates. Reminders make you aware of upcoming expiration of contracts and important documents. And as a cloud service you can reach AdminCat from anywhere and at anytime. But the best is still to come as AdminCat is seeking to be your all-in-one admin solution.',
                    )}
                  </p>
                </AccordionItemPanel>
              </AccordionItem>

              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton >
                    3 {t('Which limitations at AdminCat do I need to know?')}
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    {t(
                      'AdminCat currently offers a data storage of up to 1 GB per workspace. Uploads are possible for documents not greater than 10 MB. And excessively sending emails (>300 a day) are not allowed. For this please also see our Terms and Conditions.',
                    )}
                  </p>
                </AccordionItemPanel>
              </AccordionItem>

              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton >
                    4 {t('How much does it cost to use AdminCat?')}
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    {t(
                      'We offer a 30-days free of charge trial period to every user initially. After that all of the AdminCat services and features are offered for a monthly rate of 2 € or a yearly rate of only 19 €.',
                    )}
                  </p>
                </AccordionItemPanel>
              </AccordionItem>

              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton >
                    5 {t('Is there a notice period for cancelling the contract?')}
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    {t(
                      'No, the contract is valid until the end of the prepayment period. This means that your payment alone defines the end of the contract. All user accounts are kept anonymous and hence it is technically not foreseen to offer a premature contract cancellation and payback option.',
                    )}
                  </p>
                </AccordionItemPanel>
              </AccordionItem>

              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton >
                    6{' '}
                    {t(
                      'Does AdminCat automatically delete my data after the contract has ended?',
                    )}
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    {t(
                      'No. All your workspaces that still have at least one valid and subscribed user will continue to exist. If you haven’t assigned a new admin for them, the system will choose a subscribed successor automatically. Workspaces, which are assigned to inactive users only, will also remain in the system - for another two months. During that time, the first user to renew his or her subscription will become the new admin of such a space.',
                    )}
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    7{' '}
                    {t(
                      'What happens with spaces that have no admin for more than two months?',
                    )}
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    {t(
                      'Spaces abandoned for more than two months will get deleted automatically. An automatic and immediate deletion also takes place, if all assigned users have already deleted their accounts.',
                    )}
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton >
                    8 {t('Are users also getting deleted automatically?')}
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    {t(
                      'Inactive (unsubscribed) users, that have no active space assignment, will get deleted automatically after one month. This concerns users for instance, which either never completed the registration or space acceptance process or whose spaces got deleted because of the two months inactivity described above.',
                    )}
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton >
                    9 {t('Is my data safe on AdminCat?')}
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    {t(
                      'For security reasons we constantly keep our software packages up-to-date to avoid exploits. Additionally to the standard encryption of our server provider, we encrypt your data a second time, such that even our staff can’t read it. High password standards, a Two-Factor-Authentication (TOTP), and the automatic session expiration after inactivity, protects all user accounts. We use the secure HTTPS protocol for data transmissions through the internet of course and daily backups are stored for the unlikely event of a major data loss.',
                    )}
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton >
                    10{' '}
                    {t('Do you recommend additional security measures for users?')}
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    {t(
                      'We recommend users to obey general security rules concerning password protection and special prudence against malicious phishing mails. Additionally you can export your emails from AdminCat to your local machine manually or establish a synchronization with your Email program as an own backup.',
                    )}
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    11. {t('Are you subject to the GDPR standard in the EU?')}
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    {t(
                      'Yes, AdminCat exclusively runs on servers located in Frankfurt (Germany) and is therefore subject to one of the highest privacy-protecting regulations in the world – the GDPR of the European Union. For this reason we have contracted an external data security officer, which is regularly checking, monitoring and improving our security processes.',
                    )}
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton >
                    12. {t('Does AdminCat also offer access via App?')}
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    {t(
                      'Not yet. At first we want you to enjoy the generous user experience that our website offers for large-screen devices. But we are planning to introduce a mobile app in the future as well.',
                    )}
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    13. {t('How should I get started with my new account and Space?')}
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    {t(
                      'Add persons, person groups and favorite organizations. Once defined, these units can get assigned to your Space, your documents and data like contracts and financial transactions. Once logged in you can also check out our Quick-Tour videos for more recommendations. Or you visit our channel series on YouTube.',
                    )}
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton >
                    14. {' '}
                    {t(
                      'Does every person as member of a Space need a user account?',
                    )}
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    {t(
                      'No – like in a household, where only one or two people actively do the paperwork for all. In fact, a user may even be a third person taking care for the household’s administration.',
                    )}
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton >
                    15. {t('What is the basis for the data on the Dashboard? ')}
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    {t(
                      'Each Space represents a person group, which may be the members of a household or another type of personal economic unit. The dashboard displays data corresponding to this person group. If the person group members change, the financial data on the dashboard changes too.',
                    )}
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton >
                    16. {' '}
                    {t(
                      'How can I contact AdminCat in case of questions or feedback?',
                    )}
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    {t(
                      'As an active user you can find the option “Contact us” in the user menu on the top right. Else you can also write to support@admincat.net directly. We appreciate your valuable comments and feedback, which will of course play an important role in our further product development. But please be aware, that we can only give general support and guidance, as your data is invisible for us on the database.',
                    )}
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton >
                    17. {t('Could I join the AdminCat team?')}
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    {t(
                      'Of course! We are honored by your interest. Just send us an email and describe your main experiences and biggest strengths and we will come back to you.',
                    )}
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          </AccordionWrapper>
          <HereForYou />
        </div>
      )}
    </>
  );
};

export default compose(withTranslation, withUserPreferences, withAccount)(Faq);
