import Inbox from "./MailInbox";
import Sent from "./Sent";
import Trash from "./Trash";
import AttachmentRule from "./AttachmentRule";
import AttachmentRuleForm from "./AttachmentRule/Form";
import ComposePage from './ComposePage';

const path = "/dashboard/mail";

export default [
  {
    name: "Inbox",
    path: `${path}/inbox`,
    icon: "envelope",
    component: Inbox,
  },
  {
    name: "Sent",
    path: `${path}/sent`,
    icon: "sticky note",
    component: Sent,
  },
  {
    name: "Trash",
    path: `${path}/trash`,
    icon: "trash",
    component: Trash,
  },
  {
    name: "Attachment Rule",
    path: `${path}/attachment-rules`,
    icon: "",
    component: AttachmentRule,
    exact: true,
  },
  {
    name: "Attachment Rule Form",
    path: [
      `${path}/attachment-rules/create`,
      `${path}/attachment-rules/:id`,
      `${path}/attachment-rules/:id/update`,
    ],
    icon: "",
    component: AttachmentRuleForm,
    exact: true,
    hide: true,
  },
  {
    name: "Compose",
    path: `${path}/compose`,
    icon: "",
    component: ComposePage,
    hide: true,
  },
  // {
  //   name: "Attachment Rule Update",
  //   path: `${path}/attachment-rules/:id/update`,
  //   icon: "",
  //   component: AttachmentRuleForm,
  //   hide: true,
  // },
  // {
  //   name: "Attachment Rule View",
  //   path: `${path}/attachment-rules/:id`,
  //   icon: "",
  //   component: AttachmentRuleForm,
  //   hide: true,
  // },
  
];

export const redirects = [
  {
    from: `${path}`,
    to: `${path}/inbox`,
  },
  {
    from: `${path}/*`,
    to: `${path}/inbox`,
  },
];
