import BankAccount from './BankAccount';
import BankAccountForm from './BankAccount/Form';
import CreditCard from './CreditCard';
import CreditCardForm from './CreditCard/Form';
import Income from './Income';
import IncomeForm from './Income/Form';
import Consumption from './Consumption';
import ConsumptionForm from './Consumption/Form';
import AssetShift from './AssetShift';
import Temp from './Temp';
import AssetAccount from './AssetAccount';
import LiabilityAccount from './LiabilityAccount';
import Contract from '../common/Contract';
import ContractForm from '../common/Contract/ContractForm';
import ContractItem from '../common/ContractItem';
import ContractItemForm from '../common/ContractItem/Form';

const path = '/dashboard/data/finance';

export default [
  {
    name: 'Bank Account',
    path: `${path}/bank-account`,
    icon: 'user',
    component: BankAccount,
    exact: true,
  },
  {
    name: 'Bank Account Form',
    path: [
      `${path}/bank-account/create`,
      `${path}/bank-account/:id`,
      `${path}/bank-account/:id/update`,
    ],
    component: BankAccountForm,
    hide: true,
    exact: true,
  },
  {
    name: 'Credit Card',
    path: `${path}/credit-card`,
    icon: 'user',
    component: CreditCard,
    exact: true,
  },
  {
    name: 'Credit Card Form',
    path: [
      `${path}/credit-card/create`,
      `${path}/credit-card/:id`,
      `${path}/credit-card/:id/update`,
    ],
    component: CreditCardForm,
    hide: true,
    exact: true,
  },

  {
    name: 'Asset Account',
    icon: 'user',
    path: `${path}/asset`,
    component: AssetAccount,
    exact: true,
  },
  {
    name: 'Asset Account Form',
    path: [
      `${path}/asset-account/:id`,
      // `${path}/credit-card/:id/update`,
    ],
    component: AssetAccount,
    hide: true,
    exact: true,
  },
  {
    name: 'Liability Account',
    icon: 'user',
    path: `${path}/liability`,
    component: LiabilityAccount,
    exact: true,
  },
  {
    name: 'Liability Account Form',
    path: [`${path}/liability-account/:id`],
    component: LiabilityAccount,
    hide: true,
    exact: true,
  },
  {
    name: 'Income',
    icon: 'user',
    path: `${path}/income`,
    component: Income,
    exact: true,
  },
  {
    name: 'Income Form',
    icon: 'user',
    path: [
      `${path}/income/create`,
      `${path}/income/:id`,
      `${path}/income/:id/update`,
      `${path}/income/:id/reverse`,
    ],
    component: IncomeForm,
    hide: true,
    exact: true,
  },
  {
    name: 'Consumption',
    icon: 'user',
    path: `${path}/consumption`,
    component: Consumption,
    exact: true,
  },
  {
    name: 'Consumption Form',
    icon: 'user',
    path: [
      `${path}/consumption/create`,
      `${path}/consumption/:id`,
      `${path}/consumption/:id/update`,
      `${path}/consumption/:id/reverse`,
    ],
    component: ConsumptionForm,
    hide: true,
    exact: true,
  },
  {
    name: 'Asset Shift',
    path: `${path}/transactions/receivable`,
    component: AssetShift,
    exact: true,
    hide: true,
  },
  {
    name: 'Debt & Asset',
    path: `${path}/transactions/real-estate`,
    component: Temp,
    exact: true,
    hide: true,
  },
  {
    name: 'Contract',
    path: `${path}/contract`,
    icon: 'user',
    component: Contract,
    exact: true,
  },
  {
    name: 'Contract Form',
    path: [
      `${path}/contract/create`,
      `${path}/contract/:id`,
      `${path}/contract/:id/update`,
    ],
    exact: true,
    icon: 'user',
    component: ContractForm,
    hide: true,
  },
  {
    name: 'Contract Item',
    path: `${path}/contract-item`,
    icon: 'user',
    component: ContractItem,
    exact: true,
  },
  {
    name: 'Contract Form Item',
    path: [
      `${path}/contract-item/create`,
      `${path}/contract-item/:id`,
      `${path}/contract-item/:id/update`,
    ],
    exact: true,
    icon: 'user',
    component: ContractItemForm,
    hide: true,
  },
];

export const redirects = [
  {
    from: `${path}`,
    to: `${path}/bank-account`,
  },
  {
    from: `${path}/*`,
    to: `${path}/bank-account`,
  },
];
