import check from "check-types"


export default (errors) => {
  try {
    errors = JSON.parse(JSON.stringify(errors))
    if (!check.object(errors)) {
      return []
    }
    if ( Object.keys(errors).length == 0 ) {
      return []
    }
    if (!errors.response || !errors.response.data) {
      return ["No network connectivity or Unknown error"];
    }

    // if the error is from a token expiring, don't show a message, 
    // just silently refresh that token 
    if (errors.response.status == 401) return [];

    const { errors: err, detail } = errors.response.data;

    if (typeof err === "string") return [err];
    if (detail) return [detail]
    return Object.entries(err)
      .map(([label, value]) => {
        return [label.replace("_", " ").toUpperCase() + ": " + value];
      });

  } catch (error) {
    return [];
  }
}
