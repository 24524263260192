import styled, {css} from "styled-components"
import BackgroundImage from "./password.png"


export const Container = styled.div`
    display: flex;
    height:100vh;
    background:#fff;
`

export const ImageDiv = styled.div`
    width:100%;
    background: url(${BackgroundImage});
    background-size:cover;
    background-repeat:no-repeat;
    height:100%;
`

export const Flex = styled.div`
    flex:1;

    ${({mobile})=> mobile && css`
    @media only screen and (max-width:850px){
        display:none;
    }
    `}
`