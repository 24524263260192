import React from "react";
import { connect } from "react-redux";
import {
  getCity,
} from "../../../actions";
import hoistNonReactStatics from 'hoist-non-react-statics'
import { isEmptyValue } from 'components/utils/checkValue'


export const withCity = (WrappedComponent) => {
  class WithCity extends React.Component {
    state = { fetchingCity: false }
    async getCity(query) {
      const { getCity } = this.props;
      this.setLoading(true);
      await getCity(query);
      this.setLoading(false);
    }
    setLoading(mode=false) {
      this.setState({ fetchingCity: mode })
    }
    getCityOptions = () => undefined;
    getCityLocaleOptions = () => {
      const { city } = this.props;
      return city.map(({ created_at, ...rest }) => ({ ...rest }));
    }
    getCityByStateId = (stateId) => {
      if (!stateId) return
      this.getCity({ state: stateId });
    }
    getCityByCountryId = (countryId) => {
      if (isEmptyValue(countryId)) return
      this.getCity({ country: countryId });
    }
    render() {
      return (
        <WrappedComponent 
          cityOptions={this.getCityOptions()} 
          cityLocaleOptions={this.getCityLocaleOptions()} 
          getCityByCountryId={this.getCityByCountryId}
          getCityByStateId={this.getCityByStateId}
          fetchingCity={this.state.fetchingCity}
          {...this.props} 
        />
      );
    }
  }
  const mapStateToProps = ({ city }) => ({ city })
  const WithCityHOC = connect(mapStateToProps, {
    getCity,
  })(WithCity);
  hoistNonReactStatics(WithCityHOC, WrappedComponent)
  return WithCityHOC
}
