import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';

const AssetsVsLiabilities = ({ data }) => {
  console.log({ data });

  return (
    <ResponsiveContainer width="100%" height="70%">
      <BarChart data={data}>
        <XAxis dataKey="name" fontSize={10} hide />

        <YAxis fontSize={12} width={30} hide />

        <Tooltip />

        <Bar dataKey="asset" fill="#FCAB10" barSize={30} radius={10} />

        <Bar dataKey="liabilities" fill="#317ED9" barSize={30} radius={10} />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default AssetsVsLiabilities;
