import React from 'react';
import compose from 'lodash/fp/compose';
import { get } from 'lodash';
import { connect } from 'react-redux';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { isEmptyValue } from 'components/utils/checkValue';
import * as userLayoutChoiceActions from 'actions/userLayoutChoice';
import { withFieldCatalog } from '../Preload/FieldCatalog';
import {
  tableLabels,
  gExtraColumns,
  gRemoveColumns,
  gModifyIds,
  setDefaultColumn,
} from './tableLayoutProps';

const gExcludeColumns = ['id', 'creator', 'created_at', 'updated_at'];

export const withUserLayoutChoice = (WrapperComponent) => {
  class UserLayoutChoice extends React.Component {
    componentDidMount() {
      const { getUserLayoutChoice } = this.props;
      const user = localStorage.user;
      if (UserLayoutChoice.loaded) return;
      if (user) {
        getUserLayoutChoice({ user: JSON.parse(user).user_id });
        UserLayoutChoice.loaded = true;
      }
    }
    static loaded = false;
    getUserLayoutChoiceOptions = () => {
      const { userLayoutChoice } = this.props;
      return userLayoutChoice;
    };

    // getUserLayoutChoiceByTable = (targetTable, pExcludeColumns, pExtraColumn) => {
    //   const tables = this.getUserLayoutChoiceOptions();
    //   if (!targetTable) return tables.data;
    //   const excludeColumns = [...pExcludeColumns, ...gExcludeColumns];
    //   const includeColumns = [...pExtraColumn];
    //   return tables.data
    //     .filter(({ frontend_table }) => frontend_table === targetTable)
    //     .filter(({ field }) => !excludeColumns.includes(field))
    //     .map((data) => ({
    //       ...data,
    //       label: this.getLabel(data.field),
    //       includeColumns: includeColumns.includes(data.field)
    //      }));
    // };

    getUserLayoutChoiceByTable = (targetTable, pExcludeColumns) => {
      const tables = this.getUserLayoutChoiceOptions();
      if (!targetTable) return tables.data;
      const excludeColumns = [...pExcludeColumns, ...gExcludeColumns];
      return tables.data
        .filter(({ frontend_table }) => frontend_table === targetTable)
        .filter(({ field }) => !excludeColumns.includes(field))
        .map((data) => ({ ...data, label: this.getLabel(data.field) }));
    };

    mapColumnsToLayout = (tableColumns, defaultColumns, pExcludeColumns) => {
      const [ids, labels] = defaultColumns.reduce(
        ([ids, labels], { id, label }) => {
          if (isEmptyValue(id) && isEmptyValue(label)) {
            return [ids, labels];
          }
          const newId = id.startsWith('_') ? id.substring(1) : id;
          const newIds = { ...ids, [newId]: id };
          const newLabels = { ...labels, [newId]: label };
          return [newIds, newLabels];
        },
        [{}, {}],
      );
      const excludeColumns = [...pExcludeColumns, ...gExcludeColumns];

      return tableColumns
        .map((column, index) => ({
          id: this.getId(column),
          selected: !!ids[column],
          order_no: index,
          label: ids[column] ? labels[column] : this.getLabel(column),
          field: column?.replace('_id', ''),
        }))
        .filter(({ field }) => !excludeColumns.includes(field));
    };

    getLayoutColumnProps = (...args) => {
      let [
        frontendTableName, // frontend representation of the table
        backendTableName, // backend representation of the table
        defaultColumns, // initial default columns before layout choice is choosen
        pIncludeColumns, // manually include columns labels to existing ones
        pExcludeColumns, // manually exclude columns labels from existing ones
        pModifyIds, // remapping column id to a different id
      ] = args;

      const includeColumns = [
        ...(pIncludeColumns || []),
        ...get(gExtraColumns, frontendTableName, []),
      ];

      const excludeColumns = [
        ...(pExcludeColumns || []),
        ...get(gRemoveColumns, frontendTableName, []),
      ];

      const modifyIds = {
        ...(pModifyIds || {}),
        ...get(gModifyIds, frontendTableName, {}),
      };

      const columnsToReorder = [...get(setDefaultColumn, frontendTableName, [])];

      const { getFieldCatalogByTable } = this.props;
      const tableColumns = getFieldCatalogByTable(backendTableName);
      const layoutChoice = this.getUserLayoutChoiceByTable(
        frontendTableName,
        excludeColumns,
        // includeColumns,
      );
      // merging table fields from field_catalogue with default layout provided.
      const mapppedColumns = this.mapColumnsToLayout(
        tableColumns,
        defaultColumns,
        excludeColumns,
      );

      // mapppedColumns.sort((a, b) => {
      //   return columnsToReorder.indexOf(a.field) - columnsToReorder.indexOf(b.field);
      // });

      // setting initial layout from either layout table or default provided columns(layout)
      const initLayoutColumns = isEmptyValue(layoutChoice)
        ? mapppedColumns
        : layoutChoice;

      // initLayoutColumns.sort((a, b) => {
      //   return columnsToReorder.indexOf(a.field) - columnsToReorder.indexOf(b.field);
      // });

      const fields = initLayoutColumns.map(({ field, label }) => field || label);
      const extraColumns = includeColumns.filter((field) => !fields.includes(field));

      const layoutColumns = [
        ...initLayoutColumns,
        ...extraColumns.map((column, index) => ({
          id: this.getId(column),
          selected: false,
          order_no: initLayoutColumns.length + index + 1,
          label: this.getLabel(column),
          field: column?.replace('_id', ''),
        })),
      ]
        .sort((a, b) => a.order_no - b.order_no)
        .sort((a, b) => b.selected - a.selected)
        .sort(
          (a, b) =>
            columnsToReorder.indexOf(a.field) - columnsToReorder.indexOf(b.field),
        )
        .map(({ field, ...rest }) => {
          const label = this.getLabel(field);
          const fieldId = this.getId(field);
          return {
            ...rest,
            field,
            label: get(tableLabels, `${frontendTableName}.${fieldId}`, label),
          };
        });

      // layoutColumns.sort((a, b) => {
      //   return columnsToReorder.indexOf(a.field) - columnsToReorder.indexOf(b.field);
      // });

      const selectedColumns = layoutColumns.filter(
        ({ selected }) => selected === true,
      );

      const columns = isEmptyValue(selectedColumns)
        ? mapppedColumns
        : selectedColumns;

      const dColumns = isEmptyValue(selectedColumns)
        ? layoutColumns
        : selectedColumns;

      return {
        columns: [
          ...this.updateColumns(
            // columns.slice(0, 5),
            // columns,
            // layoutColumns,
            dColumns,
            modifyIds,
          ),
          {},
        ],
        layoutColumns,
      };
    };
    getId = (column) => {
      return column.includes('_id') ? `_${column?.replace('_id', '')}` : column;
    };
    getLabel = (column) => {
      return column?.replace('_id', '').replaceAll('_', ' ');
    };
    updateColumns = (columns, modifyIds) => {
      return columns.map(({ id: _id, field, ...rest }) => {
        const fieldId = this.getId(field);
        const id = get(modifyIds, fieldId, fieldId);
        return {
          ...rest,
          id,
          field,
        };
      });
    };
    render() {
      return (
        <WrapperComponent
          {...this.props}
          getUserLayoutChoiceByTable={this.getUserLayoutChoiceByTable}
          getUserLayoutChoiceOptions={this.getUserLayoutChoiceOptions}
          mapColumnsToLayout={this.mapColumnsToLayout}
          getLayoutColumnProps={this.getLayoutColumnProps}
        />
      );
    }
  }
  const mapStateToProps = ({ userLayoutChoice }) => {
    return {
      userLayoutChoice,
    };
  };
  const WithUserLayoutChoice = connect(mapStateToProps, {
    ...userLayoutChoiceActions,
  })(UserLayoutChoice);
  hoistNonReactStatics(WithUserLayoutChoice, WrapperComponent);
  return compose(withFieldCatalog)(WithUserLayoutChoice);
};
