import styled from 'styled-components'


export const NavWrapper = styled.div`
    background: #fff;
    position:sticky;
    top:0;
    z-index:2;
    
    .nav-container{
        display: flex;
        padding: 15px;
        justify-content: space-between;
        box-shadow: 0 2px 3px rgba(0,0,0,.05);
        align-items: center;
        transition:all ease-in-out 300ms;
        position:relative;
        height:auto;
    }

    .nav-buttons{
        flex:1;
        display: flex;
        justify-content: flex-end;
        transition:all 300ms ease-in-out;
        transform-origin: top right;

        @media only screen and (max-width:750px){
            display:${props => props.display ? props.display : 'none'};
            flex-direction: column;
            position:absolute;
            top:20px;
            right:40px;
            background:#fff;
            padding:10px;
            box-shadow:1px 5px 30px rgba(0,0,0,.3);
            border-radius:5px;
        }
    }
    
    .nav-image-wrapper{
        flex:1
    }
    
    .nav-image{
        width:200px;
        @media only screen and (max-width:750px){
            width: 120px;
        }
    }
    
    .hambugger{
        width:40px;
        height:40px;
        border-radius:50%;
        display:flex;
        justify-content: center;
        align-items: center;
        background:#295586;
        color:#fff;
        cursor: pointer;
        z-index: 5;
        transition: all 300ms ease;
        padding:10px;
        @media only screen and (min-width:750px){
            display:none;
        }
    }
    .hambugger:hover{
        background:#1f4065;
    }
`