import { BaseActionClass } from "../common/BaseActionClass"
import { DISEASE_HISTORY } from '../types'

const path = "/dashboard/disease_history";
const name = "Disease History";

class DiseaseHistory extends BaseActionClass {
  
  constructor() { super(path, DISEASE_HISTORY, name) }

  addDiseaseHistory = this._add;

  updateDiseaseHistory = this._update;

  getDiseaseHistory = this._getAll;

  getSingleDiseaseHistory = this._getById;

  deleteDiseaseHistoryById = this._deleteById;

  deleteBulkDiseaseHistory = this._deleteBulk;
}

export const { 
  addDiseaseHistory, 
  updateDiseaseHistory, 
  getDiseaseHistory,
  getSingleDiseaseHistory,
  deleteDiseaseHistoryById,
  deleteBulkDiseaseHistory,
} = new DiseaseHistory()
