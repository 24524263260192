import React from 'react';
import '../LottieLoader/style.css';
import animatedLoading from 'assets/lottie/admit-cat-loader.json';
import Lottie from 'lottie-react';

const LottieLoader = () => {
    return (
        <div className="lottie">
          <Lottie loop autoplay animationData={animatedLoading} />
        </div>
    )
}

export default LottieLoader;