import React from 'react';
import styled from 'styled-components';
import { Icon } from 'semantic-ui-react';
import { ModalStyle } from '../../../common/FormItems/styled';

export const Modal = styled.div`
  ${ModalStyle}
  &&& {
    display: ${({ open }) => (open ? 'flex' : 'none')};
  }
  @media screen and (max-width: 1024px) {
    .form-container {
      width: 90% !important;
    }
  }
  @media screen and (min-width: 1440px) {
    .form-container {
      width: 70% !important;
    }
  }
`;

export const FormArea = styled.form.attrs((props) => ({
  className: 'card body',
}))`
  overflow: scroll;
  height: 100% !important;
  margin: 0 !important;
  width: 100% !important;
  padding: 2em;
  border: transparent !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
  .form-row {
    width: initial !important;
  }
`;

const StyledEditButton = styled.span`
  i {
    cursor: pointer;
  }
  margin-right: 1em;
`;

export const EditButton = ({ onClick, detailMode, formMode }) => (
  // eslint-disable-next-line react/jsx-filename-extension
  <StyledEditButton onClick={onClick} role="presentation">
    {formMode === 'update' ? (
      <Icon name={`${detailMode === 'edit' ? 'edit' : 'eye'}`} />
    ) : null}
  </StyledEditButton>
);
