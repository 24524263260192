import React from "react";
import compose from "lodash/fp/compose";
import { connect } from "react-redux";
import hoistNonReactStatics from "hoist-non-react-statics";
import { getProfession } from "actions/preload";
import { BaseHOC, withBaseHOCProps } from "../common/BaseHOC";

let loaded = false


export const withProfession = (WrappedComponent) => {
  class Profession extends BaseHOC {

    async componentDidMount() {
      const { getProfession } = this.props
      if (!loaded)  {
        loaded = true;
        await getProfession();
      }
    }

    get professionOptions() {
      const { profession } = this.props;
      return this._getOptions(profession, ['id'], true)
    }

    render() {
      return (
        <WrappedComponent 
          {...this.props} 
          professionOptions={this.professionOptions}
        />
      )
    }
  }
  const mapStateToProps = ({ profession }) => ({ profession })
  const WithProfession = connect(mapStateToProps, { getProfession })(Profession);
  hoistNonReactStatics(WithProfession, WrappedComponent)
  return compose(withBaseHOCProps)(WithProfession);
}
