import React from "react";
import compose from "lodash/fp/compose";
import { connect } from "react-redux";
import hoistNonReactStatics from 'hoist-non-react-statics'
import * as addressActions from "actions/dashboard/addressInfo"
import { BaseHOC, withBaseHOCProps } from "../common/BaseHOC"

// let loaded = false

const initialState = {
  loaded: false,
}

export const withAddressInfo = (WrappedComponent) => {
  class AddressInfo extends BaseHOC {

    state = { ...initialState }

    async componentDidMount() {
      const { getAddressInfo } = this.props
      if (!this.state.loaded)  {
        await getAddressInfo();
        this.setState({...initialState, loaded: true });
        // loaded = true;
      }
    }

    // get addressOptions() {
    //   const { addressInfo } = this.props;
    //   return this._getOptions(addressInfo.data, ['id', 'description'])
    //     .map(({ description, ...rest }) => ({ ...rest, name: description }))
    // }

    deleteAddressInfoById = async (data) => {
      const { deleteAddressInfoById } = this.props
      await this._deleteById(deleteAddressInfoById, data);
    }

    deleteBulkAddressInfo = async (data) => {
      const { deleteBulkAddressInfo } = this.props
      await this._deleteBulk(deleteBulkAddressInfo, data);
    }

    render() {
      return (
        <WrappedComponent 
          {...this.props}
          loaded={this.state.loaded} 
          deleteAddressInfoById={this.deleteAddressInfoById}
          deleteBulkAddressInfo={this.deleteBulkAddressInfo}
          // addressOptions={this.addressOptions}
        />
      )
    }
  }
  const mapStateToProps = ({ addressInfo }) => ({ addressInfo })
  const WithAddresslInfo = connect(mapStateToProps, addressActions)(AddressInfo);
  hoistNonReactStatics(WithAddresslInfo, WrappedComponent)
  return compose(withBaseHOCProps)(WithAddresslInfo);
}
