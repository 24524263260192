export const initialValues = {
  contract_no: '',
  item: '1',
  item_description: '',
  item_reference: '1',
  subject_id: '',
  fix_or_var: 'F',
  currency: '',
  fix_period_unit: '',
  fix_amount: '',
  var_service_unit: '',
  var_service_rate: '',
  counter_reading_b: '',
  counter_reading_e: '',
  entered_on: '1990-10-10',
  account_category: '',
  financial_category: '',
  contract_items: []
};

export const getItemReference = (reference, expenseItemReference) => {
  const selected = expenseItemReference.find(
    (item) => item.id === Number.parseInt(reference || 1, 10),
  );
  console.log("selected", selected)
  const referenceExists = ['Address', 'Bank Account Number', 'Person'].includes(
    selected?.long_description_eng,
  );
  return referenceExists ? selected?.long_description_eng : '';
};

export const handleInputChange = (
  { target: { name, value } },
  formValues,
  setFormValues,
) => {
  const namePath = name.split('.');
  const isFieldArray = namePath.length > 1;

  if (!isFieldArray) {
    setFormValues((values) => ({ ...values, [name]: value }));
    return;
  }

  if (isFieldArray) {
    const index = namePath[1];
    const filedName = namePath[2];
    const currentFieldArray = formValues[namePath[0]].map((field, fieldIndex) => {
      if (fieldIndex === Number.parseInt(index)) {
        return {
          ...{
            ...field,
            subject_id: filedName === 'item_reference' ? '' : field.subject_id,
            [filedName]: value,
          },
        };
      }
      return field;
    });
    setFormValues((values) => ({
      ...values,
      contract_items: [...currentFieldArray],
    }));
  }
};

export const  addItem = (formValues, isPersonBuyer, setFormValues) => {
  setFormValues({
    ...formValues,
    contract_items: [
      ...formValues.contract_items,
      { ...initialValues, account_category: isPersonBuyer ? 'expense' : 'expenses' },
    ],
  });
};

export const passFieldArrayError = (error, fieldArry) => {
  const rex = new RegExp(`${fieldArry?.replace?.('_', ' ')}.[0-9].`, 'g');
  return error?.replace?.(rex, '');
};
