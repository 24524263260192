import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import compose from 'lodash/fp/compose';
import {
  withDocument,
  withTranslation,
  withPersonalInfo,
  withCustomRouter,
} from '../../hoc';
import routes, { redirects } from './routes';
import { setMsgInfo } from 'actions/';
import Modal from 'components/common/Modal';
import filterError from 'actions/common/filterError';
import Button from 'components/common/FormItems/Button';
import { documentSlice } from 'rootStateHandler/selectors';
import {
  documentDetailsThunk,
  fullTextSearchThunk,
  listDocumentsThunk,
  documentClearDetailsThunk,
} from './documentStates/documentThunk';
import { deleteDocumentService } from './services/documentServices';
import DocumentForm from './Form';
import Dropzone from './Dropzone';
import DocumentInfo from './DocumentInfo';
import ListDocuments from './ListDocuments';
import FilterCriteria from './FilterCriteria';
import styled from 'styled-components';
import DashboardTemplate, {
  getHasSideBarStyle,
} from '../../common/DashboardTemplate';
import './index.css';
import customStyle from './index.css';

const MainWrapper = styled.div`
  ${customStyle}
  width: 100%;
  flex: 1;
  ${getHasSideBarStyle}
`;

const Container = styled.div`
  margin-left: 50px;
`;

const Documents = (props) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [documentId, setDocumentId] = useState();
  const [isSearching, setIsSearching] = useState(false);
  const [toggleModal, setToggleModal] = useState(false);
  const [toggleViewDocumentModal, setToggleViewDocumentModalModal] = useState(false);
  const [pageState, setPageState] = useState('documentPage');

  const { documentDetails } = useSelector(documentSlice);
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    toggleDocumentForm,
    tr,
    gotoCreatePath,
    documentForm,
    gotoUpdatePath,
    routePathname,
  } = props;

  useEffect(() => {
    if (routePathname == '/dashboard/documents') {
      toggleDocumentForm({
        showForm: false,
        data: {},
      });

      fetch_documents();

      if (Boolean(documentDetails?.id)) {
        dispatch(documentDetailsThunk(documentDetails?.id));
      }
    }
    window.scrollTo(0, 0);
  }, [routePathname]);

  useEffect(() => {
    fetch_documents();
  }, [dispatch]);

  const fetch_documents = () => {
    dispatch(listDocumentsThunk());
  };

  const handleDisplayInformation = (document_id) => {
    setDocumentId(document_id);

    dispatch(documentDetailsThunk(document_id));
  };

  const handleFullTextSearch = (event) => {
    event.preventDefault();
    setSearchQuery(event.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    history.push(`?search=${searchQuery}`);
    setIsSearching(true);
    dispatch(fullTextSearchThunk(searchQuery));
  };

  const handleReset = () => {
    setSearchQuery('');
    history.push(`/dashboard/documents`);
    setIsSearching(false);
  };

  const handleEdit = () => {
    if (documentDetails?.id) {
      gotoUpdatePath(documentDetails?.id);
      toggleDocumentForm({ data: { ...documentDetails }, showForm: true });
    }
  };

  const handleDelete = () => {
    setToggleModal((toggleModal) => !toggleModal);
  };

  const handleToggleViewDocumentModal = () => {
    setToggleViewDocumentModalModal(
      (toggleViewDocumentModal) => !toggleViewDocumentModal,
    );
  };
  const handleViewDocument = () => {
    handleToggleViewDocumentModal();
  };

  const handleConfirmDelete = async () => {
    try {
      const response = await deleteDocumentService(documentId);
      setToggleModal((toggleModal) => !toggleModal);
      dispatch(
        setMsgInfo({
          success: true,
          msg: ['Document deleted successfully'],
        }),
      );

      if (response.status === 204) {
        fetch_documents();
      }
    } catch (error) {
      const errors = filterError(error);

      dispatch(
        setMsgInfo({
          success: false,
          msg: [`Document could not be deleted: ${errors}`],
        }),
      );

      throw error;
    }
  };

  const handleToggleModal = () => {
    setToggleModal((toggleModal) => !toggleModal);
  };

  const showFormPage = () => {
    gotoCreatePath();
    dispatch(documentClearDetailsThunk());
    toggleDocumentForm({ showForm: true });
  };

  const showDocumentPage = () => {
    setPageState('documentPage');
    fetch_documents();
  };

  const documentPage = (
    <div className="main-container-documentPage container-margin_documentPage">
      <div className="p-row_documentPage">
        <div className="cls-3_documentPage rightArea_documentPage">
          <div className="filterAreaContent_documentPage">
            <FilterCriteria />
          </div>
        </div>

        <div className="cls-6_documentPage mainDocumentArea_documentPage">
          <div className="mainDocumentAreaContainer_documentPage">
            <div className="search_documentPage">
              <form
                action="/"
                method="get"
                onSubmit={handleSubmit}
                onReset={handleReset}
              >
                <label>
                  <span className="visually-hidden">{tr('Full text search')}</span>
                </label>

                <div className="searchInput_documentPage">
                  <input
                    type="text"
                    name="search"
                    value={searchQuery}
                    placeholder={tr('Full text search')}
                    onChange={handleFullTextSearch}
                  />

                  {searchQuery && (
                    <Button
                      type="reset"
                      className={'seachReset_button_documentPage'}
                      name={'X'}
                    >
                      X
                    </Button>
                  )}
                </div>

                <Button
                  type="submit"
                  name={tr('Search')}
                  className={'seachButton_documentPage'}
                />
              </form>
            </div>

            <div className="dropZone_documentPage">
              <Dropzone {...props} showFormPage={showFormPage} />
            </div>

            <div className="listDocuments_documentPage">
              <ListDocuments
                isSearching={isSearching}
                document_id={documentId}
                handleDisplayInformation={handleDisplayInformation}
                handleDelete={handleDelete}
                handleEdit={handleEdit}
                handleViewDocument={handleViewDocument}
              />
            </div>
          </div>
        </div>

        <div className="cls-3_documentPage documentInfoArea_documentPage">
          <div className="documentInfoAreaContainer_documentPage">
            <div className="documentInfoHeaderText_documentPage">
              {tr('File Details')}
            </div>

            <div className="documentInfoBody_documentPage">
              <DocumentInfo />
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const formPage = <DocumentForm {...props} showDocumentPage={showDocumentPage} />;

  const showScreen = {
    documentPage,
    formPage,
  };

  return (
    <DashboardTemplate
      {...props}
      routes={routes}
      redirects={redirects}
      render={() => (
        <MainWrapper hasSideBar>
          <Container>
            {/* {showScreen[pageState]} */}
            {documentForm.showForm ? formPage : documentPage}

            <Modal
              toggleModal={toggleModal}
              title={tr('Delete Document')}
              warning
              buttonTitle={tr('DELETE')}
              handleConfrimDelete={handleConfirmDelete}
              handleToggleModal={handleToggleModal}
              message={tr('Are you sure you want to delete this Document?')}
            />

            <Modal
              toggleModal={toggleViewDocumentModal}
              handleToggleModal={handleToggleViewDocumentModal}
              children={<div>Children</div>}
            />
          </Container>
        </MainWrapper>
      )}
    />
  );
};

export default compose(
  withDocument,
  withTranslation,
  withPersonalInfo,
  withCustomRouter,
)(Documents);
