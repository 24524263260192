import { useEffect } from 'react';
import { listCreditCard } from 'actions';
import { useDispatch, useSelector } from 'react-redux';

export const useListCreditCard = () => {
  const dispatch = useDispatch();
  const { list } = useSelector((state) => state.creditCard);

  useEffect(() => {
    dispatch(listCreditCard());
  }, [dispatch]);

  return list;
};
