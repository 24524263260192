import React, { useState } from 'react';
import styled from 'styled-components';
import SubMenu from './subMenu';
import ArrowIcon from './icons/arrow.svg';
import Hambugger from './icons/hambugger.svg';
import compose from 'lodash/fp/compose';
import { SidebarData, AdminSidebarData } from './SidebarData';
import { withTranslation, withAccount, withAdminRouteFiltering } from '../../hoc';
import { Link } from 'react-router-dom';
import { useDarkMode } from 'context/DarkModeContext';

const Wrapper = styled.div`
  position: fixed;
  height: 100vh;
  z-index: 500;
`;

const Container = styled.div`
  background: var(--admincat-color-grey-1);
  width: ${({ sidebar }) => (sidebar ? '230px' : '80px')};
  height: 89vh;
  position: fixed;
  transition: 300ms;
  box-shadow: ${({ sidebar }) =>
    sidebar ? '0 0 10px rgba(0, 0, 0, 0.03)' : 'none'};
  left: 0;
  bottom: 0;
`;

const UlDiv = styled.ul`
  list-stlye: none;
  position: relative;
  margin: 0;
  padding: 0;
  width: 100%;
`;

const ListDiv = styled.li`
  display: block;
  position: relative;
  padding: 10px;
  background: var(--admincat-color-grey-1);
  margin-bottom: 2px;
  transition: 300ms;
  margin-left: 20px;

  a {
    display: flex;
    align-items: center;
    color: var(--admincat-color-grey-3);
  }

  &:hover > ul {
    visibility: visible;
    opacity: 1;
  }

  &:hover {
    cursor: pointer;
    background: var(--admincat-color-grey-0);
  }

  .icon {
    margin-right: 10px;
  }
  .title {
    position: absolute;
    left: 40px;
    color: var(--admincat-color-grey-3);
  }
  .title.active {
    left: -200px;
  }
`;

const ArrowDiv = styled.div`
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
`;

const ToggleDiv = styled.div`
  cursor: pointer;
  position: fixed;
  top: 25px;
  left: 25px;
  z-index: 10000;
`;

const SideBar = (props) => {
  const [sidebar, setSidebar] = useState(false);
  const { tr, isUserAdmin, isLoggedinAsAdmin } = props;

  const toggleSidebar = () => {
    setSidebar(!sidebar);
  };

  const { isDarkMode } = useDarkMode();

  let filteredSidebarData;
  if (isLoggedinAsAdmin) {
    filteredSidebarData = props.filterAdminRoutes(AdminSidebarData);
  } else {
    filteredSidebarData = isUserAdmin
      ? SidebarData
      : SidebarData.filter((item) => !item.isAdminOnly);
  }

  return (
    <>
      <ToggleDiv onClick={toggleSidebar}>
        <img src={Hambugger} alt="Toggle Sidebar" />
      </ToggleDiv>
      <Wrapper>
        <Container sidebar={sidebar}>
          <UlDiv>
            {filteredSidebarData.map((item, index) => (
              <ListDiv key={index}>
                <Link to={item.path}>
                  <div className="icon">
                    <img
                      src={isDarkMode ? item.iconDark : item.icon}
                      alt={item.title}
                    />
                  </div>
                  <div className={sidebar ? 'title' : 'title active'}>
                    {tr(item.title)}
                  </div>
                </Link>
                {item.subNav && (
                  <SubMenu
                    background={sidebar}
                    setWidth={sidebar}
                    sidebar={sidebar}
                    right={sidebar}
                    items={item.subNav}
                    tr={tr}
                  />
                )}

                {item.subNav && (
                  <ArrowDiv>
                    <img src={ArrowIcon} alt="Expand" />
                  </ArrowDiv>
                )}
              </ListDiv>
            ))}
          </UlDiv>
        </Container>
      </Wrapper>
    </>
  );
};

export default compose(
  withTranslation,
  withAccount,
  withAdminRouteFiltering,
)(SideBar);
