import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  withAuthentication,
  withNoAuthentication,
  withNoAdminAuthentication,
  withAdminAuthentication,
} from './components/hoc';
import RouteRedirect from './components/common/RouteRedirect';
import ForgotPassword from './components/ForgotPassword';
import Login from './components/Login';
import WaitingList from './components/WaitingList';
import HomePage from './components/HomePage';
import Register from './components/Register';
import Dashboard from './components/Dashboard';
import ChangePassword from './components/ChangePassword';
import OTP from './components/OTP';
import VerifyEmail from './components/VerifyEmail';
import SignupSuccess from './components/SignupSuccess';
import Disclaimer from './components/Footer/Disclaimer';
import UserTerms from './components/Footer/UserTerms';
import PrivacyStatement from './components/Footer/PrivacyStatement';
import Faq from './components/Footer/Faq';
import PaymentPage from './components/Payment';
import AdminLogin from './components/Admin/Login';
import Admin from './components/Admin';

const userRoutes = [
  {
    name: 'Home',
    path: `/`,
    icon: '',
    component: withNoAuthentication(HomePage),
    exact: true,
  },
  {
    name: 'Login',
    path: `/login`,
    icon: '',
    component: withNoAuthentication(Login),
    exact: true,
  },
  {
    name: 'WaitingList',
    path: `/waiting-list`,
    icon: '',
    component: withNoAuthentication(WaitingList),
    exact: true,
  },
  {
    name: 'OTP',
    path: `/otp`,
    icon: '',
    component: withNoAuthentication(OTP),
    exact: true,
  },
  {
    name: 'Register',
    path: `/register`,
    icon: '',
    component: withNoAuthentication(Register),
    exact: true,
  },
  {
    name: 'Signup Success',
    path: `/register/success`,
    icon: '',
    component: withNoAuthentication(SignupSuccess),
  },
  {
    name: 'Verify Email',
    path: `/signup/verify`,
    icon: '',
    component: withNoAuthentication(VerifyEmail),
    exact: true,
  },
  {
    name: 'Forgot password',
    path: `/forgot-password`,
    icon: '',
    component: withNoAuthentication(ForgotPassword),
    exact: true,
  },
  {
    name: 'Change Password',
    path: `/password/reset`,
    icon: '',
    component: withNoAuthentication(ChangePassword),
    exact: true,
  },
  {
    name: 'Dashboard',
    path: `/dashboard`,
    icon: '',
    component: withAuthentication(Dashboard),
  },
];

const adminRoutes = [
  {
    name: 'Admin Home',
    path: `/admin/dashboard`,
    icon: '',
    component: withAdminAuthentication(Admin),
  },
];

const sharedRoutes = [
  {
    name: 'Admin Login',
    path: `/admin`,
    icon: '',
    component: withNoAdminAuthentication(AdminLogin),
    exact: true,
  },
  {
    name: 'Disclaimer',
    path: `/disclaimer`,
    component: Disclaimer,
  },
  {
    name: 'UserTerms',
    path: `/user-terms`,
    component: UserTerms,
  },
  {
    name: 'PrivacyStatement',
    path: `/privacy-statement`,
    component: PrivacyStatement,
  },
  {
    name: 'Faq',
    path: `/faq`,
    icon: '',
    component: Faq,
  },
  {
    name: 'Payment',
    path: `/payment`,
    icon: '',
    component: PaymentPage,
  },
];

const userRedirects = [
  {
    from: `/`,
    to: `/dashboard`,
  },
  {
    from: `/*`,
    to: `/dashboard`,
  },
];

const adminRedirects = [
  {
    from: `/`,
    to: `/admin/dashboard`,
  },
  {
    from: `/*`,
    to: `/admin/dashboard`,
  },
];

const StyledRouteRedirect = styled(RouteRedirect)``;

const Routes = ({ user, isAuthenticated, isResolvingUser }) => {
  const isAdmin = user && user.logged_in_as_admin;

  if (isResolvingUser) {
    return null; // TODO: it would be nice to add a universal loader here
  }

  if (isAuthenticated && isAdmin === null) {
    // Handle the case where admin status is still being determined
    return null; // or a loading spinner
  }

  if (isAuthenticated && isAdmin) {
    // Authenticated admin
    return (
      <StyledRouteRedirect
        routes={[...sharedRoutes, ...adminRoutes]}
        redirects={adminRedirects}
      />
    );
  } else if (isAuthenticated) {
    // Authenticated regular user
    return (
      <StyledRouteRedirect
        routes={[...userRoutes, ...sharedRoutes]}
        redirects={userRedirects}
      />
    );
  } else {
    // Unauthenticated user
    return (
      <StyledRouteRedirect
        routes={[...userRoutes, ...sharedRoutes]}
        redirects={userRedirects}
      />
    );
  }
};

export default Routes;
