import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import SplitPane from 'react-split-pane';
import DocumentTemplate from '../../../Documents/DocumentTemplate';
import Dropzone from '../../../Documents/Dropzone';
import View from '../../../Documents/View';
import Form from '../../../Documents/Form';
import { withTranslation } from '../../../../hoc';

const StyledSplitPane = styled(SplitPane)`
  > div:first-child {
    min-height: fit-content !important;
  }
`

const DocumentContainer = styled.div`
  margin-left: 200px;
  position: relative;
  width: calc(100% - 220px);
  .document-table {
    display: ${(props) => (props.closeDoc ? 'none' : 'block')};
    animation-timing-function: linear;
  }
`;

const DocumentDropDownButton = styled.button`
  outline: none;
  border: none;
  color: #739bcb;
  background: ${(props) => (props.closeDoc ? 'transparent' : '#dee2e6')};
  cursor: pointer;
`;

const DocumentWrapper = ({ children, formTitle, tr, ...rest }) => {
  const [closeDoc, setCloseDoc] = useState(false);
  return (
    <Fragment>
      <StyledSplitPane split={'horizontal'}>
        <div>
          {children}
        </div>
        {/* <DocumentContainer closeDoc={closeDoc}>
          <View {...rest} />
          <Form {...rest} tr={tr} title={tr(formTitle)} />
          <DocumentDropDownButton
            closeDoc={closeDoc}
            onClick={() => setCloseDoc(!closeDoc)}
          >
            {closeDoc ? '+' : '-'}
            {tr('Documents')}
          </DocumentDropDownButton>
          <div className="document-table">
            <Dropzone />
            <DocumentTemplate raisedTable card location={window.location} />
          </div>
        </DocumentContainer> */}
      </StyledSplitPane>
    </Fragment>
  );
};

DocumentWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  tr: PropTypes.func.isRequired,
  formTitle: PropTypes.string.isRequired,
};

export default withTranslation(DocumentWrapper);
