import { BaseActionClass } from "../common/BaseActionClass"
import { COMPANION_ANIMAL_INFO } from '../types'

const path = "/dashboard/companion_animal";
const name = "Companion Animal";

class CompanionAnimalInfo extends BaseActionClass {

  constructor() { super(path, COMPANION_ANIMAL_INFO, name) }

  addCompanionAnimalInfo = this._add;

  updateCompanionAnimalInfo = this._update;

  deleteCompanionAnimalInfoById = this._deleteById;

  getCompanionAnimalInfo = this._getAll;

  getCompanionAnimalInfoById = this._getById;

  deleteBulkCompanionAnimalInfo = this._deleteBulk;
}

export const { 
  addCompanionAnimalInfo, 
  updateCompanionAnimalInfo, 
  deleteCompanionAnimalInfoById,
  getCompanionAnimalInfo,
  getCompanionAnimalInfoById,
  deleteBulkCompanionAnimalInfo,
} = new CompanionAnimalInfo()



