import React from 'react';
import styled from 'styled-components';

const Label = styled.label`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  margin-bottom: ${(props) => props.marginBottom || '2%'};
  margin-top: ${(props) => props.marginTop || '2%'};
`;

const Switch = styled.div`
  position: relative;
  width: 50px;
  height: 29px;
  background: #edf1f6;
  border-radius: 32px;
  padding: 4px;
  transition: 300ms all;

  &:before {
    transition: 300ms all;
    content: '';
    position: absolute;
    width: 21px;
    height: 21px;
    border-radius: 35px;
    top: 50%;
    left: 4px;
    // background: white;
    background: var(--admincat-color-grey-1);
    transform: translate(0, -50%);
  }
`;

const Input = styled.input`
  opacity: 0;
  position: absolute;

  &:checked + ${Switch} {
    background: #4c81be;

    &:before {
      transform: translate(20px, -50%);
    }
  }
`;

const ToggleSwitch = ({
  label = '',
  handleChange,
  checked,
  labelStyle = {},
  id,
}) => {
  return (
    <>
      <Label>
        <Input
          checked={checked}
          type="checkbox"
          onChange={handleChange}
          id={`react-switch-${id}`}
        />
        <Switch />
        <span
          style={{ color: 'var(--admincat-color-grey-2)', ...labelStyle }}
          htmlFor={`react-switch-${id}`}
        >
          {label}
        </span>
      </Label>
    </>
  );
};

export default ToggleSwitch;
