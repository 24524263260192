import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import { withTranslation } from 'components/hoc';
import compose from 'lodash/fp/compose';

const TabButton = styled('button')`
  color: ${(props) => (props.active ? '#FFFFFF' : '#68696A')};
  border: none;
  border-radius: 6px;
  height: 28px;
  width: 120px;
  padding-left: 15px;
  padding-right: 15px;
  background: ${(props) => (props.active ? '#4C81BE' : 'none')};
`;

const TabButtonContainer = styled('div')`
  // background: #DBDEE3;
  background: var(--admincat-color-grey-16);
  border-radius: 6px;
  min-height: 40px;
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
`;

export const TabContent = ({ activeTab, index, children }) => {
  return (
    <div style={{ display: activeTab === index ? 'block' : 'none' }}>{children}</div>
  );
};

export const Tabs = withTranslation(
  ({ labels, components, setActiveTab, activeTab, tr }) => {
    return (
      <div>
        <TabButtonContainer>
          {labels.map((label, index) => {
            return (
              <TabButton
                active={index === activeTab}
                key={index}
                onClick={(e) => {
                  setActiveTab(index);
                }}
              >
                {tr(label)}
              </TabButton>
            );
          })}
        </TabButtonContainer>
      </div>
    );
  },
);
