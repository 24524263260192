import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSchoolType } from '../actions';

export const useSchoolType = (type) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSchoolType(type ? `type=${type}` : ''));
  }, [dispatch, type]);

  const { schoolType } = useSelector((state) => state);

  return {
    schoolOptionTypes: schoolType,
  };
};
