import { combineReducers } from '@reduxjs/toolkit';

import * as account from './account';
import * as preload from './preload';
import * as userSpace from './userSpace';
import * as fields from './dashboard/fields';
import * as profiles from './profiles';
import organisation from './organisation';
import dateFormat from './dateFormat';
import msgInfo from './msgInfo';
import userLanguage from './userLanguage';
import userDefaultSpace from './userDefaultSpace';
import forms from './dashboard/forms';
import personalInfo from './dashboard/personalInfo';
import workExperienceInfo from './dashboard/workExperienceInfo';
import membershipInfo from './dashboard/membershipInfo';
import contactInfo from './dashboard/contactInfo';
import relationInfo from './dashboard/relationInfo';
import addressInfo from './dashboard/addressInfo';
import residenceInfo from './dashboard/residenceInfo';
import JoinBuilder from './dashboard/joinBuilder';
import comments from './dashboard/comments';
import citizenshipInfo from './dashboard/citizenshipInfo';
import documents from './dashboard/documents';
import criteriaSet from './dashboard/criteriaSet';
import criteriaSetValue from './dashboard/criteriaSetValue';
import Loader from './loader';
import { spaceMembers } from './space-members';
import bankAccount from './dashboard/finance/bankAccount';
import userPreferences from './userPreferences';
import * as mail from './dashboard/mail';
import { contract } from './dashboard/contract';
import diseaseHistory from './dashboard/diseaseHistory';
import attachmentRule from './dashboard/attachmentRule';
import orgFunctionAssignment from './orgFunctionAssignment';
import education from './dashboard/education';
import { creditCard } from './dashboard/finance/creditCard';
import { transaction } from './dashboard/finance/transaction';
import { bankTransactionExtract } from './dashboard/finance/bankTransactionExtract';
import vaccinationHistory from './dashboard/vaccinationHistory';
import vaccineHistoryDisease from './dashboard/vaccineHistoryDisease';
import timeDependentName from './dashboard/timeDependentName';
import userLayoutChoice from './userLayoutChoice';
import companionAnimalInfo from './dashboard/companionAnimalInfo';
import personGroup from './dashboard/personGroup';
import personGroupMember from './dashboard/personGroupMember';
import space from './space';
import authentication from './authentication';
import currencyRate from './dashboard/currencyRate';
import { contractAlert } from './dashboard/contractAlert';
import fogotPasswordReducer from './fogotPasswordReducer';
import waitingList from './waitingList';
import documentReducer from 'components/Dashboard/Documents/documentStates/documentSlice';
import dashboardReducer from 'components/Dashboard/slices';
// import username from "./authentication"
import usernameReducer from './usernameReducer';
import payment from './payment';
import organisationProposal from './organisationProposal';
import orgFunctionProposal from './organisationFunctionProposal';

// admin reducers
import adminEntryProposal from './admin/adminEntryProposal';
import adminOrgProposal from './admin/adminOrgProposal';
import adminDashboard from './admin/adminDashboard';

export default combineReducers({
  personalInfo,
  ...preload,
  ...userSpace,
  ...account,
  ...fields,
  ...profiles,
  ...relationInfo,
  ...contactInfo,
  // contactInfo,
  addressInfo,
  residenceInfo,
  // ...citizenshipInfo,
  citizenshipInfo,
  companionAnimalInfo,
  forms,
  Loader,
  dateFormat,
  msgInfo,
  organisation,
  spaceMembers,
  userLanguage,
  userDefaultSpace,
  ...JoinBuilder,
  ...comments,
  ...documents,
  ...criteriaSet,
  ...criteriaSetValue,
  ...bankAccount,
  ...attachmentRule,
  userPreferences,
  ...mail,
  ...timeDependentName,
  ...userLayoutChoice,
  ...orgFunctionAssignment,
  ...orgFunctionProposal,
  contract,
  diseaseHistory,
  education,
  creditCard,
  transaction,
  bankTransactionExtract,
  ...vaccinationHistory,
  ...vaccineHistoryDisease,
  personGroup,
  personGroupMember,
  ...space,
  ...authentication,
  currencyRate,
  contractAlert,
  fogotPasswordReducer,
  waitingList,
  document: documentReducer,
  dashboard: dashboardReducer,
  usernameReducer,
  workExperienceInfo,
  membershipInfo,
  payment,
  organisationProposal,
  // organisationFunctionProposal,

  // admin reducers
  adminEntryProposal,
  adminOrgProposal,
  adminDashboard,
});
