import React from 'react';
import styled from 'styled-components';
import compose from 'lodash/fp/compose';
import ContentTemplate from 'components/common/ContentTemplate';
import { Button } from 'components/common/FormItems';
import {
  withBulkDelete,
  withUserLayoutChoice,
  withTranslation,
} from 'components/hoc';
import { DnDPortal } from 'components/common/FormItems/DnDPortal';
import withFileSize from 'components/hoc/FileSizeChecker/File';
import {
  TrashFill,
  Clipboard2,
  PlusLg,
  ArrowDownUp,
  ArrowBarLeft,
} from 'react-bootstrap-icons';
import swal from 'sweetalert';
import { Input } from 'components/common/FormItems';


const ButtonWrapper = styled.div`
  margin-top: 10px;
`;

const StyledButton = styled(Button)`
  background: var(--admincat-color-grey-6);
  border-radius: 2px;
  height: 38px;
  color: ${(props) => props.color || 'var(--admincat-color-grey-7)'};
  border: none;
  font-size: 14px;
  line-height: 10px;
  // remove this styles below,
  // if you want to see the full button when resized
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const StyledContentTemplate = styled(ContentTemplate)`
  div.table-responsive {
    height: auto;
    ${'' /* min-height: 500px; */}
    ${'' /* max-height: 100%; */}
    min-height: 70vh;
    max-height: 70vh;
    overflow-y: auto;
    background: var(--admincat-color-grey-1);

    ${'' /* overflow-y: scroll; */}
    width: auto;
    max-width: 93%;

    @media screen and (max-width: 768px) {
      height: auto;
      ${
        '' /* min-height: 500px;
        max-height: 100%; */
      }
      min-height: 70vh;
      max-height: 70vh;
      overflow-y: auto;
      width: auto;
      background: var(--admincat-color-grey-1);
      max-width: 85%;
    }
    @media screen and (min-width: 1200px) {
      height: auto;
      ${'' /* min-height: 500px; */}
      ${'' /* max-height: 100%; */}
      min-height: 70vh;
      max-height: 70vh;
      overflow-y: auto;
      background: var(--admincat-color-grey-1);
      width: auto;
      max-width: 93%;
    }
  }
`;

const Text = styled.span`
  font-size: 1.5em;
  color: grey;
`;

// Create a Wrapper component that'll render a <section> tag with some styles
const Wrapper = styled.section`
  padding: 0.3em 0.5em;
  display: flex;
  flex-direction: row;
  width: 90%;
  justify-content: space-between;
  align-items: center;
`;

const TransactionContentTemplate = (props) => {
  const {
    toggleForm,
    bulkDelete = () => {},
    rowIds,
    hasBulkDelete,
    viewData,
    getLayoutColumnProps,
    tableName,
    frontendTableName: fTableName,
    backendTableName: bTableName,
    columns: defaultColumns,
    useAddModalForm,
    handleCreateForm,
    isFile,
    msg,
    tr,
    revertView,
    viewName = false,
    gotoDetailPath,
    handleViewForm,
    options,
    goBack,
    setRowDetailToState = () => {},
    executeOnRowClick = () => {},
    handleGoBack = () => {},
    contract = {},
    marginTop,
    ...rest
  } = props;

  const frontendTableName = fTableName || tableName;
  const backendTableName = bTableName || tableName;

  const { columns, layoutColumns } = getLayoutColumnProps(
    frontendTableName,
    backendTableName,
    defaultColumns,
  );

  const deleteBulk = async () => {
    if (rowIds.length == 0) {
      swal(
        tr('Warning!'),
        tr(`You haven't selected any item to delete.`),
        'warning',
      );
      return;
    }
    // make api call to delete an email
    try {
      await bulkDelete(rowIds);
    } finally {
      return;
    }
  };

  const viewTimeDependentName = () => {
    if (rowIds.length == 0) {
      swal(tr('Warning!'), tr(`You haven't selected any item to view.`), 'warning');
      return;
    }
    // history.push('/dashboard/data/base/person/create');
    // navigate to the detail page of the first item
    // leverage the view function in the options prop to navigate to a new page
    let viewOption = options.filter(
      (option) => option.name.toLowerCase() == 'Update Name'.toLowerCase(),
    );
    viewOption = viewOption.length > 0 ? viewOption[0] : () => {};
    const idToView = rowIds[0];

    viewOption.onClick({ id: idToView });
    return;
  };

  const onAddClicked = handleCreateForm || toggleForm;

  return (
    <React.Fragment>
      {msg}
      <ButtonWrapper className="flex">
        {useAddModalForm && (
          <StyledButton
            className="option-btn"
            name="Add"
            reactIcon={<PlusLg color="#0E8436" size={12} className="mr-2" />}
            onClick={() => onAddClicked()}
            disabled={isFile}
          />
        )}
        {hasBulkDelete && (
          <StyledButton
            className="option-btn"
            onClick={deleteBulk}
            reactIcon={<TrashFill color="#F00" size={16} className="mr-3" />}
            name="Delete Marked"
          />
        )}
        <DnDPortal tableName={frontendTableName} columns={layoutColumns} />

        {viewName && (
          <StyledButton
            className="option-btn"
            onClick={viewTimeDependentName}
            reactIcon={<Clipboard2 color="#4C818E" size={16} className="mr-3" />}
            name="View Name"
          />
        )}

        {revertView && (
          <StyledButton
            className="option-btn"
            onClick={viewTimeDependentName}
            reactIcon={<ArrowDownUp color="#4C818E" size={16} className="mr-3" />}
            name="Revert"
          />
        )}
        {goBack && (
          <StyledButton
            className="option-btn"
            onClick={handleGoBack}
            reactIcon={<ArrowBarLeft color="#4C818E" size={16} className="mr-1" />}
            name="Go Back"
          />
        )}
      </ButtonWrapper>
      {contract?.id && (
        <form class="form-inline">
          <div class="form-group">
            <Input
              value={contract?.contractor_detail?.name}
              name="Contractor"
              label={tr('Contractor')}
              placeholder={tr('Contractor')}
              disabled
            />
          </div>
          <div class="form-group mx-sm-3">
            <Input
              value={contract?.id}
              name="contractId"
              label={tr('Contract Id')}
              placeholder={tr('Contract Id')}
              disabled
            />
          </div>
          <div class="form-group mx-sm-3">
            <Input
              value={contract?.description}
              name="description"
              label={tr('Description')}
              placeholder={tr('Description')}
              disabled
            />
          </div>
        </form>
      )}

      <StyledContentTemplate
        {...rest}
        columns={columns}
        // columns={layoutColumns}
        hasBulkDelete={hasBulkDelete}
        viewData={viewData}
        rowIds={rowIds}
        showDetailOnRowClick
        setRowDetailToState={setRowDetailToState}
        executeOnRowClick={executeOnRowClick}
      />
    </React.Fragment>
  );
};

TransactionContentTemplate.defaultProps = {
  useAddModalForm: true,
  handleCreateForm: null,
  frontendTableName: null,
  backendTableName: null,
  columns: [],
};

export default compose(
  withBulkDelete,
  withUserLayoutChoice,
  withFileSize,
  withTranslation,
)(TransactionContentTemplate);
