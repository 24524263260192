export const sections = {
  BASE: "base",
  HOUSING: "housing",
  HEALTH: "health",
  LEISURE: "leisure",
  FINANCE: "finance",
  EDUCATION: "education",
  WORK: "work",
  MOBILITY: "mobility",
  VARIOUS: "various",
};

export const getTitleFromPath = (pathname) => {
  return pathname.replace("housing", "housing");
}

export default (source, target) => {
  target = getTitleFromPath(target);
  return (new RegExp(source, "gi")).test(target);
};
