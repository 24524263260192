import React from 'react';
import compose from 'lodash/fp/compose';
import { withUserPreferences, withTranslation, withAccount } from 'components/hoc';
import Paragraph from "../typography/p"
import H3 from "../typography/h3"
import { useTranslation } from 'react-i18next';
import {Link} from "react-router-dom"


import {Div} from "./style"


const HereForYou = (props)=>{
    const { t } = useTranslation();
    const { userPreLang, tr, account: {user: { isAuth } }} = props;

    const userLanguage = JSON.parse(localStorage.getItem("userPreferences"))

  // This change the language to default langage after login
  const changeLanguageToDefault = (value) => {
    if(userLanguage && userLanguage.language === "deu"){
        localStorage.setItem("i18nextLng", "deu")
    }else{
        localStorage.setItem("i18nextLng", "eng")
    }
  };

  React.useEffect(() => {
    changeLanguageToDefault();
  }, [changeLanguageToDefault]);
    return (
        <>
        {isAuth?
        (
        <Div style={{  background: 'var(--admincat-color-grey-0)'}}>
            <div className="rec-one"></div>
            <div className="rec-two"></div>
            <div className="wrapper">
                <div className="content">
                    <Paragraph upperCase color="#fff" align="center">
                        {tr('We are here for you!')}
                    </Paragraph>
                    <H3 color="#fff" align="center">
                        {tr('Check out AdminCat today! It’s free.')}
                    </H3>
                    <Paragraph color="#fff" align="center">
                        {tr('Press the button below in order to start with your first own AdminCat workspace, You’re gonna love it!')}
                    </Paragraph>

                    <Link to="/register">
                        {tr('Get started')}
                    </Link>
                </div>
                
            </div>
        </Div>
    )
    :
       ( <Div>
            <div className="rec-one"></div>
            <div className="rec-two"></div>
            <div className="wrapper">
                <div className="content">
                    <Paragraph upperCase color="#fff" align="center">
                        {t('We are here for you!')}
                    </Paragraph>
                    <H3 color="#fff" align="center">
                        {t('Check out AdminCat today! It’s free.')}
                    </H3>
                    <Paragraph color="#fff" align="center">
                        {t('Press the button below in order to start with your first own AdminCat workspace, You’re gonna love it!')}
                    </Paragraph>

                    <Link to="/register">
                        {t('Get started')}
                    </Link>
                </div>
                
            </div>
        </Div>
        )
        }
        </>
    )
}
export default compose(withTranslation, withUserPreferences, withAccount)(HereForYou);