import React, { Component }  from 'react';
import styled from "styled-components";


const H3Div = styled.h3`
    font-weight: ${props => props.fontWeight ? props.fontWeight : 'bold'};
    font-size:2em;
    color: ${props => props.color ? props.color : "#666"};
    text-align: ${props => props.align ? props.align : 'left'};
    font-family: ${props => props.fontFamily ? props.fontFamily : 'poppins'};
    @media only screen and (max-width:750px){
        font-size:1.5em;
    }
`

const H3 = (props) => {
    const { children, color, align, ...rest } = props
    return <H3Div color={color} align={align} {...rest}>{children}</H3Div>
}

export default H3