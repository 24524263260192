import React from "react";
import { connect } from "react-redux";
import { getAccountCategory } from "actions";
import hoistNonReactStatics from 'hoist-non-react-statics'


let loaded = false;

export const withAccountCategory = (WrapperComponent) => {
  class WithAccountCategory extends React.Component {
    componentDidMount() {
      const { getAccountCategory } = this.props;
      if (loaded) return;
      loaded = true;
      getAccountCategory();
    }
    getAccountCategoryOptions = () => undefined

    getAccountCategoryLocaleOptions = () => {
      const { accountCategory } = this.props;
      return accountCategory.map(({ created_at, ...rest }) => ({ ...rest }));
    }
    
    render() {
      return (
        <WrapperComponent 
            accountCategoryOptions={this.getAccountCategoryOptions()} 
            accountCategoryLocaleOptions={this.getAccountCategoryLocaleOptions()} 
          {...this.props} 
        />
      )
    }
  }
  const mapStateToProps = ({ accountCategory }) => ({ accountCategory })
  const WithAccountCategoryHOC = connect(mapStateToProps, {
    getAccountCategory,
  })(WithAccountCategory);
  hoistNonReactStatics(WithAccountCategoryHOC, WrapperComponent)
  return WithAccountCategoryHOC
}