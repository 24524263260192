import React from "react";
import { connect } from "react-redux";
import {
  getTitle,
} from "actions";
import hoistNonReactStatics from 'hoist-non-react-statics'


let loaded = false;

export const withTitle = (WrapperComponent) => {
  class WithTitle extends React.Component {
    componentDidMount() {
      const { getTitle } = this.props;
      if (loaded) return;
      loaded = true;
      getTitle();
    }
    getTitleOptions = () => undefined
    getTitleLocaleOptions = () => {
      const { title } = this.props;
      return title.map(({ created_at, ...rest }) => ({ ...rest }));
    }
    render() {
      return (
        <WrapperComponent 
          titleOptions={this.getTitleOptions()} 
          titleLocaleOptions={this.getTitleLocaleOptions()} 
          {...this.props} 
        />
      )
    }
  }
  const mapStateToProps = ({ title }) => ({ title })
  const WithTitleHOC = connect(mapStateToProps, {
    getTitle,
  })(WithTitle);
  hoistNonReactStatics(WithTitleHOC, WrapperComponent)
  return WithTitleHOC
}
