import * as types from '../actions/types';
import { createTypes } from '../actions';
import baseStore from './utils/baseStore';

export const initialState = {
  payments: [],
  isLoading: false,
  error: null,
};

export default function payment(state = initialState, action = {}) {
  switch (action.type) {
    case types.GET_PAYMENTS:
      return {
        ...state,
        isLoading: false,
        payments: [...action.payload],
      };
    case types.GET_PAYMENTS_LOADING:
      return { ...state, isLoading: true };
    case types.GET_PAYMENTS_FAILURE:
      return { ...state, isLoading: false, error: action.payload };

    default:
      return state;
  }
}
