import Contract from '../common/Contract';
import ContractItem from '../common/ContractItem';
import ContractItemForm from '../common/ContractItem/Form';
import ContractForm from '../common/Contract/ContractForm';
import Relation from './Relation';
import Person from './Person';
import PersonForm from './Person/Form';
import TimeDependentNameForm from './Person/TimeDependentName/Form';
import TimeDependentName from './Person/TimeDependentName';
import PersonGroupForm from './PersonGroup/Form';
import CompanionAnimalForm from './CompanionAnimal/Form';
import PersonGroup from './PersonGroup';
import RelationForm from './Relation/Form';
import CompanionAnimal from './CompanionAnimal';
// import { SPACE_TYPES } from 'constants/index';
// import OrgSelection from './OrgSelection';

import OrganisationSelection from './OrganisationSelection';
import SearchOrgForm from './OrganisationSelection/SearchOrgForm';
import PersonalOrgForm from './OrganisationSelection/PersonalOrgForm';

import OrgFunctionAssignment from './OrganisationSelection/OrgFunctionAssignment';
import OrgFunctionAssignmentForm from './OrganisationSelection/OrgFunctionAssignment/OrgFunctionAssignmentForm';

const path = '/dashboard/data/base';
const timeDependentNamePath = 'person/:personId/time-dependent-name';

export default [
  {
    name: 'Person',
    path: `${path}/person`,
    icon: 'user',
    component: Person,
    exact: true,
  },
  {
    name: 'Person',
    path: [
      `${path}/person/create`,
      `${path}/person/:id`,
      `${path}/person/:id/update`,
    ],
    icon: 'user',
    component: PersonForm,
    hide: true,
    exact: true,
  },
  {
    name: 'Time Dependent Name',
    path: `${path}/${timeDependentNamePath}`,
    icon: 'user',
    component: TimeDependentName,
    hide: true,
    exact: true,
  },
  {
    name: 'Time Dependent Name',
    path: [
      `${path}/${timeDependentNamePath}/create`,
      `${path}/${timeDependentNamePath}/:id`,
      `${path}/${timeDependentNamePath}/:id/update`,
    ],
    icon: 'user',
    component: TimeDependentNameForm,
    hide: true,
    exact: true,
  },
  {
    name: 'Person Groups',
    path: `${path}/person-groups`,
    icon: 'users',
    component: PersonGroup,
    exact: true,
  },
  {
    name: 'Person Groups',
    path: [
      `${path}/person-groups/create`,
      `${path}/person-groups/:id`,
      `${path}/person-groups/:id/update`,
    ],
    icon: 'users',
    component: PersonGroupForm,
    hide: true,
    exact: true,
  },
  {
    name: 'Companion Animal',
    path: `${path}/companion-animal`,
    icon: 'users',
    component: CompanionAnimal,
    exact: true,
  },
  {
    name: 'Companion Animal Form',
    path: [
      `${path}/companion-animal/create`,
      `${path}/companion-animal/:id`,
      `${path}/companion-animal/:id/update`,
    ],
    icon: 'users',
    component: CompanionAnimalForm,
    hide: true,
    exact: true,
  },
  {
    name: 'Relation',
    path: `${path}/relation`,
    icon: 'users',
    component: Relation,
    exact: true,
  },
  {
    name: 'Relation Form',
    path: [
      `${path}/relation/create`,
      `${path}/relation/:id`,
      `${path}/relation/:id/update`,
    ],
    icon: 'users',
    component: RelationForm,
    hide: true,
    exact: true,
  },

  {
    name: 'Favourite Organisation',
    path: `${path}/favorite-organisation`,
    icon: 'industry',
    component: OrganisationSelection,
    // permission: [SPACE_TYPES.PERSON],
    exact: true,
  },

  {
    name: 'Search Organisation Form',
    path: [
      `${path}/favorite-organisation/search`,
      `${path}/favorite-organisation/:id`,
    ],
    component: SearchOrgForm,
    hide: true,
    exact: true,
    // routes: [
    //   {
    //   path: [
    //     `${path}/organisation/create`,
    //     `${path}/organisation/:id`,
    //     `${path}/organisation/:id/update`,
    //   ],
    //   component: PersonalOrgForm,
    //   exact: true,
    //   },
    // ],
  },
  {
    name: 'Create Organisation Form',
    path: [
      `${path}/organisation/create`,
      `${path}/organisation/:id`,
      // `${path}/organisation/:id/update`,
    ],
    component: PersonalOrgForm,
    hide: true,
    exact: true,
  },
  {
    name: 'Organisation Function',
    path: `${path}/org_func_assign`,
    component: OrgFunctionAssignment,
    hide: true,
    exact: true,
  },
  {
    name: 'Create Function Assignment Organisation Form',
    path: [
      `${path}/org_func_assign/create`,
      `${path}/org_func_assign/:id`,
      `${path}/org_func_assign/:id/update`,
    ],
    component: OrgFunctionAssignmentForm,
    hide: true,
    exact: true,
  },
  // {
  //   name: 'Org Func. Assign',
  //   path: `${path}/org-function-assignment`,
  //   icon: 'industry',
  //   component: OrgFuncAssignment,
  //   permission: [SPACE_TYPES.PERSON],
  // },

  {
    name: 'Contract',
    path: `${path}/contract`,
    icon: 'user',
    component: Contract,
    exact: true,
  },
  {
    name: 'Contract Form',
    path: [
      `${path}/contract/create`,
      `${path}/contract/:id`,
      `${path}/contract/:id/update`,
    ],
    exact: true,
    icon: 'user',
    component: ContractForm,
    hide: true,
  },
  {
    name: 'Contract Item',
    path: `${path}/contract-item`,
    icon: 'user',
    component: ContractItem,
    exact: true,
  },
  {
    name: 'Contract Form Item',
    path: [
      `${path}/contract-item/create`,
      `${path}/contract-item/:id`,
      `${path}/contract-item/:id/update`,
    ],
    exact: true,
    icon: 'user',
    component: ContractItemForm,
    hide: true,
  },
];

export const redirects = [
  {
    from: `${path}`,
    to: `${path}/person`,
  },
  {
    from: `${path}/*`,
    to: `${path}/person`,
  },
];
