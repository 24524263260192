import Documents from "./index";
import DocumentForm from "./Form";

const path = '/dashboard/documents';

export default [
  // {
  //   name: 'Main Area',
  //   path: `${path}/mainArea`,
  //   icon: 'home',
  //   component: Documents,
  // },
  {
    name: "Documents",
    path: `${path}`,
    icon: "users",
    component: Documents,
    // exact: true 
  },
  {
    name: "Documents Form",
    path: [
      `${path}/create`,
      `${path}/:id`,
      `${path}/:id/update`,
    ],
    icon: "users",
    component: DocumentForm,
    // hide: true,
    // exact: true,
  },

  // {
  //   name: 'Filter Criteria',
  //   path: `${path}/filter`,
  //   icon: 'search',
  //   // component: Base, //Once we have the right component, this will be replaced
  // },
  // {
  //   name: "Document Type",
  //   path: `${path}/documentType`,
  //   icon: "book",
  //   // component: Health,
  // },

  //   {
  //   name: "Issuing Org.",
  //   path: `${path}/issuingOrg`,
  //   icon: "university",
  //   // component: Education,
  // },
];

export const redirects = [
  {
    from: `${path}`,
    to: `${path}`,
  },
  {
    from: `${path}/*`,
    to: `${path}`,
  },
];
