const baseStore = (type) => (state=[], action={}) => {
  switch(action.type){ 
    case type:
      return action.payload
    default:
      return state
  }
}

export default baseStore;
