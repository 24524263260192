import React from "react";
import { connect } from "react-redux";
import {
  getAddressType,
} from "../../../actions";
import hoistNonReactStatics from 'hoist-non-react-statics'


let loaded = false;

export const withAddressType = (WrapperComponent) => {
  class AddressType extends React.Component {
    componentDidMount() {
      const { getAddressType } = this.props;
      if (loaded) return;
      loaded = true;
      getAddressType();
    }
    getAddressTypeOptions = () => undefined
    getAddressTypeLocaleOptions = () => {
      const { addressType } = this.props;
      return addressType.map(({ created_at, ...rest }) => ({ ...rest }));
    }
    render() {
      return (
        <WrapperComponent 
          {...this.props} 
          addressTypeOptions={this.getAddressTypeOptions()} 
          addressTypeLocaleOptions={this.getAddressTypeLocaleOptions()} 
        />
      )
    }
  }
  const mapStateToProps = ({ addressType }) => ({ addressType })
  const WithAddressType = connect(mapStateToProps, {
    getAddressType,
  })(AddressType);
  hoistNonReactStatics(WithAddressType, WrapperComponent)
  return WithAddressType
}
