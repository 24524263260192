import * as types from "../../actions/types";
import { baseViewForm } from '../utils/baseViewForm';

const relationInfoForm = baseViewForm(types.RELATION_INFO_FORM);
const relationInfoView = baseViewForm(types.RELATION_INFO_VIEW, 'view');

const relationInfo = (state=[], action={}) => { 

  const getNewState = (state, payload=action.payload) => {
    if (Array.isArray(payload)) {
      return [
        ...payload,
        ...state,
      ]
    } else {
      return [
        payload,
        ...state,
      ]
    }
  };

  const removeByRelation = (payload = action.payload) => {
    return state.filter((data, index) => {
      const relations = state.map((data) => {
        if (payload.includes(data.id)) return data.relation
        return null
      }).filter(relation => !!relation);
      return !relations.includes(data.relation)
    });
  }

  switch(action.type){ 

    case types.ADD_RELATION_INFO: {
      return getNewState(state)
    }

    case types.UPDATE_RELATION_INFO: {
      const { newData, datedData } = action.payload;
      const payload = datedData.map(data => data.id);
      const newState = removeByRelation(payload);
      return getNewState(newState, newData);
    }

    case types.DELETE_RELATION_INFO: {
      return state.filter((data, index) => {
        return data.relation !== action.payload.relation;
      });
    }

    case types.DELETE_BULK_RELATION_INFO: {
      return removeByRelation()
    }

    case types.GET_RELATION_INFO: {
      return state.find((data, index) => {
        return data.id === action.payload.id;
      });
    }

    case types.GET_ALL_RELATION_INFO: {
      return action.payload;
    }

    case types.EMPTY_RELATION_INFO: {
      return action.payload;
    }

    default:
      return state
  }
}

export default { relationInfo, relationInfoForm, relationInfoView }

