import React from 'react';
import { connect } from 'react-redux';
import { getState } from '../../../actions';
import hoistNonReactStatics from 'hoist-non-react-statics';
// import { isEmptyValue } from 'components/utils/checkValue'

let loaded = false;

export const withState = (WrapperComponent) => {
  class withState extends React.Component {
    state = { fetchingState: false };

    componentDidMount() {
      const { getState } = this.props;
      if (loaded) return;
      loaded = true;
      getState();
    }

    async getState(query) {
      const { getState } = this.props;
      this.setLoading(true);
      await getState(query);
      this.setLoading(false);
    }

    setLoading(mode = false) {
      this.setState({ fetchingState: mode });
    }

    getStateOptions = () => undefined;

    getStateLocaleOptions = () => {
      const { state } = this.props;
      return state.map(({ created_at, ...rest }) => ({ ...rest }));
    };

    getStateByCountryId = (countryId) => {
      if (!countryId) return;
      this.getState({ country: countryId });
    };
    render() {
      return (
        <WrapperComponent
          stateOptions={this.getStateOptions()}
          stateLocaleOptions={this.getStateLocaleOptions()}
          getStateByCountryId={this.getStateByCountryId}
          fetchingState={this.state.fetchingState}
          {...this.props}
        />
      );
    }
  }
  const mapStateToProps = ({ state }) => ({ state });
  const withStateHOC = connect(mapStateToProps, {
    getState,
  })(withState);
  hoistNonReactStatics(withStateHOC, WrapperComponent);
  return withStateHOC;
};
