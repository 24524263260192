import React from 'react';
import compose from 'lodash/fp/compose';
import { useTranslation } from 'react-i18next';
import { withUserPreferences, withTranslation, withAccount } from 'components/hoc';
import styled from 'styled-components';
import './index.css';

const DisclaimerWrapper = styled.div`
  margin-left: 5rem;
`;

const Disclaimer = (props) => {
  const { tr } = props;
  const { t } = useTranslation();
  const {
    account: {
      user: { isAuth },
    },
  } = props;

  React.useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const colorModeStyle = {
    color: 'var(--admincat-color-grey-2)',
  };

  // Function to determine margin-left based on translated text length
  const getMarginLeft = (text) => {
    const length = text.length;
    if (length == 5) return '48px';
    if (length == 6) return '49px';
    if (length == 8) return '38px';
    return '41px';
  };

  return (
    <DisclaimerWrapper>
      {isAuth ? (
        <div className="credits-disclaimer" style={{ backgroundColor: 'var(--admincat-color-grey-4)' }}>
          <div className="credits">
            <div>
              <h1 style={colorModeStyle}>{tr('Credits')}</h1>
              <div className="general-contact">
                <div className="address-container">
                  <div className="address" style={colorModeStyle}>
                    <h3 style={colorModeStyle}>{tr('Head office')}:</h3>
                    <p className="contact-address" style={colorModeStyle}>
                      AdminCat GmbH <br />
                      Landgraf-Karl-Str. 21A <br />
                      34131 Kassel <br />
                      <span>{tr('Germany')}</span>
                    </p>
                  </div>
                  <div className="address">
                    <h3 style={colorModeStyle}>{tr('Please mail to')}:</h3>
                    <p className="contact-address" style={colorModeStyle}>
                      AdminCat GmbH <br />
                      <span>{tr('P.O. Box')}</span> 41 03 38 <br />
                      34065 Kassel <br />
                      <span>{tr('Germany')}</span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="contact-info">
                <h4 style={colorModeStyle}>{tr('Contact')}:</h4>
                <p style={colorModeStyle}>
                  <span>{tr('Phone')}:</span>
                  <span
                    style={{ display: 'inline-block', marginLeft: getMarginLeft(tr('Phone')) }}
                  ></span>
                  +49 561 201 74392 ({tr('no support')})
                </p>
                <p style={colorModeStyle}>
                  <span>{tr('E-Mail')}:</span>
                  <span
                    style={{ display: 'inline-block', marginLeft: getMarginLeft(tr('E-Mail')) }}
                  ></span>
                  <a style={{ fontSize: '18px' }} href="mailto:admin@admincat.net">
                    admin@admincat.net
                  </a>
                </p>
              </div>
              <p style={colorModeStyle}>
                ----------------------------------------------------------------------------------------------
              </p>
              <p className="line" style={colorModeStyle}>
                <span>{tr('Support')}:</span>
                <span
                  style={{ display: 'inline-block', marginLeft: getMarginLeft(tr('Support:')) }}
                ></span>
                <a style={{ fontSize: '18px' }} href="mailto:support@admincat.net">
                  support@admincat.net
                </a>
              </p>
              <p style={colorModeStyle}>
                ----------------------------------------------------------------------------------------------
              </p>
              <p style={colorModeStyle}>
                <span>{tr('Authorized managing director')}</span>: Benjamin Kluge<br />
                <span>{tr('Register court')}</span>: <span>{tr('Local court ')} </span>Kassel <br />
                <span>{tr('Register number')}</span>: HRB 19706 <br />
                <span>{tr('VAT ID')}</span>: DE366542355 <br />
              </p>
            </div>
          </div>
          <div className="disclaimer">
            <h1 style={colorModeStyle}>{tr('Disclaimer')}</h1>
            <div>
              <h3 style={colorModeStyle}>{tr('Liability for Content')}</h3>
              <p style={colorModeStyle}>
                {tr(
                  'The content provided has been created with greatest care. However we cannot guarantee for the accuracy, completeness, reliability, usability and timeliness of the content. As a service provider according to § 7 (1) of the German Telemedia Act (TMG), we are responsible for own content on these sites under the general laws. However, corresponding to §§ 8 to 10 TMG, it is not our duty, to control or check information transmitted or saved from third parties, whether or not they are in line with the law.',
                )}
                <br />
                {tr(
                  'Obligations to remove or block the use of information under the general laws remain unaffected. However, liability is only possible from the date of knowledge of a specific infringement. Upon notification of such violations, we will remove the content immediately.',
                )}
              </p>
            </div>
            <div>
              <h3 style={colorModeStyle}>{tr('Copyrights')}</h3>
              <p style={colorModeStyle}>
                {tr(
                  'The contents and works on these pages compiled by us are subject to copyright law. Copying, processing, distribution and any kind of use outside the limits of copyright law require the written consent of us.',
                )}
                <br />
                {tr(
                  'In case the content is not created by us, the copyrights of third parties are being observed. In particular contents of third parties are marked as such. However, if a user becomes aware of a copyright infringement, we ask the user for notification. Upon notification of such violations, we will remove the content immediately.',
                )}
              </p>
            </div>
            <div>
              <h3 style={colorModeStyle}>{tr('Data Protection')}</h3>
              <p style={colorModeStyle}>
                {tr(
                  'The collection of person-related data on our website is based, as far as possible, on voluntariness. These data will not be transmitted to third parties without your explicit approval.',
                )}
                <br />
                {tr(
                  'Please be aware, that the data transmission over the internet (e.g. communication via email) may bear security risks. Therefore a complete protection of data from access of third parties is not possible.',
                )}
                <br />
                {tr(
                  'We hereby expressly object to the use of contact data published within the framework of the imprint obligation by third parties for sending unsolicited advertising and information material. The site operators reserve the right to take legal action in the event that unsolicited advertising information is sent, such as spam e-mails.',
                )}
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div className="credits-disclaimer" style={{ background: '#fff' }}>
          <div className="credits">
            <div>
              <h1>{t('Credits')}</h1>
              <div className="general-contact">
                <div className="address-container">
                  <div className="address">
                    <h3>{t('Head office')}:</h3>
                    <p className="contact-address">
                      AdminCat GmbH <br />
                      Landgraf-Karl-Str. 21A <br />
                      34131 Kassel <br />
                      <span>{t('Germany')}</span>
                    </p>
                  </div>
                  <div className="address">
                    <h3>{t('Please mail to')}:</h3>
                    <p className="contact-address">
                      AdminCat GmbH <br />
                      <span>{t('P.O. Box')}</span>41 03 38 <br />
                      34065 Kassel <br />
                      <span>{t('Germany')}</span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="contact-info">
                <h4>{t('Contact')}:</h4>
                <p>
                  <span>{t('Phone')}:</span>
                  <span
                    style={{ display: 'inline-block', marginLeft: getMarginLeft(t('Phone')) }}
                  ></span>
                  +49 561 201 74392 ({t('no support')})
                </p>
                <p>
                  <span>{t('E-Mail')}:</span>
                  <span
                    style={{ display: 'inline-block', marginLeft: getMarginLeft(t('E-Mail')) }}
                  ></span>
                  <a style={{ fontSize: '18px' }} href="mailto:admin@admincat.net">
                    admin@admincat.net
                  </a>
                </p>
              </div>
              <p>----------------------------------------------------------------------------------------------</p>
              <p className="line">
                <span>{t('Support')}:</span>
                <span
                  style={{ display: 'inline-block', marginLeft: getMarginLeft(t('Support:')) }}
                ></span>
                <a style={{ fontSize: '18px' }} href="mailto:support@admincat.net">
                  support@admincat.net
                </a>
              </p>
              <p>----------------------------------------------------------------------------------------------</p>
              <p>
                <span>{t('Authorized managing director')}</span>: Benjamin Kluge<br />
                <span>{t('Register court')}</span>: <span>{t('Local court ')}</span>Kassel <br />
                <span>{t('Register number')}</span>: HRB 19706 <br />
                <span>{t('VAT ID')}</span>: DE366542355 <br />
              </p>
            </div>
          </div>
          <div className="disclaimer">
            <h1>{t('Disclaimer')}</h1>
            <div>
              <h3>{t('Liability for Content')}</h3>
              <p>
                {t(
                  'The content provided has been created with greatest care. However we cannot guarantee for the accuracy, completeness, reliability, usability and timeliness of the content. As a service provider according to § 7 (1) of the German Telemedia Act (TMG), we are responsible for own content on these sites under the general laws. However, corresponding to §§ 8 to 10 TMG, it is not our duty, to control or check information transmitted or saved from third parties, whether or not they are in line with the law.',
                )}
                <br />
                {t(
                  'Obligations to remove or block the use of information under the general laws remain unaffected. However, liability is only possible from the date of knowledge of a specific infringement. Upon notification of such violations, we will remove the content immediately.',
                )}
              </p>
            </div>
            <div>
              <h3>{t('Copyrights')}</h3>
              <p>
                {t(
                  'The contents and works on these pages compiled by us are subject to copyright law. Copying, processing, distribution and any kind of use outside the limits of copyright law require the written consent of us.',
                )}
                <br />
                {t(
                  'In case the content is not created by us, the copyrights of third parties are being observed. In particular contents of third parties are marked as such. However, if a user becomes aware of a copyright infringement, we ask the user for notification. Upon notification of such violations, we will remove the content immediately.',
                )}
              </p>
            </div>
            <div>
              <h3>{t('Data Protection')}</h3>
              <p>
                {t(
                  'The collection of person-related data on our website is based, as far as possible, on voluntariness. These data will not be transmitted to third parties without your explicit approval.',
                )}
                <br />
                {t(
                  'Please be aware, that the data transmission over the internet (e.g. communication via email) may bear security risks. Therefore a complete protection of data from access of third parties is not possible.',
                )}
                <br />
                {t(
                  'We hereby expressly object to the use of contact data published within the framework of the imprint obligation by third parties for sending unsolicited advertising and information material. The site operators reserve the right to take legal action in the event that unsolicited advertising information is sent, such as spam e-mails.',
                )}
              </p>
            </div>
          </div>
        </div>
      )}
    </DisclaimerWrapper>
  );
};

export default compose(
  withTranslation,
  withUserPreferences,
  withAccount,
)(Disclaimer);
