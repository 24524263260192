import * as types from "../../actions/types";


const initialState = {
  data: {},
  showPopup: false,
  isLoading: false,
  error: null,
};

const currencyRate = (state=initialState, action={}) => { 
  switch(action.type){ 
    case types.GET_CURRENCY_RATE: 
      return { ...state, data: action.payload};
    default:
      return state
  }
}


export default currencyRate;
