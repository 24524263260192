import React, { useState, useEffect } from 'react';
import compose from 'lodash/fp/compose';
import { withTranslation, withAccount, withUserPreferences } from '../hoc';
import { Input, Button, Form } from '../common/AuthFormItems';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { OtpBody, OtpDivChild, OtpDivImage } from './style';

const SpacedButton = styled(Button)`
  margin-top: 2%;
  margin-bottom: 2%;
`;

const OTPActionButtons = (props) => {
  const { tr, isLoading, countdown } = props;
  const { t, i18n } = useTranslation();

  return (
    <React.Fragment>
      <Button type="submit" name={t('Submit')} isLoading={isLoading} />
    </React.Fragment>
  );
};

const OTP = (props) => {
  const { tr, history, account } = props;
  const { isLoading, user } = account;
  const [otp, setOtp] = useState(''); // Initialize the OTP input field value
  const [countdown, setCountdown] = useState(60); // Initialize the countdown timer to 60 seconds
  const [isResendDisabled, setIsResendDisabled] = useState(true); // Disable the resend button initially

  useEffect(() => {
    const user_id = localStorage.getItem('user_id');

    // if the user id is not set, return back to the login page
    if (!user_id) {
      history.push('/login');
    }
  });

  useEffect(() => {
    // Start the countdown when the component mounts
    const countdownInterval = setInterval(() => {
      if (countdown === 0) {
        clearInterval(countdownInterval);
        setIsResendDisabled(false);
      } else {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }
    }, 1000); // Update countdown every second

    return () => {
      // Clean up the interval when the component unmounts
      clearInterval(countdownInterval);
    };
  }, [countdown]);

  const handleResendClick = async () => {
    // Handle the resend button click, e.g., trigger email sending logic
    // You can add your email sending logic here
    const { resendOTP } = props;
    const email = localStorage.getItem('user_email');
    const user_id = localStorage.getItem('user_id');
    await resendOTP({ email, user_id });

    // Disable the resend button and reset the countdown
    setIsResendDisabled(true);
    setCountdown(60);
  };

  const onSubmit = (e, redirectPath) => {
    e.preventDefault();
    const { validateOTP } = props;
    if (isLoading) return;
    const email = localStorage.getItem('otp_email');
    const user_id = localStorage.getItem('user_id');
    const browser_id = localStorage.getItem('browserId');
    validateOTP({ otp, email, user_id, browser_id }, redirectPath);
  };

  const onChange = (e) => {
    setOtp(e.target.value);
  };

  const { t } = useTranslation();

  return (
    <>
      <OtpBody>
        <OtpDivImage></OtpDivImage>

        <OtpDivChild>
          <Form
            title={t('Please enter your one-time code (TOTP) here:')}
            onSubmit={(e) => {
              onSubmit(e);
            }}
            renderActionButtons={(name) => (
              <OTPActionButtons
                isLoading={isLoading}
                tr={tr}
                countdown={countdown}
              />
            )}
            render={() => (
              <React.Fragment>
                <Input
                  icon="envelope"
                  onChange={onChange}
                  value={otp}
                  name="otp"
                  label="Otp"
                  placeholder={t('TOTP')}
                  required
                />
                <div>
                  {countdown <= 0 ? (
                    <>
                      <SpacedButton
                        type="button"
                        name={t('Resend TOTP')}
                        isLoading={isLoading}
                        onClick={handleResendClick}
                        disabled={isResendDisabled}
                      />
                    </>
                  ) : (
                    <>
                      <p>You can resend TOTP in {countdown} seconds</p>
                    </>
                  )}
                </div>
              </React.Fragment>
            )}
          />
        </OtpDivChild>
      </OtpBody>
    </>
  );
};

export default compose(withAccount, withTranslation, withUserPreferences)(OTP);
