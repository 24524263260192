/* eslint-disable */
import React, { useEffect } from 'react';
import compose from 'lodash/fp/compose';
import { useSelector, useDispatch } from 'react-redux';
import { getFavoriteOrg, deleteBulkFavoriteOrg } from 'actions/organisation';
import {
  withTranslation,
  withPersonalInfo,
  withDeleteConfirmation,
  withBulkDelete,
  withUserLayoutChoice,
  withCustomRouter,
} from 'components/hoc';
import OrgContentTemplate from '../../common/OrgContentTemplate';
import './style.css';
import { useHistory } from 'react-router';

const OrganisationSelection = (props) => {
  const { favorites } = useSelector((state) => state.organisation);

  const dispatch = useDispatch();
  const history = useHistory();

  // console.log('favorites', favorites)

  const mapFields = () => {
    const { trObj, fn, fd } = props;
    return favorites.map(({ id, organisation_data, space }) => {
      const {
        name,
        public: _public,
        legal_form_detail,
        ...rest
      } = organisation_data;

      return {
        ...rest,
        id,
        name,
        space_name: space,
        _legal_form: legal_form_detail ? trObj(legal_form_detail) : '',
        _public: _public ? '\u2713' : '\u2717',
        _foundation: fd(rest.foundation),
        _liquidation: fd(rest.liquidation),
        _active: rest.active ? '\u2713' : '\u2717',
        _organisation_type: trObj(rest.organisation_type_detail),
        _org_country: trObj(rest.org_country_detail),
        _state: trObj(rest.state_detail),
        _city_location: trObj(rest.city_location_detail),
        _register_institute: rest?.register_institute?.name,
        _revenue_currency: trObj(rest.revenue_currency_detail),
        _industry: trObj(rest.industry_type_detail),
        _no_of_employees: fn(rest.no_of_employees),
        _revenue_approx: fn(rest.revenue_approx),
        _legal_personality: rest.legal_personality ? '\u2713' : '\u2717',
        _non_profit: rest.non_profit ? '\u2713' : '\u2717',
        _org_motivation: trObj(rest.org_motivation_detail),
        _parent_org: rest?.parent_org?.name,
        _organisation_logo: rest?.organisation_logo,
      };
    });
  };

  const deleteMultipleFavoriteOrg = async (data) => {
    const { deleteConfirmation } = props;
    console.log('Row ID', data);
    const deleteBulk = () => dispatch(deleteBulkFavoriteOrg(data));
    try {
      await deleteConfirmation.initBulk(deleteBulk, data);
    } catch (e) {}
  };

  const handleOrgAction = (id, data) => {
    // this org function serves to differentiate between organisation
    // that come pre built in the system and those that were created by the user
    // if the organisation was created by the user move to the personal org form
    // else you move it to the search org form
    const { basePath } = props;
    if (data?.creator) {
      const pathArray = basePath.split('/');
      pathArray.pop();
      const pathway = `${pathArray.join('/')}/organisation/${data?.id}`;
      history.push(pathway);
    } else {
      // route to the search org form
      history.push(`${basePath}/${data?.id}`);
    }
  };

  useEffect(() => {
    dispatch(getFavoriteOrg());
  }, []);

  const tableName = 'organisation';
  const { gotoSearchPath } = props;

  return (
    <React.Fragment>
      <OrgContentTemplate
        tableName={tableName}
        columns={[]}
        data={mapFields() || []}
        toggleForm={gotoSearchPath}
        // orgCondition
        // handleOrgAction={handleOrgAction}
        bulkDelete={deleteMultipleFavoriteOrg}
      />
    </React.Fragment>
  );
};

export default compose(
  withBulkDelete,
  withTranslation,
  withPersonalInfo,
  withDeleteConfirmation,
  withUserLayoutChoice,
  withCustomRouter,
)(OrganisationSelection);
