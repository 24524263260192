import React from 'react';
import compose from 'lodash/fp/compose';
import { addDays, parseISO, formatISO, isEqual, isAfter } from 'date-fns';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import {
  Input,
  Select,
  Form,
  ButtonLink,
  InputDatePicker,
  FlashMessage,
} from 'components/common/FormItems';
import {
  withCustomRouter,
  withTranslation,
  withPersonalInfo,
  withTimeDependentName,
  withPersonNamesReason,
  withUserPreferences,
} from 'components/hoc';
import { isEmptyValue } from 'components/utils/checkValue';

const Wrapper = styled.div``;

const StyledButtonLink = styled(ButtonLink)`
  span {
    text-transform: uppercase;
  }
`;

const timeDependentNameFormSchema = {
  last_name: {
    validation: 'required',
  },
  reason: {
    validation: 'required',
  },
  // valid_from: {
  //   validation: 'required',
  // },
};

class TimeDependentName extends React.Component {
  state = {
    error: null,
    submittingForm: false,
    loadingData: false,
    timeDependentName: [],
  };

  async componentDidMount() {
    const { getTimeDependentNames, getTimeDependentNameById, routeParams } =
      this.props;
    const params = routeParams;
    const timeDependentName = await getTimeDependentNames({
      person: routeParams.personId,
    });
    await this.setState({ timeDependentName });

    if (params.id) {
      this.setState({ loadingData: true });
      try {
        const data = await getTimeDependentNameById(params.id);
        this.setState(data);
      } finally {
        this.setState({ loadingData: false });
      }
    }
  }

  onChange = (e) => {
    if (this.formLoading) return;
    const { value, name } = e.target;
    this.setState({ [name]: value });
  };

  onSubmit = async () => {
    const {
      addTimeDependentName,
      updateTimeDependentName,
      routeParams,
      gotoDetailPath,
      personalInfo,
      updatePersonalInfo,
      getPersonalInfo,
      tr,
      fd,
      basePath,
    } = this.props;
    let { id, timeDependentName, ...formData } = this.state;
    if (this.formLoading) return;

    const history = useHistory();

    const { personId } = routeParams;
    const person = personalInfo.data.find((d) => d.id == +personId);
    let options = { person: personId };
    const isoDate = parseISO(formData.valid_from);
    const date = addDays(isoDate, -1);
    const dateOfBirth = formatISO(date).substring(0, 10);

    if (!person.date_of_birth) {
      options = { ...options, date_of_birth: dateOfBirth };
    } else if (
      isAfter(parseISO(person.date_of_birth), parseISO(formData.valid_from))
    ) {
      this.setState({
        error: `${tr('Valid From')} (${fd(formData.valid_from)}) ${tr(
          'must be lesser than the Birth Date',
        )} (${fd(person.date_of_birth)})`,
      });
      return;
    }

    const nameDependentIds = timeDependentName
      .filter((data) => {
        return isEqual(parseISO('9999-12-31'), parseISO(data.valid_to));
      })
      .map((data) => data.id);

    Object.assign(formData, options, {
      name_dependent_ids: nameDependentIds,
      new_valid_to: dateOfBirth,
    });
    this.setState({ submittingForm: true, error: null });

    let data = {};

    try {
      // updating persionInfo date_of_birth and last_name
      if (options.date_of_birth) {
        await updatePersonalInfo(personId, formData);
      }
      if (isEmptyValue(id)) {
        data = await addTimeDependentName(formData);
      } else {
        data = await updateTimeDependentName(id, formData);
      }
      // await getPersonalInfo()
      // gotoDetailPath(data.id)
      history.push(basePath);
    } finally {
      this.setState({ submittingForm: false });
    }
  };

  get formLoading() {
    const { submittingForm, loadingData } = this.state;
    return submittingForm || loadingData;
  }

  render() {
    const { state, props } = this;
    const { basePath } = props;

    return (
      <Wrapper key={basePath} className="form-wrapper">
        <div className="row">
          <div className="col-7">
            <div className="flex">
              <StyledButtonLink
                to={basePath}
                className="option-btn"
                icon="angle double left"
                name="View Names"
              />
            </div>
            <br />
            <Form
              onSubmit={this.onSubmit}
              formData={state}
              validationSchema={timeDependentNameFormSchema}
              title="Time Dependent Name"
              isLoading={state.submittingForm}
              loadingData={state.loadingData}
            >
              <Input
                name="last_name"
                value={state.last_name || ''}
                onChange={this.onChange}
                label="Last Name"
                placeholder="Last Name"
                showRequiredAsterisk
              />
              <Select
                name="reason"
                optionDefaultValue="Select Reason"
                onChange={this.onChange}
                value={state.reason || ''}
                label="Reason"
                localeOptions={props.personNamesReasonLocaleOptions}
                sort={false}
                showRequiredAsterisk
              />
              <InputDatePicker
                label="Valid From"
                value={state.valid_from || ''}
                name="valid_from"
                onChange={this.onChange}
                showRequiredAsterisk
              />
              {state.error && (
                <FlashMessage hasError={state.error}>{state.error}</FlashMessage>
              )}
            </Form>
          </div>
        </div>
      </Wrapper>
    );
  }
}

export default compose(
  withCustomRouter,
  withTranslation,
  withPersonalInfo,
  withTimeDependentName,
  withPersonNamesReason,
  withUserPreferences,
)(TimeDependentName);
