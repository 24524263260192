import * as types from "../../actions/types";
import { createTypes } from "../../actions";
import { baseViewForm } from '../utils/baseViewForm';

const initialState = {
  data: [],
  isLoading: false,
};

const documentForm = baseViewForm(types.DOCUMENT_FORM)
const documentView = baseViewForm(types.DOCUMENT_VIEW, 'view')

const documents = (state=initialState, action={}) => { 

  switch(action.type){ 

    case createTypes(types.GET_DOCUMENT).success: 
      return { ...state, data: action.payload, isLoading: false };

    case createTypes(types.ADD_DOCUMENT).success: 
      return { 
        ...state, 
        data: [ ...action.payload, ...state.data ], 
        isLoading: false 
    };

    case createTypes(types.UPDATE_DOCUMENT).success: 
      return { 
        ...state, 
        data: state.data.map((_data) => { 
          if(_data.id === action.payload.id) return action.payload;
          return _data;
        }), 
        isLoading: false 
    };
    
    case createTypes(types.DELETE_DOCUMENT_BY_ID).success: 
      return { 
        ...state, 
        data: state.data.filter((_data) => { 
          if (action.payload !== undefined) {
            return _data.id !== action.payload.id;
          }else{
            return _data.id
          }
        }), 
        isLoading: false 
    };
    case createTypes(types.ADD_DOCUMENT).loading: 
    case createTypes(types.UPDATE_DOCUMENT).loading: 
    case createTypes(types.GET_DOCUMENT).loading: 
    case createTypes(types.DELETE_DOCUMENT_BY_ID).loading: 
      return { ...state, isLoading: true, error: null };
    case createTypes(types.ADD_DOCUMENT).failure: 
    case createTypes(types.UPDATE_DOCUMENT).failure: 
    case createTypes(types.GET_DOCUMENT).failure: 
    case createTypes(types.DELETE_DOCUMENT_BY_ID).failure: 
      return { ...state, isLoading: false, error: action.payload };
    default:
      return state
  }
}


const documentType = (state=[], action={}) => { 
  switch(action.type){ 
    case types.DOCUMENT_TYPE:
      return action.payload
    default:
      return state
  }
}

export default { documentForm, documentView, documents, documentType };
