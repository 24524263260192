import React from "react";
import compose from "lodash/fp/compose";
import { 
  withTranslation,
  withUserPreferences,
  withDateFormat,
  withRelationInfo,
  withCustomRouter,
} from "components/hoc";
// import { 
//   setRelationDetail
// } from 'actions';
import MyDataContentTemplate from '../../common/MyDataContentTemplate'
import { getFullName } from 'components/utils/getFullName';
import LottieLoader from 'components/common/LottieLoader';

const options = ({ detail, _delete }) => {
  return [
    {
      onClick: detail,
      name:  "View",
    },
    {
      onClick: _delete,
      name: "Delete",
    },
  ];
}


const Relation = (props) => {

  const { relationInfo, trObj, fd } = props;

  const mapFields = () => {
    
    return relationInfo.map((data) => {
      return { 
        ...data, 
        space_name: data.space,
        _person: getFullName(data.person_detail),
        _relation_to: getFullName(data.relation_to_detail),
        _relation_part: trObj(data.relation_part_detail),
        _valid_from: fd(data.valid_from),
        _valid_to: fd(data.valid_to),
      }
    })
  }

  const { 
    deleteRelationInfoById, 
    deleteBulkRelationInfo,
    gotoCreatePath,
    gotoDetailPath,
    loaded,
  } = props;

  const goToDetailPathWithRelation = (id) => {
    let selectedRelation = relationInfo.filter(relation => relation.id == id);
    selectedRelation = selectedRelation.length > 0 ? selectedRelation[0] : undefined;
    // dispatch(setRelationDetail(selectedRelation));
    gotoDetailPath(id, selectedRelation);
  }

  const executeOnRowClick = (detailRelation) => {
    let selectedRelation = relationInfo.filter(relation => relation.id == detailRelation.id);
    selectedRelation = selectedRelation.length > 0 ? selectedRelation[0] : undefined;
    // dispatch(setRelationDetail(selectedRelation));
    gotoDetailPath(selectedRelation.id, selectedRelation);
  }

  if (!loaded) {
    return (
      <>
          <LottieLoader />
      </>)
  }

  return (
    <React.Fragment>
      <MyDataContentTemplate 
        columns={[]}
        tableName="relation"
        data={mapFields()}
        toggleForm={gotoCreatePath}
        bulkDelete={deleteBulkRelationInfo}
        // executeOnRowClick={executeOnRowClick}
        // options={
        //   options({
        //     detail: (data) => goToDetailPathWithRelation(data.id),
        //     _delete: (data) => deleteRelationInfoById(data),
        //   })
        // }
      />
    </React.Fragment>
  )
}

export default compose(
  withTranslation,
  withUserPreferences,
  withDateFormat,
  withRelationInfo,
  withCustomRouter,
)(Relation);

