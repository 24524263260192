import { BaseActionClass } from "../common/BaseActionClass"
import { ADDRESS_INFO } from '../types'

const path = "/dashboard/address_info";
const name = "Address";

class AddressInfo extends BaseActionClass {
  
  constructor() { super(path, ADDRESS_INFO, name) }

  addAddressInfo = this._add;

  updateAddressInfo = this._update;

  getAddressInfo = this._getAll;

  getAddressInfoById = this._getById;

  deleteAddressInfoById = this._deleteById;

  deleteBulkAddressInfo = this._deleteBulk;
}

export const { 
  addAddressInfo, 
  updateAddressInfo, 
  getAddressInfo,
  getAddressInfoById,
  deleteAddressInfoById,
  deleteBulkAddressInfo,
} = new AddressInfo()
