import axios from '../axios';
import formatUserPreferencesData from '../components/utils/formatUserPreferencesData';
import {
  GET_USER_PREFERENCES,
  UPDATE_USER_PREFERENCES,
  USER_PREFERENCES_LOADER,
} from './types';
import API from './api';
import createAction from './createAction';

const baseUrl = `${API}/dashboard/user_preference`;

export function getUserPreferences() {
  return async (dispatch) => {
    try {
      dispatch(createAction(USER_PREFERENCES_LOADER));

      const {
        data: [prefData],
      } = await axios.get(`${baseUrl}`);

      const userPref = formatUserPreferencesData(prefData);
      localStorage.setItem('userPreferences', JSON.stringify(userPref));

      dispatch({
        type: GET_USER_PREFERENCES,
        payload: userPref,
      });
      dispatch(createAction(USER_PREFERENCES_LOADER));

      return userPref;
    } catch (error) {
      dispatch(createAction(USER_PREFERENCES_LOADER));
      throw error;
    }
  };
}

export function updateUserPreferences({ id, ...rest }) {
  return async (dispatch) => {
    try {
      const { data } = await axios.patch(`${baseUrl}/${id}`, {
        dateformat: rest.dateFormatId,
        numberformat: rest.numberFormat,
        ...rest,
      });

      const userPref = formatUserPreferencesData(data);
      localStorage.setItem('userPreferences', JSON.stringify(userPref));

      dispatch({
        type: UPDATE_USER_PREFERENCES,
        payload: userPref,
      });
    } catch (error) {
      throw error;
    }
  };
}
