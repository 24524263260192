import Workspace from "./Workspace";
import { SPACE_TYPES } from 'constants/index';


const path = "/dashboard/workspace";

export const redirects = [
  {
    from: path,
    to: `${path}`
  },
  {
    from: `${path}/*`,
    to: `${path}`
  },
]

export default [
  {
    path: `${path}`,
    icon: "",
    component: Workspace,
    exact: true,
    permission: [SPACE_TYPES.PERSON, SPACE_TYPES.ORG],
  },
]
