import React from 'react';
import compose from 'lodash/fp/compose';
import { connect } from 'react-redux';
import hoistNonReactStatics from 'hoist-non-react-statics';
import {
  addDocument,
  updateDocument,
  getDocuments,
  deleteDocumentById,
  createAction,
  DOCUMENT_VIEW,
  DOCUMENT_FORM,
} from '../../../actions';
import { withState, withDeleteConfirmation, withDocumentType } from '..';

let loaded = false;

export const withDocument = (WrapperComponent) => {
  class Documents extends React.Component {
    state = {};

    componentDidMount() {
      const { getDocuments } = this.props;
      if (loaded) return;
      loaded = true;
      getDocuments();
    }

    onSubmit = (e) => {
      e.preventDefault();
      const { isLoading } = this.state;
      const { addDocument, updateDocument, documentForm } = this.props;
      if (isLoading) return;
      const {
        id,
        update,
        files,
        concerned_person,
        file,
        // _issuing_date,
        issuing_date,
        ...rest
      } = documentForm.data;

      const formData = new FormData();
      concerned_person && formData.append('concerned_person', concerned_person);
      files &&
        Array.from(files).forEach((file) => {
          formData.append('pdf_files[]', file);
        });
      Object.entries(rest).forEach(([key, value]) => {
        formData.append(key, value);
      });

      if (!id) {
        this.request(addDocument, formData);
      } else {
        this.request(updateDocument, id, rest);
      }
    };

    request = async (asyncFunc, ...payload) => {
      try {
        this.setState({ isLoading: true });
        await asyncFunc(...payload);
        this.setState({ isLoading: false });
      } catch (e) {
        this.setState({ isLoading: false });
      }
    };

    onChange = ({ target }) => {
      const { name, value } = target;
      const {
        setValue,
        documentForm: { data: documentFormData },
        getOrgById,
        getStateByCountryId,
      } = this.props;
      let data = { ...documentFormData, [name]: value };
      if (name === 'issuing_org') {
        const { org_country } = getOrgById(value);
        data = { ...data, country: org_country };
        getStateByCountryId(org_country);
      }
      setValue({ data, showForm: true });
    };

    deleteDocumentById = async (data) => {
      const { deleteConfirmation, deleteDocumentById } = this.props;
      try {
        await deleteConfirmation.init(deleteDocumentById, data);
      } catch (e) {}
    };

    render() {
      const { setValue, ...rest } = this.props;
      return (
        <WrapperComponent
          {...rest}
          documentFormSubmitting={this.state.isLoading}
          toggleDocumentForm={setValue}
          onChangeDocument={this.onChange}
          onSubmitDocument={this.onSubmit}
          deleteDocumentById={this.deleteDocumentById}
        />
      );
    }
  }

  const mapStateToProps = ({ documents, documentForm, documentView }) => {
    return {
      documents,
      documentView,
      documentForm,
    };
  };
  const WithDocument = connect(mapStateToProps, {
    addDocument,
    updateDocument,
    deleteDocumentById,
    getDocuments,
    toggleDocumentView: (data) => createAction(DOCUMENT_VIEW, data),
    setValue: (data) => createAction(DOCUMENT_FORM, data),
  })(Documents);
  hoistNonReactStatics(WithDocument, WrapperComponent);
  return compose(withDeleteConfirmation, withDocumentType, withState)(WithDocument);
};
