import React from 'react';
import styled from 'styled-components';
import compose from 'lodash/fp/compose';
import {
  withCurrency,
  withWorkspace,
  withAccount,
  withSpace,
  withUserDefaultSpace,
  withPersonGroup,
} from 'components/hoc';
import { Form, Select, Input, Button } from 'components/common/FormItems';

const StyledForm = styled(Form)`
  div.card-body {
    max-width: 700px;
  }
`;

const StyledButton = styled(Button)`
  width: 100px;
`;

const CurrencySelect = (props) => {
  const { currency, currencyLocaleOptions, spaceAdmin, setCurrency } = props;

  return (
    <Select
      name="currency"
      optionDefaultValue="Select Currency"
      onChange={({ target }) => setCurrency(target.value)}
      value={currency}
      label="Currency"
      localeOptions={props.currencyLocaleOptions}
      disabled={!spaceAdmin}
    />
  );
};

const PersonGroupSelect = (props) => {
  const { personGroup, spaceAdmin, setPersonGroup, personGroupOptions } = props;

  return (
    <Select
      name="person_group"
      optionDefaultValue="Select Person Group"
      onChange={({ target }) => setPersonGroup(target.value)}
      value={personGroup || ''}
      label="Person Group"
      options={personGroupOptions}
      disabled={!spaceAdmin}
    />
  );
};

const ContractAlert = (props) => {
  const options = new Array(10).fill().map((_, i) => {
    return { id: i + 1, name: i + 1 };
  });
  const { setReminder, reminder } = props;

  return (
    <Select
      optionDefaultValue="Choose Contract / Document  Reminder (Weeks)"
      label="Document / Contract Reminder Alert (Weeks)"
      value={reminder}
      name="contract_alert"
      options={options}
      onChange={({ target }) => setReminder(target.value)}
      deselectable
      sort={false}
    />
  );
};

const WorkspaceSettingsForm = (props) => {
  const { updateSpace, userDefaultSpace, personGroupOptions, account } = props;

  const spaceAdmin =
    userDefaultSpace?.space_detail?.creator === account?.user?.user_id;
  const spacePersonGroup = userDefaultSpace?.space_detail?.person_group;
  const spaceCurrency = userDefaultSpace?.space_detail?.currency;
  const spaceReminder = userDefaultSpace?.space_detail?.reminders;
  const newProps = { ...props, spaceAdmin };

  const [personGroup, setPersonGroup] = React.useState(spacePersonGroup);
  const [currency, setCurrency] = React.useState(spaceCurrency);
  const [reminders, setReminder] = React.useState(spaceReminder);
  const [loading, setLoading] = React.useState(false);

  const onSubmit = async () => {
    setLoading(() => true);
    try {
      const data = await updateSpace(userDefaultSpace.space, {
        person_group: personGroup,
        currency,
        reminders,
      });
    } finally {
      setLoading(() => false);
    }
  };

  return (
    <StyledForm
      title="Workspace Setting"
      onSubmit={onSubmit}
      isLoading={loading}
      renderActionButtons={() => (
        <StyledButton
          type="submit"
          name="Save"
          isLoading={loading}
          className="btn-primary"
        />
      )}
    >
      <PersonGroupSelect
        {...newProps}
        setPersonGroup={setPersonGroup}
        personGroup={personGroup || spacePersonGroup}
      />
      <CurrencySelect
        {...newProps}
        setCurrency={setCurrency}
        currency={currency || spaceCurrency}
      />
      <ContractAlert
        {...newProps}
        setReminder={setReminder}
        reminder={reminders || spaceReminder}
      />
    </StyledForm>
  );
};

export default compose(
  withCurrency,
  withWorkspace,
  withAccount,
  withSpace,
  withUserDefaultSpace,
  withPersonGroup,
)(WorkspaceSettingsForm);
