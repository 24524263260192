import React, { Component }  from 'react';
import styled from "styled-components";


const H4Div = styled.h4`
    font-weight: bold;
    font-size:2em;
    color: ${props => props.color ? props.color : "#666"};
    text-align: ${props => props.align ? props.align : 'left'};
    margin: ${props => props.margin ? props.margin: '10px'};
    font-family: ${props => props.fontFamily ? props.fontFamily : 'poppins'};
    @media only screen and (max-width:750px){
        font-size:1.5em;
    }
`

const H4 = (props) => {
    const { children, color, align,...rest } = props
    return <H4Div color={color} align={align} {...rest}>{children}</H4Div>
}

export default H4