import React, { useState, useRef, useEffect } from 'react';
import compose from 'lodash/fp/compose';
import ReactPlayer from 'react-player/youtube';
import Slider from 'react-slick';
import person from 'assets/img/person.png';
import favourite from 'assets/img/organization.png';
import document from 'assets/img/upload.png';
import mailbox from 'assets/img/email.png';
import contract from 'assets/img/contracts.png';
import dashboard from 'assets/img/dashboard.png';
import financial from 'assets/img/financial.png';
import missing from 'assets/img/missing.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withTranslation } from 'components/hoc';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import './index.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      onClick={onClick}
      style={{
        ...style,
        display: 'block',
        right: '80px',
      }}
    >
      <FontAwesomeIcon
        icon={faAngleRight}
        color="var(--admincat-color-grey-2)"
        size="3x"
      />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      onClick={onClick}
      style={{
        ...style,
        display: 'block',
        left: '-10px',
      }}
    >
      <FontAwesomeIcon
        icon={faAngleLeft}
        color="var(--admincat-color-grey-2)"
        size="3x"
      />
    </div>
  );
}

function QuickTour({ tr }) {
  // const [currentSlide, setCurrentSlide] = useState(0);
  const [playerState, setPlayerState] = useState({});
  const sliderRef = useRef(null);

  const handlePlayPause = (index) => {
    setPlayerState({ ...playerState, [index]: !playerState[index] });
  };

  // useEffect(() => {
  //   setPlayerState({ [currentSlide]: false });
  // }, [currentSlide]);

  const quicktourdata = [
    {
      id: 1,
      title: 'Create person, person group and assign a space group',
      src: 'https://www.youtube.com/watch?v=kaJ3eft-dNI',
      image: person,
      description:
        'Persons are central in your workspace - the members of your family or household as well as relatives and friends. Therefore start to create the persons that you need. Group them as you like as person groups. A special group is the one that you assign to your space:All financial data on the dashboard refer to that group. And on the Dashboard card "Space Group" you even see the pictures of its members.',
    },
    {
      id: 2,
      title: 'Add or create your favourite organizations',
      src: 'https://www.youtube.com/watch?v=kaJ3eft-dNI',
      image: favourite,
      description:
        'Organizations are (like persons) central in your workspace – the ones you worked for or currently do business with. Many organizations (for Germany) exist already in our Database. Search and add a selection as your favourites. Once done you can use these anywhere in your workspace. You can also create new organizations, if not yet existent.',
    },
    {
      id: 3,
      title: 'Upload and administer documents',
      src: 'https://www.youtube.com/watch?v=kaJ3eft-dNI',
      image: document,
      description:
        'Passports, birth certificates, diploma etc. – documents are important. Under the main menu option Documents you can click on the upload area in the center and chose the documents from your drive. Use the filter on the left or the full-text search bar on top and review the document details on the right. Edit, view or delete each document individually.',
    },
    {
      id: 4,
      title: 'E-Mailbox and attachment rules',
      src: 'https://www.youtube.com/watch?v=kaJ3eft-dNI',
      image: mailbox,
      description:
        'For each space AdminCat provides you with an own space email address. Send and receive emails normally, mark them as read, print or export them. You can also assign them to data areas (Finance, Mobility, etc.) if you like to use that for filtering and sorting. And if you define attachment rules,incoming attachments are stored in the document area automatically, enriched with data according to the individual rules.',
    },
    {
      id: 5,
      title: 'Contracts',
      src: 'https://www.youtube.com/watch?v=kaJ3eft-dNI',
      image: contract,
      description:
        'A contract is the foundation for financial transactions – and a special type of document. Here you can enter your contract details, link it to the involved persons and organizations as well as to a underlying document, that was saved in the documents area.',
    },
    {
      id: 6,
      title: 'The Dashboard',
      src: 'https://www.youtube.com/watch?v=kaJ3eft-dNI',
      image: dashboard,
      description:
        'Displays financial key figures and charts for your space – according to your space group and your chosen space currency. It also informs about Storage Space usage, number of stored documents, unread emails and important notifications concerning documents and contracts.',
    },
    {
      id: 7,
      title: 'Financial transactions in 53 currencies',
      src: 'https://www.youtube.com/watch?v=kaJ3eft-dNI',
      image: financial,
      description:
        'Post your income and consumption transactions like a professional using a double-entry accounting with a variety of payment sources. Foreign currencies get translated into your central workspace currency using a daily updated exchange rate. And if a posting is incorrect, just reverse it again whenever you like.',
    },
    {
      id: 8,
      title: 'Missing values -> Propose new entries',
      src: 'https://www.youtube.com/watch?v=kaJ3eft-dNI',
      image: missing,
      description:
        'The list of entry options is not complete in certain fields? Well, there is a way how you can help us to improve this quickly: Just propose a new entry and we will add the entry after a short review period.',
    },
  ];

  const settings = {
    dots: true,
    // fade: true,
    className: 'center',
    centerMode: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    // autoplay: false,
    afterChange: (current) => setPlayerState({ [current]: false }),
    // afterChange: (current) => {
    //   setCurrentSlide(current);
    //   setPlayerState({ ...playerState, [current]: false });
    // },
  };

  return (
    <Slider {...settings} ref={sliderRef}>
      {quicktourdata.map((data, index) => {
        return (
          <div
            className="col-7 mt-40 container"
            style={{
              position: 'relative',
              margin: '10px',
            }}
          >
            <div key={index} onClick={() => handlePlayPause(index)}>
              <h3
                style={{
                  margin: '10px',
                  color: 'var(--admincat-color-grey-2)',
                }}
              >
                {tr(data.title)}
              </h3>
              <div style={{ display: 'inline-flex' }}>
                <img src={data.image} width="60px" height="60px" />
                <p
                  style={{
                    margin: '10px',
                    fontSize: '14px',
                    color: 'var(--admincat-color-grey-2)',
                  }}
                >
                  {tr(data.description)}
                </p>
              </div>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <ReactPlayer
                  url={data.src}
                  volume={1}
                  width="40vw"
                  height="350px"
                  style={{
                    marginBottom: '20px',
                  }}
                  controls
                  playing={playerState[index]}
                  // playing={playerState[index] === true}
                />
              </div>
            </div>
          </div>
        );
      })}
    </Slider>
  );
}
export default compose(withTranslation)(QuickTour);
