import axios from '../../axios';
import * as types from '../types';
import { createAction } from '../';
import API from '../api';

const baseUrl = API;

export const getCurrencyRate = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(`${baseUrl}/currency-rate`);
      dispatch(createAction(types.GET_CURRENCY_RATE, data));
      return data;
    } 
    catch (err) {
      throw err;
    }
  };
};
