import AdminDashboard from './Dashboard';
import Proposal from './Proposal';
import Mail from './Mail';
import { ADMIN_TYPES } from 'config/adminTypes';

export const path = '/admin/dashboard';

export default [
  {
    name: 'Mail',
    path: `${path}/mail`,
    icon: '',
    component: Mail,
    allowedAdminTypes: [ADMIN_TYPES.COMMUNICATION, ADMIN_TYPES.SUPER_USER],
  },
  {
    name: 'Proposal',
    path: `${path}/proposal`,
    icon: '',
    component: Proposal,
    allowedAdminTypes: [ADMIN_TYPES.DATA_ADMIN, ADMIN_TYPES.SUPER_USER],
  },
  {
    name: 'Dashboard',
    path: `${path}`,
    icon: '',
    component: AdminDashboard,
    allowedAdminTypes: Object.values(ADMIN_TYPES), // accessible by all admin types
  },
];

export const redirects = [
  {
    from: `${path}`,
    to: `${path}`,
  },
  {
    from: `${path}/*`,
    to: `${path}`,
  },
];
