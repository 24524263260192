import { useLayoutEffect, useState } from 'react';

export const useScrollPosition = () => {
  const [position, sethight] = useState(0);
  useLayoutEffect(() => {
    const setWindowHeight = () => {
      sethight(window.scrollY);
    };
    window.addEventListener('scroll', setWindowHeight);
    return () => window.removeEventListener('scroll', setWindowHeight);
  }, []);
  return {
    position,
  };
};
