import axios from '../axios';
import { createTypes } from "./createTypes";
import createAction from "./createAction";
import API from "./api";
import * as types from "./types";

const baseUrl = `${API}/user/language/`;


export function getUserLanguage() {
  return (async dispatch => { 
    try {
      dispatch(createAction(createTypes(types.USER_LANGUAGE).loading, true))
      const { data } = await axios.get(`${baseUrl}`);
      dispatch(createAction(createTypes(types.USER_LANGUAGE).success, data))
    } catch(error) {
      throw error;
    }
  })
}

export function setUserLanguage(payload) {
  return (async dispatch => { 
    try {
      dispatch(createAction(createTypes(types.USER_LANGUAGE).loading, true))
      const { data } = await axios.put(`${baseUrl}`, payload);
      dispatch(createAction(createTypes(types.USER_LANGUAGE).success, data))
    } catch(error) {
      throw error;
    }
  })
}
