import React from 'react';
import styled from 'styled-components';
import RouteRedirect from '../../common/RouteRedirect';
import DashboardTemplate from '../../common/DashboardTemplate';
import routes, { redirects } from './routes';

const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
  margin: auto auto auto 3.7%;
`;

const QuickTour = () => {
  return (
    <DashboardTemplate
      stripeHeaderTitle="Quick Tour"
      showSubmenu
      routes={routes}
      redirects={redirects}
      render={(renderPropsData) => (
        <Wrapper>
          <RouteRedirect {...renderPropsData} />
        </Wrapper>
      )}
    />
  );
};

export default QuickTour;
