import axios from '../axios';
import * as types from './types';
import createAction from './createAction';

import API from './api';

const baseUrl = API + '/dashboard/payments';

export function getPayments(payload) {
  return async (dispatch) => {
    try {
      dispatch(createAction(types.GET_PAYMENTS_LOADING));
      const { data } = await axios.get(`${baseUrl}`);

      dispatch(createAction(types.GET_PAYMENTS, data));
    } catch (error) {
      dispatch(createAction(types.GET_PAYMENTS_FAILURE, error));
    }
  };
}
