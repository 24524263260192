export const professionLabels = [
  {
    profession: 'Profession',
  },
];

export const getProfessionOptions = (options, trObj) => options.map(({ id, name }) => {
  if (id.includes('X')) {
    return {
      id,
      profession: name,
      header: true,
    };
  }
  return {
    id,
    profession: name,
  };
});
