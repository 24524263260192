import axios from '../../axios';
import * as types from '../types';
import { setMsgInfo } from '..';
import filterError from '../common/filterError';
import API from '../api';

const baseUrl = API + '/admin/org_proposal/';

export const getAllOrgProposals = () => {
  return async (dispatch) => {
    dispatch({ type: types.ORG_PROPOSAL_LOADING });
    try {
      const { data } = await axios.get(`${baseUrl}?requires_action=${true}`);
      dispatch({
        type: types.GET_ALL_ORG_PROPOSAL_SUCCESS,
        payload: data,
      });
      return data;
    } catch (err) {
      const errors = filterError(err);
      dispatch({ type: types.ORG_PROPOSAL_FAILURE, payload: errors });
      dispatch(
        setMsgInfo({
          success: false,
          msg: errors,
        }),
      );
      throw err;
    }
  };
};

export const getOrgProposal = (id) => {
  return async (dispatch) => {
    dispatch({ type: types.ORG_PROPOSAL_LOADING });
    try {
      const { data } = await axios.get(`${baseUrl}${id}/`);
      dispatch({
        type: types.GET_ORG_PROPOSAL_SUCCESS,
        payload: data,
      });
      return data;
    } catch (err) {
      const errors = filterError(err);
      dispatch({ type: types.ORG_PROPOSAL_FAILURE, payload: errors });
      dispatch(
        setMsgInfo({
          success: false,
          msg: errors,
        }),
      );
      throw err;
    }
  };
};

export const approveOrgProposal = (id) => {
  return async (dispatch) => {
    dispatch({ type: types.ORG_PROPOSAL_LOADING });
    try {
      const { data } = await axios.put(`${baseUrl}${id}/approve/`);
      dispatch({
        type: types.APPROVE_ORG_PROPOSAL_SUCCESS,
        payload: data,
      });
      dispatch(
        setMsgInfo({
          success: true,
          msg: 'Organization proposal approved successfully',
        }),
      );
      return data;
    } catch (err) {
      const errors = filterError(err);
      dispatch({ type: types.ORG_PROPOSAL_FAILURE, payload: errors });
      dispatch(
        setMsgInfo({
          success: false,
          msg: errors,
        }),
      );
      throw err;
    }
  };
};

export const rejectOrgProposal = (id) => {
  return async (dispatch) => {
    dispatch({ type: types.ORG_PROPOSAL_LOADING });
    try {
      const { data } = await axios.put(`${baseUrl}${id}/reject/`);
      dispatch({
        type: types.REJECT_ORG_PROPOSAL_SUCCESS,
        payload: data,
      });
      dispatch(
        setMsgInfo({
          success: true,
          msg: 'Organization proposal rejected successfully',
        }),
      );
      return data;
    } catch (err) {
      const errors = filterError(err);
      dispatch({ type: types.ORG_PROPOSAL_FAILURE, payload: errors });
      dispatch(
        setMsgInfo({
          success: false,
          msg: errors,
        }),
      );
      throw err;
    }
  };
};

export const bulkDeleteOrgProposals = (ids) => {
  return async (dispatch) => {
    dispatch({ type: types.ORG_PROPOSAL_LOADING });
    try {
      await axios.delete(`${baseUrl}bulk_delete/`, { data: { ids } });
      const { data } = await axios.get(`${baseUrl}?requires_action=${true}`);
      dispatch({
        type: types.BULK_DELETE_ORG_PROPOSAL_SUCCESS,
        payload: data,
      });
      dispatch(
        setMsgInfo({
          success: true,
          msg: 'Organization proposals deleted successfully',
        }),
      );
      return data;
    } catch (err) {
      const errors = filterError(err);
      dispatch({ type: types.ORG_PROPOSAL_FAILURE, payload: errors });
      dispatch(
        setMsgInfo({
          success: false,
          msg: errors,
        }),
      );
      throw err;
    }
  };
};
