import axios from '../../axios';
import * as types from "../types";
import createAction from "../createAction";
import { setMsgInfo } from "../";
import API from "../api";
import filterError from "../common/filterError";

const baseUrl = API + "/dashboard/form";

export const addForm = (payload) => { 
  return async dispatch => { 
    try {
      const { data } = await axios.post(baseUrl, payload)
      dispatch(createAction(types.ADD_FORM, data));
      dispatch(setMsgInfo({
        success: true, 
        msg: ["Form uploaded successfully"],
      }));
      return data;
    } catch (err) {
      const errors = filterError(err);
      console.log({ errors })
      dispatch(setMsgInfo({
        success: false, 
        msg: errors,
      }));
      throw err;
    }
  }
}

export const getAllForm = () => { 
  return async dispatch => { 
    try {
      const { data } = await axios.get(baseUrl)
      dispatch(createAction(types.GET_ALL_FORM, data));
      return data;
    } catch (err) {}
  }
}

export const getFormById = (id, payload) => { 
  return async dispatch => { 
    try {
      const { data } = await axios.get(`${baseUrl}/${id}`)
      dispatch(createAction(types.GET_FORM, data));
      dispatch(setMsgInfo({
        success: true, 
        msg: ["Data fetched successfully"],
      }));
      return data;
    } catch (err) {}
  }
}

export const updateForm = (id, payload) => { 
  return async dispatch => { 
    try {
      await axios.put(`${baseUrl}/${id}`, payload)
      dispatch(createAction(types.UPDATE_FORM, payload));
      dispatch(setMsgInfo({
        success: true, 
        msg: ["Form updated successfully"],
      }));
    } catch (err) {
      const errors = filterError(err);
      dispatch(setMsgInfo({
        success: false, 
        msg: errors,
      }));
      throw err;
    }
  }
}

export const deleteFormById = (id, payload) => { 
  return async dispatch => { 
    try {
      await axios.delete(`${baseUrl}/${id}`)
      dispatch(createAction(types.DELETE_FORM, payload));
    } catch(err) {}
  }
}


export const deleteBulkForm = (payload) => { 
  return async dispatch => { 
    try {
      await axios.post(`${baseUrl}/bulk_delete`, {ids: payload})
      dispatch(createAction(types.DELETE_BULK_FORM, payload));
    } catch(err) {}
  }
}

export const getFormFilter = (payload) => {
  return (async dispatch => { 
    try {
      dispatch(createAction(types.FORM_FILTER_LOADING));
      const { data } = await axios.get(`${baseUrl}/filter?${payload}`);
      dispatch(createAction(types.FORM_FILTER_SUCCESS, data));
      return data;
    } catch(error) {
      dispatch(createAction(types.FORM_FILTER_FAILURE, error));
      throw error;
    }
  })
}
