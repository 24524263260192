import React, { Fragment } from "react";
import styled from "styled-components";
import { withTranslation } from "../../../hoc";
import { Form } from ".."
import Loader from '../../../common/Loader';
import { ModalStyle } from "../styled"

import customStyle from "./index.css";

const ViewModalWrapper = styled.div`
  ${customStyle}
  ${ModalStyle}
  &&& {
    display: ${({open}) => open ? 'flex' : 'none'};
  }
`

const ViewModal = (props) => {
  const {
    tr, 
    title,
    columns, 
    className, 
    open, 
    toggleView,
    loadingData
  } = props;
  return (
    <ViewModalWrapper 
      open={open}
      className={className}>
      <div className="body">
        <span 
          onClick={toggleView}
          className="exit">
          <i className="fa fa-times"></i>
        </span>
        <Form 
          onSubmit={(e) => e.preventDefault()}
          title={title}
          renderActionButtons={() => null}
        >
          <div className="">
            <div className="table-responsive">
            {!loadingData
             ? (
            <Fragment>
              <table 
                className="table table-hover table-outline
                table-vcenter text-nowrap card-table">
                <tbody>
                  {columns.map(({label, value}, index) => {
                      return (
                        <tr key={index}>
                          <th className="w-1 text-right">
                            <strong>
                              {tr(label)}:
                            </strong>
                          </th>
                          <td>
                            <div 
                              className="small text-muted capitalize">
                              {value}
                            </div>
                          </td>
                        </tr>
                      )
                    })}
                </tbody>
              </table>
                {props.children}
              </Fragment>
              ): (
                <Loader />
              )
            }
            </div>
          </div>
        </Form>
      </div>
    </ViewModalWrapper>
  )
}

export default withTranslation(ViewModal);
