import User from './User';
import Workspace from './Workspace';
// import PaymentMethods from './PaymentMethods';
import OrganisationForm from './OrganisationProfile';
import { SPACE_TYPES } from '../../../constants';

const path = '/dashboard/settings';

export default [
  
  {
    name: 'User',
    path: `${path}/user`,
    icon: 'user',
    component: User,
    permission: [SPACE_TYPES.PERSON, SPACE_TYPES.ORG],
  },
  {
    name: 'Workspace',
    path: `${path}/workspace`,
    icon: 'users',
    component: Workspace,
    permission: [SPACE_TYPES.PERSON, SPACE_TYPES.ORG],
  },
  {
    name: 'Organisation Profile',
    path: `${path}/organisation-form`,
    icon: 'industry',
    component: OrganisationForm,
    permission: [SPACE_TYPES.ORG],
  },

];

export const redirects = [
  {
    from: `${path}`,
    to: `${path}/user`,
  },
  {
    from: `${path}/*`,
    to: `${path}/user`,
  },
];
