import React from "react";
import { connect } from "react-redux";
import {
  getOrganisationFunction,
} from "../../../actions/preload";
import hoistNonReactStatics from 'hoist-non-react-statics'


let loaded = false;

export const withOrganisationFunction = (WrapperComponent) => {
  class WithOrganisationFunction extends React.Component {
    componentDidMount() {
      const { getOrganisationFunction } = this.props;
      if (loaded) return;
      loaded = true;
      getOrganisationFunction();
      
    }
    getOrgFunctionOptions = () => undefined
    getOrgFunctionLocaleOptions = () => {
      const { organisationFunction } = this.props;
      return organisationFunction.map(({ created_at, ...rest }) => ({ ...rest }));
    }
    render() {
      const {
        getOrganisationFunction: getAll,
        organisationFunction: store,
        ...rest
      } = this.props;
      return (
        <WrapperComponent 
          {...this.props}
          orgFunctionOptions={this.getOrgFunctionOptions()}
          orgFunctionLocaleOptions={this.getOrgFunctionLocaleOptions()}
        />
      )
    }
  }
  const mapStateToProps = ({ organisationFunction }) => ({ organisationFunction })
  const WithOrganisationFunctionHOC = connect(mapStateToProps, {
    getOrganisationFunction,
  })(WithOrganisationFunction);
  hoistNonReactStatics(WithOrganisationFunctionHOC, WrapperComponent)
  return WithOrganisationFunctionHOC
}

