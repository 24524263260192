import React, { useEffect } from 'react';
import {
  StatsCard,
  StatsContainer,
} from 'components/Admin/common/AdminDashboardItems';
import styled from 'styled-components';
import { withTranslation, withAdminDashboard } from 'components/hoc';
import LottieLoader from 'components/common/LottieLoader';
import compose from 'lodash/fp/compose';

const DashboardContainer = styled.div`
  margin: 0 auto;
  padding: 1%;
  min-height: 80vh;
`;

const Header = styled.header`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 20px;
`;

const Welcome = styled.h1`
  font-size: 24px;
  font-weight: normal;
  color: var(--admincat-color-grey-2);
`;

const UserName = styled.span`
  font-weight: bold;
`;

const Status = styled.span`
  background-color: #d9efff;
  color: #4f81bb;
  border-radius: 12px;
  font-size: 10px;
  width: 50px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-left: 1%;
`;

const AdminDashboard = (props) => {
  const { loaded, tr, trObj, adminDashboard } = props;

  if (!loaded) {
    return (
      <>
        <LottieLoader />
      </>
    );
  }

  const adminDashboardData = adminDashboard?.dashboardData;

  return (
    <DashboardContainer>
      <Header>
        <Welcome>
          {tr('Welcome!')} <UserName>{adminDashboardData?.username || ''}</UserName>
        </Welcome>
        <Status>Admin</Status>
      </Header>

      <StatsContainer>
        <StatsCard
          title={tr('Users')}
          value={adminDashboardData?.total_users}
          color={'#456EA6'}
        />
        <StatsCard
          title={tr('Active')}
          value={adminDashboardData?.active_users}
          color={'#1ABE52'}
        />
        <StatsCard
          title={tr('Inactive')}
          value={adminDashboardData?.inactive_users}
          color={'#E83434'}
        />
        <StatsCard
          title={tr('Subscribers')}
          value={adminDashboardData?.subscribed_users}
          color="#345CE8"
        />
      </StatsContainer>
    </DashboardContainer>
  );
};

export default compose(withAdminDashboard)(AdminDashboard);
