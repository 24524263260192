import React from "react";
import { connect } from "react-redux";
import {
  getOrganisationMotivation,
} from "../../../actions";
import hoistNonReactStatics from 'hoist-non-react-statics'

let loaded = false;

export const withOrganisationMotivation = (WrapperComponent) => {
  class WithOrganisationMotivation extends React.Component {
    componentDidMount() {
      const { getOrganisationMotivation } = this.props;
      if (loaded) return;
      loaded = true;
      getOrganisationMotivation();
    }
    getOrgMotivationOptions = () => undefined
    
    getOrgMotivationLocaleOptions = () => {
      const { organisationMotivation } = this.props;
      return organisationMotivation.map(({ created_at, ...rest }) => ({ ...rest }));
    }
    render() {
      const { props } = this;
      return (
        <WrapperComponent 
          {...props}
          orgMotivationOptions={this.getOrgMotivationOptions()}
          orgMotivationLocaleOptions={this.getOrgMotivationLocaleOptions()}
        />
      )
    }
  }
  const mapStateToProps = ({ organisationMotivation }) => ({ organisationMotivation })
  const WithOrganisationMotivationHOC = connect(mapStateToProps, {
    getOrganisationMotivation,
  })(WithOrganisationMotivation);
  hoistNonReactStatics(WithOrganisationMotivationHOC, WrapperComponent)
  return WithOrganisationMotivationHOC
}
