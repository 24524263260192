import React from "react";
import { connect } from "react-redux";
import {
  getUserDefaultSpace,
  setUserDefaultSpace,
  setSpaceCurrency,
} from "../../../actions";
import hoistNonReactStatics from 'hoist-non-react-statics'

let loaded = false;

export const withUserDefaultSpace = (WrapperComponent) => {
  class Enhance extends React.Component {
    
    componentDidMount() {
      const { getUserDefaultSpace } = this.props;
      if (loaded) return;
      loaded = true;
      getUserDefaultSpace();
    }

    changeUserDefaultSpace = async (space) => {
      const { setUserDefaultSpace } = this.props
      await setUserDefaultSpace({ space });
      // window.location.reload();
    }

    render() {
      return (
        <WrapperComponent 
          {...this.props} 
          changeUserDefaultSpace={this.changeUserDefaultSpace}
        />
      )
    }
  }
  const mapStateToProps = ({ userDefaultSpace }) => ({ userDefaultSpace })
  
  const WithUserDefaultSpace = connect(mapStateToProps, {
    getUserDefaultSpace,
    setUserDefaultSpace,
    setSpaceCurrency,
  })(Enhance);
  hoistNonReactStatics(WithUserDefaultSpace, WrapperComponent)
  return WithUserDefaultSpace
}
