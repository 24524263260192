import React from 'react';
import { compose } from 'lodash/fp';
import styled from 'styled-components';
import { Icon } from 'semantic-ui-react';
import { genId } from '../../utils';
import {
  // Form as BaseForm,
  FormNotAuth as NotAuthForm,
  Button as BaseButton,
  Select as BaseSelect,
} from '../FormItems';
import { SelectOptions } from '../FormItems/SelectOptions';
import { withTranslation, withFieldError } from '../../hoc';
// import { useTranslation } from 'react-i18next';
// import { Link } from 'react-router-dom';

const StyledBaseForm = styled(NotAuthForm)`
  &&& {
    max-width: 80%;
    margin: auto;
    box-shadow: none;
    height: auto;
    div.card-body {
      > button,
      > p {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
`;

export const Button = styled(BaseButton)`
  &&& {
    background-color: #467fcf;
    border: solid 1px #1d579b;
    text-transform: capitalize;
    width: 100%;
    cursor: pointer;
    height: 2.7rem;
    color: #ffffff;
    -webkit-tap-highlight-color: transparent;
    box-shadow: none;
    border-radius: 7px;
    &:hover {
      background: #386bb4;
    }
  }
`;

export const Form = withTranslation((props) => {
  return <StyledBaseForm {...props} />;
});

Form.defaultProps = {
  indicatorLeft: '35px',
  indicatorGap: '63px',
};

const renderTitle = ({ tr, title }) => {
  return (
    <div className="text-center">
      <h3 className="mb-3 mt-6 font-weight-bold">
        <strong>{tr(title)}</strong>
      </h3>
    </div>
  );
};

const renderActionButtons = ({ name, isLoading }) => {
  return <Button type="submit" name={name} isLoading={isLoading} />;
};

Form.defaultProps = {
  renderActionButtons,
  renderTitle,
};

const StyledIcon = styled(Icon)`
  &&& {
    font-size: 19px;
    color: #2196f3 !important;
    position: absolute;
    top: 30%;
    transform: translateY(-50%);
  }
`;

const BaseFieldWrapper = styled.div`
  background: #f1f6fd;
  border-radius: 7px;
  border: solid 1px #d4e6fa;
  &&& {
    margin-bottom: 1rem;
  }
`;

const BaseField = (props) => {
  const { icon, render, children, className } = props;
  return (
    <BaseFieldWrapper className={`md-form BaseFieldWrapper ${className}`}>
      <StyledIcon className="prefix blue-text" name={icon} />
      {children || render(props)}
    </BaseFieldWrapper>
  );
};

BaseField.defaultProps = {
  icon: '',
  children: null,
  render: () => null,
};

const InputComponent = compose(
  withTranslation,
  withFieldError,
)((props) => {
  const {
    tr,
    icon,
    name,
    type,
    value,
    onChange,
    onBlur,
    required,
    disabled,
    placeholder,
    localeOptions,
    options,
    className,
    ...rest
  } = props;
  const listid = genId();
  const hasOptions = localeOptions || options;
  return (
    <BaseField icon={icon}>
      <input
        type={type}
        id={name}
        value={value}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        className="form-control"
        required={required}
        disabled={disabled}
        list={listid}
      />
      {hasOptions && (
        <datalist id={listid}>
          <SelectOptions localeOptions={localeOptions} options={options} {...rest} />
        </datalist>
      )}
      <label htmlFor={name}>{tr(placeholder)}</label>
    </BaseField>
  );
});

InputComponent.defaultProps = {
  name: '',
  type: 'text',
  value: '',
  required: false,
  disabled: false,
  placeholder: '',
};

export const Input = styled(InputComponent)`
  div.DisplayError {
    margin-left: 40px;
  }
  &&& input {
    border: none;
    border-bottom: ${({ fieldValidationProps, name }) => {
      const { errors } = fieldValidationProps ? fieldValidationProps[name] : {};
      return errors && errors.length ? '1px solid red' : '1px solid #bdbdbd';
    }};
  }
`;

const SelectWrapper = styled(BaseField)`
  &&& select {
    width: 84%;
  }
`;

export const Select = withFieldError((props) => {
  const { icon, label: _, ...rest } = props;
  return (
    <SelectWrapper icon={icon}>
      <BaseSelect {...rest} />
    </SelectWrapper>
  );
});

const BigCheckboxWrapper = styled.div`
  && {
    padding-left: 0;
    label.form-check-label {
      margin-left: 13px;
      font-size: 14px;
    }
  }
`;

export const BigCheckbox = withTranslation((props) => {
  const {
    className,
    name,
    onChange,
    value = false,
    label,
    disabled,
    tr,
    children,
  } = props;
  // const { t } = useTranslation();
  return (
    <BigCheckboxWrapper className={`form-check flex position-rel ${className}`}>
      <input
        className="form-check-input m-l-0 check-box"
        name={name}
        onChange={() =>
          onChange({
            target: { name, value: !value },
          })
        }
        type="checkbox"
        checked={value}
        id={name}
        disabled={disabled}
      />
      <label htmlFor={name} className="check-box"></label>
      <label className="form-check-label" htmlFor={name}>
        {children}
        {/* {tr(label)} */}
      </label>
    </BigCheckboxWrapper>
  );
});

BigCheckbox.defaultProps = {
  className: '',
  name: '',
  onChange: () => {},
  value: false,
  label: '',
  disabled: false,
};
