import styled from "styled-components"
import PasswordImage from "./password.png"


export const MainWrapper = styled.div`
    height:100vh;
    display:flex;
    box-sizing:border-box;
    `

export const ImageSection = styled.div`
    flex:1;
    background:url(${PasswordImage});
    background-size:cover;
    background-repeat:no-repeat;
    box-sizing:border-box;

    @media only screen and (max-width:750px){
        display:none;
    }
`

export const PasswordSection = styled.div`
    flex:1;
    background:#fff;
    padding:50px;
    box-sizing:border-box;

    @media only screen and (max-width:750px){
        padding:10px;
    }
`
export const Confirmation = styled.div`
    display:flex;
    justify-content: center;
    align-items: center;

    .content{
        padding:10px;
        text-align:center;
        .image{
            width:140px;
            margin:auto;
            img{
                width:100%;
            }
        }
    }
`