import React, { Component } from 'react';
import compose from 'lodash/fp/compose';
import { Link } from 'react-router-dom';
import { withTranslation, withAccount, withUserPreferences } from '../hoc';
import { Input, Button, Form } from '../common/AuthFormItems';
// import { Translation, Trans } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { LoginBody, LoginDivChild, LoginDivImage } from './style';

const LoginInputs = (props) => {
  const { t } = useTranslation();

  const { email, password, onChange } = props;
  return (
    <React.Fragment>
      <Input
        icon="envelope"
        onChange={onChange}
        value={email}
        name="email"
        label="Email"
        placeholder={t('Email')}
        required
      />
      <Input
        icon="lock"
        onChange={onChange}
        type="password"
        value={password}
        name="password"
        label="Password"
        placeholder={t('Password')}
        required
      />
    </React.Fragment>
  );
};

const LoginActionButtons = (props) => {
  const { isLoading } = props;
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <p className="font-small blue-text d-flex justify-content-end">
        <Link to="/forgot-password" className="blue-text ml-1">
          {t('Forgot Password')}
        </Link>
      </p>
      <Button type="submit" name={t('Sign in')} isLoading={isLoading} />
    </React.Fragment>
  );
};

const Banner = styled.div`
  padding: 5px;
  text-align: center;
  font-size: 14px;
  color: #4f81bd;
  background-color: #d3d3d3;
`;

class Login extends Component {
  state = {};

  onSubmit = (e, redirectPath) => {
    e.preventDefault();
    const {
      account: { isLoading },
      login,
    } = this.props;
    if (isLoading) return;
    // comment out the login function because we are now using 2 F.A authentication
    login(
      { ...this.state, browser_id: localStorage.getItem('browserId') },
      redirectPath,
    );
  };

  onChange = ({ target: { name, value } }) => {
    this.setState({ [name]: value });
  };

  render() {
    const {
      tr,
      account: { isLoading },
    } = this.props;
    const params = new URLSearchParams(window.location.search);
    const encodedPathname = params.get('path');
    const decodedPathname = decodeURIComponent(encodedPathname);
    const sessionExpired = params.get('sessionExpired');

    return (
      <>
        {sessionExpired && (
          <Banner>
            {tr('Your session has expired. If you wish, please log in again.')}
          </Banner>
        )}
        <LoginBody>
          <LoginDivImage></LoginDivImage>

          <LoginDivChild>
            <Form
              title={tr('Login')}
              onSubmit={(e) => {
                this.onSubmit(e, decodedPathname);
              }}
              render={() => <LoginInputs {...this.state} onChange={this.onChange} />}
              renderActionButtons={() => (
                <LoginActionButtons isLoading={isLoading} tr={tr} />
              )}
            />
          </LoginDivChild>
        </LoginBody>
      </>
    );
  }
}
export default compose(withAccount, withTranslation, withUserPreferences)(Login);
