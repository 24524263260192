/* eslint-disable camelcase */

export const transactionPosting = (values, formMode) => {
  const {
    description,
    currency,
    contract,
    contract_item,
    date,
    person,
    org_indicator,
    partner,
    transaction: transaction_id,
    ...transaction_items_data
  } = values;

  const {
    amount,
    amount_sc,
    asset_financial_account,
    expense_financial_account,
    asset_account_category,
    expense_account_category,
    position_link,
  } = transaction_items_data;

  return {
    description,
    contract,
    contract_item,
    date,
    person,
    org_indicator,
    partner,
    transaction_id,
    transaction_items: [
      {
        amount: formMode === 'update' ? parseFloat(amount) * -1 : amount,
        amount_sc: formMode === 'update' ? parseFloat(amount_sc) * -1 : amount_sc,
        financial_account: asset_financial_account,
        dc_indicator: 'C',
        currency,
        account_category: ['payable', 'credit'].includes(asset_financial_account)
          ? 'liability'
          : asset_account_category,
        line: 1,
        position_link: asset_financial_account === 'cash' ? currency : position_link,
      },
      {
        amount: formMode === 'update' ? parseFloat(amount) * -1 : amount,
        amount_sc: formMode === 'update' ? parseFloat(amount_sc) * -1 : amount_sc,
        financial_account: expense_financial_account,
        dc_indicator: 'D',
        account_category: expense_account_category,
        currency,
        line: 2,
      },
    ],
  };
};

export const getTransactionValuesFromPayload = (values) => {
  const { transaction_item, ...rest } = values;
  return {
    ...rest,
    ...transaction_item?.[0],
    amount: Math.abs(transaction_item?.[0].amount),
    amount_sc: Math.abs(transaction_item?.[0].amount_sc),
    asset_financial_account: transaction_item?.[0]?.financial_account,
    expense_financial_account: transaction_item?.[1]?.financial_account,
    asset_account_category: transaction_item?.[0]?.account_category,
    expense_account_category: transaction_item?.[1]?.account_category,
  };
};
