import Disclaimer from './Disclaimer';
import UserTerms from './UserTerms';
import PrivacyStatement from './PrivacyStatement';
import Faq from './Faq';
import { withAuthentication, withNoAuthentication } from 'components/hoc';


const path = '';

export default  [
  {
    name: 'Disclaimer',
    path:  `${path}/disclaimers`,
    component: Disclaimer,
  },

  {
    name: 'User Terms',
    path: `${path}/user-terms`,
    component: UserTerms,
  },

  {
    name: 'PrivacyStatement',
    path: `${path}/privacy-statement`,
    component: PrivacyStatement,
  },

  {
    name: 'Faq',
    path: `faq/`,
    // icon: '',
    component: Faq,
  },
  
];


export const redirects = [
  {
    from: `${path}`,
    to: `${path}/home`,
  },
  {
    from: `${path}/*`,
    to: `${path}/home`,
  },
];

