import * as types from "../../actions/types";

export const initialState = {
  data: {
    join: "",
    description: "",
    tableName: "",
    fieldName: "",
    lines: [],
  },
  isLoading: false,
  showForm: false,
  error: null,
};

const criteriaSetValue = (state=initialState, action={}) => { 
  switch(action.type){ 
    case types.CRITERIA_SET_VALUE:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
        isLoading: false,
      };
    case types.CRITERIA_SET_VALUE_FORM:
      return { ...state, showForm: action.payload };
    case types.CRITERIA_SET_VALUE_LOADING:
      return { ...state, isLoading: action.payload };
    default:
      return state
  }
}

export default { criteriaSetValue  };

