import React from "react";
import compose from "lodash/fp/compose";
import { connect } from "react-redux";
import hoistNonReactStatics from 'hoist-non-react-statics'
import * as diseaseHistoryActions from "actions/dashboard/diseaseHistory"
import { BaseHOC, withBaseHOCProps } from "../common/BaseHOC"

// let loaded = false
const initialState = {
  loaded: false,
}

export const withDiseaseHistory = (WrappedComponent) => {
  class DiseaseHistory extends BaseHOC {

    state = { ...initialState };

    async componentDidMount() {
      const { getDiseaseHistory } = this.props
      if (!this.state.loaded)  {
        
        await getDiseaseHistory();
        this.setState({ ...initialState, loaded: true });
        // loaded = true;
      }
    }

    deleteDiseaseHistoryById = async (data) => {
      const { deleteDiseaseHistoryById } = this.props
      await this._deleteById(deleteDiseaseHistoryById, data);
    }

    deleteBulkDiseaseHistory = async (data) => {
      const { deleteBulkDiseaseHistory } = this.props
      await this._deleteBulk(deleteBulkDiseaseHistory, data);
    }

    render() {
      return (
        <WrappedComponent 
          {...this.props} 
          loaded={this.state.loaded}
          deleteDiseaseHistoryById={this.deleteDiseaseHistoryById}
          deleteBulkDiseaseHistory={this.deleteBulkDiseaseHistory}
        />
      )
    }
  }
  const mapStateToProps = ({ diseaseHistory }) => ({ diseaseHistory })
  
  const WithDiseaseHistory = connect(mapStateToProps, diseaseHistoryActions)
  (DiseaseHistory);
  hoistNonReactStatics(WithDiseaseHistory, WrappedComponent)
  return compose(withBaseHOCProps)(WithDiseaseHistory);
}
