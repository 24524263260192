import { getNoificationsService, dashboardSummaryService } from '../services';

import {
  FailedApiCall,
  notifications,
  dashboardSummary,
  initializingApi,
} from '../slices';

export const dashboardSummaryThunk = () => async (dispatch) => {
  try {
    dispatch(initializingApi());

    const { data } = await dashboardSummaryService();

    dispatch(dashboardSummary(data));
  } catch (error) {
    dispatch(FailedApiCall());
  }
};

export const getNotificationsThunk = () => async (dispatch) => {
  try {
    dispatch(initializingApi());
    const data = await getNoificationsService();

    dispatch(notifications(data));
  } catch (error) {
    dispatch(FailedApiCall());
  }
};
