import React, { Component }  from 'react';
import styled from "styled-components";


const ImgDiv = styled.img`
    width:100%;
`


const Img = (props) =>{
    const {src, alt} = props
    return <ImgDiv
                src={src}
                alt={alt}
            />
}

export default Img