/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import routes, { redirects } from './routes';
import { MyDataDashboardTemplate } from '../common/MyDataDashboardTemplate';
import DocumentWrapper from '../common/DocumentWrapper';
import { withTranslation } from '../../../hoc';

const Finance = (props) => {
  return (
    <DocumentWrapper formTitle="Finance">
      <MyDataDashboardTemplate
        {...props}
        showSideBar
        routes={routes}
        redirects={redirects}
        render={(renderPropsData) => (
          <MyDataDashboardTemplate.Main {...renderPropsData} card={false} />
        )}
      />
    </DocumentWrapper>
  );
};

export default withTranslation(Finance);
