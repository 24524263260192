import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  overflow: hidden;
  padding-bottom: ${(props) => props.paddingBottom || '10px'};
  padding-top: ${(props) => props.paddingTop || '10px'};
  padding-left: ${(props) => props.paddingLeft || '10px'};
  padding-right: ${(props) => props.paddingRight || '10px'};
  margin-top: ${(props) => props.marginTop || '0%'};
  margin-bottom: ${(props) => props.marginBottom || '0%'};
  margin-left: ${(props) => props.marginLeft || '0%'};
  margin-right: ${(props) => props.marginRight || '0%'};
  width: ${(props) => props.width || '300px'};
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05), 0 0px 40px rgba(0, 0, 0, 0.08);
  border-radius: ${(props) => props.borderRadius || '5px'};
  // background: #ffffff;
  background: var(--admincat-color-grey-1);
`;

const HeaderWrapper = styled('div')`
  margin-top: 2%;
  margin-bottom: 2%;
  display: flex;
  justify-content: space-between;
`;

const Card = (props) => {
  const { cardHeader, rightChild } = props;

  return (
    <>
      <HeaderWrapper>
        {cardHeader}
        {rightChild}
      </HeaderWrapper>
      <Wrapper {...props}>{props.children}</Wrapper>
    </>
  );
};

export default Card;
