import React from "react";
import compose from "lodash/fp/compose";
import { connect } from "react-redux";
import hoistNonReactStatics from 'hoist-non-react-statics'
import { getStudyField } from "actions/preload";
import { BaseHOC, withBaseHOCProps } from "../common/BaseHOC"

let loaded = false

export const withStudyField = (WrappedComponent) => {
  class StudyField extends BaseHOC {

    async componentDidMount() {
      const { getStudyField } = this.props
      if (!loaded)  {
        loaded = true;
        await getStudyField();
      }
    }

    get studyFieldOptions() {
      const { studyField } = this.props;
      return this._getOptions(studyField, ['id'], true)
    }

    render() {
      return (
        <WrappedComponent 
          {...this.props} 
          studyFieldOptions={this.studyFieldOptions}
        />
      )
    }
  }
  const mapStateToProps = ({ studyField }) => ({ studyField });
  const WithStudyField = connect(mapStateToProps, { getStudyField })(StudyField);
  hoistNonReactStatics(WithStudyField, WrappedComponent)
  return compose(withBaseHOCProps)(WithStudyField);
}
