import { BaseActionClass } from './common/BaseActionClass';
import { AUTHENTICATION, USER } from './types';
import axios from "../axios";
// import axios from "axios"

const path = '/auth';
const name = 'Authentication';

// TODO: implement below actions here
// - login
// - registration
// - passwork reset

class Authentication extends BaseActionClass {
  constructor() {
    super(path, AUTHENTICATION, name);
  }

  tSlash = '/';

  getUser = (userId) => {
    return async (dispatch) => {
      try {
        const { data } = await axios.get(`${this._baseUrl}/`);
        dispatch({ type: this.createTypes(`GET_${USER}`).success, payload: data });
        return data;
      } catch (err) {
        this._handleError(err, dispatch);
      }
    };
  };


  deleteUserById = (userId) => {
    return async (dispatch) => {
      try {
        // const { data } = await axios.delete(`${this._baseUrl}/${userId}/delete_user/`);
        await axios.post(`${this._baseUrl}/${userId}/delete_user/`);
        dispatch(
          this.setMsgInfo({
            success: true,
            msg: ['Your account is successfully removed'],
          }),
        );
        dispatch({
          type: this.createTypes(`DELETE_${USER}`).success,
          payload: { id: userId },
        });
        return userId;
      } catch (err) {
        this._handleError(err, dispatch);
      }
    };
  };

  deleteBulkUser = (userId) => {
    return async (dispatch) => {
      try {
        // const { data } = await axios.delete(`${this._baseUrl}/${userId}/`);
        const { data } = await axios.post(`${this._baseUrl}/bulk_delete/`, { ids: [userId] });
        dispatch(
          this.setMsgInfo({
            success: true,
            msg: ['Your account is successfully removed'],
          }),
        );
        dispatch({
          type: this.createTypes(`DELETE_${USER}`).success,
          payload: { id: userId },
        });
        return userId;
      } catch (err) {
        this._handleError(err, dispatch);
      }
    };
  };
}

export const { deleteUserById, getUser } = new Authentication();
