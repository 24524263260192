import React from 'react';
import { DropdownMenu } from "./styled";

class BaseDropdown extends React.Component {

  dropdownRef = React.createRef();
  dropdownTriggerRef = React.createRef();

  componentDidMount() {
    document.addEventListener("click", this.hideDropdown)
    this.dropdownRef.style.display = "none";
  }
  componentWillUnmount() {
    document.removeEventListener("click", this.hideDropdown)
  }
  changeDropdownState = () => {
    const { display } = this.dropdownRef.style;
    this.dropdownRef.style.display = display === "none" ? "flex" : "none";
  }
  hideDropdown = (e) => {
    if (!this.dropdownTriggerRef.contains(e.target)) {
      this.dropdownRef.style.display = "none";
    }
  }
  render() { return null }
}

BaseDropdown.DropdownMenu = DropdownMenu;

export default BaseDropdown;
