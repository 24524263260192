// import { createGlobalStyle } from 'styled-components';
// export const DarkModeStyles = createGlobalStyle`
// :root {
// --primary-color: #ffffff;

// --background-color: #000000;
// /* Other CSS variables for dark mode */
// } `;

import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`

:root {
    --admincat-color: #739bcb;
    --admincat-dark-color: #2d5a90;
    --admincat-medium-color: #5e7698;

    &, &.light-mode {
      --admincat-color-grey-0: #fff;
      --admincat-color-grey-1: #fff;
      --admincat-color-grey-2: #000;
      --admincat-color-grey-3: #68696a;
      --admincat-color-grey-4: #f5f7fb;
      --admincat-color-grey-5: #e1e3e8;
      --admincat-color-grey-6: #eaedf1;
      --admincat-color-grey-7: #626467;
      --admincat-color-grey-8: #bbb;
      --admincat-color-grey-9: #e0e5ec;
      --admincat-color-grey-10: #AEABAB;
      --admincat-color-grey-11: #777;
      --admincat-color-grey-12: #2c2d2f;
      --admincat-color-grey-13: #e7eff3;
      --admincat-color-grey-14: #e1e3e8;
      --admincat-color-grey-15: #9fa7b0;
      --admincat-color-grey-16: #DBDEE3;
      --admincat-color-grey-17: #495057;
      --admincat-color-grey-18: #68696A;
      --admincat-color-grey-19: #DBDEE3;
     
        }

        &.dark-mode {
          --admincat-color-grey-0: #000;
          --admincat-color-grey-1: #232c37;
          --admincat-color-grey-2: #fff;
          --admincat-color-grey-3: #d1d5db;
          --admincat-color-grey-4: #000000;
          --admincat-color-grey-5: #000;
          --admincat-color-grey-6: #232c37;
          --admincat-color-grey-7: #fff;
          --admincat-color-grey-8: #000;
          --admincat-color-grey-9: #000000;
          -admincat-color-grey-10: #fff;
          --admincat-color-grey-11: #fff;
          --admincat-color-grey-12: #ffffff;
          --admincat-color-grey-13: #000;
          --admincat-color-grey-14: #232c37;
          --admincat-color-grey-15: #fff;
          --admincat-color-grey-16: #232c37;
          --admincat-color-grey-17: #fff;
          --admincat-color-grey-18: #fff;
          --admincat-color-grey-19: #fff;
     
        }
  }
  
  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  body {
    font-size: 16px;
    font-family: helvetica, sans-serif;
  }
  
  // div.main {
  //   position: relative;
  //   width: 100vw;
  //   height: 100%;
  //   min-height: 90vh;
  // }
  
  div#logo a {
    display: inline-block;
    width: 167px;
    height: 48px;
  }
  
  div#logo a img {
    width: 100%;
    height: 100%;
  }
  
  section.banner-area {
    height: 45vh;
    min-height: 400px;
  }
  
  section.banner-area div.banner-content {
    margin-top: 0px;
  }
  
  button.unset-properties {
    border: none;
    background: transparent;
    outline: none;
    cursor: pointer;
    color: inherit;
  }
  
  div.datepicker-style input {
    background-color: transparent;
    border-bottom: 1px solid #ccc;
    border: 1px solid #ccc;
    border-radius: 0;
    outline: 0;
    height: 2.1rem;
    width: 100%;
    font-size: 1rem;
    box-shadow: none;
    box-sizing: content-box;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    padding-left: 10px;
  }
  div.react-datepicker-popper {
    z-index: 10;
  }
  
  small#passwordHelp {
    display: none;
  }

`;

export default GlobalStyles;
