import styled, {css} from "styled-components";


export const LinkDiv = styled.div`
    a{
        padding: 10px 20px;
        cursor:pointer;
        transition:all ease-in-out 300ms;
        margin:10px;
        border-radius:5px;
        display:inline-block;
    
        ${({disabled}) => disabled && css`
            opacity: 0.5;
        `}
    
        ${({blue}) => blue && css `
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            text-align: center;
            letter-spacing: -1px;
            color: #FFFFFF;
            background: #4d80b8;
            border-radius: 3px;
            min-width: 100px;
            height: 40px;
            display:inline-block;
            width: ${props => props.width ? props.width : 'auto'};
    
            &:hover{
                background:#36679C;
            }
        `}
    
        ${({border}) => border && css `
            border: 1px solid #1F1F1F;
        `}
    
        ${({white}) => white && css `
            backkground: #fff;
            color: #666;
    
            &:hover{
                color: #4d80b8;
            }
        `}
    
        ${({medium}) => medium && css`
            padding: 20px 65px;
        `}
    
        ${({large}) => large && css`
            padding: 20px 115px;
        `}
    
        ${({disabled}) => disabled && css`
            opacity: 0.5;
        `}
    
        @media (max-width:768px) {
            padding:15px 30px;
        }
    }

`