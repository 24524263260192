import React from 'react';

import Slider from "react-slick";
import Paragraph from "../typography/p";
import UserIcon from "../userIcon";
import KarinaImage from "./images/karina.png"
import MarkusImage from "./images/Markus.png"
import { useTranslation } from 'react-i18next';

import {TestimonialDiv} from "./style"

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


export default function Testimonial() {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        autoplay: true,
        slidesToShow: 1,

      };
      const { t, i18n } = useTranslation();
    return (
        <TestimonialDiv>
            <div className="desktop">
                <div className="testimonial-card">
                    <Paragraph margin="10px" color="#000">
                    {
                        t("My paperwork was always a challenge. Now I come here and find my documents in a few seconds. You are sooo smart!")
                    }
                    </Paragraph>
                    <UserIcon name="Karina" src={KarinaImage}/> 
                </div>
                <div className="testimonial-card" >
                    <Paragraph  margin="10px"  color="#000" >
                    {t("AdminCat is a great help to organize contracts and personal finances in general. And I like the dashboard a lot.")}
                    </Paragraph>
                    <UserIcon name="Markus" src={MarkusImage}/> 
                </div>
            </div>

            <div className="mobile">
                <Slider {...settings}>
                    <div >
                        <Paragraph margin="10px" color="#000" >
                        {
                        t("My paperwork was always a challenge. Now I come here and find my documents in a few seconds. You are sooo smart!")
                        }
                        </Paragraph>
                        <UserIcon name="Karina" src={KarinaImage}/> 
                    </div>
                    <div >
                        <Paragraph  margin="10px"  color="#000" >
                        {t("AdminCat is a great help to organize contracts and personal finances in general. And I like the dashboard a lot.")}
                        </Paragraph>
                        <UserIcon name="Markus" src={MarkusImage}/> 
                    </div>
                </Slider>
            </div>
        </TestimonialDiv>
    );
  }