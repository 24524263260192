import React from "react";
import { connect } from "react-redux";
import {
  getSex,
} from "../../../actions";
import hoistNonReactStatics from 'hoist-non-react-statics'

let loaded = false;

export const withSex = (WrapperComponent) => {
  class WithSex extends React.Component {
    componentDidMount() {
      const { getSex } = this.props;
      if (loaded) return;
      loaded = true;
      getSex();
    }
    getSexOptions = () => undefined
    getSexLocaleOptions = () => {
      const { sex } = this.props;
      return sex.map(({ created_at, ...rest }) => ({ ...rest }));
    }
    render() {
      return (
        <WrapperComponent 
          sexOptions={this.getSexOptions()} 
          sexLocaleOptions={this.getSexLocaleOptions()} 
          {...this.props} 
        />
      )
    }
  }
  const mapStateToProps = ({ sex }) => ({ sex })
  const WithSexHOC = connect(mapStateToProps, {
    getSex,
  })(WithSex);
  hoistNonReactStatics(WithSexHOC, WrapperComponent)
  return WithSexHOC
}
