import React from 'react';
import check from 'check-types';
import Validator from 'validatorjs';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { passwordStrength, getDateInstance, dateFields } from 'components/utils';
import i18next from 'i18next';

export const FormValidationContext = React.createContext({
  validateForm: () => {},
  fieldValidationProps: {},
  formProps: {},
  setFormProps: () => {},
});

const getFormSchema = (formSchema) => {
  return Object.entries(formSchema).reduce((accum, [field, data]) => {
    accum[field] = data.validation;
    return accum;
  }, {});
};

const getCustomMessage = (field, message) => {
  message = message || {};
  return Object.entries(message).reduce((accum, [validation, vMessage]) => {
    if (!validation || !vMessage) return accum;
    accum[`${validation}.${field}`] = vMessage;
    return accum;
  }, {});
};

const getFormCustomMessage = (formSchema) => {
  return Object.entries(formSchema).reduce((accum, [field, data]) => {
    const { message } = data;
    if (!message) return accum;
    return { ...accum, ...getCustomMessage(field, message) };
  }, {});
};

const getFieldErrors = (formSchema, errors) => {
  return Object.entries(formSchema).reduce((accum, [field, validate]) => {
    return {
      ...accum,
      [field]: {
        errors: errors[field] || [],
      },
    };
  }, {});
};

const isRequiredField = (validation) => validation.includes('required');

const getFieldValidationProps = (formSchema, errors) => {
  return Object.entries(formSchema).reduce((accum, [field, validation = '']) => {
    const fieldErrors = errors[field];
    accum[field] = {
      required: isRequiredField(validation),
      errors: fieldErrors ? fieldErrors.errors : [],
    };
    return accum;
  }, {});
};

export const withFormValidation = (WrapperComponent) => {
  class WithFormValidation extends React.Component {
    state = { validationErrors: {}, formProps: {} };

    get formSchema() {
      const { validationSchema } = this.props;
      return validationSchema || {};
    }

    get formData() {
      const { formData } = this.props;
      return formData || {};
    }

    setFormProps = (data = {}) => {
      this.setState({
        formProps: { ...this.state.formProps, ...data },
      });
    };

    get formatedFormData() {
      const { formData } = this;
      return Object.entries(formData).reduce((accum, [name, value]) => {
        const fieldValue = dateFields.includes(name)
          ? getDateInstance(value)
          : value;
        accum[name] = fieldValue;
        return accum;
      }, {});
    }

    validateForm =
      (onSubmit = () => {}) =>
      (e = {}) => {
        e.preventDefault && e.preventDefault();

        const { formSchema, formatedFormData } = this;
        if (check.emptyObject(formSchema)) {
          return onSubmit(e);
        }
        const validation = new Validator(
          formatedFormData,
          getFormSchema(formSchema),
          getFormCustomMessage(formSchema),
        );
        const fails = validation.fails();
        const errors = validation.errors.all();
        this.setValidationFields(getFieldErrors(formSchema, errors));
        !fails && onSubmit(e);
      };

    setValidationFields = (errors = {}) => {
      const { validationErrors } = this.state;
      this.setState({
        validationErrors: {
          ...validationErrors,
          ...errors,
        },
      });
    };

    validateField =
      (onChange, fieldType) =>
      ({ target }) => {
        const { formSchema, formatedFormData } = this;
        const { name, value } = target;
        const schema = getFormSchema(formSchema);
        // include prop "fieldType = 'password'" to Input component if you want to validate
        // the field as password to ensure the platform password requirement is met
        if (fieldType === 'password') {
          const ePassword = passwordStrength(value);
          this.setValidationFields({
            [name]: ePassword,
          });
        } else if (schema[name]) {
          const fieldValue = dateFields.includes(name)
            ? getDateInstance(value)
            : value;
          const validation = new Validator(
            { ...formatedFormData, [name]: fieldValue },
            { [name]: schema[name] },
            getCustomMessage(name, formSchema[name].message),
          );
          validation.fails();
          const errors = validation.errors.get(name);
          this.setValidationFields({
            [name]: { errors },
          });
        }
        onChange({ target });
      };
    render() {
      const { validateForm, validateField, state, props } = this;
      const fieldValidationProps = getFieldValidationProps(
        getFormSchema(this.formSchema),
        state.validationErrors,
      );
      return (
        <FormValidationContext.Provider
          value={{
            validateForm,
            validateField,
            fieldValidationProps,
            formProps: state.formProps,
            setFormProps: this.setFormProps,
          }}
        >
          <WrapperComponent {...props} />
        </FormValidationContext.Provider>
      );
    }
  }

  WithFormValidation.defaultProps = {};

  hoistNonReactStatics(WithFormValidation, WrapperComponent);
  return WithFormValidation;
};
