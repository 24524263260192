const common = {
    eng: {
      "std.mydata": "My Data",
      "std.view": "View",
      "std.edit": "Edit",
      "std.delete": "Delete",
    },
    deu: {
      "std.mydata": "Meine Daten",
      "std.view": "Anzeigen",
      "std.edit": "Ändern",
      "std.delete": "Löschen",
    }
}

export default {
  eng: {
    "std.mydata": "My Data",
    "std.firstname": "First Name",
    "std.lastname": "Last Name",
    "std.othernames": "Other Names",
    "std.birthplace": "Birth Place",
    "std.birthcountry": "Birth Country",
    "std.deathdate": "Death Date",
    "std.ethnicgroup": "Ethnic Group",
    "std.sex": "Sex",
    "std.maritalstatus": "Marital Status",
    "std.religion": "Religion",
    "std.eyecolor": "Eye Color",
    "std.heightinfo": "Height Info",
    "std.height": "Height",

    "std.unit": "Unit",
    "std.cancel": "Cancel",
    "std.submit": "Submit",
    "std.name": "Name",
    "std.contactus": "Contact us",
    "std.title": "Title",
    "std.birthdate": "Birth Date",
    "std.country": "Country",
    "std.relatedperson": "Related Person",
    "std.state": "State",
    "std.city": "City or Town",
    "std.localmunicipal": "Local Municipality",
    "std.postalarea": "Postal Area",
    "std.buildname": "Build Name",
    "std.addresstype": "Address Type",
    "std.addresstypeid": "Address Type Identifier",
    "std.streetdir": "Street Direction",
    "std.streetname": "Street Name",
    "std.streetno": "Street Number",
    "std.streetnosuffix": "Street Number Suffix",
    "std.principalindicator": "Principal Indicator",
    "std.validityperiod": "Validity Period",
    "std.ved": `${common["eng"]["std.view"]}/${common["eng"]["std.edit"]}/${common["eng"]["std.delete"]}`,
    "std.view": common["eng"]["std.view"],
    "std.edit": common["eng"]["std.edit"],
    "std.delete": common["eng"]["std.delete"],
    "std.prev": "Prev",
    "std.next": "Next",
    "std.add": "Add",

    "std.change": "Change",
    "std.pdffiles": "PDF files",

    "std.relationpart": "Relation Part",
    "std.relationto": "Relation To",
    "std.validfrom": "Valid From",
    "std.validto": "Valid To",
    "std.relperiod": "Relationship Period",
    "std.contacttype": "Contact Type",
    "std.countrytelcode": "Country Telephone Code",
    "std.areatelcode": "Area Telephone Code",
    "std.telno": "Telephone Number",
    "std.active": "Active",
    "std.deactive": "Deactive",
    "std.address": "Address",
    "std.email": "Email",
    "std.cockpit": "Cockpit",
    "std.fillout": "Fill-Out",
    "std.overview": "Overview",
    "std.upload": "Upload",

    "std.filter": "Filter",
    "std.filterform": "Filter Form",
    "std.issueingspace": "Issuing Space",
    "std.chooseissueingspace": "Choose Issung Space",


    "std.headers": "Headers",
    "std.formdesc": "Description",
    "std.status": "Status",
    "std.uploaddate": "Upload Date",
    "std.emailaddr": "Email Address",
    "std.changepassword": "Change Password",
    "std.changepasswordinfo": "It's a good idea that you choose a more secure password",
    "std.personspaces": "Personal Spaces",
    "std.youareanadminchange": "You are the Administrator - Change",
    "std.datastorage": "Data Storage",
    "std.paymentmethod": "Payment Methods",
    "std.admincloud": "On AdminCat Cloud",
    "std.locally": "Locally",
    "std.users": "Users",
    "std.usertype": "User Type",
    "std.defaults": "Defaults",
    "std.choosefile": "Choose File",
    "std.language": "Language",
    "std.decimalnotation": "Decimal Notation",
    "std.dateformat": "Date Format",
    "std.timeformat": "Time Format",
    "std.timezone": "Timezone",
    "std.username": "Username",
    "std.ctrlrm": "Control Room",
    "std.forms": "Forms",
    "std.settings": "Settings",
    "std.admin": "Admin",
    "std.approved": "Approved",
    "std.pending": "Pending",
    "std.declined": "Declined",
    "std.approvedusers": "Approved Users",
    "std.space": "Space",
    "std.approvedate": "Approved Date",
    "std.browse": "Browse",
    "std.save": "Save",
    "std.ouser": "Organization User",
    "std.fields": "Fields",
    "std.profile": "Profile",
    "std.message": "Message",
    "std.needhelp": "Need help?",
    "std.signout": "Sign out",
    "std.choosetitle": "Choose Title",
    "std.choosebirthcountry": "Choose Birth Country",
    "std.chooseethnicgroup": "Choose Ethnic Group",
    "std.choosesex": "Choose Sex",
    "std.choosemaritalstatus": "Choose Marital Status",
    "std.choosereligion": "Choose Religion",
    "std.chooseeyecolor": "Choose Eye Color",
    "std.chooseheightunit": "Choose Height Unit",
    "std.relationform": "Relation Form",
    "std.addressform": "Address Form",
    "std.residenceform": "Residence Form",
    "std.contactform": "Contact Form",
    "std.chooserelationpart": "Choose Relation Part",
    "std.choosecountry": "Choose Country",
    "std.chooseaddresstype": "Choose Address Type",
    "std.choosestreetdir": "Choose Street Direction",
    "std.choosecontacttype": "Choose Contact Type",
    "std.choosecontacttype": "Choose Contact Type",
    "std.choosecountrytelcode": "Choose Country Telephone Code",
    "std.resident": "Resident",
    "std.residence": "Residence",
    "std.search": "Search",
    "std.myworkspaces": "My Workspaces",
    "std.pendinguserrequests": "Pending User Requests",
    "std.declinedusers": "Declined Users",
    "std.registerdate": "Register Date",
    "std.declineddate": "Declined Date",
    "std.formupload": "Form Upload",
    "std.description": "Description",
    "std.ownsign": "Own Sign",
    "std.creator": "Creator",
    "std.ward": "Ward",

    "std.formcategory": "Form Category",
    "std.chooseformcategory": "Choose Form Category",
    "std.chooselanguage": "Choose Language",
    "std.pdffile": "PDF file(fill-able)",
    "std.draft": "Draft",
    "std.released": "Released",
    "std.archorpersons": "Archor Persons",
    "std.requester": "Requester",
    "std.person": "Person",
    "std.anchorpersons": "Anchor Persons",
    "std.wardinquestion": "Ward in question",
    "std.pendingusers": "Pending Users",
    "std.declinedusers": "Declined Users",

    "std.commentcategorylabel": "Comment category",
    "std.descriptionplaceholder": "Enter description here...",

    "std.contactviaemail": "You may contact me via email for this subject",
    "std.uploadfiledocument": "Upload a screenshot or explanatory documents",

    "std.wishtypelabel": "Wish type (please add details in description)",
    "std.choosewishtype": "Choose wish type",
    "std.validityperiod": "Validity period",

    "std.validperiod": "Valid period",

    "std.category": "Category",
    "std.formdescription": "Form Description",
    "std.position": "Position",
    "std.fieldname": "Field Name",
    "std.fieldtype": "Field Type",
    "std.configured": "Configured",
    "std.clear": "Clear",
    "std.invalidvalid": "Invalid/Valid",

    "std.type": "Type",

    "std.joindescription": "Join Description",
    "std.mainarea": "Main Area",
    "std.criteriasets": "Criteria Sets",

    "std.formusage": "Form Usage",

    "std.joincreator": "Join Creator",

    "std.id": "ID",
    "std.create": "Create",
    "std.customfilter": "Filter",




    "std.commentcategory": "Choose comment category",
    "std.wishtype": "Choose wish type",
    "std.citizen":"Citizen",
    "std.nationality": "Nationality",
    "std.sendmessage": "Send message",

    "std.choosenationality": "Choose nationality",

    "citizenship.citizeninfo":"Citizenship Information",
    "citizenship.title":"Citizenship",
    "citizenship.citizenview":"Citizenship view",
    "citizenship.form":"Citizenship Form",


    "mydocument.header":"My Documents",
    "mydocument.referenceyear":"Reference year",
    "mydocument.issuedate":"Issue date",
    "mydocument.issuingorg":"Issuing Organisation",
    "mydocument.documenttype":"Document Type",
    "mydocument.city":"City/Town",
    "mydocument.concernedperson":"Concerned person",
    "mydocument.documentform":"Document Form",
    "mydocument.documentview":"Document View",
    "mydocument.referencemonth":"Reference Month",
    "mydocument.file":"File",




    "header.home": "Home",
    "header.login": "Login",
    "header.reg": "Register",
    "header.ctrlrm": "Control Room",
    "header.mydata": common["eng"]["std.mydata"],
    "header.mydocuments": "My Documents",
    "header.forms": "Forms",
    "header.settings": "Settings",
    "header.admin": "Admin",

    "home.info": "An Innovative Document Management Tool",
    "home.regnow": "Register Now",

    "login.title": "AdminCat Login",
    "login.username": "Your Username",
    "login.password": "Your Password",
    "login.fgpassword": "Forgot Password?",
    "login.signin": "Sign In",

    "reg.title": "AdminCat Registration",
    "reg.puser": "Private User",
    "reg.ouser": "Organization User",
    "reg.username": "Username",
    "reg.email": "Email",
    "reg.password": "Password",
    "reg.jpspace": "Join Personal Workspace",
    "reg.cpspace": "Create Personal Workspace",
    "reg.jspace": "Join Workspace",
    "reg.cspace": "Create Workspace",
    "reg.agree": "I Agree With the Terms and Conditions",
    "reg.signup": "Sign Up",
    "reg.acc": "Have an account?",
    "reg.login": "Log in",

    "mydata.menu.personal": "Personal",
    "mydata.menu.health": "Health",
    "mydata.menu.finance": "Finance",
    "mydata.menu.contract": "Contract",

    "personal.sidemenu.person": "Person",
    "personal.sidemenu.address": "Address",
    "personal.sidemenu.contact": "Contact",
    "personal.sidemenu.relation": "Relation",

    "mydocument.sidemenu.education": "Education",
    "mydocument.sidemenu.tax": "Taxes",
    "mydocument.sidemenu.houserent": "House and Rent",
    "mydocument.sidemenu.insurance": "Insurance",
    "mydocument.sidemenu.work": "Work",
    "mydocument.sidemenu.mobility": "Mobility",



    "person.title": "Personal Information",
    "person.form.title": "Personal Form",
    "person.view.title": "Personal View",

    "relation.title": "Relation Information",

    "address.title": "Address Information",

    "residence.title": "Residence Information",

    "contact.title": "Contact Information",
    "document.form.title": "Upload Documents",
    "document.title": "Documents List",
    "document.view.title": "Documents List",

    "std.issueingorganisation": "Issuing Organisition",
    "std.city": "City/Town",
    "std.concernperson": "Concerned Person",
    "std.issuedate": "Issuing Date",
    "document.sidemenu.title": "Area",
    "document.sidemenu.personal": "Personal",
    "document.sidemenu.hr": "House & Rent",
    "document.sidemenu.health": "Health",
    "document.sidemenu.insurance": "Insurance",

  },

  deu: {
    "std.mydata": "Meine Daten",
    "std.firstname": "Vorname",
    "std.lastname": "Nachname",
    "std.othernames": "Weitere Namen",
    "std.birthplace": "Geburtsort",
    "std.birthcountry": "Geburtsland",
    "std.deathdate": "Todesdatum",
    "std.ethnicgroup": "Ethnische Gruppe",
    "std.sex": "Geschlecht",
    "std.maritalstatus": "Beziehungsstatus",
    "std.religion": "Religion",
    "std.eyecolor": "Augenfarbe",
    "std.heightinfo": "Größeninformation",
    "std.height": "Größe",
    "std.unit": "Einheit",
    "std.cancel": "Abbrechen",
    "std.submit": "Speichern",
    "std.title": "Titel",
    "std.contactus": "Kontaktieren Sie uns",
    "std.birthdate": "Geburtsdatum",
    "std.country": "Land",
    "std.state": "Bundesland",
    "std.city": "Stadt",
    "std.localmunicipal": "Ortsteil",
    "std.postalarea": "Postleitzahl",
    "std.buildname": "Gebäudename",
    "std.addresstype": "Adressart",
    "std.addresstypeid": "Adressart-Bezeichner",
    "std.streetdir": "Straßenrichtung",
    "std.streetname": "Straße",
    "std.streetno": "Straßennummer",
    "std.streetnosuffix": "Straßennummer-Suffix",
    "std.principalindicator": "Hauptadresse",
    "std.validityperiod": "Gültigkeitszeitraum",
    "std.ved": `${common["deu"]["std.view"]}/${common["deu"]["std.edit"]}/${common["deu"]["std.delete"]}`,
    "std.view": common["deu"]["std.view"],
    "std.edit": common["deu"]["std.edit"],
    "std.delete": common["deu"]["std.delete"],
    "std.prev": "Vorige",
    "std.next": "Nächste",
    "std.add": "Hinzufügen",
    "std.relationpart": "Rolle",
    "std.relationto": "Beziehung zu",
    "std.validfrom": "Gültig von",
    "std.validto": "Gültig bis",
    "std.relperiod": "Beziehungszeitraum",
    "std.relatedperson": "Betreffende",
    "std.contacttype": "Kontaktart",
    "std.countrytelcode": "Ländervorwahl",
    "std.areatelcode": "Vorwahl",
    "std.telno": "Telefonnummer",
    "std.active": "Aktiv",
    "std.deactive": "Inaktiv",
    "std.address": "Adresse",
    "std.email": "Email",
    "std.cockpit": "Cockpit",
    "std.fillout": "Ausfüllen",
    "std.creator": "Ersteller",
    "std.overview": "Übersicht",
    "std.upload": "Hochladen",
    "std.filter": "Filter",
    "std.headers": "Kopf",
    "std.formdesc": "Formular",
    "std.status": "Status",
    "std.uploaddate": "Upload-Datum",
    "std.emailaddr": "Email Adresse",
    "std.changepassword": "Passwort ändern",
    "std.changepasswordinfo": "Wählen Sie bitte ein möglichst sicheres Passwort",
    "std.personspaces": "Private Bereiche",
    "std.youareanadminchange": "Sie sind der Administrator - Ändern",
    "std.datastorage": "Datenspeicher",
    "std.paymentmethod": "Zahlungsmethoden",
    "std.admincloud": "In der AdminCat Cloud",
    "std.users": "Benutzer",
    "std.usertype": "Benutzerart",
    "std.defaults": "Standards",
    "std.locally": "Lokal",
    "std.choosefile": "Datei auswählen",
    "std.language": "Sprache",
    "std.decimalnotation": "Dezimalnotation",
    "std.dateformat": "Datumsformat",
    "std.timeformat": "Zeitformat",
    "std.timezone": "Zeitzone",
    "std.username": "Benutzername",
    "std.ctrlrm": "Kontrollraum",
    "std.forms": "Formulare",
    "std.settings": "Einstellungen",
    "std.admin": "Verwaltung",
    "std.approved": "Genehmigt",
    "std.pending": "Offen",
    "std.declined": "Abgelehnt",
    "std.approvedusers": "Genehmigte Benutzer",
    "std.name": "Benutzername",
    "std.space": "Bereich",
    "std.approvedate": "Zutrittsdatum",
    "std.browse": "Suchen",
    "std.save": "Speichern",
    "std.ouser": "Organisations-Nutzer",
    "std.fields": "Felder",
    "std.profile": "Profil",
    "std.message": "Nachrichten",
    "std.needhelp": "Hilfe",
    "std.signout": "Abmelden",
    "std.choosetitle": "Titel auswählen",
    "std.choosebirthcountry": "Geburtsland auswählen",
    "std.chooseethnicgroup": "Ethnische Gruppe auswählen",
    "std.choosesex": "Geschlecht auswählen",
    "std.choosemaritalstatus": "Beziehungsstatus auswählen",
    "std.choosereligion": "Religion auswählen",
    "std.chooseeyecolor": "Augenfarbe auswählen",
    "std.chooseheightunit": "Einheit auswählen",
    "std.relationform": "Beziehungsdaten",
    "std.addressform": "Adressdaten",
    "std.residenceform": "Wohnsitzdaten",
    "std.contactform": "Kontaktdaten",
    "std.chooserelationpart": "Rolle auswählen",
    "std.choosecountry": "Land auswählen",
    "std.chooseaddresstype": "Adressart auswählen",
    "std.choosestreetdir": "Straßenrichtung auswählen",
    "std.choosecontacttype": "Kontaktart auswählen",
    "std.choosecountrytelcode": "Ländervorwahl auswählen",
    "std.resident": "Bewohner",
    "std.residence": "Wohnsitz",
    "std.search": "Suche",
    "std.myworkspaces": "Meine Bereiche",
    "std.pendinguserrequests": "Offene Nutzeranträge",
    "std.declinedusers": "Abgelehnte Benutzer",
    "std.registerdate": "Antragsdatum",
    "std.declineddate": "Absagedatum",
    "std.description": "Beschreibung",
    "std.ownsign": "Eigenes Zeichen",
    "std.formcategory": "Formularkategorie",
    "std.chooseformcategory": "Formularkategorie auswählen",
    "std.chooselanguage": "Sprache auswählen",
    "std.pdffile": "PDF-Datei (ausfüllbar)",
    "std.draft": "Entwurf",
    "std.released": "Freigegeben",
    "std.archorperson": "Hauptpersonen",
    "std.requester": "Antragsteller",
    "std.wardinquestion": "Betroffener Mündel",
    "std.formupload": "Formular - Upload",
    "std.person": "Person",
    "std.anchorpersons": "Hauptpersonen",
    "std.wardinquestion": "Betroffener Mündel",
    "std.pendingusers": "Offene Nutzeranträge",
    "std.declinedusers": "Abgelehnte Benutzer",


    "std.commentcategorylabel": "Kommentarkategorie",

    "std.commentcategory": "Wählen Sie eine Kommentarkategorie",
    "std.wishtype": "Choose wish type",
    "std.descriptionplaceholder": "Beschreibung hier eingeben",
    "std.contactviaemail": "Sie können mich per Email zu diesem Thema kontaktieren",
    "std.uploadfiledocument": "Laden Sie einen Screenshot oder ein Dokument hoch",

    "std.commentcategorylabel": "Comment category",
    "std.descriptionplaceholder": "Enter description here...",

    "std.contactviaemail": "You may contact me via email for this subject",
    "std.uploadfiledocument": "Upload a screenshot or explanatory documents",

    "std.wishtypelabel": "Wish type (please add details in description)",
    "std.choosewishtype": "Choose wish type",
    "std.validityperiod": "Validity period",



    "std.validperiod": "Gültigkeitsdauer",


    "std.commentcategory": "Choose comment category",
    "std.wishtype": "Choose wish type",
    "std.citizen":"Citizen",
    "std.nationality": "Nationality",
    "std.sendmessage": "Send message",


    "citizenship.title":"Citizenship",
    "citizenship.form":"Staatsbürgerschaftsformular",



    "std.citizen":"Bürger",
    "std.nationality": "Staatsangehörigkeit",
    "std.sendmessage": "Nachricht senden",

    "person.title": "Personal Information",
    "person.form.title": "Personal Form",
    "person.view.title": "Personal View",

    "relation.title": "Relation Information",

    "address.title": "Address Information",

    "residence.title": "Residence Information",

    "contact.title": "Contact Information",
    "document.form.title": "Upload Documents",
    "document.title": "Documents List",
    "document.view.title": "Documents List",

    "std.issueingorganisation": "Organisition",
    "std.city": "City/Town",
    "std.concernperson": "Concerned Person",
    "std.issuedate": "Issuing Date",
    "document.sidemenu.title": "Area",
    "document.sidemenu.personal": "Personal",
    "document.sidemenu.hr": "House & Rent",
    "document.sidemenu.health": "Health",
    "document.sidemenu.insurance": "Insurance",

  },

  deu: {
    "std.mydata": "Meine Daten",
    "std.firstname": "Vorname",
    "std.lastname": "Nachname",
    "std.othernames": "Weitere Namen",
    "std.birthplace": "Geburtsort",
    "std.birthcountry": "Geburtsland",
    "std.deathdate": "Todesdatum",
    "std.ethnicgroup": "Ethnische Gruppe",
    "std.sex": "Geschlecht",
    "std.maritalstatus": "Beziehungsstatus",
    "std.religion": "Religion",
    "std.eyecolor": "Augenfarbe",
    "std.heightinfo": "Größeninformation",
    "std.height": "Größe",
    "std.unit": "Einheit",
    "std.cancel": "Abbrechen",
    "std.submit": "Speichern",
    "std.title": "Titel",
    "std.contactus": "Kontaktieren Sie uns",
    "std.birthdate": "Geburtsdatum",
    "std.country": "Land",
    "std.state": "Bundesland",
    "std.city": "Stadt",
    "std.localmunicipal": "Ortsteil",
    "std.postalarea": "Postleitzahl",
    "std.buildname": "Gebäudename",
    "std.addresstype": "Adressart",
    "std.addresstypeid": "Adressart-Bezeichner",
    "std.streetdir": "Straßenrichtung",
    "std.streetname": "Straße",
    "std.streetno": "Straßennummer",
    "std.streetnosuffix": "Straßennummer-Suffix",
    "std.principalindicator": "Hauptadresse",
    "std.validityperiod": "Gültigkeitszeitraum",
    "std.ved": `${common["deu"]["std.view"]}/${common["deu"]["std.edit"]}/${common["deu"]["std.delete"]}`,
    "std.view": common["deu"]["std.view"],
    "std.edit": common["deu"]["std.edit"],
    "std.delete": common["deu"]["std.delete"],
    "std.prev": "Vorige",
    "std.next": "Nächste",
    "std.add": "Hinzufügen",

    "std.change": "Veränderung",
    "std.pdffiles": "PDF-Dateien",

    "std.relationpart": "Rolle",
    "std.relationto": "Beziehung zu",
    "std.validfrom": "Gültig von",
    "std.validto": "Gültig bis",
    "std.relperiod": "Beziehungszeitraum",
    "std.relatedperson": "Betreffende",
    "std.contacttype": "Kontaktart",
    "std.countrytelcode": "Ländervorwahl",
    "std.areatelcode": "Vorwahl",
    "std.telno": "Telefonnummer",
    "std.active": "Aktiv",
    "std.deactive": "Inaktiv",
    "std.address": "Adresse",
    "std.email": "Email",
    "std.cockpit": "Cockpit",
    "std.fillout": "Ausfüllen",
    "std.creator": "Ersteller",
    "std.overview": "Übersicht",
    "std.upload": "Hochladen",
    "std.filter": "Filter",
    "std.headers": "Kopf",
    "std.formdesc": "Formular",
    "std.status": "Status",
    "std.uploaddate": "Upload-Datum",
    "std.emailaddr": "Email Adresse",
    "std.changepassword": "Passwort ändern",
    "std.changepasswordinfo": "Wählen Sie bitte ein möglichst sicheres Passwort",
    "std.personspaces": "Private Bereiche",
    "std.youareanadminchange": "Sie sind der Administrator - Ändern",
    "std.datastorage": "Datenspeicher",
    "std.paymentmethod": "Zahlungsmethoden",
    "std.admincloud": "In der AdminCat Cloud",
    "std.users": "Benutzer",
    "std.usertype": "Benutzerart",
    "std.defaults": "Standards",
    "std.locally": "Lokal",
    "std.choosefile": "Datei auswählen",
    "std.language": "Sprache",
    "std.decimalnotation": "Dezimalnotation",
    "std.dateformat": "Datumsformat",
    "std.timeformat": "Zeitformat",
    "std.timezone": "Zeitzone",
    "std.username": "Benutzername",
    "std.ctrlrm": "Kontrollraum",
    "std.forms": "Formulare",
    "std.settings": "Einstellungen",
    "std.admin": "Verwaltung",
    "std.approved": "Genehmigt",
    "std.pending": "Offen",
    "std.declined": "Abgelehnt",
    "std.approvedusers": "Genehmigte Benutzer",
    "std.name": "Benutzername",
    "std.space": "Bereich",
    "std.approvedate": "Zutrittsdatum",
    "std.browse": "Suchen",
    "std.save": "Speichern",
    "std.ouser": "Organisations-Nutzer",
    "std.fields": "Felder",
    "std.profile": "Profil",
    "std.message": "Nachrichten",
    "std.needhelp": "Hilfe",
    "std.signout": "Abmelden",
    "std.choosetitle": "Titel auswählen",
    "std.choosebirthcountry": "Geburtsland auswählen",
    "std.chooseethnicgroup": "Ethnische Gruppe auswählen",
    "std.choosesex": "Geschlecht auswählen",
    "std.choosemaritalstatus": "Beziehungsstatus auswählen",
    "std.choosereligion": "Religion auswählen",
    "std.chooseeyecolor": "Augenfarbe auswählen",
    "std.chooseheightunit": "Einheit auswählen",
    "std.relationform": "Beziehungsdaten",
    "std.addressform": "Adressdaten",
    "std.residenceform": "Wohnsitzdaten",
    "std.contactform": "Kontaktdaten",
    "std.chooserelationpart": "Rolle auswählen",
    "std.choosecountry": "Land auswählen",
    "std.chooseaddresstype": "Adressart auswählen",
    "std.choosestreetdir": "Straßenrichtung auswählen",
    "std.choosecontacttype": "Kontaktart auswählen",
    "std.choosecountrytelcode": "Ländervorwahl auswählen",
    "std.resident": "Bewohner",
    "std.residence": "Wohnsitz",
    "std.search": "Suche",
    "std.myworkspaces": "Meine Bereiche",
    "std.pendinguserrequests": "Offene Nutzeranträge",
    "std.declinedusers": "Abgelehnte Benutzer",
    "std.registerdate": "Antragsdatum",
    "std.declineddate": "Absagedatum",
    "std.description": "Beschreibung",
    "std.ownsign": "Eigenes Zeichen",
    "std.formcategory": "Formularkategorie",
    "std.chooseformcategory": "Formularkategorie auswählen",
    "std.chooselanguage": "Sprache auswählen",
    "std.pdffile": "PDF-Datei (ausfüllbar)",
    "std.draft": "Entwurf",
    "std.released": "Freigegeben",
    "std.archorperson": "Hauptpersonen",
    "std.requester": "Antragsteller",
    "std.wardinquestion": "Betroffener Mündel",
    "std.formupload": "Formular - Upload",
    "std.person": "Person",
    "std.anchorpersons": "Hauptpersonen",
    "std.wardinquestion": "Betroffener Mündel",
    "std.pendingusers": "Offene Nutzeranträge",
    "std.declinedusers": "Abgelehnte Benutzer",

    "std.category": "Kategorie",
    "std.formdescription": "Formularbeschreibung",
    "std.position": "Position",
    "std.fieldname": "Feldname",
    "std.fieldtype": "Feldtyp",
    "std.configured": "konfiguriert",

    "std.clear": "Zurücksetzen",
    "std.invalidvalid": "ungültig/gültig",
    "std.type": "Typ",
    "std.create": "erstellen",

    "std.ward": "Mündel",

    "std.filterform": "Filterformular",
    "std.issueingspace": "Ausgabe von Speicherplatz",
    "std.chooseissueingspace": "Wählen Sie den Ausgabebereich",


    "std.joindescription": "Join-Beschreibung",
    "std.mainarea": "Hauptbereich",
    "std.criteriasets": "Kriteriensätze",

    "std.formusage": "Formularnutzung",

    "std.joincreator": "Join-Ersteller",

    "std.id": "ID",
    "std.customfilter": "Filter",


    "std.commentcategorylabel": "Kommentarkategorie",

    "std.commentcategory": "Wählen Sie eine Kommentarkategorie",
    "std.wishtype": "Choose wish type",
    "std.descriptionplaceholder": "Beschreibung hier eingeben",
    "std.contactviaemail": "Sie können mich per Email zu diesem Thema kontaktieren",
    "std.uploadfiledocument": "Laden Sie einen Screenshot oder ein Dokument hoch",

    "std.wishtypelabel": "Wunschart (bitte erklären Sie die Details in der Beschreibung)",
    "std.choosewishtype": "Wählen Sie eine Wunschart",

    "std.citizen":"Bürger",
    "std.nationality": "Staatsangehörigkeit",
    "std.sendmessage": "Nachricht senden",

    "std.validityperiod": "Gültigkeitszeitraum",

    "citizenship.title":"Staatsbürgerschaft",

    "citizenship.citizeninfo":"Informationen zur Staatsbürgerschaft",

    "citizenship.citizenview":"Sicht der Staatsbürgerschaft",

    "std.choosenationality": "Wählen Sie die Nationalität",

    "citizenship.form":"Staatsbürgerschaftsformular",


    "mydocument.header":"Mein Dokument",
    "mydocument.referenceyear":"Bezugsjahr",
    "mydocument.issuedate" : "Ausgabedatum",
    "mydocument.issuingorg" : "Organisation",
    "mydocument.documenttype" : "Dokumentenart",
    "mydocument.city":"Stadt",
    "mydocument.concernedperson":"Betroffene Person",
    "mydocument.documentform":"Dokumentformular",
    "mydocument.documentview":"Dokumentansicht",

    "mydocument.referencemonth":"Bazugsmonat",
    "mydocument.file":"Datei",



    "header.home": "Home",
    "header.login": "Login",
    "header.reg": "Registrieren",
    "header.ctrlrm": "Kontrollraum",
    "header.mydata": common["deu"]["std.mydata"],
    "header.forms": "Formulare",
    "header.settings": "Einstellungen",
    "header.admin": "Verwaltung",

    "home.info": "Ein innovatives Dokumenten-Management-Tool",
    "home.regnow": "Hier registrieren",

    "login.title": "AdminCat Login",
    "login.username": "Dein Benutzername",
    "login.password": "Dein Passwort",
    "login.fgpassword": "Passwort vergessen?",
    "login.signin": "Anmelden",

    "reg.title": "AdminCat Registrierung",
    "reg.puser": "Privat-Nutzer",
    "reg.ouser": "Organisations-Nutzer",
    "reg.username": "Benutzername",
    "reg.email": "Email",
    "reg.password": "Passwort",
    "reg.jpspace": "Trete Privatem Bereich bei",
    "reg.cpspace": "Erstelle Privaten Bereich",
    "reg.jspace": "Trete Workspace bei",
    "reg.cspace": "Erstelle Workspace bei",
    "reg.agree": "Ich stimme den Nutzungsbedingungen zu",
    "reg.signup": "Registrieren",
    "reg.acc": "Haben Sie ein Konto?",
    "reg.login": "Zum Login",

    "mydata.menu.personal": "Personen",
    "mydata.menu.health": "Gesundheit",
    "mydata.menu.finance": "Finanzen",
    "mydata.menu.contract": "Verträge",

    "personal.sidemenu.person": "Person",
    "personal.sidemenu.address": "Adresse",
    "personal.sidemenu.contact": "Kontakt",
    "personal.sidemenu.relation": "Beziehung",


    "mydocument.sidemenu.education": "Bildung",
    "mydocument.sidemenu.tax": "Steuern",
    "mydocument.sidemenu.houserent": "Haus und Miete",
    "mydocument.sidemenu.insurance": "Versicherung",
    "mydocument.sidemenu.work": "Arbeit",
    "mydocument.sidemenu.mobility": "Mobilität",


    "person.title": "Personendaten",
    "person.form.title": "Personen-Eingabe",
    "person.view.title": "Personen-Anzeigen",

    "relation.title": "Beziehungsdaten",

    "address.title": "Adressdaten",

    "residence.title": "Wohnsitzdaten",

    "contact.title": "Kontaktdaten",
  }
}
