import React from 'react';
import { connect } from 'react-redux';
import { getDiseaseTypes } from '../../../actions';
import hoistNonReactStatics from 'hoist-non-react-statics';

let loaded = false;

export const withDiseaseTypes = (WrapperComponent) => {
  class WithDiseaseTypes extends React.Component {
    componentDidMount() {
      const { getDiseaseTypes } = this.props;
      if (loaded) return;
      loaded = true;
      getDiseaseTypes();
    }

    getDiseaseTypesOptions = () => undefined;

    getDiseaseTypesLocaleOptions = () => {
      const { diseaseTypes } = this.props;
      return diseaseTypes.map(({ created_at, ...rest }) => ({ ...rest }));
    };

    render() {
      const { getDiseaseTypes, diseaseTypes, ...rest } = this;
      return (
        <WrapperComponent
          {...this.props}
          diseaseTypesOptions={this.getDiseaseTypesOptions()}
          diseaseTypesLocaleOptions={this.getDiseaseTypesLocaleOptions()}
        />
      );
    }
  }
  const mapStateToProps = ({ diseaseTypes }) => ({ diseaseTypes });
  const WithDiseaseTypesHOC = connect(mapStateToProps, { getDiseaseTypes })(
    WithDiseaseTypes,
  );
  hoistNonReactStatics(WithDiseaseTypesHOC, WrapperComponent);
  return WithDiseaseTypesHOC;
};
