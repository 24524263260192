/* eslint-disable */
import React from 'react';
import compose from 'lodash/fp/compose';
import { useSelector } from 'react-redux';
import {
  withTranslation,
  withUserPreferences,
  withCustomRouter,
  withUserDefaultSpace,
} from 'components/hoc';
import TransactionContentTemplate from '../../common/TransactionContentTemplate';
// import MyDataContentTemplate from '../../common/MyDataContentTemplate';
import { useListTransaction } from '../hooks/useListTransaction';
import { getTransactionItem, getDCIndicator } from '../utils';
import { getFullName } from 'components/utils';
import LottieLoader from 'components/common/LottieLoader';

const Consumption = (props) => {
  const loading = useSelector((state) => state.transaction.loading);
  const data = useListTransaction('expense');
  const { fn, userDefaultSpace } = props;

  const roundToTwoDecimal = (number) => {
    return +(Math.round(number + "e+2") + "e-2")
  }

  const mapFields = () =>
    data?.map((expense) => {
      const transactionItem = getTransactionItem(expense.transaction_item[0], fn);
      return {
        ...expense,
        ...transactionItem,
        space_name: expense.space,
        person: getFullName(expense.transaction_item[1].person_details),
        description: expense.description,
        partner: expense?.partner_detail?.name,
        date: props.fd(expense.date),
        currency: expense.transaction_item[0].currency,
        contract: expense.contract_details?.description,
        amount: fn(expense.transaction_item[1].amount),
        amount_sc: fn(expense.transaction_item[1].amount_sc),
        sp_currency: userDefaultSpace.space_detail.currency,
        dc_indicator: getDCIndicator(transactionItem.dc_indicator),
        org_indicator: expense.org_indicator,
        account_category: props.trObj(
          expense.transaction_item[1].account_category_details,
        ),
        financial_account: props.trObj(
          expense.transaction_item[1].financial_account_details,
        ),
        offset_account_category: props.trObj(
          expense.transaction_item[0].account_category_details,
        ),
        offset_financial_account: props.trObj(
          expense.transaction_item[0].financial_account_details,
        ),
        reversal: expense.reversal ? '\u2713' : '\u2717',
        reversed: expense.reversed ? '\u2713' : '\u2717',
      };
    });

  const tableProps = {
    frontendTableName: 'consumption',
    backendTableName: 'transaction',
  };

  const { gotoCreatePath } = props;

  if (loading)
    return (
      <>
        <LottieLoader />
      </>
    );

  return (
    <TransactionContentTemplate
      {...tableProps}
      columns={[]}
      data={mapFields() || []}
      toggleForm={gotoCreatePath}
      hasBulkDelete={false}
    />
  );
};

export default compose(
  withTranslation,
  withUserPreferences,
  withCustomRouter,
  withUserDefaultSpace,
)(Consumption);
