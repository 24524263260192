import Home from './Home';
import Documents from './Documents';
import WorkspaceSettings from './WorkspaceSetting';
import QuickTour from './QuickTour';
import Settings from './Settings';
import MyData from './MyData';
import ControlRoom from './ControlRoom';
import ContactUs from './ContactUs';
import Mail from './Mail';
import SettingsMakePayment from './Settings/SettingsPayment';
import SpaceAdmin from './Admin';

export const path = '/dashboard';

export default [
  {
    name: 'Contact Us',
    path: `${path}/contact-us`,
    icon: '',
    component: ContactUs,
  },
  {
    name: 'Documents',
    path: `${path}/documents`,
    icon: '',
    component: Documents,
  },
  {
    name: 'Data',
    path: `${path}/data`,
    icon: '',
    component: MyData,
  },
  {
    name: 'SettingsPayment',
    path: `${path}/settings/payments/create`,
    icon: '',
    component: SettingsMakePayment,
  },
  {
    name: 'Space Member',
    path: `${path}/space-admin`,
    icon: '',
    component: SpaceAdmin,
  },
  {
    name: 'Settings',
    path: `${path}/settings`,
    icon: '',
    component: Settings,
  },
  {
    name: 'Control Room',
    path: `${path}/control-room`,
    icon: '',
    component: ControlRoom,
  },
  // {
  //   name: 'Form Studio',
  //   path: `${path}/forms`,
  //   icon: '',
  //   component: Forms,
  // },
  {
    name: 'WorkSpace',
    path: `${path}/workspace`,
    icon: '',
    component: WorkspaceSettings,
  },
  {
    name: 'Mail',
    path: `${path}/mail`,
    icon: '',
    component: Mail,
  },
  {
    name: 'Quick Tour',
    path: `${path}/quick-tour`,
    icon: '',
    component: QuickTour,
  },
  {
    name: 'Dashboard',
    path: `${path}`,
    icon: '',
    component: Home,
  },
];

export const redirects = [
  {
    from: `${path}`,
    to: `${path}`,
  },
  {
    from: `${path}/*`,
    to: `${path}`,
  },
];
