import QuickTour from './QuickTour';

const path = '/dashboard/quick-tour';

export default [
  {
    name: 'QuickTour',
    path: `${path}`,
    icon: '',
    component: QuickTour,
  },
];

export const redirects = [
  {
    from: `${path}`,
    to: `${path}`,
  },
  {
    from: `${path}/*`,
    to: `${path}`,
  },
];
