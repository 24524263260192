import React from 'react';
import ReactDOM from 'react-dom';
import { compose } from 'lodash/fp';
import styled from 'styled-components';
import { withUserLayoutChoice, withTranslation } from 'components/hoc';
import { isEmptyValue } from 'components/utils/checkValue';
import { DnD } from './DnD';
import {
  DnDWrapper,
  Modal,
  StyledButton as Button,
  InnerContainer,
  Title,
} from './styled';
import Icon from 'components/Icon';
import { LayoutThreeColumns, Table } from 'react-bootstrap-icons';
import { Grid1x2Fill } from 'react-bootstrap-icons';

const StyledButton = styled(Button)`
  background: var(--admincat-color-grey-6);
  border-radius: 2px;
  height: 38px;
  color: ${(props) => props.color || 'var(--admincat-color-grey-7)'};
  border: none;
  font-size: 14px;
  line-height: 10px;

  // remove this styles below,
  // if you want to see the full button when resized
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

class DnDModal extends React.Component {
  parent = document.body;
  elem = document.createElement('div');
  items = [];

  state = { loading: false };

  static defaultProps = {
    tr: (v) => v,
  };

  componentDidMount() {
    this.parent.appendChild(this.elem);
  }

  componentWillUnmount() {
    this.parent.removeChild(this.elem);
  }

  setItems = (items) => {
    this.items = items;
  };

  onSubmit = async () => {
    const { tableName, updateUserLayoutChoice, togglePortal } = this.props;
    const user = JSON.parse(localStorage.user);
    try {
      this.setState({ loading: true });
      if (isEmptyValue(this.items)) {
        // togglePortal()
        return;
      }
      const items = this.items.map(({ id, label, ...rest }) => ({
        ...rest,
        frontend_table: tableName,
        user: user.user_id,
      }));

      await updateUserLayoutChoice({
        layout_choice_list: items,
      });
    } finally {
      this.setState({ loading: false });
      this.items = [];
      togglePortal();
    }
  };

  renderChildren = () => {
    const { showPortal, togglePortal, className, columns, tr } = this.props;

    const { loading } = this.state;

    return (
      showPortal && (
        <Modal className={className}>
          <InnerContainer>
            <Title>{tr('Selected Fields')}</Title>
            <DnDWrapper>
              <DnD columns={columns} setItems={this.setItems} tr={tr} />
            </DnDWrapper>
            <div className="card-footer text-right">
              <div className="d-flex">
                <Button
                  type="button"
                  name="Cancel"
                  onClick={togglePortal}
                  className="_btn-link"
                />
                <Button
                  type="button"
                  className="btn-primary"
                  isLoading={loading}
                  onClick={this.onSubmit}
                  name="Save"
                />
              </div>
            </div>
          </InnerContainer>
        </Modal>
      )
    );
  };

  render() {
    return ReactDOM.createPortal(this.renderChildren(), this.elem);
  }
}

class DnDPortalComponent extends React.Component {
  state = { showPortal: false };

  togglePortal = () => {
    const { showPortal } = this.state;
    this.setState({ showPortal: !showPortal });
  };

  render() {
    const { showPortal } = this.state;
    return (
      <>
        <StyledButton
          reactIcon={<Grid1x2Fill color="#4C81BE" size={12} className="mr-2" />}
          name="Layout"
          onClick={this.togglePortal}
        />
        <DnDModal
          {...this.props}
          showPortal={showPortal}
          togglePortal={this.togglePortal}
        />
      </>
    );
  }
}

export const DnDPortal = compose(
  withUserLayoutChoice,
  withTranslation,
)(DnDPortalComponent);
