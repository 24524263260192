import React, { useEffect } from 'react';
import Lottie from 'lottie-react';
import compose from 'lodash/fp/compose';
import { useSelector, useDispatch } from 'react-redux';
import { dashboardSlice } from 'rootStateHandler/selectors';
import animatedLoading from 'assets/lottie/admit-cat-loader.json';
import withFileSize from 'components/hoc/FileSizeChecker/File';
import {
  getNotificationsThunk,
  dashboardSummaryThunk,
} from 'components/Dashboard/thunks';
import Grids from './Elements/Grids';
import styles from './dashboard.module.css';

const Dashboard = (props) => {
  const { totalFileSize } = props;
  const { notifications, dashboard_summary, loading } = useSelector(dashboardSlice);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getNotificationsThunk());
    dispatch(dashboardSummaryThunk());
  }, [dashboard_summary?.user_space]);

  return (
    <>
      {loading || !Boolean(dashboard_summary?.user_space) ? (
        <div className={styles['lottie_loader']}>
          <Lottie loop autoplay animationData={animatedLoading} />
        </div>
      ) : (
        <div>
          <Grids
            storageCounter={totalFileSize}
            dashboard_summary={dashboard_summary}
            notifications={notifications}
          />
        </div>
      )}
    </>
  );
};

export default compose(withFileSize)(Dashboard);
