import React from "react";
import { connect } from "react-redux";
import {
  getAllOrganisationWorkspace,
} from "../../../actions";
import hoistNonReactStatics from 'hoist-non-react-statics'


let loaded = false;

export const withAllOrganisationWorkspace = (WrapperComponent) => {
  class Enhance extends React.Component {
    componentDidMount() {
      const { getAllOrganisationWorkspace } = this.props;
      if (loaded) return;
      loaded = true;
      getAllOrganisationWorkspace();
    }
    getAllOrganisationWorkspaceLocaleOptions = () => undefined
    getAllOrganisationWorkspaceOptions = () => {
      const { allOrganisationWorkspace } = this.props;
      return allOrganisationWorkspace;
    }
    render() {
      return (
        <WrapperComponent 
          allOrganisationWorkspaceOptions={this.getAllOrganisationWorkspaceOptions()} 
          allOrganisationWorkspaceLocaleOptions={this.getAllOrganisationWorkspaceLocaleOptions()} 
          {...this.props} 
        />
      )
    }
  }
  const mapStateToProps = ({ allOrganisationWorkspace }) => ({ allOrganisationWorkspace })
  const WithAllOrganisationWorkspace = connect(mapStateToProps, {
    getAllOrganisationWorkspace,
  })(Enhance);
  hoistNonReactStatics(WithAllOrganisationWorkspace, WrapperComponent)
  return WithAllOrganisationWorkspace
}
