import React from "react";
import { connect } from "react-redux";
import {
  getCountry,
} from "../../../actions";
import hoistNonReactStatics from 'hoist-non-react-statics'


let loaded = false;

export const withCountry = (WrapperComponent) => {
  class WithCountry extends React.Component {
    componentDidMount() {
      const { getCountry } = this.props;
      if (loaded) return;
      loaded = true;
      getCountry();
    }
    
    getCountryOptions = () => undefined
    
    getCountryLocaleOptions = () => {
      const { country } = this.props;
      return country.map(({ created_at, ...rest }) => ({ ...rest }));
    }
    render() {
      return (
        <WrapperComponent 
          countryOptions={this.getCountryOptions()} 
          countryLocaleOptions={this.getCountryLocaleOptions()} 
          {...this.props} 
        />
      )
    }
  }
  const mapStateToProps = ({ country }) => ({ country })
  const WithCountryHOC = connect(mapStateToProps, {
    getCountry,
  })(WithCountry);
  hoistNonReactStatics(WithCountryHOC, WrapperComponent)
  return WithCountryHOC
}
