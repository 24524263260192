import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  getUserOrganisationWorkspace,
} from "actions";
import hoistNonReactStatics from 'hoist-non-react-statics'


export const withUserOrganisationWorkspace = (WrapperComponent) => {
  class WithUserOrganisationWorkspace extends React.Component {
    componentDidMount() {
      const { getUserOrganisationWorkspace } = this.props;
      getUserOrganisationWorkspace();
    }
    getUserOrganisationWorkspaceLocaleOptions = () => undefined
    getUserOrganisationWorkspaceOptions = () => {
      const { userOrganisationWorkspace } = this.props;
      return userOrganisationWorkspace.map(({ created_at, space, ...rest }) => {
        return ({ id: space.space_name, name: space.space_name, ...rest   })
      });
    }
    render() {
      return (
        <WrapperComponent 
          userOrganisationWorkspaceOptions={this.getUserOrganisationWorkspaceOptions()} 
          userOrganisationWorkspaceLocaleOptions={this.getUserOrganisationWorkspaceLocaleOptions()} 
          {...this.props} 
        />
      )
    }
  }
  const mapStateToProps = ({ userOrganisationWorkspace }) => ({ userOrganisationWorkspace })
  const WithUserOrganisationWorkspaceHOC = connect(mapStateToProps, {
    getUserOrganisationWorkspace,
  })(WithUserOrganisationWorkspace);
  hoistNonReactStatics(WithUserOrganisationWorkspaceHOC, WrapperComponent)
  return WithUserOrganisationWorkspaceHOC
}
