import React, { Suspense } from 'react';
import styled from 'styled-components';
import RouteRedirect from 'components/common/RouteRedirect';
import { withAccount } from '../../components/hoc';
import routes, { redirects, path } from './routes';

const StyledRouteRedirect = styled(RouteRedirect)`
  margin-right: 1rem;
`;

const Dashboard = (props) => {
  const { isPaymentNeeded } = props;

  let filteredRoutes;
  let filteredRedirects;
  if (!isPaymentNeeded) {
    // if active plan return all routes
    filteredRoutes = routes;
    filteredRedirects = redirects;
  } else if (isPaymentNeeded) {
    // Limit to only the SettingsPayment route only if the user active plan is set to false
    filteredRoutes = routes.filter((route) => route.name === 'SettingsPayment');
    // If no active, prevent the user from going to any other route except make payment
    filteredRedirects = [
      {
        from: `${path}`,
        to: `${path}/settings/payments/create`,
      },
    ];
  } else {
    filteredRoutes = routes;
    filteredRedirects = redirects;
  }

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <StyledRouteRedirect routes={filteredRoutes} redirects={filteredRedirects} />
    </Suspense>
  );
};

export default withAccount(Dashboard);
