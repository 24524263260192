import { createTypes } from "actions";

const initialState = {
  data: [],
};

export const baseStore = (TYPE) => (state=initialState, action={}) => { 
  switch(action.type){ 

    case createTypes(`GET_${TYPE}`).success: 
      return { 
        ...state, 
        data: action.payload 
      };
      
    case createTypes(`ADD_${TYPE}`).success: 
      return { 
        ...state, 
        data: [ action.payload, ...state.data ], 
    };

    case createTypes(`UPDATE_${TYPE}`).success: 
      return { 
        ...state, 
        data: state.data.map((data) => { 
          if(data.id === action.payload.id) return action.payload;
          return data;
        }), 
    };

    case createTypes(`DELETE_${TYPE}`).success: 
      return { 
        ...state, 
        data: state.data.filter((data) => { 
          return data.id !== action.payload.id;
        }), 
      };

    case createTypes(`DELETE_BULK_${TYPE}`).success: 
      return { 
        data: state.data.filter((data, index) => {
          return !action.payload.includes(data.id);
        })
      };

    case createTypes(`EMPTY_${TYPE}`).success: 
      return { 
          ...state, 
          data: action.payload 
      };
      
    default:
      return state
  }
}

