import { MSGINFO } from "../actions";

export default function msgInfo(state={ success: false, msg: [] }, action={}) {
  switch(action.type){ 
    case MSGINFO:
      return action.payload
    default:
      return state
  }
}

