import axios from '../../../../axios';
import API from './api';

export const updateUserNameService = async ({ id, username, org_id }) => {
  try {
    const { data } = await axios.patch(`${API}/auth/${id}/`, {
      username: username,
      is_active: true,
      is_verified: true,
    });

    const userData = {
      user_id: data.id,
      space_admin: data.is_space_admin,
      space_type: data.space_type,
      org_name: data.organisation,
      org_id: org_id,
      username: data.username,
      email: data.email,
    };
    localStorage.setItem('user', JSON.stringify(userData));

    return data;
  } catch (error) {
    throw error;
  }
};
