import React from 'react';
import compose from 'lodash/fp/compose';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router-dom';
import {
  withTranslation,
  withAdminEntryProposal,
  withCustomRouter,
  withUserPreferences,
  withLanguage,
  withDataArea,
} from '../../../hoc';
import {
  Input,
  Form,
  ButtonLink,
  Select,
  TextArea,
  Button,
} from '../../../common/FormItems';
import { excludeUnsetField } from 'components/utils/excludeUnsetField';
import swal from 'sweetalert';

const Wrapper = styled.div``;

const StyledButtonLink = styled(ButtonLink)`
  span {
    text-transform: uppercase;
  }
`;

const ApproveButton = styled(Button)``;

const RejectButton = styled(Button)`
  background-color: #dc3545;
`;

const AdminEntryProposalActionForm = (props) => {
  const {
    routeParams,
    approveProposal,
    rejectProposal,
    basePath,
    getEntryProposal,
    dataArea,
  } = props;

  const { id: entryProposalId } = routeParams;
  const history = useHistory();
  const location = useLocation();

  const [formData, setFormData] = React.useState({});
  const [formSubmitting, setFormSubmitting] = React.useState(false);
  const [formLoading, setFormLoading] = React.useState(false);

  const fetchData = async () => {
    if (entryProposalId) {
      try {
        setFormLoading(true);
        const res = await getEntryProposal(entryProposalId);
        setFormData({
          ...res,
          long_description_eng: res.language === 'eng' ? res.proposed_entry : '',
          long_description_deu: res.language === 'deu' ? res.proposed_entry : '',
        });
      } finally {
        setFormLoading(false);
      }
    }
  };

  React.useEffect(() => {
    fetchData();
    return () => null;
  }, []);

  const onChange = (e) => {
    if (formSubmitting) return;
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const onApprove = async () => {
    if (formSubmitting) return;
    if (!formData?.long_description_deu || !formData?.long_description_eng) {
      swal('Warning', tr('Please enter german and english translation'), 'warning');
      return;
    }

    try {
      setFormSubmitting(true);
      let payload = {
        id: formData.id,
        long_description_deu: formData?.long_description_deu,
        long_description_eng: formData?.long_description_eng,
      };
      await approveProposal(payload);
      history.push(basePath);
    } catch (e) {
      swal('Error', 'Failed to approve proposal', 'error');
    } finally {
      setFormSubmitting(false);
    }
  };

  const onReject = async () => {
    if (formSubmitting) return;
    try {
      setFormSubmitting(true);
      await rejectProposal(formData.id);
      swal('Success', 'Proposal rejected successfully', 'success');
      history.push(basePath);
    } catch (e) {
      swal('Error', 'Failed to reject proposal', 'error');
    } finally {
      setFormSubmitting(false);
    }
  };

  const renderActionButtons = () => {
    if (!location.pathname.endsWith('/update')) {
      return null;
    }
    return (
      <div>
        <ApproveButton
          onClick={onApprove}
          name={tr('Approve')}
          actionButton
          isLoading={formSubmitting}
          disabled={formSubmitting}
        >
          Approve
        </ApproveButton>
        <RejectButton
          onClick={onReject}
          cancelButton
          name={tr('Reject')}
          isLoading={formSubmitting}
          disabled={formSubmitting}
        >
          Reject
        </RejectButton>
      </div>
    );
  };

  const { tr, activeLanguageOptions } = props;

  return (
    <Wrapper className="form-wrapper">
      <div className="flex">
        <StyledButtonLink
          to={basePath}
          className="option-btn"
          icon="angle double left"
          name="All Entries"
        />
      </div>
      <div className="row">
        <div className="col-7 ml-10 mt-30 container">
          <div
            className="col-7 ml-10 mt-30 container"
            style={{
              position: 'relative',
              background: 'var(--admincat-color-grey-1)',
            }}
          >
            <br />
            <Form
              onSubmit={(e) => e.preventDefault()}
              formData={formData}
              isLoading={formSubmitting}
              loadingData={formLoading}
              title="Admin Entry Proposal"
              renderActionButtons={renderActionButtons}
            >
              <Input
                onChange={onChange}
                value={formData.proposed_entry || ''}
                name="proposed_entry"
                placeholder="Proposed Entry"
                label="Proposed Entry"
                disabled
              />
              <Input
                onChange={onChange}
                value={formData.table || ''}
                name="table"
                label="Table"
                disabled
              />
              <Select
                onChange={onChange}
                value={formData.language || ''}
                name="language"
                label="Language"
                options={activeLanguageOptions}
                disabled
              />
              <Input
                onChange={onChange}
                value={formData.long_description_eng || ''}
                name="long_description_eng"
                placeholder="English Description"
                label="English Description"
                required
              />
              <Input
                onChange={onChange}
                value={formData.long_description_deu || ''}
                name="long_description_deu"
                placeholder="German Description"
                label="German Description"
                required
              />
              <Select
                onChange={onChange}
                value={formData.data_area || ''}
                name="data_area"
                label={tr('Data Area')}
                localeOptions={dataArea}
                disabled
              />
              <Input
                onChange={onChange}
                value={formData.status || ''}
                name="status"
                placeholder="Status"
                label="Status"
                disabled
              />
              <Input
                onChange={onChange}
                value={formData?.creator_info?.username || ''}
                name="creator"
                placeholder="Creator"
                label="Creator"
                disabled
              />
            </Form>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default compose(
  withCustomRouter,
  withTranslation,
  withUserPreferences,
  withAdminEntryProposal,
  withDataArea,
  withLanguage,
)(AdminEntryProposalActionForm);
