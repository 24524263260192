import React, { useState, useEffect } from 'react';
import styled, { keyframes }  from 'styled-components';
import compose from 'lodash/fp/compose';
import ContentTemplate from 'components/common/ContentTemplate';
import { Button, Input } from 'components/common/FormItems';
import { useSelector, useDispatch  } from 'react-redux';
import LottieLoader from 'components/common/LottieLoader';
import {
  withBulkDelete,
  withUserLayoutChoice,
  withTranslation,
} from 'components/hoc';
import { DnDPortal } from 'components/common/FormItems/DnDPortal';
import withFileSize from 'components/hoc/FileSizeChecker/File';
import { TrashFill, Clipboard2, PlusLg, Funnel, ArrowClockwise  } from 'react-bootstrap-icons';
import swal from 'sweetalert';
import FilterModal, { FilterButtonWithBadge, parseDate } from './FilterModal';

const ButtonWrapper = styled.div`
  margin-top: 10px;
`;

const HeaderWrapper = styled.div`
  margin-top: 10px;
  disply: flex;
  justify-content: space-between;
  align-items: center;
  width: 92%;
`;

const StyledButton = styled(Button)`
  background: var(--admincat-color-grey-6);
  border-radius: 2px;
  height: 38px;
  color: ${(props) => props.color || 'var(--admincat-color-grey-7)'};
  border: none;
  font-size: 14px;
  line-height: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

// Create a keyframes animation for the spin effect
const spin = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

// Create a styled component for the spinning icon
const SpinningIcon = styled(ArrowClockwise)`
  animation: ${spin} 1s linear infinite;
`;


export const StyledContentTemplate = styled(ContentTemplate)`
  div.table-responsive {
    height: auto;
    min-height: 70vh;
    max-height: 70vh;
    overflow-y: auto;
    background: var(--admincat-color-grey-1);
    width: auto;
    max-width: 93%;

    @media screen and (max-width: 768px) {
      min-height: 70vh;
      max-height: 70vh;
      overflow-y: auto;
      width: auto;
      background: var(--admincat-color-grey-1);
      max-width: 85%;
    }

    @media screen and (min-width: 1200px) {
      min-height: 70vh;
      max-height: 70vh;
      overflow-y: auto;
      background: var(--admincat-color-grey-1);
      width: auto;
      max-width: 93%;
    }
  }
`;

const MyDataContentTemplate = (props) => {
  const [filters, setFilters] = useState([
    {
      id: Date.now(),
      column: '',
      condition: '',
      value: '',
      additionalValues: { start: [], end: [] },
    },
  ]);
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.bankTransactionExtract);
  // console.log("loading", loading)

  useEffect(() => {
    setFilteredData(props.data);
  }, [props.data]);

  const {
    toggleForm,
    bulkDelete = () => {},
    rowIds,
    hasBulkDelete,
    viewData,
    getLayoutColumnProps,
    tableName,
    frontendTableName: fTableName,
    backendTableName: bTableName,
    columns: defaultColumns,
    useAddModalForm,
    handleCreateForm,
    isFile,
    msg,
    tr,
    viewName = false,
    loadBankTransaction = false,
    bankTransaction = () => {},
    options,
    contract = {},
    setRowDetailToState = () => {},
    executeOnRowClick = () => {},
    filterableColumns = [],
    ...rest
  } = props;

  const frontendTableName = fTableName || tableName;
  const backendTableName = bTableName || tableName;

  const { columns, layoutColumns } = getLayoutColumnProps(
    frontendTableName,
    backendTableName,
    defaultColumns,
  );

  const onAddClicked = handleCreateForm || toggleForm;

  const toggleFilterModal = () => {
    setOpenFilterModal(!openFilterModal);
  };

  const deleteBulk = async () => {
    if (rowIds.length == 0) {
      swal(
        tr('Warning!'),
        tr(`You haven't selected any item to delete.`),
        'warning',
      );
      return;
    }
    try {
      await bulkDelete(rowIds);
    } finally {
      return;
    }
  };

  const viewTimeDependentName = () => {
    if (rowIds.length == 0) {
      swal(tr('Warning!'), tr(`You haven't selected any item to view.`), 'warning');
      return;
    }
  };

  const extractBankTransaction = async () => {
    if (rowIds.length == 0) {
      swal(tr('Warning!'), tr(`You haven't selected any bank to extract the transaction.`), 'warning');
      return;
    }
    try {
      await dispatch(bankTransaction(rowIds)); // Dispatch the action with selected rows
    } catch (error) {
      console.error("Error extracting bank transactions", error);
    }
    // try {
    //   console.log("rowIds", rowIds)
    //   await bankTransaction(rowIds);
    // } finally {
    //   return;
    // }
  };

  const onDataFiltered = (data) => {
    setFilteredData(data);
  };

  const renderFilters = (filterableColumns, columns, onDataFiltered) => {
    return (
      <FilterModal
        tr={tr}
        filters={filters}
        setFilters={setFilters}
        columns={columns}
        data={props.data}
        filterableColumns={filterableColumns}
        onClose={toggleFilterModal}
        onDataFiltered={onDataFiltered}
      />
    );
  };

  return (
    <React.Fragment>
      {msg}
      <HeaderWrapper className="flex">
        <ButtonWrapper className="flex" style={{ marginBottom: '15px' }}>
          {useAddModalForm && (
            <StyledButton
              className="option-btn"
              name="Add"
              reactIcon={<PlusLg color="#0E8436" size={12} className="mr-2" />}
              onClick={() => onAddClicked()}
              disabled={isFile}
            />
          )}
          {hasBulkDelete && (
            <StyledButton
              className="option-btn"
              onClick={deleteBulk}
              reactIcon={<TrashFill color="#F00" size={16} className="mr-3" />}
              name="Delete Marked"
            />
          )}
          <DnDPortal tableName={frontendTableName} columns={layoutColumns} />
          {viewName && (
            <StyledButton
              className="option-btn"
              onClick={viewTimeDependentName}
              reactIcon={<Clipboard2 color="#4C818E" size={16} className="mr-3" />}
              name="View Name"
            />
          )}
          {loadBankTransaction && (
            <StyledButton
              className="option-btn"
              onClick={extractBankTransaction}
              reactIcon={
                loading ? (
                  <SpinningIcon color="#4C818E" size={16} className="mr-3" />
                ) : (
                  <Clipboard2 color="#4C818E" size={16} className="mr-3" />
                )
              }
              // reactIcon={<Clipboard2 color="#4C818E" size={16} className="mr-3" />}
              // name="Extract Bank Transaction"
              name={loading ? "Extracting..." : "Extract Bank Transaction"}
              disabled={loading}
            />
          )}
        </ButtonWrapper>
        {filterableColumns.length > 0 && (
          <FilterButtonWithBadge
            filters={filters}
            onClick={toggleFilterModal}
            tr={tr}
          />
        )}
      </HeaderWrapper>

      {contract?.id && (
        <form className="form-inline" style={{ marginBottom: '20px' }}>
          <div className="form-group">
            <Input
              value={contract?.contractor_detail?.name}
              name="Contractor"
              label={tr('Contractor')}
              placeholder={tr('Contractor')}
              disabled
            />
          </div>
          <div className="form-group mx-sm-3">
            <Input
              value={contract?.id}
              name="contractId"
              label={tr('Contract Id')}
              placeholder={tr('Contract Id')}
              disabled
            />
          </div>
          <div className="form-group mx-sm-3">
            <Input
              value={contract?.description}
              name="description"
              label={tr('Description')}
              placeholder={tr('Description')}
              disabled
            />
          </div>
        </form>
      )}
      

      <StyledContentTemplate
        {...rest}
        columns={columns}
        hasBulkDelete={hasBulkDelete}
        viewData={viewData}
        rowIds={rowIds}
        data={filteredData}
        showDetailOnRowClick
        setRowDetailToState={setRowDetailToState}
        executeOnRowClick={executeOnRowClick}
      />

      {openFilterModal && renderFilters(filterableColumns, columns, onDataFiltered)}
    </React.Fragment>
  );
};

MyDataContentTemplate.defaultProps = {
  useAddModalForm: true,
  handleCreateForm: null,
  frontendTableName: null,
  backendTableName: null,
  columns: [],
  filterableColumns: [],
};

export default compose(
  withBulkDelete,
  withUserLayoutChoice,
  withFileSize,
  withTranslation,
)(MyDataContentTemplate);
