import React from 'react';
import { Line } from 'react-chartjs-2';
import compose from 'lodash/fp/compose';
import styled from 'styled-components';
import { withTranslation, withUserPreferences } from 'components/hoc';
import { Button } from 'components/common/FormItems';

import styles from './chart.module.css';

const IncomeVsExpenditure = ({
  dataList_1_year,
  incomeCategoryList_1_year,
  expenseCategoryList_1_year,
  dataList_3_year,
  incomeCategoryList_3_year,
  expenseCategoryList_3_year,
  dataList_5_year,
  incomeCategoryList_5_year,
  expenseCategoryList_5_year,
  dataList_10_year,
  incomeCategoryList_10_year,
  expenseCategoryList_10_year,
  tr,
  fn,
  xfn,
}) => {
  const [interval, setInterval] = React.useState('year_1');
  const [selectedCategory, setSelectedCategory] = React.useState(
    'Income vs Expenditure',
  );

  const income_expense =
    interval == 'year_1'
      ? dataList_1_year
      : interval == 'year_3'
      ? dataList_3_year
      : interval == 'year_5'
      ? dataList_5_year
      : interval == 'year_10'
      ? dataList_10_year
      : [];

  const icomeList =
    interval == 'year_1'
      ? incomeCategoryList_1_year
      : interval == 'year_3'
      ? incomeCategoryList_3_year
      : interval == 'year_5'
      ? incomeCategoryList_5_year
      : interval == 'year_10'
      ? incomeCategoryList_10_year
      : [];

  const expenseList =
    interval == 'year_1'
      ? expenseCategoryList_1_year
      : interval == 'year_3'
      ? expenseCategoryList_3_year
      : interval == 'year_5'
      ? expenseCategoryList_5_year
      : interval == 'year_10'
      ? expenseCategoryList_10_year
      : [];

  const chartData = {
    options: {
      title: {
        display: true,
        fontSize: 20,
      },

      plugins: {
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            // This more specific font property overrides the global property
            font: {
              size: 14,
            },
          },
        },
      },
    },
  };

  const data = {
    labels: income_expense?.map((data) => data?.date),

    datasets: [
      {
        label: tr('Income'),
        type: 'line',
        borderColor: 'rgba(54, 162, 235, 1)',
        backgroundColor: 'rgba(54, 162, 235, 0.2)',
        data: income_expense?.map((p) => p?.income?.toFixed(2)) || [],
        fill: false,
      },
      {
        label: tr('Expenditure'),
        type: 'line',
        borderColor: '#8e5ea2',
        backgroundColor: '#8e5ea2',
        data: income_expense?.map((p) => p?.expense?.toFixed(2)) || [],
        fill: false,
      },
    ],
  };

  let data_ =
    selectedCategory == 'Income vs Expenditure'
      ? data
      : selectedCategory == 'Income'
      ? icomeList
      : expenseList;

  return (
    <div>
      <div className={styles['interval_btn_container']}>
        <select
          value={interval}
          onChange={({ target }) => setInterval(target.value)}
          style={{
            height: 38,
            transition: 'all ease-in-out 300ms',
            borderRadius: 2,
            background: '#d5e4f7',
            color: '#4576b3',
            fontSize: 14,
            overflow: 'hidden',
            fontWeight: 500,
            padding: 10,
            border: 0,
          }}
        >
          <option value="year_1">{tr('1 year')}</option>
          <option value="year_3">{tr('3 years')}</option>
          <option value="year_5">{tr('5 years')}</option>
          <option value="year_10">{tr('10 years')}</option>
        </select>

        <select
          value={selectedCategory}
          onChange={({ target }) => setSelectedCategory(target.value)}
          style={{
            height: 38,
            transition: 'all ease-in-out 300ms',
            borderRadius: 2,
            background: '#d5e4f7',
            color: '#4576b3',
            fontSize: 14,
            overflow: 'hidden',
            fontWeight: 500,
            padding: 10,
            border: 0,
          }}
        >
          <option value="Income vs Expenditure">
            {tr('Income vs Expenditure')}
          </option>

          <option value="Income">{tr('Income')}</option>

          <option value="Expenditure">{tr('Expenditure')}</option>
        </select>
      </div>

      <Line data={data_} options={chartData} height={110} />
    </div>
  );
};

export default compose(withTranslation, withUserPreferences)(IncomeVsExpenditure);
