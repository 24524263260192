import React from "react";
import styled from "styled-components";


const CircleDiv = styled.div`
    width: 50px;
    height:50px;
    background: ${props => props.background ? props.background : 'rgba(252, 92, 101, 0.06)'};
    color: ${props => props.color ? props.color : '#FC5C65'};
    border-radius:50%;
    display:flex;
    justify-content:center;
    align-items:center;
    margin:auto;
    margin-bottom: 35px;
`

const Circle = ({background, color})=>{
    return <CircleDiv background={background} color={color}><i className="fas fa-circle"></i></CircleDiv>
}

export default Circle