import React from "react";
import compose from "lodash/fp/compose";
import { connect } from "react-redux";
import hoistNonReactStatics from 'hoist-non-react-statics'
import * as spaceActions from "actions/space"
import { BaseHOC, withBaseHOCProps } from "../common/BaseHOC"

let loaded = false

export const withSpace = (WrapperComponent) => {
  class Space extends BaseHOC {

    async componentDidMount() {
      const { getSpace, getSpaceMember } = this.props
      if (!loaded)  {
        loaded = true;
        await Promise.all([
          getSpace(),
          getSpaceMember(),
        ]);
      }
    }

    get spaceOptions() {
      const { space } = this.props;
      return space.data.map((space) => {
        return {
          id: space.id,
          name: space.name
        }
      });
    }

    deleteSpaceById = async (data) => {
      const { deleteSpaceById } = this.props
      await this._deleteById(deleteSpaceById, data);
    }

    deleteBulkSpace = async (data) => {
      const { deleteBulkSpace } = this.props
      await this._deleteBulk(deleteBulkSpace, data);
    }

    render() {
      return (
        <WrapperComponent 
          {...this.props} 
          deleteSpaceById={this.deleteSpaceById}
          deleteBulkSpace={this.deleteBulkSpace}
          spaceOptions={this.spaceOptions}
        />
      )
    }
  }
  const mapStateToProps = ({ space, spaceMember }) => ({ space, spaceMember })
  const WithSpace = connect(mapStateToProps, {
    ...spaceActions,
  })(Space);
  hoistNonReactStatics(WithSpace, WrapperComponent)
  return compose(withBaseHOCProps)(WithSpace);
}
