import React from 'react';
import styled from 'styled-components';
import ContactTemplate from '../../common/ContactTemplate';
import routes, { redirects } from './routes';

const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  width: 90%;
  margin: auto;
  background: var(--admincat-color-grey-1);
`;

const ContactUs = () => {
  return (
    <ContactTemplate
      stripeHeaderTitle="Contact Us"
      routes={routes}
      redirects={redirects}
      render={(renderPropsData) => (
        <Wrapper>
          <ContactTemplate.Main {...renderPropsData} hasSideBar={false} />
        </Wrapper>
      )}
    />
  );
};

export default ContactUs;
