import React, { Component }  from 'react';
import styled from "styled-components";
import Img from "../Img"



export const IconDiv = styled.div`
    width: ${props => props.width ? props.width : '50px'};
    height: ${props => props.height ? props.height : '50px'};
    background: ${props => props.background ? props.background : null};
    color: ${props => props.color ? props.color : '#fff'};
    padding:10px;
    border-radius:5px;
    margin:auto;
    margin-right:10px;
    display:flex;
    justify-content:center;
    align-items:center;
    @media only screen and (max-width:750px){
        margin-left:1px;
    }
`


const Icon = props => {
    const {src, alt, color, width, background, ...rest} = props
    return <IconDiv
            color={color}
            width={width}
            background={background}
            {...rest}
    > <Img src={src} alt={alt} /> </IconDiv>
}

export default Icon