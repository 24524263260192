import styled from 'styled-components';

const commonStyle = `
  align-self: center;
  margin: auto 10px;
`;

export const NavMenu = styled.div`
  display: flex;
  width: 100%;
  /* margin:auto; */
  justify-content: space-between;
`;

export const Dropdown = styled.div`
  display: flex;
  ${commonStyle};
`;

export const Logo = styled.img`
  display: block;
  width: 200px;
  height: 100%;
  object-fit: contain;
  ${commonStyle}
  margin: auto;
  margin-left: 20px;

  @media (max-width: 780px) {
    width: 200px !important;
    margin-left: 40%;
    margin-right: 40%;
  }
`;

export const StyledHeader = styled.header`
  display: flex;
  background: var(--color-grey-0);
  height: 80px;
  justify-content: space-between;
  position: relative;
`;

export const Avatar = styled.span`
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
  border-radius: 50%;
  display: inline-block;
  background: #ced4da no-repeat center/cover;
  position: relative;
  text-align: center;
  color: #868e96;
  font-weight: 600;
  vertical-align: bottom;
  font-size: 0.875rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  ${commonStyle}
`;

export const UserName = styled.span`
  display: block;
  text-transform: capitalize;
  color: var(--admincat-color-grey-17);
  align-self: center;
  line-height: 2;
  user-select: none;
`;

export const UserRole = styled.small`
  display: block;
  color: #9aa0ac;
  align-self: center;
`;

export const AvatarSection = styled.div`
  display: ${({ isAuth }) => (isAuth ? 'flex' : 'none')};
  cursor: pointer;
`;

export const Navbar = styled.ul`
  display: flex;
  list-style: none;
  align-items: center;

  a {
    border: solid 1px #1f1f1f;
    padding: 10px 15px;
    border-radius: 3px;
    display: inline-block;
    font-size: 16px;
    min-width: 87px;
    height: 40px;
    line-height: 19px;

    .blue {
      color: #fff;
    }
  }
`;

const NavbarItem = styled.li`
  margin: 0;
  padding: 0 10px;
  margin-top: 3%;
  width: 100%;

  > * {
    display: inline-block;
    padding: 10px 20px; /* Increase padding to allow more space */
    font-size: 16px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.5);
    text-decoration: none;
    border: 1px solid #1f1f1f;
    border-radius: 3px;
    transition: background-color 0.3s ease-in-out;
    white-space: nowrap; /* Prevent text from wrapping */
  }

  > a.active {
    color: #000;
  }

  > a.blue-link {
    color: #fff;
    background: rgb(68, 125, 189);
    border: none;
  }
`;

Navbar.Item = NavbarItem;
