/* eslint-disable */
import axios from '../../axios';
import * as types from '../types';
import { createTypes } from '../createTypes';
import { setMsgInfo } from '../';
import API from '../api';
import filterError from 'actions/common/filterError';
import { getQueryParams } from '../common/getQueryParams';

const getUrl = (path) => API + path;
const getName = (TYPE, name) => {
  const regex = /GET|ADD|UPDATE|DELETE|DELETE_BULK/g;
  return name || TYPE.replace(regex, '').replace(/_/g, ' ');
};

export class BaseActionClass {
  constructor(path, TYPE, name) {
    this._TYPE = TYPE;
    this._name = getName(TYPE, name);
    this._baseUrl = getUrl(path);
  }

  createTypes = createTypes;

  setMsgInfo = setMsgInfo;

  // trailing slash
  tSlash = '';

  //api = axios;

  _add = (payload) => {
    return async (dispatch) => {
      try {
        const { data } = await axios.post(this._baseUrl + this.tSlash, payload);
        dispatch({ type: createTypes(`ADD_${this._TYPE}`).success, payload: data });
        dispatch(
          setMsgInfo({
            success: true,
            msg: [`${this._name} added successfully`],
            navigationCounter: 2,
          }),
        );
        return data;
      } catch (err) {
        this._handleError(err, dispatch);
      }
    };
  };

  _update = (id, payload) => {
    return async (dispatch) => {
      try {
        const { data } = await axios.patch(
          `${this._baseUrl}/${id}${this.tSlash}`,
          payload,
        );
        dispatch({
          type: createTypes(`UPDATE_${this._TYPE}`).success,
          payload: data,
        });
        dispatch(
          setMsgInfo({
            success: true,
            msg: [`${this._name} updated successfully`],
          }),
        );
        return data;
      } catch (err) {
        this._handleError(err, dispatch);
      }
    };
  };

  _deleteById = (id, payload) => {
    return async (dispatch) => {
      try {
        await axios.delete(`${this._baseUrl}/${id}${this.tSlash}`);
        dispatch({ type: createTypes(`DELETE_${this._TYPE}`).success, payload });
        dispatch(
          setMsgInfo({
            success: true,
            msg: [`${this._name} deleted successfully`],
          }),
        );
      } catch (err) {
        this._handleError(err, dispatch, [`${this._name} couldn't successfully`]);
      }
    };
  };

  _getAll = (query) => {
    return async (dispatch) => {
      try {
        const queryParams = getQueryParams(query);
        const { data } = await axios.get(
          `${this._baseUrl}${this.tSlash}${queryParams}`,
        );
        dispatch({ type: createTypes(`GET_${this._TYPE}`).success, payload: data });
        return data;
      } catch (err) {
        this._handleError(err, dispatch);
      }
    };
  };

  _getById = (id) => {
    return async (dispatch) => {
      try {
        const { data } = await axios.get(`${this._baseUrl}/${id}${this.tSlash}`);
        return data;
      } catch (err) {
        this._handleError(err, dispatch);
      }
    };
  };

  _deleteBulk = (payload) => {
    return async (dispatch) => {
      try {
        await axios.post(`${this._baseUrl}/bulk_delete${this.tSlash}`, {
          ids: payload,
        });
        dispatch({
          type: createTypes(`DELETE_BULK_${this._TYPE}`).success,
          payload,
        });
        return payload;
      } catch (err) {
        throw err;
      }
    };
  };

  _handleError = (err, dispatch, msg = null) => {
    const errors = filterError(err);
    dispatch(setMsgInfo({ success: false, msg: msg || errors }));
  };
}
