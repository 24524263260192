import React, { Component } from 'react';
import compose from "lodash/fp/compose";
import ModalInfo from "../ModalInfo";
import { 
  withTranslation,
  withAccount,
} from "../hoc";


import "./index.css";


class VerifyEmail extends Component {

  constructor(props){
    super(props);

    this.state = {
      verified: false,
      isLoading: true,
      regSuccess: false,
      verifyEmail: true,
    }
  }

  componentWillMount(){
    let code = decodeURI(this.props.location.search);
    if(!code){
      this.props.history.push("/login", null);
      return;
    }

    this.props.verifyEmail(code)
      .then(data => {
        this.setState({verified: true, isLoading: false});
      })
      .catch(err => {
        this.setState({isLoading: false});
      });
  }

  render() {

    const {
      verified, 
      regSuccess,
      verifyEmail,
      isLoading} = this.state;

    return (
      <div className="wrapper">
        {
          <ModalInfo 
            title={"Account Activation Information"}
            verified={verified}
            successInfo={"Your account is activated successfully"}
            errorInfo={"Your account could not be activated"}
            // showBtn={false}
            verifyEmail={verifyEmail}
            regSuccess={regSuccess}
          />
        }
      </div>
    );
  }
}

export default compose(
  withAccount,
  withTranslation,
)(VerifyEmail);

