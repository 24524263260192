import React from 'react';
import {
  withMail,
  withUserDefaultSpace,
  withUserPreferences,
  withTranslation,
} from 'components/hoc';
import compose from 'lodash/fp/compose';

const MailRow = (props) => {
  const {
    mail,
    selectCheckbox,
    fd,
    trObj,
    isChecked,
    selectMail,
    isSelectedMail,
    type,
  } = props;

  const selectRow = () => {
    selectMail(mail?.id);
  };

  const { flags } = mail;

  return (
    <>
      <tr className={isSelectedMail ? 'bg-secondary rounded' : ''}>
        <td>
          <input
            type="checkbox"
            checked={isChecked(mail?.id)}
            // className="form-check-input"
            // id="rowcheck{user.id}"
            onChange={(e) => {}}
            onClick={() => selectCheckbox(mail?.id)}
          />
        </td>
        <td
          onClick={selectRow}
          className={
            flags != 'SEEN' && type === 'INBOX'
              ? 'text-truncate font-weight-bold'
              : 'text-truncate'
          }
        >
          {type === 'INBOX' ? mail?.from_mail : mail.to_mail}
        </td>
        <td
          className={
            flags != 'SEEN' && type === 'INBOX'
              ? 'text-truncate font-weight-bold'
              : 'text-truncate'
          }
          onClick={selectRow}
        >
          {mail?.subject}
        </td>
        <td
          className={
            flags != 'SEEN' && type === 'INBOX'
              ? 'text-truncate font-weight-bold'
              : 'text-truncate'
          }
          onClick={selectRow}
        >
          {fd(mail?.created_at)}
        </td>
        <td
          className={
            flags != 'SEEN' && type === 'INBOX'
              ? 'text-truncate font-weight-bold'
              : 'text-truncate'
          }
          onClick={selectRow}
        >
          {trObj(mail?.area_detail)}
        </td>
      </tr>
    </>
  );
};

const MailList = (props) => {
  const {
    mailMessages,
    tr,
    fd,
    trObj,
    selectCheckbox,
    isChecked,
    isMasterChecked,
    selectMail,
    selectedMail,
    type = 'INBOX',
  } = props;

  return (
    <table
      className="table table-striped table-hover"
      style={{ 'table-layout': 'fixed' }}
    >
      <thead style={{ backgroundColor: 'var(--admincat-color-grey-1)' }}>
        <tr>
          <th span={1} style={{ width: '7%' }}>
            <input
              type="checkbox"
              checked={isMasterChecked()}
              id="mastercheck"
              onClick={() => selectCheckbox()}
            />
          </th>
          <th className="font-weight-bold" scope="col">
            {type === 'INBOX' ? tr('Sender') : tr('Receiver')}
          </th>
          <th className="font-weight-bold" scope="col">
            {tr('Subject')}
          </th>
          <th className="font-weight-bold" scope="col">
            {tr('Date')}
          </th>
          <th className="font-weight-bold" scope="col">
            {tr('Data Area')}
          </th>
        </tr>
      </thead>
      <tbody>
        {mailMessages.map((mail) => (
          <MailRow
            key={mail?.id}
            mail={mail}
            fd={fd}
            trObj={trObj}
            selectCheckbox={selectCheckbox}
            isChecked={isChecked}
            selectMail={selectMail}
            isSelectedMail={selectedMail?.id == mail?.id}
            type={type}
          />
        ))}
      </tbody>
    </table>
  );
};

export default compose(
  withMail,
  withUserDefaultSpace,
  withUserPreferences,
  withTranslation,
)(MailList);
