export const degreeOptions = [
  {
    id: 'Y',
    long_description_eng: 'Yes',
    long_description_deu: 'Ja',
  },
  {
    long_description_eng: 'No',
    long_description_deu: 'Nein',
    id: 'N',
  },
  {
    long_description_eng: 'Ongoing',
    long_description_deu: 'Noch laufend',
    id: 'O',
  },
];

export const lastClassOptions = Array.from(Array(14)).map((_, index) => {
  const option = `${index}`;
  return {
    long_description_eng: option,
    long_description_deu: option,
    id: option,
  };
});

export const getViewName = (path) => path.replace(/(-|_)/g, " ")

export const getDegreeAchieaval = (degree_achieval) => {
  switch(degree_achieval){
    case 'Y':
      return 'Yes';
    case 'N':
      return 'No';
    case 'O':
      return 'Ongoing';
    default:
      return '';
  }
}
