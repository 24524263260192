/* eslint-disable */
import React from 'react';
import compose from 'lodash/fp/compose';
import styled from 'styled-components';
import check from 'check-types';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  withCountry,
  withTitle,
  withEthnicGroup,
  withSex,
  withReligion,
  withHeightUnit,
  withMaritalStatus,
  withEyeColor,
  withPersonalInfo,
  withCustomRouter,
  withTranslation,
  withDependentField,
  withTimeDependentName,
  withUserPreferences,
  withLanguage,
  withMsgInfo,
} from 'components/hoc';
import {
  Select,
  Input,
  Form,
  InputDatePicker,
  ButtonLink,
  FileInput,
  Image,
  NewEntriesSelect,
} from 'components/common/FormItems';
import { isEmptyValue } from 'components/utils/checkValue';
import { excludeUnsetField } from 'components/utils/excludeUnsetField';
import axios from '../../../../../axios';
import API from 'actions/api';
import swal from 'sweetalert';
import { RESPONSE_STRINGS } from '../../../../../constants/index';

const Wrapper = styled.div``;

const ImageWrapper = styled.div`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
`;

const DualInputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  > div {
    min-width: 150px;
    flex: 1;
    margin-right: 1rem;

    :last-child {
      margin-right: 0px;
    }
  }
`;

const DualSelectWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  > div {
    min-width: 200px;
    flex: 1;
    margin-right: 10px;

    :last-child {
      margin-right: 0px;
    }
  }
`;

const StyledButtonLink = styled(ButtonLink)`
  span {
    text-transform: uppercase;
  }
`;

const FormAreaDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

const FormFlex = styled.div`
  flex: 1;
  // padding:5px;
  position: relative;
  margin: 5px;

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`;

const personFormSchema = {
  last_name: {
    validation: 'required',
  },
  first_name: {
    validation: 'required',
  },
  date_of_birth: {
    validation: 'required',
  },
};

const PersonalInfoForm = (props) => {
  const {
    addPersonalInfo,
    updatePersonalInfo,
    getPersonalInfoById,
    routeParams,
    addTimeDependentName,
    userPreferences,
    activeLanguageOptions,
    basePath,
    setMsgInfo,
  } = props;

  const history = useHistory();

  const { id: personId } = routeParams;
  const [formData, setFormData] = React.useState({});
  const [formSubmitting, setFormSubmitting] = React.useState(false);
  const [formLoading, setFormLoading] = React.useState(false);
  const [entrySubmitting, setEntrySubmitting] = React.useState(false);
  const [showEntryForm, setShowEntryForm] = React.useState(false);
  const [showReligionEntryForm, setShowReligionEntryForm] = React.useState(false);
  const [entryValues, setEntryValues] = React.useState({
    value: '',
    language: userPreferences.language,
  });

  const dispatch = useDispatch();

  const fetchData = async () => {
    if (personId) {
      try {
        setFormLoading(true);
        const { person_picture, ...rest } = await getPersonalInfoById(personId);
        setFormData({ person_picture: person_picture?.url, ...rest });
      } finally {
        setFormLoading(false);
      }
    }
  };

  const toggleEntryField = () => {
    setShowEntryForm(!showEntryForm);
  };

  const toggleReligionEntryField = () => {
    setShowReligionEntryForm(!showReligionEntryForm);
  };

  React.useEffect(() => {
    fetchData();
    return () => null;
  }, []);

  const onEntryChange = (e) => {
    const { value, name } = e.target;
    setEntryValues({ ...entryValues, [name]: value });
  };

  const validateAndSubmitEntry = async (entryType) => {
    if (!(entryValues.name && entryValues.language)) {
      swal(
        tr('Warning!'),
        tr(`Please ensure all required fields are populated.`),
        'warning',
      );
      return;
    }
    try {
      setEntrySubmitting(true);
      const payload = {
        name: entryValues.name,
        language: entryValues.language,
        entry_type: entryType,
      };
      await axios.post(`${API}/propose/entry/`, payload);
      swal(
        tr('Success!'),
        tr(`${RESPONSE_STRINGS?.ENTRY_RESPONSE_SUCCESS_MESSAGE}`),
        'success',
      );
      setEntryValues({});
      setEntrySubmitting(false);
      setShowEntryForm(false);
      setShowReligionEntryForm(false);
      return;
    } catch (error) {
      setEntrySubmitting(false);
      setShowEntryForm(false);
      setShowReligionEntryForm(false);
    } finally {
      setEntrySubmitting(false);
      setShowEntryForm(false);
      setShowReligionEntryForm(false);
    }
  };

  const onChange = (e) => {
    if (formSubmitting) return;
    const { value, name, files } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onSubmit = async (e) => {
    if (formSubmitting) return;
    let payload = excludeUnsetField(formData);

    if (!payload.height_unit || !payload.height) {
      let { height, height_unit, ...rest } = payload;
      payload = rest;
    }
    const fData = new FormData();

    Object.entries(payload).forEach(([key, value]) => {
      if (key === 'person_picture') {
        // exluding person_picture if it's string or null, meaning no
        // change made to the field
        if (!check.string(value) && !isEmptyValue(value)) {
          fData.append(key, value);
        }
      } else {
        fData.append(key, value);
      }
    });

    try {
      setFormSubmitting(true);
      let result = {};
      if (!formData.id) {
        result = await addPersonalInfo(fData);
        await addTimeDependentName({
          person: result.id,
          last_name: payload.last_name,
          valid_from: result.date_of_birth,
          reason: 'birth',
        });
        // await dispatch(
        //   setMsgInfo({
        //     success: true,
        //     msg: [`${tr('person added successfully')}`],
        //     navigationCounter: 2,
        //   }),
        // );
      } else {
        result = await updatePersonalInfo(formData.id, fData);
      }
      history.push(basePath);
      window.scrollTo(0, 0);
    } catch (e) {
    } finally {
      setFormSubmitting(false);
    }
  };

  const formId = formData.id;
  const { tr, gotoPath } = props;
  const gotoTimeDependent = () => gotoPath(`${formId}/time-dependent-name`);
  const lastNameLabel = !formData.id ? 'Last Name at birth' : 'Last Name';
  const updateMode = !isEmptyValue(formId);
  const LastNameInputField = withDependentField(
    <Input
      value={formData.last_name || ''}
      onChange={onChange}
      name="last_name"
      id="last_name"
      placeholder={lastNameLabel}
      label={lastNameLabel}
      disabled={!!formData.id}
      showRequiredAsterisk
    />,
    { toggleButton: updateMode, onClick: gotoTimeDependent },
  );

  return (
    <Wrapper className="form-wrapper">
      <div className="flex">
        <StyledButtonLink
          to={basePath}
          className="option-btn"
          icon="angle double left"
          name="All Entries"
        />

      </div>
      <div className="row">
        <div
          className="col-7 ml-10 mt-30 container"
          style={{
            position: 'relative',
            background: 'var(--admincat-color-grey-1)',
          }}
        >
          <br />
          <Form
            onSubmit={onSubmit}
            formData={formData}
            validationSchema={personFormSchema}
            isLoading={formSubmitting}
            loadingData={formLoading}
            title={tr('Persons')}
          >
            <FormAreaDiv>
              <FormFlex>
                <ImageWrapper>
                  <Image src={formData.person_picture} />
                </ImageWrapper>
              </FormFlex>
              <FormFlex>
                <FileInput
                  onChange={onChange}
                  // ref={fileRef}
                  value={formData.person_picture}
                  hasError="Error"
                  name="person_picture"
                  label={tr('Person Picture')}
                  accept="image/*"
                />
              </FormFlex>
            </FormAreaDiv>
            <NewEntriesSelect
              value={formData.title || ''}
              name="title"
              label="Title"
              optionDefaultValue={tr('Choose Title')}
              localeOptions={props.titleLocaleOptions}
              onChange={(e) => {
                if (e.target.value == 'proposeValue') {
                  toggleEntryField();
                  return;
                } else {
                  onChange(e);
                }
              }}
              entryDefaultLanguage={entryValues.language}
              entryValue={entryValues.name}
              entryLanguageValue={entryValues.language}
              entryValueName={'name'}
              entryType={tr('Title')}
              entryLanguageName={'language'}
              onEntryChange={onEntryChange}
              onCreateNewEntry={(e) => {
                e.preventDefault();
                validateAndSubmitEntry('title');
              }}
              add={showEntryForm}
              toggleEntryField={toggleEntryField}
              options={activeLanguageOptions}
              isLoading={entrySubmitting}
              closeEntryForm={() => {
                toggleEntryField();
              }}
            />
            <DualInputWrapper>
              <div>{LastNameInputField}</div>
              <Input
                className="mr-md-1"
                value={formData.first_name || ''}
                onChange={onChange}
                name="first_name"
                id="first_name"
                placeholder="First Name"
                label="First Name"
                showRequiredAsterisk
              />
            </DualInputWrapper>

            <DualInputWrapper>
              <Input
                value={formData.other_names || ''}
                onChange={onChange}
                name="other_names"
                id="other_names"
                placeholder="Other Names"
                label="Other Names"
              />
              <InputDatePicker
                className="mr-md-1"
                label="Birth Date"
                value={formData.date_of_birth || ''}
                name="date_of_birth"
                onChange={onChange}
                showRequiredAsterisk
              />
            </DualInputWrapper>

            <DualInputWrapper>
              <Input
                value={formData.birth_place || ''}
                onChange={onChange}
                name="birth_place"
                id="birth_place"
                placeholder="Birth Place"
                label="Birth Place"
              />
              <Select
                optionDefaultValue="Choose Birth Country"
                label="Birth Country"
                value={formData.birth_country || ''}
                name="birth_country"
                localeOptions={props.countryLocaleOptions}
                onChange={onChange}
                deselectable
              />
            </DualInputWrapper>

            <DualSelectWrapper>
              <Select
                optionDefaultValue="Choose Sex"
                label="Sex"
                value={formData.sex || ''}
                name="sex"
                onChange={onChange}
                localeOptions={props.sexLocaleOptions}
                deselectable
                sort={false}
              />
              <Select
                optionDefaultValue="Choose Marital Status"
                label="Marital Status"
                value={formData.marital_status || ''}
                name="marital_status"
                onChange={onChange}
                localeOptions={props.maritalStatusLocaleOptions}
                deselectable
                sort={false}
              />
            </DualSelectWrapper>

            <DualSelectWrapper>
              <NewEntriesSelect
                value={formData.religion || ''}
                name="religion"
                label="Religion"
                optionDefaultValue={tr('Choose Religion')}
                localeOptions={props.religionLocaleOptions}
                onChange={(e) => {
                  if (e.target.value == 'proposeValue') {
                    toggleReligionEntryField();
                    return;
                  } else {
                    onChange(e);
                  }
                }}
                entryDefaultLanguage={entryValues.language}
                entryValue={entryValues.name}
                entryLanguageValue={entryValues.language}
                entryValueName={'religion'}
                entryType={tr('Religion')}
                entryLanguageName={'language'}
                onEntryChange={onEntryChange}
                onCreateNewEntry={(e) => {
                  e.preventDefault();
                  validateAndSubmitEntry('religion');
                }}
                add={showReligionEntryForm}
                toggleEntryField={toggleReligionEntryField}
                options={activeLanguageOptions}
                isLoading={entrySubmitting}
                closeEntryForm={() => {
                  toggleReligionEntryField();
                }}
              />
              <Select
                optionDefaultValue="Choose Eye Color"
                label="Eye Color"
                value={formData.eye_color || ''}
                name="eye_color"
                localeOptions={props.eyeColorLocaleOptions}
                onChange={onChange}
                deselectable
                sort={false}
              />
            </DualSelectWrapper>

            <DualInputWrapper>
              <Input
                placeholder="Height"
                label="Height"
                value={formData.height || ''}
                name="height"
                fieldType="number"
                onChange={onChange}
              />
              <Select
                style={{ marginLeft: '0.5rem' }}
                optionDefaultValue="Choose Height Unit"
                label="Height Unit"
                value={formData.height_unit || ''}
                name="height_unit"
                localeOptions={props.heightUnitLocaleOptions}
                onChange={onChange}
                deselectable
                sort={false}
              />
              <InputDatePicker
                className="ml-sm-1 mr-md-1"
                label="Death Date"
                value={formData.date_of_death}
                name="date_of_death"
                onChange={onChange}
              />
            </DualInputWrapper>
          </Form>
        </div>
      </div>
    </Wrapper>
  );
};

export default compose(
  withPersonalInfo,
  withCountry,
  withTitle,
  withEthnicGroup,
  withSex,
  withReligion,
  withHeightUnit,
  withMaritalStatus,
  withEyeColor,
  withCustomRouter,
  withTranslation,
  withLanguage,
  withUserPreferences,
  withTimeDependentName,
  withMsgInfo,
)(PersonalInfoForm);
