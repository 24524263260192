// import * as types from '../../actions/types';
// import { createTypes } from '../../actions';
// import baseStore from './../utils/baseStore';

// export const initialState = {
//   data: {},
//   showForm: false,
//   error: null,

// };

// export function contractAlert(state = initialState, action = {}) {
//   switch (action.type) {
//     case types.CREATE_CONTRACT_ALERT:
//       return {
//         ...state,
//         data: [action.payload, ...state.data],
//       };

//     case types.GET_CONTRACT_ALERT:
//       return {
//         ...state,
//         data: action.payload,
//       };

//     case types.UPDATE_CONTRACT_ALERT:
//       return {
//         ...state,
//         data: state.data.map((_data) => {
//           if (_data.id === action.payload.id) return action.payload;
//           return _data;
//         }),
//       };

//       default:
//       return state;
//   }
// }



import * as types from '../../actions';

const initialState = {
  data: [],
  showForm: false,
  
};

export const contractAlert = (state = initialState, action={}) => {
  switch (action.type) {
    case types.CREATE_CONTRACT_ALERT:
      return {
        ...state,
        ...action.payload,
      };

    case types.GET_CONTRACT_ALERT:
      return {
        ...state,
        data:action.payload
      };

    case types.GET_SINGLE_CONTRACT:
      return {
        ...state,
        
      };
   
    default:
      return state;
  }
};
