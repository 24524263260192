import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import compose from 'lodash/fp/compose';
import styled from 'styled-components';
import { Icon, Button, Divider, Segment } from 'semantic-ui-react';
import { useForm } from 'hooks/useForm';
import {
  // addOrganisation,
  updateOrgFunctionAssignment,
  addBulkOrgFunctionAssignment,
  addBulkBankBic,
  // addOrgFunctionProposal,
  addBulkOrgFunctionProposal,
} from 'actions';
import { useSchoolType } from 'hooks/useSchoolType';
import { isEmptyValue } from 'components/utils';
import {
  withOrganisation,
  withCountry,
  withTranslation,
  withLegalForm,
  withState,
  withCity,
  withOrganisationFunction,
  withReligion,
  withPublicRespArea,
  withOrganisationMotivation,
  withCurrency,
  withIndustryType,
  withOrgFunctionAssignment,
  // withDependentField,
  withCustomRouter,
  withUserPreferences,
  withLanguage,
  // withOrganisationFunctionProposal,
  withMsgInfo,
} from 'components/hoc';
import {
  // FormModal,
  Input,
  Select,
  FlashMessage,
  // Form,
  ButtonLink,
  OrgForm,
  NewEntriesSelect,
} from 'components/common/FormItems';
import axios from '../../../../../../axios';
import API from '../../../../../../actions/api';
import swal from 'sweetalert';
import { RESPONSE_STRINGS } from 'constants/index';
// import { setMsgInfo } from 'actions';

const FlexReverse = styled.div`
  display: flex;
  justify-content: right;
`;
const Wrapper = styled.div``;

const StyledButtonLink = styled(ButtonLink)`
  span {
    text-transform: uppercase;
  }
`;

const orgFormSchema = {
  name: 'required|min:3',
  org_country: 'required',
  // register_institute: 'required',
  no_of_employees: 'numeric',
  revenue_approx: 'numeric',
  website: 'url',
  // public_resp_area: [{ required_if: ['public', 'true'] }],
};

export const customValidationMessage = {
  'min.name': 'Organisation name must be at least 3 characters',
  'required.name': 'Organisation name is required',
  'required.org_country': 'Organisation country is required',
  // 'required_if.public_resp_area': '',
};

const initialValues = {
  name: '',
  org_country: '',
  state: '',
  legal_form: '',
  register_institute: '',
  register_no: '',
  organisation_category: '',
  legal_personality: true,
  public: false,
  non_profit: false,
  org_function_assignment_list: [{}],
};

const OrgFunctionAssignmentForm = (props) => {
  const {
    religionLocaleOptions,
    basePath,
    getOrgById,
    orgFunctionAssignment: { register_institute_options },
    userPreferences,
    activeLanguageOptions,
    tr,
  } = props;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const dispatch = useDispatch();
  const { schoolOptionTypes } = useSchoolType();
  const [selectedOrganisation, setSelectedOrganisation] = useState({});
  // const [formSubmitting, setFormSubmitting] = useState(false);
  const [formLoading, setFormLoading] = useState(false);

  const [entrySubmitting, setEntrySubmitting] = useState(false);
  const [showEntryForm, setShowEntryForm] = useState(false);
  const [entryValues, setEntryValues] = useState({
    value: '',
    language: userPreferences.language,
  });

  const history = useHistory();
  // console.log('basePath', basePath)

  const { q: orgId } = props.routeQueries;

  const onSubmit = async () => {
    try {
      const { id } = props.routeParams;
      if (id) {
        const payload =
          values.org_function_assignment_list.length > 0
            ? values.org_function_assignment_list[0]
            : {};
        await dispatch(updateOrgFunctionAssignment(id, payload));
        return;
      }

      // await dispatch(
      //   addBulkOrgFunctionAssignment({
      //     org_function_assignment_list: values.org_function_assignment_list
      //       .map(
      //         ({ BIC, organisation_function, school_type, religion, ...rest }) => ({
      //           ...rest,
      //           organisation: orgId,
      //           organisation_function,
      //           ...(organisation_function === 'school' ? { school_type } : {}),
      //           ...(organisation_function === 'religious_inst' ? { religion } : {}),
      //         }),
      //       )
      //       .filter(
      //         ({ organisation_function }) => !isEmptyValue(organisation_function),
      //       ),
      //   }),
      // );


      await dispatch(
        addBulkOrgFunctionProposal({
          org_function_assignment_list: values.org_function_assignment_list
            .map(
              ({ BIC, organisation_function, school_type, religion, ...rest }) => ({
                ...rest,
                organisation: orgId,
                organisation_function,
                ...(organisation_function === 'school' ? { school_type } : {}),
                ...(organisation_function === 'religious_inst' ? { religion } : {}),
              }),
            )
            .filter(
              ({ organisation_function }) => !isEmptyValue(organisation_function),
            ),
        }),
      );

      // await dispatch(
      //   setMsgInfo({
      //     success: true,
      //     msg: [`${tr('Org Function proposal added successfully')}`],
      //     navigationCounter: 1,
      //   }),
      // );

      const bank_bic_list = values.org_function_assignment_list
        .filter(({ organisation_function }) => {
          return organisation_function === 'bank';
        })
        .map(({ organisation_function, ...rest }) => ({ ...rest, org: orgId }));

      if (bank_bic_list.length) {
        await dispatch(addBulkBankBic({ bank_bic_list }));
      }

      setLoading(false);
    } catch (err) {
      setError('Problem creating organisation, please try again');
      setLoading(false);
    }
    history.push(`/dashboard/data/base/org_func_assign?q=${orgId}`);
    window.scrollTo(0, 0);
    // window.location.reload()

  };

  const [orgFunctionAssigError, setOrgFunctionAssigError] = useState('');
  const [owner, setOwner] = useState(false);
  const { handleSubmit, values, errors, touched, handleBlur, handleChange } =
    useForm({
      initialValues,
      validationSchema: orgFormSchema,
      customValidationMessage,
      onSubmit,
    });

  const onChange = (e, index) => {
    const { name, value } = e.target;
    const l_values = [...values.org_function_assignment_list];
    const valueIndex = {
      ...l_values[index],
      [name]: value,
    };
    l_values[index] = valueIndex;
    handleChange({
      target: {
        name: 'org_function_assignment_list',
        value: l_values,
      },
    });
  };


  const toggleEntryField = () => {
    setShowEntryForm(!showEntryForm);
  };

  const onEntryChange = (e) => {
    const { value, name } = e.target;
    setEntryValues({ ...entryValues, [name]: value });
  };

  const validateAndSubmitEntry = async () => {
    if (!(entryValues.name && entryValues.language)) {
      swal(
        tr('Warning!'),
        tr(`Please ensure all required fields are populated.`),
        'warning',
      );
      return;
    }
    try {
      setEntrySubmitting(true);
      const payload = {
        name: entryValues.name,
        language: entryValues.language,
        entry_type: 'org_function_proposal',
      };
      await axios.post(`${API}/propose/entry/`, payload);
      swal(
        tr('Success!'),
        tr(`${RESPONSE_STRINGS?.ENTRY_RESPONSE_SUCCESS_MESSAGE}`),
        'success',
      );
      setEntryValues({});
      setEntrySubmitting(false);
      setShowEntryForm(false);
      return;
    } catch (error) {
      setEntrySubmitting(false);
      setShowEntryForm(false);
    } finally {
      setEntrySubmitting(false);
      setShowEntryForm(false);
    }
  };


  const remove = (index) => {
    const l_values = [...values.org_function_assignment_list];
    if (l_values.length > 1) {
      l_values.splice(index, 1);
      handleChange({
        target: {
          name: 'org_function_assignment_list',
          value: l_values,
        },
      });
    }
  };

  const add = () => {
    const lastValues =
      values.org_function_assignment_list[
        values.org_function_assignment_list.length - 1
      ];
    const organisation_function = lastValues['organisation_function'];
    const school_type = lastValues['school_type'];
    const religion = lastValues['religion'];
    if (!organisation_function) return;
    if (organisation_function === 'school_type' && !school_type) return;
    if (organisation_function === 'religious_inst' && !religion) return;
    orgFunctionAssigError && setOrgFunctionAssigError('');
    handleChange({
      target: {
        name: 'org_function_assignment_list',
        value: [...values.org_function_assignment_list, {}],
      },
    });
  };

  const addBIC = () => {
    // const lastValues = org_function_assignment_list[
    //   org_function_assignment_list.length - 1
    // ]

    orgFunctionAssigError && setOrgFunctionAssigError('');

    handleChange({
      target: {
        name: 'org_function_assignment_list',
        // value: [...org_function_assignment_list,  {}]
        value: [...[], {}],
      },
    });
  };

  const toggleForm = () => {
    const { name, organisation_function, school_type, religion, BIC } =
      values.org_function_assignment_list[
        values.org_function_assignment_list.length - 1
      ];
    if (!organisation_function) {
      setOrgFunctionAssigError('Organization function is required');
      return;
    }

    if (organisation_function === 'school' && !school_type) {
      setOrgFunctionAssigError('School type is required');
      return;
    }

    if (organisation_function === 'religious_inst' && !religion) {
      setOrgFunctionAssigError('Religion is required');
      return;
    }

    if (organisation_function === 'bank' && !BIC) {
      setOrgFunctionAssigError('BIC is required');
      return;
    }

    setOrgFunctionAssigError('');
    //toggleOrgFuncForm();
    onSubmit();
  };

  React.useEffect(() => {
    const { id } = props.routeParams;

    if (id) {
      try {
        setLoading(true);
        axios
          .get(`${API}/organisation-function-assignment/${id}/org_detail/`)
          .then((response) => {
            const { data } = response;
            setOwner(data?.owner);
            handleChange({
              target: {
                name: 'org_function_assignment_list',
                value: [data],
              },
            });
          })
          .catch((e) => {
            setLoading(false);
          });
      } catch (e) {
        setLoading(false);
      }
    }

    const { q: organisationId } = props.routeQueries;
    const org = getOrgById(organisationId);
    setSelectedOrganisation(org);
  }, []);

  React.useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <Wrapper className="form-wrapper">
      <div className="flex">
          <StyledButtonLink
                to={`${basePath}?q=${props.routeQueries.q}`}
                className="option-btn"
                icon="angle double left"
                name="All Entries"
          />
      </div>
      <div className="row">
      <div
        className="col-7 ml-10 mt-30 container"
        style={{
          position: 'relative',
          background: 'var(--admincat-color-grey-1)',
        }}
      >
      <br/>

          <OrgForm
            isLoading={loading}
            loadingData={formLoading}
            title="Organization Function Assignment"
            onSubmit={() => null}
            renderActionButtons={() => null}
            updateMode={false}
            detailMode={false}
            allowUpdate={owner}
          >
            <Input
              placeholder="Organisation"
              label="Organisation"
              value={selectedOrganisation?.name}
              name="organisation"
              id="organisation"
              onChange={handleChange}
              disabled
            />
            {values.org_function_assignment_list.map((values, index) => (
              <Segment key={index}>
                <FlexReverse>
                  <Icon name="cancel" onClick={() => remove(index)} />
                </FlexReverse>
                {/* <Select
                  optionDefaultValue="Choose Organization Function"
                  label="Organization Function"
                  value={values.organisation_function || ''}
                  name="organisation_function"
                  id="organisation_function"
                  localeOptions={props.orgFunctionLocaleOptions}
                  onChange={(e) => onChange(e, index)}
                  required
                  sort={false}
                /> */}

            <NewEntriesSelect
              value={values.organisation_function || ''}
              name="organisation_function"
              label="Organization Function"
              optionDefaultValue={tr('Choose Organization Function')}
              localeOptions={props.orgFunctionLocaleOptions}
              onChange={(e) => {
                if (e.target.value == 'proposeValue') {
                  toggleEntryField();
                  return;
                } else {
                  onChange(e, index);
                }
              }}
              entryDefaultLanguage={entryValues.language}
              entryValue={entryValues.name}
              entryLanguageValue={entryValues.language}
              entryValueName={'name'}
              entryType={tr('Organization Function')}
              entryLanguageName={'language'}
              onEntryChange={onEntryChange}
              onCreateNewEntry={(e) => {
                e.preventDefault();
                validateAndSubmitEntry();
              }}
              add={showEntryForm}
              toggleEntryField={toggleEntryField}
              options={activeLanguageOptions}
              isLoading={entrySubmitting}
              closeEntryForm={() => {
                toggleEntryField();
              }}
            />

                {values.organisation_function === 'school' && (
                  <Select
                    optionDefaultValue="Choose School Type"
                    label=" School Type"
                    value={values.school_type || ''}
                    name="school_type"
                    id="school_type"
                    localeOptions={schoolOptionTypes}
                    onChange={(e) => onChange(e, index)}
                    required
                    sort={false}
                  />
                )}
                {values.organisation_function === 'religious_inst' && (
                  <Select
                    optionDefaultValue="Choose Religion"
                    label="Religion"
                    value={values.religion || ''}
                    name="religion"
                    id="religion"
                    localeOptions={religionLocaleOptions}
                    onChange={(e) => onChange(e, index)}
                    required
                    sort={false}
                  />
                )}
                {/* 
                {values.map((data, index) => {
                      return (
                        <React.Fragment key={index}>
                      
                          {
                            data.organisation_function === "bank" && (
                            <Segment key={index}>
                            <Input 
                              placeholder="BIC"
                              label="BIC"
                              value={data.BIC || ""}
                              name="BIC"
                              id="BIC"
                              onChange={(e) => onChange(e, index)}
                              required
                            /> 
                            <Button onClick={add} icon="plus" type="button" /> 
                            </Segment>
                            )
                          }
                          
                        </React.Fragment>
                      )
                    })} */}

                {values.organisation_function === 'bank' && (
                  <div>
                    <Input
                      placeholder="BIC"
                      label={`BIC ${index + 1}`}
                      value={values.BIC || ''}
                      name="BIC"
                      id="BIC"
                      onChange={(e) => onChange(e, index)}
                      required
                    />
                    <Button onClick={addBIC} icon="plus" type="button" />
                  </div>
                )}
              </Segment>
            ))}
            <Button onClick={add} icon="plus" type="button" />

            <Button onClick={toggleForm} type="button" isLoading={loading}>
              {props.tr('Save')}
            </Button>

            <Divider />
            {orgFunctionAssigError && (
              <FlashMessage hasError={orgFunctionAssigError}>
                {orgFunctionAssigError}
              </FlashMessage>
            )}
          </OrgForm>
        </div>
      </div>
    </Wrapper>
  );
};

export default compose(
  withCountry,
  withState,
  withCity,
  withLegalForm,
  withIndustryType,
  withOrganisation,
  withOrganisationMotivation,
  withPublicRespArea,
  withOrgFunctionAssignment,
  withOrganisationFunction,
  withCurrency,
  withReligion,
  withTranslation,
  withCustomRouter,
  withUserPreferences,
  withLanguage,
  withMsgInfo,
  // withOrganisationFunctionProposal,
)(OrgFunctionAssignmentForm);
