import axios from '../../axios';
import * as types from "../types";
import { setMsgInfo } from "../";
import filterError from "../common/filterError";
import API from "../api";

const baseUrl = API + "/dashboard/comments";

export const addComment = (payload) => { 
  return (async (dispatch) => { 
    dispatch({ type: types.ADD_COMMENT_LOADING });
    try {
      const { data } = await axios.post(baseUrl, payload);
      dispatch({ 
        type: types.ADD_COMMENT_SUCCESS, 
        payload: data,
      });
      return data;
    }
    catch(err) {
      const errors = filterError(err);
      dispatch({ type: types.ADD_COMMENT_FAILURE, payload: errors });
      dispatch(setMsgInfo({
        success: false, 
        msg: errors,
      }));
      throw err;
    }
  });
}

