export const contractValidationConstraints = {
  description: 'required|min:3',
  data_area: 'required',
  continuous: 'required',
  contractor: 'required',
  org_indicator: 'required',
  auto_renewal: [{ required_if: ['continuous', 'true'] }],
  run_time: [{ required_if: ['auto_renewal', 'true'] }, 'numeric'],
  run_time_unit: [{ required_if: ['auto_renewal', 'true'] }],
  start_date: 'required',
  notice_period: [{ required_if: ['continuous', 'true'] }, 'numeric'],
  notice_period_unit: [{ required_if: ['continuous', 'true'] }],
  person: 'required',
  person_is: 'required',
};

export const customContractValidationMessage = {
  'description.required': ' ',
  'required_if.auto_renewal': ' ',
  'required_if.run_time': ' ',
  'required_if.run_time_unit': ' ',
  'required_if.notice_period': ' ',
  'required_if.notice_period_unit': ' ',
};

export const contractItemValidationConstraints = {
  item_description: 'required',
  financial_category: 'required',
  account_category: 'required',
  item_reference: 'required',
  fix_or_var: 'required',
  currency: 'required',
  fix_period_unit: [{ required_if: ['fix_or_var', 'F'] }],
  fix_amount: [{ required_if: ['fix_or_var', 'F'] }, 'numeric'],
  var_service_unit: [{ required_if: ['fix_or_var', 'V'] }],
  var_service_rate: [{ required_if: ['fix_or_var', 'V'] }, 'numeric'],
  counter_reading_e: 'numeric',
  counter_reading_b: [{ required_if: ['fix_or_var', 'V'] }, 'numeric'],
};

export const contactValidationConstraints = {
  person: 'required',
  contact_type: 'required',
  email: 'required',
  website: 'required',
};

// const contactFormSchema = {
//   person: {
//     validation: 'required',
//   },
//   contact_type: {
//     validation: 'required',
//     email: {
//       validation: 'required',
//     },
//     website: {
//       validation: 'required',
//     },
//   },
//   // email: {
//   //   validation: 'required',
//   // },
//   // website: {
//   //   validation: 'required',
//   // },

//   // tel_country_code: {
//   //   validation: 'required',
//   // },
//   // tel_area_code: {
//   //   validation: 'required',
//   // },
//   // tel_number: {
//   //   validation: 'required',
//   // },
//   // address: {
//   //   validation: 'required',
//   // },

//   // landline: {
//   //   validation: 'required',
//   // },
// };
