import * as types from "../actions/types";

const initialState = {
  data: [],
  isLoading: false,
  error: null,
};

export const profiles = (state=initialState, action={}) => { 
  switch(action.type){ 
    case types.GET_PROFILES_SUCCESS: 
      return { ...state, data: action.payload, isLoading: false };
    case types.GET_PROFILES_LOADING: 
      return { ...state, isLoading: true };
    case types.GET_PROFILES_FAILURE: 
      return { ...state, isLoading: false, error: action.payload };
    default:
      return state
  }
}

