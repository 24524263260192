import React, { useState, useEffect } from 'react';
// import  { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import compose from 'lodash/fp/compose';
import styled from 'styled-components';
import { useForm } from 'hooks/useForm';
import {
  createIncomeTransaction,
  getSingleIncomeTransaction,
  updateIncomeTransaction,
} from 'actions';
import { removeEmptyProperties } from 'helpers/removeEmptyProperties';
import { useFinancialCategory } from 'hooks/useFinancialCategory';
import {
  withTranslation,
  withCurrency,
  withPersonalInfo,
  withUserDefaultSpace,
  withCustomRouter,
  withContract,
} from 'components/hoc';
import { useOrgIndicator } from 'hooks/useOrgIndicator';
import { useAccountCategoryRef } from '../hooks/useAccountCategoryRef';
import { transactionPosting, getTransactionValuesFromPayload } from './utils';
import lodash from 'lodash';
import moment from 'moment';
import {
  Input,
  Select,
  InputDatePicker,
  Button,
  ButtonLink,
  TransactionForm,
} from 'components/common/FormItems';
import { useCurrencyRate } from 'hooks/useCurrencyRate';
import { getDateDistance, formCurrencyConverter } from '../finance-utils';
import { ORG_INDICATOR_TYPES, orgIndicatorOptions } from '../../../../../constants';

const Wrapper = styled.div``;

const StyledButtonLink = styled(ButtonLink)`
  span {
    text-transform: uppercase;
  }
`;

const initialValues = {
  description: '',
  amount: '',
  amount_sc: '',
  currency: 'EUR',
  dc_indicator: 'C',
  contract: '',
  person: '',
  contract_item: '',
  income_financial_account: '',
  // org_indicator: '',
  org_indicator: ORG_INDICATOR_TYPES.ORG,
  partner: '',
  asset_financial_account: 'bank',
  asset_account_category: 'asset',
  income_account_category: 'income',
  position_link: '',
  date: '',
  rec_op_id: '',
  transaction: '',
};

const cardTypeOptions = [
  {
    id: 'C',
    long_description_deu: 'Income',
    long_description_eng: 'Income',
  },
  {
    id: 'D',
    long_description_deu: 'Expense',
    long_description_eng: 'Expense',
  },
];

const validationSchema = {
  description: 'required',
  amount: 'required',
  dc_indicator: 'required',
  currency: 'required',
  income_account_category: 'required',
  asset_financial_account: 'required',
  income_financial_account: 'required',
  date: 'required',
  person: 'required',
  rec_op_id: [{ required_if: ['asset_financial_account', 'payable|receive'] }],
};

const incomeFormSchema = {
  description: {
    validation: 'required',
  },
  amount: {
    validation: 'required',
  },
  amount_sc: {
    validation: 'required',
  },
  dc_indicator: {
    validation: 'required',
  },
  currency: {
    validation: 'required',
  },
  position_link: {
    validation: 'required',
  },
  // org_indicator: {
  //   validation: 'required',
  // },
  // partner: {
  //   validation: 'required',
  // },
  income_account_category: {
    validation: 'required',
  },
  asset_financial_account: {
    validation: 'required',
  },
  date: {
    validation: 'required',
  },
  person: {
    validation: 'required',
  },
};

const IncomeForm = (props) => {
  const {
    tr,
    personalInfoOptions,
    // createIncomeTransaction,
    userDefaultSpace,
    fn,
    basePath,
    routeParams,
    updateMode,
    detailMode,
    reverseMode,
    currencyLocaleOptions,
    contractInfoOptions,
    // allowReverse = false,
  } = props;

  const { currencyRate } = useCurrencyRate();

  const routepath = props.location.pathname;
  let currentPath = routepath.split('/');

  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formLoading, setFormLoading] = useState(false);

  const history = useHistory();
  const { item } = useSelector((state) => state.transaction);
  const { handleOrgIndicatorChange, organisationOrPersonOptions } = useOrgIndicator({
    defaultOrgIndicator: ORG_INDICATOR_TYPES.ORG,
  });

  const onSubmit = async (formValues) => {
    if (isSubmitting) return;
    let payload = transactionPosting(removeEmptyProperties(formValues));

    try {
      setIsSubmitting(true);
      let incomeData = {};
      if (!values.id || reverseMode) {
        await dispatch(createIncomeTransaction(payload));
      } else {
        await dispatch(updateIncomeTransaction(values.id, payload));
      }
      history.push(basePath);
      window.scrollTo(0, 0);
    } catch (e) {
    } finally {
      setIsSubmitting(false);
    }
  };

  // const onSubmit = async (formValues, { resetForm }) => {
  //   setIsSubmitting(true);
  //   try {
  //     const payload = transactionPosting(removeEmptyProperties(formValues));
  //     if (!values.id || reverseMode) {
  //       await createIncomeTransaction(payload);
  //       // dispatch(createIncomeTransaction(payload));

  //     } else {
  //       dispatch(updateIncomeTransaction(values.id, payload));
  //       // setIsSubmitting(false);
  //     }

  //     history.push(basePath);
  //     window.scrollTo(0, 0);
  //     // window.location.reload();

  //   } catch (error) {
  //     setIsSubmitting(false);
  //   }
  // };

  const {
    handleChange,
    handleBlur,
    touched,
    values,
    errors,
    setValues,
    handleSubmit,
  } = useForm({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const { id: transactionId } = routeParams;

  const fetchData = () => {
    if (transactionId) {
      try {
        setFormLoading(true);
        const { ...data } = dispatch(getSingleIncomeTransaction(transactionId));
        setValues({ ...data });
      } finally {
        setFormLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchData();
    return () => null;
  }, []);

  useEffect(() => {
    if (item) {
      const formValues = {};
      const payload = getTransactionValuesFromPayload(item);
      Object.keys(initialValues).forEach((key) => {
        formValues[key] = payload[key];
      });
      setValues({ ...formValues, id: item.id });
      return;
    }
    setValues(initialValues);
  }, [item, setValues]);

  useEffect(() => {
    if (reverseMode) {
      setValues({
        ...values,
        amount: parseFloat(values.amount) * -1,
        amount_sc: parseFloat(values.amount_sc) * -1,
      });
    }
  }, [reverseMode, setValues]);

  useEffect(() => {
    if (item?.org_indicator) {
      handleOrgIndicatorChange(item?.org_indicator);
      setValues((currentFormValues) => ({
        ...currentFormValues,
        org_indicator: item?.org_indicator || '',
        partner: item?.partner || '',
      }));
    }
  }, [item?.partner, organisationOrPersonOptions, setValues]);

  const isError = (touchedObj, error) =>
    touchedObj && error?.length > 0 ? '  ' : '';

  const { financialCategoryOptions } = useFinancialCategory();

  const incomeFinancialAccountOptions = financialCategoryOptions.filter(
    ({ acc_cat_group }) => acc_cat_group === 'income',
  );

  const transactionCategoryOptions = useAccountCategoryRef({
    financialAccountCat: values.asset_financial_account,
    amount: values.amount,
    currency: values.currency,
    rec_op_id: values.rec_op_id,
  });

  const DefaultSpaceCurrency = userDefaultSpace?.space_detail?.currency;

  const [SpaceCurrency, setSpaceCurency] = useState(0);

  const allCurrencyRate = lodash.compact(
    Object.keys(currencyRate).map((data) => currencyRate[data]),
  );

  const currentDate = moment(values.date);

  const PERIOD_OF_DAILY_RATES = '2021-06-01';

  const closestDate = (selectedDate) => {
    const dateSelected = selectedDate.split('-');
    const selectedYear = dateSelected[0];
    const selectedMonth = dateSelected[1];
    const LAST_AVAILABLE_DATE = '2011-01-01';
    const LAST_FETCHED_MONTHLY_DATE = '2021-05-01';
    const PERIOD_BEFORE_DAILY_RATES = '2021-05-31';

    //compose date range
    if (selectedDate < LAST_AVAILABLE_DATE) {
      return null;
    } else if (
      selectedDate >= LAST_FETCHED_MONTHLY_DATE &&
      selectedDate < PERIOD_BEFORE_DAILY_RATES
    ) {
      const closestRate = allCurrencyRate.find(
        (rate) => rate.date == LAST_FETCHED_MONTHLY_DATE,
      );

      return closestRate;
    } else if (selectedDate >= PERIOD_OF_DAILY_RATES) {
      const closestRate = allCurrencyRate.find((rate) => rate.date == selectedDate);

      if (closestRate) {
        return closestRate;
      } else {
        const previouslyAvailableRates = allCurrencyRate.filter(
          (rate) => rate.date <= selectedDate,
        );
        // const latestRate = allCurrencyRate.pop();
        const latestAvailableRate = previouslyAvailableRates.pop();

        return latestAvailableRate;
      }
    } else if (selectedMonth === 1) {
      const FIRST_DAY = '01';
      const nearestPastYear = parseInt(selectedYear) - 1;
      const NEAREST_PAST_MONTH = 12;
      const nearestFutureMonth = parseInt(selectedMonth) + 1;

      const composedPreviousDate = [
        `${nearestPastYear}`,
        `${NEAREST_PAST_MONTH}`,
        `${FIRST_DAY}`,
      ].join('-');

      const composedFutureDate = [
        `${selectedYear}`,
        `0${nearestFutureMonth}`,
        `${FIRST_DAY}`,
      ].join('-');

      const distancePast = currentDate.diff(moment(composedPreviousDate), 'days');
      const distanceFuture = moment(composedFutureDate).diff(currentDate, 'days');

      const closestRate = getDateDistance(
        distancePast,
        distanceFuture,
        allCurrencyRate,
        composedPreviousDate,
        composedFutureDate,
      );

      return closestRate;
    } else if (selectedMonth === 12) {
      const FIRST_DAY = '01';
      const NEAREST_FUTURE_MONTH = '01';
      const nearestPastMonth = parseInt(selectedMonth) - 1;

      const composedPreviousDate = [
        `${selectedYear}`,
        `${nearestPastMonth}`,
        `${FIRST_DAY}`,
      ].join('-');

      const composedFutureDate = [
        `${selectedYear}`,
        `${NEAREST_FUTURE_MONTH}`,
        `${FIRST_DAY}`,
      ].join('-');

      const distancePast = currentDate.diff(moment(composedPreviousDate), 'days');
      const distanceFuture = moment(composedFutureDate).diff(currentDate, 'days');

      const closestRate = getDateDistance(
        distancePast,
        distanceFuture,
        allCurrencyRate,
        composedPreviousDate,
        composedFutureDate,
      );

      return closestRate;
    } else {
      const FIRST_DAY = '01';
      const nearestFutureMonth = parseInt(selectedMonth) + 1;

      const composedPreviousDate = [
        `${selectedYear}`,
        `${selectedMonth}`,
        `${FIRST_DAY}`,
      ].join('-');

      const composedFutureDate = [
        `${selectedYear}`,
        `0${nearestFutureMonth}`,
        `${FIRST_DAY}`,
      ].join('-');

      const distancePast = currentDate.diff(moment(composedPreviousDate), 'days');
      const distanceFuture = moment(composedFutureDate).diff(currentDate, 'days');

      const closestRate = getDateDistance(
        distancePast,
        distanceFuture,
        allCurrencyRate,
        composedPreviousDate,
        composedFutureDate,
      );

      return closestRate;
    }
  };

  const closestCurrencyRate = closestDate(values.date);

  useEffect(() => {
    setSpaceCurency(
      formCurrencyConverter(
        values.amount,
        closestCurrencyRate,
        DefaultSpaceCurrency,
        values,
      ),
    );
  }, [closestDate, closestCurrencyRate, setSpaceCurency, SpaceCurrency]);

  useEffect(() => {
    if (isNaN(SpaceCurrency)) {
      setValues({ ...values, amount_sc: 'NaN' });
    } else {
      setValues({ ...values, amount_sc: SpaceCurrency });
    }
  }, [SpaceCurrency, closestCurrencyRate, values.amount, values.amount_sc]);

  // function roundToTwoDecimal(number) {
  //   return +(Math.round(number + "e+2") + "e-2")
  // }

  const roundToTwoDecimal = (number) => {
    return +(Math.round(number + 'e+2') + 'e-2');
  };

  return (
    <Wrapper className="form-wrapper">
      <div className="flex">
        <StyledButtonLink
          to={basePath}
          className="option-btn"
          icon="angle double left"
          name="All Entries"
        />
      </div>
      <div className="row">
        <div
          className="col-7 ml-10 mt-30 container"
          style={{
            position: 'relative',
            background: 'var(--admincat-color-grey-1)',
          }}
        >
          <br />

          <TransactionForm
            onSubmit={handleSubmit}
            formData={values}
            title={tr('Income')}
            isLoading={isSubmitting}
            validationSchema={incomeFormSchema}
            // loadingData={formLoading}
            renderActionButtons={() => null}
          >
            <InputDatePicker
              id="date"
              name="date"
              label="Transaction date"
              value={values.date}
              onChange={handleChange}
              error={isError(touched?.date, errors?.date?.[0])}
              showRequiredAsterisk
              disabled={updateMode && true}
            />

            <div className="row">
              <div className="col-md-6">
                <Input
                  label="Amount"
                  name="amount"
                  id="amount"
                  value={values.amount}
                  placeholder="1000"
                  onChange={handleChange}
                  showRequiredAsterisk
                  disabled={updateMode && true}
                  fieldType="number"
                />
              </div>
              <div className="col-md-6">
                <Select
                  label="Currency"
                  showRequiredAsterisk
                  name="currency"
                  optionDefaultValue={tr('Currency')}
                  value={values.currency}
                  onChange={handleChange}
                  localeOptions={currencyLocaleOptions}
                  onBlur={handleBlur}
                  disabled={updateMode && true}
                  error={isError(touched?.currency, errors?.currency?.[0])}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <Input
                  label="Amount in Space Currency"
                  // showRequiredAsterisk
                  name="amount_sc"
                  value={fn(roundToTwoDecimal(values.amount_sc))}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  disabled={updateMode && true}
                />
              </div>
              <div className="col-md-6">
                <Select
                  label="Space Currency"
                  name="currency"
                  value={DefaultSpaceCurrency}
                  onChange={handleChange}
                  localeOptions={currencyLocaleOptions}
                  disabled
                />
              </div>
            </div>

            <Input
              label="Description"
              showRequiredAsterisk
              name="description"
              id="description"
              value={values.description}
              placeholder="Description"
              onChange={handleChange}
            />

            <Select
              label="Person"
              showRequiredAsterisk
              optionDefaultValue="Choose Person"
              value={values.person}
              className="w-80"
              id="person"
              name="person"
              options={personalInfoOptions}
              onChange={handleChange}
              onBlur={handleBlur}
              error={isError(touched?.person, errors?.person?.[0])}
              // disabled={disabled}
            />

            <Select
              label={tr('Transaction partner indicator')}
              value={values.org_indicator}
              name="org_indicator"
              id="title"
              className="d-inline"
              optionDefaultValue={tr('Choose Organisation or Person')}
              localeOptions={orgIndicatorOptions}
              onChange={(e) => {
                handleChange(e);
                handleOrgIndicatorChange(e.target.value);
              }}
              // onChange={handleChange}
              sort={false}
            />

            <Select
              label={tr('Partner')}
              value={values?.partner}
              name="partner"
              localeOptions={organisationOrPersonOptions}
              onChange={handleChange}
              optionDefaultValue={tr('Partner')}
            />

            <Select
              label={tr('Contract')}
              value={values?.contract}
              name="contract"
              options={contractInfoOptions}
              onChange={handleChange}
              onBlur={handleBlur}
              optionDefaultValue={tr('Choose Contract')}
            />

            <div className="row">
              <div className="col-md-6">
                <Select
                  label="Account (+)"
                  showRequiredAsterisk
                  id="asset_financial_account"
                  name="asset_financial_account"
                  value={values.asset_financial_account}
                  onChange={handleChange}
                  localeOptions={financialCategoryOptions.filter(
                    ({ acc_cat_group }) => acc_cat_group === 'asset',
                  )}
                  onBlur={handleBlur}
                  placeholder="Account (+)"
                  error={isError(
                    touched?.asset_financial_account,
                    errors?.asset_financial_account?.[0],
                  )}
                />
              </div>
              <div className="col-md-6">
                <Select
                  label="Position-ID"
                  name="position_link"
                  optionDefaultValue={tr('Position')}
                  value={values.position_link}
                  onChange={handleChange}
                  options={transactionCategoryOptions || []}
                  onBlur={handleBlur}
                  showRequiredAsterisk
                  error={isError(touched?.line, errors?.line?.[0])}
                />
              </div>
            </div>

            <div className="row align-items-center">
              <div className="col-md-6">
                <Select
                  label="Offset Category"
                  name="income_account_category"
                  id="income_account_category"
                  optionDefaultValue={tr('Income')}
                  value={values.income_account_category}
                  onChange={handleChange}
                  localeOptions={cardTypeOptions}
                  onBlur={handleBlur}
                  // showRequiredAsterisk
                  disabled
                />
              </div>
              <div className="col-md-6">
                <Select
                  label="Account"
                  name="income_financial_account"
                  optionDefaultValue={tr('Income Category')}
                  value={values.income_financial_account}
                  onChange={handleChange}
                  localeOptions={incomeFinancialAccountOptions}
                  onBlur={handleBlur}
                  showRequiredAsterisk
                  error={isError(
                    touched?.income_financial_account,
                    errors?.income_financial_account?.[0],
                  )}
                />
              </div>
            </div>

            {currentPath.includes('create') && (
              <div className="row">
                <div className="col-md-12 d-flex">
                  <Button
                    style={{ width: '20%' }}
                    actionButton
                    type="submit"
                    name={tr('Post')}
                  />
                </div>
              </div>
            )}

            {(reverseMode || updateMode) && (
              <div className="row">
                <div className="col-md-12 d-flex">
                  <Button
                    style={{ width: '20%' }}
                    name={reverseMode ? 'Reverse' : updateMode ? 'Save' : 'Post'}
                    actionButton
                    type="submit"
                    disabled={isSubmitting}
                    isLoading={isSubmitting}
                  />
                </div>
              </div>
            )}
          </TransactionForm>
        </div>
      </div>
    </Wrapper>
  );
};

IncomeForm.propTypes = {
  open: PropTypes.bool.isRequired,
  toggleForm: PropTypes.func.isRequired,
  tr: PropTypes.func.isRequired,
};

export default compose(
  withTranslation,
  withCurrency,
  withPersonalInfo,
  withUserDefaultSpace,
  withCustomRouter,
  withContract,
)(IncomeForm);
