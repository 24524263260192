import React from "react";
import { connect } from "react-redux";
import { getFinanceCategory } from "actions";
import hoistNonReactStatics from 'hoist-non-react-statics'


let loaded = false;

export const withFinancialCategory = (WrapperComponent) => {
  class WithFinancialCategory extends React.Component {
    componentDidMount() {
      const { getFinanceCategory } = this.props;
      if (loaded) return;
      loaded = true;
      getFinanceCategory();
    }
    getFinancialCategoryOptions = () => undefined

    getFinancialCategoryLocaleOptions = () => {
      const { financialCategory } = this.props;
      return financialCategory.map(({ created_at, ...rest }) => ({ ...rest }));
    }
    
    render() {
      return (
        <WrapperComponent 
            financialCategoryOptions={this.getFinancialCategoryOptions()} 
            financialCategoryLocaleOptions={this.getFinancialCategoryLocaleOptions()} 
          {...this.props} 
        />
      )
    }
  }
  const mapStateToProps = ({ financialCategory }) => ({ financialCategory })
  const WithFinancialCategoryHOC = connect(mapStateToProps, {
    getFinanceCategory,
  })(WithFinancialCategory);
  hoistNonReactStatics(WithFinancialCategoryHOC, WrapperComponent)
  return WithFinancialCategoryHOC
}