import React from 'react';
import {
  withTranslation,
  withUserPreferences,
  withCustomRouter,
  withDataArea,
  withAdminEntryProposal,
} from 'components/hoc';
import LottieLoader from 'components/common/LottieLoader';
import AdminContentTemplate from 'components/Admin/common/AdminContentTemplate';
import compose from 'lodash/fp/compose';

const AdminProposalEntry = (props) => {
  const { loaded, tr, trObj, adminEntryProposal, dataArea, bulkDeleteProposals } =
    props;

  if (!loaded) {
    return (
      <>
        <LottieLoader />
      </>
    );
  }

  const mapFields = () => {
    const proposals = adminEntryProposal?.proposals || [];
    return proposals.map((proposal) => {
      return {
        ...proposal,
        language: tr(proposal.language),
        data_area: trObj(dataArea.find((da) => da.id == proposal.data_area)),
      };
    });
  };

  return (
    <>
      <AdminContentTemplate
        tableName="entry_proposal"
        columns={[]}
        data={mapFields()}
        useAddModalForm={false}
        bulkDelete={bulkDeleteProposals}
      />
    </>
  );
};

export default compose(
  withTranslation,
  withCustomRouter,
  withUserPreferences,
  withAdminEntryProposal,
  withDataArea,
)(AdminProposalEntry);
