import React from 'react';
import compose from 'lodash/fp/compose';
import { connect } from 'react-redux';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { BaseHOC, withBaseHOCProps } from '../common/BaseHOC';
import * as paymentActions from 'actions/payment';

// let loaded = false;

const initialState = {
  loaded: false,
};

export const withPayments = (WrappedComponent) => {
  class WithPayments extends BaseHOC {
    state = { ...initialState };

    async componentDidMount() {
      const { getPayments } = this.props;
      if (!this.state.loaded) {
        await getPayments();
        this.setState({ ...initialState, loaded: true });
        // loaded = true;
      }
    }

    render() {
      return <WrappedComponent {...this.props} loaded={this.state.loaded} />;
    }
  }
  const mapStateToProps = ({ payment: { payments } }) => ({ payments });
  const Payment = connect(mapStateToProps, paymentActions)(WithPayments);
  hoistNonReactStatics(Payment, WrappedComponent);
  return compose(withBaseHOCProps)(Payment);
};
