import React, { Component } from "react";


class Temp extends Component {

  render() {
    return (
      <div className="card">
        <div className="table-responsive table-scroll">
          <h1>Route is working!</h1>
        </div>
      </div>
    )
  }
}


export default Temp;
