import SpaceAdmin from './SpaceAdmin';

const path = '/dashboard/space-admin';

export const redirects = [
  {
    from: path,
    to: `${path}`,
  },
  {
    from: `${path}/*`,
    to: `${path}`,
  },
];

export default [
  {
    name: 'Space Admin',
    path: `${path}`,
    icon: '',
    component: SpaceAdmin,
    exact: true,
  },
];
