import React from 'react';
import styled from 'styled-components';
import DashboardTemplate from '../../../common/DashboardTemplate';
import PageHeader from '../components/PageHeader';
import PaymentPage from 'components/Payment';

import { redirects } from '../routes';

const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  width: 90%;
  margin: auto;
`;

const Settings = (props) => {
  return (
    <DashboardTemplate
      stripeHeaderTitle="Settings"
      showSubHeading={false}
      showSideBar={true}
      card={true}
      redirects={redirects}
    >
      <Wrapper>
        <PageHeader
          leftTitle={''}
          // rightIcon={<Icon.Gear />}
        />
        <PaymentPage />
      </Wrapper>
    </DashboardTemplate>
  );
};

export default Settings;
