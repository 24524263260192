import axios from '../../axios';
import * as types from '../types';
import { createTypes } from '../createTypes';

import { setMsgInfo } from '../';
import API from '../api';
import filterError from '../common/filterError';

const baseUrl = API + '/dashboard/documents';

const closeDocumentForm = () => {
  return {
    type: types.DOCUMENT_FORM,
    payload: { data: {}, showForm: false },
  };
};

export const addDocument = (payload) => {
  return async (dispatch) => {
    dispatch({ type: createTypes(types.ADD_DOCUMENT).loading });
    try {
      const { data } = await axios.post(baseUrl, payload);

      dispatch({ type: createTypes(types.ADD_DOCUMENT).success, payload: data });
      dispatch(closeDocumentForm());
      dispatch(
        setMsgInfo({
          success: true,
          msg: ['Document saved successfully'],
        }),
      );
      return data;
    } catch (err) {
      const errors = filterError(err);
      dispatch({ type: createTypes(types.ADD_DOCUMENT).failure, payload: errors });
      dispatch(
        setMsgInfo({
          success: false,
          msg: errors,
        }),
      );
      return err;
    }
  };
};

export const updateDocument = (id, payload) => {
  return async (dispatch) => {
    dispatch({ type: createTypes(types.UPDATE_DOCUMENT).loading });
    try {
      const { data } = await axios.patch(`${baseUrl}/${id}`, payload);

      dispatch({ type: createTypes(types.UPDATE_DOCUMENT).success, payload: data });
      dispatch(closeDocumentForm());
      dispatch(
        setMsgInfo({
          success: true,
          msg: ['Document updated successfully'],
        }),
      );
    } catch (err) {
      const errors = filterError(err);
      dispatch({
        type: createTypes(types.UPDATE_DOCUMENT).failure,
        payload: errors,
      });
      dispatch(
        setMsgInfo({
          success: false,
          msg: errors,
        }),
      );
    }
  };
};

export const deleteDocumentById = (id, payload) => {
  return async (dispatch) => {
    dispatch({ type: createTypes(types.DELETE_DOCUMENT_BY_ID).loading });
    try {
      const response = await axios.delete(`${baseUrl}/${id}`);
      dispatch({ type: createTypes(types.DELETE_DOCUMENT_BY_ID).success, payload });
      dispatch(
        setMsgInfo({
          success: true,
          msg: ['Document deleted successfully'],
        }),
      );

      return response;
    } catch (err) {
      const errors = filterError(err);
      dispatch({
        type: createTypes(types.DELETE_DOCUMENT_BY_ID).failure,
        payload: errors,
      });
      dispatch(
        setMsgInfo({
          success: false,
          msg: [`Document could not be deleted: ${errors}`],
        }),
      );
      throw errors;
    }
  };
};

export const getDocuments = () => {
  return async (dispatch) => {
    dispatch({ type: createTypes(types.GET_DOCUMENT).loading });
    try {
      const { data } = await axios.get(baseUrl);
      dispatch({ type: createTypes(types.GET_DOCUMENT).success, payload: data });
      return data;
    } catch (err) {
      const errors = filterError(err);
      dispatch({ type: createTypes(types.GET_DOCUMENT).failure, payload: errors });
      dispatch(
        setMsgInfo({
          success: false,
          msg: errors,
        }),
      );
    }
  };
};

export const getDocumentInfo = (id) => {
  return async (dispatch) => {
    dispatch({ type: createTypes(types.GET_DOCUMENT_INFO).loading });
    try {
      const { data } = await axios.get(`${baseUrl}/${id}`);
      dispatch({
        type: createTypes(types.GET_DOCUMENT_INFO).success,
        payload: data,
      });
      return data;
    } catch (err) {
      const errors = filterError(err);
      dispatch({
        type: createTypes(types.GET_DOCUMENT_INFO).failure,
        payload: errors,
      });
      dispatch(
        setMsgInfo({
          success: false,
          msg: errors,
        }),
      );
    }
  };
};

export const documentFullTextSearch = (search_query) => {
  return async (dispatch) => {
    dispatch({ type: createTypes(types.DOCUMENT_FULL_TEXT_SEARCH).loading });

    try {
      const { data } = await axios.get(`${baseUrl}/search/?search=` + search_query);
      dispatch({
        type: createTypes(types.DOCUMENT_FULL_TEXT_SEARCH).success,
        payload: data,
      });
      return data;
    } catch (error) {
      const errors = filterError(error);
      dispatch({
        type: createTypes(types.DOCUMENT_FULL_TEXT_SEARCH).failure,
        payload: errors,
      });
    }
  };
};

export const filterDocumentById = (id, payload) => {
  return async (dispatch) => {
    dispatch({ type: createTypes(types.DELETE_DOCUMENT_BY_ID).loading });
    try {
      await axios.get(`${baseUrl}/${id}`);
      dispatch({ type: createTypes(types.DELETE_DOCUMENT_BY_ID).success, payload });
      dispatch(
        setMsgInfo({
          success: true,
          msg: ['Document deleted successfully'],
        }),
      );
    } catch (err) {
      const errors = filterError(err);
      dispatch({
        type: createTypes(types.DELETE_DOCUMENT_BY_ID).failure,
        payload: errors,
      });
      dispatch(
        setMsgInfo({
          success: false,
          msg: ['Document could not be deleted'],
        }),
      );
    }
  };
};
