import { BaseActionClass } from "./common/BaseActionClass"
import { SPACE, SPACE_MEMBER } from './types'
import axios from '../axios';

//TODO: refactor all space endpoints

const path = "/space";
const name = "Space";

class Space extends BaseActionClass {

  constructor() { super(path, SPACE, name) }

  tSlash = "/"

  addSpace = this._add;

  updateSpace = this._update;

  deleteSpaceByName = this._deleteById;

  getSpace = this._getAll;

  getSpaceByName = this._getById;

  getSpaceMember = () => {
    return (async (dispatch) => { 
      try {
        const { data } = await axios.get(`${this._baseUrl}/space_members/`)
        dispatch({ type: this.createTypes(`GET_${SPACE_MEMBER}`).success, payload: data });
        return data
      } catch (err) {
        this._handleError(err, dispatch)
      }
    });
  }

  assignSpaceAdmin = (payload) => {
    return (async (dispatch) => { 
      try {
        const { data } = await axios.patch(`${this._baseUrl}/assign_admin/`, payload)
        dispatch(
          this.setMsgInfo({ 
            success: true, 
            msg: ['Space admin changed'] })
        );
        return data
      } catch (err) {
        this._handleError(err, dispatch)
      }
    });
  }
  // deleteBulkSpace = this._deleteBulk;
}

export const { 
  addSpace, 
  updateSpace, 
  deleteSpaceByName,
  getSpace,
  getSpaceByName,
  getSpaceMember,
  assignSpaceAdmin,
  // deleteBulkSpace,
} = new Space()

