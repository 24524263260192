import * as types from '../../../actions/dashboard/finance/types';

const initialSate = {
  list: [],
  loading: false,
  item: {},
};

export function creditCard(state = initialSate, action = {}) {
  switch (action.type) {
    case types.CREATE_CREDIT_CARD:
      return {
        ...state,
        list: [...state.list, action.payload],
      };
    case types.LIST_CREDIT_CARD:
      return {
        ...state,
        loading: action.payload.loading,
        list: action.payload.data,
      };
    case types.DELETE_BULK_CREDIT_CARD:
      return {
        ...state,
        list: [
          ...state.list
            .filter((item) => !action.payload.includes(item.id)),
        ],
      };

    case types.DELETE_CREDIT_CARD:
      return {
        ...state,
        list: [
          ...state.list
            .filter((item) => item.id !== action.payload),
        ],
      };
    case types.GET_CREDIT_CARD:
      return {
        ...state,
        item: action.payload,
      };
    case types.UPDATE_CREDIT_CARD:
      return {
        ...state,
        list: [...state.list]
          .map((item) => {
            if (item.id === action.payload.id) {
              return action.payload;
            }
            return item;
          }),
      };
    default:
      return state;
  }
}
