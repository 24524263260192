import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  notifications: [],
  dashboard_summary: [],
  loading: false,
  error: false,
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    initializingApi: (state) => {
      state.loading = true;
      state.error = false;
    },

    FailedApiCall: (state) => {
      state.loading = false;
      state.error = true;
    },

    //for Notification
    notifications: (state, { payload }) => {
      state.notifications = payload;
      state.loading = false;
      state.error = false;
    },

    dashboardSummary: (state, { payload }) => {
      state.dashboard_summary = payload;
      state.loading = false;
      state.error = false;
    },
  },
});

export const { initializingApi, notifications, FailedApiCall, dashboardSummary } =
  dashboardSlice.actions;

export default dashboardSlice.reducer;
