// import * as types from '../../actions';

// const initialState = {
//   diseaseHistoryList: {
//     data: [],
//     loading: false,
//   },
//   formLoading: false,
//   singleDiseaseHistory: {
//     data: null,
//     loading: false,
//   },
//   diseaseTypes: {
//     data: [],
//     loading: false,
//   },
// };

// export const diseaseHistory = (state = initialState, action = {}) => {
//   switch (action.type) {
//     case types.ADD_DISEASE_HISTORY:
//       return {
//         ...state,
//         diseaseHistoryList: {
//           ...state.diseaseHistoryList,
//           data: action.payload.data
//             ? [action.payload.data, ...state.diseaseHistoryList.data]
//             : state.diseaseHistoryList.data,
//         },
//       };

//     case types.GET_DISEASE_HISTORY:
//       return {
//         ...state,
//         diseaseHistoryList: {
//           ...state.diseaseHistoryList,
//           ...action.payload,
//         },
//       };

//     case types.GET_SINGLE_DISEASE_HISTORY:
//       return {
//         ...state,
//         singleDiseaseHistory: {
//           ...action.payload,
//         },
//       };
//     case types.DELETE_DISEASE_HISTORY:
//       return {
//         ...state,
//         diseaseHistoryList: {
//           ...state.diseaseHistoryList,
//           data: state.diseaseHistoryList.data.filter(
//             (disease) => !action.payload.includes(disease.id)
//           ),
//         },
//       };

//     case types.UPDATE_DISEASE_HISTORY:
//       return {
//         ...state,
//         diseaseHistoryList: {
//           ...state.diseaseHistoryList,
//           data: state.diseaseHistoryList.data.map((data) => {
//             if (data.id === action?.payload?.data?.id) {
//               return action.payload.data;
//             }
//             return data;
//           }),
//         },
//       };
//     case types.GET_DISEASE_TYPES:
//       return {
//         ...state,
//         diseaseTypes: action.payload,
//       };
//     default:
//       return state;
//   }
// };


import { DISEASE_HISTORY  } from "actions/types";
import { baseStore } from "../common/baseStore"

export default baseStore(DISEASE_HISTORY)
