import axios from '../../axios';
import * as types from "../types";
import { createTypes } from "../createTypes";

import { setMsgInfo } from "../";
import API from "../api";
import filterError from "../common/filterError";

const baseUrl = API + "/dashboard/criteria-set";



export const addCriteriaSet = (payload, history) => { 
  return (async (dispatch) => { 
    dispatch({ type: createTypes(types.ADD_CRITERIA_SET).loading });
    try {
      const { data } = await axios.post(baseUrl, payload);
      dispatch({ type: createTypes(types.ADD_CRITERIA_SET).success, payload: data });
      dispatch({ type: types.CRITERIA_SET_FORM, payload: false });
      dispatch(setMsgInfo({
        success: true, 
        msg: ["Criteria Set created successfully"],
      }))
      history.push("/dashboard/forms/criteria-set");
      return data;
    }
    catch(err) {
      const errors = filterError(err);
      dispatch({ type: createTypes(types.ADD_CRITERIA_SET).failure, payload: errors });
      dispatch(setMsgInfo({
        success: false, 
        msg: errors,
      }));
      throw err;
    }
  });
}


export const updateCriteriaSet = (payload, id, history) => { 
  return (async (dispatch) => { 
    dispatch({ type: createTypes(types.UPDATE_CRITERIA_SET).loading });
    try {
      const { data } = await axios.put(`${baseUrl}/${id}`, payload);
      dispatch({ type: createTypes(types.UPDATE_CRITERIA_SET).success, payload: data });
      dispatch({ type: types.CRITERIA_SET_FORM, payload: false });
      dispatch(setMsgInfo({
        success: true, 
        msg: ["Criteria Set updated successfully"],
      }))
      history.push("/dashboard/forms/criteria-set");
      return data;
    }
    catch(err) {
      const errors = filterError(err);
      dispatch({ type: createTypes(types.UPDATE_CRITERIA_SET).failure, payload: errors });
      dispatch(setMsgInfo({
        success: false, 
        msg: errors,
      }));
      throw err;
    }
  });
}

export const getCriteriaSet = (query="") => { 
  return (async (dispatch) => { 
    dispatch({ type: createTypes(types.GET_CRITERIA_SET).loading });
    try {
      const { data } = await axios.get(`${baseUrl}${query && `${query}`}`);
      dispatch({ type: createTypes(types.GET_CRITERIA_SET).success, payload: data });
    }
    catch(err) {
      const errors = filterError(err);
      dispatch({ type: createTypes(types.GET_CRITERIA_SET).failure, payload: errors });
      dispatch(setMsgInfo({
        success: false, 
        msg: errors,
      }));
    }
  });
}

const getCriteriaValue = (criteria_value) => {
  const type = { "I": "included", "E": "excluded" };
  const getKeyValue = (data={}) => {
    return Object.entries(data).map(([key, value = ""]) => {
      return { type: key, value };
    })
  }
  return criteria_value.reduce((accum, curr) => {
    const { sign, low, high, opt, aggr, sequence } = curr;
    const currentValue = accum[type[sign]];
    currentValue.push(getKeyValue({aggr, opt, low, high, sequence}));
    accum[type[sign]] = currentValue;
    return accum;
  }, {included: [], excluded: []});
}

const mapLines = (lines) => {
  return lines.map((line) => {
    return line.field_path;
  }).map(line => {
    return line.map(data => {
      return {
        field_path: data.field_path,
        criteria_value: getCriteriaValue(data.criteria_value)
      }
    })
  });
}

export const getCriteriaSetById = (id) => { 
  return (async (dispatch) => { 
    dispatch({ 
      type: types.CRITERIA_SET_VALUE_LOADING, 
      payload: true,
    });
    try {
      const { data: { lines, ...rest} } = await axios.get(`${baseUrl}/${id}`);
      dispatch({ 
        type: types.CRITERIA_SET_VALUE, 
        payload: {
          lines: mapLines(lines),
          ...rest
        }});
    }
    catch(err) {
      const errors = filterError(err);
      dispatch(setMsgInfo({
        success: false, 
        msg: errors,
      }));
      dispatch({ 
        type: types.CRITERIA_SET_VALUE_LOADING, 
        payload: false,
      });
    }
  });
}


export const deleteCriteriaSetById = (id, payload) => { 
  return (async (dispatch) => { 
    dispatch({ type: createTypes(types.DELETE_CRITERIA_SET_BY_ID).loading });
    try {
      await axios.delete(`${baseUrl}/${id}`);
      dispatch({ type: createTypes(types.DELETE_CRITERIA_SET_BY_ID).success, payload });
      dispatch(setMsgInfo({
        success: true, 
        msg: ["Criteria Set deleted successfully"],
      }))
    }
    catch(err) {
      const errors = filterError(err);
      dispatch({ type: createTypes(types.DELETE_CRITERIA_SET_BY_ID).failure, payload: errors });
      dispatch(setMsgInfo({
        success: false, 
        msg: ["Criteria Set could not be deleted"],
      }));
    }
  });
}

