import React from "react";
import compose from "lodash/fp/compose";
import { connect } from "react-redux";
import hoistNonReactStatics from "hoist-non-react-statics";
import * as workExperienceInfoActions from "actions/dashboard/workExperienceInfo";
import { BaseHOC, withBaseHOCProps } from "../common/BaseHOC";


const initialState = {
  loaded: false,
}

export const withWorkExperienceInfo = (WrappedComponent) => {

  class WithWorkExperienceInfo extends BaseHOC {

    state = { ...initialState }

    async componentDidMount() {
      const { getWorkExperience } = this.props
      if (!this.state.loaded)  {
        await getWorkExperience();
        this.setState({...initialState, loaded: true });
        // loaded = true;
      }
    }

    deleteWorkExperienceInfoById = async (data) => {
      const { deleteWorkExperienceById } = this.props
      await this._deleteById(deleteWorkExperienceById, data);
    }

    deleteBulkWorkExperienceInfo = async (data) => {
      const { deleteBulkWorkExperience } = this.props
      await this._deleteBulk(deleteBulkWorkExperience, data);
    }

    render() {
      return (
        <WrappedComponent 
          {...this.props} 
          loaded={this.state.loaded}
          deleteBulkWorkExperienceInfo={this.deleteBulkWorkExperienceInfo}
          deleteWorkExperienceInfoById={this.deleteWorkExperienceInfoById}
        
        />
      )
    }
  }
  const mapStateToProps = ({ workExperienceInfo }) => ({ workExperienceInfo })
  const WorkExperienceInfo = connect(mapStateToProps, workExperienceInfoActions)(WithWorkExperienceInfo);
  hoistNonReactStatics(WorkExperienceInfo, WrappedComponent)
  return compose(withBaseHOCProps)(WorkExperienceInfo);
}
