import React from "react";
import { connect } from "react-redux";
import {
  getReligion,
} from "../../../actions";
import hoistNonReactStatics from 'hoist-non-react-statics'


let loaded = false;

export const withReligion = (WrapperComponent) => {
  class WithReligion extends React.Component {
    componentDidMount() {
      const { getReligion } = this.props;
      if (loaded) return;
      loaded = true;
      getReligion();
    }
    getReligionOptions = () => undefined
    getReligionLocaleOptions = () => {
      const { religion } = this.props;
      return religion.map(({ created_at, ...rest }) => ({ ...rest }));
    }
    render() {
      return (
        <WrapperComponent 
          religionOptions={this.getReligionOptions()} 
          religionLocaleOptions={this.getReligionLocaleOptions()} 
          {...this.props} 
        />
      )
    }
  }
  const mapStateToProps = ({ religion }) => ({ religion })
  const WithReligionHOC = connect(mapStateToProps, {
    getReligion,
  })(WithReligion);
  hoistNonReactStatics(WithReligionHOC, WrapperComponent)
  return WithReligionHOC
}
