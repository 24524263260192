import React from 'react';
import './style.css';

export default function Loader({ fullpage, size, ...rest }) {
  const modalClasses = {
    fullpage: 'full-page',
    halfPage: 'half-page',
    small: 'small',
  };
  return (
    <div
      className={`loader__conatainer ${
        modalClasses[size] ?? modalClasses[fullpage]
      }`}
      {...rest}
    >
      <div className="loader"></div>
    </div>
  );
}
