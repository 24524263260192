export const SPACE_TYPES = {
  PERSON: 'P',
  ORG: 'O',
};

export const DATA_AREA_KEYS = {
  base: 'B',
  education: 'E',
  finance: 'F',
  health: 'G',
  housing: 'H',
  leisure: 'L',
  mobility: 'M',
  work: 'W',
};

export const ORG_INDICATOR_TYPES = {
  PERSON: 'P',
  ORG: 'O',
};

export const orgIndicatorOptions = [
  {
    id: ORG_INDICATOR_TYPES.ORG,
    long_description_eng: 'Organisation',
    long_description_deu: 'Organisation',
  },
  {
    id: ORG_INDICATOR_TYPES.PERSON,
    long_description_eng: 'Person',
    long_description_deu: 'Person',
  },
];

export const yesOrNoOptions = [
  {
    id: 'Yes',
    long_description_eng: 'Yes',
    long_description_deu: 'Yes',
  },
  {
    long_description_eng: 'No',
    long_description_deu: 'NO',
    id: 'No',
  },
];

export const RESPONSE_STRINGS = {
  ENTRY_RESPONSE_SUCCESS_MESSAGE: 'We will review your proposal. if successful, it will be added in the next 5 days. Thank you!'
}
