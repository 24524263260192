import React from "react";
import { connect } from "react-redux";
import hoistNonReactStatics from 'hoist-non-react-statics'
import {
  setDateFormat,
  getDateFormat
} from "../../../actions";

let loaded = false;

export const withDateFormat = (WrapperComponent) => {
  class DateFormat extends React.Component {
    componentDidMount() {
      const { getDateFormat } = this.props;
      if (!loaded) {
        getDateFormat();
      }
      loaded = true;
    }

    render() {
      return (
        <WrapperComponent 
          {...this.props} 
          dateFormatList={this.props.dateFormat}
        />
      )
    }
  }
  const mapStateToProps = ({ dateFormat }) => ({ dateFormat })
  const WithDateFormat = connect(mapStateToProps, {
    setDateFormat,
    getDateFormat,
  })(DateFormat);
  hoistNonReactStatics(WithDateFormat, WrapperComponent)
  return WithDateFormat
}
