import React from 'react';
import compose from 'lodash/fp/compose';
import { connect } from 'react-redux';
import hoistNonReactStatics from 'hoist-non-react-statics';
import * as adminDashboardActions from 'actions/admin/adminDashboard';
import { BaseHOC, withBaseHOCProps } from '../common/BaseHOC';

const initialState = {
  loaded: false,
};

export const withAdminDashboard = (WrappedComponent) => {
  class WithAdminDashboard extends BaseHOC {
    state = { ...initialState };

    async componentDidMount() {
      const { fetchAdminDashboardData } = this.props;
      if (!this.state.loaded) {
        await fetchAdminDashboardData();
        this.setState({ ...initialState, loaded: true });
      }
    }

    refreshDashboardData = async () => {
      const { fetchAdminDashboardData } = this.props;
      await fetchAdminDashboardData();
    };

    render() {
      return (
        <WrappedComponent
          {...this.props}
          loaded={this.state.loaded}
          refreshDashboardData={this.refreshDashboardData}
        />
      );
    }
  }

  const mapStateToProps = ({ adminDashboard }) => ({ adminDashboard });

  const AdminDashboard = connect(
    mapStateToProps,
    adminDashboardActions,
  )(WithAdminDashboard);

  hoistNonReactStatics(AdminDashboard, WrappedComponent);

  return compose(withBaseHOCProps)(AdminDashboard);
};

export default withAdminDashboard;
