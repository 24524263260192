import React from "react";
import { connect } from "react-redux";
import {
  getMaritalStatus,
} from "../../../actions";
import hoistNonReactStatics from 'hoist-non-react-statics'


let loaded = false;

export const withMaritalStatus = (WrapperComponent) => {
  class WithMaritalStatus extends React.Component {
    componentDidMount() {
      const { getMaritalStatus } = this.props;
      if (loaded) return;
      loaded = true;
      getMaritalStatus();
    }
    getMaritalStatusOptions = () => undefined
    getMaritalStatusLocaleOptions = () => {
      const { maritalStatus } = this.props;
      return maritalStatus.map(({ created_at, ...rest }) => ({ ...rest }));
    }
    render() {
      return (
        <WrapperComponent 
          maritalStatusOptions={this.getMaritalStatusOptions()} 
          maritalStatusLocaleOptions={this.getMaritalStatusLocaleOptions()} 
          {...this.props} 
        />
      )
    }
  }
  const mapStateToProps = ({ maritalStatus }) => ({ maritalStatus })
  const WithMaritalStatusHOC = connect(mapStateToProps, {
    getMaritalStatus,
  })(WithMaritalStatus);
  hoistNonReactStatics(WithMaritalStatusHOC, WrapperComponent)
  return WithMaritalStatusHOC
}
