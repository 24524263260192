import React from 'react';
import { Form as BaseForm, withFormProps } from '../common/Form'
import { Select } from 'components/common/FormItems';

class Form extends BaseForm {

  educationFormSchema = {
    organisation: this.required,
  }
  renderUpperInputs = (state) => {
    return (
      <>
        <Select
          onChange={this.onChange}
          value={state.organisation}
          name="organisation"
          label="Pre-School Institution"
          optionDefaultValue="Choose Organisation"
          options={this.organisationOptions}
          showRequiredAsterisk
        />
        <Select
          onChange={this.onChange}
          value={this.getSchoolTypeValue(state.organisation)}
          name="school_type"
          label="School Type"
          optionDefaultValue="Choose School Type"
          options={this.schoolTypeOptions}
          disabled
        />
      </>
    )
  }
}

export default withFormProps(Form)

