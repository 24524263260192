import React, { Component } from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import compose from 'lodash/fp/compose';
import styled from "styled-components";
import {
  withTranslation,
  withFieldError,
} from "../../../hoc";
import {
  inputStyle,
  InputWrapper,
} from "../Input";
import "./style.css";


const StyledTextArea = styled.textarea`
  ${inputStyle}
  height: 150px;
  resize: none;
  outline: none;
  width: 100%;
`

//Root Wrapper of the Editor 
const EditorWrapper = styled.div`
  min-width: 700px;
  display: flex;
  flex-direction: column;
  height: fit-content;
  margin-top: 3em;
`;

//DraftEditor Container 
const EditorContainer = styled.div`
  display: flex;
  min-height: 9em;
  border-radius: 0 0 3px 3px;
  background-color: #fff;
  padding: 5px;
  font-size: 17px;
  font-weight: 300;
  box-shadow: 0px 0px 3px 1px rgba(15, 15, 15, 0.17);
`;


class Editor extends Component {
 
  constructor(props){
    super(props);
    this.onChange = this.onChange.bind(this);
   
  }
  

  // onEditorStateChange = (editorState) => {
  //   this.setState({
  //     editorState,
  //   });
  //   this.props.onChange({ target: { value: editorState, name: 'message' }})
  // };

  onChange(e) {
    console.log(e.target.getContent());
  }


  render() {
    const { className, label, required, name, tr, placeholder, value, onChange, ...rest } = this.props;

    return (
            <InputWrapper className={this.props.className}>
              {label && (
                <label className="form-label" htmlFor={name}>
                  {tr(label || "")}
                  {required && <span className="form-required">*</span>}
                </label>
              )}

            <CKEditor
                editor={ClassicEditor}
                data="<p>Hello from CKEditor 5!</p>"
                config={{
                    toolbar: [
                    "heading",
                    "|",
                    "bold",
                    "italic",
                    "link",
                    "bulletedList",
                    "numberedList",
                    "blockQuote",
                    "ckfinder",
                    "|",
                    "imageTextAlternative",
                    "imageUpload",
                    "imageStyle:full",
                    "imageStyle:side",
                    "|",
                    "mediaEmbed",
                    "insertTable",
                    "tableColumn",
                    "tableRow",
                    "mergeTableCells",
                    "|",
                    "undo",
                    "redo"
                    ]
                }}
                onInit={editor => {
                    // You can store the "editor" and use when it is needed.
                    console.log("Editor is ready to use!", editor);
                    console.log(
                    "toolbar: ",
                    Array.from(editor.ui.componentFactory.names())
                    );
                    console.log(
                    "plugins: ",
                    ClassicEditor.builtinPlugins.map(plugin => plugin.pluginName)
                    );
                }}
                onChange={(event, editor) => {
                    const data = editor.getData();
                    console.log({ event, editor, data });
                }}
                onBlur={editor => {
                    console.log("Blur.", editor);
                }}
                onFocus={editor => {
                    console.log("Focus.", editor);
                }}
                {...rest }
            />
              
              {/* <Editor 
                placeholder={tr(placeholder)}
                editorState={this.state.editorState} 
                onEditorStateChange={this.onEditorStateChange}
                {...rest } 
              /> */}
            </InputWrapper>
    )
  }
}

Editor.defaultProps = {
  className: '',
}

export default compose(
  withFieldError,
  withTranslation,
)(Editor);

