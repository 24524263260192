import React from 'react';
import { Select, Input, Button } from 'components/common/FormItems';
// import ProgressBar from 'components/common/ProgressBar';
import Card from 'components/common/Card';
import compose from 'lodash/fp/compose';
import styled from 'styled-components';
import {
  CenterContentDiv,
  FormFlexWrapper,
  VariableWidthDiv,
  FormLabel,
} from '../SettingsFormUtils';
import {
  withUserPersonalWorkspace,
  withUserOrganisationWorkspace,
  withAccount,
  withMsgInfo,
  withTranslation,
  withUserDefaultSpace,
  withDateFormat,
  withLanguage,
  withUserPreferences,
  withNumberFormat,
  withAuth,
  withSpace,
  withDocument,
  withPersonalInfo,
  withUserLayoutChoice,
} from 'components/hoc';
import withFileSize from 'components/hoc/FileSizeChecker/File';
import { sweetAlert } from 'components/utils';
import { updateUserNameService } from '../services/settings';
import swal from 'sweetalert';
import { TrashFill } from 'react-bootstrap-icons';
import { passwordStrength } from 'components/utils';
import { passwordChange } from '../../../../actions';
import { useDispatch } from 'react-redux';
import ToggleSwitch from 'components/common/ToggleSwitch';
import { useHistory } from 'react-router';
import { withPayments } from 'components/hoc/Payment';

const StyledButton = styled(Button)`
  background: #eaedf1;
  // background: var(--admincat-color-grey-6);
  border-radius: 2px;
  height: 38px;
  color: ${(props) => props.color || '#F00'};
  border: none;
  font-size: 14px;
  line-height: 10px;
  margin-left: 25px;
`;

const SettingsUserTab = (props) => {
  const [values, setValues] = React.useState({});
  const [errors, setErrors] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const [passwordChangeLoading, setPasswordChangeLoading] = React.useState(false);
  const {
    tr,
    // userPersonalWorkspaceOptions,
    // userOrganisationWorkspaceOptions,
    otp_browser_dependent,
    numberFormatLocaleOptions,
    handlePreferenceChange,
    userPrefDateFormat,
    updateUserPreferences,
    userPreNumberFormat,
    selectedLocale,
    languageOptions,
    account,
    setMsgInfo,
    toggleOTPBrowserPreference,
  } = props;
  const history = useHistory();

  const workspace_type =
    account.user.space_type === 'P' ? 'personal' : 'organisation';

  const getOptions = () => {
    const { dateFormatList, userPreLang } = props;
    return dateFormatList.map((item) => ({
      id: item.id,
      name: item[`long_description_${userPreLang}`],
    }));
  };

  const handleChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };

  const handleSubmitUsername = () => {
    const { tr } = props;
    if (values.userName && values.userName !== account.user.username) {
      sweetAlert({
        title: tr('Change Username'),
        content: tr('Are you sure you want to change username?'),
        tr,
        actions: [
          {
            label: tr('Yes'),
            onClick: async () => {
              try {
                setLoading(true);
                updateUserNameService({
                  id: account.user.user_id,
                  org_id: account.user.org_id,
                  username: values?.userName,
                }).then((data) => {
                  if (data) {
                    dispatch(
                      setMsgInfo({
                        success: true,
                        msg: [`${tr('Your username has been changed')}`],
                        navigationCounter: 1,
                      }),
                    );
                  }
                  setLoading(false);
                });
              } catch (e) {
                swal(tr('Failed!'), tr('there was an error.'), 'error');
                setLoading(false);
              }
            },
          },
        ],
      });
    }
  };

  const handleSubmitPasswordChange = async () => {
    let errNewPassword = values.newPassword ? '' : 'This field is required';
    let errCurrentPassword = values.currentPassword ? '' : 'This field is required';

    setErrors({
      ...errors,
      currentPassword: errCurrentPassword,
      newPassword: errNewPassword,
    });

    if (errCurrentPassword || errNewPassword) {
      // if none of this field are filled, return
      return;
    }

    let old_password = values?.currentPassword;
    let new_password = values?.newPassword;

    let valid =
      !!new_password && !!old_password && !passwordStrength(new_password).hasError;

    if (!valid) {
      const { errors } = passwordStrength(new_password);
      setMsgInfo({
        success: false,
        msg: errors,
      });
      return;
    }

    // here make the api call, to actually reset the password
    setPasswordChangeLoading(true);
    try {
      await dispatch(
        passwordChange({
          old_password: values?.currentPassword,
          new_password: values?.newPassword,
        }),
      );
    } finally {
      setPasswordChangeLoading(false);
      setValues({ ...values, newPassword: '', oldPassword: '' });
      setErrors({});
    }
  };

  const deleteAccount = () => {
    const { logout, deleteUserById, tr } = props; 
    let myUser = props.user.data.length > 0 ? props.user.data[0] : null;
    if (myUser?.id) {
      sweetAlert({
        title: tr('Delete Account'),
        content: tr('Are you sure you want to delete account?'),
        tr,
        actions: [
          {
            label: tr('Yes'),
            onClick: async () => {
              try {
                await deleteUserById(myUser.id);
                logout();
              } catch (e) {}
            },
          },
        ],
      });
    }
  };

  const resetPassword = () => {
    setValues({ ...values, newPassword: '', currentPassword: '' });
    setErrors({});
  };

  const handleOtpBrowserDependentChange = (e) => {
    const value = e.target.checked;
    toggleOTPBrowserPreference({ target: { name: 'otp_browser_dependent', value } });
  };

  return (
    <>
      <Card
        cardHeader=""
        width="100%"
        paddingRight="7%"
        paddingLeft="3%"
        paddingTop="3%"
        paddingBottom="3%"
      >
        <FormLabel>{tr('User Type')}</FormLabel>
        <Input
          name="userType"
          type="text"
          placeholder=""
          onChange={handleChange}
          value={workspace_type === 'personal' ? 'Personal' : 'Organisation'}
          disabled
        />
        <FormLabel>{tr('UserName')}</FormLabel>
        <Input
          name="userName"
          type="text"
          placeholder={account.user.username || 'Username'}
          onChange={handleChange}
          value={values?.userName}
          autoComplete="off"
          required
        />
        <CenterContentDiv>
          <Button
            actionButton
            name={tr('Save')}
            style={{ width: '130px' }}
            onClick={handleSubmitUsername}
            isLoading={loading}
          />
        </CenterContentDiv>
      </Card>
      <Card
        cardHeader=""
        width="100%"
        paddingRight="7%"
        paddingLeft="3%"
        paddingTop="3%"
        paddingBottom="3%"
      >
        <FormLabel>{tr('Email Address')}</FormLabel>
        <VariableWidthDiv flexDirection="row">
          <div style={{ flexGrow: 1 }}>
            <Input
              name="Email"
              type="email"
              placeholder={account.user.email || 'yourname@domain.com'}
              disabled
            />
          </div>
          <div style={{ flexShrink: 1 }}>
            <StyledButton
              reactIcon={<TrashFill color="#F00" size={12} className="mr-2" />}
              onClick={deleteAccount}
              name={tr('Delete Account')}
            />
          </div>
        </VariableWidthDiv>

        {/** Put the OTP browser dependent setting  */}
        <FormLabel style={{ marginBottom: '0' }}>
          {tr('O.T.P Browser Dependent Setting')}
        </FormLabel>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <ToggleSwitch
            checked={otp_browser_dependent}
            name={'otp_browser_dependent'}
            handleChange={handleOtpBrowserDependentChange}
          />
          {props?.isSubmitting && (
            <span
              style={{
                marginLeft: '10px',
                display: 'inline-block',
                verticalAlign: 'middle',
              }}
            >
              <i className="fa fa-spinner fa-spin"></i>
            </span>
          )}
        </div>

        {/** put the change password component here */}
        <FormLabel>{tr('Change Password')}</FormLabel>
        <FormFlexWrapper padding={'2%'}>
          <VariableWidthDiv width="47%" marginRight="5%">
            <FormLabel>{tr('Current Password')}</FormLabel>
            <Input
              name="currentPassword"
              type="password"
              onChange={handleChange}
              value={values?.currentPassword || ''}
              autoComplete="off"
              showRequiredAsterisk
              error={errors?.currentPassword}
              tinyInfo={errors?.currentPassword}
              placeholder="Enter current password"
            />
          </VariableWidthDiv>
          <VariableWidthDiv width="47%">
            <FormLabel>{tr('New Password')}</FormLabel>
            <Input
              name="newPassword"
              type="password"
              onChange={handleChange}
              value={values?.newPassword || ''}
              autoComplete="off"
              showRequiredAsterisk
              error={errors?.newPassword}
              tinyInfo={errors?.newPassword}
              placeholder="Enter new password"
            />
          </VariableWidthDiv>
        </FormFlexWrapper>
        <CenterContentDiv flexDirection={'row'}>
          <Button
            cancelButton
            name={tr('Clear')}
            style={{ width: '130px' }}
            onClick={resetPassword}
            isLoading={false}
          />
          <Button
            actionButton
            name={tr('Save')}
            style={{ width: '130px' }}
            onClick={handleSubmitPasswordChange}
            isLoading={passwordChangeLoading}
          />
        </CenterContentDiv>
      </Card>
      <Card
        cardHeader=""
        width="100%"
        paddingRight="7%"
        paddingLeft="3%"
        paddingTop="3%"
        paddingBottom="3%"
      >
        <FormFlexWrapper>
          <VariableWidthDiv width="49%">
            <FormLabel>{tr('Choose Language')}</FormLabel>
            <Select
              name="selectedLocale"
              id="selectedLocale"
              value={selectedLocale}
              optionDefaultValue={tr('Choose Language')}
              onChange={handlePreferenceChange}
              options={languageOptions.filter(({ id }) =>
                ['eng', 'deu'].includes(id),
              )}
            />
          </VariableWidthDiv>
          <VariableWidthDiv width="49%">
            <FormLabel>{tr('Choose Date Format')}</FormLabel>
            <Select
              name="dateFormatId"
              id="dateFormat"
              value={userPrefDateFormat}
              optionDefaultValue={tr('Choose Date Format')}
              onChange={handlePreferenceChange}
              options={getOptions()}
            />
          </VariableWidthDiv>
          <VariableWidthDiv width="49%">
            <FormLabel>{tr('Choose Number Format')}</FormLabel>
            <Select
              name="numberFormat"
              value={userPreNumberFormat}
              optionDefaultValue={tr('Choose Number Format')}
              onChange={handlePreferenceChange}
              localeOptions={numberFormatLocaleOptions}
            />
          </VariableWidthDiv>
        </FormFlexWrapper>
        <CenterContentDiv>
          <Button
            actionButton
            name={tr('Save')}
            style={{ width: '130px' }}
            onClick={() => {
              updateUserPreferences();
            }}
            isLoading={props.isSubmitting}
          />
        </CenterContentDiv>
      </Card>
    </>
  );
};

export default compose(
  withTranslation,
  withDateFormat,
  withNumberFormat,
  withLanguage,
  withUserPreferences,
  withUserPersonalWorkspace,
  withUserOrganisationWorkspace,
  withUserLayoutChoice,
  withAccount,
  withMsgInfo,
  withUserDefaultSpace,
  withAuth,
  withSpace,
  withDocument,
  withPersonalInfo,
  withFileSize,
  withMsgInfo,
  withPayments,
)(SettingsUserTab);
