import React from 'react';
import compose from 'lodash/fp/compose';
import {
  withUserPersonalWorkspace,
  withUserOrganisationWorkspace,
  withAccount,
  withMsgInfo,
  withTranslation,
  withUserDefaultSpace,
  withDateFormat,
  withLanguage,
  withUserPreferences,
  withNumberFormat,
  withAuth,
  withSpace,
  withDocument,
  withPersonalInfo,
  withUserLayoutChoice,
} from 'components/hoc';
import withFileSize from 'components/hoc/FileSizeChecker/File';
import { useDispatch } from 'react-redux';
import ContentTemplate from 'components/common/ContentTemplate';
import { useHistory } from 'react-router';
import { withPayments } from 'components/hoc/Payment';




const SettingsPaymentTab = (props) => {
  // const dispatch = useDispatch();
  const { tr, getLayoutColumnProps, payments, fd, fn } = props;
  const history = useHistory();

  const frontendTableName = 'user_payment';
  const backendTableName = 'user_payment';

  const { columns, layoutColumns } = getLayoutColumnProps(
    frontendTableName,
    backendTableName,
    [],
  );
  let customColumns = [
    {
      id: '_payment_date',
      label: 'payment date',
      order_no: 0,
      selected: false,
      field: 'payment_date',
    },
    {
      id: '_subscription_start_date',
      label: 'Start Date',
      // label: 'Subscription Start Date',
      // label: "payment coverage",
      order_no: 1,
      selected: false,
      field: 'subscription_start_date',
    },
    {
      id: '_subscription_end_date',
      label: 'End Date',
      // label: 'Subscription End Date',
      // label: "payment coverage",
      order_no: 2,
      selected: false,
      field: 'subscription_end_date',
    },
    {
      id: '_amount',
      label: 'Amount',
      // label: "payment coverage",
      order_no: 3,
      selected: false,
      field: 'amount',
    },
    {
      id: '_payment_reference',
      label: 'Paypal Reference',
      // label: "payment coverage",
      order_no: 4,
      selected: false,
      field: 'payment_reference',
    },
    {
      id: '_status',
      label: 'Payment Status',
      // label: "payment coverage",
      order_no: 5,
      selected: false,
      field: 'payment_status',
    },
    // {
    //   id: '_coverage',
    //   label: 'coverage',
    //   order_no: 2,
    //   selected: false,
    //   field: '_coverage',
    // },
    // {
    //   id: '_coverage_left',
    //   label: 'coverage left',
    //   order_no: 3,
    //   selected: false,
    //   field: '_coverage_left',
    // },
  ];

  return (
    <>
      <>
        <ContentTemplate
          tableName="user_payment"
          columns={customColumns}
          title={tr('Payments')}
          data={payments.map((payment) => {
            let payment_date = fd(payment.payment_date);
            let subscription_start_date = fd(payment.subscription_start_date);
            let subscription_end_date = fd(payment.subscription_end_date);

            return {
              _payment_date: payment_date,
              _subscription_start_date: subscription_start_date,
              _subscription_end_date: subscription_end_date,
              _amount: fn(payment.amount),
              _payment_reference: payment.payment_reference,
              _status: tr(payment.payment_status),
            };
          })}
          showActionButton={true}
          onActionClick={() => {
            history.push('/dashboard/settings/payments/create');
          }}
        />
      </>
    </>
  );
};

export default compose(
  withTranslation,
  withDateFormat,
  withNumberFormat,
  withLanguage,
  withUserPreferences,
  withUserPersonalWorkspace,
  withUserOrganisationWorkspace,
  withUserLayoutChoice,
  withAccount,
  withMsgInfo,
  withUserDefaultSpace,
  withAuth,
  withSpace,
  withDocument,
  withPersonalInfo,
  withFileSize,
  // withMsgInfo,
  withPayments,
)(SettingsPaymentTab);
