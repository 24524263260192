import check from 'check-types';
import moment from 'moment';

export const dateFormater = (oldValue, newValue) => {
  if (oldValue != null) {
    if (oldValue.length > newValue.length) {
      return newValue;
    }
  }

  return newValue.replace(/^(\d{4})(\d{2})(\d{2})/, '$1-$2-$3').substring(0, 10);
};

export const yearFormater = (oldValue, newValue) => {
  if (oldValue != null) {
    if (oldValue.length > newValue.length) {
      return newValue;
    }
  }

  return newValue.substring(0, 4);
};

export const monthFormater = (oldValue, newValue) => {
  if (oldValue != null) {
    if (oldValue.length > newValue?.length) {
      return newValue;
    }
  }

  return newValue?.substring(0, 2);
};

export const getDate = (date) => {
  const dateValue = new Date(date);
  return check.date(dateValue) ? dateValue : null;
};

export const getDateFormat = (dateFormat) => {
  let format = dateFormat || 'dd/MM/yyyy';
  format = format.replace('YYYY', 'yyyy').replace('DD', 'dd');
  // if (showMothYear) {
  //   format = format.replace(/dd./gi, '').replace('yyyy', 'yy');
  // }
  return format;
};

export const reformatDate = (date) => {
  const newDate = moment(new Date(date)).format('YYYY-MM-DD');
  return newDate === 'Invalid date' ? null : newDate;
};
