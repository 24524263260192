import React from 'react';
import compose from 'lodash/fp/compose';
import styled from 'styled-components';
import { withTranslation, withFieldError } from '../../../hoc';

const Wrapper = styled.div`
  width: 100%;
  div.custom-file {
    z-index: 3;
    position: relative;
    height: 44px;
    border-radius: 3px;
    padding: 22px 0;
    border: ${({ hasError }) =>
      hasError ? '1px solid red !important' : '1px solid #e0e5ec'};
    label.custom-file-label::after,
    label.custom-file-label {
      height: auto;
      border: none;
      line-height: 2rem;
    }

    label.custom-file-label::after {
      border-left: 1px solid #eee;
    }

    // input[type='file'] {
    //   position: absolute;
    //   top: 0;
    //   height: 100%;
    // }
  }
`;


class FileInput extends React.Component {
  state = { file_name: '' };

  componentDidUpdate(prevProps) {
    if (!prevProps.preview && this.props.preview) {
      this.setState({
        file_name: this.props.preview,
      });
    }
  }

  onChange = (e) => {
    const { onChange, multiple } = this.props;
    const { files, name } = e.target;
    if (!files.length) {
      this.setState({ file_name: '' });
      onChange({ target: { name, value: '' } });
      return;
    }
    this.setState({
      file_name: multiple
        ? Array.from(files)
            .map(({ name }) => name)
            .join(', ')
        : files[0].name,
    });
    const fileData = multiple ? files : files[0];
    onChange({ target: { name, value: fileData } });
  };

  render() {
    const {
      tr,
      name,
      label,
      required,
      className,
      accept,
      multiple,
      hasError,
      error,
      disabled,
      ...rest
    } = this.props;

    return (
      <Wrapper
        hasError={hasError || error}
        className={`form-group user-title ${className}`}
        
      >
        <label className="form-label" htmlFor={name}>
          {label}
          {required && <span className="form-required">*</span>}
        </label>
        <div className="file__input__wrapper custom-file" >
          { 
            (!disabled) && (
              <input
                name={name}
                id={name}
                onChange={this.onChange}
                type="file"
                accept={accept}
                multiple={multiple}
                className="custom-file-input"
                required={required}
                disabled={disabled}
              />
            )
          } 
          <label className="custom-file-label">
            {this.state.file_name || tr('Choose File')}
          </label>
          <small id="passwordHelp" className="text-danger bt-2">
            {error}
          </small>
        </div>
      </Wrapper>
    );
  }
}

FileInput.defaultProps = {
  label: '',
  name: '',
  required: false,
  multiple: false,
  accept: '*',
};

export default compose(withTranslation, withFieldError)(FileInput);
