import React from 'react';
import {
  withTranslation,
  withUserPreferences,
  withCustomRouter,
} from 'components/hoc';
import LottieLoader from 'components/common/LottieLoader';
import AdminContentTemplate from 'components/Admin/common/AdminContentTemplate';
import compose from 'lodash/fp/compose';
import withAdminOrgProposal from 'components/hoc/AdminOrgProposal';

const AdminOrgProposal = (props) => {
  const {
    loaded,
    fn,
    fd,
    tr,
    trObj,
    adminOrgProposal,
    dataArea,
    bulkDeleteOrgProposals,
  } = props;

  if (!loaded) {
    return (
      <>
        <LottieLoader />
      </>
    );
  }

  const mapFields = () => {
    const proposals = adminOrgProposal?.proposals || [];
    return proposals.map((proposal) => {
      const { name, public: _public, legal_form_detail, ...rest } = proposal;
      return {
        ...rest,
        name,
        legal_form: legal_form_detail ? trObj(legal_form_detail) : '',
        public: _public ? '\u2713' : '\u2717',
        foundation: fd(rest.foundation),
        liquidation: fd(rest.liquidation),
        modified: fd(rest.modified),
        active: rest.active ? '\u2713' : '\u2717',
        organisation_type: trObj(rest.organisation_type_detail),
        org_country: trObj(rest.org_country_detail),
        state: trObj(rest.state_detail),
        city_location: trObj(rest.city_location_detail),
        register_institute: rest?.register_institute?.name,
        revenue_currency: trObj(rest.revenue_currency_detail),
        industry: trObj(rest.industry_type_detail),
        no_of_employees: fn(rest.no_of_employees),
        revenue_approx: fn(rest.revenue_approx),
        legal_personality: rest.legal_personality ? '\u2713' : '\u2717',
        non_profit: rest.non_profit ? '\u2713' : '\u2717',
        org_motivation: trObj(rest.org_motivation_detail),
        parent_org: rest?.parent_org?.name,
        organisation_logo: rest?.organisation_logo,
      };
    });
  };

  return (
    <>
      <AdminContentTemplate
        tableName="org_proposal"
        columns={[]}
        data={mapFields()}
        useAddModalForm={false}
        bulkDelete={bulkDeleteOrgProposals}
      />
    </>
  );
};

export default compose(
  withTranslation,
  withCustomRouter,
  withUserPreferences,
  withAdminOrgProposal,
)(AdminOrgProposal);
