const formatUserPreferencesData = ({
  user,
  id,
  language,
  dateformat,
  date_format_detail,
  numberformat,
  updatedUsername,
  otp_browser_dependent,
}) => {
  return {
    user,
    id,
    language,
    dateFormat: date_format_detail[`long_description_${language}`],
    dateFormatId: dateformat,
    numberFormat: numberformat,
    updatedUsername,
    otp_browser_dependent,
  };
};

export default formatUserPreferencesData;
