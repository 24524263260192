/* eslint-disable require-jsdoc */
import React from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import compose from 'lodash/fp/compose';
import { connect } from 'react-redux';
import * as vaccinationHistoryActions from 'actions/dashboard/vaccinationHistory';
import { 
  withDeleteConfirmation,
} from "../DeleteConfirmation";

const initialState = {
  loaded: false,
}

export const withVaccinationHistory = (WrapperComponent) => {
  class VaccinationHistory extends React.Component {

    state = { ...initialState };

    async componentDidMount() {
      const { getVaccinationHistory } = this.props;
      if (this.state.loaded) return;
      await getVaccinationHistory();
      // VaccinationHistory.loaded = true;
      this.setState({ ...initialState, loaded: true });
    }
    deleteVaccinationHistoryById = async (data) => {
      const { 
        deleteConfirmation, 
        deleteVaccinationHistoryById,
      } = this.props
      try {
        await deleteConfirmation.init(deleteVaccinationHistoryById, data);
      } catch(e) {}
    }
    deleteBulkVaccinationHistory = (data) => {
      const { 
        deleteConfirmation, 
        deleteBulkVaccinationHistory,
      } = this.props
      deleteConfirmation.initBulk(deleteBulkVaccinationHistory, data);
    }
    static loaded = false;
    render() {
      return (
        <WrapperComponent
          {...this.props}
          loaded={this.state.loaded}
          deleteVaccinationHistoryById={this.deleteVaccinationHistoryById}
          deleteBulkVaccinationHistory={this.deleteBulkVaccinationHistory}
        />
      );
    }
  }
  const mapStateToProps = ({ vaccinationHistory }) => {
    return {
      vaccinationHistory,
    };
  };
  const WithVaccinationHistory = connect(
    mapStateToProps, 
    vaccinationHistoryActions
  )
  (VaccinationHistory);
  hoistNonReactStatics(WithVaccinationHistory, WrapperComponent);
  return compose(withDeleteConfirmation)(WithVaccinationHistory);
};
