import { BaseActionClass } from "../common/BaseActionClass"
import { PERSONAL_INFO } from '../types'

const path = "/dashboard/personal_info";
const name = "Person";

class PersonalInfo extends BaseActionClass {

  constructor() { super(path, PERSONAL_INFO, name) }

  addPersonalInfo = this._add;

  updatePersonalInfo = this._update;

  deletePersonalInfoById = this._deleteById;

  getPersonalInfo = this._getAll;

  getPersonalInfoById = this._getById;

  deleteBulkPersonalInfo = this._deleteBulk;
}

export const { 
  addPersonalInfo, 
  updatePersonalInfo, 
  deletePersonalInfoById,
  getPersonalInfo,
  getPersonalInfoById,
  deleteBulkPersonalInfo,
} = new PersonalInfo()

