import { getAllForm, getDocuments, getPersonalInfo } from 'actions';
import { useFetchContracts } from 'hooks/useFetchContracts';
import React, { Component } from 'react';
import { useDispatch, useSelector} from 'react-redux';
import { withPersonalInfo } from '../PersonalInfo';
import styled from 'styled-components/macro';

const Small = styled.small`
  color: red;
  font-size: 20px;
`;

function bytesToSize(bytes) {
  var sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'P'];
  for (var i = 0; i < sizes.length; i++) {
    if (bytes <= 1024) {
      return bytes + sizes[i];
    } else {
      bytes = parseFloat(bytes / 1024).toFixed(2);
    }
  }
  return bytes + ' P';
}

const withFileSize = (WrappedComponent) => (props) => {
  const { contractList } = useFetchContracts(null, props?.tableName) || [];
  const dispatch = useDispatch();
  const [file, setFile] = React.useState(false);

  // Form pdf_file
  const Form = useSelector((state) => state.forms.data);

  const Formfiltered = Form.filter(function (value) {
    return value?.pdf_file?.size != null;
  });
  
  const FormSize = Formfiltered.reduce(function (index, value) {
    return index + value?.pdf_file?.size;
  }, 0);



  // Document
  const document = useSelector((state) => state.documents.data);

  const documentfiltered = document.filter(function (value) {
    return value?.document_size != null;
  });
  
  const DocumentSize = documentfiltered.reduce(function (index, value) {
    return index + value?.document_size || 0;
  }, 0);


// This is for the attachement file sent from the an email 
// into the admincat

const attachFiltered = document.filter(function (value) {
  return value?.size !== null;
});
const  attachSize = attachFiltered.reduce(function (index, value) {
  return index + parseFloat( value?.size) || 0;
}, 0);

  // Personal
  const personal = useSelector((state) => state.personalInfo.data);
  const Personalfiltered = personal.filter(function (value) {
    return value.person_picture.size != null;
  });
  const PersonalSize = Personalfiltered.reduce(function (index, value) {
    return index + value.person_picture.size;
  }, 0);

  // Contract
  const ContractFileSize = contractList.reduce(function (index, value) {
    return index + value.document.size;
  }, 0);

  const TotalFileSize = DocumentSize + PersonalSize + ContractFileSize + FormSize +attachSize;
  const fileSizeinDetails = bytesToSize(TotalFileSize);

  React.useEffect(() => {
    if (fileSizeinDetails == '1GB' || fileSizeinDetails > '1GB') {
      // setFile(true);
    }
  }, [fileSizeinDetails]);

  React.useEffect(() => {
    dispatch(getDocuments());
    dispatch(getPersonalInfo());
    dispatch(getAllForm());
  }, [dispatch]);

  let msg;
  const Message = file
    ? (msg = (
        <Small>
          You have reach your File limit. You cannot upload any file or send emails.
        </Small>
      ))
    : null;

  return (
    <WrappedComponent
      {...props}
      totalFileSize={fileSizeinDetails}
      isFile={file}
      msg={Message}
    />
  );
};

export default withFileSize;
