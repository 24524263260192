import axios from '../../axios';
import * as types from '../types';
import { setMsgInfo } from '..';
import filterError from '../common/filterError';
import API from '../api';

const baseUrl = API + '/admin/entry_proposal/';

export const getAllEntryProposals = () => {
  return async (dispatch) => {
    dispatch({ type: types.ENTRY_PROPOSAL_LOADING });
    try {
      const { data } = await axios.get(`${baseUrl}?requires_action=${true}`);
      dispatch({
        type: types.GET_ALL_ENTRY_PROPOSAL_SUCCESS,
        payload: data,
      });
      return data;
    } catch (err) {
      const errors = filterError(err);
      dispatch({ type: types.ENTRY_PROPOSAL_FAILURE, payload: errors });
      dispatch(
        setMsgInfo({
          success: false,
          msg: errors,
        }),
      );
      throw err;
    }
  };
};

export const getEntryProposal = (id) => {
  return async (dispatch) => {
    dispatch({ type: types.ENTRY_PROPOSAL_LOADING });
    try {
      const { data } = await axios.get(`${baseUrl}${id}/`);
      dispatch({
        type: types.GET_ENTRY_PROPOSAL_SUCCESS,
        payload: data,
      });
      return data;
    } catch (err) {
      const errors = filterError(err);
      dispatch({ type: types.ENTRY_PROPOSAL_FAILURE, payload: errors });
      dispatch(
        setMsgInfo({
          success: false,
          msg: errors,
        }),
      );
      throw err;
    }
  };
};

export const approveProposal = (payload) => {
  return async (dispatch) => {
    dispatch({ type: types.ENTRY_PROPOSAL_LOADING });
    try {
      const { data } = await axios.put(
        `${baseUrl}${payload.id}/approve/`,
        payload, // This is the JSON payload
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );
      dispatch({
        type: types.APPROVE_ENTRY_PROPOSAL_SUCCESS,
        payload: data,
      });
      dispatch(
        setMsgInfo({
          success: true,
          msg: 'Proposal approved successfully',
        }),
      );
      return data;
    } catch (err) {
      const errors = filterError(err);
      dispatch({ type: types.ENTRY_PROPOSAL_FAILURE, payload: errors });
      dispatch(
        setMsgInfo({
          success: false,
          msg: errors,
        }),
      );
      throw err;
    }
  };
};

export const rejectProposal = (id) => {
  return async (dispatch) => {
    dispatch({ type: types.ENTRY_PROPOSAL_LOADING });
    try {
      const { data } = await axios.put(`${baseUrl}${id}/reject/`);
      dispatch({
        type: types.REJECT_ENTRY_PROPOSAL_SUCCESS,
        payload: data,
      });
      dispatch(
        setMsgInfo({
          success: true,
          msg: 'Proposal rejected successfully',
        }),
      );
      return data;
    } catch (err) {
      const errors = filterError(err);
      dispatch({ type: types.ENTRY_PROPOSAL_FAILURE, payload: errors });
      dispatch(
        setMsgInfo({
          success: false,
          msg: errors,
        }),
      );
      throw err;
    }
  };
};

export const bulkDeleteProposals = (ids) => {
  return async (dispatch) => {
    dispatch({ type: types.ENTRY_PROPOSAL_LOADING });
    try {
      // Perform the bulk delete operation
      await axios.delete(`${baseUrl}bulk_delete/`, { data: { ids } });

      // Fetch the updated list of proposals
      const { data } = await axios.get(`${baseUrl}/requires_action=${true}`);

      dispatch({
        type: types.BULK_DELETE_ENTRY_PROPOSAL_SUCCESS,
        payload: data, // Now we're passing the updated list instead of just the deleted IDs
      });
      dispatch(
        setMsgInfo({
          success: true,
          msg: 'Proposals deleted successfully',
        }),
      );
      return data;
    } catch (err) {
      const errors = filterError(err);
      dispatch({ type: types.ENTRY_PROPOSAL_FAILURE, payload: errors });
      dispatch(
        setMsgInfo({
          success: false,
          msg: errors,
        }),
      );
      throw err;
    }
  };
};
