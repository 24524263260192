import Contract from '../common/Contract';
import ContractItem from '../common/ContractItem';
import ContractItemForm from "../common/ContractItem/Form";
import ContractForm from '../common/Contract/ContractForm';
import WorkExperience from './WorkExperience';
import WorkExperienceForm from './WorkExperience/Form';

const path = '/dashboard/data/work';

export default [
  {
    name: 'Contract',
    path: `${path}/contract`,
    icon: 'user',
    component: Contract,
    exact:true,
  },
  {
    name: 'Contract Form',
    path: [
      `${path}/contract/create`,
      `${path}/contract/:id`,
      `${path}/contract/:id/update`,
    ],
    exact: true,
    icon: 'user',
    component: ContractForm,
    hide: true,
  },
  {
    name: 'Contract Form Item',
    path: [
      `${path}/contract-item/create`,
      `${path}/contract-item/:id`,
      `${path}/contract-item/:id/update`,
    ],
    exact: true,
    icon: 'user',
    component: ContractItemForm,
    hide: true,
  },
  {
    name: "Work Experience",
    path: `${path}/experience`,
    icon: "user",
    component: WorkExperience,
    exact: true,
  },
  {
    name: "Work Experience Form",
    path: [
      `${path}/experience/create`,
      `${path}/experience/:id`,
      `${path}/experience/:id/update`,
    ],
    icon: "user",
    component: WorkExperienceForm,
    exact: true,
  },
];

export const redirects = [
  {
    from: `${path}`,
    to: `${path}/contract`,
  },
  {
    from: `${path}/*`,
    to: `${path}/contract`,
  },
];
