import React from 'react';
import routes, { redirects } from './routes';
import DashboardTemplate from '../../common/DashboardTemplate';
import RouteRedirect from '../../common/RouteRedirect';

const MyData = () => {
  return (
    <DashboardTemplate
      showSideBar={false}
      showSubmenu={true}
      routes={routes}
      redirects={redirects}
      stripeHeaderTitle="Home"
      render={(renderPropsData) => <RouteRedirect {...renderPropsData} />}
    />
  );
};

export default MyData;
