import * as types from "../../actions";
import { baseViewForm } from '../utils/baseViewForm';

const initialState = {
  data: [],
  showForm: false,
};

const contactInfoForm = (state = initialState, action = {}) => {
  return baseViewForm(types.CONTACT_INFO_FORM)(state, action);
}

const contactInfoView = baseViewForm(types.CONTACT_INFO_VIEW, 'view');


const contactInfo = (state = [], action = {}) => {
  switch (action.type) {
    case types.ADD_CONTACT_INFO: {
      return [
        action.payload,
        ...state,
      ]
    }

    case types.UPDATE_CONTACT_INFO: {
      return state.map((data, index) => {
        if (data.id === action.payload.id) {
          return action.payload;
        }
        return data;
      });
    }

    case types.DELETE_CONTACT_INFO: {
      return state.filter((data, index) => {
        return data.id !== action.payload.id;
      });
    }

    case types.DELETE_BULK_CONTACT_INFO: {
      return state.filter((data, index) => {
        return !action.payload.includes(data.id);
      });
    }

    case types.GET_CONTACT_INFO: {
      return state.find((data, index) => {
        return data.id === action.payload.id;
      });
    }

    case types.GET_ALL_CONTACT_INFO: {
      return action.payload;
    }

    
    default:
      return state
  }
}
export default { contactInfo, contactInfoForm, contactInfoView };



// import { CONTACT_INFO  } from "actions/types";
// import { baseStore } from "../common/baseStore"

// export default baseStore(CONTACT_INFO)
