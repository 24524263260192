import React, { Component } from 'react';
import {
  EditorState,
  ContentState,
  convertFromHTML,
  DefaultDraftBlockRenderMap,
  ContentBlock,
} from 'draft-js';
// import { convertFromHTML } from 'draft-convert';
// import { convertFromHTML } from 'draft-convert';
import parse from 'html-react-parser';
import htmlToDraft from 'html-to-draftjs';
import { Editor } from 'react-draft-wysiwyg';
import compose from 'lodash/fp/compose';
import styled from 'styled-components';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { List } from 'immutable';
import Immutable from 'immutable';
import draft from 'draft-js';
import { withTranslation, withFieldError } from '../../../hoc';
import { inputStyle, InputWrapper } from '../Input';
import './style.css';

const StyledTextArea = styled.textarea`
  ${inputStyle}
  height: 150px;
  resize: none;
  outline: none;
  width: 100%;
`;

//Root Wrapper of the Editor
const EditorWrapper = styled.div`
  min-width: 700px;
  display: flex;
  flex-direction: column;
  height: fit-content;
  margin-top: 3em;
`;

//DraftEditor Container
const EditorContainer = styled.div`
  display: flex;
  min-height: 9em;
  border-radius: 0 0 3px 3px;
  background-color: #fff;
  padding: 5px;
  font-size: 17px;
  font-weight: 300;
  box-shadow: 0px 0px 3px 1px rgba(15, 15, 15, 0.17);
`;

const blockRenderMap = Immutable.Map({
  'header-one': {
    element: 'h1',
  },
  'header-two': {
    element: 'h2',
  },
  'header-three': {
    element: 'h3',
  },
  'header-four': {
    element: 'h4',
  },
  'header-five': {
    element: 'h5',
  },
  'header-six': {
    element: 'h6',
  },
  paragraph: {
    element: 'p',
  },
});

// // Include 'paragraph' as a valid block and updated the unstyled element but
// // keep support for other draft default block types
const extendedBlockRenderMap = DefaultDraftBlockRenderMap.merge(blockRenderMap);

function replaceDivsWithPs(element) {
  var divTags = element.querySelectorAll('div');
  for (var i = 0; i < divTags.length; i++) {
    var div = divTags[i];
    var p = document.createElement('p');
    p.innerHTML = div.innerHTML;
    div.parentNode.replaceChild(p, div);
    replaceDivsWithPs(p); // Recursively replace divs inside the new p tag
  }
}

function removeInvalidTags(htmlString) {
  var tempElement = document.createElement('div');
  tempElement.innerHTML = htmlString;

  var tagsToRemove = tempElement.querySelectorAll('*');
  for (var i = 0; i < tagsToRemove.length; i++) {
    var tag = tagsToRemove[i];
    if (tag.innerHTML.trim() === '' || tag.tagName === 'BR') {
      tag.parentNode.removeChild(tag);
    }
  }

  return tempElement.innerHTML;
}

function removeEmptyTags(element) {
  var childNodes = Array.from(element.childNodes);
  for (var i = childNodes.length - 1; i >= 0; i--) {
    var node = childNodes[i];
    if (node.nodeType === Node.ELEMENT_NODE) {
      if (!node.innerHTML || isWhitespace(node.innerHTML)) {
        node.parentNode.removeChild(node);
      } else {
        removeEmptyTags(node);
      }
    }
  }
}

function isWhitespace(str) {
  return str.trim().length === 0;
}

function convertManualHTML(htmlString) {
  var tempElement = document.createElement('div');
  tempElement.innerHTML = htmlString;

  replaceDivsWithPs(tempElement);

  var fontTags = tempElement.querySelectorAll('font');
  for (var i = 0; i < fontTags.length; i++) {
    var font = fontTags[i];
    var span = document.createElement('span');
    var fontSize = parseInt(font.getAttribute('size')) * 6;
    span.style.fontSize = fontSize + 'px';

    var color = font.getAttribute('color');
    if (color) {
      span.style.color = color;
    }

    span.innerHTML = font.innerHTML;
    font.parentNode.replaceChild(span, font);
  }

  var tagsToReplace = {
    b: 'strong',
    i: 'em',
    u: 'ins',
  };
  for (var tag in tagsToReplace) {
    var elements = tempElement.querySelectorAll(tag);
    for (var i = 0; i < elements.length; i++) {
      var element = elements[i];
      var newTag = document.createElement(tagsToReplace[tag]);
      newTag.innerHTML = element.innerHTML;
      element.parentNode.replaceChild(newTag, element);
    }
  }

  var cleanedHTML = removeInvalidTags(tempElement.innerHTML);
  removeEmptyTags(tempElement);
  var cleanedHTML = removeInvalidTags(tempElement.innerHTML);
  return cleanedHTML;
}

function uploadImageCallBack(file) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('POST', 'https://api.imgur.com/3/image');
    xhr.setRequestHeader('Authorization', 'Client-ID 14d8559ca4d04c2');
    const data = new FormData();
    data.append('image', file);
    xhr.send(data);
    xhr.addEventListener('load', () => {
      const response = JSON.parse(xhr.responseText);
      resolve(response);
    });
    xhr.addEventListener('error', () => {
      const error = JSON.parse(xhr.responseText);
      reject(error);
    });
  });
}

class RichTextEditor extends Component {
  constructor(props) {
    super(props);
    const htmlWriteUp =
      props?.selectedMail?.message_html || props?.selectedMail?.message;

    const convertedHtml = htmlToDraft(convertManualHTML(htmlWriteUp || ''));

    this.state = {
      editorState: EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertedHtml.contentBlocks,
          convertedHtml.entityMap,
        ),
      ),
    };
  }

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
    this.props.onChange({ target: { value: editorState, name: 'message' } });
  };

  render() {
    const {
      className,
      label,
      required,
      name,
      tr,
      placeholder,
      value,
      onChange,
      ...rest
    } = this.props;

    return (
      <InputWrapper className={this.props.className}>
        {label && (
          <label className="form-label" htmlFor={name}>
            {tr(label || '')}
            {required && <span className="form-required">*</span>}
          </label>
        )}

        <Editor
          placeholder={tr(placeholder)}
          editorState={this.state.editorState}
          onEditorStateChange={this.onEditorStateChange}
          blockRenderMap={extendedBlockRenderMap}
          toolbar={{
            options: [
              'inline',
              'fontSize',
              'fontFamily',
              'list',
              'textAlign',
              'colorPicker',
              'link',
              'emoji',
              'image',
              'history',
            ],
            inline: { inDropdown: false, options: ['bold', 'italic', 'underline'] },
            fontFamily: {
              options: [
                'Arial',
                'Calibri',
                'Courier',
                'Garamond',
                'Georgia',
                'Impact',
                'Roboto',
                'Tahoma',
                'Serif',
                'Verdana',
              ],
            },
            image: {
              uploadCallback: uploadImageCallBack,
              urlEnabled: false,
              previewImage: true,
              alt: { present: false, mandatory: false },
            },
          }}
          editorStyle={{
            border: '1px solid var(--admincat-color-grey-2)',
            height: '30vh',
            width: '100%',
            padding: '0.5rem',
            color: 'var(--admincat-color-grey-2)',
          }}
          {...rest}
        />
      </InputWrapper>
    );
  }
}

RichTextEditor.defaultProps = {
  className: '',
};

export default compose(withFieldError, withTranslation)(RichTextEditor);
