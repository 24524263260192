const COMMUNICATION = 'C';
const DATA_ADMIN = 'DA';
const SUPPORT = 'S';
const ADMINISTRATION = 'A';
const SUPER_USER = 'SU';

export const ADMIN_TYPES = {
  COMMUNICATION,
  DATA_ADMIN,
  SUPPORT,
  ADMINISTRATION,
  SUPER_USER,
};
