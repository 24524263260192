/* eslint-disable */
import React, { useState } from 'react';
import compose from 'lodash/fp/compose';
import { Link, NavLink, withRouter, useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { Icon } from 'semantic-ui-react';
import { withTranslation, withAccount, withUserPreferences } from '../hoc';
import logo from '../../static/demo/brand/Admincatlogo1.png';
import logoInverted from '../../static/demo/brand/AdminCatinverted.png';
import BaseDropdown from '../common/BaseDropdown';
import { useScrollPosition } from '../../hooks/useScrollPosition';
import {
  StyledHeader,
  Logo,
  Avatar,
  UserName,
  Navbar,
  NavMenu,
  Dropdown,
  AvatarSection,
} from './styled';
import { useTranslation } from 'react-i18next';
import axios from '../../axios';
import DarkModeToggle from 'components/common/DarkModeToggle';
import { useDarkMode } from 'context/DarkModeContext';

const DropdownMenu = BaseDropdown.DropdownMenu;

const routes = [
  {
    path: '/login',
    name: 'Login',
    authRequired: false,
  },
  {
    path: '/register',
    name: 'Get started',
    authRequired: false,
    className: 'blue-link',
  },
];

const dropdownMenuLinks = [
  {
    path: '/dashboard/space-admin',
    name: 'Space Member',
    icon: 'user outline',
    isSpaceAdmin: true,
  },
  {
    path: '/dashboard/settings',
    name: 'Settings',
    icon: 'setting',
  },

  {
    path: '/dashboard/contact-us',
    name: 'Contact Us',
    icon: 'comment outline',
  },
];

const LogoWrapper = styled(Link)`
  display: block;
  height: 100%;
  margin-left: 60px;
`;

const DropdownMenuLink = styled(Link)`
  display: flex;
`;

const SelectItem = styled.select`
  /* margin-left: 55em; */
  padding: 10px 15px;
  background: #f8f9fc;
  border: solid 1px #1f1f1f;
  font-size: 10px;
  height: 40px;
  border-radius: 2px;
`;

class DropdownSection extends BaseDropdown {
  usernamePref = JSON.parse(localStorage.getItem('username'));
  render() {
    const {
      tr,
      account: {
        user: { username, isAuth, space_admin },
      },
      logout,
      className = '',
      showHamburger,
    } = this.props;

    return (
      <Dropdown className={className}>
        {showHamburger ? (
          <div
            ref={(e) => (this.dropdownTriggerRef = e)}
            onClick={this.changeDropdownState}
          >
            <StyledIcon name="bars" />
          </div>
        ) : (
          <AvatarSection
            ref={(e) => (this.dropdownTriggerRef = e)}
            onClick={this.changeDropdownState}
            isAuth={isAuth}
          >
            <Avatar />
            <UserName>{username}</UserName>
          </AvatarSection>
        )}
        <DropdownMenu ref={(e) => (this.dropdownRef = e)}>
          {dropdownMenuLinks
            .filter(({ isSpaceAdmin }) => {
              if (isSpaceAdmin && !space_admin) return false;
              return true;
            })
            .map(({ icon, name, path }) => (
              <DropdownMenu.Item key={name}>
                <DropdownMenuLink to={path}>
                  <Icon name={icon} />
                  <span>{tr(name)}</span>
                </DropdownMenuLink>
              </DropdownMenu.Item>
            ))}
          <DropdownMenu.Item onClick={logout}>
            <Icon name="sign-out" />
            <span>{tr('Sign out')}</span>
          </DropdownMenu.Item>
        </DropdownMenu>
      </Dropdown>
    );
  }
}

const NavbarSection = (props) => {
  const { t, i18n } = useTranslation();
  // const [language, setLanguage] = useState(null);
  const [language, setLanguage] = useState("");

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  React.useEffect(() => {
    let selected = localStorage.getItem('i18nextLng');
    selected = selected == 'en' ? 'eng' : selected == 'de' ? 'deu' : '';
    setLanguage(selected);
  }, [language]);

  const handleOnChange = (e) => {
    if (e.target.value == 'deu') {
      changeLanguage('deu');
      setLanguage('deu');
      localStorage.setItem('i18nextLng', 'de');
      axios.defaults.headers.common['Accept-Language'] = 'de';
    } else if (e.target.value == 'eng') {
      changeLanguage('eng');
      setLanguage('eng');
      localStorage.setItem('i18nextLng', 'en');
      axios.defaults.headers.common['Accept-Language'] = 'en';
    }
  };

  const {
    tr,
    account: {
      user: { isAuth = false },
    },
    className = '',
  } = props;

  const DetectLanguage = isAuth ? tr : t;

  return (
    <Navbar className={`NavBar ${className}`}>
      {isAuth ? null : (
        <Navbar.Item>
          <SelectItem
            onChange={handleOnChange}
            value={language || ""}
            style={{ width: '100%' }}
          >
            <option value="eng">ENG</option>
            <option value="deu">DEU</option>
          </SelectItem>
        </Navbar.Item>
      )}

      {routes
        .filter(({ authRequired }) => {
          return authRequired === undefined || authRequired === isAuth;
        })
        .map(({ path, name, exact = false, className }, index) => (
          <Navbar.Item key={index}>
            <NavLink
              to={path}
              exact={exact}
              className={className}
              style={{ width: '100%' }}
            >
              {DetectLanguage(name)}
            </NavLink>
          </Navbar.Item>
        ))}
    </Navbar>
  );
};

const mobileBreakpoint = '800px';

const StyledMobileHeader = styled(StyledHeader)`
  display: none;
  @media (max-width: ${mobileBreakpoint}) {
    display: flex;
  }
`;

const MobileNavMenu = styled(NavMenu)`
  justify-content: space-between;
  width: 100%;
  align-items: center;
  transition: all 300ms ease-in-out;
`;

const StyledIcon = styled(Icon)`
  && {
    font-size: 2rem;
    margin: auto 20px;
    cursor: pointer;
  }
`;

const MobileNavbarSection = styled(NavbarSection)`
  flex-direction: column;
  width: 100%;
  margin: 0;
  height: 100%;
  overflow: hidden;
  transition: all 300ms ease-in-out;
  justify-content: flex-start;
`;

const MobileNavMenuWrapper = styled.div`
  position: absolute;
  top: 100%;
  right: 0; /* Update the position to right */
  height: 100vh;
  background: #fff;
  box-shadow: 3px 3px 30px rgba(0, 0, 0, 0.3);
  z-index: 12000;
  padding-right: 2%;
  display: flex;
  justify-content: flex-start;
  transition: all 300ms ease-in-out;
  flex-direction: column; /* Align items vertically */
  align-items: center; /* Align items to the right */
`;

// const MobileDropdownSection = styled(DropdownSection)`
//   width: 170px;
//   margin: 2px 0;
//   position: relative;
//   background: blue;
//   div:nth-child(2) {
//     top: 24px;
//     right: auto;
//     left: 10px;
//   }
// `;

class MobileHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSubMenu: false,
    };
  }

  toggleSubMenu = () => {
    this.setState((prevState) => ({
      showSubMenu: !prevState.showSubMenu,
    }));
  };

  render() {
    const {
      account: {
        user: { isAuth },
      },
    } = this.props;

    return (
      <StyledMobileHeader>
        <MobileNavMenu>
          {Boolean(isAuth === true) ? (
            <Logo src={logo} />
          ) : (
            <LogoWrapper to="/">
              <Logo src={logo} />
            </LogoWrapper>
          )}
        </MobileNavMenu>
        {Boolean(isAuth === true) ? (
          <div>
            <DarkModeToggle />
            <DropdownSection {...this.props} showHamburger />
          </div>
        ) : (
          <div style={{ margin: '5%' }} onClick={this.toggleSubMenu}>
            <StyledIcon name="bars" />
          </div>
        )}
        {this.state.showSubMenu && (
          <MobileNavMenuWrapper ref={(e) => (this.dropdownRef = e)}>
            <div>
              <MobileNavbarSection {...this.props} />
            </div>
          </MobileNavMenuWrapper>
        )}
      </StyledMobileHeader>
    );
  }
}

// const { isDarkMode } = useDarkMode();
//background-color: ${(props) => (props.isDarkMode ? 'black' : 'white')};

// background: ${({ isAuth }) => (isAuth ? '--admincat-color-grey-0' : '')}
const DesktopHeader = styled(StyledHeader)`
  @media (max-width: ${mobileBreakpoint}) {
    display: none;
  }
`;

const DesktopHeaderColormode = styled(StyledHeader)`
  background: var(--admincat-color-grey-1);
  margin-bottom: 5px;
  // background: ${({ isAuth }) => (isAuth ? 'var(--admincat-color-grey-1)' : null)};
  @media (max-width: ${mobileBreakpoint}) {
    display: none;
  }
`;

const StyledDropdownSection = styled(DropdownSection)`
  margin-right: 50px;
`;

// background: ${({ isAuth }) =>Boolean(isAuth === true) && 'var(--admincat-color-grey-1)'};

const HeaderWrapper = styled.div`
  background: #fff;
  padding: 0 0rem;
  ${({ fixHeader }) =>
    fixHeader &&
    css`
      position: sticky;
      z-index: 2000;
      top: 0;
    `}
`;

const Header = (props) => {
  const { position } = useScrollPosition();
  const {
    account: {
      user: { isAuth },
    },
  } = props;

  const { isDarkMode } = useDarkMode();
  const location = useLocation();

  const src = isDarkMode ? logoInverted : logo;

  // Render null if the route is /admin because of the admin ui design
  if (location.pathname === '/admin') {
    return null;
  }

  return (
    <HeaderWrapper fixHeader={position > 0} isAuth>
      {Boolean(isAuth === true) ? (
        <>
          <DesktopHeaderColormode>
            <NavMenu>
              {Boolean(isAuth === true) ? (
                <LogoWrapper to="/dashboard">
                  {/* <LogoWrapper to="/dashboard/home"> */}
                  <Logo src={src} />
                </LogoWrapper>
              ) : (
                <LogoWrapper to="/">
                  <Logo src={logo} />
                </LogoWrapper>
              )}

              <NavbarSection {...props} />
            </NavMenu>

            {Boolean(isAuth === true) && <DarkModeToggle />}

            <StyledDropdownSection {...props} />
          </DesktopHeaderColormode>
          <MobileHeader {...props} />
        </>
      ) : (
        <>
          <DesktopHeader>
            <NavMenu>
              {Boolean(isAuth === true) ? (
                <LogoWrapper to="/dashboard">
                  {/* <LogoWrapper to="/dashboard/home"> */}
                  <Logo src={src} />
                </LogoWrapper>
              ) : (
                <LogoWrapper to="/">
                  <Logo src={logo} />
                </LogoWrapper>
              )}

              <NavbarSection {...props} />
            </NavMenu>

            {Boolean(isAuth === true) && <DarkModeToggle />}

            <StyledDropdownSection {...props} />
          </DesktopHeader>
          <MobileHeader {...props} />
        </>
      )}
    </HeaderWrapper>
  );
};

export default compose(
  withRouter,
  withAccount,
  withTranslation,
  withUserPreferences,
)(Header);
