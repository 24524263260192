import * as types from "../../actions/types";

const initialState = {
  data: [],
  loading: false,
  serverInbox: [],
};

export const mail = (state=initialState, action={}) => { 


  switch(action.type){
    case types.GET_MAILS: {
      const data = action.payload;
      return {
        ...state,
        data: data || state.data,
      };
    }
    case types.GET_MAIL_SERVER_INBOX: {
      const data = action.payload;
      return {
        ...state,
        serverInbox: data,
      };
    }
    case types.ADD_MAIL: {
      const data = action.payload;
      return {
        ...state,
        data: [data, ...state.data],
      };
    }
    // case types.UPDATE_BULK_MAIL: {
    //   const { ids, area } = action.payload;
    //   return {
    //     ...state,
    //     data: state.data.map((data, index) => {
    //       if (ids.includes(data.id)) data.area = area
    //       return data;
    //     }),
    //   }    
    // }
    case types.DELETE_MAIL: {
      return {
        ...state,
        data: state.data.filter((data, index) => {
          return data.id !== action.payload.id;
        }),
      }    
    }
    case types.DELETE_BULK_MAIL: {
      return {
        ...state,
        data: state.data.filter((data, index) => {
          return !action.payload.includes(data.id);
        }),
      }    
    }
    case types.DELETE_MAIL_PARTIALLY: {
      return {
        ...state,
        data: state.data.map((data, index) => {
          if (data.id === action.payload) {
            data.is_deleted = true;
          }
          return data;
        }),
      }    
    }
    case types.DELETE_BULK_MAIL_PARTIALLY: {
      return {
        ...state,
        data: state.data.filter((data, index) => {
          if (action.payload.includes(data.id)) {
            data.is_deleted = true;
          }
          return data;
        }),
      }    
    }
    case types.MARK_MAIL: {
      return {
        ...state,
        loading: action.payload.loading
      }    
    }
    case types.UNDO_MAIL_DELETE: {
      return {
        ...state,
        loading: action.payload.loading
      }
    }
    default: return state;
  }
}
