import axios from '../axios';
import * as types from './types';
import { createTypes } from './createTypes';
import { setMsgInfo } from './';
import API from './api';
import filterError from './common/filterError';
import { getState } from './preload';

const baseUrl = API + '/organisation';

export const addOrganisation = (payload, successMessage) => {
  return async (dispatch) => {
    dispatch({ type: createTypes(types.ADD_ORGANISATION).loading });
    try {
      const { data } = await axios.post(`${baseUrl}/`, payload);
      dispatch({
        type: createTypes(types.ADD_ORGANISATION).success,
        payload: data,
      });
      dispatch({
        type: types.ORGANISATION_FORM,
        payload: { showForm: false, orgFormData: {} },
      });
      dispatch(
        setMsgInfo({
          success: true,
          msg: [successMessage],
        }),
      );
      return data;
    } catch (err) {
      const errors = filterError(err);
      dispatch({
        type: createTypes(types.ADD_ORGANISATION).failure,
        payload: errors,
      });
      dispatch(
        setMsgInfo({
          success: false,
          msg: errors,
        }),
      );
      throw new Error(err);
    }
  };
};

export const updateOrganisation = (id, formData) => {
  for (const key of formData.keys()) {
    if (formData.get(key) === 'null') {
      formData.delete(key);
    }
  }

  return async (dispatch) => {
    dispatch({
      type: createTypes(types.UPDATE_ORGANISATION).loading,
    });
    try {
      const { data } = await axios.patch(`${baseUrl}/${id}/`, formData);
      dispatch({
        type: createTypes(types.UPDATE_ORGANISATION).success,
        payload: data,
      });
      dispatch(
        setMsgInfo({
          success: true,
          msg: ['Organisation updated successfully'],
        }),
      );
    } catch (err) {
      const errors = filterError(err);
      dispatch({
        type: createTypes(types.UPDATE_ORGANISATION).failure,
        payload: errors,
      });
      dispatch(
        setMsgInfo({
          success: false,
          msg: errors,
        }),
      );
    }
  };
};

// export const setOrgData = (orgData) => ({
//   type: 'SET_ORG_DATA',
//   payload: orgData,
// });


export const getOrganisations = (query) => {
  const queryParams = query ? query : '';
  return async (dispatch) => {
    dispatch({ type: createTypes(types.GET_ORGANISATION).loading });
    try {
      const { data } = await axios.get(`${baseUrl}-list/${queryParams}`);
      dispatch({
        type: createTypes(types.GET_ORGANISATION).success,
        payload: data,
      });
    } catch (err) {
      const errors = filterError(err);
      dispatch({
        type: createTypes(types.GET_ORGANISATION).failure,
        payload: errors,
      });
      dispatch(
        setMsgInfo({
          success: false,
          msg: errors,
        }),
      );
    }
  };
};

const getFormData = (data) => {
  const fields = [
    'organisation_logo',
    'name',
    'org_country',
    'legal_form',
    'register_no',
    'register_doc_type',
    'register_institute',
    'state',
    'no_of_employees',
    'liquidation',
    'foundation',
    'revenue_currency',
    'website',
    'revenue_approx',
    'industry',
  ];
  const formData = {};
  fields.forEach((field) => {
    formData[field] = data[field];
  });
  return formData;
};

export const getOrganisationById = (id, updateForm) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.GET_SINGLE_ORGANISATION,
        payload: { data: {}, loading: true },
      });

      const { data } = await axios.get(`${baseUrl}/${id}/`);
      if (updateForm) {
        const formData = getFormData(data);
        dispatch(getState(`country=${formData.org_country}`));
        // dispatch(getLegalForm(`country=${formData.org_country}`));
        dispatch(getRegisterInstitute(formData.org_country, formData.state));
        dispatch({
          type: types.ORGANISATION_FORM,
          payload: { showForm: false, orgFormData: formData },
        });
        dispatch({
          type: types.GET_SINGLE_ORGANISATION,
          payload: { data, loading: false },
        });
      } else {
        dispatch({
          type: types.GET_SINGLE_ORGANISATION,
          payload: { data, loading: false },
        });
      }
    } catch (err) {
      dispatch({
        type: types.GET_SINGLE_ORGANISATION,
        payload: { data: {}, loading: false },
      });
    }
  };
};

export const getFavoriteOrgById = (id) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.GET_SINGLE_FAVORITE_ORGANISATION,
        payload: { data: {}, loading: true },
      });

      const { data } = await axios.get(`${API}/favorite-org/${id}/`);
      dispatch({
        type: types.GET_SINGLE_FAVORITE_ORGANISATION,
        payload: { data, loading: false },
      });
    } catch (err) {
      dispatch({
        type: types.GET_SINGLE_FAVORITE_ORGANISATION,
        payload: { data: {}, loading: false },
      });
    }
  };
};

export const getRegisterInstitute = (country, state) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(
        API + `/register-institute/?country=${country}&state=${state}`,
      );
      dispatch({ type: types.GET_REGISTER_INSTITUTE, payload: data });
    } catch (err) {
      throw err;
    }
  };
};

export function searchOrganisation(query) {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(`${API}/search-org?${query}`);
      dispatch({ type: types.SEARCH_ORGANISATION, payload: data });
    } catch (err) {
      throw err;
    }
  };
}

export const resetOrgSearchResults = () => {
  return async (dispatch) => {
    dispatch({ type: types.SEARCH_ORGANISATION, payload: [] });
  };
};

export function getFavoriteOrg(type) {
  return async (dispatch) => {
    const orgType = type ? `${type}/` : '';
    try {
      dispatch({
        type: types.FAVORITE_ORGANISATION,
        payload: { data: [], isLoading: true },
      });
      const { data } = await axios.get(`${API}/favorite-org/${orgType}`);
      dispatch({
        type: types.FAVORITE_ORGANISATION,
        payload: { data, isLoading: false },
      });
    } catch (err) {
      dispatch({
        type: types.FAVORITE_ORGANISATION,
        payload: { data: [], isLoading: false },
      });
      throw err;
    }
  };
}

export function getUserSchools(type) {
  return async (dispatch) => {
    const orgType = type ? `${type}/` : '';
    try {
      const { data } = await axios.get(`${API}/favorite-org/${orgType}`);
      dispatch({ type: types.GET_USER_SCHOOLS, payload: data });
    } catch (err) {
      throw err;
    }
  };
}

export function addFavoriteOrg(favoriteOrgIds) {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(`${API}/favorite-org/`, {
        selected_org_ids: favoriteOrgIds,
      });
      dispatch({ type: types.SEARCH_ORGANISATION, payload: data });
    } catch (err) {
      throw err;
    }
  };
}

export function updateFavoriteOrg(favoriteOrgIds) {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(`${API}/favorite-org/`, {
        selected_org_ids: favoriteOrgIds,
      });
      dispatch({ type: types.SEARCH_ORGANISATION, payload: data });
    } catch (err) {
      throw err;
    }
  };
}

export function deleteFavoriteOrg(favoriteOrgId) {
  return async (dispatch) => {
    try {
      await axios.delete(`${API}/favorite-org/${favoriteOrgId}/`);
      dispatch({
        type: types.DELETE_FAVORITE_ORGANISATION,
        payload: [favoriteOrgId],
      });
    } catch (err) {
      throw err;
    }
  };
}

export function deleteBulkFavoriteOrg(favoriteOrgIds) {
  return async (dispatch) => {
    try {
      await axios.post(`${API}/favorite-org/bulk_delete/`, {
        selected_org_ids: favoriteOrgIds,
      });
      dispatch({
        type: types.DELETE_FAVORITE_ORGANISATION,
        payload: favoriteOrgIds,
      });
    } catch (err) {
      throw err;
    }
  };
}
