import React from 'react';
import { withRouter } from 'react-router-dom';
import hoistNonReactStatics from 'hoist-non-react-statics';
import compose from 'lodash/fp/compose';

import { connect } from 'react-redux';
import * as authActions from 'actions/authentication';
import { BaseHOC, withBaseHOCProps } from '../common/BaseHOC';

export const withAuthentication = (WrapperComponent) => {
  class Authentication extends React.Component {
    componentDidMount() {
      const { history } = this.props;
      if (!localStorage.token || !localStorage.user) {
        history.replace('/login');
      }
    }
    render() {
      return <WrapperComponent {...this.props} />;
    }
  }
  hoistNonReactStatics(Authentication, WrapperComponent);
  return compose(withRouter)(Authentication);
};

export const withNoAuthentication = (WrapperComponent) => {
  class NoAuthentication extends React.Component {
    componentDidMount() {
      const { history } = this.props;
      if (localStorage.token && localStorage.user) {
        history.replace('/dashboard');
      }
    }
    render() {
      return <WrapperComponent {...this.props} />;
    }
  }
  hoistNonReactStatics(NoAuthentication, WrapperComponent);
  return compose(withRouter)(NoAuthentication);
};

export const withAdminAuthentication = (WrapperComponent) => {
  class Authentication extends React.Component {
    componentDidMount() {
      const { history } = this.props;
      if (!localStorage.token || !localStorage.user) {
        history.replace('/admin');
      }
    }
    render() {
      return <WrapperComponent {...this.props} />;
    }
  }
  hoistNonReactStatics(Authentication, WrapperComponent);
  return compose(withRouter)(Authentication);
};

export const withNoAdminAuthentication = (WrapperComponent) => {
  class NoAuthentication extends React.Component {
    componentDidMount() {
      const { history } = this.props;
      if (localStorage.token && localStorage.user) {
        history.replace('/admin/dashboard');
      }
    }
    render() {
      return <WrapperComponent {...this.props} />;
    }
  }
  hoistNonReactStatics(NoAuthentication, WrapperComponent);
  return compose(withRouter)(NoAuthentication);
};

let loaded = false;

export const withAuth = (WrapperComponent) => {
  class Auth extends BaseHOC {
    async componentDidMount() {
      const { getUser } = this.props;
      if (!loaded) {
        loaded = true;
        await getUser();
      }
    }

    render() {
      return <WrapperComponent {...this.props} />;
    }
  }
  const mapStateToProps = ({ authentication, user }) => ({ authentication, user });
  const WithAuth = connect(mapStateToProps, {
    ...authActions,
  })(Auth);
  hoistNonReactStatics(WithAuth, WrapperComponent);
  return compose(withBaseHOCProps)(WithAuth);
};
