import React from "react";
import styled from "styled-components"

const MessageWrapper = styled.div`
  font-size: 0.9rem;
  padding: 1rem; 

  ${({ hasError }) => {
    if (hasError) {
      return `
        color: red;
        background: rgba(255,0,0,0.1);
      `
    }
    return `
      color: green;
      background: rgba(0,255,0,0.1);
    `
  }}
`

export const FlashMessage = ({ children, hasError }) => {
  return (
    children && (
      <MessageWrapper hasError={hasError}>{children}</MessageWrapper>
    )
  )
}

export default FlashMessage;
