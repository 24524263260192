import React from "react";
import { connect } from "react-redux";
import {
  getFieldCatalog,
} from "../../../actions";
import hoistNonReactStatics from 'hoist-non-react-statics'


export const withFieldCatalog = (WrapperComponent) => {
  class WithFieldCatalog extends React.Component {
    componentDidMount() {
      const { getFieldCatalog } = this.props;
      if (!WithFieldCatalog.loaded) {
        WithFieldCatalog.loaded = true;
        getFieldCatalog();
      }
    }
    static loaded = false;
    getFieldCatalogLocaleOptions = () => undefined
    getFieldCatalogOptions = () => {
      const { fieldCatalog } = this.props;
      return fieldCatalog.map(({ created_at, ...rest }) => ({ ...rest }));
    }
    getFieldCatalogByTable = (targetTable) => {
      const tables = this.getFieldCatalogOptions();
      if (!targetTable) return tables;
      return tables
        .filter(({ table }) => table === targetTable)
        .map(({ field }) => field);
    }
    render() {
      return (
        <WrapperComponent 
          {...this.props} 
          fieldCatalogOptions={this.getFieldCatalogOptions()} 
          fieldCatalogLocaleOptions={this.getFieldCatalogLocaleOptions()} 
          getFieldCatalogByTable={this.getFieldCatalogByTable}
        />
      )
    }
  }
  const mapStateToProps = ({ fieldCatalog }) => ({ fieldCatalog })
  const WithFieldCatalogHOC = connect(mapStateToProps, {
    getFieldCatalog,
  })(WithFieldCatalog);
  hoistNonReactStatics(WithFieldCatalogHOC, WrapperComponent)
  return WithFieldCatalogHOC
}
