import React from 'react';
import compose from 'lodash/fp/compose';
import styled from 'styled-components';
import axios from '../../../../axios';
import {
  // TrashFill,
  PencilFill,
  // Grid1x2Fill,
  ReplyFill,
  ForwardFill,
  // Archive,
  ArrowCounterclockwise,
  PrinterFill,
} from 'react-bootstrap-icons';
import {
  Select,
  // Input,
  Button,
  // ButtonLink,
} from 'components/common/FormItems';
import LottieLoader from 'components/common/LottieLoader';
import {
  withMail,
  withBulkDelete,
  withTranslation,
  withDataArea,
  withUserLayoutChoice,
  withUserDefaultSpace,
  withUserPreferences,
} from 'components/hoc';
import withFileSize from 'components/hoc/FileSizeChecker/File';
// import { layoutColumnProps } from "../common/layoutColumnProps";
import MailList from '../MailList';
import MailLetter from '../MailLetter';
import { device } from '../../../common/responsiveUtils';
import Compose from '../Compose-old';
// import Compose from '../ComposePage';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import { isEmptyValue } from 'components/utils/checkValue';
import { toBase64 } from 'components/utils/toBase64';
import { useDispatch } from 'react-redux';
import swal from 'sweetalert';
import { useLocation } from 'react-router-dom';
import { Dropdown } from 'components/common/ContentTemplate/TableBody.js';
import ReactToPrint from 'react-to-print';
import API from '../../../../actions/api.js';

const Flex = styled.div`
  display: flex;
  margin: 1%;
  align-items: center;
`;

const ResponsiveFlex = styled.div`
  display: flex;
  margin: 1%;
  overflow-x: auto;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 95%;

  @media only screen and ${device.tablet} {
    flex-direction: column;
  }
`;

const MinWidthDiv = styled.div`
  width: 49%;
  border-radius: 12px;
  background: var(--admincat-color-grey-1);
  color: var(--admincat-color-grey-2);
  padding: ${(props) => props.padding || '10px'};
  margin: ${(props) => props.margin || '2px'};
  max-height: 500px;
  height: 500px;
  overflow-y: auto;
  overflow-x: 'auto';

  @media only screen and ${device.tablet} {
    width: 98%;
    margin: ${(props) => props.margin || '2px'};
  }
`;

const StyledButton = styled(Button)`
  // background: #EAEDF1;
  background: var(--admincat-color-grey-6);
  border-radius: 2px;
  height: 38px;
  // color: ${(props) => props.color || 'var(--admincat-color-grey-3)'};
  color: var(--admincat-color-grey-3);
  border: none;
  font-size: 14px;
  line-height: 10px;

  // remove this styles below,
  // if you want to see the full button when resized
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const StyledSelect = styled(Select)`
  &&&& select {
    width: 150px;
    height: 38px;
    text-transform: capitalize;
    margin-bottom: 0;
    margin-right: 5px;
    // background: #eaedf1;
    color: var(--admincat-color-grey-3);
    background: var(--admincat-color-grey-6);
  }

  div.form-group {
    margin-bottom: 0;
  }
`;

const MailInbox = (props) => {
  const dispatch = useDispatch();
  const [selectedMail, setSelectedMail] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [checkedMails, setCheckedMails] = React.useState([]); // an array of all mails checked
  const {
    mail,
    openComposeForm,
    toggleComposeForm,
    deleteMailByIdPartially,
    deleteBulkMailPartially,
    deleteBulkMail,
    userDefaultSpace,
    updateMailArea,
    rowIds,
    mailFormData,
    dataArea,
    tr,
    trObj,
    getLayoutColumnProps,
    isFile,
    msg,
    fd,
    hasBulkDelete,
    filterBy = 'INBOX',
    loaded,
    markMailAsRead,
    markMailAsUnread,
    undoMailDelete,
    bulkDelete = () => {},
    exportMail,
    ...rest
  } = props;

  const mailMessages = mail?.data || [];
  const componentRef = React.useRef();

  const { pathname: path } = useLocation();

  // const frontendTableName = 'inbox';

  // const { columns, layoutColumns } = getLayoutColumnProps(
  //   ...layoutColumnProps(frontendTableName)
  // );

  const isChecked = (mailId) => {
    return checkedMails.includes(mailId);
  };

  const isMasterChecked = () => {
    return checkedMails.length == mailMessages.length && mailMessages.length > 0;
  };

  const selectCheckbox = (id) => {
    // if a single id, select only that id
    if (id) {
      if (checkedMails.includes(id)) {
        // if that item has already been checked, uncheck it
        setCheckedMails([...checkedMails.filter((cid) => cid != id)]);
      } else {
        // check the item, if not check
        setCheckedMails([...checkedMails, id]);
      }
    } else {
      // if no id was given, select all
      if (checkedMails.length == mailMessages.length) {
        // if the master check is active, uncheck it
        setCheckedMails([]);
      } else {
        // if the master check is not active, check it
        setCheckedMails([...mailMessages.map((mail) => mail?.id)]);
      }
    }
  };

  const selectMail = async (id) => {
    // if the mail is already selected, unselect it
    if (id == selectedMail?.id) {
      setSelectedMail(null);
      return;
    }
    const selected = mappedData.filter((mail) => mail.id == id);
    if (selected.length > 0) {
      setSelectedMail(selected[0]);
      await markMailAsRead([selected[0].id]);
    }
    return;
  };

  const getFilterBy = (data) => {
    const isValidSection = data.mail_type === filterBy;
    switch (filterBy) {
      case 'INBOX': {
        return isValidSection && !data.is_deleted;
      }
      case 'SENT': {
        return isValidSection && !data.is_deleted;
      }
      default: {
        return data.is_deleted;
      }
    }
  };

  const convertBytes = (num) => {
    for (let x of ['bytes', 'KB', 'MB', 'GB', 'TB']) {
      if (num < 1024) {
        return `${Number(num).toFixed(2)} ${x}`;
      }
      num /= 1024;
    }
  };

  const getAttachments = (attachments) => {
    if (!isEmptyValue(attachments)) {
      return attachments.map((attachment) => ({
        ...attachment,
        _file: toBase64(attachment),
        size: convertBytes(attachment.size),
      }));
    }
    return [];
  };

  const mappedData = mail.data.filter(getFilterBy).map((data) => {
    const attachments = getAttachments(data.attachments);
    return {
      ...data,
      space_name: userDefaultSpace.space,
      _area: trObj(data.area_detail),
      _attachment: attachments.length,
      size: !isEmptyValue(attachments)
        ? convertBytes(data.total_attachments_size)
        : '',
      __attachments: attachments,
      date: fd(data.created_at),
      // message: parse(data.message)
    };
  });

  if (selectedMail && !mappedData.find((d) => d.id === selectedMail.id)) {
    setSelectedMail(null);
  }

  const deleteMail = async () => {
    if (checkedMails.length == 0) {
      swal(
        tr('Warning!'),
        tr(`You haven't selected any mail to delete.`),
        'warning',
      );
      return;
    }
    // make api call to delete an email
    try {
      if (filterBy == 'TRASH') {
        await deleteBulkMail(checkedMails);
      } else {
        await deleteBulkMailPartially(checkedMails);
      }
    } finally {
      setCheckedMails([]);
    }
  };

  const moveMailToInbox = async () => {
    if (checkedMails.length == 0) {
      swal(tr('Warning!'), tr(`You haven't selected any mail to move.`), 'warning');
      return;
    }
    // make api call to delete an email
    try {
      // make api call here
      await undoMailDelete(checkedMails);
    } finally {
      setCheckedMails([]);
    }
  };

  const markMailAsReadOption = async () => {
    if (checkedMails.length == 0) {
      swal(
        tr('Warning!'),
        tr(`You haven't selected any mail to mark as read.`),
        'warning',
      );
      return;
    }
    // make api call to mark an email
    try {
      await markMailAsRead(checkedMails);
      swal(tr('Success!'), tr(`Mail has been marked`), 'success');
      return;
    } finally {
      setCheckedMails([]);
    }
  };

  const markMailAsUnreadOption = async () => {
    if (checkedMails.length == 0) {
      swal(
        tr('Warning!'),
        tr(`You haven't selected any mail to unmark as read.`),
        'warning',
      );
      return;
    }
    // make api call to mark an email
    try {
      await markMailAsUnread(checkedMails);
      swal(tr('Success!'), tr(`Mail has been unmarked`), 'success');
      return;
    } finally {
      setCheckedMails([]);
    }
  };

  const updateMailAreaOption = async (target) => {
    if (checkedMails.length == 0) {
      swal(
        tr('Warning!'),
        tr(`You haven't selected any mail to assign to an area.`),
        'warning',
      );
      return;
    }
    // make api call to mark an email
    try {
      await updateMailArea(target.value, checkedMails);
      swal(tr('Success!'), tr(`Mail has been assigned`), 'success');
      return;
    } finally {
      setCheckedMails([]);
    }
  };

  const exportMailSample = () => {
    setLoading(true);
    axios
      .get(`${API}/mail/export_mail/`, {
        responseType: 'blob',
      })
      .then((response) => {
        setLoading(false);
        return response.data;
      })
      .then((blob) => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = `exported_mailbox_${new Date().toJSON().slice(0, 10)}.zip`;
        document.body.appendChild(a); // append the element to the dom
        a.click();
        a.remove(); // afterwards, remove the element
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  if (!loaded || loading) {
    return (
      <>
        <LottieLoader />
      </>
    );
  }

  return (
    <>
      <Compose {...props} />
      <Flex>
        <Link
          to={{
            pathname: 'compose',
            state: { selectedMail: null, type: 'compose', previousPath: path },
          }}
        >
          <StyledButton
            reactIcon={<PencilFill color="#2D7E10" size={12} className="mr-2" />}
            to={'compose'}
            name={tr('Compose')}
          />
        </Link>
        {(filterBy === 'INBOX' || filterBy === 'SENT') && (
          <StyledSelect
            onChange={({ target }) => updateMailAreaOption(target)}
            value={mailFormData.area}
            name="area"
            optionDefaultValue="Assign to Area"
            required
            localeOptions={dataArea}
          />
        )}
        {/** remove the delete button  */}
        {/* <StyledButton 
                    reactIcon={<TrashFill color="#F00" size={12} className="mr-2"/>}
                    onClick={deleteMail}
                    name={tr('Delete Marked')}
                /> */}
        {selectedMail != null && filterBy === 'INBOX' && (
          <Link
            to={{
              pathname: 'compose',
              state: { selectedMail, type: 'reply', previousPath: path },
            }}
          >
            <StyledButton
              reactIcon={<ReplyFill color="#2D7E10" size={14} className="mr-1" />}
              to={'reply'}
              name={tr('Reply')}
            />
          </Link>
        )}
        {selectedMail != null && (filterBy === 'INBOX' || filterBy === 'SENT') && (
          <Link
            to={{
              pathname: 'compose',
              state: { selectedMail, type: 'fwd', previousPath: path },
            }}
          >
            <StyledButton
              reactIcon={<ForwardFill color="#2D7E10" size={14} className="mr-1" />}
              to={'fwd'}
              name={tr('Forward')}
            />
          </Link>
        )}
        {selectedMail != null && (filterBy === 'INBOX' || filterBy === 'SENT') && (
          <ReactToPrint
            trigger={() => (
              <StyledButton
                reactIcon={
                  <PrinterFill color="#2D7E10" size={14} className="mr-1" />
                }
                name={tr('Print')}
              />
            )}
            content={() => componentRef.current}
          />
        )}
        {filterBy == 'TRASH' && (
          <StyledButton
            reactIcon={
              <ArrowCounterclockwise color="#F00" size={14} className="mr-2" />
            }
            onClick={moveMailToInbox}
            name={tr('Move back to inbox')}
          />
        )}
        <Dropdown
          options={[
            {
              name: 'delete',
              onClick: () => {
                deleteMail();
              },
              isLink: false,
            },
            {
              name: 'Mark as Unread',
              onClick: () => {
                markMailAsUnreadOption();
              },
              isLink: false,
              show: filterBy == 'INBOX',
            },
            {
              name: 'Mark as Read',
              onClick: () => {
                markMailAsReadOption();
              },
              isLink: false,
              show: filterBy == 'INBOX',
            },
            {
              name: 'Other Actions',
              onClick: () => {},
              isLink: false,
              type: 'header',
            },
            {
              name: 'Export Email',
              onClick: () => {
                exportMailSample();
              },
              isLink: false,
            },
          ]}
          tr={tr}
          headerTitle={'Tagged Emails'}
          userDefaultSpace={userDefaultSpace}
          type={'vertical'} // this controls the type of ellipsis icon used
        />
      </Flex>
      <ResponsiveFlex>
        <MinWidthDiv padding={'0px'}>
          <MailList
            mailMessages={mappedData}
            tr={tr}
            trObj={trObj}
            selectCheckbox={selectCheckbox}
            isChecked={isChecked}
            isMasterChecked={isMasterChecked}
            selectMail={selectMail}
            selectedMail={selectedMail}
            type={filterBy}
          />
        </MinWidthDiv>
        {selectedMail != null && (
          <MinWidthDiv padding={'10px'}>
            <MailLetter
              selectedMail={selectedMail}
              fd={fd}
              tr={tr}
              ref={componentRef}
            />
          </MinWidthDiv>
        )}
      </ResponsiveFlex>
    </>
  );
};

export default compose(
  withMail,
  withBulkDelete,
  withTranslation,
  withDataArea,
  withUserLayoutChoice,
  withUserDefaultSpace,
  withUserPreferences,
  withFileSize,
)(MailInbox);
