import React, { useEffect } from 'react';
import compose from 'lodash/fp/compose';
import styled from 'styled-components';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './style.css';
import { useLocation, Link } from 'react-router-dom';
import {
  withAdminMail,
  withAccount,
  withTranslation,
  withUserDefaultSpace,
  withFormValidation,
  withLanguage,
  withUserCommunicationTypes,
} from '../../../hoc';
import { FormValidationContext } from '../../../hoc/Preload/FormValidation';
import {
  Form,
  Input,
  Button,
  RichTextEditor,
  Select,
  ButtonLink,
} from '../../../common/FormItems';
import MultiSelect from 'components/common/MultiSelect';
import { BaseColumnalSelect } from 'components/common/FormItems/BaseColumnalSelect';
import { FileEarmark, Square, XCircleFill } from 'react-bootstrap-icons';
import { setMsgInfo } from '../../../../actions';
import { useDispatch } from 'react-redux';
import { Icon, Popup } from 'semantic-ui-react';
import _ from 'lodash';
import swal from 'sweetalert';

const Wrapper = styled.div``;

const Flex = styled.div`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
  justify-content: ${({ justifyContent }) => justifyContent || ''};
  align-items: ${({ alignItems }) => alignItems || ''};
  flex-wrap: wrap;
`;

const FileAvatar = styled.a`
  margin: 5px 5px;
  font-style: italic;
  font-size: 0.9rem;
  align-items: center;
  display: block;
  &:last-child {
    margin-top: 0;
  }
`;

Flex.displayName = 'Flex';

const InputSection = styled(Flex)`
  margin: 2px 0;
`;

const StyledButtonLink = styled(ButtonLink)`
  span {
    text-transform: uppercase;
  }
`;

const InputWrapper = styled(Flex)`
  margin-right: 5px;
  justify-content: space-between;
  max-width: 100%;
  align-items: center;
  flex-wrap: wrap;

  // Style specifically for checkbox group
  &.checkbox-group {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  > div {
    flex: 1;
  }
`;

const Label = styled.label`
  color: var(--admincat-dark-color);
  font-size: 1rem;
  font-weight: 600;
  margin-right: 10px;
  align-self: start;
  width: 15%;

  // Style for labels above checkboxes
  &.checkbox-label {
    width: auto;
    align-self: center;
    margin-bottom: 5px;
    text-align: center;
  }
`;

const StyledButton = styled(Button)`
  && {
    // color: var(--admincat-dark-color) !important;
    text-transform: capitalize;
    width: 8.0625rem;
    height: 2.5rem;
    font-weight: 500;
    border-radius: 7px;
    transition: all ease-in-out 300ms;
    background: #4c81be;
    border: 1px solid #1d579b;
    box-sizing: border-box;
    font-style: normal;
    font-size: 1rem;
    line-height: 1rem;
    color: #ffffff;
    cursor: pointer;
    margin-right: 1rem;
  }
`;

const mailFormSchema = {
  from_mail: {
    validation: 'required|email',
    message: {
      email: "Sender's email is invalid",
      required: "Sender's email is required",
    },
  },
  language: {
    validation: 'required',
    message: {
      required: 'language is required',
    },
  },
  subject: {
    validation: 'required|min:3',
  },
  message: {
    validation: 'required|min:3',
    Link,
  },
  user_types: {
    validation: 'required|min:3',
  },
};

const MAX_SIZE_OF_ATTACHMENTS = 8485760; // this is the file size for attachments the django mail client can handle without crashing

/**
 * User Classification IDs
 * These values are determined by the backend and should NOT be modified.
 * Any changes here must be coordinated with the backend to ensure consistency.
 */
const UserTypes = Object.freeze({
  UNVERIFIED_USERS: 0,
  TRIAL_USERS: 1,
  NON_RENEWED_TRIAL_USERS: 2,
  ACTIVE_SUBSCRIBERS: 3,
  AWAITING_RENEWAL: 4,
});

const getFields = (formData) => {
  return [
    {
      type: 'email',
      name: 'from_mail',
      label: 'From',
      placeholder: 'Your email',
      disabled: true,
      required: true,
    },
    {
      type: 'text',
      name: 'subject',
      label: 'Subject',
      placeholder: 'Subject',
      required: true,
    },
    {
      type: 'select',
      name: 'language',
      label: 'Language',
      required: true,
      options: formData?.activeLanguageOptions,
    },
    {
      type: 'multi_select',
      name: 'user_types',
      label: 'Users',
      placeholder: '',
      required: true,
      sort: false,
      options: [
        { id: formData.userTypes.UNVERIFIED_USERS, name: 'Unverified Users' },
        { id: formData.userTypes.TRIAL_USERS, name: 'Trial Users' },
        {
          id: formData.userTypes.NON_RENEWED_TRIAL_USERS,
          name: 'Non-Renewed Trial Users',
        },
        { id: formData.userTypes.ACTIVE_SUBSCRIBERS, name: 'Active Subscribers' },
        {
          id: formData.userTypes.AWAITING_RENEWAL,
          name: 'Members Awaiting Renewal',
        },
      ],
    },
    {
      type: 'textarea',
      name: 'message',
      label: 'Message',
      required: true,
      placeholder: 'Your message...',
    },
  ].map((data) => ({
    ...formData,
    ...data,
    value: formData[data.name],
  }));
};

const InputFieldType = {
  email: Input,
  text: Input,
  textarea: RichTextEditor,
  select: Select,
  multi_select: MultiSelect,
};

const Fields = (props) => {
  const otherFields = getFields({ ...props });

  return (
    <>
      {otherFields.map(({ type, label, tr, error, name, required, ...rest }) => {
        const InputField = InputFieldType[type];
        return (
          <InputWrapper key={label}>
            <Label>{tr(label)}:</Label>
            <InputField
              name={name}
              type={type}
              error={error[name] ?? ''}
              {...rest}
            />
          </InputWrapper>
        );
      })}
    </>
  );
};

const Compose = (props) => {
  const {
    openComposeForm,
    toggleComposeForm,
    onMailChange: onChange,
    mailFormData,
    sendMail,
    sendMailLoading,
    account: {
      user: { email: personalEmail },
    },
    userDefaultSpace: { space_detail },
    tr,
    basePath,
    activeLanguageOptions,
    userTypes,
  } = props;
  const location = useLocation();
  const { selectedMail = {}, type, previousPath = '' } = location.state || {};

  const dispatch = useDispatch();

  const spaceEmail = space_detail && space_detail.email;

  const { validateForm } = React.useContext(FormValidationContext);

  const from_mail = spaceEmail || personalEmail;

  // Create a reference to the hidden file input element
  const hiddenFileInput = React.useRef(null);
  const [uploadedFiles, setUploadedFiles] = React.useState([]);
  const [error, setError] = React.useState({});

  const handleFileUploadClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleFileChange = (event) => {
    const chosenFiles = Array.prototype.slice.call(event.target.files);
    handleUploadFiles(chosenFiles);
  };

  const handleUploadFiles = (files) => {
    const uploaded = [...uploadedFiles];
    let size = 0;
    files.some((file) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        uploaded.push(file);
      }
    });

    uploaded.map((file) => (size += file.size));
    if (size > MAX_SIZE_OF_ATTACHMENTS) {
      dispatch(
        setMsgInfo({
          success: false,
          msg: ['Attachments size must be less than 8 MB.'],
        }),
      );
      return;
    }

    setUploadedFiles(uploaded);
    onChange({ target: { name: 'attachments', value: files } });
    dispatch(setMsgInfo({ success: true, msg: ['Files have been appended.'] }));
  };

  const removeFile = (fileName) => {
    const files = uploadedFiles.filter((file) => file.name != fileName);
    setUploadedFiles(files);
    onChange({ target: { name: 'attachments', value: files } });
  };

  const handleSubmission = (e) => {
    e.preventDefault();

    // ensure all fields are valid
    const validationError = {};
    for (const key in mailFormSchema) {
      // ensure each field is provided for
      if (!mailFormData[key]) {
        validationError[key] = true;
      }
    }

    if (!_.isEmpty(validationError)) {
      setError(validationError);
      swal(tr('Warning!'), tr(`Please fill all fields.`), 'warning');
      return;
    }

    sendMail();
  };

  useEffect(() => {
    if (spaceEmail) {
      onChange({ target: { name: 'from_mail', value: from_mail } });
    }
    if (type == 'fwd') {
      onChange({
        target: { name: 'subject', value: `Fwd: ${selectedMail?.subject}` },
      });
    }
    if (type == 'reply') {
      onChange({
        target: { name: 'subject', value: `Re: ${selectedMail?.subject}` },
      });
      onChange({ target: { name: 'to_mail', value: selectedMail?.from_mail } });
    }
    return () => null;
  }, [spaceEmail, mailFormData.from_mail]);

  return (
    <Wrapper key={basePath} style={{ height: '100vh' }} className="form-wrapper">
      <div className="row">
        <div className="col-7 ml-10 mt-30" style={{ position: 'relative' }}>
          <div
            className="ml-0"
            style={{ position: 'absolute', top: '-40px', left: '0', zIndex: 200 }}
          >
            <StyledButtonLink
              to={{
                pathname: `/dashboard`,
              }}
              className="option-btn"
              icon="angle double left"
              name="Go Back"
            />
          </div>
          <br />
          <Form
            title="Compose Mail"
            open={openComposeForm}
            toggleForm={() => toggleComposeForm(null)}
            formData={mailFormData}
            validationSchema={mailFormSchema}
            renderActionButtons={() => null}
          >
            <Flex justifyContent="flex-end"></Flex>
            <InputSection flexDirection="column">
              <Fields
                {...mailFormData}
                userTypes={userTypes}
                selectedMail={selectedMail}
                tr={tr}
                activeLanguageOptions={activeLanguageOptions}
                onChange={onChange}
                key={openComposeForm}
                error={error}
              />
              <Flex justifyContent="flex-end" alignItems="flex-end">
                <Popup
                  content={tr('Attach documents')}
                  size="mini"
                  trigger={
                    <Icon
                      name="attach"
                      onClick={handleFileUploadClick}
                      size="large"
                      style={{ marginRight: '1%' }}
                    />
                  }
                />
                <StyledButton
                  onClick={handleSubmission}
                  name="Send"
                  isLoading={sendMailLoading}
                />
                <input
                  type="file"
                  ref={hiddenFileInput}
                  onChange={handleFileChange}
                  style={{ display: 'none' }}
                  multiple
                />
              </Flex>
            </InputSection>
          </Form>
        </div>
        {uploadedFiles.length > 0 && (
          <div className="col-4 ml-10 mt-30" style={{ position: 'relative' }}>
            <br />
            <Form
              title={`Attachements(${uploadedFiles.length})`}
              renderActionButtons={null}
            >
              <Flex justifyContent="flex-start" flexDirection="column">
                {uploadedFiles.map((data, index) => {
                  return (
                    <FileAvatar key={data?.name}>
                      <Icon name="attach" /> {data?.name}{' '}
                      <Popup
                        content={tr('Remove this file')}
                        size="mini"
                        trigger={
                          <Icon
                            name="times"
                            onClick={() => {
                              removeFile(data?.name);
                            }}
                            circular
                            inverted
                            color="red"
                            size="small"
                          />
                        }
                      />
                    </FileAvatar>
                  );
                })}
              </Flex>
            </Form>
          </div>
        )}
      </div>
    </Wrapper>
  );
};

export default compose(
  withAdminMail,
  withAccount,
  withTranslation,
  withUserDefaultSpace,
  withFormValidation,
  withLanguage,
  withUserCommunicationTypes,
)(Compose);
