/* eslint-disable camelcase */
/* eslint-disable */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import compose from 'lodash/fp/compose';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router-dom';
import check from 'check-types';
import {
  withOrganisation,
  withCountry,
  withTranslation,
  withLegalForm,
  withState,
  withCity,
  withOrganisationFunction,
  withReligion,
  withPublicRespArea,
  withOrganisationMotivation,
  withCurrency,
  withIndustryType,
  withOrgFunctionAssignment,
  // withDependentField,
  withCustomRouter,
  withUserPreferences,
  withLanguage,
  withOrganisationProposal,
} from 'components/hoc';
import {
  Input,
  Select,
  Switchery,
  InputDatePicker,
  FileInput,
  Image,
  Form,
  ButtonLink,
  NewEntriesSelect,
} from 'components/common/FormItems';
// import { getFavoriteOrgById } from 'actions/organisation';
import { isEmptyValue } from 'components/utils/checkValue';
import { excludeUnsetField } from 'components/utils/excludeUnsetField';
import API from '../../../../../utils/api';
import axios from '../../../../../axios';
import { RESPONSE_STRINGS } from '../../../../../constants';
import swal from 'sweetalert';

const ImageWrapper = styled.div`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
`;

const Wrapper = styled.div``;

const StyledButtonLink = styled(ButtonLink)`
  span {
    text-transform: uppercase;
  }
`;

const orgFormSchema = {
  name: {
    validation: 'required|min:3',
  },
  org_country: {
    validation: 'required',
  },

  industry: {
    validation: 'required',
  },
};

export const customValidationMessage = {
  'min.name': 'Organisation name must be at least 3 characters',
  'required.name': 'Organisation name is required',
  'required.org_country': 'Organisation country is required',
  // 'required_if.public_resp_area': '',
};

const initialValues = {
  id: '',
  name: '',
  org_country: '',
  state: '',
  legal_form: '',
  register_institute: '',
  register_no: '',
  organisation_category: '',
  legal_personality: true,
  public: false,
  non_profit: false,
  org_function_assignment_list: [{}],
};

const PersonalOrgForm = (props) => {
  const [values, setValues] = useState({ ...initialValues });
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [error, setError] = useState('');
  // const [parentOrgModalState, setParentOrgModalState] = useState({
  //   open: false,
  // });
  const [showEntryForm, setShowEntryForm] = React.useState(false);
  const [entryValues, setEntryValues] = React.useState({
    value: '',
    language: props.userPreferences.language,
  });
  const [entrySubmitting, setEntrySubmitting] = React.useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  const location = useLocation();

  const {
    countryLocaleOptions,
    legalFormLocaleOptions,
    getStateByCountryId,
    stateLocaleOptions,
    getLegalFormByCountryId,
    tr,
    routeParams,
    activeLanguageOptions,
    basePath,
    currencyLocaleOptions,
    addOrganisation,
    fetchingState,
    orgFunctionAssignment: { register_institute_options },
    addOrganisationProposal,
    // getFavoriteOrgById,
    // formData,
  } = props;


  console.log("basePath", basePath)

  const toggleEntryField = () => {
    setShowEntryForm(!showEntryForm);
  };

  const onEntryChange = (e) => {
    const { value, name } = e.target;
    setEntryValues({ ...entryValues, [name]: value });
  };

  const validateAndSubmitEntry = async (entryType) => {
    if (!(entryValues.name && entryValues.language)) {
      swal(
        tr('Warning!'),
        tr(`Please ensure all required fields are populated.`),
        'warning',
      );
      return;
    }
    try {
      setEntrySubmitting(true);
      const payload = {
        name: entryValues.name,
        language: entryValues.language,
        entry_type: entryType,
      };
      await axios.post(`${API}/propose/entry/`, payload);
      swal(
        tr('Success!'),
        tr(`${RESPONSE_STRINGS?.ENTRY_RESPONSE_SUCCESS_MESSAGE}`),
        'success',
      );
      setEntryValues({});
      setEntrySubmitting(false);
      setShowEntryForm(false);
      return;
    } catch (error) {
      setEntrySubmitting(false);
      setShowEntryForm(false);
    } finally {
      setEntrySubmitting(false);
      setShowEntryForm(false);
    }
  };

  const onSubmit = async (e) => {
    if (formSubmitting) return;
    let payload = excludeUnsetField(values);

    const fData = new FormData();
    Object.entries(payload).forEach(([key, value]) => {
      if (key === 'organisation_logo') {
        if (!check.string(value) && !isEmptyValue(value)) {
          fData.append(key, value);
        }
      } else {
        fData.append(key, value);
      }
    });

    const successMessage = tr('Organisation proposal created and will be review');
    try {
      setFormSubmitting(true);
      if (!values.id) {
        await addOrganisationProposal(fData, successMessage);
      }
      history.push('/dashboard/data/base/favorite-organisation');
      window.scrollTo(0, 0);
    } catch (e) {
      setError('Problem creating organisation proposal, please try again');
    } finally {
      setFormSubmitting(false);
    }
  };

  const handleChange = (e) => {
    if (formSubmitting) return;
    const { value, name } = e.target;
    setValues({ ...values, [name]: value });
  };

  const registerInstituteOptions = React.useMemo(() => {
    // uniquelize the organisations
    const map = new Map();
    register_institute_options.forEach((props) => {
      const { name, org_country } = props;
      if (org_country == values?.org_country) {
        map.set(name, props);
      }
    });
    return Array.from(map.values());
  }, [register_institute_options, values?.org_country]);

  const moveToOrg = () => {
    const pathArray = basePath.split('/');
    pathArray.pop();
    const pathway = `${pathArray.join('/')}/favorite-organisation`;
    return pathway;
  };

  const moveToOrgFunction = () => {
    const { id: orgId } = routeParams;
    const pathArray = basePath.split('/');
    pathArray.pop();
    const pathway = `${pathArray.join('/')}/org_func_assign?q=${values?.id}`;
    return pathway;
  };

  React.useEffect(() => {
    window.scroll(0, 0);
  }, []);

  // console.log('props.routeQueries', props.routeQueries)


  React.useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const updatedValues = {};
    // Iterate over the query parameters and update the values object
    queryParams.forEach((value, key) => {
      updatedValues[key] = value;
    });

    // const { id } = routeParams;
    // console.log("id", id)
    // if (id) {
    //   // Fetch the city options based on the state
    //   if (updatedValues.state) {
    //     props.getCityByStateId(updatedValues.state);
    //   }
    // }
    
    setValues((prevValues) => ({
      ...prevValues,
      ...updatedValues,
    }));
  }, [location.search]);



  // React.useEffect(() => {
  //   const fetchData = async () => {
  //     const { id: orgId } = routeParams;
  //     if (orgId) {
  //       try {
  //         setFormLoading(true);
  //         const { data } = await axios.get(`${API}/organisations/${orgId}/`);
  //         const updatedValues = { ...initialValues, ...data };
  //         setValues(updatedValues);
  
  //         // Fetch state options based on the country
  //         if (data.org_country) {
  //           getStateByCountryId(data.org_country);
  //         }
  
  //         // Fetch city options based on the state
  //         if (data.state) {
  //           props.getCityByStateId(data.state);
  //         }
  //       } catch (error) {
  //         console.error('Error fetching organization data:', error);
  //       } finally {
  //         setFormLoading(false);
  //       }
  //     }
  //   };
  
  //   fetchData();
  // }, [routeParams.id]);


  // React.useEffect(() => {
  //   const searchParams = new URLSearchParams(props.location.search);
  //   const newValues = { ...initialValues };
  
  //   for (const [key, value] of searchParams.entries()) {
  //     if (value) {
  //       newValues[key] = value;
  //     }
  //   }
  
  //   setValues(newValues);
  // }, [props.location.search]);



  // React.useEffect(() => {
  //   const { routeQueries } = props;
  //   let updatedValues = { ...values };
  //   Object.keys(routeQueries).forEach((key) => {
  //     if (routeQueries[key]) {
  //       updatedValues = { ...updatedValues, [key]: routeQueries[key] };
  //     }
  //   });
  //   setValues(updatedValues);
  // }, []);



  return (
    <Wrapper className="form-wrapper">
      <div className="flex">
        <StyledButtonLink
              to={moveToOrg()}
              className="option-btn"
              icon="angle double left"
              name="All Entries"
        />
        <StyledButtonLink
              to={moveToOrgFunction()}
              className="option-btn"
              icon="angle double right"
              name={tr('Go to Org Function')}
            />
      </div>
      <div className="row">
        <div
          className="col-7 ml-10 mt-30 container"
          style={{
            position: 'relative',
            background: 'var(--admincat-color-grey-1)',
          }}
        >
          <br/>

          <Form
            onSubmit={onSubmit}
            formData={values}
            validationSchema={orgFormSchema}
            isLoading={formSubmitting}
            loadingData={formLoading}
            title="Organisation"
          >
            <ImageWrapper>
              <Image src={values.organisation_logo} />
            </ImageWrapper>

            <FileInput
              onChange={handleChange}
              value={values?.organisation_logo || ''}
              hasError="Error"
              name="organisation_logo"
              label={tr('Organisation logo')}
              accept="image/*"
            />
            <Input
              onChange={handleChange}
              value={values?.name || ''}
              name="name"
              label="Organisation"
              placeholder="Organisation"
              showRequiredAsterisk
            />
            <Select
              onChange={(e) => {
                handleChange(e);
                getStateByCountryId(e.target.value);
                getLegalFormByCountryId(e.target.value);
              }}
              showRequiredAsterisk
              value={values?.org_country || ''}
              name="org_country"
              label="Country"
              optionDefaultValue="Choose Country"
              localeOptions={countryLocaleOptions}
            />
            <Select
              isLoading={fetchingState}
              onChange={(e) => {
                handleChange(e);
                props.getCityByStateId(e.target.value);
              }}
              value={values?.state || ''}
              name="state"
              label="State"
              placeholder="State"
              optionDefaultValue="State"
              localeOptions={stateLocaleOptions}
              disabled={!values?.org_country}
              deselectable
            />

            <Select
              isLoading={props.fetchingCity}
              onChange={handleChange}
              optionDefaultValue="Choose City Location"
              label="City Location"
              value={values?.city_location || ''}
              name="city_location"
              id="city_location"
              localeOptions={props.cityLocaleOptions}
              disabled={!props.stateLocaleOptions}
              deselectable
            />
            <NewEntriesSelect
              value={values.legal_form || ''}
              name="legal_form"
              deselectable
              label={tr('Legal Form')}
              optionDefaultValue={tr('Choose Legal Form')}
              localeOptions={legalFormLocaleOptions}
              onChange={(e) => {
                if (e.target.value == 'proposeValue') {
                  toggleEntryField();
                  return;
                } else {
                  handleChange(e);
                }
              }}
              entryDefaultLanguage={entryValues.language}
              entryValue={entryValues.name}
              entryLanguageValue={entryValues.language}
              entryValueName={tr('Legal Form')}
              entryType={tr('Legal Form')}
              entryLanguageName={'language'}
              onEntryChange={onEntryChange}
              onCreateNewEntry={(e) => {
                e.preventDefault();
                validateAndSubmitEntry('legal_form');
              }}
              add={showEntryForm}
              toggleEntryField={toggleEntryField}
              options={activeLanguageOptions}
              isLoading={entrySubmitting}
              closeEntryForm={() => {
                toggleEntryField();
              }}
            />
            {values.legal_form !== 'KöR' && (
              <Select
                onChange={handleChange}
                value={values.register_institute}
                name="register_institute"
                label="Registration Institution"
                optionDefaultValue="Registration Institution"
                options={registerInstituteOptions}
                disabled={!values?.org_country}
                deselectable
              />
            )}
            {values.legal_form !== 'KöR' && (
              <Input
                onChange={handleChange}
                value={values.register_no}
                name="register_no"
                label="Official Register No"
                placeholder="Official Register No"
              />
            )}
            <InputDatePicker
              label="Liquidation"
              value={values.liquidation}
              name="liquidation"
              onChange={handleChange}
            />
            <InputDatePicker
              label="Foundation"
              value={values.foundation}
              name="foundation"
              onChange={handleChange}
            />
            <Switchery
              name="public"
              value={values.public}
              onChange={handleChange}
              label="Public"
              showRightLabel={false}
            />
            <Select
              onChange={handleChange}
              value={values.org_motivation}
              name="org_motivation"
              label="Org Motivation"
              optionDefaultValue="Choose Org Motivation"
              localeOptions={props.orgMotivationLocaleOptions}
              deselectable
              sort={false}
            />
            <Switchery
              name="legal_personality"
              value={values.legal_personality}
              onChange={handleChange}
              label="Legal Personality"
              showRightLabel={false}
            />

            <Select
              onChange={handleChange}
              value={values.parent_org}
              name="parent_org"
              label="Parent Organisation"
              optionDefaultValue="Choose Parent Organisation"
              options={props.orgOptions}
              // deselectable
              required={!values.legal_personality}
              disabled={values.legal_personality}
            />
            <Switchery
              name="non_profit"
              value={values.non_profit}
              onChange={handleChange}
              label="Non Profit"
              showRightLabel={false}
            />
            <Select
              onChange={handleChange}
              value={values.industry}
              name="industry"
              label="Industry"
              optionDefaultValue="Choose Industry"
              options={props.industryTypeOptions}
              showRequiredAsterisk
              sort={false}
            />
            <Input
              onChange={handleChange}
              value={values.no_of_employees}
              name="no_of_employees"
              label="No. of Employees"
              placeholder="No. of Employees"
              fieldType="number"
            />
            <Input
              onChange={handleChange}
              value={values.revenue_approx}
              name="revenue_approx"
              label="Revenue approx."
              placeholder="Revenue approx."
              fieldType="number"
            />
            <Select
              onChange={handleChange}
              value={values.revenue_currency}
              name="revenue_currency"
              label="Revenue Currency"
              optionDefaultValue="Choose Revenue Currency"
              // options={props.currencyLocaleOptions.map(({ id }) => ({ id, name: id }))}
              localeOptions={currencyLocaleOptions}
              deselectable
            />
            <Input
              onChange={handleChange}
              value={values.website}
              name="website"
              label="Website"
              placeholder="Website"
            />
          </Form>
        </div>
      </div>
    </Wrapper>
  );
};

export default compose(
  withOrganisation,
  withCountry,
  withLegalForm,
  withTranslation,
  withState,
  withCity,
  withOrganisationFunction,
  withReligion,
  withPublicRespArea,
  withOrganisationMotivation,
  withCurrency,
  withIndustryType,
  withOrgFunctionAssignment,
  withUserPreferences,
  withLanguage,
  withCustomRouter,
  withOrganisationProposal,
)(PersonalOrgForm);
