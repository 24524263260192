import React from "react";
import compose from "lodash/fp/compose";
import { connect } from "react-redux";
import hoistNonReactStatics from 'hoist-non-react-statics'
import * as educationActions from "actions/dashboard/education"
import { BaseHOC, withBaseHOCProps } from "../common/BaseHOC"
import { withCustomRouter } from '../Preload/CustomRouter'

const educationCategory = {
  'academic-studies': {
    category: 'AS',
    school_type: 'A',
  },
  'general-education': {
    category: 'GE',
    school_type: 'G',
  },
  'pre-school': {
    category: 'PR',
    school_type: 'P',
  },
  'vocational-school': {
    category: 'VS',
    school_type: 'V',
  },
  'vocational-training': {
    category: 'VT',
    school_type: 'V',
  },
  'further-training': {
    category: 'FT',
    school_type: 'F',
  },
  'languages': {
    category: 'LA',
    school_type: 'L',
  },
}


const initialState = {
  loaded: false,
}


export const withEducation = (WrapperComponent) => {
  class Education extends BaseHOC {

    state = { ...initialState }

    async componentDidMount() {
      const { getEducation } = this.props 
      if (!this.state.loaded)  {
        await getEducation({ category: this.category });
        this.setState({...initialState, loaded: true });
        // loaded = true;
      }
    }

    get educationPathName() {
      const { pathname } = this.props.location
      const paths = pathname.split('/')
      const index = paths.indexOf('education')
      return paths[index + 1]
    }

    get category() {
      const pathName = this.educationPathName
      return (educationCategory[pathName] || {}).category
    }

    deleteEducationById = async (data) => {
      const { deleteEducationById } = this.props
      await this._deleteById(deleteEducationById, data);
    }

    deleteBulkEducation = async (data) => {
      const { deleteBulkEducation } = this.props
      await this._deleteBulk(deleteBulkEducation, data);
    }

    render() {
      return (
        <WrapperComponent 
          {...this.props} 
          loaded={this.state.loaded}
          educationCategory={educationCategory}
          educationPathName={this.educationPathName}
          deleteEducationById={this.deleteEducationById}
          deleteBulkEducation={this.deleteBulkEducation}
        />
      )
    }
  }
  const mapStateToProps = ({ education }) => ({ education })
  const WithEducation = connect(mapStateToProps, educationActions)(Education);
  hoistNonReactStatics(WithEducation, WrapperComponent)
  return compose(withBaseHOCProps, withCustomRouter)(WithEducation);
}
