import React from "react";
import { connect } from "react-redux";
import {
  getNationality,
} from "../../../actions";
import hoistNonReactStatics from 'hoist-non-react-statics'


let loaded = false;

export const withNationality = (WrapperComponent) => {
  class WithNationality extends React.Component {
    componentDidMount() {
      const { getNationality } = this.props;
      if (loaded) return;
      loaded = true;
      getNationality();
    }
    getNationalityOptions = () => undefined
    getNationalityLocaleOptions = () => {
      const { nationality } = this.props;
      return nationality.map(({ created_at, ...rest }) => ({ ...rest }));
    }
    render() {
      return (
        <WrapperComponent 
          nationalityOptions={this.getNationalityOptions()} 
          nationalityLocaleOptions={this.getNationalityLocaleOptions()} 
          {...this.props} 
        />
      )
    }
  }
  const mapStateToProps = ({ nationality }) => ({ nationality })
  const WithNationalityHOC = connect(mapStateToProps, {
    getNationality,
  })(WithNationality);
  hoistNonReactStatics(WithNationalityHOC, WrapperComponent)
  return WithNationalityHOC
}
