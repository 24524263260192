import { CHANGE_USERNAME_SUCCESS, CHANGE_USERNAME_FAILED, CHANGE_USERNAME_LOADING} from "../actions/types"


const initialState = {
  data: [],
  isLoading: false,
  error: null,
};

const usernameReducer = (state=initialState, action={}) => { 
  switch(action.type){ 
    case CHANGE_USERNAME_SUCCESS: 
      return { ...state,
         data: action.payload, 
         isLoading: false };
    case CHANGE_USERNAME_LOADING: 
      return { ...state, isLoading: true };
    case CHANGE_USERNAME_FAILED: 
      return { ...state, isLoading: false, error: action.payload };
    default:
      return state
  }
}

export default usernameReducer

