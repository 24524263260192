import {
  RESET_PASSWORD_FAILURE,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_LOADING
} from "../actions/types";

const initialState = {
  data: [],
  isLoading: false,
  error: null,
};

export const fogotPasswordReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false
      };

    case RESET_PASSWORD_LOADING:
      return {
        ...state,
        isLoading: true
      };

    case RESET_PASSWORD_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    default:
      return state
  }
}

export default fogotPasswordReducer

