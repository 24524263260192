import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import compose from 'lodash/fp/compose';
import ContentTemplate from 'components/common/ContentTemplate';
import { Button } from 'components/common/FormItems';
import {
  withBulkDelete,
  withUserLayoutChoice,
  withTranslation,
} from 'components/hoc';
import { approveSpaceMember, disapproveSpaceMember } from 'actions';
import { DnDPortal } from 'components/common/FormItems/DnDPortal';
import withFileSize from 'components/hoc/FileSizeChecker/File';
import { HandThumbsUp, HandThumbsDown } from 'react-bootstrap-icons';
import swal from 'sweetalert';
import LottieLoader from 'components/common/LottieLoader';

const ButtonWrapper = styled.div`
  margin-top: -6px;
`;

const StyledButton = styled(Button)`
  background: var(--admincat-color-grey-6);
  border-radius: 2px;
  height: 38px;
  color: ${(props) => props.color || 'var(--admincat-color-grey-7)'};
  border: none;
  font-size: 14px;
  line-height: 10px;

  // remove this styles below,
  // if you want to see the full button when resized
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const StyledContentTemplate = styled(ContentTemplate)`
  div.table-responsive {
    height: auto;
    min-height: 500px;
    max-height: 100%;
    width: auto;
    max-width: 93%;
    background: var(--admincat-color-grey-1);

    @media screen and (max-width: 768px) {
      height: auto;
      min-height: 500px;
      max-height: 100%;
      width: auto;
      max-width: 85%;
      background: var(--admincat-color-grey-1);
    }

    @media screen and (min-width: 1200px) {
      height: auto;
      min-height: 500px;
      max-height: 100%;
      width: auto;
      max-width: 93%;
      background: var(--admincat-color-grey-1);
    }
  }
`;

const SpaceContentTemplate = (props) => {
  const [approveMember, setApproveMember] = React.useState({});
  // const [disapproveMember, setDisapproveMember] = React.useState({});
  const [loading, setLoading] = React.useState(false);

  const {
    bulkDelete = () => {},
    rowIds,
    hasBulkDelete,
    getLayoutColumnProps,
    tableName,
    frontendTableName: fTableName,
    backendTableName: bTableName,
    columns: defaultColumns,
    msg,
    tr,
    ...rest
  } = props;

  const dispatch = useDispatch();

  const frontendTableName = fTableName || tableName;
  const backendTableName = bTableName || tableName;

  const { columns, layoutColumns } = getLayoutColumnProps(
    frontendTableName,
    backendTableName,
    defaultColumns,
  );

  const approve = async (rowIds) => {
    if (rowIds.length == 0) {
      swal(
        tr('Warning!'),
        tr(`You haven't select any member to approve.`),
        'warning',
      );
      return;
    }

    try {
      setLoading(true);
      await dispatch(approveSpaceMember(rowIds));
    } finally {
      setLoading(false);
    }
  };

  const disapprove = async (rowIds) => {
    if (rowIds.length == 0) {
      swal(
        tr('Warning!'),
        tr(`You haven't select any member to decline.`),
        'warning',
      );
      return;
    }

    try {
      setLoading(true);
      const { status, ...rest } = await dispatch(disapproveSpaceMember(rowIds));
      setApproveMember({ status: status, ...rest });
    } finally {
      setLoading(false);
    }
  };

  // const disapprove = (data) => {
  //   if(this.state.isLoading) return;
  //   this.setState({isLoading: true});
  //   this.props.disapproveSpaceMember(data)
  //     .then(data => {
  //       this.setState({isLoading: false});
  //     })
  //     .catch(err => {
  //       this.setState({isLoading: false});
  //     })
  // }

  if (loading) {
    return (
      <>
        <LottieLoader />
      </>
    );
  }

  return (
    <React.Fragment>
      {msg}
      <ButtonWrapper className="flex">
        <StyledButton
          onClick={() => approve(rowIds)}
          className="option-btn"
          reactIcon={<HandThumbsUp color="#0E8436" size={16} className="mr-2" />}
          name="Approve"
        />
        <StyledButton
          onClick={() => disapprove(rowIds)}
          className="option-btn"
          name="Decline"
          reactIcon={<HandThumbsDown color="#F00" size={16} className="mr-3" />}
        />
        <DnDPortal tableName={frontendTableName} columns={layoutColumns} />
      </ButtonWrapper>
      <StyledContentTemplate
        {...rest}
        columns={columns}
        hasBulkDelete={hasBulkDelete}
        rowIds={rowIds}
      />
    </React.Fragment>
  );
};

SpaceContentTemplate.defaultProps = {
  useAddModalForm: true,
  handleCreateForm: null,
  frontendTableName: null,
  backendTableName: null,
  columns: [],
};

export default compose(
  withBulkDelete,
  withUserLayoutChoice,
  withFileSize,
  withTranslation,
)(SpaceContentTemplate);
