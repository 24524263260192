import React from 'react';
import compose from 'lodash/fp/compose';
import { connect } from 'react-redux';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { ADMIN_TYPES } from 'config/adminTypes';
import { BaseHOC, withBaseHOCProps } from '../common/BaseHOC';

export const withAdminRouteFiltering = (WrapperComponent) => {
  class AdminRouteFiltering extends BaseHOC {
    filterAdminRoutes = (routes) => {
      const user = JSON.parse(localStorage.getItem('user'));
      const adminType = user?.admin_type;

      if (!adminType) return [];

      return routes.filter(
        (route) =>
          route.allowedAdminTypes?.includes(adminType) ||
          adminType === ADMIN_TYPES.SUPER_USER ||
          !route.allowedAdminTypes,
      );
    };

    render() {
      return (
        <WrapperComponent
          {...this.props}
          filterAdminRoutes={this.filterAdminRoutes}
        />
      );
    }
  }

  const mapStateToProps = ({ user }) => ({ user });

  const WithAdminRouteFiltering = connect(mapStateToProps)(AdminRouteFiltering);

  hoistNonReactStatics(WithAdminRouteFiltering, WrapperComponent);

  return compose(withBaseHOCProps)(WithAdminRouteFiltering);
};
