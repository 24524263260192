export const space_group = `<svg width="71" height="71" viewBox="0 0 71 71" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="35.5" cy="35.5" r="35.5" fill="#ECEDF0"/>
<g clip-path="url(#clip0_2040_880)">
<path d="M37.2774 6C38.9341 6.26502 40.6083 6.51237 42.1769 7.18375C47.9575 9.67491 51.5175 13.9859 52.6102 20.2226C53.8086 27.1484 50.4248 34.2155 44.1507 37.5901C38.0529 40.8763 31.9903 40.5406 26.2978 36.5654C21.7332 33.3852 19.3364 28.8268 19.2306 23.2615C19.1073 17.0777 21.7861 12.2014 26.897 8.73852C29.1529 7.20141 31.6731 6.37102 34.3871 6.08834C34.5105 6.07067 34.6339 6.03534 34.7572 6C35.5855 6 36.4315 6 37.2774 6Z" fill="#C6D1F2"/>
<path d="M35.8499 71.0002C28.5536 70.9296 21.8566 68.7741 15.9702 64.3748C12.516 61.7776 9.60802 58.6327 7.35218 54.9048C7.01732 54.3395 6.85871 53.7918 7.14069 53.1734C10.0839 46.9543 14.8599 42.9967 21.5217 41.3359C21.9976 41.2123 22.4734 41.1592 22.9493 41.0532C23.9891 40.8236 24.8879 41.1239 25.7338 41.7246C29.0647 44.0744 32.7657 45.1168 36.8192 44.9402C39.921 44.8165 42.8994 44.0744 45.3844 42.237C47.2878 40.8236 49.1206 40.9296 51.165 41.4949C56.7518 43.0674 60.9639 46.4243 63.9599 51.3713C65.2817 53.5621 65.3346 53.6327 63.9423 55.7352C60.5761 60.8236 56.1878 64.7988 50.7068 67.5197C46.0717 69.8518 41.1194 70.9649 35.8499 71.0002Z" fill="#C6D1F2"/>
</g>
<defs>
<clipPath id="clip0_2040_880">
<rect width="58" height="65" fill="white" transform="translate(7 6)"/>
</clipPath>
</defs>
</svg>
`;
