import { ADD_WAITING_LIST } from "./types"

import axios from '../axios';
import API from './api'
import { createTypes } from './createTypes';
import filterError from './common/filterError';
import { setMsgInfo } from "./msgInfo";


const baseUrl = API + '/waiting-list'

const waitingLIstAction = payload =>{
    return async (dispatch) =>{
        dispatch({
            type:createTypes(ADD_WAITING_LIST).loading
        })
        try{
            const { data } = await axios.post(`${baseUrl}/`, payload);
            dispatch({
                type: createTypes(ADD_WAITING_LIST).success,
                payload: data
            })

        }catch (err){
            const errors = filterError(err);
            dispatch({
                type: createTypes(ADD_WAITING_LIST).failure,
                payload: errors,
              });
            dispatch(
                setMsgInfo({
                  success: false,
                  msg: errors,
                })
              );
        }
    }
}
export default waitingLIstAction