import styled from 'styled-components';
import Background from './login.png';

export const LoginBody = styled.div`
  background-size: cover;
  width: 100%;
  height: 100vh;
  display: flex;
  background: #fff;
`;

export const LoginDivImage = styled.div`
  background: url(${Background});
  background-size: cover;
  background-repeat: no-repeat;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;
export const LoginDivChild = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
`;

export const ImageSection = styled.div`
  background: url(${Background});
  width: 100%;
  height: 100%;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
`;
