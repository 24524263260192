import axios from '../../axios';
import * as types from "../types";

import { setMsgInfo } from "../";
import API from "../api";
import filterError from "../common/filterError";

const baseUrl = API + "/join-builder/";


export const getAllJoinBuilder = () => {
  return async dispatch => {
    dispatch({ type: types.GET_ALL_JOIN_BUILDER_LOADING });
    try {
      const { data } = await axios.get(baseUrl);
      dispatch({ type: types.GET_ALL_JOIN_BUILDER_SUCCESS, payload: data });
    } catch (err) {
      const errors = filterError(err);
      dispatch({ type: types.GET_ALL_JOIN_BUILDER_FAILURE, payload: errors });
      dispatch(
        setMsgInfo({
          success: false,
          msg: errors
        })
      );
    }
  };
};

export const getJoinBuilderByID = (id) => {
  return async dispatch => {
    dispatch({ type: types.GET_JOIN_BUILDER_BY_ID_LOADING });
    try {
      const { data } = await axios.get(`${baseUrl}${id}/`);
      dispatch({ type: types.GET_JOIN_BUILDER_BY_ID_SUCCESS, payload: data });
    }
    catch(err) {
      const errors = filterError(err);
      dispatch({ type: types.GET_JOIN_BUILDER_BY_ID_FAILURE, payload: errors });
      dispatch(setMsgInfo({ success: false, msg: errors }));
    }
  };
};

const initialData = {
  joinDataPos: [],
  jointLines: [],
  tables: [],
  tablesData: [], 
};

export const updateJoinBuilder = payload => {
  return async dispatch => {
    dispatch({ type: types.UPDATE_JOIN_BUILDER_LOADING });
    try {
      const { data } = await axios.put(`${baseUrl}${payload.id}/`, payload);
      dispatch({ 
        type: types.UPDATE_JOIN_BUILDER_SUCCESS, 
        payload: initialData,
      });
      await getAllJoinBuilder()(dispatch);
      dispatch(
        setMsgInfo({
          success: true,
          msg: ["Data updated successfully"]
        })
      );
      return data;
    } catch (err) {
      const errors = filterError(err);
      dispatch({ type: types.UPDATE_JOIN_BUILDER_FAILURE, payload: errors });
      dispatch(
        setMsgInfo({
          success: false,
          msg: errors
        })
      );
      throw err
    }
  };
};

export const addJoinBuilder = payload => {
  return async dispatch => {
    dispatch({ type: types.ADD_JOIN_BUILDER_LOADING });
    try {
      const { data } = await axios.post(baseUrl, payload);
      dispatch({ type: types.ADD_JOIN_BUILDER_SUCCESS, payload: initialData });
      await getAllJoinBuilder()(dispatch);
      dispatch(
        setMsgInfo({
          success: true,
          msg: ["Data saved successfully"]
        })
      );
      return data;
    } catch (err) {
      const errors = filterError(err);
      dispatch({ type: types.ADD_JOIN_BUILDER_FAILURE, payload: errors });
      dispatch(
        setMsgInfo({
          success: false,
          msg: errors
        })
      );
      throw err
    }
  };
};

export const deleteJoinBuilderById = (id, payload) => {
  return async dispatch => {
    dispatch({ type: types.DELETE_JOIN_BUILDER_LOADING });
    try {
      await axios.delete(`${baseUrl}${id}/`);
      dispatch({ type: types.DELETE_JOIN_BUILDER_SUCCESS, payload });
      dispatch(
        setMsgInfo({
          success: true,
          msg: ["Data deleted successfully"]
        })
      );
    } catch (err) {
      const errors = filterError(err);
      dispatch({ type: types.DELETE_JOIN_BUILDER_FAILURE, payload: errors });
      dispatch(
        setMsgInfo({
          success: false,
          msg: ["Data could not be deleted"]
        })
      );
    }
  };
};
