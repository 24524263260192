import Contract from '../common/Contract';
import ContractItem from '../common/ContractItem';
import ContractItemForm from '../common/ContractItem/Form';
import ContractForm from '../common/Contract/ContractForm';
import DiseaseHistory from './DiseaseHistory';
import VaccinationHistory from './VaccinationHistory/';
import VaccinationHistoryForm from './VaccinationHistory/Form';
import DiseaseHistoryForm from './DiseaseHistory/Form';

const path = '/dashboard/data/health';

export default [
  // {
  //   name: 'Contract',
  //   path: `${path}/contract`,
  //   icon: 'user',
  //   component: Contract,
  //   exact:true,
  // },
  // {
  //   name: 'Contract Form',
  //   path: [
  //     `${path}/contract/create`,
  //     `${path}/contract/:id`,
  //     `${path}/contract/:id/update`,
  //   ],
  //   exact: true,
  //   icon: 'user',
  //   component: ContractForm,
  //   hide: true,
  // },
  // {
  //   name: 'Contract Item',
  //   path: `${path}/contract-item`,
  //   icon: 'user',
  //   component: ContractItem,
  //   exact:true,
  // },
  // {
  //   name: 'Contract Form Item',
  //   path: [
  //     `${path}/contract-item/create`,
  //     `${path}/contract-item/:id`,
  //     `${path}/contract-item/:id/update`,
  //   ],
  //   exact: true,
  //   icon: 'user',
  //   component: ContractItemForm,
  //   hide: true,
  // },
  {
    name: 'Disease',
    path: `${path}/disease`,
    icon: 'user',
    component: DiseaseHistory,
    exact: true,
  },
  {
    name: 'Disease History',
    path: [
      `${path}/disease/create`,
      `${path}/disease/:id`,
      `${path}/disease/:id/update`,
    ],
    exact: true,
    icon: 'user',
    component: DiseaseHistoryForm,
    hide: true,
  },
  {
    name: 'Vaccination',
    path: `${path}/vaccination`,
    icon: 'user',
    exact: true,
    component: VaccinationHistory,
  },
  {
    name: 'Vaccination Form',
    path: [
      `${path}/vaccination/create`,
      `${path}/vaccination/:id`,
      `${path}/vaccination/:id/update`,
    ],
    icon: 'users',
    component: VaccinationHistoryForm,
    hide: true,
    exact: true,
  },
];

export const redirects = [
  {
    from: `${path}`,
    to: `${path}/contract`,
  },
  {
    from: `${path}/*`,
    to: `${path}/contract`,
  },
];
