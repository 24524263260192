import React from 'react';
import compose from 'lodash/fp/compose';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import {
  withTranslation,
  withPersonalInfo,
  withDiseaseTypes,
  withDiseaseHistory,
  withCustomRouter,
  withLanguage,
  withUserPreferences,
} from '../../../../hoc';
import {
  InputDatePicker,
  // Input,
  Select,
  ColumnalSelect,
  Form,
  ButtonLink,
  NewEntriesSelect,
} from '../../../../common/FormItems';
import { excludeUnsetField } from 'components/utils/excludeUnsetField';
import swal from 'sweetalert';
import axios from '../../../../../axios';
import API from '../../../../../actions/api.js';
import { RESPONSE_STRINGS } from '../../../../../../src/constants/index';

const Wrapper = styled.div``;

const StyledButtonLink = styled(ButtonLink)`

  span {
    text-transform: uppercase;
  }
`;

const personLabels = [
  {
    first_name: 'First Name',
  },
  {
    last_name: 'Last Name',
  },
];

const diseaseHistoryFormSchema = {
  person: {
    validation: 'required',
  },
  disease: {
    validation: 'required',
  },
  valid_from: {
    validation: 'required',
  },
};

const DiseaseHistoryForm = (props) => {
  const {
    addDiseaseHistory,
    updateDiseaseHistory,
    getDiseaseHistory,
    getSingleDiseaseHistory,
    userPreferences,
    languageOptions,
    activeLanguageOptions,
    routeParams,
    gotoDetailPath,
    basePath,
  } = props;

  const history = useHistory();

  const { id: diseaseHistoryId } = routeParams;

  const [formData, setFormData] = React.useState({});
  const [formSubmitting, setFormSubmitting] = React.useState(false);
  const [formLoading, setFormLoading] = React.useState(false);
  const [showEntryForm, setShowEntryForm] = React.useState(false);
  const [retrivedLanguage, setRetrivedLanguage] = React.useState('');
  const [entrySubmitting, setEntrySubmitting] = React.useState(false);

  const defaultLanguage =
    languageOptions.find((language) => language.id === retrivedLanguage) || '';

  const [entryValues, setEntryValues] = React.useState({
    value: '',
    language: userPreferences.language,
  });

  const fetchData = async () => {
    if (diseaseHistoryId) {
      try {
        setFormLoading(true);
        const { ...data } = await getSingleDiseaseHistory(diseaseHistoryId);
        setFormData(data);
      } finally {
        setFormLoading(false);
      }
    }
  };

  const toggleEntryField = () => {
    setShowEntryForm(!showEntryForm);
  };

  React.useEffect(() => {
    fetchData();
    return () => null;
  }, []);

  const onChange = (e) => {
    if (formSubmitting) return;
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const onEntryChange = (e) => {
    const { value, name } = e.target;
    setEntryValues({ ...entryValues, [name]: value });
  };

  const validateAndSubmitEntry = async () => {
    if (!(entryValues.name && entryValues.language)) {
      swal(
        tr('Warning!'),
        tr(`Please ensure all required fields are populated.`),
        'warning',
      );
      return;
    }
    try {
      setEntrySubmitting(true);
      const payload = {
        name: entryValues.name,
        language: entryValues.language,
        entry_type: 'disease',
      };
      const response = await axios.post(`${API}/propose/entry/`, payload);
      swal(
        tr('Success!'),
        tr(`${RESPONSE_STRINGS?.ENTRY_RESPONSE_SUCCESS_MESSAGE}`),
        'success',
      );
      setEntryValues({});
      toggleEntryField();
      return;
    } catch (error) {
      setEntrySubmitting(false);
    } finally {
      setEntrySubmitting(false);
    }
  };

  const onSubmit = async (e) => {
    if (formSubmitting) return;
    let payload = excludeUnsetField(formData);

    try {
      setFormSubmitting(true);
      let diseaseHistory = {};
      if (!formData.id) {
        diseaseHistory = await addDiseaseHistory(payload);
      } else {
        diseaseHistory = await updateDiseaseHistory(formData.id, payload);
      }
      // await getDiseaseHistory()
      // gotoDetailPath(diseaseHistory.id)
      history.push(basePath);
      window.scrollTo(0, 0);
    } catch (e) {
    } finally {
      setFormSubmitting(false);
    }
  };

  const { tr } = props;

  return (

    <Wrapper className="form-wrapper">
    <div className="flex">
      <StyledButtonLink
        to={basePath}
        className="option-btn"
        icon="angle double left"
        name="All Entries"
      />
    </div>
    <div className="row">
      <div
        className="col-7 ml-10 mt-30 container"
        style={{
          position: 'relative',
          background: 'var(--admincat-color-grey-1)',
        }}
      >
        <br />
          <Form
            onSubmit={onSubmit}
            formData={formData}
            isLoading={formSubmitting}
            loadingData={formLoading}
            validationSchema={diseaseHistoryFormSchema}
            title="Disease History"
          >
            {/* <ColumnalSelect
              onChange={onChange}
              value={formData.person || ''}
              showRequiredAsterisk
              name="person"
              label="Person"
              options={props.personalInfo.data}
              labels={personLabels}
            /> */}
            <Select
              optionDefaultValue={tr('Choose Person')}
              label={tr('Choose Person')}
              value={formData?.person || ''}
              name="person"
              onChange={onChange}
              options={props.personalInfoOptions}
              deselectable
              sort={false}
              showRequiredAsterisk
            />

            {/* <Select
                    onChange={onChange}
                    value={formData.disease || ""}
                    name="disease"
                    label="Disease"
                    required
                    optionDefaultValue="Choose Disease"
                    localeOptions={props.diseaseTypesLocaleOptions}
                />   */}
            <NewEntriesSelect
              value={formData.disease || ''}
              name="disease"
              label="Disease"
              optionDefaultValue="Choose Disease"
              localeOptions={props.diseaseTypesLocaleOptions}
              showRequiredAsterisk
              onChange={(e) => {
                if (e.target.value == 'proposeValue') {
                  toggleEntryField();
                  return;
                } else {
                  onChange(e);
                }
              }}
              entryDefaultLanguage={entryValues.language}
              entryValue={entryValues.name}
              entryLanguageValue={entryValues.language}
              entryValueName={'name'}
              entryType={tr('Disease')}
              entryLanguageName={'language'}
              onEntryChange={onEntryChange}
              onCreateNewEntry={(e) => {
                e.preventDefault();
                validateAndSubmitEntry();
              }}
              add={showEntryForm}
              toggleEntryField={toggleEntryField}
              options={activeLanguageOptions}
              isLoading={entrySubmitting}
              closeEntryForm={() => {
                toggleEntryField();
              }}
            />
            <InputDatePicker
              onChange={onChange}
              value={formData.valid_from || ''}
              showRequiredAsterisk
              name="valid_from"
              label="Valid From"
            />
            <InputDatePicker
              onChange={onChange}
              value={formData.valid_to || ''}
              name="valid_to"
              label="Valid To"
            />
          </Form>
        </div>
      </div>
    </Wrapper>
  );
};

export default compose(
  withTranslation,
  withPersonalInfo,
  withDiseaseTypes,
  withDiseaseHistory,
  withCustomRouter,
  withUserPreferences,
  withLanguage,
)(DiseaseHistoryForm);
