export const emptyState = `<svg width="151" height="141" viewBox="0 0 151 141" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_750_1382)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M16.4086 80.6924L0.0725236 35.3225C-0.169852 34.6688 0.193711 33.9183 0.848126 33.7004C22.3226 25.9774 46.5359 18.8112 67.2106 9.97448C68.1316 9.39344 68.8587 9.97448 69.2708 11.0639L94.9383 77.1335L101.919 96.5016C102.161 97.1553 101.822 97.9058 101.143 98.1479L52.0378 115.797L32.8902 122.673C32.2358 122.915 31.4844 122.576 31.2663 121.898L16.4086 80.6924Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M30.0302 65.6334L21.8621 18.133C21.7409 17.4309 22.2014 16.7772 22.9043 16.6562L79.087 7.02054L91.9571 4.91425C92.66 4.59952 93.7992 4.96267 94.0658 6.53633L107.954 75.85L111.42 96.1382C111.541 96.816 111.081 97.4939 110.378 97.615L58.9456 106.427L38.9011 109.865C38.2225 109.986 37.5438 109.502 37.4226 108.824L30.0302 65.6334Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M45.7603 51.6403V97.3249C45.7603 98.0512 46.3662 98.6564 47.0933 98.6564H68.3254H99.7615H104.27H122.763C123.49 98.6564 124.096 98.0512 124.096 97.3249V75.8504V16.5598V15.9303H110.596C109.287 15.9303 106.597 16.124 106.597 13.1703L106.548 0H47.0933C46.3662 0 45.7603 0.605254 45.7603 1.33156V12.7345V17.5524V51.6403Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M23.2194 26.002C15.6088 28.5682 8.07092 31.0861 0.848126 33.7008C0.193711 33.9429 -0.169852 34.6692 0.0725236 35.3229L16.4086 80.6927L31.242 121.898C31.4844 122.552 32.2115 122.915 32.8659 122.673L52.0136 115.797L95.6412 100.133L23.2194 26.002Z" fill="#B5C4CF"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M45.7606 12.7344L22.9046 16.6564C22.2259 16.7775 21.7412 17.4311 21.8624 18.1332L23.2197 26.0015L30.0304 65.6336L37.4229 108.8C37.5441 109.478 38.2227 109.962 38.9014 109.841L58.9458 106.404L95.6173 100.133L104.246 98.6563H99.7376L45.7606 12.7344Z" fill="#D7E7EC"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M124.096 75.8504V16.5598V15.9303V15.7366C124.096 15.3492 123.926 15.3008 123.345 14.7198L108.754 0.823146C108.075 0.169471 107.954 0 107.542 0H106.548H47.0933C46.3662 0 45.7603 0.605254 45.7603 1.33156V97.3249C45.7603 98.0512 46.3662 98.6564 47.0933 98.6564H122.763C123.49 98.6564 124.096 98.0512 124.096 97.3249V75.8504Z" fill="#EDF3F4"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M45.7603 12.7344L40.4038 13.6544V102.239C40.4038 102.99 41.0097 103.571 41.7369 103.571H62.969H75.5967L95.6412 100.133L104.27 98.6563H99.7616H94.2112H68.3255H47.0934C46.3662 98.6563 45.7603 98.051 45.7603 97.3247C45.7603 69.1199 45.7603 40.915 45.7603 12.7344Z" fill="#B5C4CF"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M30.03 65.634L23.2192 26.002C21.3772 26.6314 19.5351 27.2367 17.7173 27.8661L18.5656 32.7808L25.3764 72.4128L32.793 115.58C32.9142 116.257 33.5686 116.742 34.2715 116.621L54.316 113.183L63.8414 111.561L92.4902 101.247L92.1508 100.715L75.6208 103.547L58.9938 106.404L38.9494 109.842C38.2707 109.963 37.5921 109.479 37.4709 108.801L30.03 65.634Z" fill="#9AAFB7"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M124.096 75.8504V16.5598V15.9303C124.096 15.204 123.854 15.204 123.345 14.6956L108.754 0.823146C108.075 0.169471 107.954 0 107.542 0H106.548H91.7388C109.82 24.186 110.862 67.7885 97.4346 95.0007C96.8044 96.2596 96.1742 97.4701 95.4956 98.6564H99.7372H104.245H122.739C123.466 98.6564 124.072 98.0512 124.072 97.3249L124.096 75.8504Z" fill="#D7E7EC"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M106.573 13.1703C106.573 16.124 109.263 15.9303 110.572 15.9303H124.096V15.7366C124.096 15.3492 123.927 15.3008 123.345 14.7198L108.754 0.823146C108.075 0.169471 107.954 0 107.542 0H106.548L106.573 13.1703Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M123.345 14.7198L108.754 0.823146C108.075 0.169471 107.954 0 107.542 0H106.548L106.573 13.1703C106.573 16.124 109.263 15.9303 110.572 15.9303H124.096V15.7366C124.096 15.3492 123.927 15.3008 123.345 14.7198Z" fill="#B5C4CF"/>
<path d="M112.074 29.0516H58.5333C57.4911 29.0516 56.667 28.2043 56.667 27.1874C56.667 26.1706 57.5153 25.3232 58.5333 25.3232H112.074C113.116 25.3232 113.94 26.1706 113.94 27.1874C113.94 28.2043 113.116 29.0516 112.074 29.0516Z" fill="#9AAFB7"/>
<path d="M112.074 42.3436H58.5333C57.4911 42.3436 56.667 41.4962 56.667 40.4794C56.667 39.4384 57.5153 38.6152 58.5333 38.6152H112.074C113.116 38.6152 113.94 39.4626 113.94 40.4794C113.94 41.5205 113.116 42.3436 112.074 42.3436Z" fill="#9AAFB7"/>
<path d="M112.074 55.6346H58.5333C57.4911 55.6346 56.667 54.7873 56.667 53.7704C56.667 52.7536 57.5153 51.9062 58.5333 51.9062H112.074C113.116 51.9062 113.94 52.7536 113.94 53.7704C113.94 54.7873 113.116 55.6346 112.074 55.6346Z" fill="#9AAFB7"/>
<path d="M112.074 68.9266H58.5333C57.4911 68.9266 56.667 68.0793 56.667 67.0624C56.667 66.0456 57.5153 65.1982 58.5333 65.1982H112.074C113.116 65.1982 113.94 66.0456 113.94 67.0624C113.94 68.0793 113.116 68.9266 112.074 68.9266Z" fill="#9AAFB7"/>
<path d="M112.074 82.2176H58.5333C57.4911 82.2176 56.667 81.3703 56.667 80.3534C56.667 79.3366 57.5153 78.4893 58.5333 78.4893H112.074C113.116 78.4893 113.94 79.3366 113.94 80.3534C113.94 81.3703 113.116 82.2176 112.074 82.2176Z" fill="#9AAFB7"/>
<path d="M62.896 116.33C63.2596 118.001 64.326 121.148 66.6043 121.196C68.9069 121.269 69.5856 119.598 69.5856 119.598L67.6466 115.87L62.896 116.33Z" fill="#4C81BE"/>
<path opacity="0.5" d="M68.4706 120.785C69.2947 120.325 69.6098 119.623 69.6098 119.623L67.6708 115.895L66.0469 116.04C66.5559 117.759 67.4042 119.332 68.4706 120.785Z" fill="url(#paint0_linear_750_1382)"/>
<path d="M63.9382 116.233L62.896 116.33C63.2596 118.001 64.326 121.148 66.6043 121.196C66.774 121.196 66.9194 121.172 67.0649 121.172C64.9804 119.744 64.423 117.565 63.9382 116.233Z" fill="#4C81BE"/>
<path d="M128.732 73.5013L113.015 140.669L114.408 140.994L130.124 73.8264L128.732 73.5013Z" fill="#929DBC"/>
<path d="M113.577 140.807L113.02 140.662L128.725 73.5029L129.331 73.624L113.577 140.807Z" fill="white"/>
<path d="M151 78.8525C151 78.8525 146.031 72.4126 138.614 74.2041C131.197 75.9957 128.968 73.1631 128.968 73.1631L125.114 89.2629C125.114 89.2629 129.04 91.6112 137.257 89.2629C143.898 87.3503 146.613 95.267 146.613 95.267C148.77 91.7565 149.473 84.7598 151 78.8525Z" fill="url(#paint1_linear_750_1382)"/>
<path d="M139.996 75.1249C142.662 74.8101 147.582 75.8512 150.588 80.5237C150.709 79.9669 150.854 79.3859 151 78.829C151 78.829 146.031 72.3891 138.614 74.1807C131.198 75.9722 128.968 73.1396 128.968 73.1396C128.968 73.1396 129.453 76.408 139.996 75.1249Z" fill="white"/>
<path d="M117.6 101.489C117.6 101.489 120.775 105.581 122.811 105.387C125.768 105.121 125.55 99.5766 124.072 99.2135C122.593 98.8503 120.097 97.543 120.097 97.543L117.6 101.489Z" fill="#4C81BE"/>
<path opacity="0.5" d="M118.158 100.594L117.6 101.49C117.6 101.49 120.775 105.581 122.811 105.387C123.272 105.339 123.635 105.169 123.95 104.903C123.272 102.313 120.533 101.538 118.158 100.594Z" fill="#777676"/>
<path d="M125.09 102.506C124.968 101.489 124.605 100.69 124.072 100.545C122.593 100.182 120.097 98.8745 120.097 98.8745L118.061 102.07C117.77 101.731 117.6 101.489 117.6 101.489L120.097 97.543C120.097 97.543 122.593 98.8745 124.072 99.2135C124.823 99.4314 125.259 100.981 125.09 102.506Z" fill="#4C81BE"/>
<path d="M122.811 97.9064L117.818 102.409L117.043 103.112C114.304 100.061 111.517 97.5674 108.681 96.8169C108.681 96.8169 108.026 121.293 94.4291 136.013L88.3697 135.65L86.7943 121.923L86.5519 135.65L80.4925 136.013C80.4925 136.013 72.2275 130.348 67.1134 117.25L62.2658 118.195C62.2658 118.195 54.2432 104.032 61.6599 90.9339C65.5379 84.0824 72.2759 79.6277 84.1766 78.7804C99.4463 77.6667 112.583 83.9855 122.811 97.9064Z" fill="url(#paint2_linear_750_1382)"/>
<path opacity="0.5" d="M86.5764 119.598C85.4373 119.913 85.0252 133.447 85.1707 135.722L86.5764 135.65L86.8188 121.923L88.3943 135.65L89.5334 135.722C90.1151 131.437 87.1824 119.429 86.5764 119.598Z" fill="url(#paint3_linear_750_1382)"/>
<path d="M81.9955 135.916L80.517 136.013C80.517 136.013 72.664 130.638 67.5499 118.242C75.7906 130.832 80.832 134.439 81.9955 135.916ZM93.5325 135.94L94.4536 135.988C107.009 122.382 108.511 100.472 108.681 97.2279C107.833 104.975 102.452 125.723 93.5325 135.94ZM62.8963 91.5869C68.0104 79.7724 91.9571 81.9755 97.2409 82.5565C108.633 83.8155 119.079 94.008 122.206 98.4626L122.812 97.9058C112.583 83.9607 99.4707 77.6661 84.2253 78.7798C72.3247 79.6513 65.5866 84.0818 61.7086 90.9575C54.2919 104.031 62.2903 118.194 62.2903 118.194L63.1386 118.024C61.1754 112.795 56.8126 105.629 62.8963 91.5869Z" fill="#F6F6F6"/>
<path d="M85.7523 29.1256C79.5717 29.1014 74.8939 26.8741 74.6273 25.5183C74.5788 25.3004 74.3364 25.252 74.1667 25.3246C72.3974 26.1478 72.9306 28.6172 73.8517 29.8519C74.2152 30.3361 74.6515 30.7235 75.1605 31.0624C74.4334 31.3287 70.1191 28.6898 70.8462 24.7194C70.9189 24.3078 70.4584 24.1867 70.2403 24.4531C68.9072 26.172 68.0346 27.9877 68.8587 30.1424C69.3192 31.3529 70.1676 32.2245 71.1855 32.9266C71.1128 32.975 71.0401 32.9992 70.9674 33.0477C70.216 32.8056 69.4647 32.515 68.7375 32.1519C67.7438 31.6677 65.8775 30.8203 66.0229 29.513C66.0472 29.2467 65.6836 29.1982 65.5624 29.3919C64.5687 30.7719 65.6351 32.2245 66.7016 33.1687C67.3318 33.7255 67.4772 35.4203 66.8955 35.9045C61.0542 40.8433 57.3701 48.0338 57.3701 56.0231C57.3701 70.8881 70.0706 82.9448 85.7281 82.9448C101.386 82.9448 114.086 70.8881 114.086 56.0231C114.11 41.1581 101.41 29.1498 85.7523 29.1256Z" fill="#4C81BE"/>
<path opacity="0.5" d="M114.11 56.0469C114.11 70.912 101.41 82.9686 85.7525 82.9686C70.095 82.9686 57.3945 70.912 57.3945 56.0469C57.3945 54.9575 57.4672 53.868 57.6127 52.8028C61.2483 63.7216 71.9855 71.6141 84.686 71.6141C99.2043 71.6141 111.153 61.2763 112.85 47.9365C113.65 50.5028 114.11 53.2143 114.11 56.0469Z" fill="#22100D"/>
<path d="M58.6061 51.9072C62.678 35.8558 86.1884 26.656 100.173 35.5895C110.499 42.1989 114.886 55.2724 111.832 66.5785C113.286 63.3344 114.086 59.7755 114.086 56.0229C114.086 41.1579 101.385 29.1254 85.7279 29.1012C79.5473 29.077 74.8695 26.8497 74.6028 25.4939C74.5544 25.276 74.312 25.2276 74.1423 25.3002C72.373 26.1233 72.9062 28.5928 73.8272 29.8275C74.1908 30.3117 74.6271 30.6991 75.1361 31.038C74.4089 31.3043 70.0947 28.6654 70.8218 24.6949C70.8945 24.2834 70.434 24.1623 70.2158 24.4286C68.8828 26.1476 68.0102 27.9633 68.8343 30.118C69.2948 31.3285 70.1431 32.2001 71.1611 32.9022C71.0884 32.9506 71.0157 32.9748 70.943 33.0232C70.1916 32.7811 69.4402 32.4906 68.7131 32.1275C67.7194 31.6433 65.8531 30.7959 65.9985 29.4886C66.0227 29.2223 65.6592 29.1738 65.538 29.3675C64.5443 30.7475 65.6107 32.2001 66.6772 33.1443C67.3073 33.7011 67.4528 35.3958 66.8711 35.88C61.0298 40.8189 57.3457 48.0093 57.3457 55.9987C57.3457 57.6692 57.5154 59.2913 57.8305 60.8892C57.6123 57.8871 57.8789 54.8366 58.6061 51.9072Z" fill="url(#paint4_linear_750_1382)"/>
<g opacity="0.54">
<path opacity="0.54" d="M85.7526 19.0779C94.7689 19.0779 103.252 22.5883 109.651 28.9556C116.025 35.3229 119.54 43.7964 119.54 52.8268C119.54 61.833 116.025 70.3066 109.651 76.6981C103.276 83.0653 94.7932 86.5758 85.7526 86.5758C76.7362 86.5758 68.253 83.0653 61.8543 76.6981C55.4799 70.3308 51.9654 61.8572 51.9654 52.8268C51.9654 43.8207 55.4799 35.3471 61.8543 28.9556C68.2288 22.5883 76.712 19.0779 85.7526 19.0779ZM85.7526 18.3516C66.7018 18.3516 51.2383 33.7734 51.2383 52.8268C51.2383 71.856 66.6776 87.3021 85.7526 87.3021C104.803 87.3021 120.267 71.8802 120.267 52.8268C120.267 33.7976 104.803 18.3516 85.7526 18.3516Z" fill="white"/>
</g>
<g opacity="0.19">
<path opacity="0.19" d="M110.42 76.605C123.899 63.1417 123.899 41.3134 110.42 27.8501C96.9417 14.3868 75.0887 14.3868 61.6101 27.8501C48.1316 41.3134 48.1316 63.1417 61.6101 76.605C75.0887 90.0683 96.9417 90.0683 110.42 76.605Z" fill="white"/>
</g>
<path d="M104.972 83.7917H66.5317C65.029 83.7917 63.8413 82.5811 63.8413 81.1043C63.8413 79.6033 65.0532 78.417 66.5317 78.417H104.972C106.475 78.417 107.663 79.6275 107.663 81.1043C107.663 82.5811 106.451 83.7917 104.972 83.7917Z" fill="#4C81BE"/>
<path d="M107.663 81.1041C107.663 81.2494 107.639 81.4188 107.615 81.5641C107.397 80.281 106.282 79.3126 104.949 79.3126H66.532C65.7806 79.3126 65.102 79.6031 64.6172 80.1115C64.2294 80.4989 63.9628 81.0073 63.8659 81.5641C63.8416 81.4188 63.8174 81.2494 63.8174 81.1041C63.8174 80.3536 64.1082 79.6757 64.6172 79.1915C65.102 78.7073 65.7806 78.3926 66.532 78.3926H104.973C106.451 78.3926 107.663 79.6031 107.663 81.1041Z" fill="url(#paint5_linear_750_1382)"/>
<path opacity="0.5" d="M107.663 81.1044C107.663 81.8549 107.372 82.5328 106.863 83.017C106.378 83.5012 105.7 83.8159 104.948 83.8159H66.5317C65.029 83.8159 63.8413 82.6054 63.8413 81.1286C63.8413 80.8381 63.8898 80.5476 63.9867 80.2812C64.3261 81.3465 65.344 82.1212 66.5559 82.1212H104.997C105.748 82.1212 106.427 81.8307 106.911 81.3223C107.202 81.0318 107.445 80.6686 107.566 80.2812C107.614 80.5233 107.663 80.8139 107.663 81.1044Z" fill="url(#paint6_linear_750_1382)"/>
<path d="M80.517 136.014L81.0018 137.515L78.9658 138.895L86.6976 138.628L86.5764 135.65L80.517 136.014Z" fill="#4C81BE"/>
<path opacity="0.79" d="M87.5942 25.2035C89.3151 21.572 102.016 22.9277 109.772 33.4349C116.413 42.4411 117.6 56.0472 114.11 56.0472C113.165 56.0472 110.596 42.9979 101.7 37.1391C92.1751 30.8444 84.7584 31.1834 87.5942 25.2035Z" fill="url(#paint7_linear_750_1382)"/>
<path opacity="0.79" d="M86.1887 76.6254C84.6133 80.3295 71.8643 79.5064 63.672 69.3623C56.6432 60.6466 54.8738 47.1132 58.364 46.9679C59.3093 46.9195 62.4602 59.8477 71.5977 65.3192C81.3897 71.1781 88.7822 70.5244 86.1887 76.6254Z" fill="url(#paint8_linear_750_1382)"/>
<path d="M102.137 100.933C102.452 106.477 105.409 108.535 107.59 106.405C108.148 105.872 108.754 108.027 106.572 109.6C104.488 111.101 99.9071 110.714 100.246 101.03C100.246 101.03 102.113 100.788 102.137 100.933Z" fill="#4C81BE"/>
<path opacity="0.5" d="M103.761 110.157C104.003 109.988 104.245 109.794 104.439 109.576C105.094 108.874 105.263 108.027 105.263 107.131C103.712 106.719 102.355 104.589 102.137 100.909C102.137 100.763 100.246 101.006 100.246 101.006C100.028 107.276 101.87 109.649 103.761 110.157Z" fill="#2D1C43"/>
<path d="M105.263 82.364C103.252 82.5576 100.052 87.4481 101.119 92.2175C101.264 92.9196 102.355 92.5322 102.403 91.8786C102.622 88.1744 104.973 84.4702 106.96 83.8166C108.39 83.3324 105.797 82.2913 105.263 82.364Z" fill="#4C81BE"/>
<path opacity="0.5" d="M102.379 84.8818C101.24 86.7944 100.489 89.506 101.095 92.1933C101.24 92.8954 102.331 92.508 102.379 91.8544C102.476 90.087 103.082 88.2955 103.882 86.8671C103.47 86.1408 102.985 85.4629 102.379 84.8818Z" fill="#2D1C43"/>
<path d="M104.876 83.1627C105.457 83.3806 105.991 83.6711 106.475 84.01C106.621 83.9374 106.79 83.8406 106.936 83.7921C108.39 83.3321 105.797 82.2911 105.239 82.3395C104.876 82.3637 104.488 82.5574 104.1 82.8479C104.367 82.969 104.633 83.0658 104.876 83.1627Z" fill="url(#paint9_linear_750_1382)"/>
<path d="M107.591 106.405C107.3 106.695 106.985 106.889 106.694 107.034C106.718 108.075 106.524 109.116 105.942 109.988C106.185 109.891 106.403 109.77 106.597 109.625C108.754 108.027 108.148 105.872 107.591 106.405Z" fill="url(#paint10_linear_750_1382)"/>
<path d="M73.8273 101.03C74.1423 110.714 69.5614 111.101 67.5013 109.6C67.0407 109.261 66.6045 108.995 66.4106 108.729C66.3136 107.906 66.3136 107.204 66.2651 106.259C68.4708 108.39 71.6216 106.453 71.961 100.909C71.961 100.788 73.8273 101.03 73.8273 101.03Z" fill="#564F4F"/>
<g opacity="0.5">
<path opacity="0.5" d="M73.8273 101.03C74.1423 110.714 69.5614 111.101 67.5013 109.6C67.0407 109.261 66.6045 108.995 66.4106 108.729C66.3136 107.906 66.3136 107.204 66.2651 106.259C68.4708 108.39 71.6216 106.453 71.961 100.909C71.961 100.788 73.8273 101.03 73.8273 101.03Z" fill="#2D1C43"/>
</g>
<path d="M67.1378 83.7921C69.1253 84.4458 71.4764 88.15 71.6945 91.8541C71.743 92.5078 72.8094 92.8952 72.9791 92.1931C74.0698 87.4237 70.8462 82.5332 68.8345 82.3395C68.277 82.2911 65.6836 83.3321 67.1378 83.7921Z" fill="#4C81BE"/>
<path opacity="0.5" d="M71.6702 84.8818C72.8094 86.7944 73.5607 89.506 72.9548 92.1933C72.8094 92.8954 71.7187 92.508 71.6702 91.8544C71.5733 90.087 70.9673 88.2955 70.1675 86.8671C70.6038 86.1408 71.0885 85.4629 71.6702 84.8818Z" fill="#2D1C43"/>
<path d="M69.198 83.1627C68.6163 83.3806 68.0831 83.6711 67.5983 84.01C67.4529 83.9374 67.2833 83.8406 67.1378 83.7921C65.6836 83.3321 68.277 82.2911 68.8345 82.3395C69.198 82.3637 69.5858 82.5574 69.9736 82.8479C69.707 82.969 69.4404 83.0658 69.198 83.1627Z" fill="url(#paint11_linear_750_1382)"/>
<path opacity="0.5" d="M83.7163 138.725L86.6975 138.628L86.5763 135.65L84.9039 135.747C84.8555 136.909 84.4434 137.926 83.7163 138.725Z" fill="url(#paint12_linear_750_1382)"/>
<path d="M80.2986 138.846C80.6864 138.58 81.0742 138.313 81.462 138.023C81.9225 137.684 82.3345 137.539 81.9952 136.957C81.8013 136.619 81.4862 136.304 81.2438 135.989V135.965L80.5167 136.013L81.0015 137.514L78.9897 138.894L80.2986 138.846Z" fill="#4C81BE"/>
<path d="M94.4535 136.014L93.9688 137.515L95.9805 138.895L88.2729 138.628L88.3941 135.65L94.4535 136.014Z" fill="#4C81BE"/>
<path opacity="0.5" d="M90.0181 135.747L88.3941 135.65L88.2729 138.628L90.7452 138.701C90.3089 137.781 90.0181 136.74 90.0181 135.747Z" fill="url(#paint13_linear_750_1382)"/>
<path d="M93.6777 135.965C93.3868 136.473 93.0717 136.933 92.7324 137.417C93.3626 137.853 93.9443 138.313 94.4775 138.846L95.9803 138.894L93.9685 137.514L94.4533 136.013L93.6777 135.965Z" fill="#4C81BE"/>
<path d="M71.4038 77.3519C73.2459 74.6888 97.6531 74.9551 100.271 77.3519C102.913 79.7487 103.107 113.57 101.022 115.822C98.9619 118.073 72.3733 117.42 71.0645 115.604C68.6165 112.215 69.5618 80.015 71.4038 77.3519Z" fill="url(#paint14_linear_750_1382)"/>
<path opacity="0.2" d="M101.046 115.846C98.9616 118.098 72.3973 117.444 71.0642 115.628C69.925 114.055 69.513 106.235 69.5857 98.0758C69.6342 104.951 70.0705 110.931 71.0642 112.287C72.373 114.103 98.9616 114.781 101.046 112.505C101.919 111.561 102.379 105 102.428 97.5674C102.525 106.283 102.04 114.757 101.046 115.846Z" fill="#777676"/>
<path opacity="0.4" d="M101.046 91.7089C98.9618 92.6289 72.3975 92.3626 71.0644 91.6121C69.804 90.91 69.4405 87.109 69.6344 83.429C69.7798 85.7532 70.2161 87.6658 71.0644 88.15C72.3732 88.8763 98.9618 89.1668 101.046 88.2469C101.822 87.9079 102.258 85.8016 102.404 83.2354C102.597 87.1332 102.137 91.2247 101.046 91.7089Z" fill="#777676"/>
<path d="M71.4038 75.3183C73.2459 74.2288 97.6531 74.3499 100.271 75.3183C102.913 76.2867 103.107 90.0865 101.022 91.0064C98.9619 91.9264 72.3733 91.6601 71.0645 90.9096C68.6165 89.5296 69.5618 76.4077 71.4038 75.3183Z" fill="url(#paint15_linear_750_1382)"/>
<path opacity="0.3" d="M102.428 85.196C102.331 81.4434 101.604 77.6424 100.271 77.134C97.6289 76.1656 73.2459 76.0446 71.4039 77.134C70.4344 77.7151 69.7072 81.5887 69.6103 85.3655C69.4891 81.1287 70.2889 75.9719 71.4039 75.3183C73.2459 74.2288 97.6532 74.3499 100.271 75.3183C101.846 75.8993 102.549 80.9592 102.428 85.196Z" fill="#636262"/>
<path opacity="0.2" d="M101.046 91.0068C98.9618 91.9267 72.3975 91.6604 71.0644 90.9099C69.804 90.2078 69.4405 86.4068 69.6344 82.7269C69.7798 85.0511 70.2161 86.9637 71.0644 87.4479C72.3732 88.1742 98.9618 88.4647 101.046 87.5447C101.822 87.2058 102.258 85.0995 102.404 82.5332C102.597 86.431 102.137 90.5226 101.046 91.0068Z" fill="#777676"/>
<path opacity="0.5" d="M74.9182 94.4198C73.2216 96.3808 72.8823 107.977 74.9182 109.067C76.9542 110.156 95.9564 110.302 96.6593 109.067C97.3622 107.832 98.2347 96.066 96.6593 94.4198C95.1081 92.7735 76.6391 92.4587 74.9182 94.4198Z" fill="url(#paint16_linear_750_1382)"/>
<path opacity="0.5" d="M65.9745 97.2529C65.9745 97.2045 65.8533 96.3813 65.514 97.8823C63.9143 104.952 65.2231 114.442 66.2653 117.396C66.5804 117.299 66.847 117.275 67.1621 117.226C66.5077 113.062 65.9018 100.618 65.9745 97.2529Z" fill="url(#paint17_linear_750_1382)"/>
<path opacity="0.5" d="M117.818 102.409L117.043 103.111C114.304 100.061 111.517 97.5671 108.681 96.8166C108.657 96.7924 110.281 95.8239 114.474 98.7292C115.419 99.3828 116.994 100.981 117.818 102.409Z" fill="url(#paint18_linear_750_1382)"/>
</g>
<defs>
<linearGradient id="paint0_linear_750_1382" x1="66.0495" y1="118.33" x2="69.5961" y2="118.33" gradientUnits="userSpaceOnUse">
<stop stop-color="#36204C"/>
<stop offset="1" stop-color="#583681"/>
</linearGradient>
<linearGradient id="paint1_linear_750_1382" x1="125.12" y1="84.2292" x2="150.999" y2="84.2292" gradientUnits="userSpaceOnUse">
<stop stop-color="#E4DAE5"/>
<stop offset="1" stop-color="#F7F3F8"/>
</linearGradient>
<linearGradient id="paint2_linear_750_1382" x1="58.5506" y1="107.328" x2="122.804" y2="107.328" gradientUnits="userSpaceOnUse">
<stop stop-color="#E4DAE5"/>
<stop offset="1" stop-color="#F7F3F8"/>
</linearGradient>
<linearGradient id="paint3_linear_750_1382" x1="85.1315" y1="127.667" x2="89.6168" y2="127.667" gradientUnits="userSpaceOnUse">
<stop stop-color="#CEB8D4" stop-opacity="0.9"/>
<stop offset="1" stop-color="#CEB8D4" stop-opacity="0.3"/>
</linearGradient>
<linearGradient id="paint4_linear_750_1382" x1="57.379" y1="45.453" x2="114.115" y2="45.453" gradientUnits="userSpaceOnUse">
<stop stop-color="#E4DAE5"/>
<stop offset="1" stop-color="#F7F3F8"/>
</linearGradient>
<linearGradient id="paint5_linear_750_1382" x1="63.8274" y1="79.9835" x2="107.669" y2="79.9835" gradientUnits="userSpaceOnUse">
<stop stop-color="#36204C"/>
<stop offset="1" stop-color="#583681"/>
</linearGradient>
<linearGradient id="paint6_linear_750_1382" x1="63.8271" y1="82.0253" x2="107.668" y2="82.0253" gradientUnits="userSpaceOnUse">
<stop stop-color="#36204C"/>
<stop offset="1" stop-color="#583681"/>
</linearGradient>
<linearGradient id="paint7_linear_750_1382" x1="90.6156" y1="23.0802" x2="120.193" y2="52.9361" gradientUnits="userSpaceOnUse">
<stop stop-color="white"/>
<stop offset="1" stop-color="white" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint8_linear_750_1382" x1="57.7052" y1="43.4854" x2="77.9217" y2="76.1693" gradientUnits="userSpaceOnUse">
<stop stop-color="white"/>
<stop offset="1" stop-color="white" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint9_linear_750_1382" x1="104.104" y1="83.1808" x2="107.375" y2="83.1808" gradientUnits="userSpaceOnUse">
<stop stop-color="#36204C"/>
<stop offset="1" stop-color="#583681"/>
</linearGradient>
<linearGradient id="paint10_linear_750_1382" x1="105.925" y1="108.146" x2="108.11" y2="108.146" gradientUnits="userSpaceOnUse">
<stop stop-color="#36204C"/>
<stop offset="1" stop-color="#583681"/>
</linearGradient>
<linearGradient id="paint11_linear_750_1382" x1="66.697" y1="83.1808" x2="69.9677" y2="83.1808" gradientUnits="userSpaceOnUse">
<stop stop-color="#36204C"/>
<stop offset="1" stop-color="#583681"/>
</linearGradient>
<linearGradient id="paint12_linear_750_1382" x1="83.7244" y1="137.185" x2="86.6959" y2="137.185" gradientUnits="userSpaceOnUse">
<stop stop-color="#36204C"/>
<stop offset="1" stop-color="#583681"/>
</linearGradient>
<linearGradient id="paint13_linear_750_1382" x1="88.2708" y1="137.177" x2="90.7533" y2="137.177" gradientUnits="userSpaceOnUse">
<stop stop-color="#36204C"/>
<stop offset="1" stop-color="#583681"/>
</linearGradient>
<linearGradient id="paint14_linear_750_1382" x1="69.5937" y1="96.3641" x2="102.451" y2="96.3641" gradientUnits="userSpaceOnUse">
<stop stop-color="#E4DAE5"/>
<stop offset="1" stop-color="#F7F3F8"/>
</linearGradient>
<linearGradient id="paint15_linear_750_1382" x1="69.5937" y1="83.0638" x2="102.451" y2="83.0638" gradientUnits="userSpaceOnUse">
<stop stop-color="#E4DAE5"/>
<stop offset="1" stop-color="#F7F3F8"/>
</linearGradient>
<linearGradient id="paint16_linear_750_1382" x1="73.5166" y1="101.496" x2="97.5512" y2="101.496" gradientUnits="userSpaceOnUse">
<stop stop-color="#CEB8D4" stop-opacity="0.9"/>
<stop offset="1" stop-color="#CEB8D4" stop-opacity="0.3"/>
</linearGradient>
<linearGradient id="paint17_linear_750_1382" x1="64.757" y1="107.207" x2="67.139" y2="107.207" gradientUnits="userSpaceOnUse">
<stop stop-color="#CEB8D4" stop-opacity="0.9"/>
<stop offset="1" stop-color="#CEB8D4" stop-opacity="0.3"/>
</linearGradient>
<linearGradient id="paint18_linear_750_1382" x1="108.697" y1="99.8721" x2="117.83" y2="99.8721" gradientUnits="userSpaceOnUse">
<stop stop-color="#CEB8D4" stop-opacity="0.9"/>
<stop offset="1" stop-color="#CEB8D4" stop-opacity="0.3"/>
</linearGradient>
<clipPath id="clip0_750_1382">
<rect width="151" height="141" fill="white"/>
</clipPath>
</defs>
</svg>`;
