import React from "react";
import compose from "lodash/fp/compose";
import { connect } from "react-redux";
import hoistNonReactStatics from "hoist-non-react-statics";
import { getSchoolType } from "actions/preload";
import { BaseHOC, withBaseHOCProps } from "../common/BaseHOC";

let loaded = false

export const withSchoolType = (WrappedComponent) => {
  class SchoolType extends BaseHOC {

    async componentDidMount() {
      const { getSchoolType } = this.props
      if (!loaded)  {
        loaded = true;
        await getSchoolType();
      }
    }

    get schoolTypeOptions() {
      const { schoolType } = this.props;
      return this._getOptions(schoolType, ['id'], true)
    }

    getSchoolTypeByType = (type) => {
      const { schoolType } = this.props;
      const options = schoolType.filter(({ type: sType }) => {
        return sType === type
      })
      return this._getOptions(options, ['id'], true)
    }

    render() {
      return (
        <WrappedComponent 
          {...this.props} 
          schoolTypeOptions={this.schoolTypeOptions}
          getSchoolTypeByType={this.getSchoolTypeByType}
        />
      )
    }
  }
  const mapStateToProps = ({ schoolType }) => ({ schoolType });
  const WithSchoolType = connect(mapStateToProps, { getSchoolType })(SchoolType);
  hoistNonReactStatics(WithSchoolType, WrappedComponent)
  return compose(withBaseHOCProps)(WithSchoolType);
}
