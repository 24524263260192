import { BaseActionClass } from "../common/BaseActionClass"
import { MEMBERSHIP_INFO } from '../types'

const path = "/dashboard/membership";
const name = "Membership";

class MembershipInfo extends BaseActionClass {

  constructor() { super(path, MEMBERSHIP_INFO, name) }

  addMembershipInfo = this._add;

  updateMembershipInfo = this._update;

  deleteMembershipInfoById = this._deleteById;

  getMembershipInfo = this._getAll;

  getMembershipInfoById = this._getById;

  deleteBulkMembershipInfo = this._deleteBulk;
}

export const { 
  addMembershipInfo, 
  updateMembershipInfo, 
  deleteMembershipInfoById,
  getMembershipInfoById, 
  getMembershipInfo,
  deleteBulkMembershipInfo,
} = new MembershipInfo()

