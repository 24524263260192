/* eslint-disable react/jsx-filename-extension */
import React from 'react';
// import { useDispatch, useSelector, shallowEqual } from 'react-redux';
// import { useLocation } from 'react-router-dom';
import compose from 'lodash/fp/compose';
// import {
//   getContracts,
//   // deleteContractById,
//   // deleteBulkContract,
//   setContractDetail,
// } from 'actions';
import {
  withTranslation,
  withBulkDelete,
  withDeleteConfirmation,
  withUserLayoutChoice,
  withCustomRouter,
  withContract,
} from 'components/hoc';
import MyDataContentTemplate from '../MyDataContentTemplate';
import './style.css';
// import { DATA_AREA_KEYS } from 'constants/index';
import LottieLoader from 'components/common/LottieLoader';
import { getFullName } from "components/utils";

const Contract = (props) => {
  // const dispatch = useDispatch();

  // const { loading } = useSelector((state) => {
  //   return state.contract;
  // }, shallowEqual);

  const { tr, loaded, contract, deleteBulkContract, gotoCreatePath } = props;

  const getPersonIs = (person_is) => {
    switch (person_is) {
      case 'B':
        return tr('Buyer');
      case 'S':
        return tr('Seller');
      default:
        return '';
    }
  };

  const mapFields = () => {
    const { trObj, fd } = props;
    return contract.data.map((contract) => {
      return {
        ...contract,
        space_name: contract.space,
        description: contract.description,
        start_date: fd(contract.start_date),
        continuous: contract.continuous ? tr('Yes') : tr('No'),
        auto_renewal: contract.auto_renewal ? tr('Yes') : tr('No'),
        contractor_id: contract.contractor,
        contractor: contract.contractor_detail.name,
        contract: contract.contract_id,
        customer: contract.customer_id,
        org_indicator: contract.org_indicator === 'P' ? 'Personal' : 'Organisation',
        end_date: fd(contract.end_date),
        data_area: trObj(contract.data_area_detail),
        run_time_unit: trObj(contract.run_time_unit_detail),
        notice_period_unit: trObj(contract.notice_period_unit_detail),
        person: getFullName(contract?.person_detail),
        person_is: getPersonIs(contract.person_is),
        renewal_period_unit: trObj(contract.renewal_period_unit_detail),
        // person_is: contract.person_is,
        document: contract.document?.name,
      };
    });
  };

  const checkContractItems = (contract) => {
    if (contract.has_contract_items <= 0)
      return tr(
        'This contract has no contract items and is therefore not complete yet. Please add at least one item.',
      );
    return '';
  };

  // const { pathname } = useLocation();

  // const fetchContracts = useCallback(() => {
  //   const dataArea = DATA_AREA_KEYS[pathname.split('/')[3]];
  //   dispatch(getContracts(dataArea));
  // }, [pathname, dispatch]);

  // const handleBulkDelete = async (contractIds) => {
  //   const deleteBulk = () => dispatch(deleteBulkContract(contractIds));
  //   const { deleteConfirmation } = props;
  //   try {
  //     await deleteConfirmation.initBulk(deleteBulk, contractIds);
  //   } catch (e) {}
  // };

  // useEffect(() => {
  //   fetchContracts();
  // }, [fetchContracts]);

  const tableName = 'contract';

  // const { gotoCreatePath } = props;

  // const goToDetailPathWithSelectedContract = (id) => {
  //   let selectedContract = data.filter(contract => contract.id == id);
  //   selectedContract = selectedContract.length > 0 ? selectedContract[0] : undefined;
  //   dispatch(setContractDetail(selectedContract));
  //   gotoDetailPath(id, selectedContract);
  // }

  // const executeOnRowClick = (detailContract) => {
  //   let selectedContract = contract.data.filter(
  //     (contract) => contract.id == detailContract.id,
  //   );
  //   selectedContract = selectedContract.length > 0 ? selectedContract[0] : '';
  //   dispatch(setContractDetail(selectedContract));
  // };

  if (!loaded) {
    return (
      <>
        <LottieLoader />
      </>
    );
  }

  return (
    <MyDataContentTemplate
      columns={[]}
      data={mapFields()}
      toggleForm={gotoCreatePath}
      bulkDelete={deleteBulkContract}
      tableName={tableName}
      // executeOnRowClick={executeOnRowClick}
      hasQuestionMark={checkContractItems}
    />
  );
};

export default compose(
  withTranslation,
  withBulkDelete,
  withDeleteConfirmation,
  withUserLayoutChoice,
  withCustomRouter,
  withContract,
)(Contract);
