import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAccountCategory } from '../actions';

export const useAccountCategory = () => {
  const dispatch = useDispatch();
  const accountCategoryOptions = useSelector((state) => state.accountCategory);

  useEffect(() => {
    dispatch(getAccountCategory());
  }, [dispatch]);

  return {
    accountCategoryOptions,
  };
};
