import React from "react";
import compose from "lodash/fp/compose";
import { connect } from "react-redux";
import hoistNonReactStatics from 'hoist-non-react-statics'
import * as residenceInfoActions from "actions/dashboard/residenceInfo"
import { BaseHOC, withBaseHOCProps } from "../common/BaseHOC"

// let loaded = false

const initialState = {
  loaded: false,
}

export const withResidenceInfo = (WrappedComponent) => {
  class ResidenceInfo extends BaseHOC {
    state = { ...initialState }
    async componentDidMount() {
      const { getResidenceInfo } = this.props
      if (!this.state.loaded)  {
        await getResidenceInfo();
        this.setState({...initialState, loaded: true });
        // loaded = true;
        
      }
    }

    

    // get residenceInfoOptions() {
    //   const { residenceInfo } = this.props;
    //   return this._getOptions(residenceInfo.data, ['id', 'description'])
    //     .map(({ description, ...rest }) => ({ ...rest, name: description }))
    // }

    deleteResidenceInfoById = async (data) => {
      const { deleteResidenceInfoById } = this.props
      await this._deleteById(deleteResidenceInfoById, data);
    }

    deleteBulkResidenceInfo = async (data) => {
      const { deleteBulkResidenceInfo } = this.props
      await this._deleteBulk(deleteBulkResidenceInfo, data);
    }

    render() {
      return (
        <WrappedComponent 
          {...this.props} 
          loaded={this.state.loaded}
          deleteResidenceInfoById={this.deleteResidenceInfoById}
          deleteBulkResidenceInfo={this.deleteBulkResidenceInfo}
          // companionAnimalOptions={this.companionAnimalOptions}
        />
      )
    }
  }
  const mapStateToProps = ({ residenceInfo }) => ({ residenceInfo })
  const WithResidenceInfo = connect(mapStateToProps, residenceInfoActions)
  (ResidenceInfo);
  hoistNonReactStatics(WithResidenceInfo, WrappedComponent)
  return compose(withBaseHOCProps)(WithResidenceInfo);
}
