import React from 'react';
import styled from 'styled-components';
import RouteRedirect from '../RouteRedirect';
import SideMenu from '../sidemenu';
import { useSelector } from 'react-redux';
import './style.css';
import { withTranslation } from '../../hoc';
import compose from 'lodash/fp/compose';
// import NewSideBar from "../sidemenu/sideBarIndex"

const Wrapper = styled.div`
  @media (max-width: 950px) {
    flex-wrap: wrap;
  }
`;

const Container = styled.div`
  margin-left: 50px;
`;

const ContactTemplate = (props) => {
  const { stripeHeaderTitle, className, tr, ...rest } = props;
  // const { height } = useScrollPosition(); //***Spotted causing re-rending issues***//
  const account = useSelector((state) => state.account);

  return (
    <>
      <div className={className}>
        {account.user && <SideMenu />}
        {/* <NewSideBar links={props.routes || []} /> */}
        <Container className="my-3  DashboardTemplate">
          <Wrapper className="flex Wrapper">
            {Boolean(props.dropZone) ? (
              <>
                {props.children ||
                  props.render({
                    hasSideBar: props.showSideBar,
                    ...rest,
                  })}
              </>
            ) : (
              <>
                {props.children ||
                  props.render({
                    hasSideBar: props.showSideBar,
                    ...rest,
                  })}
              </>
            )}
          </Wrapper>
        </Container>
      </div>
    </>
  );
};

export const getHasSideBarStyle = ({ hasSideBar }) => {
  return (
    hasSideBar &&
    `
    min-width: 250px;
    width: calc(100% - 200px);
    flex: auto;

    @media (max-width: 950px) {
      min-width: 600px;
    }

    @media (max-width: 600px) {
      min-width: 100%;
    }
  `
  );
};

const StyledRouteRedirect = styled(RouteRedirect)`
  &&& {
    padding: ${({ card }) => (card ? '20px' : '')};
    background: var(--admincat-color-grey-1);
    ${getHasSideBarStyle}
  }
`;

const Main = ({ className, ...rest }) => {
  return (
    <StyledRouteRedirect
      className={`${rest.card && 'card'} ${className}`}
      {...rest}
    />
  );
};

ContactTemplate.defaultProps = {
  showSubHeading: false,
  className: '',
};

Main.defaultProps = {
  showSideBar: false,
  showSubHeading: false,
  className: '',
  card: true,
};

ContactTemplate.Main = Main;
export default compose(withTranslation)(ContactTemplate);
