import React from 'react';
import styled from 'styled-components';
import DashboardTemplate from '../../common/DashboardTemplate';
import Card from '../../common/Card';
import routes, { redirects } from './routes';
import PageHeader from './components/PageHeader';
import { Tabs, TabContent } from '../../common/Tabs';
import * as Icon from 'react-bootstrap-icons';
import customStyle from './index.css';
import SettingsUserTab from './SettingsUserTab';
import SettingsWorkspaceTab from './SettingsWorkspaceTab';
// import SettingsDashboardTab from './SettingsDashboardTab';
import SettingsPaymentTab from './SettingsPaymentTab';

const SettingsWrapper = styled.div`
  ${customStyle}
  margin-right: 5rem;
  margin-left: 3rem;
`;

const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  width: 90%;
  margin: auto;
`;

const InnerContainer = styled.div`
  padding: 2% 10%;
`;

const Settings = (props) => {
  const user = JSON.parse(localStorage.getItem('user'));
  const [activeTab, setActiveTab] = React.useState(0);

  return (
    <DashboardTemplate
      stripeHeaderTitle="Settings"
      showSubHeading={false}
      showSideBar={true}
      card={true}
      redirects={redirects}
    >
      <Wrapper>
        <PageHeader
          leftTitle={''}
          // rightIcon={<Icon.Gear />}
        />
        <InnerContainer>
          <Tabs
            labels={['User', 'Workspace', 'Payments']}
            setActiveTab={setActiveTab}
            activeTab={activeTab}
          />
          <TabContent index={0} activeTab={activeTab}>
            <SettingsUserTab />
          </TabContent>
          <TabContent index={1} activeTab={activeTab}>
            <SettingsWorkspaceTab />
          </TabContent>
          <TabContent index={2} activeTab={activeTab}>
            <SettingsPaymentTab />
          </TabContent>
          {/* <TabContent index={2} activeTab={activeTab}>
              <SettingsDashboardTab />
            </TabContent> */}
        </InnerContainer>
      </Wrapper>
    </DashboardTemplate>
  );
};

export default Settings;
