import styled, {css} from "styled-components";


export const SectionOne = styled.div`
    background: #fff;
    padding:50px;
    position:relative;
    @media only screen and (max-width:750px){
        padding:25px;
    }

    .shape-one{
        position:absolute;
        top:20%;
        right:20px;
        width: 100px;

        @media only screen and (max-width:750px){
            top:15%;
            width:75px;
            right:7px;
        }
    }
    .shape-two{
        position:absolute;
        top:20%;
        left:20px;
        width:150px;
        @media only screen and (max-width:750px){
            top:10%;
            width:95px;
            left:7px;
        }
    }
    .div-image{
        width:70%;
        margin:auto;
        margin-top:15px;

        @media only screen and (max-width:750px){
            width:100%;
            padding:0;
        }
    }
    
    .card-wrapper{
        display:flex;
        justify-content:space-evenly;
        flex-wrap:wrap;
        
    }
    .card-content{
        width:400px;
        padding:20px;
        margin:10px;
        position:relative;
        @media only screen and (max-width:750px){
        }
    }

    .card-content-border{
            &::after{
                content:"";
                width:1px;
                height:50%;
                background:rgba(128, 138, 142, 0.2);
                position:absolute;
                right:0;
                top:50%;
                transform: translateY(-50%)
            }
            
            @media only screen and (max-width:750px){
                &::after{
                    display:none;
                }

            }
        }

`

export const SectionTwo = styled.div`
    background: #1f4065;
    padding: 40px;

    @media only screen and (max-width:750px){
        padding:20px;
    }

    .sec-two-header{
        padding: 10px;
        margin-bottom:20px;
    }
    .flex{
        display:flex;
        @media only screen and (max-width:750px){
            flex-direction: column;
        }
    }
    .fixed-flex{
        display:flex;
    }
    .text-wrapper{
        max-width: 80%;
        margin:auto;

        @media only screen and (max-width:750px){
            width:100%;
        }
    }
    .text-card{
        display:flex;
        padding:15px;
        flex:1;
        margin-bottom: 10px;

    }
    .text-card-texts{
        padding-left:10px;
    } 
`

export const SectionThree = styled.div`
    padding: 40px;
    height: 70vh;
    display:flex;
    align-items:center;
    background: #fff;
    @media only screen and (max-width:750px){
        height:auto;
    }
    .wrapper{
        max-width: 70%;
        margin:auto;
        display:flex;
        flex-direction: column;
        align-items:center;
        justify-content:center;

        overflow:hidden;
    }

    .sec-three-header{
        padding:10px;
        margin-bottom: 20px;
    }
`

