import React from "react";
import { connect } from "react-redux";
import { getLegalForm } from "../../../actions";
import hoistNonReactStatics from "hoist-non-react-statics";

let loaded = false;

export const withLegalForm = (WrapperComponent) => {
  class WithLegalForm extends React.Component {
    componentDidMount() {
      const { getLegalForm } = this.props;
      if (loaded) return;
      loaded = true;
      getLegalForm();
    }

    getLegalFormOptions = () => undefined;

    getLegalFormLocaleOptions = () => {
      const { legalForm } = this.props;
      return legalForm.map(({ created_at, ...rest }) => ({ ...rest }));
    };
    
    getLegalFormByCountryId = (countryId) => {
      const { getLegalForm } = this.props;
      getLegalForm({ country: countryId });
    };

    render() {
      return (
        <WrapperComponent
          
          legalFormOptions={this.getLegalFormOptions()}
          legalFormLocaleOptions={this.getLegalFormLocaleOptions()}
          getLegalFormByCountryId={this.getLegalFormByCountryId}
          {...this.props}
        />
      );
    }
  }
  const mapStateToProps = ({ legalForm }) => ({ legalForm });
  const WithLegalFormHOC = connect(mapStateToProps, {
    getLegalForm,
  })(WithLegalForm);
  hoistNonReactStatics(WithLegalFormHOC, WrapperComponent);
  return WithLegalFormHOC;
};
