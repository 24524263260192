import React from "react";
import styled from "styled-components";
import { Icon } from "semantic-ui-react";


const Wrapper = styled.div`
  position: relative;
  
  > div {
    width: 100%;
  }
`

const StyledIcon = styled(Icon)`
  position: absolute;
  right: -20px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
`

export const withDependentField = (Component, options={}) => {
  const { toggleButton = true, onClick = () => {} } = options;
  return (
    <Wrapper>
      {Component}
      {toggleButton && (
        <StyledIcon name="plus" onClick={onClick} />
      )}
    </Wrapper>
  )
}
