import * as types from '../../actions';

const initialState = {
  data: [],
  loading: false,
  contract: {
    data: null,
    contract_items: [],
    loading: false,
  },
  contract_reference_id_list: {
    data: [],
    loading: false,
  },
};

export const contract = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.CREATE_CONTRACT:
      return {
        ...state,
        contract: {
          ...state.contract,
          ...action.payload,
        },
      };

    case types.GET_CONTRACT:
      return {
        ...state,
        contract: {
          data: {},
          contract_items: [],
          loading: false,
        },
        data: action.payload.data,
        loading: action.payload.loading,
      };

    case types.GET_SINGLE_CONTRACT:
      return {
        ...state,
        contract: {
          ...state.contract,
          ...action.payload,
        },
      };

    case types.SET_SINGLE_CONTRACT:
      return {
        ...state,
        contract: {
          ...state.contract,
          ...action.payload,
        },
      };

    case types.DELETE_CONTRACTS:
      return {
        ...state,
        data: state.data.filter((contract) => !action.payload.includes(contract.id)),
      };

    case types.GET_ITEM_REFERENCE_ID:
      return {
        ...state,
        contract_reference_id_list: action.payload,
      };
    default:
      return state;
  }
};
