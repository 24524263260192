import axios from '../../axios';
import * as types from "../types";
import { createTypes } from "../createTypes";

import { setMsgInfo } from "../";
import API from "../api";
import filterError from "../common/filterError";

const baseUrl = API + "/attachment-rule";

export const addAttachmentRule = (payload) => { 
  return (async (dispatch) => { 
    try {
      const { data } = await axios.post(`${baseUrl}/`, payload);
      dispatch({ type: createTypes(types.ADD_ATTACHMENT_RULE).success, payload: data });
      dispatch(setMsgInfo({
        success: true, 
        msg: ["Rule added successfully"],
        navigationCounter: 2,
      }))
      return data
    }
    catch(err) {
      const errors = filterError(err);
      dispatch({ type: createTypes(types.ADD_ATTACHMENT_RULE).failure, payload: errors });
      dispatch(setMsgInfo({
        success: false, 
        msg: errors,
      }));
    }
  });
}

export const updateAttachmentRule = (id, payload) => { 
  return (async (dispatch) => { 
    try {
      const { data } = await axios.put(`${baseUrl}/${id}/`, payload);
      dispatch({ type: createTypes(types.UPDATE_ATTACHMENT_RULE).success, payload: data });
      dispatch(setMsgInfo({
        success: true, 
        msg: ["Rule updated successfully"],
      }))
      return data
    }
    catch(err) {
      const errors = filterError(err);
      dispatch({ type: createTypes(types.UPDATE_ATTACHMENT_RULE).failure, payload: errors });
      dispatch(setMsgInfo({
        success: false, 
        msg: errors,
      }));
    }
  });
}

export const deleteAttachmentRuleById = (id, payload) => { 
  return (async (dispatch) => { 
    try {
      await axios.delete(`${baseUrl}/${id}/`);
      dispatch({ type: createTypes(types.DELETE_ATTACHMENT_RULE_BY_ID).success, payload });
      dispatch(setMsgInfo({
        success: true, 
        msg: ["Attachment Rule deleted successfully"],
      }))
    }
    catch(err) {
      const errors = filterError(err);
      dispatch({ type: createTypes(types.DELETE_ATTACHMENT_RULE_BY_ID).failure, payload: errors });
      dispatch(setMsgInfo({
        success: false, 
        msg: ["Attachment Rule could not be deleted"],
      }));
    }
  });
}



export const deleteBulkAttachmentRule = (payload) => { 
  return (async (dispatch) => { 
    try {
      await axios.post(`${baseUrl}/bulk_delete/`, { ids: payload });
      dispatch({ type: createTypes(types.DELETE_BULK_ATTACHMENT_RULE).success, payload });
      dispatch(setMsgInfo({
        success: true, 
        msg: ["Attachment Rule deleted successfully"],
      }))
    }
    catch(err) {
      const errors = filterError(err);
      dispatch({ type: createTypes(types.DELETE_BULK_ATTACHMENT_RULE).failure, payload: errors });
      dispatch(setMsgInfo({
        success: false, 
        msg: ["Attachment Rule could not be deleted"],
      }));
    }
  });
}


// export const deleteAttachmentRule = (payload) => {
//   return async (dispatch) => {
//     try {
//       await axios.post(`${baseUrl}/bulk_delete/`, { ids: payload });
//       dispatch(createAction(types.DELETE_ATTACHMENT_RULE, payload));
//       return payload;
//     } catch (err) {
//       throw err;
//     }
//   };
// };


export const getAttachmentRules = () => { 
  return (async (dispatch) => { 
    try {
      const { data } = await axios.get(`${baseUrl}/`);
      dispatch({ type: createTypes(types.GET_ATTACHMENT_RULE).success, payload: data });
    }
    catch(err) {
      const errors = filterError(err);
      dispatch({ type: createTypes(types.GET_ATTACHMENT_RULE).failure, payload: errors });
      dispatch(setMsgInfo({
        success: false, 
        msg: errors,
      }));
    }
  });
}


export const getAttachmentRuleById = (id) => { 
  return (async (dispatch) => { 
    try {
      const { data } = await axios.get(`${baseUrl}/${id}/`);
      return data;
      // dispatch({ type: types.ATTACHMENT_RULE_FORM, payload: { data } });
    }
    catch(err) {
      const errors = filterError(err);
      dispatch(setMsgInfo({
        success: false, 
        msg: errors,
      }));
    }
  });
}

