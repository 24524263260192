import axios from '../axios';
import { createTypes } from './createTypes';
import createAction from './createAction';
import API from './api';
import {
  CHANGE_USERNAME_SUCCESS,
  CHANGE_USERNAME_FAILED,
  CHANGE_USERNAME_LOADING,
} from './types';

const baseUrl = `${API}/username/update_username/`;
const getToken = localStorage.getItem('token');

const headers = (token) => {
  return {
    headers: {
      'Content-Type': 'application/json; charset=UTF-8',
      Authorization: 'Token ' + token,
    },
  };
};

const startChange = () => ({
  type: CHANGE_USERNAME_LOADING,
});

const updateUsernameFailed = (payload) => ({
  type: CHANGE_USERNAME_FAILED,
  payload,
});

const updateUsernameSuccess = (payload) => ({
  type: CHANGE_USERNAME_SUCCESS,
  payload,
});

const updateUsername = (payload) => {
  return function (dispatch) {
    dispatch(startChange());
    axios
      .post(baseUrl, payload, headers(getToken))
      .then((res) => {
        dispatch(updateUsernameSuccess(res));
      })
      .catch((err) => {
        dispatch(updateUsernameFailed(err));
        console.log(err);
      });
  };
};

export default updateUsername;
