import { ORG_INDICATOR_TYPES } from '../../../../constants';

export const initialValues = {
  document_type: '',
  issuing_org: '',
  country: '',
  concerned_person: '',
  reference_year: '',
  reference_month: '',
  language: '',
  valid_from: '',
  valid_to: '',
  main_area: '',
  org_indicator: ORG_INDICATOR_TYPES.ORG,
  files: null,
  description:''
};
export const getFormValuesFromPayload = (payload) => {
  const formValues = {};
  Object.keys(initialValues).forEach((key) => {
    if (payload[key]) formValues[key] = payload[key];
  });
  formValues.country = payload?.issuing_org_detail?.country;
  formValues.id = payload.id;
  return formValues;
};
