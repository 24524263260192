import React from "react";
import styled from "styled-components";
import Validator from "validatorjs";
import hoistNonReactStatics from "hoist-non-react-statics";
import { FormValidationContext } from "./FormValidation"

const FieldErrorWrapper = styled.div`
  position: relative;
  &&& input, select, div.BaseColumnalSelectWrapper, div.file__input__wrapper, textarea {
    border: ${({hasError}) => hasError ? '1px solid red' : '1px solid #e0e5ec'};
  }
`

const DisplayError = styled.div`
  width: 100%;
  position: relative;
  width: 97%;
  margin-left: 0px;
  position: relative;
  top: -10px;
  font-size: 12px;
`

const DisplayErrorItem = styled.p`
  &&& {
    color: red;
    width: 100%;
    font-size: 14px;
    margin-bottom: 4px;
  }
`
const getStyle = ({ strength, indicatorGap }) => {
  switch (strength){
    case 'weak':
      return { 
        "width": `calc(33% - ${indicatorGap})`,
        "background-color": "red", 
      }
    case 'medium':
      return { 
        "width": `calc(68% - ${indicatorGap})`,
        "background-color": "#dada38", 
      }
    case 'strong':
      return { 
        "width": `calc(100% - ${indicatorGap})`,
        "background-color": "green", 
      }
    default:
      return { 
        "width": "0px",
        "background-color": "transparent", 
      }
  }
}


const PasswordStrengthIndicator = styled.span`
  display: block;
  position: absolute;
  top: -5px;
  left: ${({indicatorLeft}) => indicatorLeft};
  ${getStyle};
  transition: width 0.5s, background-color 0.5s;
  height: 5px;
`


export const withFieldError = (WrappedComponent) => {

  const WithFieldError = (props) => {

    const { formProps, fieldValidationProps, validateField } = React.useContext(FormValidationContext);

    const { 
      name, 
      fieldType,
      indicatorGap, 
      indicatorLeft,
      onChange,
      value,
      options,
      localeOptions,
      langOptions,
      disabled,
    } = props;
    const { errors = [], strength = '' } = fieldValidationProps[name] || {};
    const hasError = !!errors.length;

    const renderErrors = () => {

      // tobe removed when error message is localized
      const showErrorMsg = fieldType === "password"

      if (hasError) {
        return (
          <DisplayError className="DisplayError">
            {fieldType === "password" && (
              <PasswordStrengthIndicator 
                className="PasswordStrength"
                strength={strength} 
                indicatorGap={indicatorGap} 
                indicatorLeft={indicatorLeft} 
              />
            )}
            {showErrorMsg && (errors.map(error => (
              <DisplayErrorItem key={error}>
                {error}
              </DisplayErrorItem>
            )))}
          </DisplayError>
        )
      }
      return null;
    }

    const onFieldChange = validateField ? validateField(onChange, fieldType) : onChange;

    return (
      <FieldErrorWrapper 
        hasError={!!errors.length}
        className={`${props.className} FieldErrorWrapper`}
      >
        <WrappedComponent 
          {...props} 
          errors={errors}
          hasError={hasError}
          onChange={onFieldChange}
          onBlur={() => onFieldChange({ target: { ...props } })}
          disabled={props.disabled || formProps.disabled}
        />
        {renderErrors()}
      </FieldErrorWrapper>
    )

  }

  WithFieldError.defaultProps = {
    className: '',
    fieldType: '',
    indicatorLeft: "0px",
    indicatorGap: "0px",
    onChange: () => {},
  }

  hoistNonReactStatics(WithFieldError, WrappedComponent)
  return WithFieldError
}
