import React from "react";
import compose from "lodash/fp/compose";
import { connect } from "react-redux";
import hoistNonReactStatics from 'hoist-non-react-statics'
import {
  createAction,
  ATTACHMENT_RULE_VIEW,
  ATTACHMENT_RULE_FORM,
} from "../../../actions";
import * as attRuleActions from "../../../actions/dashboard/attachmentRule"
import { withState, withOrganisation, withDeleteConfirmation } from "..";
import { BaseHOC, withBaseHOCProps } from "../common/BaseHOC";


let loaded = false;

export const withAttachmentRule = (WrapperComponent) => {
  class AttachmentRules extends BaseHOC {
    async componentDidMount() {
      try{
        const { getAttachmentRules } = this.props;
        if (!loaded) {
          await getAttachmentRules();
          loaded = true;
        }
      }catch(e){
        loaded = true;
      }
    }
    deleteAttachmentRuleById = async(data) => {
      const { deleteConfirmation, deleteAttachmentRuleById } = this.props
      try {
        await deleteConfirmation.init(deleteAttachmentRuleById, data);
      } catch(e) {}
    }

    deleteBulkAttachmentRule = async (data) => {
      const { deleteBulkAttachmentRule } = this.props
      await this._deleteBulk(deleteBulkAttachmentRule, data);
    }

    render() {
      const { setValue, ...rest } = this.props;
      return (
        <WrapperComponent 
          {...rest} 
          toggleAttachmentRuleForm={setValue}
          deleteAttachmentRuleById={this.deleteAttachmentRuleById}
          deleteBulkAttachmentRule={this.deleteBulkAttachmentRule}
          loaded={loaded}
        />
      )
    }
  }
  const mapStateToProps = ({ 
    attachmentRules, 
    attachmentRuleForm, 
    attachmentRuleView,
  }) => {
    return ({ 
      attachmentRules,
      attachmentRuleView,
      attachmentRuleForm,
    })
  }
  const WithAttachmentRule = connect(mapStateToProps, {
    ...attRuleActions,
    toggleAttachmentRuleView: (data) => createAction(ATTACHMENT_RULE_VIEW, data),
    setValue: (data) => createAction(ATTACHMENT_RULE_FORM, data),
  })(AttachmentRules);
  hoistNonReactStatics(WithAttachmentRule, WrapperComponent)
  return compose(
    withBaseHOCProps,
    withDeleteConfirmation,
    withOrganisation, 
    withState,
  )(WithAttachmentRule);
}
