import React from "react";
import Header from "./Header";

const ControlRoom = (props) => {
  return (

    <span>

      <Header />

      <div className="my-3 my-md-5">
        <div className="container">

          <div className="row row-cards">
            <h1>Control room is working</h1>
          </div>
        </div>
      </div>

    </span>
  )
}


export default ControlRoom;
