import * as types from '../../actions/types';

const initialState = {
  loading: false,
  error: null,
  proposals: [],
  currentProposal: null,
};

const adminEntryProposalReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.ENTRY_PROPOSAL_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case types.ENTRY_PROPOSAL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case types.GET_ALL_ENTRY_PROPOSAL_SUCCESS:
      return {
        ...state,
        loading: false,
        proposals: action.payload,
        error: null,
      };

    case types.GET_ENTRY_PROPOSAL_SUCCESS:
      return {
        ...state,
        loading: false,
        currentProposal: action.payload,
        error: null,
      };

    case types.APPROVE_ENTRY_PROPOSAL_SUCCESS:
    case types.REJECT_ENTRY_PROPOSAL_SUCCESS:
      return {
        ...state,
        loading: false,
        proposals: state.proposals.map((proposal) =>
          proposal.id === action.payload.id ? action.payload : proposal,
        ),
        currentProposal: action.payload,
        error: null,
      };
    case types.BULK_DELETE_ENTRY_PROPOSAL_SUCCESS:
      return {
        ...state,
        proposals: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default adminEntryProposalReducer;
