import React from "react";
import compose from "lodash/fp/compose";
import { connect } from "react-redux";
import hoistNonReactStatics from 'hoist-non-react-statics'
import * as personGroupMemberActions from "actions/dashboard/personGroupMember"
import { BaseHOC, withBaseHOCProps } from "../common/BaseHOC"

// preventing initial load 
let loaded = true

export const withPersonGroupMember = (WrapperComponent) => {
  class PersonGroupMember extends BaseHOC {

    async componentDidMount() {
      const { getPersonGroupMember } = this.props
      if (!loaded)  {
        loaded = true;
        await getPersonGroupMember();
      }
    }

    deletePersonGroupMemberById = async (data) => {
      const { deletePersonGroupMemberById } = this.props
      await this._deleteById(deletePersonGroupMemberById, data);
    }

    deleteBulkPersonGroupMember = async (data) => {
      const { deleteBulkPersonGroupMember } = this.props
      await this._deleteBulk(deleteBulkPersonGroupMember, data);
    }

    render() {
      return (
        <WrapperComponent 
          {...this.props} 
          deletePersonGroupMemberById={this.deletePersonGroupMemberById}
          deleteBulkPersonGroupMember={this.deleteBulkPersonGroupMember}
        />
      )
    }
  }
  const mapStateToProps = ({ personGroupMember }) => ({ personGroupMember })
  const WithPersonGroupMember = connect(mapStateToProps, {
    ...personGroupMemberActions,
  })(PersonGroupMember);
  hoistNonReactStatics(WithPersonGroupMember, WrapperComponent)
  return compose(withBaseHOCProps)(WithPersonGroupMember);
}
