import React from "react";
import compose from "lodash/fp/compose";
import { connect } from "react-redux";
import hoistNonReactStatics from 'hoist-non-react-statics'
import { createAction, } from "../../../actions";

import * as timeDependentNameActions from "../../../actions/dashboard/timeDependentName"
import { 
  withDeleteConfirmation,
} from "../DeleteConfirmation";

let loaded = false;

export const withTimeDependentName = (WrapperComponent) => {
  class TimeDependentName extends React.Component {
    deleteTimeDependentNameById = async(data) => {
      const { 
        deleteConfirmation, 
        deleteTimeDependentNameById,
      } = this.props
      try {
        await deleteConfirmation.init(deleteTimeDependentNameById, data);
      } catch(e) {}
    }
    render() {
      return (
        <WrapperComponent 
          {...this.props} 
          deleteTimeDependentNameById={this.deleteTimeDependentNameById}
        />
      )
    }
  }
  const mapStateToProps = ({ 
    timeDependentName, 
  }) => {
    return ({ 
      timeDependentName,
    })
  }
  const WithTimeDependentName = connect(mapStateToProps, {
    ...timeDependentNameActions,
  })(TimeDependentName);
  hoistNonReactStatics(WithTimeDependentName, WrapperComponent)
  return compose(
    withDeleteConfirmation,
  )(WithTimeDependentName);
}
