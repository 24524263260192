import * as types from "../actions/types";

const initState = [];

export default function dateFormat(state = initState, actions = {}) {
  switch (actions.type) {
    case types.DATE_FORMAT:
      return [...state, ...actions.payload];
    case types.GET_DATE_FORMAT:
      return [...state, ...actions.payload];
    default:
      return state;
  }
}
