import React, { useState } from 'react';
import compose from 'lodash/fp/compose';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router-dom';
import {
  withTranslation,
  withAdminOrgProposal,
  withCustomRouter,
  withUserPreferences,
  withLanguage,
  withDataArea,
  withOrganisation,
  withCountry,
  withLegalForm,
  withState,
  withCity,
  withOrganisationFunction,
  withReligion,
  withPublicRespArea,
  withOrganisationMotivation,
  withCurrency,
  withIndustryType,
  withOrgFunctionAssignment,
  withOrganisationProposal,
} from '../../../hoc';
import {
  Input,
  Form,
  ButtonLink,
  Select,
  TextArea,
  Button,
  Switchery,
  InputDatePicker,
  FileInput,
  Image,
  NewEntriesSelect,
} from '../../../common/FormItems';
import swal from 'sweetalert';

const Wrapper = styled.div``;

const ImageWrapper = styled.div`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
`;

const StyledButtonLink = styled(ButtonLink)`
  span {
    text-transform: uppercase;
  }
`;

const ApproveButton = styled(Button)``;

const RejectButton = styled(Button)`
  background-color: #dc3545;
`;

const initialValues = {
  id: '',
  name: '',
  org_country: '',
  state: '',
  legal_form: '',
  register_institute: '',
  register_no: '',
  organisation_category: '',
  legal_personality: true,
  public: false,
  non_profit: false,
  org_function_assignment_list: [{}],
};

const AdminOrgProposalActionForm = (props) => {
  const {
    routeParams,
    approveOrgProposal,
    rejectOrgProposal,
    basePath,
    getOrgProposal,
    dataArea,
    countryLocaleOptions,
    legalFormLocaleOptions,
    getStateByCountryId,
    stateLocaleOptions,
    getLegalFormByCountryId,
    activeLanguageOptions,
    currencyLocaleOptions,
    addOrganisation,
    fetchingState,
    orgFunctionAssignment: { register_institute_options },
    tr,
  } = props;

  const { id: orgProposalId } = routeParams;
  const history = useHistory();
  const location = useLocation();

  const [formData, setFormData] = React.useState({ ...initialValues });
  const [formSubmitting, setFormSubmitting] = React.useState(false);
  const [formLoading, setFormLoading] = React.useState(false);
  const [error, setError] = useState('');

  const fetchData = async () => {
    if (orgProposalId) {
      try {
        setFormLoading(true);
        const res = await getOrgProposal(orgProposalId);
        setFormData({
          ...res,
        });
      } finally {
        setFormLoading(false);
      }
    }
  };

  const registerInstituteOptions = React.useMemo(() => {
    // uniquelize the organisations
    const map = new Map();
    register_institute_options.forEach((props) => {
      const { name, org_country } = props;
      if (org_country == formData?.org_country) {
        map.set(name, props);
      }
    });
    return Array.from(map.values());
  }, [register_institute_options, formData?.org_country]);

  React.useEffect(() => {
    fetchData();
    return () => null;
  }, []);

  React.useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const onApprove = async () => {
    if (formSubmitting) return;
    try {
      setFormSubmitting(true);
      await approveOrgProposal(formData.id);
      history.push(basePath);
    } catch (e) {
      swal('Error', 'Failed to approve proposal', 'error');
    } finally {
      setFormSubmitting(false);
    }
  };

  const onReject = async () => {
    if (formSubmitting) return;
    try {
      setFormSubmitting(true);
      await rejectOrgProposal(formData.id);
      swal('Success', 'Proposal rejected successfully', 'success');
      history.push(basePath);
    } catch (e) {
      swal('Error', 'Failed to reject proposal', 'error');
    } finally {
      setFormSubmitting(false);
    }
  };

  const renderActionButtons = () => {
    if (!location.pathname.endsWith('/update')) {
      return null;
    }
    return (
      <div>
        <ApproveButton
          onClick={onApprove}
          name={tr('Approve')}
          actionButton
          isLoading={formSubmitting}
          disabled={formSubmitting}
        >
          Approve
        </ApproveButton>
        <RejectButton
          onClick={onReject}
          cancelButton
          name={tr('Reject')}
          isLoading={formSubmitting}
          disabled={formSubmitting}
        >
          Reject
        </RejectButton>
      </div>
    );
  };

  return (
    <Wrapper className="form-wrapper">
      <div className="flex">
        <StyledButtonLink
          to={basePath}
          className="option-btn"
          icon="angle double left"
          name="All Entries"
        />
      </div>
      <div className="row">
        <div className="col-7 ml-10 mt-30 container">
          <div
            className="col-7 ml-10 mt-30 container"
            style={{
              position: 'relative',
              background: 'var(--admincat-color-grey-1)',
            }}
          >
            <br />
            <Form
              onSubmit={(e) => e.preventDefault()}
              formData={formData}
              isLoading={formSubmitting}
              loadingData={formLoading}
              title="Admin Org Proposal"
              renderActionButtons={renderActionButtons}
            >
              <ImageWrapper>
                <Image src={formData?.organisation_logo} />
              </ImageWrapper>

              <FileInput
                value={formData?.organisation_logo || ''}
                hasError="Error"
                name="organisation_logo"
                label={tr('Organisation logo')}
                disabled
                accept="image/*"
              />
              <Input
                value={formData?.name || ''}
                name="name"
                label="Organisation"
                placeholder="Organisation"
                disabled
                showRequiredAsterisk
              />
              <Select
                showRequiredAsterisk
                value={formData?.org_country || ''}
                name="org_country"
                label="Country"
                optionDefaultValue="Choose Country"
                disabled
                localeOptions={countryLocaleOptions}
              />
              <Select
                isLoading={fetchingState}
                value={formData?.state || ''}
                name="state"
                label="State"
                placeholder="State"
                optionDefaultValue="State"
                localeOptions={stateLocaleOptions}
                disabled
                deselectable
              />

              <Select
                isLoading={props.fetchingCity}
                optionDefaultValue="Choose City Location"
                label="City Location"
                value={formData?.city_location || ''}
                name="city_location"
                id="city_location"
                localeOptions={props.cityLocaleOptions}
                deselectable
                disabled
              />
              <Select
                value={formData?.legal_form || ''}
                name="legal_form"
                deselectable
                label={tr('Legal Form')}
                optionDefaultValue={tr('Choose Legal Form')}
                localeOptions={legalFormLocaleOptions}
                disabled
              />
              {formData?.legal_form !== 'KöR' && (
                <Select
                  value={formData?.register_institute}
                  name="register_institute"
                  label="Registration Institution"
                  optionDefaultValue="Registration Institution"
                  options={registerInstituteOptions}
                  disabled
                  deselectable
                />
              )}
              {formData?.legal_form !== 'KöR' && (
                <Input
                  value={formData?.register_no}
                  name="register_no"
                  label="Official Register No"
                  placeholder="Official Register No"
                  disabled
                />
              )}
              <InputDatePicker
                label="Liquidation"
                value={formData?.liquidation}
                name="liquidation"
                disabled
              />
              <InputDatePicker
                label="Foundation"
                value={formData?.foundation}
                name="foundation"
                disabled
              />
              <Switchery
                name="public"
                value={formData?.public}
                label="Public"
                showRightLabel={false}
                disabled
              />
              <Select
                value={formData?.org_motivation}
                name="org_motivation"
                label="Org Motivation"
                optionDefaultValue="Choose Org Motivation"
                localeOptions={props.orgMotivationLocaleOptions}
                deselectable
                sort={false}
                disabled
              />
              <Switchery
                name="legal_personality"
                value={formData?.legal_personality}
                label="Legal Personality"
                showRightLabel={false}
                disabled
              />

              <Select
                value={formData?.parent_org}
                name="parent_org"
                label="Parent Organisation"
                optionDefaultValue="Choose Parent Organisation"
                options={props.orgOptions}
                // deselectable
                required={!formData?.legal_personality}
                disabled
              />
              <Switchery
                name="non_profit"
                value={formData?.non_profit}
                label="Non Profit"
                showRightLabel={false}
                disabled
              />
              <Select
                value={formData?.industry}
                name="industry"
                label="Industry"
                optionDefaultValue="Choose Industry"
                options={props.industryTypeOptions}
                showRequiredAsterisk
                sort={false}
                disabled
              />
              <Input
                value={formData?.no_of_employees}
                name="no_of_employees"
                label="No. of Employees"
                placeholder="No. of Employees"
                fieldType="number"
                disabled
              />
              <Input
                value={formData?.revenue_approx}
                name="revenue_approx"
                label="Revenue approx."
                placeholder="Revenue approx."
                fieldType="number"
                disabled
              />
              <Select
                value={formData?.revenue_currency}
                name="revenue_currency"
                label="Revenue Currency"
                optionDefaultValue="Choose Revenue Currency"
                localeOptions={currencyLocaleOptions}
                deselectable
                disabled
              />
              <Input
                value={formData?.website}
                name="website"
                label="Website"
                placeholder="Website"
                disabled
              />
              <Input
                value={formData?.creator_info?.username || ''}
                name="creator"
                placeholder="Creator"
                label="Creator"
                disabled
              />
            </Form>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default compose(
  withCustomRouter,
  withTranslation,
  withUserPreferences,
  withAdminOrgProposal,
  withOrganisationFunction,
  withOrgFunctionAssignment,
  withDataArea,
  withLanguage,
  withOrganisation,
  withCountry,
  withLegalForm,
  withState,
  withCity,
  withReligion,
  withPublicRespArea,
  withOrganisationMotivation,
  withCurrency,
  withIndustryType,
)(AdminOrgProposalActionForm);
