import React from 'react';
import compose from 'lodash/fp/compose';
import {
  withBulkDelete,
  withEducation,
  withCustomRouter,
  withTranslation,
  withUserPreferences,
} from 'components/hoc';
// import MyDataContentTemplate from '../../common/MyDataContentTemplate';
import MyDataContentTemplate from 'components/Dashboard/MyData/common/MyDataContentTemplate';
import { getViewName, getDegreeAchieaval } from '../utils';
import LottieLoader from 'components/common/LottieLoader';

const options = ({ detail, _delete }) => {
  return [
    {
      onClick: detail,
      name: 'View',
    },
    {
      onClick: _delete,
      name: 'Delete',
    },
  ];
};

const List = (props) => {
  const {
    frontendTableName,
    backendTableName,
    deleteBulkEducation,
    deleteEducationById,
    gotoDetailPath,
    gotoCreatePath,
    education,
    educationPathName,
    trObj,
    tr,
    fd,
    loaded,
  } = props;

  const viewName = tr(getViewName(educationPathName));

  const getData = () => {
    return education.data.map((data) => ({
      ...data,
      space_name: data.space,
      person:
        `${data?.person_detail?.first_name} ${data?.person_detail?.last_name}`.trim(),
      school_type: trObj(data?.school_type_detail),
      language: trObj(data?.language_detail),
      language_literacy: trObj(data?.language_literacy_detail),
      language_verbal: trObj(data?.language_verbal_detail),
      graduation_goal: trObj(data?.graduation_goal_detail),
      profession: trObj(data?.profession_detail),
      school: data?.organisation_detail?.name,
      organisation: data?.organisation_detail?.name,
      category: viewName,
      study_subject: trObj(data?.study_subject_detail),
      school_subject: trObj(data?.school_subject_detail),
      degree_achieval: tr(getDegreeAchieaval(data.degree_achieval)),
      valid_from: fd(data.valid_from),
      valid_to: fd(data.valid_to),
    }));
  };

  if (!loaded) {
    return (
      <>
        <LottieLoader />
      </>
    );
  }

  return (
    <MyDataContentTemplate
      data={getData()}
      toggleForm={gotoCreatePath}
      bulkDelete={deleteBulkEducation}
      frontendTableName={frontendTableName}
      backendTableName={backendTableName}
      options={options({
        detail: ({ id }) => gotoDetailPath(id),
        _delete: deleteEducationById,
      })}
    />
  );
};

export default compose(
  withBulkDelete,
  withEducation,
  withCustomRouter,
  withTranslation,
  withUserPreferences,
)(List);
