import styled from "styled-components"
import Background from "./signup.png"

export const RegisterBody = styled.div`
    background-size:cover;
    width:100%;
    height:auto;
    display:flex;
    `

export const RegisterDivImage = styled.div`
    background:url(${Background});
    background-size:cover;
    background-repeat:no-repeat;
    flex:1;
    display:flex;
    justify-content:center;
    align-items:center;

    @media only screen and (max-width: 768px){
        display:none;
    }
    `
export const RegisterDivChild = styled.div`
    flex:1;
    display:flex;
    justify-content:center;
    align-items:center;
    height:100%;
    padding-top:50px;
    `
