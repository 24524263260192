import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getReferenceAccountData } from 'helpers/bankAccounts';
import { bankAccountActions } from '../actions';

export const useReferenceCheckingAccount = () => {
  const dispatch = useDispatch();
  const { bankAccounts } = useSelector((state) => state.bankAccount);

  useEffect(() => {
    dispatch(bankAccountActions.getAllBankAccount());
  }, [dispatch]);

  return getReferenceAccountData(bankAccounts);
};
