/* eslint-disable camelcase */
/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import compose from 'lodash/fp/compose';
import { useForm } from 'hooks/useForm';
import { createIncomeTransaction } from 'actions';
import { removeEmptyProperties } from 'helpers/removeEmptyProperties';
import { useFetchContracts } from 'hooks/useFetchContracts';
import { useFinancialCategory } from 'hooks/useFinancialCategory';
import { withTranslation, withCurrency, withPersonalInfo } from '../../../../hoc';
import { useAccountCategoryRef } from '../hooks/useAccountCategoryRef';
import { transactionPosting, getTransactionValuesFromPayload } from '../utils';
import { Label } from './style';
import {
  Input,
  Select,
  InputDatePicker,
  Button,
} from '../../../../common/FormItems';
import { Modal, FormArea } from '../../common/style';


const initialValues = {
  description: '',
  amount: '',
  currency: 'EUR',
  dc_indicator: 'C',
  contract: '',
  person: '',
  contract_item: '',
  income_financial_account: '',
  asset_financial_account: 'bank',
  asset_account_category: 'asset',
  income_account_category: 'income',
  position_link: '',
  date: '',
  rec_op_id: '',
};

const validationSchema = {
  description: 'required',
  amount: 'required',
  dc_indicator: 'required',
  currency: 'required',
  income_account_category: 'required',
  asset_financial_account: 'required',
  income_financial_account: 'required',
  date: 'required',
  person: 'required',
  rec_op_id: [{ required_if: ['asset_financial_account', 'payable|receive'] }],
};

const Form = (props) => {
  const { toggleForm, open, formMode, setFormMode, tr, personalInfoOptions } = props;

  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [detailMode, setdetailMode] = useState('view');
  const { item } = useSelector((state) => state.transaction);

  const onSubmit = async (formValues, { resetForm }) => {
    setIsSubmitting(true);
    try {
      const payload = transactionPosting(
        removeEmptyProperties(formValues),
        formMode,
      );
      const err = await dispatch(createIncomeTransaction(payload));
      setIsSubmitting(false);
      if (!err) {
        toggleForm();
        resetForm();
        setdetailMode('view');
      }
    } catch (error) {
      setIsSubmitting(false);
    }
  };

  const {
    handleChange,
    handleBlur,
    touched,
    values,
    errors,
    setValues,
    handleSubmit,
  } = useForm({
    initialValues,
    validationSchema,
    onSubmit,
  });

  useEffect(() => {
    if (item) {
      const formValues = {};
      const payload = getTransactionValuesFromPayload(item);
      Object.keys(initialValues).forEach((key) => {
        formValues[key] = payload[key];
      });
      setValues({ ...formValues, id: item.id });
      return;
    }
    setValues(initialValues);
    setFormMode('update');
  }, [item, setValues, setFormMode, formMode]);

  useEffect(() => {
    if (formMode === 'create') {
      setValues(initialValues);
    }
  }, [formMode, setValues]);

  const disabled = detailMode !== 'edit' && formMode !== 'create';
  const isError = (touchedObj, error) =>
    touchedObj && error?.length > 0 ? '  ' : '';

  const { currencyLocaleOptions } = props;
  const { contractList, contract } = useFetchContracts(values.contract);
  const { financialCategoryOptions } = useFinancialCategory();

  const transactionCategoryOptions = useAccountCategoryRef({
    financialAccountCat: values.asset_financial_account,
    amount: values.amount,
    currency: values.currency,
    rec_op_id: values.rec_op_id,
  });

  // const persons = useSelector((state) => state.personalInfo);

  return (
    <React.Fragment>
      <Modal open={open} className="modal-wrapper">
        <div className="body card form-container">
          <h3 className="mb-4 d-inline">
            <span className="d-flex justify-content-between">
              {tr('Asset Shift')}
            </span>
          </h3>
          <span
            role="presentation"
            onClick={() => {
              toggleForm();
            }}
            className="exit"
          >
            <i className="fa fa-times" />
          </span>
          <FormArea onSubmit={handleSubmit}>
            <div className="row align-items-center">
             
            </div>
            <div className="row align-items-center">
              <Label className="col-md-3">
                <label className="form-label" htmlFor="date">
                  {tr('Transaction date')} <span className="form-required">*</span>
                </label>
              </Label>
              <div className="col-md-4">
                <InputDatePicker
                  id="date"
                  name="date"
                  value={values.date}
                  onChange={handleChange}
                  disabled={disabled}
                  error={isError(touched?.date, errors?.date?.[0])}
                />
              </div>
            </div>
            <div className="row align-items-center">
              <Label className="col-md-3">
                <label className="form-label" htmlFor="amount">
                  {tr('Amount')}
                  <span className="form-required">*</span>
                </label>
              </Label>
              <div className="col-md-4">
                <Input
                  showRequiredAsterisk
                  name="amount"
                  id="amount"
                  value={values.amount}
                  placeholder="1000"
                  onChange={handleChange}
                  required
                  disabled={disabled}
                />
              </div>
              <div className="col-md-5">
                <Select
                  showRequiredAsterisk
                  name="currency"
                  optionDefaultValue={tr('Currency')}
                  value={values.currency}
                  onChange={handleChange}
                  localeOptions={currencyLocaleOptions}
                  onBlur={handleBlur}
                  required
                  error={isError(touched?.currency, errors?.currency?.[0])}
                  disabled={disabled}
                />
              </div>
            </div>
            <div className="row align-items-center">
              <Label className="col-md-3">
                <label className="form-label" htmlFor="description">
                  {tr('Description')}
                  <span className="form-required">*</span>
                </label>
              </Label>
              <div className="col-md-9">
                <Input
                  showRequiredAsterisk
                  name="description"
                  id="description"
                  value={values.description}
                  placeholder="Description"
                  onChange={handleChange}
                  required
                  disabled={disabled}
                />
              </div>
            </div>
            <div className="row align-items-center">
              <Label className="col-md-3">
                <label className="form-label" htmlFor="person">
                  <span>{tr('Person')}</span>
                  <span className="form-required">*</span>
                </label>
              </Label>
              <div className="col-md-4">
                <Select
                  showRequiredAsterisk
                  optionDefaultValue="Choose Person"
                  value={values.person}
                  className="w-80"
                  id="person"
                  name="person"
                  options={personalInfoOptions}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={isError(touched?.person, errors?.person?.[0])}
                  disabled={disabled}
                />
              </div>
            </div>
            <div className="row align-items-center">
              <Label className="col-md-3">
                <label className="form-label" htmlFor="person">
                  <span>{tr('Transaction Partner')}</span>
                  <span className="form-required">*</span>
                </label>
              </Label>
              <div className="col-md-4">
                <Select
                  showRequiredAsterisk
                  optionDefaultValue="Choose Transaction Partner"
                  value={values.transaction_partner}
                  className="w-80"
                  id="transaction_partner"
                  name="transaction_partner"
                  localeOptions={[]}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={isError(
                    touched?.transaction_partner,
                    errors?.transaction_partner?.[0],
                  )}
                  disabled={disabled}
                />
              </div>
            </div>
            <div className="row align-items-center mt-4 mb-4">
              <Label
                className="col-md-12 form-label text-decoration-underline"
                textUnderLine
              >
                {tr('Accounting')}
              </Label>
            </div>
            <div className="row align-items-center">
              <Label className="col-md-3">
                <label className="form-label" htmlFor="asset_financial_account">
                  {tr('Account / Position-ID')}{' '}
                  <span className="form-required">*</span>
                </label>
              </Label>
              <div className="col-md-4">
                <Select
                  showRequiredAsterisk
                  id="asset_financial_account"
                  name="asset_financial_account"
                  value={values.asset_financial_account}
                  onChange={handleChange}
                  localeOptions={financialCategoryOptions.filter(
                    ({ acc_cat_group }) => acc_cat_group === 'asset',
                  )}
                  onBlur={handleBlur}
                  required
                  placeholder="Category Account"
                  error={isError(
                    touched?.asset_financial_account,
                    errors?.asset_financial_account?.[0],
                  )}
                  disabled={disabled}
                />
              </div>
              <div className="col-md-5">
                <Select
                  showRequiredAsterisk
                  name="position_link"
                  optionDefaultValue={tr('Position')}
                  value={values.position_link}
                  onChange={handleChange}
                  options={transactionCategoryOptions || []}
                  onBlur={handleBlur}
                  required
                  error={isError(touched?.line, errors?.line?.[0])}
                  disabled={disabled}
                />
              </div>
            </div>
            <div className="row align-items-center">
              <Label className="col-md-3">
                <label className="form-label" htmlFor="account_category">
                  {tr('Amount/Quantity Account(Received)')}
                  <span className="form-required">*</span>
                </label>
              </Label>
              <div className="col-md-3">
                <Input
                  showRequiredAsterisk
                  name="description"
                  id="description"
                  value={values.description}
                  placeholder="Amount"
                  onChange={handleChange}
                  required
                  disabled={disabled}
                />
              </div>
              <div className="col-md-3">
                <Select
                  showRequiredAsterisk
                  name="currency"
                  id="currency"
                  optionDefaultValue={tr('EUR')}
                  value={values.currency}
                  onChange={handleChange}
                  localeOptions={[]}
                  onBlur={handleBlur}
                  required
                />
              </div>
              <div className="col-md-3">
                <Select
                  showRequiredAsterisk
                  name="income_financial_account"
                  optionDefaultValue={tr('Income Category')}
                  value={values.income_financial_account}
                  onChange={handleChange}
                  localeOptions={financialCategoryOptions.filter(
                    ({ acc_cat_group }) => acc_cat_group === 'income',
                  )}
                  onBlur={handleBlur}
                  required
                  error={isError(
                    touched?.income_financial_account,
                    errors?.income_financial_account?.[0],
                  )}
                  disabled={disabled}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 d-flex justify-content-center">
                <Button
                  name={formMode === 'update' ? 'Reverse' : 'Post'}
                  className="btn btn-primary"
                  type="submit"
                  disabled={isSubmitting}
                  isLoading={isSubmitting}
                />
              </div>
            </div>
          </FormArea>
        </div>
      </Modal>
    </React.Fragment>
  );
};

Form.propTypes = {
  open: PropTypes.bool.isRequired,
  formMode: PropTypes.bool.isRequired,
  toggleForm: PropTypes.func.isRequired,
  tr: PropTypes.func.isRequired,
};

export default compose(withTranslation, withCurrency, withPersonalInfo)(Form);
