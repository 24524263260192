import React from 'react';
import styled from 'styled-components';
import { withTranslation } from '../../../hoc';

const StyledLabel = styled.label`
  font-size: 12px;
`;
const StyledText = styled.label`
  margin: 0;
  color: var(--admincat-color-grey-7);
  // color: rgba(0, 0, 0, 0.29);
`;

const SquareCheckbox = withTranslation((props) => {
  const { tr, label, name, value, onChange, className, required, disabled } = props;
  return (
    <div className={`form-group ${className}`}>
      <StyledLabel className={`custom-control custom-checkbox ${className}`}>
        <input
          type="checkbox"
          className="custom-control-input"
          onChange={() => {
            !disabled && onChange({ target: { name, value: !value } });
          }}
          name={name}
          id={name}
          checked={value}
          disabled={disabled}
        />
        <div className="custom-control-label"></div>
        {label && (
          <StyledText className="SquareCheckboxLabel" htmlFor={name}>
            {tr(label)}
            {required && <span className="form-required">*</span>}
          </StyledText>
        )}
      </StyledLabel>
    </div>
  );
});

SquareCheckbox.defaultProps = {
  className: '',
  name: '',
  value: false,
  required: false,
  disabled: false,
};

export default SquareCheckbox;
