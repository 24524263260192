import axios from '../axios';
import * as types from './types';
import { setMsgInfo } from './';
import API from './api';
import createAction from './createAction';

const baseUrl = API + '/space/member';

export const approveSpaceMember = (payload) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.put(`${baseUrl}/approve/`, { ids: payload });
      dispatch(createAction(types.UPDATE_SPACE_MEMBERS, data));
      dispatch(
        setMsgInfo({
          success: true,
          msg: ['Member successfully approved'],
        }),
      );
      return data;
    } catch (err) {
      dispatch(
        setMsgInfo({
          success: false,
          msg: ['Member could not be approved'],
          navigationCounter: 2,
        }),
      );
      throw err;
    }
  };
};

export const disapproveSpaceMember = (payload) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.put(`${baseUrl}/disapprove/`, { ids: payload });

      dispatch(createAction(types.UPDATE_SPACE_MEMBERS, data));
      dispatch(
        setMsgInfo({
          success: true,
          msg: ['Member successfully disapproved'],
        }),
      );
      return data;
    } catch (err) {
      dispatch(
        setMsgInfo({
          success: false,
          msg: ['Member could not be disapproved'],
        }),
      );
      throw err;
    }
  };
};

export const getSpaceMembers = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(`${baseUrl}/`);

      dispatch(createAction(types.SPACE_MEMBERS, data));
      return data;
    } catch (err) {
      throw err;
    }
  };
};
