import React from "react";
import { Link } from "react-router-dom";
import "./index.css";


const ModalInfo = (props) => {
  const {
    verified, title, regSuccess, 
    successInfo, errorInfo, verifyEmail, 
    showBtn } = props;

  return (
    <div className="wrapper">
      <div className="modal-content text-center">
        <div className="modal-header d-flex
          justify-content-center">
          <p className="heading">{title}</p>
        </div>
        <div className="modal-body">
          {
            verified && verifyEmail &&
                <i className="fa fa-bell success 
                fa-4x animated rotateIn mb-4"
                ></i>
          }
          {
            !verified && verifyEmail &&
              <i className="fa fa-bell error 
                fa-4x animated rotateIn mb-4"
              ></i>
          }
          {
            verified && regSuccess &&
                <i className="fa fa-envelope success 
                fa-4x animated rotateIn mb-4"
                ></i>
          }
          {
            !verified && regSuccess &&
              <i className="fa fa-envelope error 
                fa-4x animated rotateIn mb-4"
              ></i>
          }
          <p>
            {
              verified ? successInfo : errorInfo   
            }
          </p>
          {/* <div className="button">
            {
              verified && showBtn && 
                <Link className="route-btn" to="/login">
                  Login &nbsp;
                  <i className="fa fa-sign-in"></i>
                </Link>
            }
            {
              !verified && showBtn && 
                <Link className="route-btn" to="/register">
                  Sign up &nbsp;
                  <i className="fa fa-unlock"></i>
                </Link>
            }
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default ModalInfo;
