import React from "react";
import { connect } from "react-redux";
import {
  getDocumentType,
} from "../../../actions";
import hoistNonReactStatics from 'hoist-non-react-statics'


let loaded = false;

export const withDocumentType = (WrapperComponent, documentGroup) => {
  class WithDocumentType extends React.Component {
    componentDidMount() {
      if (loaded) return;
      loaded = true;
      this.getDocumentType();
    }
    getDocumentType(documentGroup){
      const { getDocumentType } = this.props;
      const group = documentGroup ? { group: documentGroup } : null;
      getDocumentType(group);
    }
    getDocumentTypeOptions = () => undefined
    getDocumentTypeLocaleOptions = () => {
      const { documentType } = this.props;
      return documentType.map(({ created_at, ...rest }) => ({ ...rest }));
    }
    render() {
      return (
        <WrapperComponent 
          {...this.props} 
          documentTypeOptions={this.getDocumentTypeOptions()} 
          documentTypeLocaleOptions={this.getDocumentTypeLocaleOptions()} 
          getDocumentType={this.getDocumentType}
        />
      )
    }
  }
  const mapStateToProps = ({ documentType }) => ({ documentType })
  const WithDocumentTypeHOC = connect(mapStateToProps, {
    getDocumentType,
  })(WithDocumentType);
  hoistNonReactStatics(WithDocumentTypeHOC, WrapperComponent)
  return WithDocumentTypeHOC
}
