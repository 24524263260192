import axios from '../axios';
import { setMsgInfo } from "./msgInfo";
import API from "./api";
import filterError from "./common/filterError";

const baseUrl = API + "/bank-bic-view";


export const addBulkBankBic = (payload) => {
  return (async (dispatch) => { 
    try {
      const { data } = await axios.post(`${baseUrl}/bulk_create/`, payload);
      return data
    }
    catch(err) {
      const errors = filterError(err);
      dispatch(setMsgInfo({
        success: false, 
        msg: errors,
      }));
    }
  });
}

