import React from "react";
import compose from "lodash/fp/compose";
import { 
  withTranslation,
  withAddressInfo,
  withCustomRouter,
} from "../../../../hoc";
import MyDataContentTemplate from '../../common/MyDataContentTemplate'
import LottieLoader from 'components/common/LottieLoader';


// const options = ({ detail, _delete }) => {
//   return [
//     {
//       onClick: detail,
//       name:  "View",
//     },
//     {
//       onClick: _delete,
//       name: "Delete",
//     },
//   ];
// }

const Address = (props) => {
  const mapFields = () => {
    const { addressInfo, trObj } = props;
    return addressInfo.data.map((data) => {
      return { 
        ...data, 
        space_name: data.space,
        _country: trObj(data.country_detail),
        _state: trObj(data.state_detail),
        _city: trObj(data.city_detail),
        // _street_direction: trObj(data.street_direction_detail),
        // _address_type: trObj(data.address_type_detail),
        // _address_type_identifier: data.address_type_identifier,
      }
    })
  }

  const { 
    deleteAddressInfoById, 
    deleteBulkAddressInfo,
    gotoCreatePath,
    gotoDetailPath,
    loaded,
  } = props;

  if (!loaded) {
    return (
      <>
          <LottieLoader />
      </> )
  }

  return (
    <React.Fragment>
      <MyDataContentTemplate 
        tableName="address"
        columns={[]}
        data={mapFields()}
        toggleForm={gotoCreatePath}
        bulkDelete={deleteBulkAddressInfo}
        // options={
        //   options({
        //     detail: (data) => gotoDetailPath(data.id),
        //     _delete: (data) => deleteAddressInfoById(data),
        //   })
        // }
      />
    </React.Fragment>
  )
}

export default compose(
  withTranslation,
  withAddressInfo,
  withCustomRouter,
)(Address);

