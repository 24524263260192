import React from 'react';
import SVG from 'react-inlinesvg';

import { icons } from 'libs/icon';

const Icon = ({ icon, width, height }) => {
  return <SVG src={icons[icon]} width={width} height={height} />;
};

export default Icon;
