import React from "react";
import { connect } from "react-redux";
import {
  getHeightUnit,
} from "../../../actions";
import hoistNonReactStatics from 'hoist-non-react-statics'


let loaded = false;

export const withHeightUnit = (WrapperComponent) => {
  class WithHeightUnit extends React.Component {
    componentDidMount() {
      const { getHeightUnit } = this.props;
      if (loaded) return;
      loaded = true;
      getHeightUnit();
    }
    getHeightUnitOptions = () => undefined
    
    getHeightUnitLocaleOptions = () => {
      const { heightUnit } = this.props;
      return heightUnit.map(({ created_at, ...rest }) => ({ ...rest }));
    }
    render() {
      return (
        <WrapperComponent 
          heightUnitOptions={this.getHeightUnitOptions()} 
          heightUnitLocaleOptions={this.getHeightUnitLocaleOptions()} 
          {...this.props} 
        />
      )
    }
  }
  const mapStateToProps = ({ heightUnit }) => ({ heightUnit })
  const WithHeightUnitHOC = connect(mapStateToProps, {
    getHeightUnit,
  })(WithHeightUnit);
  hoistNonReactStatics(WithHeightUnitHOC, WrapperComponent)
  return WithHeightUnitHOC
}
