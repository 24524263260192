import ComposeMail from './ComposeMail';

const path = '/admin/dashboard/mail';

export default [
  {
    name: 'Compose',
    path: `${path}/compose`,
    icon: 'envelope',
    component: ComposeMail,
  },
];

export const redirects = [
  {
    from: path,
    to: `${path}/compose`,
  },
  {
    from: `${path}/*`,
    to: `${path}/compose`,
  },
];
