import React from 'react';

class BaseMultiSelect extends React.Component { 
  constructor(props) {
    super(props);
    this.state = { 
      selected: props.value, 
    }
  }
  componentDidUpdate() {
    let { value, selectableItems } = this.props;
    if (!value && selectableItems.length) {
      value = selectableItems[0].value;
    }
    const { selected } = this.state;
    if (selected !== value) {
      this.setState({ selected: value });
    }
  }
  onChange = async (value) => {
    const { selected } = this.state;
    const { onChange, name } = this.props;
    if (selected !== value) {
      onChange({ target: { name, value } });
    }
  }
  render() { return null }
}

BaseMultiSelect.defaultProps = {
  label: "",
  className: "",
  selectableItems: [{ label: "", value: "" }],
  required: false,
  onChange: () => {},
}

export default BaseMultiSelect;

