import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import thunk from 'redux-thunk';
import { createStore, applyMiddleware, compose } from 'redux';
import axios from './axios';
import App from './App';
import rootReducer from './reducers/index';
import setAuthToken from './authToken';
import { removeAuthUser, setAuthUser } from './actions';
import registerServiceWorker from './registerServiceWorker';
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import './index.css';
// import i18n from './config/i18n';

let composeEnhancers;

if (process.env.NODE_ENV === 'development') {
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
} else {
  composeEnhancers = compose;
}


export const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);


axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.data) {
      const data = JSON.parse(JSON.stringify(error.response.data));
      if (data && data.detail === 'Invalid token.') {
        removeAuthUser();
      }
    }
    throw error;
  }
);

if (localStorage.token && localStorage.user) {
  setAuthToken(localStorage.token);
  setAuthUser(JSON.stringify(localStorage.getItem('user')));
} else {
  removeAuthUser();
}


ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <App store={store} />
  </I18nextProvider>
  , document.getElementById('root'));
registerServiceWorker();

export default axios;
