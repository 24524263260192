import React from "react";
import { connect } from "react-redux";
import { getBSPosition, } from "actions";
import hoistNonReactStatics from 'hoist-non-react-statics'

// let loaded = false;

const initialState = {
  loaded: false,
}

export const withBSPosition = (WrapperComponent) => {
  class WithBSPosition extends React.Component {

    state = { ...initialState }

    async componentDidMount() {
      const { getBSPosition } = this.props;
      if (this.state.loaded) return;
      await getBSPosition();
      // loaded = true;
      this.setState({...initialState, loaded: true });
    }
    render() {
      return (
        <WrapperComponent 
        {...this.props} 
        loaded={this.state.loaded}
        />
      )
    }
  }
  const mapStateToProps = ({ bsPosition }) => ({ bsPosition });
  const WithBSPositionHOC = connect(mapStateToProps, {
    getBSPosition,
  })(WithBSPosition);
  hoistNonReactStatics(WithBSPositionHOC, WrapperComponent)
  return WithBSPositionHOC
}
