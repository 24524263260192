import Home from './Home';

const path = '/admin/dashboard';
export const redirects = [
  {
    from: path,
    to: `${path}`,
  },
  {
    from: `${path}/*`,
    to: `${path}`,
  },
];

export default [
  {
    path: `${path}`,
    icon: '',
    component: Home,
    exact: true,
  },
];
