import React from "react";
import { connect } from "react-redux";
import {
  getEthnicGroup,
} from "../../../actions";
import hoistNonReactStatics from 'hoist-non-react-statics'


let loaded = false;

export const withEthnicGroup = (WrapperComponent) => {
  class WithEthnicGroup extends React.Component {
    componentDidMount() {
      const { getEthnicGroup } = this.props;
      if (loaded) return;
      loaded = true;
      getEthnicGroup();
    }
    getEthnicGroupOptions = () => undefined
    getEthnicGroupLocaleOptions = () => {
      const { ethnicGroup } = this.props;
      return ethnicGroup.map(({ created_at, ...rest }) => ({ ...rest }));
    }
    render() {
      return (
        <WrapperComponent 
          ethnicGroupOptions={this.getEthnicGroupOptions()} 
          ethnicGroupLocaleOptions={this.getEthnicGroupLocaleOptions()} 
          {...this.props} 
        />
      )
    }
  }
  const mapStateToProps = ({ ethnicGroup }) => ({ ethnicGroup })
  const WithEthnicGroupHOC = connect(mapStateToProps, {
    getEthnicGroup,
  })(WithEthnicGroup);
  hoistNonReactStatics(WithEthnicGroupHOC, WrapperComponent)
  return WithEthnicGroupHOC
}
