import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { getUserPreferences, updateUserPreferences } from '../../../actions';
import hoistNonReactStatics from 'hoist-non-react-statics';

const numberNotations = {
  1: ['.', ','],
  2: [',', '.'],
  3: [' ', ','],
  4: [' ', '.'],
};

//

export const withUserPreferences = (WrapperComponent) => {
  class Enhance extends React.Component {
    state = {
      language: '',
      dateFormat: '',
      numberFormat: '',
      selectedLocale: '',
      otp_browser_dependent: false,
    };

    componentDidMount() {
      const pref = JSON.parse(localStorage.getItem('userPreferences'));

      if (pref) {
        this.setState({
          ...pref,
          selectedLocale: pref.language,
          // updatedUsername: usernamePref?.usename,
        });
      }
    }

    handlePreferenceChange = ({ target: { name, value } }) => {
      this.setState((state) => ({ ...state, [name]: value }));
    };

    toggleOTPBrowserPreference = ({ target: { name, value } }) => {
      this.setState(
        (state) => ({ ...state, [name]: value }),
        () => {
          // Callback function (updateUserPreferences) should be invoked here
          this.updateUserPreferences();
        },
      );
    };

    componentDidUpdate({ userPreferences }) {
      const {
        language,
        dateFormat,
        numberFormat,
        id,
        dateFormatId,
        user,
        otp_browser_dependent,
      } = this.props.userPreferences;
      if (
        language != userPreferences.language ||
        dateFormat !== userPreferences.dateFormat ||
        numberFormat !== userPreferences.numberFormat
      ) {
        this.setState({
          language,
          id,
          dateFormat,
          numberFormat,
          dateFormatId,
          otp_browser_dependent,
          user,
        });
      }
    }

    updateUserPreferences = async () => {
      this.setState({ isSubmitting: true });
      await this.props.updateUserPreferences({
        ...this.state,
        language: this.state.selectedLocale,
      });

      // this is to address the mismatch between language codes
      // on the db and axios
      // on the db the language codes are 'eng' and 'deu'
      // on the axios the accepted language code are 'en' and 'de'
      let lang = '';
      if (this.state.selectedLocale == 'eng') {
        lang = 'en';
      } else if (this.state.selectedLocale == 'deu') {
        lang = 'de';
      }
      localStorage.setItem('i18nextLng', lang);

      // await refreshPage()
      this.setState({ isSubmitting: false });
    };

    updateUsernameChange = async () => {
      this.setState({ isSubmitting: true });

      await this.props.updateUsername({
        username: this.state.updatedUsername,
      });
      this.setState({ isSubmitting: false });
      // window.location.reload(false);
    };

    renderDateFormat = (date) => {
      const { dateFormat } = this.state;
      const _date = moment(date).format(dateFormat);
      const isValidDate = _date !== 'Invalid date';
      return isValidDate ? _date : '';
    };

    // if isDynamic is true, digits param is expect to change often
    // converting decimal number to format (represented by the notation)
    renderNumberFormat = (digits, isDynamic = false) => {
      const { numberFormat } = this.state;
      let strDigit = String(digits);

      if (!strDigit || !digits) return digits;

      let isSigned = false;
      if (strDigit.startsWith('-')) {
        strDigit = strDigit.replace('-', '');
        isSigned = true;
      }

      let [number, decimals = ''] = strDigit.split('.');
      number = number.split('').reverse().join('');

      let accum = [];
      let step = 3;
      let pos = 0;

      while (true) {
        const num = number.substring(pos, step + pos);
        accum.push(num);
        if (num.length < step) break;
        pos += step;
      }

      const [digitN, decimatN] = numberNotations[numberFormat] || numberNotations[2];

      accum = accum
        .map((str) => str.split('').reverse().join(''))
        .reverse()
        .filter((str) => str.length > 0)
        .join(digitN);

      if (decimals || (isDynamic && strDigit.endsWith('.'))) {
        accum = [accum, decimals].join(decimatN);
      }

      return isSigned ? `-${accum}` : accum;
    };

    // if isDynamic is true, formatedDigits param is expect to change often
    // reversing number format (represented by the notation) to decimal
    xRenderNumberFormat = (formatedDigits, isDynamic = false) => {
      const { numberFormat } = this.state;
      const strDigit = String(formatedDigits);
      if (!strDigit || !formatedDigits) return formatedDigits;
      const [digitN, decimalN] = numberNotations[numberFormat] || numberNotations[2];
      let [number, decimals] = strDigit.split(decimalN);
      // const regex = new RegExp(`\\${digitN}`, 'g')
      // removing comma, dot and whitespace
      const regex = new RegExp(`(\\,|\\.|\\s)`, 'g');
      number = number.replace(regex, '');

      isDynamic =
        isDynamic &&
        number.length > 0 &&
        (strDigit.endsWith('.') || strDigit.endsWith(','));
      if (decimals || isDynamic) {
        return [number, decimals].join('.');
      }
      return number;
    };

    checkNumeric = (currencyString) => {
      /** simple logic to determine if a currency string or date string is numeric
       *  1,200.30 should be considered as a numeric irrespective of the comma
       *
       */
      let numString = currencyString || '';
      if (typeof numString === 'string') {
        var num = numString.replace(/,/g, '');
        if (!isNaN(num) && numString.split('.').length <= 2) {
          return true;
        }
        return false;
      } else if (typeof numString == 'number') {
        return true;
      } else {
        return false;
      }
    };

    render() {
      const {
        numberFormat,
        language,
        selectedLocale,
        updatedUsername,
        dateFormatId,
        dateFormat,
        isSubmitting,
        otp_browser_dependent,
      } = this.state;

      return (
        <WrapperComponent
          {...this.props}
          fd={this.renderDateFormat}
          fn={this.renderNumberFormat}
          xfn={this.xRenderNumberFormat}
          checkNumeric={this.checkNumeric}
          userPreLang={language}
          selectedLocale={selectedLocale}
          updatedUsername={updatedUsername}
          userPreNumberFormat={numberFormat}
          isSubmitting={isSubmitting}
          dateFormat={dateFormat}
          otp_browser_dependent={otp_browser_dependent}
          userPrefDateFormat={dateFormatId}
          handlePreferenceChange={this.handlePreferenceChange}
          handleUsernameChange={this.handleUsernameChange}
          updateUserPreferences={this.updateUserPreferences}
          updateUsernameChange={this.updateUsernameChange}
          toggleOTPBrowserPreference={this.toggleOTPBrowserPreference}
        />
      );
    }
  }
  const mapStateToProps = ({ userPreferences }) => ({ userPreferences });
  const WithUserPreferences = connect(mapStateToProps, {
    getUserPreferences,
    updateUserPreferences,
    // updateUsername,
  })(Enhance);
  hoistNonReactStatics(WithUserPreferences, WrapperComponent);
  return WithUserPreferences;
};
