import * as types from '../../actions/types';
import { createTypes } from '../../actions';
import baseStore from './../utils/baseStore';

export const initialState = {
  data: [],

};

export default function vaccineHistoryDisease(state = initialState, action = {}) {
  switch (action.type) {
    case createTypes(types.ADD_VACCINE_HISTORY_DISEASE).success:
      return {
        ...state,
        data: [action.payload, ...state.data],
        isLoading: false,
      };

    case createTypes(types.GET_VACCINE_HISTORY_DISEASE).success:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };

    case createTypes(types.UPDATE_VACCINE_HISTORY_DISEASE).success:
      return {
        ...state,
        data: state.data.map((_data) => {
          if (_data.id === action.payload.id) return action.payload;
          return _data;
        }),
        isLoading: false,
      };

      case createTypes(types.DELETE_VACCINE_HISTORY_DISEASE).success: 
      return { 
        ...state, 
        data: state.data.filter((_data) => { 
          return _data.id !== action.payload.id;
        }), 
    };

    case createTypes(types.ADD_VACCINE_HISTORY_DISEASE).loading:

    case createTypes(types.UPDATE_VACCINE_HISTORY_DISEASE).loading:

    case createTypes(types.GET_VACCINE_HISTORY_DISEASE).loading:
      return {
        ...state,
        isLoading: true,
        error: null,
      };

    case createTypes(types.ADD_VACCINE_HISTORY_DISEASE).failure:

    case createTypes(types.UPDATE_VACCINE_HISTORY_DISEASE).failure:

    case createTypes(types.GET_VACCINE_HISTORY_DISEASE).failure:
      return {
        ...state,
        error: action.payload,
      };

    case createTypes(types.DELETE_VACCINE_HISTORY_DISEASE).failure: 
      return { 
        ...state, 
        error: action.payload 
      };

    default:
      return state;
  }
}