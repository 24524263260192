import React from "react";
import { connect } from "react-redux";
import {
  getPublicRespArea,
} from "../../../actions";
import hoistNonReactStatics from 'hoist-non-react-statics'


let loaded = false;

export const withPublicRespArea = (WrapperComponent) => {
  class WithPublicRespArea extends React.Component {
    componentDidMount() {
      const { getPublicRespArea } = this.props;
      if (loaded) return;
      loaded = true;
      getPublicRespArea();
    }

    getPublicRespAreaOptions = () => undefined

    getPublicRespAreaLocaleOptions = () => {
      const { publicRespArea } = this.props;
      return publicRespArea.map(({ created_at, ...rest }) => ({ ...rest }));
    }
    
    render() {
      const { getPublicRespArea, publicRespArea, ...rest } = this;
      return (
        <WrapperComponent 
          {...this.props}
          publicRespAreaOptions={this.getPublicRespAreaOptions()}
          publicRespAreaLocaleOptions={this.getPublicRespAreaLocaleOptions()}
        />
      )
    }
  }
  const mapStateToProps = ({ publicRespArea }) => ({ publicRespArea })
  const WithPublicRespAreaHOC = connect(
    mapStateToProps, 
    { getPublicRespArea }
  )(WithPublicRespArea);
  hoistNonReactStatics(WithPublicRespAreaHOC, WrapperComponent)
  return WithPublicRespAreaHOC
}
