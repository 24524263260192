import React, { Component} from 'react';
import { withTranslation } from 'react-i18next';
import ModalInfo from "../ModalInfo";

class SignupSuccess extends Component{
  constructor(props){
    super(props);

    this.state = {
      title: "Registration Status",
      verified: true,
      // successInfo: "Registration successful, check your email for activation link",
      successInfo: "Registration successful. Activation link has been sent to your email",
      errorInfo: "",
      showBtn: false,
      regSuccess: true,
      verifyEmail: false,
    }
  }

  render() {
    const { 
      title, 
      verified, 
      successInfo, 
      errorInfo, 
      regSuccess,
      verifyEmail,
      // showBtn,
    } = this.state;
    const { t } = this.props;

    return (
      <ModalInfo 
        title={t(title)}
        verified={verified}
        successInfo={t(successInfo)}
        errorInfo={errorInfo}
        // showBtn={showBtn}
        verifyEmail={verifyEmail}
        regSuccess={regSuccess}
      />
    )
  }
}
export default withTranslation()(SignupSuccess);

// export default SignupSuccess;
