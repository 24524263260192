import React from 'react';
import compose from 'lodash/fp/compose';
import styled from 'styled-components';
import ReactDatePicker from 'react-datepicker';
import {
  withUserPreferences,
  withFieldError,
  withTranslation,
} from 'components/hoc';
import { inputStyle } from '../Input';
import 'react-datepicker/dist/react-datepicker.min.css';
import { reformatDate } from 'components/utils';
import check from 'check-types';
import './index.css';

const DatepickerWrapper = styled.div`
  width: 100%;
  .react-datepicker-wrapper {
    display: block;
    width: 100%;

    .react-datepicker__input-container {
      width: 100%;

      input {
        ${inputStyle}
        border-radius: 5px;
        padding: 1.5px;
        padding-left: 10px;
        height: 38px;
        box-sizing: border-box; /* Add this property to include padding and border in the width */
        width: 100%; /* Set the width to 100% to fill the containing div */
      }

      &&& {
        ${inputStyle}
        border: ${({ error }) =>
          error ? '1px solid red !important' : '1px solid #e0e5ec'};
      }
    }
  }
`;

// const DatepickerWrapper = styled.div`
//   width: 100%;
//   .react-datepicker-wrapper {
//     display: block;
//     width: 100%;

//     .react-datepicker__input-container {
//       width: 100%;

//       input {
//         ${inputStyle}
//         height: 37px;
//         box-sizing: border-box;
//         border: ${({ error }) =>
//           error ? '1px solid red !important' : '1px solid #e0e5ec'};
//         width: 100%;
//       }
//     }
//   }
// `;

// const StyledSelect = styled.select`
//   &&& {
//     ${inputStyle}
//   }

//   border: ${({ error }) => (error ? '1px solid red !important' : 'inherit')};
// `;
// const StyledInput = styled.input`
//   &&& {
//     ${inputStyle}
//     border: ${({ error }) =>
//       error ? '1px solid red !important' : '1px solid #e0e5ec'};
//     height: 38px;
//     box-sizing: border-box; /* Add this property to include padding and border in the width */
//     width: 100%; /* Set the width to 100% to fill the containing div */
//   }
// `;

class InputDatePicker extends React.Component {
  state = {
    date: null,
  };

  componentDidUpdate() {
    const {
      value,
      dateFormat: { data: dateFormat },
    } = this.props;
    const { date } = this.state;
    const newDate = this.getDate(value);
    if (reformatDate(newDate) !== reformatDate(date)) {
      this.setState({ date: newDate });
    }
  }

  onChange = (date) => {
    const { name, onChange } = this.props;
    onChange({
      target: {
        name,
        value: reformatDate(date),
      },
    });
  };

  getDate = (date) => {
    const dateValue = new Date(date);
    return check.date(dateValue) ? dateValue : null;
  };

  getDateFormat = (dateFormat, showMothYear) => {
    let format = dateFormat || 'dd/MM/yyyy';
    format = format.replace('YYYY', 'yyyy').replace('DD', 'dd');
    if (showMothYear) {
      format = format.replace(/dd./gi, '').replace('yyyy', 'yy');
    }
    return format;
  };

  render() {
    const { state, props } = this;
    // console.log('props', props);
    const { dateFormat, showMothYear, tr, error, inputStyle, ...rest } = props;
    return (
      <div className={`form-group user-title ${rest.className}`}>
        <label className="form-label" htmlFor={rest.name}>
          {tr(rest.label || '')}
          {/* {(rest.required || props?.showRequiredAsterisk) && ( */}
          {(rest.required || props?.showRequiredAsterisk) && (
            <span className="form-required">*</span>
          )}
        </label>
        <DatepickerWrapper error={error}>
          <ReactDatePicker
            placeholderText={this.getDateFormat(dateFormat, showMothYear)}
            disabled={this.props.disabled}
            selected={state.date}
            onChange={this.onChange}
            dateFormat={this.getDateFormat(dateFormat, showMothYear)}
            showYearDropdown
            scrollableMonthYearDropdown
            required={rest.required}
            showRequiredAsterisk={props.showRequiredAsterisk}
            style={{ ...inputStyle, ...inputStyle }}
            showMonthYearPicker={!!showMothYear}
            popperPlacement="top-start"
            popperProps={{
              positionFixed: true,
            }}
          />
        </DatepickerWrapper>
        <small id="passwordHelp" className="text-danger">
          {tr(error)}
        </small>
      </div>
    );
  }
}

export default compose(
  withFieldError,
  withUserPreferences,
  withTranslation,
)(InputDatePicker);
