import React from "react";
import { connect } from "react-redux";
import * as msgInfoAction from "../../../actions/msgInfo";
import hoistNonReactStatics from 'hoist-non-react-statics'

export const withMsgInfo = (WrapperComponent) => {
  const Enhance = (props) => {
    return (
      <WrapperComponent 
        {...props}
      />
    )
  }
  hoistNonReactStatics(Enhance, WrapperComponent)
  const mapStateToProps = ({ msgInfo }) =>  ({ msgInfo })
  return connect(mapStateToProps, { ...msgInfoAction })(Enhance)
}
