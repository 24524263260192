/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import compose from 'lodash/fp/compose';
// import { deleteOrgFunctionAssignment } from 'actions';
// import { deleteBulkContract } from 'actions';
import {
  withTranslation,
  withBulkDelete,
  withDeleteConfirmation,
  withUserLayoutChoice,
  withCustomRouter,
  withCurrency,
  withMeasurementUnit,
  withOrganisation,
  withOrganisationFunction,
  withOrgFunctionAssignment,
  withReligion,
  withSchoolType,
} from 'components/hoc';
// import MyDataContentTemplate from '../../../common/MyDataContentTemplate';
import MyDataContentTemplate from 'components/Dashboard/MyData/common/MyDataContentTemplate';
import '../style.css';
import LottieLoader from 'components/common/LottieLoader';
import axios from '../../../../../../axios';
import API from '../../../../../../utils/api';
// import swal from 'sweetalert';

const OrgFunctionAssignment = (props) => {
  const dispatch = useDispatch();
  const [assignedOrgFunctions, setAssignedOrgFunctions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [owner, setOwner] = useState(false);

  const history = useHistory();

  const { q: organisationId } = props.routeQueries;
  const { organisationFunction, religionLocaleOptions, schoolType } = props;

  useEffect(() => {
    if (organisationId) {
      try {
        setLoading(true);
        axios
          .get(
            `${API}/organisation-function-assignment/${organisationId}/all_org_functions/`,
          )
          .then((response) => {
            const { data } = response;
            setAssignedOrgFunctions(data);
            setLoading(false);
          })
          .catch((e) => {
            setAssignedOrgFunctions([]);
            setLoading(false);
          });
      } catch (e) {
        setAssignedOrgFunctions([]);
        setLoading(false);
      }
    } else {
      setAssignedOrgFunctions([]);
      setLoading(false);
    }
  }, []);

  const searchById = (term, listItems) => {
    for (const item of listItems) {
      if (term == item?.id) {
        return item;
      }
    }
    return null;
  };
  const mapFields = () => {
    const { trObj, tr, getOrgById, account } = props;
    return assignedOrgFunctions.map((orgFunction) => {
      const orgFunc = searchById(
        orgFunction?.organisation_function,
        organisationFunction,
      );
      const religionRec = searchById(orgFunction?.religion, religionLocaleOptions);
      const schoolRec = searchById(orgFunction?.school_type, schoolType);
      const org = getOrgById(orgFunction?.organisation);

      // const user_id = account?.user?.user_id;
      // const creator = org?.creator;

      // if (user_id == creator) {
      //   setOwner(true);
      // }

      return {
        ...orgFunction,
        organisation_function: trObj(orgFunc),
        religion: trObj(religionRec),
        school_type: trObj(schoolRec),
        organisation: org?.name,
        organisationId: org?.id,
      };
    });
  };

  // const handleBulkDelete = async (orgFuncIds) => {
  //   const deleteBulk = () => dispatch(deleteOrgFunctionAssignment(orgFuncIds));
  //   const { deleteConfirmation } = props;
  //   try {
  //     await deleteConfirmation.initBulk(deleteBulk, orgFuncIds);
  //   } catch (e) {}
  // };

  const tableName = 'org_function_assignment';

  const {
    // gotoCreatePath,
    tr,
    basePath,
    deleteBulkOrgFunctionAssignment,
    deleteOrgFunctionAssignmentById,
  } = props;

  const executeOnRowClick = (orgFunc) => {};

  const goBack = () => {
    const pathArray = basePath.split('/');
    pathArray.pop();
    const pathway = `${pathArray.join('/')}/favorite-organisation`;
    history.push(pathway);
  };

  // const showWarningDialog = () => {
  //   swal(
  //     tr('Warning!'),
  //     tr(`You cannot assign org function to an organisation you did not create!`),
  //     'warning',
  //   );
  //   return;
  // };

  const moveToCreateOrgFunctionForm = () => {
    history.push(`/dashboard/data/base/org_func_assign/create?q=${organisationId}`);
  };

  if (loading) {
    return (
      <>
        <LottieLoader />
      </>
    );
  }

  return (
    <MyDataContentTemplate
      columns={[]}
      data={mapFields()}
      // showHeader={false}
      goBack
      handleGoBack={goBack}
      handleCreateForm={() => {
        moveToCreateOrgFunctionForm();
      }}
      // handleCreateForm={()=>{owner ? moveToCreateOrgFunctionForm() : showWarningDialog()}}
      // bulkDelete={handleBulkDelete}
      // bulkDelete={deleteBulkOrgFunctionAssignment}
      // bulkDelete={deleteOrgFunctionAssignmentById}
      tableName={tableName}
      hasBulkDelete={false}
      // executeOnRowClick={executeOnRowClick}
    />
  );
};

export default compose(
  withTranslation,
  withBulkDelete,
  withDeleteConfirmation,
  withUserLayoutChoice,
  withCustomRouter,
  withCurrency,
  withMeasurementUnit,
  withReligion,
  withOrganisationFunction,
  withOrganisation,
  withSchoolType,
  withOrgFunctionAssignment,
)(OrgFunctionAssignment);
