import axios from '../axios';
import createAction from "./createAction";
import * as types from "./types";

import API from './api';

export const setDateFormat = (value) => {
  return createAction(types.DATE_FORMAT, value);
};


export const getDateFormat = () => {
  return async dispatch => {
    try {
      dispatch(createAction(types.USER_PREFERENCES_LOADER))
      const  { data } = await axios.get(API + "/date-format/");
      dispatch(createAction(types.GET_DATE_FORMAT, data));
      dispatch(createAction(types.USER_PREFERENCES_LOADER))
      return data;
    } catch (error) {
      dispatch(createAction(types.USER_PREFERENCES_LOADER))
      throw error
    }
  };
}
