import React from "react";
import { connect } from "react-redux";
import {
  getEyeColor,
} from "../../../actions";
import hoistNonReactStatics from 'hoist-non-react-statics'


let loaded = false;

export const withEyeColor = (WrapperComponent) => {
  class WithEyeColor extends React.Component {
    componentDidMount() {
      const { getEyeColor } = this.props;
      if (loaded) return;
      loaded = true;
      getEyeColor();
    }
    getEyeColorOptions = () => undefined
    getEyeColorLocaleOptions = () => {
      const { eyeColor } = this.props;
      return eyeColor.map(({ created_at, ...rest }) => ({ ...rest }));
    }
    render() {
      return (
        <WrapperComponent 
          eyeColorOptions={this.getEyeColorOptions()} 
          eyeColorLocaleOptions={this.getEyeColorLocaleOptions()} 
          {...this.props} 
        />
      )
    }
  }
  const mapStateToProps = ({ eyeColor }) => ({ eyeColor })
  const WithEyeColorHOC = connect(mapStateToProps, {
    getEyeColor,
  })(WithEyeColor);
  hoistNonReactStatics(WithEyeColorHOC, WrapperComponent)
  return WithEyeColorHOC
}
