import * as types from '../actions/types';
import { createTypes } from '../actions';

export const initialState = {
  data: [],
  orgFormData: {},
  isLoading: false,
  showForm: false,
  error: null,
  registerInstitutes: [],
  search: [],
  favorites: [],
  schools: [],
  singleFavoriteOrg: {
    data: {},
    loading: false,
  },
  singleOrganisation: {
    data: {},
    loading: false,
  },
};

export default function organisation(state = initialState, action = {}) {
  switch (action.type) {
    case createTypes(types.ADD_ORGANISATION).success:
      return {
        ...state,
        data: [action.payload, ...state.data],
        isLoading: false,
      };

    case createTypes(types.GET_ORGANISATION).success:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };

    case 'SET_ORG_DATA':
        return {
          ...state,
          data: action.payload,
          
        };

      
    case createTypes(types.UPDATE_ORGANISATION).success:
      return {
        ...state,
        data: state.data.map((_data) => {
          if (_data.id === action.payload.id) return action.payload;
          return _data;
        }),
        isLoading: false,
      };

    case types.ORGANISATION_FORM:
      return {
        ...state,
        ...action.payload,
      };

    case createTypes(types.ADD_ORGANISATION).loading:

    case createTypes(types.UPDATE_ORGANISATION).loading:

    case createTypes(types.GET_ORGANISATION).loading:
      return {
        ...state,
        isLoading: true,
        error: null,
      };

    case createTypes(types.ADD_ORGANISATION).failure:

    case createTypes(types.UPDATE_ORGANISATION).loading:

    case createTypes(types.GET_ORGANISATION).failure:
      return {
        ...state,
        error: action.payload,
      };

    case types.GET_REGISTER_INSTITUTE:
      return {
        ...state,
        registerInstitutes: [...action.payload],
      };

    case types.SEARCH_ORGANISATION:
      return {
        ...state,
        search: action.payload,
      };

    case types.FAVORITE_ORGANISATION:
      return {
        ...state,
        favorites: action.payload.data,
        isLoading: action.payload.isLoading,
      };

    case types.DELETE_FAVORITE_ORGANISATION:
      return {
        ...state,
        favorites: state.favorites.filter((org) => !action.payload.includes(org.id)),
      };

    case types.GET_SINGLE_ORGANISATION:
      return {
        ...state,
        singleOrganisation: {
          loaidng: action.payload.loading,
          ...action.payload.data,
        },
      };
    case types.GET_SINGLE_FAVORITE_ORGANISATION:
      return {
        ...state,
        singleFavoriteOrg: {
          loaidng: action.payload.loading,
          ...action.payload.data,
        },
      };
    case types.GET_USER_SCHOOLS:
      return {
        ...state,
        schools: action.payload,
      };
    default:
      return state;
  }
}
