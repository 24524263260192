import React from "react";
import compose from "lodash/fp/compose";
import { connect } from "react-redux";
import hoistNonReactStatics from 'hoist-non-react-statics'
import * as companionAnimalActions from "actions/dashboard/companionAnimalInfo"
import { BaseHOC, withBaseHOCProps } from "../common/BaseHOC"

// let loaded = false

const initialState = {
  loaded: false,
}

export const withCompanionAnimalInfo = (WrappedComponent) => {
  class CompanionAnimalInfo extends BaseHOC {

    state = { ...initialState }

    async componentDidMount() {
      const { getCompanionAnimalInfo } = this.props
      if (!this.state.loaded)  {
        await getCompanionAnimalInfo();
        this.setState({...initialState, loaded: true})
        // loaded = true;
      }
    }

    deleteCompanionAnimalInfoById = async (data) => {
      const { deleteCompanionAnimalInfoById } = this.props
      await this._deleteById(deleteCompanionAnimalInfoById, data);
    }

    deleteBulkCompanionAnimalInfo = async (data) => {
      const { deleteBulkCompanionAnimalInfo } = this.props
      await this._deleteBulk(deleteBulkCompanionAnimalInfo, data);
    }

    render() {
      return (
        <WrappedComponent 
          {...this.props} 
          loaded={this.state.loaded}
          deleteCompanionAnimalInfoById={this.deleteCompanionAnimalInfoById}
          deleteBulkCompanionAnimalInfo={this.deleteBulkCompanionAnimalInfo}
        />
      )
    }
  }
  const mapStateToProps = ({ companionAnimalInfo }) => ({ companionAnimalInfo })
  const WithCompanionAnimalInfo = connect(mapStateToProps,companionAnimalActions)(CompanionAnimalInfo);
  hoistNonReactStatics(WithCompanionAnimalInfo, WrappedComponent)
  return compose(withBaseHOCProps)(WithCompanionAnimalInfo);
}
