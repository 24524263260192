/* eslint-disable */
import React from 'react';
import compose from 'lodash/fp/compose';
import {
  withDocumentType,
  withDataArea,
  withTranslation,
  withLanguage,
  withAttachmentRule,
  withCustomRouter,
  withUserLayoutChoice,
  withBulkDelete,
  withUserPreferences,
} from 'components/hoc';
import MyDataContentTemplate from 'components/Dashboard/MyData/common/MyDataContentTemplate';
import LottieLoader from 'components/common/LottieLoader';
// import { ButtonLink, Button } from 'components/common/FormItems';
import { getFullName } from 'components/utils/getFullName'
// import {
//   Envelope,
//   PlusSquare,
//   TrashFill,
//   EyeFill,
//   PlusLg,
// } from 'react-bootstrap-icons';
// import swal from 'sweetalert';


const AttachmentRule = (props) => {
  const mapFields = () => {
    const { attachmentRules, trObj, fd } = props;
    console.log('attachmentRules', attachmentRules)
    return attachmentRules.data.map((data) => {
      return {
        ...data,
        space_name: data.space,
        person: getFullName(data?.person_detail),
        organisation: data.organisation_detail?.name,
        document_type: trObj(data.document_type_detail),
        data_area: trObj(data.data_area_detail),
        language: trObj(data.language_detail),
      };
    });
  };

  const {
    // basePath,
    // getLayoutColumnProps,
    gotoCreatePath,
    // rowIds,
    // hasBulkDelete,
    // tr,
    // deleteAttachmentRuleById,
    loaded,
    deleteBulkAttachmentRule,
  } = props;

  const tableName = 'attachment_rule';

  if (!loaded) {
    return (
      <>
        <LottieLoader />
      </>
    );
  }

  return (
    
    <MyDataContentTemplate
      tableName={tableName}
      columns={[]}
      data={mapFields()}
      handleCreateForm={gotoCreatePath}
      bulkDelete={deleteBulkAttachmentRule}
    />
  );
};

export default compose(
  withCustomRouter,
  withDocumentType,
  withDataArea,
  withTranslation,
  withLanguage,
  withAttachmentRule,
  withUserLayoutChoice,
  withBulkDelete,
  withUserPreferences,
)(AttachmentRule);
