import axios from '../axios';
import { setMsgInfo } from './';
import API from './api';
import filterError from './common/filterError';
// import createAction from "./createAction";
// import { createTypes } from "./createTypes";

const baseUrl = `${API}/space/create`;

export const createOrgSpace = (payload) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(`${baseUrl}/organisation/`, payload);
      // dispatch(createAction(types.UPDATE_SPACE_MEMBER, data));
      dispatch(
        setMsgInfo({
          success: true,
          msg: ['Organisation space successfully created'],
        }),
      );
      return data;
    } catch (err) {
      const errors = filterError(err);
      dispatch(
        setMsgInfo({
          success: false,
          msg: errors,
        }),
      );
      throw err;
    }
  };
};

export const createPersonalSpace = (payload) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(`${baseUrl}/personal/`, payload);
      // dispatch(createAction(types.UPDATE_SPACE_MEMBER, data));
      dispatch(
        setMsgInfo({
          success: true,
          msg: ['Personal space successfully created'],
        }),
      );
      return data;
    } catch (err) {
      const errors = filterError(err);
      // console.log(errors)
      dispatch(
        setMsgInfo({
          success: false,
          msg: errors,
        }),
      );
      throw err;
    }
  };
};

const baseUrl2 = `${API}/space/join`;

export const joinOrgSpace = (name, payload) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(
        `${baseUrl2}/organisation/${name}/`,
        payload,
      );
      // dispatch(createAction(types.UPDATE_SPACE_MEMBER, data));
      dispatch(
        setMsgInfo({
          success: true,
          msg: ['The access request was sent to the space administrator.'],
        }),
      );
      return data;
    } catch (err) {
      const errors = filterError(err);
      dispatch(
        setMsgInfo({
          success: false,
          msg: errors,
        }),
      );
      throw err;
    }
  };
};

export const joinPersonalSpace = (name, payload) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(`${baseUrl2}/personal/${name}/`, payload);
      // dispatch(createAction(types.UPDATE_SPACE_MEMBER, data));
      dispatch(
        setMsgInfo({
          success: true,
          msg: ['The access request was sent to the space administrator.'],
        }),
      );
      return data;
    } catch (err) {
      const errors = filterError(err);
      dispatch(
        setMsgInfo({
          success: false,
          msg: errors,
        }),
      );
      throw err;
    }
  };
};
