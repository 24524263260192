/* eslint-disable camelcase */
import React from 'react';

export const getNumberSign = (props) => {
  const { account_category, dc_indicator } = props
  if (
    account_category === 'asset' && dc_indicator === 'D' ||
    account_category === 'debt' && dc_indicator === 'C' ||
    account_category === 'expense' && dc_indicator === 'D' ||
    account_category === 'expenses' && dc_indicator === 'C'
  ) {
    return 1;
  }
  if (
    account_category === 'asset' && dc_indicator === 'C' ||
    account_category === 'debt' && dc_indicator === 'D' ||
    account_category === 'expense' && dc_indicator === 'C' ||
    account_category === 'expenses' && dc_indicator === 'D'
  ) {
    return -1;
  }
  return 1;
}

export const getSignedNumber = (number, props, fn = (v) => v) => {
  const sign = getNumberSign(props)
  const absNumber = String(number).replace('-', '')
  return number ? fn(absNumber * sign) : ''
}

export const transactionPosting = (values, formMode) => {
  const {
    description,
    currency,
    contract,
    contract_item,
    date,
    person,
    transaction: transaction_id,
    ...transaction_items_data
  } = values;

  const {
    amount,
    amount_sc,
    asset_financial_account,
    income_financial_account,
    asset_account_category,
    income_account_category,
    position_link,
  } = transaction_items_data;

  return {
    description,
    contract,
    contract_item,
    date,
    person,
    transaction_id,
    transaction_items: [
      {
        amount: formMode === 'update' ? parseFloat(amount) * -1: amount,
        amount_sc: formMode === 'update' ? parseFloat(amount_sc) * -1: amount_sc,
        financial_account: asset_financial_account,
        dc_indicator: 'D',
        currency,
        account_category: asset_account_category,
        line: 1,
        position_link: asset_financial_account === 'cash' ? currency : position_link,
      },
      {
        amount: formMode === 'update' ? parseFloat(amount) * -1: amount,
        amount_sc: formMode === 'update' ? parseFloat(amount_sc) * -1: amount_sc,
        financial_account: income_financial_account,
        dc_indicator: 'C',
        account_category: income_account_category,
        currency,
        line: 2,
      },
    ],
  };
};

export const getTransactionValuesFromPayload = (values) => {
  const { transaction_item, ...rest } = values;
  return {
    ...rest,
    ...transaction_item?.[0],
    asset_financial_account: transaction_item?.[0]?.financial_account,
    income_financial_account: transaction_item?.[1]?.financial_account,
    asset_account_category: transaction_item?.[0]?.account_category,
    income_account_category: transaction_item?.[1]?.account_category,
  };
};

export const getTransactionItem = (transaction_item, fn = (v) => v) => {
  const { amount, amount_sc, id, ...rest } = transaction_item || {}
  const sign = getNumberSign(rest)
  return { 
    ...rest, 
    ...(amount && { amount: fn(parseFloat(amount) * sign) }),
    ...(amount_sc && { amount_sc: fn(parseFloat(amount_sc) * sign) })  
  }
}

export const getDCIndicator = (dc_indicator) => {
  switch (dc_indicator) {
    case 'C':
      return 'Credit';
    case 'D':
      return 'Debit';
    default:
      return null
  }
}

export const twoDecimal = (number) =>{
  return parseFloat(number).toFixed(2);
}
