import Contract from '../common/Contract';
// import ContractItem from '../common/ContractItem';
import ContractItemForm from '../common/ContractItem/Form';
import ContractForm from '../common/Contract/ContractForm';
import Membership from './Membership';
import MembershipForm from './Membership/Form';

const path = '/dashboard/data/leisure';

export default [
  // {
  //   name: 'Contract',
  //   path: `${path}/contract`,
  //   icon: 'user',
  //   component: Contract,
  //   exact:true,
  // },
  // {
  //   name: 'Contract Form',
  //   path: [
  //     `${path}/contract/create`,
  //     `${path}/contract/:id`,
  //     `${path}/contract/:id/update`,
  //   ],
  //   exact: true,
  //   icon: 'user',
  //   component: ContractForm,
  //   hide: true,
  // },
  // {
  //   name: 'Contract Form Item',
  //   path: [
  //     `${path}/contract-item/create`,
  //     `${path}/contract-item/:id`,
  //     `${path}/contract-item/:id/update`,
  //   ],
  //   exact: true,
  //   icon: 'user',
  //   component: ContractItemForm,
  //   hide: true,
  // },
  {
    name: 'Membership',
    path: `${path}/membership`,
    icon: 'user',
    component: Membership,
    exact: true,
  },
  {
    name: 'Membership Form',
    path: [
      `${path}/membership/create`,
      `${path}/membership/:id`,
      `${path}/membership/:id/update`,
    ],
    icon: 'user',
    component: MembershipForm,
    exact: true,
  },
];

export const redirects = [
  {
    from: `${path}`,
    to: `${path}/contract`,
  },
  {
    from: `${path}/*`,
    to: `${path}/contract`,
  },
];
