import * as types from '../actions/types';
import { createTypes } from '../actions';

const initialState = {
  data: [],
  register_institute_options: [],
  school_options: [],
  medical_facility_options: [],
  credit_card_options: [],
  bank_options: [],
};

const orgFunctionAssignment = (state = initialState, action = {}) => {
  switch (action.type) {
    case createTypes(types.GET_ORG_FUNCTION_ASSIGNMENT).success:
      return { ...state, data: action.payload };

    case createTypes(types.GET_ORG_FUNCTION_ASSIGNMENT_REGISTER_INSTITUTE_OPTIONS)
      .success:
      return { ...state, register_institute_options: action.payload };

    case createTypes(types.GET_ORG_FUNCTION_ASSIGNMENT_SCHOOL_OPTIONS).success:
      return { ...state, school_options: action.payload };

    case createTypes(types.GET_ORG_FUNCTION_ASSIGNMENT_MEDICAL_FACILITY_OPTIONS)
      .success:
      return { ...state, medical_facility_options: action.payload };

    case createTypes(types.GET_ORG_FUNCTION_ASSIGNMENT_CREDIT_CARD_OPTIONS).success:
      return { ...state, credit_card_options: action.payload };

    case createTypes(types.GET_ORG_FUNCTION_ASSIGNMENT_BANK_OPTIONS).success:
      return { ...state, bank_options: action.payload };

    case createTypes(types.ADD_ORG_FUNCTION_ASSIGNMENT).success:
      return {
        ...state,
        data: [action.payload, ...state.data],
      };

    case createTypes(types.UPDATE_ORG_FUNCTION_ASSIGNMENT).success:
      return {
        ...state,
        data: state.data.map((_data) => {
          if (_data.id === action.payload.id) return action.payload;
          return _data;
        }),
      };

    case createTypes(types.DELETE_ORG_FUNCTION_ASSIGNMENT_BY_ID).success:
      return {
        ...state,
        data: state.data.filter((_data) => {
          return _data.id !== action.payload.id;
        }),
      };

    // case types.DELETE_FAVORITE_ORGANISATION:
    //   return {
    //     ...state,
    //     favorites: state.favorites.filter((org) => !action.payload.includes(org.id)),
    //   };

    case createTypes(types.ADD_ORG_FUNCTION_ASSIGNMENT).failure:

    case createTypes(types.UPDATE_ORG_FUNCTION_ASSIGNMENT).failure:

    case createTypes(types.GET_ORG_FUNCTION_ASSIGNMENT).failure:

    case createTypes(types.DELETE_ORG_FUNCTION_ASSIGNMENT_BY_ID).failure:
      return { ...state, error: action.payload };
    default:
      return state;
  }
};

export default { orgFunctionAssignment };