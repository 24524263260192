import React from 'react';
import Button from 'components/common/FormItems/Button';

import Icon from 'components/Icon';

import './emptyState.css';

const EmptyState = ({ hasButton = false, text = '' }) => {
  return (
    <div className={'container'}>
      <div className={'image'}>
        <Icon icon={'emptyState'} width={151} heigth={141} />
      </div>

      <div className={'text'}>{text}</div>

      {hasButton === true && (
        <div>
          <Button
            type="button"
            onClick={() => console.log('Add button clicked!')}
            name={'Add'}
            className={'button'}
          />
        </div>
      )}
    </div>
  );
};

export default EmptyState;
