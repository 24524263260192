import styled from "styled-components";
import SquareCheckbox from "../SquareCheckbox";
import Button from "../Button";


export const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width 100%;
  height: 100%;
  z-index: 2000;
  background: rgba(0,0,0,0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
`

export const InnerContainer = styled.div`
  width: auto;
  height: 100%;
  max-height: 50vh;

  > div {
    background: #fff;
  }

  > h3 {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom: 1px solid #ddd;
  }

  > div.card-footer {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top: 1px solid #ddd;
  }
`

export const DnDWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
`


export const StyledButton = styled(Button)`
  && {
    min-width: 80px;
  }
`;


export const FlexBox = styled.div`
  display: flex;
  align-items: center;
  text-transform: capitalize;
`

export const StyledSquareCheckbox = styled(SquareCheckbox)`
  && {
    margin-bottom: 0;

    > label {
      margin-bottom: 0;
    }
  }
`

export const Title = styled.h3`
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  padding: 10px;
  background: #fff;
  color: var(--admincat-dark-color);
`
