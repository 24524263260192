import * as types from "./types";
import baseAction from "./baseAction";

const baseUrl = "space/user"; 

export const getUserPersonalWorkspace = baseAction(
    types.USER_PERSONAL_WORKSPACE, `${baseUrl}/personal`);
    
export const getUserOrganisationWorkspace = baseAction(
    types.USER_ORGANISATION_WORKSPACE, `${baseUrl}/organisation`);
