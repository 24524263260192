import React from "react";
import compose from "lodash/fp/compose";
import { connect } from "react-redux";
import hoistNonReactStatics from "hoist-non-react-statics";
import { getLanguage } from "actions/preload";
import { BaseHOC, withBaseHOCProps } from "../common/BaseHOC";

let loaded = false

export const withLanguage = (WrappedComponent) => {
  class Language extends BaseHOC {

    async componentDidMount() {
      const { getLanguage } = this.props
      if (!loaded)  {
        loaded = true;
        await getLanguage();
      }
    }

    get languageOptions() {
      const { language } = this.props;
      return this._getOptions(language, ['id'], true)
    }

    get activeLanguageOptions() {
      const languages = this.languageOptions;
      return languages.filter(language => ['deu', 'eng'].includes(language.id) );
    }

    render() {
      return (
        <WrappedComponent 
          {...this.props} 
          languageOptions={this.languageOptions}
          activeLanguageOptions={this.activeLanguageOptions}
        />
      )
    }
  }
  const mapStateToProps = ({ language }) => ({ language })
  const WithLanguage = connect(mapStateToProps, { getLanguage })(Language);
  hoistNonReactStatics(WithLanguage, WrappedComponent)
  return compose(withBaseHOCProps)(WithLanguage);
}
