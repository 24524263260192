import React, { Suspense, useEffect, useState } from 'react';
import styled from 'styled-components';
import RouteRedirect from 'components/common/RouteRedirect';
import { withAccount, withAdminRouteFiltering } from '../../components/hoc';
import routes, { redirects, path } from './routes';
import compose from 'lodash/fp/compose';

const StyledRouteRedirect = styled(RouteRedirect)`
  margin-right: 1rem;
`;

const AdminDashboard = (props) => {
  const [filteredRoutes, setFilteredRoutes] = useState([]);

  useEffect(() => {
    const filtered = props.filterAdminRoutes(routes);
    setFilteredRoutes(filtered);
  }, []);

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <StyledRouteRedirect routes={filteredRoutes} redirects={[]} />
    </Suspense>
  );
};

export default compose(withAccount, withAdminRouteFiltering)(AdminDashboard);
