import React, { Component, Fragment } from "react";
import languages from "../../language-resource";
import { connect } from "react-redux";
import { withLanguage } from "../hoc";

class Locale extends Component {
  render() {
    const lang = languages["eng"];
    const value = lang[this.props.value] || "Unknown";
    return (
      <Fragment>
        { value }
      </Fragment>
    )
  }
}

export function translate(value) {
  return languages[localStorage.lang || 'eng'][value];
}

export default withLanguage(Locale)
