import React from 'react';
import { Icon } from 'semantic-ui-react';
import { withTranslation } from '../../../hoc';
import styled, { css } from 'styled-components';

const ButtonDiv = styled.button`
  padding: 6px 15px;
  border-radius: 7px;
  margin-right: 5px;
  cursor: pointer;

  ${({ cancelButton }) =>
    cancelButton &&
    css`
      background: #e4f1ff;
      border: solid 1px #e4f1ff;
      color: #fff;
    `}

  ${({ actionButton }) =>
    actionButton &&
    css`
      background: #4c81be;
      border: solid 1px #1d579b;
      color: #fff;
    `}
`;

const func = () => {};

const Button = withTranslation(
  ({ tr, render, disabled, cancelButton, actionButton, ...props }) => {
    return (
      <ButtonDiv
        actionButton={actionButton}
        cancelButton={cancelButton}
        onClick={props.onClick || func}
        to={props.to || ''}
        type={props.type || 'button'}
        disabled={disabled}
        style={props.style || {}}
        className={`${props.className || ''}`}
      >
        {render ? (
          render(props)
        ) : (
          <span className="block">
            {props.icon && <Icon name={props.icon} />}
            {props.svgIcon && props.svgIcon}
            {props.reactIcon && props.reactIcon}
            <span>
              {props.isLoading ? (
                <i className="fa fa-spinner fa-spin"></i>
              ) : (
                tr(props.name)
              )}
            </span>
          </span>
        )}
      </ButtonDiv>
    );
  },
);

export default Button;
