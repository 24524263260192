import React from 'react';
import {
  withTranslation,
  withUserPreferences,
  withCustomRouter,
} from 'components/hoc';
import LottieLoader from 'components/common/LottieLoader';
import AdminContentTemplate from 'components/Admin/common/AdminContentTemplate';
import compose from 'lodash/fp/compose';

const ProposalFunction = (props) => {
  // if (!loaded) {
  // we are not loading data yet
  if (false) {
    return (
      <>
        <LottieLoader />
      </>
    );
  }

  return (
    <>
      <AdminContentTemplate
        tableName="organisation_function_proposal"
        columns={[]}
        data={[]}
        handleCreateForm={() => {}}
        bulkDelete={() => {}}
      />
    </>
  );
};

export default compose(
  withTranslation,
  withCustomRouter,
  withUserPreferences,
)(ProposalFunction);
