import  { MSGINFO } from "./types";

export function setMsgInfo(data){ 
  return { 
    type: MSGINFO,
    payload: data
  }
}


