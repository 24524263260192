import ProposalEntry from './ProposalEntry';
import ProposalFunction from './ProposalFunction';
import OrgProposal from './OrgProposal';
import AdminEntryProposalActionForm from './ProposalEntry/Form';
import AdminOrgProposalActionForm from './OrgProposal/Form';

const path = '/admin/dashboard/proposal';

export default [
  {
    name: 'Entry',
    path: `${path}/entry`,
    icon: 'envelope',
    component: ProposalEntry,
    exact: true,
  },
  {
    name: 'Entry',
    path: [`${path}/entry/:id`, `${path}/entry/:id/update`],
    icon: 'user',
    component: AdminEntryProposalActionForm,
    hide: true,
    exact: true,
  },
  {
    name: 'Organisation',
    path: `${path}/org`,
    icon: 'sticky note',
    component: OrgProposal,
    exact: true,
  },
  {
    name: 'Organisation',
    path: [`${path}/org/:id`, `${path}/org/:id/update`],
    icon: 'user',
    component: AdminOrgProposalActionForm,
    hide: true,
    exact: true,
  },
  {
    name: 'Function Assignment',
    path: `${path}/function_assignment`,
    icon: 'trash',
    component: ProposalFunction,
    exact: true,
  },
];

export const redirects = [
  {
    from: `${path}`,
    to: `${path}/entry`,
  },
  {
    from: `${path}/*`,
    to: `${path}/entry`,
  },
];
