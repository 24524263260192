/* eslint-disable camelcase */
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getOrganisations, getFavoriteOrg } from 'actions';
import { SPACE_TYPES } from '../constants';

export const useFavoriteOrganisation = (type) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.account);
  const { favorites } = useSelector((state) => state.organisation);

  // console.log('favorites', favorites)

  useEffect(() => {
    if (favorites.length) return;
    if (!user) return;
    if (user.space_type === SPACE_TYPES.PERSON) {
      dispatch(getFavoriteOrg(type));
    } else {
      dispatch(getOrganisations());
    }
  }, []);

  return favorites.map((org) => ({
    ...org,
    id: org.organisation,
    name: org.organisation_data.name,
    orgFunction: org.organisation_data?.org_function,
  }));
};
