import moment from 'moment';

export const reformatDate = (date) => {
  const newDate = moment(new Date(date)).format('YYYY-MM-DD');
  return newDate === 'Invalid date' ? null : newDate;
};

export const reformatDate2 = (date, dateFormat) => {
  // console.log('inside date 2: ', date, dateFormat);
  // const newDate = moment(new Date(date)).format(dateFormat);
  // console.log('new date: ', newDate);
  // return newDate === 'Invalid date' ? null : newDate;

  const momentFormats = [
    "DD.MM.YYYY",
    "DD-MM-YYYY",
    "DD/MM/YYYY",
    "MM-DD-YYYY",
    "MM.DD.YYYY",
    "MM/DD/YYYY",
    "YYYY-MM-DD",
    "YYYY.MM.DD",
    "YYYY/MM/DD"
  ];

  const momentDate = moment(date, momentFormats, true);
  return momentDate.isValid() ? momentDate.format("DD-MM-YYYY") : null;
};