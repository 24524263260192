import React from "react";
import { connect } from "react-redux";
import {
  getFormType,
} from "../../../actions";
import hoistNonReactStatics from 'hoist-non-react-statics'


let loaded = false;

export const withFormType = (WrapperComponent) => {
  class WithFormType extends React.Component {
    componentDidMount() {
      const { getFormType } = this.props;
      if (loaded) return;
      loaded = true;
      getFormType();
    }
    getFormTypeOptions = () => undefined
    getFormTypeLocaleOptions = () => {
      const { formType } = this.props;
      return formType.map(({ created_at, ...rest }) => ({ ...rest }));
    }
    render() {
      return (
        <WrapperComponent 
          {...this.props} 
          formTypeOptions={this.getFormTypeOptions()} 
          formTypeLocaleOptions={this.getFormTypeLocaleOptions()} 
        />
      )
    }
  }
  const mapStateToProps = ({ formType }) => ({ formType })
  const WithFormTypeHOC = connect(mapStateToProps, {
    getFormType,
  })(WithFormType);
  hoistNonReactStatics(WithFormTypeHOC, WrapperComponent)
  return WithFormTypeHOC
}
