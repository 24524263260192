import React from "react";
import { connect } from "react-redux";
import {
  getDataArea,
} from "../../../actions";
import hoistNonReactStatics from 'hoist-non-react-statics'

let loaded = false;

export const withDataArea = (WrapperComponent) => {
  class DataArea extends React.Component {
    componentDidMount() {
      const { getDataArea } = this.props;
      if (loaded) return;
      loaded = true;
      getDataArea();
    }
    getDataAreaOptions = () => undefined
    getDataAreaLocaleOptions = () => {
      const { dataArea } = this.props;
      return dataArea.map(({ created_at, ...rest }) => ({ ...rest }));
    }
    render() {
      return (
        <WrapperComponent 
          {...this.props} 
          dataAreaOptions={this.getDataAreaOptions()} 
          dataAreaLocaleOptions={this.getDataAreaLocaleOptions()} 
        />
      )
    }
  }
  const mapStateToProps = ({ dataArea }) => ({ dataArea })
  const WithDataArea = connect(mapStateToProps, {
    getDataArea,
  })(DataArea);
  hoistNonReactStatics(WithDataArea, WrapperComponent)
  return WithDataArea
}
