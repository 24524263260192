import React from 'react';
import compose from 'lodash/fp/compose';
import { connect } from 'react-redux';
import hoistNonReactStatics from 'hoist-non-react-statics';
import * as orgFunctionAssignmentActions from 'actions/orgFunctionAssignment';
import { BaseHOC, withBaseHOCProps } from '../common/BaseHOC';

let loaded = false;

export const withOrgFunctionAssignment = (WrappedComponent) => {
  class OrgFunctionAssignment extends BaseHOC {
    async componentDidMount() {
      const {
        getOrgFunctionAssignment,
        getRegisterInstituteOptions,
        getSchoolOptions,
        getMedicalFacilityOptions,
        getCreditCardOptions,
        getBankOptions,
      } = this.props;

      if (loaded) return;
      loaded = true;
      await Promise.all([
        getRegisterInstituteOptions(),
        getOrgFunctionAssignment(),
        getSchoolOptions(),
        getMedicalFacilityOptions(),
        getCreditCardOptions(),
        getBankOptions(),
      ]);
    }

    deleteOrgFunctionAssignmentById = async (data) => {
      const { deleteOrgFunctionAssignmentById } = this.props;
      await this._deleteById(deleteOrgFunctionAssignmentById, data);
    };

    deleteBulkOrgFunctionAssignment = async (data) => {
      const { deleteOrgFunctionAssignment } = this.props;

      await this._deleteBulk(deleteOrgFunctionAssignment, data);
    };

    getOrgFunctionAssignmentBySchoolType = (schoolType) => {
      const { orgFunctionAssignment } = this.props;
      const { school_options } = orgFunctionAssignment;
      if (!schoolType) return school_options;
      const options = school_options.filter(({ school_type_detail = {} }) => {
        return school_type_detail.type === schoolType;
      });
      return this._getOptions(options, ['id', 'name', 'school_type_detail.id']);
    };

    render() {
      return (
        <WrappedComponent
          {...this.props}
          deleteOrgFunctionAssignmentById={this.deleteOrgFunctionAssignmentById}
          getOrgFunctionAssignmentBySchoolType={
            this.getOrgFunctionAssignmentBySchoolType
          }
          deleteBulkOrgFunctionAssignment={this.deleteBulkOrgFunctionAssignment}
        />
      );
    }
  }
  const mapStateToProps = ({ orgFunctionAssignment }) => ({ orgFunctionAssignment });
  const WithOrgFunctionAssignment = connect(
    mapStateToProps,
    orgFunctionAssignmentActions,
  )(OrgFunctionAssignment);
  hoistNonReactStatics(WithOrgFunctionAssignment, WrappedComponent);
  return compose(withBaseHOCProps)(WithOrgFunctionAssignment);
};