/* eslint-disable */
import React from "react";
import styled from "styled-components";
import compose from "lodash/fp/compose";
import { 
  withWorkspace, 
  withEnhance,
  withAccount,
  withSpace,
  withUserDefaultSpace,
} from "components/hoc";
import { 
  Form, 
  Select, 
  Input,
  Button,
} from "components/common/FormItems";
import {
  BaseWorkspace,
  Personal,
  Organisation,
} from "components/common/Workspace";
import WorkspaceSettings from './WorkspaceSettings'


const StyledForm = styled(Form)`
  div.card-body {
    max-width: 700px;
  }
`

const StyledButton = styled(Button)`
  width: 100px;
`

const withEnhancedFields = withEnhance({ Input, Select });

class Workspace extends BaseWorkspace {
  PersonalComponent = withEnhancedFields(Personal);
  OrganisationComponent = withEnhancedFields(Organisation);
  joinOrg = this.asyncAction("joinOrgSpace"); 
  createOrg = this.asyncAction("createOrgSpace");
  joinPersonal = this.asyncAction("joinPersonalSpace");
  createPersonal = this.asyncAction("createPersonalSpace");
  renderRadioButtonArray = () => null;

  renderPersonalOrOrganisationForm = () => {
    const { 
      state, 
      onChange, 
      PersonalComponent,
      OrganisationComponent,
      props: {
        account: {
          user: {
            space_type = 'P',
          }
        }
      }
    } = this;
    return space_type === 'P' ? (
      <PersonalComponent 
        onChange={onChange}
        {...state}
      />
    ) : (
      <OrganisationComponent 
        onChange={onChange}
        {...state}
      />
    )
  }

  render() {
    const { state } = this;

    return (
      <React.Fragment>
        <WorkspaceSettings />
        <StyledForm
          title="Create or Join Workspace "
          onSubmit={this.onSubmit}
          isLoading={state.isLoading}
          renderActionButtons={() => (
            <StyledButton 
              type="submit" 
              name="Save" 
              isLoading={state.isLoading}
              className="btn-primary"
            />
          )}
        >
          { this.renderRadioButtonArray() }
          { this.renderPersonalOrOrganisationForm() }
          <br />
        </StyledForm>
      </React.Fragment>
    );
  }
}

export default compose(
  withWorkspace,
  withAccount,
  withSpace,
  withUserDefaultSpace,
)(Workspace);
