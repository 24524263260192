import { BaseActionClass } from "../common/BaseActionClass"
import { PERSON_GROUP } from '../types'

const path = "/dashboard/person_group";
const name = "Person Group";

class PersonGroup extends BaseActionClass {

  constructor() { super(path, PERSON_GROUP, name) }

  addPersonGroup = this._add;

  updatePersonGroup = this._update;

  deletePersonGroupById = this._deleteById;

  getPersonGroup = this._getAll;

  getPersonGroupById = this._getById;

  deleteBulkPersonGroup = this._deleteBulk;
}

export const { 
  addPersonGroup, 
  updatePersonGroup, 
  deletePersonGroupById,
  getPersonGroup,
  getPersonGroupById,
  deleteBulkPersonGroup,
} = new PersonGroup()

