import styled from "styled-components";
import SquareCheckbox from "../../../../common/FormItems/SquareCheckbox";

export const StyledSquareCheckbox = styled(SquareCheckbox)`
  && {
    margin-bottom: 0;

    > label {
      margin-bottom: 0;
    }
  }
`
