import React from 'react';
import compose from 'lodash/fp/compose';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { excludeUnsetField } from 'components/utils/excludeUnsetField';
import {
  withTranslation,
  withPersonalInfo,
  withAddressInfo,
  withResidenceInfo,
  withUserPreferences,
  withCustomRouter,
} from '../../../../hoc';
import {
  InputDatePicker,
  ColumnalSelect,
  Switchery,
  DualInputWrapper,
  Form,
  ButtonLink,
  Select,
} from '../../../../common/FormItems';

const ValidityLabel = styled(({ className, tr }) => (
  <div className={`form-group ${className}`}>
    <label className="form-label">{tr('Validity Period')}</label>
  </div>
))`
  && {
    margin-top: 1rem;
    margin-bottom: 0;
  }
`;

const Wrapper = styled.div``;

const StyledButtonLink = styled(ButtonLink)`
  span {
    text-transform: uppercase;
  }
`;

const personLabels = [
  {
    first_name: 'First Name',
  },
  {
    last_name: 'Last Name',
  },
];

const addressLabels = [
  { _country: 'Country' },
  { _city: 'City' },
  { _street: 'Street' }
];

// const addressLabels = [
//   {
//     _country: 'Country',
//   },
//   {
//     _city: 'City',
//   },
//   {
//     _street_name: 'Street name',
//   },
//   {
//     _street_no: 'Street number',
//   },
//   {
//     _street_no_suffix: 'Street suffix',
//   },
//   // {
//   //   street: `${Street_name} ${street_no} ${street_no_suffix}`,
//   // },
// ];

const residenceFormSchema = {
  resident: {
    validation: 'required',
  },
  address: {
    validation: 'required',
  },
  valid_from: {
    validation: 'required',
  },
  valid_to: {
    validation: 'required',
  },
};

const ResidenceForm = (props) => {
  const {
    addResidenceInfo,
    // getResidenceInfo,
    getResidenceInfoById,
    updateResidenceInfo,
    routeParams,
    // gotoDetailPath,
  } = props;
  const { tr, trObj, basePath } = props;
  const { id: residenceId } = routeParams;
  const [formData, setFormData] = React.useState({});
  const [formSubmitting, setFormSubmitting] = React.useState(false);
  const [formLoading, setFormLoading] = React.useState(false);

  const history = useHistory();
  console.log(addressLabels)

  const fetchData = async () => {
    if (residenceId) {
      try {
        setFormLoading(true);
        const { ...data } = await getResidenceInfoById(residenceId);
        setFormData(data);
      } finally {
        setFormLoading(false);
      }
    }
  };

  React.useEffect(() => {
    fetchData();
    return () => null;
  }, []);

  const onChange = (e) => {
    if (formSubmitting) return;
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const getAddressInfo = () => {
    return props.addressInfo.data.map(({ country_detail, city_detail, street_name, street_no, street_no_suffix, ...rest }) => ({
      ...rest,
      _country: trObj(country_detail),
      _city: trObj(city_detail),
      _street_name: street_name,
      _street_no: street_no,
      _street_no_suffix: street_no_suffix,
    }));
  };
  

  const onSubmit = async (e) => {
    if (formSubmitting) return;
    let payload = excludeUnsetField(formData);
    try {
      setFormSubmitting(true);
      let residence = {};
      if (!formData.id) {
        residence = await addResidenceInfo(payload);
      } else {
        residence = await updateResidenceInfo(formData.id, payload);
      }
      // await getResidenceInfo()
      // gotoDetailPath(residence.id)
      history.push(basePath);
      window.scrollTo(0, 0);
    } catch (e) {
    } finally {
      setFormSubmitting(false);
    }
  };

  return (
    <Wrapper className="form-wrapper">
    <div className="flex">
      <StyledButtonLink
        to={basePath}
        className="option-btn"
        icon="angle double left"
        name="All Entries"
      />
    </div>
    <div className="row">
      <div
        className="col-7 ml-10 mt-30 container"
        style={{
          position: 'relative',
          background: 'var(--admincat-color-grey-1)',
        }}
      >
        <br />
          <Form
            onSubmit={onSubmit}
            formData={formData}
            isLoading={formSubmitting}
            loadingData={formLoading}
            validationSchema={residenceFormSchema}
            title="Residence"
          >
            {/* <ColumnalSelect
              onChange={onChange}
              value={formData.resident || ''}
              name="resident"
              label="Resident"
              showRequiredAsterisk
              labels={personLabels}
              options={props.personalInfo.data}
            /> */}
            <Select
              optionDefaultValue={tr('Choose Resident')}
              label={tr('Resident')}
              value={formData?.resident || ''}
              name="resident"
              onChange={onChange}
              options={props.personalInfoOptions}
              deselectable
              sort={false}
              showRequiredAsterisk
            />

            <ColumnalSelect
              onChange={onChange}
              value={formData.address || ''}
              name="address"
              label="Address"
              showRequiredAsterisk
              labels={addressLabels}
              options={getAddressInfo()}
            />
            <Switchery
              name="principal_indicator"
              value={formData.principal_indicator}
              onChange={onChange}
              label="Principal Indicator"
              showRightLabel={false}
            />
            <ValidityLabel tr={tr} />
            <DualInputWrapper>
              <InputDatePicker
                label="Valid From"
                value={formData.valid_from || ''}
                name="valid_from"
                onChange={onChange}
                showRequiredAsterisk
              />
              <InputDatePicker
                label="Valid To"
                value={formData.valid_to || ''}
                name="valid_to"
                onChange={onChange}
                showRequiredAsterisk
              />
            </DualInputWrapper>
          </Form>
        </div>
      </div>
    </Wrapper>
  );
};
export default compose(
  withTranslation,
  withPersonalInfo,
  withAddressInfo,
  withResidenceInfo,
  withUserPreferences,
  withCustomRouter,
)(ResidenceForm);
