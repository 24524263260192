import { useState, useCallback } from 'react';

export const useApiError = () => {
  const [error, setError] = useState(null);

  const handleApiError = useCallback((error) => {
    setError(error);
    if (process.env.NODE_ENV === 'development') {
      console.error('API Error:', error);
    }
  }, []);

  const clearError = useCallback(() => {
    setError(null);
  }, []);

  return { error, handleApiError, clearError };
};