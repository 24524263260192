import React from 'react';
import styled, { css } from 'styled-components';
import compose from 'lodash/fp/compose';
// import { useHistory } from 'react-router-dom';
import {
  Form,
  TextArea,
  Select,
  FileInput,
  // Switchery,
  SquareCheckbox,
  Button,
} from '../../common/FormItems';
import { withCommentCategory, withComment, withTranslation } from '../../hoc';

const StyledButton = styled(Button)`
  width: 8.25rem;
  height: 2.5rem;
  line-height: 1.25rem;
  font-weight: 500;
  font-size: 1rem;
  border: ${(props) => (props.border ? props.border : 'none')};
  border-radius: 7px;
  color: ${(props) => (props.color ? props.color : '#fff')};
  background: ${(props) => (props.background ? props.background : '#4C81BE')};
  transition: all ease-in-out 300ms;
  ${({ border }) =>
    border &&
    css`
      border: solid 1px #4c81be;
      border-radius: 7px;
    `}

  &:hover {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.29);
  }
`;

const StyledTextArea = styled(TextArea)`
  width: 100%;
  max-width: 100%;
`;

const getContactUsFormSchema = () => ({
  comment_cat: {
    validation: 'required',
  },

  description: {
    validation: 'required',
  },
});

const ContactUsForm = (props) => {
  const {
    // commentFormSubmitting,
    commentFormData,
    commentCategoryLocaleOptions,
    onSubmitComment,
    onChangeComment,
    tr,
    isLoading,
  } = props;

  // const history = useHistory();

  const [onChange, onSubmit] = [onChangeComment, onSubmitComment];
  const { comment_cat } = commentFormData;
  const contactUsFormSchema = getContactUsFormSchema();

  return (
    <div
      className="form-wrapper"
      style={{ background: 'var(--admincat-color-grey-1)' }}
    >
      <Form
        title="Contact Us"
        isLoading={isLoading}
        onSubmit={onSubmit}
        formData={commentFormData}
        validationSchema={contactUsFormSchema}
        renderActionButtons={() => (
          <StyledButton
            type="submit"
            name="Submit"
            isLoading={commentFormData.isLoading}
          />
        )}
      >
        <div className="row">
          <div className="col-md-8">
            <Select
              name="comment_cat"
              value={comment_cat}
              onChange={onChange}
              label="Comment Category"
              optionDefaultValue="Choose Comment Category"
              localeOptions={commentCategoryLocaleOptions}
              sort={false}
              showRequiredAsterisk
            />
            <StyledTextArea
              name="description"
              value={commentFormData.description}
              onChange={onChange}
              placeholder="Enter Description"
              label="Description"
              required
              style={{ color: 'var(--admincat-color-grey-2)' }}
            />
            <FileInput
              name="document"
              value={commentFormData.document}
              onChange={onChange}
              placeholder="File"
              label={tr('File')}
              hasError="Error"
            />
            <SquareCheckbox
              name="response_welcome"
              value={commentFormData.response_welcome}
              onChange={onChange}
              label="You may contact me via email for this subject"
              showRightLabel={true}
            />
            {/* <StyledButton
              type="submit"
              name={tr('Submit')}
              isLoading={commentFormData.loading}
            /> */}
            {/* <Switchery 
                name="response_welcome" 
                value={commentFormData.response_welcome}
                onChange={onChange}
                label="Response Welcome (No/Yes)"
                showRightLabel={false}
              /> */}
          </div>
        </div>

        {/* {(formProps) => (
          <div className="row">
            <div className="col-md-8">
              <Select
                name="comment_cat"
                value={comment_cat}
                onChange={onChange}
                label="Comment Category"
                optionDefaultValue="Choose Comment Category"
                localeOptions={commentCategoryLocaleOptions}
                {...formProps}
                sort={false}
                showRequiredAsterisk
              />
              <StyledTextArea
                name="description"
                value={commentFormData.description}
                onChange={onChange}
                placeholder="Enter Description"
                label="Description"
                required
                style={{ color: 'var(--admincat-color-grey-2)' }}
                {...formProps}
              />
              <FileInput
                name="document"
                value={commentFormData.document}
                onChange={onChange}
                placeholder="File"
                label={tr('File')}
                hasError="Error"
                {...formProps}
              />
              <SquareCheckbox
                name="response_welcome"
                value={commentFormData.response_welcome}
                onChange={onChange}
                label="You may contact me via email for this subject"
                showRightLabel={true}
              />
             
            </div>
          </div>
        )} */}
      </Form>
    </div>
  );
};

export default compose(
  withCommentCategory,
  withComment,
  withTranslation,
)(ContactUsForm);
