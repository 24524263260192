import React, { Component, useState, useEffect } from "react";
import compose from "lodash/fp/compose";

import { Input, Form } from "../common/AuthFormItems";
import {MainWrapper, ImageSection, PasswordSection, Confirmation} from "./style"
import { useTranslation } from 'react-i18next';
import { 
  withTranslation,
  withAccount,
  withMsgInfo,
} from "../hoc";
import "../common/form.css";
import forgotPasswordAction from "../../actions/fogotPassword"
import { useDispatch, useSelector } from 'react-redux'

import infoImage from "./info.svg"



const ForgotPassword = (props)=>{
  const { t } = useTranslation();
  const [email, setEmail] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  
  const dispatch = useDispatch()
  const pwdState = useSelector((state) => state.fogotPasswordReducer)


  const onSubmit=(e)=>{
    e.preventDefault();
    setIsLoading(true)
    if(!email){
      props.setMsgInfo({
        success: false, 
        msg: ["Enter a valid email"]
      });
      return;
    }

    if(email){
      const dispatchEmail = {
        "email":email
      }
      dispatch(forgotPasswordAction(dispatchEmail))
      setIsLoading(false)
    }
  }
  const onChange = (e)=>{
    setEmail(e.target.value)
  }


  return <MainWrapper>
  <ImageSection />

  <PasswordSection>
    {pwdState.data.email ? (
    <Confirmation>
      <div className="content">
        <div className="image">
          <img src={infoImage}/>
        </div>
        <p>{t("An email has been sent to your email address")}</p>
      </div>
    </Confirmation>
    ):(

    <Form 
      isLoading={pwdState.isLoading}
      title={t("Password Recovery")} 
      name={t("Reset Password" )}
      onSubmit={onSubmit}>
      <Input
        icon="lock"
        onChange={(e)=> onChange(e)}
        type="email"
        value={email}
        name="email"
        label="email"
        placeholder={t("email")}
        required
      />
    </Form>
    )}
  </PasswordSection>
</MainWrapper>
}


export default compose(
  withAccount,
  withMsgInfo,
  withTranslation,
)(ForgotPassword);
