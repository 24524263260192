import check from "check-types";
import { isEmptyValue } from "./checkValue"

export const getQueryParams = (data={}) => {
  check.assert.object(data, "param must be an object");
  const queryObj = {}
  Object.entries(data).forEach(([key, value]) => {
    if (!isEmptyValue(value)) {
      Object.assign(queryObj, { [key]: value }); 
    }
  })
  return new URLSearchParams(queryObj).toString()
}
