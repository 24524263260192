import React from "react";
// import { isEqual } from "lodash";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { FlexBox, StyledSquareCheckbox as SquareCheckbox } from "./styled"

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  const reordedResult = result.map(({ order, ...rest }, index) => ({
    order_no: index,
    ...rest,
  }))
  return reordedResult;
};

const grid = 4;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? "#eee" : "#fff",

  color: '#5d6e76',
  border: '1px solid #5d6e76',

  borderRadius: '5px',

  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? "lightblue" : "#fff",
  padding: grid,
  width: 270
});

export class DnD extends React.Component {

  state = {
    items: this.props.columns,
  };

  // componentDidUpdate() {
  //   const { items } = this.state;
  //   const { columns } = this.props;
  //   console.log({ items, columns })
  //   if (!isEqual(items, columns)) {
  //     this.setState(columns);
  //   }
  // }

  static defaultProps = {
    columns: []
  }

  onChange = (index) => (e) => {
    const { setItems } = this.props;
    const { name, value } = e.target;
    const items = [ ...this.state.items ];
    items[index] = { ...items[index], selected: value };
    setItems(items);
    this.setState({ items });
  }

  onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const { setItems } = this.props;
    const items = reorder(
      this.state.items,
      result.source.index,
      result.destination.index
    );
    const newItems = items.map((item, index) => ({ ...item, order_no: index }));

    setItems(newItems);
    this.setState({ items: newItems });
  }

  // Normally you would want to split things out into separate components.
  // But in this example everything is just done in one place for simplicity
  render() {

    const { tr } = this.props;

    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {this.state.items.map((item, index) => (
                <Draggable 
                  key={item.id} 
                  draggableId={item.id.toString()} 
                  index={index}
                >
                  {(provided, snapshot) => (
                    <FlexBox
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      <SquareCheckbox 
                        value={item.selected}
                        name={item.field}
                        onChange={this.onChange(index)} 
                      />
                      {tr(item.label)}
                    </FlexBox>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
}

