import swal from 'sweetalert';

class DeleteConfirmation {

  constructor(){
    this.swal = swal;
    this.setDefault(); 
  }

  init = (DeleteRequest, selectedData) => {
    this.DeleteRequest = DeleteRequest;
    this.selectedData = selectedData;
    this.isDeleteSingle = true;
    return this.showModal();
  }

  initBulk = (DeleteRequest, selectedDataArray) => {
    if(!selectedDataArray.length) return Promise.resolve();
    this.DeleteRequest = DeleteRequest;
    this.selectedDataArray = selectedDataArray;
    this.isDeleteSingle = false;
    return this.showModal();
  }

  showModal = () => {
    return this.swal({
      title: "Delete?",
      text: `Are you sure you want to delete ${this.isDeleteSingle ? "this" : "these"} data?`,
      icon: "warning",
      // buttons: true,
      buttons: [true, "Yes!"],
      dangerMode: true,
    })
      .then(willDelete => {
        if (willDelete) {
          this.swal("Deleting!", "Please wait...", "info", {
            buttons: false,
            closeOnEsc: false,
            closeOnClickOutside: false,
          });

          if(this.isDeleteSingle){
            return this.deleteSingle();
          } else {
            return this.deleteBulk();
          }
        }
      })
  }

  deleteSingle = async () => {
    try {
      await this.DeleteRequest(this.selectedData.id, this.selectedData)
      this.swal("Deleted!", "Data has been deleted!", "success");
      this.setDefault(); 
    } catch(e) {
      this.swal("Oops!", "Data could not be deleted", "error")
      this.setDefault(); 
    }
  }

  deleteBulk = async () => {
    try {
      await this.DeleteRequest(this.selectedDataArray)
      this.swal("Deleted!", "Data has been deleted!", "success");
      this.setDefault(); 
    } catch(e) {
      this.swal("Oops!", "Data could not be deleted", "error")
      this.setDefault(); 
    }
  }

  setDefault(){
    this.selectedData = null;
    this.selectedDataArray = [];
    this.DeleteRequest = null;
    this.isDeleteSingle = false;
  }

}

const deleteConfirmation = new DeleteConfirmation();

export { deleteConfirmation };
