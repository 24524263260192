import React from 'react';
import compose from 'lodash/fp/compose';
import {
  withTranslation,
  withContactInfo,
  withCountry,
  withCustomRouter,
} from 'components/hoc';
import MyDataContentTemplate from '../../common/MyDataContentTemplate';
import { getFullName, isEmptyValue } from 'components/utils';
import LottieLoader from 'components/common/LottieLoader';

const Contact = (props) => {
  const mapFields = () => {
    const { contactInfo, trObj } = props;

    // const getAddress = (address_detail) => {
    //   return [
    //     trObj(address_detail.country_detail),
    //     trObj(address_detail.state_detail),
    //     address_detail.street_name,
    //     address_detail.street_no,
    //     address_detail.street_no_suffix,
    //   ]
    //     .filter((v) => !isEmptyValue(v))
    //     .join(', ');
    // };

    const getAddress = (address_detail) => {
      const streetDetails = [address_detail.street_name, address_detail.street_no, address_detail.street_no_suffix]
        .filter(v => !isEmptyValue(v))
        .join(" ");
  
      return [
        trObj(address_detail.country_detail),
        trObj(address_detail.city_detail),
        streetDetails,
      ]
        .filter(v => !isEmptyValue(v))
        .join(", ");
    }
  
  
    // const getTelCountryCode = () => {
    //   return  country.filter(({tel_code}) => !!tel_code)
    //   .map(({ tel_code, ...rest }) => ({ id: tel_code, ...rest }))
    // }

    return contactInfo.map((data) => {
      return {
        ...data,
        space_name: data.space,
        _person: getFullName(data.person_detail),
        _contact_type: trObj(data.contact_type_detail),
        _active: data.active ? '\u2713' : '\u2717',
        _address: getAddress(data.address_detail),
        // _tel_country_code: data.tel_country_code
      };
    });
  };

  const {
    deleteBulkContactInfo,
    // deleteContactInfoById,
    gotoCreatePath,
    loaded,
  } = props;

  if (!loaded) {
    return (
      <>
        <LottieLoader />
      </>
    );
  }

  return (
    <React.Fragment>
      {/* {openForm ? (
        <ContactForm />
      ) : (
        <MyDataContentTemplate
          tableName="contact"
          columns={[]}
          data={mapFields()}
          toggleForm={gotoCreatePath}
          bulkDelete={deleteBulkContactInfo}
        />
      )} */}
      <MyDataContentTemplate
        tableName="contact"
        columns={[]}
        data={mapFields()}
        toggleForm={gotoCreatePath}
        bulkDelete={deleteBulkContactInfo}
      />
    </React.Fragment>
  );
};

export default compose(
  withTranslation,
  withContactInfo,
  withCountry,
  withCustomRouter,
)(Contact);
