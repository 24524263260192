import React from 'react';
import { Form as BaseForm, withFormProps } from '../common/Form'
import { Input } from 'components/common/FormItems';

class Form extends BaseForm {

  educationFormSchema = {
    further_training: this.required,
  }

  renderUpperInputs = (state) => {
    return (
      <Input
        onChange={this.onChange}
        value={state.further_training}
        name="further_training"
        label="Description"
        placeholder="Description"
        showRequiredAsterisk
      />
    )
  }
}

export default withFormProps(Form)
