import * as types from '../../../actions/dashboard/finance/types';

const initialState = {
  transactions: [],
  loading: false,
  error: null,
};


export const bankTransactionExtract = (state = initialState, action) => {
  switch (action.type) {
    case types.BANK_TRANSACTIONS_LOADING:
      return { ...state, loading: true, error: null };
      
    case types.BANK_TRANSACTIONS_SUCCESS:
      return { ...state, transactions: action.payload, loading: false };
      
    case types.BANK_TRANSACTIONS_FAILURE:
      return { ...state, error: action.payload, loading: false };
      
    default:
      return state;
  }
};

  // const initialState = {
  //   loading: false,
  //   transactions: [],
  //   error: null,
  // };
  
  // export const bankTransactionExtract = (state = initialState, action = {}) => {
  //   switch (action.type) {
  //     case types.BANK_TRANSACTIONS:
  //       return {
  //         ...state,
  //         transactions: [...state.transactions, action.payload],
  //       };
  
  //     default:
  //       return state;
  //   }
  // }
    // switch (action.type) {
    //   case types.GET_TRANSACTIONS_REQUEST:
    //     return { ...state, loading: true };
  
    //   case types.GET_TRANSACTIONS_SUCCESS:
    //     return { ...state, loading: false, transactions: action.payload };
  
    //   case types.GET_TRANSACTIONS_FAILURE:
    //     return { ...state, loading: false, error: action.payload };
  
    //   default:
    //     return state;
    // }


  