import axios from '../../axios';
import * as types from '../types';

import { setMsgInfo } from '../msgInfo';
import createAction from '../createAction';
import filterError from '../common/filterError';
import API from '../api';

const baseUrl = `${API}/dashboard`;

export function getVaccineHistoryDisease() {
  return (dispatch) => {
    dispatch(
      createAction(types.GET_VACCINE_HISTORY_DISEASE, {
        loading: true,
      })
    );
    return axios
      .get(`${baseUrl}/vaccine_history_disease`)
      .then(({ data }) => {
        dispatch(
          createAction(types.GET_VACCINE_HISTORY_DISEASE, {
            data,
            loading: false,
          })
        );
        return data;
      })
      .catch((err) => {
        let errors;
        if (err.response?.status === 500) {
          errors = 'Server error please try again';
        } else {
          errors = err;
        }
        const errorMessages = filterError(errors);
        dispatch(
          setMsgInfo({
            success: false,
            msg: errorMessages,
          })
        );
        dispatch(
          createAction(types.GET_VACCINE_HISTORY_DISEASE, {
            loading: false,
          })
        );
        return err;
      });
  };
}


export const addVaccineHistoryDisease = (formValues) => async (dispatch) => {
  dispatch({
    type: types.ADD_VACCINE_HISTORY_DISEASE,
    payload: { data: null },
  });
  try {
    const { data } = await axios.post(`${baseUrl}/vaccine_history_disease`, formValues);
    dispatch({
      type: types.ADD_VACCINE_HISTORY_DISEASE,
      payload: { data },
    });
    dispatch(
      setMsgInfo({
        success: true,
        msg: ['Vaccine History Disease added successfully'],
      }),
    );
  } catch (err) {
    dispatch({
      type: types.ADD_VACCINE_HISTORY_DISEASE,
      payload: { data: null },
    });
    let errors;
    if (err.response?.status === 500) {
      errors = 'Server error please try again';
    } else {
      errors = err;
    }
    const errorMessages = filterError(errors);
    dispatch(
      setMsgInfo({
        success: false,
        msg: errorMessages,
      })
    );
    return err;
  }
};

export const getSingleVaccineHistoryDisease = (vaccineHistoryDiseaseId) => async (
  dispatch
) => {
  dispatch({
    type: types.GET_SINGLE_VACCINE_HISTORY_DISEASE,
    payload: { data: null, loading: true },
  });
  try {
    const { data } = await axios.get(
      `${baseUrl}/vaccine_history_disease/${vaccineHistoryDiseaseId}`
    );
    dispatch({
      type: types.GET_SINGLE_VACCINE_HISTORY_DISEASE,
      payload: { data, loading: false },
    });
  } catch (error) {
    dispatch({
      type: types.GET_SINGLE_VACCINE_HISTORY_DISEASE,
      payload: { data: null, loading: false },
    });
  }
};

export const updateVaccineHistoryDisease = (vaccineHistoryDiseaseId, formValues) => async (
  dispatch
) => {
  try {
    const { data } = await axios.patch(
      `${baseUrl}/vaccine_history_disease/${vaccineHistoryDiseaseId}`,
      formValues
    );
    dispatch({
      type: types.UPDATE_VACCINE_HISTORY_DISEASE,
      payload: { data },
    });
    dispatch(
      setMsgInfo({
        success: true,
        msg: ['Vaccine History Disease updated successfully'],
      }),
    );
  } catch (err) {
    let errors;
    if (err.response?.status === 500) {
      errors = 'Server error please try again';
    } else {
      errors = err;
    }
    const errorMessages = filterError(errors);
    dispatch(
      setMsgInfo({
        success: false,
        msg: errorMessages,
      })
    );
    return err;
  }
};

export const deleteVaccineHistoryDiseaseById = (vaccineHistoryDiseaseId) => async (
  dispatch
) => {
  await axios.delete(`${baseUrl}/vaccine_history_disease/${vaccineHistoryDiseaseId}`);
  dispatch({
    type: types.DELETE_VACCINE_HISTORY_DISEASE,
    payload: [vaccineHistoryDiseaseId],
  });
};

export const deleteBulkVaccineHistoryDisease = (vaccineHistoryDiseaseIds) => async (
  dispatch
) => {
  await axios.post(`${baseUrl}/vaccine_history_disease/bulk_delete`, {
    selected_vaccine_history_disease_ids: vaccineHistoryDiseaseIds,
  });
  dispatch({
    type: types.DELETE_VACCINE_HISTORY_DISEASE,
    payload: [...vaccineHistoryDiseaseIds],
  });
};
