import React from "react";
import compose from "lodash/fp/compose";
import { connect } from "react-redux";
// import { withRouter } from "react-router-dom";
import hoistNonReactStatics from 'hoist-non-react-statics'
import * as workspaceActions from "../../../actions/workspace";


export const withWorkspace = (WrapperComponent) => {
  class Enhance extends React.Component {
    render() {
      return (
        <WrapperComponent 
          {...this.props} 
        />
      )
    }
  }
   const mapStateToProps = (state) => ({})
  const WithWorkspace = connect(mapStateToProps, {
    ...workspaceActions,
  })(Enhance);
  hoistNonReactStatics(WithWorkspace, WrapperComponent)
  return compose()(WithWorkspace)
}
