import React from 'react';
import styled from 'styled-components';
import parse from 'html-react-parser';
import { Icon } from 'semantic-ui-react';
import { isEmptyValue } from 'components/utils/checkValue';
import ReactHtmlParser from 'react-html-parser';

const AttSize = styled.span`
  //   color: #777;
  color: var(--admincat-color-grey-11);
  display: inline-block;
  margin-left: 5px;
`;

const FileLink = styled.a`
  margin: 5px 0;
  font-style: italic;
  font-size: 0.9rem;
  display: block;
  color: var(--admincat-color-grey-11);
  &:last-child {
    margin-top: 0;
  }
`;

const MailMessage = styled.div`
  position: relative;
`;

// const TimeDiv = styled.div`
//   font-size: 14px;
//   color: #555;
//   position: absolute;
//   right: 15px;
//   top: 15px;
// `;

const MessageHead = styled.h4`
  color: var(--admincat-color-grey-10);
  font-weight: bold;
  line-height: 32px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  margin-top: ${(props) => props.marginTop || '0%'};
  margin-bottom: ${(props) => props.marginBottom || '0%'};
`;

const MessageBorder = styled.div`
  border-bottom: ${(props) =>
    props.borderBottom || '1px solid var(--admincat-color-grey-2)'};
  padding: 15px;'

`;

const MessageTitle = styled.h3`
  padding: 15px 0px;
  font-size: 18px;
  color: var(--admincat-color-grey-2);
`;

const MessageSub = styled.h5`
  margin: 0px 0px 10px 0px;
  padding: 0px;
  color: #4f81bb;
  font-weight: 400;
  font-size: 14px;

  span {
    // color: #000;
    color: var(--admincat-color-grey-2);
  }
`;

// const MessageBody = styled.div`
//   font-weight: normal;
//   font-size: 18px;

//   ul {
//     margin: 0;
//     padding: 0;
//     list-style: disc;
//   }

//   ol {
//     margin: 0;
//     padding: 0;
//     list-style: decimal;
//   }

//   li {
//     margin-left: 1.5em;
//   }
// `;

const Message = styled.div`
  ${({ html }) => !html && 'white-space: break-spaces'};
  b,
  i {
    // color: black;
    color: var(--admincat-color-grey-2);
  }
`;

const MailLetter = React.forwardRef((props, ref) => {
  const { selectedMail, tr, fd } = props;

  const createMarkup = () => {
    return {
      __html: selectedMail.message_html,
    };
  };

  return (
    <>
      <MailMessage ref={ref}>
        <MessageBorder>
          <MessageHead>
            {fd(selectedMail.updated_at)}{' '}
            {new Date(selectedMail.updated_at).toLocaleTimeString()}
          </MessageHead>
          <MessageTitle>{selectedMail.subject}</MessageTitle>
        </MessageBorder>
        {/* <TimeDiv>
                    {fd(selectedMail.updated_at)} {new Date(selectedMail.updated_at).toLocaleTimeString()}
                </TimeDiv> */}
        <MessageBorder>
          <MessageSub>
            {tr('From')}: <span>{selectedMail.from_mail}</span>
          </MessageSub>
          <MessageSub>
            {tr('To')}: <span> {selectedMail.to_mail}</span>
          </MessageSub>
        </MessageBorder>
        {!isEmptyValue(selectedMail.__attachments) &&
          selectedMail.__attachments.map((attachment, index) => (
            <FileLink
              key={index}
              target="_blank"
              rel="noopener noreferrer"
              href={attachment._file}
            >
              <Icon name="attach" /> {attachment.filename}{' '}
              <AttSize>{attachment.size}</AttSize>
            </FileLink>
          ))}
        <MessageBorder borderBottom="None">
          {(selectedMail.message_html || selectedMail?.message) && (
            <Message html>
              {parse(selectedMail.message_html || selectedMail?.message || '')}
            </Message>
          )}
        </MessageBorder>
      </MailMessage>
    </>
  );
});

export default MailLetter;
