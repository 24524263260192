import React from "react";
import compose from "lodash/fp/compose";
import { connect } from "react-redux";
import hoistNonReactStatics from "hoist-non-react-statics";
import * as personalInfoActions from "actions/dashboard/personalInfo";
import { BaseHOC, withBaseHOCProps } from "../common/BaseHOC";

// let loaded = false;
const initialState = {
  loaded: false,
}

export const withPersonalInfo = (WrappedComponent) => {

  class WithPersonalInfo extends BaseHOC {

    state = { ...initialState }

    async componentDidMount() {
      const { getPersonalInfo } = this.props
      if (!this.state.loaded)  {
        await getPersonalInfo();
        this.setState({...initialState, loaded: true });
        // loaded = true;
      }
    }

    get personalInfoOptions() {
      const { personalInfo } = this.props;
      return personalInfo.data.map(({ id, ...rest }) => {
        return {
          id,
          name: `${rest.first_name} ${rest.last_name}`,
        }
      });
    }

    deletePersonalInfoById = async (data) => {
      const { deletePersonalInfoById } = this.props
      await this._deleteById(deletePersonalInfoById, data);
    }

    deleteBulkPersonalInfo = async (data) => {
      const { deleteBulkPersonalInfo } = this.props
      await this._deleteBulk(deleteBulkPersonalInfo, data);
    }

    getFullName = (person) => {
      return person ? `${person.last_name} ${person.first_name}` : "";
    }

    render() {
      return (
        <WrappedComponent 
          {...this.props} 
          loaded={this.state.loaded}
          personalInfoOptions={this.personalInfoOptions}
          deleteBulkPersonalInfo={this.deleteBulkPersonalInfo}
          deletePersonalInfoById={this.deletePersonalInfoById}
          getFullName={this.getFullName}
        />
      )
    }
  }
  const mapStateToProps = ({ personalInfo }) => ({ personalInfo })
  const PersonalInfo = connect(mapStateToProps, personalInfoActions)(WithPersonalInfo);
  hoistNonReactStatics(PersonalInfo, WrappedComponent)
  return compose(withBaseHOCProps)(PersonalInfo);
}
