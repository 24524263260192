import * as types from "../../actions/types";

const initialState = {
  isLoading: false,
  data: [],
  error: null,
};

const forms = (state=initialState, action={}) => { 
  switch(action.type){ 
    case types.ADD_FORM: {
      return { ...state, data: [...state.data, action.payload], isLoading: false, };
    }
    case types.UPDATE_FORM: {
      return {
        ...state,
        data: state.data.map((data, index) => {
          if(data.id === action.payload.id){
            return action.payload;
          }
          return data;
        }),
        isLoading: false,
      }
    }
    case types.DELETE_FORM: {
      return {
        ...state,
        data: state.data.filter((data, index) => {
          return data.id !== action.payload.id;
        }),
        isLoading: false,
      }    
    }
    case types.DELETE_BULK_FORM: {
      return {
        ...state,
        data: state.data.filter((data, index) => {
          return !action.payload.includes(data.id);
        }),
        isLoading: false,
      }    
    }
    case types.FORM_FILTER_LOADING: {
      return { ...state, isLoading: true, };
    }
    case types.GET_ALL_FORM:
    case types.FORM_FILTER_SUCCESS: {
      return { ...state, data: action.payload, isLoading: false, };
    }
    case types.EMPTY_FORM: {
      return initialState;
    }
    default:
      return state
  }
}

export default forms;
