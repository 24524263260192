import { BaseActionClass } from "../common/BaseActionClass"
import { RESIDENCE_INFO } from '../types'

const path = "/dashboard/residence_info";
const name = "Residence";

class ResidenceInfo extends BaseActionClass {
  
  constructor() { super(path, RESIDENCE_INFO, name) }

  addResidenceInfo = this._add;

  updateResidenceInfo = this._update;

  getResidenceInfo = this._getAll;

  getResidenceInfoById = this._getById;

  deleteResidenceInfoById = this._deleteById;

  deleteBulkResidenceInfo = this._deleteBulk;
}

export const { 
  addResidenceInfo, 
  updateResidenceInfo, 
  getResidenceInfo,
  getResidenceInfoById,
  deleteResidenceInfoById,
  deleteBulkResidenceInfo,
} = new ResidenceInfo()
