import React from 'react';
import compose from 'lodash/fp/compose';
import { connect } from 'react-redux';
import hoistNonReactStatics from 'hoist-non-react-statics';
import * as actions from '../../../actions/dashboard/contactInfo';
import { withDeleteConfirmation } from '..';
import { CONTACT_INFO_FORM, CONTACT_INFO_VIEW } from '../../../actions/types';
import { createAction } from '../../../actions';
import { excludeUnsetField } from '../../utils/excludeUnsetField';

const preloadFields = ['person', 'contact_type', 'active'];
const fieldBasedOnContactType = {
  email: [...preloadFields, 'email'],
  landline: [
    ...preloadFields,
    'tel_country_code',
    'tel_area_code',
    'tel_number',
    'address',
  ],
  mobile: [...preloadFields, 'tel_country_code', 'tel_area_code', 'tel_number'],
  website: [...preloadFields, 'website'],
};

// let loaded = false;

const initialState = {
  loaded: false,
};

export const withContactInfo = (WrappedComponent) => {
  class WithContactInfo extends React.Component {
    state = { ...initialState };

    async componentDidMount() {
      const { getContactInfo } = this.props;
      if (this.state.loaded) return;
      await getContactInfo();
      this.setState({ ...initialState, loaded: true });
      // loaded = true;
    }

    // onSubmit = async (e) => {
    //   const { formSubmitting } = this.state;
    //   const {
    //     addContactInfo,
    //     updateContactInfo,
    //     contactInfoForm,
    //     contactInfoForm: { data: contactInfoFormData },
    //   } = this.props;

    //   if (formSubmitting) return;

    //   let payload = excludeUnsetField(contactInfoFormData);

    //   try {
    //     this.setState({ formSubmitting: true });
    //     if (!contactInfoFormData.id) {
    //       await addContactInfo(payload);
    //     } else {
    //       await updateContactInfo(contactInfoFormData.id, payload);
    //     }
    //     // history.push(basePath);
    //   } catch (e) {
    //   } finally {
    //     this.setState({ formSubmitting: false });
    //   }
    // };

    // onSubmit = (e) => {
    //   const { formSubmitting } = this.state;
    //   const {
    //     addContactInfo,
    //     updateContactInfo,
    //     contactInfoForm,
    //     contactInfoForm: { data: contactInfoFormData },
    //   } = this.props;

    //   if (formSubmitting) return;

    //   const validPayloadFields =
    //     fieldBasedOnContactType[contactInfoFormData?.contact_type || 'email'];

    //   const payload = Object.entries(excludeUnsetField(contactInfoFormData)).reduce(
    //     (accum, [key, value]) => {
    //       if (validPayloadFields.includes(key)) {
    //         accum[key] = value;
    //       }
    //       return accum;
    //     },
    //     {},
    //   );

    //   if (!payload.contact_type) {
    //     payload['contact_type'] = 'email';
    //   }

    //   if (!contactInfoFormData.id) {
    //     this.request(addContactInfo, payload);
    //   } else {
    //     this.request(updateContactInfo, contactInfoFormData.id, payload);
    //   }
    // };

    request = async (action, ...payload) => {
      try {
        this.setState({ formSubmitting: true });
        await action(...payload);
        this.setState({ formSubmitting: false });
        // this.toggleContactInfoForm()
      } catch (err) {
        this.setState({ formSubmitting: false });
      }
    };

    toggleContactInfoView = (data = {}) => {
      const { contactInfoView, setViewData } = this.props;
      data = { data, showView: !contactInfoView.showView };
      setViewData(data);
    };
    toggleContactInfoForm = (data = {}) => {
      const { contactInfoForm, setFormData } = this.props;
      setFormData({ showForm: !contactInfoForm.showForm, data });
    };

    setDataToForm = (data = {}) => {
      const { setFormData } = this.props;
      setFormData({ data, showForm: true });
    };

    onChange = ({ target }) => {
      const { name, value } = target;
      const {
        setFormData,
        contactInfoForm: { data: contactInfoFormData },
      } = this.props;
      const data = { ...contactInfoFormData, [name]: value };
      setFormData({ data, showForm: true });
    };

    deleteContactInfoById = (data) => {
      const { deleteConfirmation, deleteContactInfoById } = this.props;
      deleteConfirmation.init(deleteContactInfoById, data);
    };
    deleteBulkContactInfo = (data) => {
      const { deleteConfirmation, deleteBulkContactInfo } = this.props;
      deleteConfirmation.initBulk(deleteBulkContactInfo, data);
    };
    render() {
      const { setFormData: _, ...rest } = this.props;
      return (
        <WrappedComponent
          {...rest}
          loaded={this.state.loaded}
          contactInfoFormSubmitting={this.state.formSubmitting}
          toggleContactInfoForm={this.toggleContactInfoForm}
          toggleContactInfoView={this.toggleContactInfoView}
          onContactInfoChange={this.onChange}
          onContactInfoSubmit={this.onSubmit}
          setDataToForm={this.setDataToForm}
          deleteBulkContactInfo={this.deleteBulkContactInfo}
          deleteContactInfoById={this.deleteContactInfoById}
        />
      );
    }
  }
  const mapStateToProps = ({ contactInfo, contactInfoForm, contactInfoView }) => {
    return {
      contactInfo,
      contactInfoView,
      contactInfoForm,
    };
  };
  const ContactInfo = connect(mapStateToProps, {
    ...actions,
    setViewData: (data) => createAction(CONTACT_INFO_VIEW, data),
    setFormData: (data) => createAction(CONTACT_INFO_FORM, data),
  })(WithContactInfo);
  hoistNonReactStatics(ContactInfo, WrappedComponent);
  return compose(withDeleteConfirmation)(ContactInfo);
};
