import * as types from "../../actions/types";
import { createTypes } from "../../actions";

const initialState = {
  data: [],
  isLoading: false,
  showForm: false,
  error: null,
};

const criteriaSet = (state=initialState, action={}) => { 
  switch(action.type){ 
    case createTypes(types.GET_CRITERIA_SET).success: 
      return { ...state, data: action.payload, isLoading: false };
    case createTypes(types.ADD_CRITERIA_SET).success: 
      return { 
        ...state, 
        data: [ action.payload, ...state.data ], 
        isLoading: false 
    };
    case createTypes(types.UPDATE_CRITERIA_SET).success: 
      return { 
        ...state, 
        data: state.data.map((_data) => { 
          if(_data.id === action.payload.id) {
            return action.payload;
          }
          return _data;
        }), 
        isLoading: false 
    };
    case createTypes(types.DELETE_CRITERIA_SET_BY_ID).success: 
      return { 
        ...state, 
        data: state.data.filter((_data) => { 
          return _data.id !== action.payload.id;
        }), 
        isLoading: false 
    };
    case types.CRITERIA_SET_FORM: 
      return { ...state, showForm: action.payload };
    case createTypes(types.DELETE_CRITERIA_SET_BY_ID).failure: 
    case createTypes(types.ADD_CRITERIA_SET).failure: 
    case createTypes(types.UPDATE_CRITERIA_SET).failure: 
      return { 
        ...state, isLoading: false, error: action.payload 
      };
    default:
      return state
  }
}

export default { criteriaSet };

