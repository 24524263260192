import React, { Component } from 'react';
import { connect } from "react-redux";
import { Dimmer, Loader } from 'semantic-ui-react'

class LoaderComponent extends Component {
  render() {
    return (
      <Dimmer active={this.props.active} inverted>
        <Loader content='Loading' />
      </Dimmer>
    );
  }
}

function mapStateToProps(state) {
  return {
    active: state.Loader.active,
  }
}

export default connect(mapStateToProps, {})(LoaderComponent);
