// import Home from '../Home/Home';
// import Form from  "../components/Dashboard/ContactUs/Form"
import Dashboard from './Dashboard';

const path = '/dashboard';

export const redirects = [
  {
    from: path,
    to: `${path}`,
  },
  {
    from: `${path}/*`,
    to: `${path}`,
  },
];

export default [
  {
    path: `${path}`,
    icon: '',
    component: Dashboard,
    exact: true,
  },
];
