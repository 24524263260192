import styled from 'styled-components';

export const CheckBoxWrapper = styled.div`
  display: flex;
  margin: 16px 0px;

  label.checkbox {
    width: 17px;
    height: 17px;
    background: #ddd;
    margin-right: 10px;
    transition: all 0.3s;
    position: relative;
    top: 4px;
    left: 10px;
  }

  label {
    cursor: pointer;
  }

  input[type='checkbox']:checked + label.checkbox::before {
    content: '\\2713';
    position: absolute;
    transition: all 0.3s;
    top: -3.2px;
    left: 2px;
    z-index: 10;
    color: #fff;
  }

  input[type='checkbox']:checked + label.checkbox {
    background: #6eb2fb;
  }
`;

export const ModalStyle = `
  .card {
    box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
    background: var(--admincat-color-grey-1);
  }

  {
    display: flex;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 300;
    width: 100%;
    height: 100%;
    padding: 20px;

    background: rgba(0,0,0,0.5);
  }

  div.row {
    height: 100%;
    width: 100%;
  }

  div.body {
    padding: 20px;
    background: #fff;
    // background: var(--admincat-color-grey-1);
    overflow-y: auto;
    border-radius: 5px;
    min-height: 300px;
    height: 70%;
    max-height: 100%;
    margin: auto;
    margin-top: 5%;
    width: 50%;
    position: relative;
    border: 3px solid #739BCB;
  }

  div.body form.card {
    margin-bottom: 0px;
    overflow: auto;
    height: 100%;
  }


  span.exit {
    position: absolute;
    right: 0px;
    top: 0px;
    font-size: 16px;
    z-index: 10;
    color: red;
    width: 18px;
    cursor: pointer;
  }

  @media (max-width: 900px) {
    div.body {
      width: 90%;
    }
  }

  @media (max-width: 500px) {
    div.body {
      width: 100%;
    }
  }
`;
