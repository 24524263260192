import React from "react";
import compose from 'lodash/fp/compose';
import styled from "styled-components";
// import ReactQuill from 'react-quill';
import ReactQuill, {Quill} from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {
  withTranslation,
  withFieldError,
} from "../../../hoc";
import {
  inputStyle,
  InputWrapper,
} from "../Input";
import './style.css';
// import ReactQuill from "react-quill";



// const Quill = ReactQuill.Quill;
// var Font = Quill.import("formats/font");
// // Font.whitelist = ["Roboto", "Raleway", "Montserrat", "Lato", "Rubik"];
// Font.whitelist = ["Roboto", "San Serif", "Arial", "Calibri", "Times New Roman",  "Comic Sans MS", "Georgia"];
// Quill.register(Font, true);

const fontFamilyArr = ["Roboto", "Arial", "Calibri", "Comic Sans MS", "Georgia", "Times New Roman",  "Sans-Serif"];
let fonts = Quill.import("attributors/style/font");
fonts.whitelist = fontFamilyArr;
Quill.register(fonts, true);


const fontSizeArr = ['10px', '12px', '14px', '16px', '18px', '20px'];
var Size = Quill.import('attributors/style/size');
Size.whitelist = fontSizeArr;
Quill.register(Size, true);


const modules = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'], 
    // [{ font:  Font.whitelist }],
    [{ 'size': ['small', false, 'large', 'huge'] }],
    // [{
    //   'size': fontSizeArr
    // }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{
      'font': fontFamilyArr
    }],
    [{ align: [] }],
    [{ color: [] }, { background: [] }],
    [{ script:  "sub" }, { script:  "super" }],
    ["blockquote", "code-block"],
    [{ list:  "ordered" }, { list:  "bullet" }],
    [{ indent:  "-1" }, { indent:  "+1" }],
    ["link", "image"],
    
    ["clean"],
]
};

const formats = [
  "header",
  "font",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "background",
  "code",
  "script",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
];



const StyledTextArea = styled.div`
  height: 150px;
  width: 100%;
`


const TextEditor = compose(
  withFieldError,
  withTranslation,
)((props) => {
  const { className, label, required, name, tr, placeholder, value, onChange, ...rest } = props;

  const onEditorStateChange = (editorState) => {
    onChange({ target: { value: editorState, name }})
  }

  return (
    <InputWrapper className={className}>
      {label && (
        <label className="form-label" htmlFor={name}>
          {tr(label || "")}
          {required && <span className="form-required">*</span>}
        </label>
      )}
    
      <ReactQuill 
        onChange={onEditorStateChange}
        placeholder={tr(placeholder)}
        modules={modules}
        formats={formats}
        theme="snow"
        style={{ 
          height: "70vh"
         }}
        { ...rest }
      >
        {/* <div className="my-editing-area" style={{height: "70vh"}} /> */}
      </ReactQuill>
      
    </InputWrapper>
  )
});

TextEditor.defaultProps = {
  className: '',
}

export default TextEditor;