import React from 'react';
import { Form as BaseForm, withFormProps } from '../common/Form'
import {
  Select,
  Input,
  ColumnalSelect,
} from 'components/common/FormItems';
import { MultiColumns } from '../styles';
import { getProfessionOptions, professionLabels } from './utils';

class Form extends BaseForm {

  educationFormSchema = {
    organisation: this.required,
    profession: this.required,
  }

  get industryOptions() {
    const { organisation } = this.state
    const org = this.favoriteOrgOptions.find(({ id }) => {
      return String(id) === String(organisation)
    });
    return this.industryTypeOptions.filter(({ id }) => {
      return id === org?.industry
    });
  }

  renderUpperInputs = (state) => {
    return (
      <>
        <Select
          onChange={this.onChange}
          value={state.organisation}
          name="organisation"
          label="Organisation"
          optionDefaultValue="Choose Organisation"
          options={this.favoriteOrgOptions}
          showRequiredAsterisk
        />
        <Select
          onChange={this.onChange}
          value={this.industryOptions[0]?.id}
          name="industry"
          label="Industry"
          optionDefaultValue="Industry"
          options={this.industryOptions}
          disabled
          sort={false}
        />
      </>
    )
  }

  renderLowerInputs = (state) => {
    const { trObj } = this.props
    return (
      <>
        <ColumnalSelect
          onChange={this.onChange}
          value={state.profession}
          name="profession"
          label="Profession"
          group
          optionDefaultValue="Choose Profession"
          labels={professionLabels}
          options={getProfessionOptions(this.professionOptions, trObj)}
          showRequiredAsterisk
          sort={false}
        />
        <MultiColumns col={2}>
          <Select
            onChange={this.onChange}
            value={state.degree_achieval}
            name="degree_achieval"
            label="Degree achieval"
            optionDefaultValue="Degree achieval"
            localeOptions={this.degreeOptions}
            sort={false}
          />
          <Input
            onChange={this.onChange}
            value={state.final_grade}
            name="final_grade"
            label="Final grade"
          />
        </MultiColumns>
      </>
    )
  }
}

export default withFormProps(Form)

