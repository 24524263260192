import React from 'react';
import routes, { redirects } from './routes';
import { MyDataDashboardTemplate } from '../common/MyDataDashboardTemplate';
import DocumentWrapper from '../common/DocumentWrapper';
import { withTranslation } from '../../../hoc';

const Health = (props) => (
  <DocumentWrapper formTitle="Health" {...props}>
    <MyDataDashboardTemplate
      {...props}
      showSideBar
      routes={routes}
      redirects={redirects}
      tableName="health"
      render={(renderPropsData) => (
        <MyDataDashboardTemplate.Main {...renderPropsData} card={false} />
      )}
    />
  </DocumentWrapper>
);

export default withTranslation(Health);
