import * as types from "../actions/types";

export default function Loader (state={active: false}, actions={}){ 

  switch(actions.type){ 

    case types.LOADING_ACTIVE:
      return { active: true };

    case types.LOADING_INACTIVE:
      return { active: false };

    default:
      return state

  }
}
