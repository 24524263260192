import { BaseActionClass } from "../common/BaseActionClass"
import { CITIZENSHIP_INFO } from '../types'

const path = "/dashboard/citizenship_info";
const name = "Citizenship";

class CitizenshipInfo extends BaseActionClass {

  constructor() { super(path, CITIZENSHIP_INFO, name) }

  addCitizenshipInfo = this._add;

  updateCitizenshipInfo = this._update;

  getCitizenshipInfo = this._getAll;

  getCitizenshipInfoById = this._getById;

  deleteCitizenshipInfoById = this._deleteById;

  deleteBulkCitizenshipInfo = this._deleteBulk;
}

export const { 
  addCitizenshipInfo, 
  updateCitizenshipInfo, 
  getCitizenshipInfo,
  getCitizenshipInfoById,
  deleteCitizenshipInfoById,
  deleteBulkCitizenshipInfo,
} = new CitizenshipInfo()



