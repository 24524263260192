import React from "react";
import check from "check-types";
import compose from "lodash/fp/compose";
import { countBy } from "lodash";
import hoistNonReactStatics from "hoist-non-react-statics"
import { stringify, reformatDate, reformatDate2, isEmptyValue } from "../../utils"
import { withDateFormat } from "../DateFormat"
import { withUserPreferences } from '../UserPreferences'


const getTime = (dateTime) => Date.parse(dateTime);

const cDate = (date, dateFormat) => {
  const data = reformatDate2(date, dateFormat ).split("-")
  return `${data[2]}/${data[1]}/${data[0]}`
}

const _isDate = (data) => {
  if (!check.string(data)) return false;
  return (
    countBy(data.split(""))["/"] === 2 || 
    countBy(data.split(""))["-"] === 2 || 
    countBy(data.split(""))["."] === 2  
  )
}

const toLowerCase = (str) => str.toLowerCase();

const sortBy = {
  date: {
    isDate: _isDate,
    sort: (a, b) => {
      return a - b;
    },
  },
  boolean: {
    isBoolean: check.boolean,
    sort: (a, b) => a >= b,
  },
  number: {
    isNumber: check.number,
    sort: (a, b) => a - b,
  },
  string: {
    isString: check.string,
    sort: (a, b) => toLowerCase(a) > toLowerCase(b) ? 1 : toLowerCase(a) < toLowerCase(b) ? -1 : 0,
  },
  other: {
    sort: (a, b) => a >= b,
  }
}

const getTHelement = (element) => {
  while (element.tagName !== "TH") {
    element = element.parentElement;
  }
  return element;
}

export const withTableSort = (WrappedComponent) => {
  class TableSort extends React.Component {

    state = { data: [] }
    tempState = { data: [] }
    currentTargetElement = null
    flipped = false
    columnId = null

    componentDidMount() {
      this.updateState()
    }

    componentDidUpdate() {
      this.updateState()
    }

    componentWillUnmount() {
      this.tempState.data = [];
    }

    updateState = () => {
      const { props } = this;
      if (stringify(this.tempState.data) !== stringify(props.data)) {
        this.tempState['data'] = props.data;
        this.setStateAndhandleSort(this.tempState.data)
      }
    }

    onTableHeaderClicked = (columnId) => async (e) => {
      const targetElement = getTHelement(e.target)
      if (targetElement !== this.currentTargetElement) {
        try {
          const p = this.currentTargetElement.getElementsByTagName("p")[0];
          this.currentTargetElement.removeChild(p);
        } catch(e) {}
        // keeping track of the target element
        this.currentTargetElement = targetElement;
        const p = document.createElement("p");
        p.innerHTML = "&#x025BE;";
        this.currentTargetElement.appendChild(p);
        this.flipped = false;
      } else {
        const p = targetElement.getElementsByTagName("p")[0];
        p.innerHTML = !this.flipped ? "&#x025BE;" : "&#x025B4;";
      }
      this.columnId = columnId;
      this.flipped = !this.flipped;
      await this.setStateAndhandleSort(this.state.data);
    }

    setStateAndhandleSort = async (data = []) => {
      let newData = [ ...data ];
      const { dateFormat } = this.props;
      if (this.columnId) {
        newData.sort(this.sortData(this.columnId, dateFormat));
      }
      await this.setState({ data: newData })
    } 

    sortData = (columnId, dateFormat = "DD/MM/YYYY") => (left, right) => {
      let [a, b] = [left[columnId], right[columnId]]
      const isDate = columnId.includes('date');
      const defaultDate = "01/01/1970"
      ;([a, b] = [(isDate && !a ? defaultDate : a), (isDate && !b ? defaultDate : b)]);
      if (this.flipped) {
        ;([b, a] = [a, b]);
      } 
      const { date, boolean, number, string, other } = sortBy
      if (date.isDate(a) && date.isDate(b)) {
        ([a, b] = [cDate(a, dateFormat), cDate(b, dateFormat)]);
        ([a, b] = [getTime(a), getTime(b)]);
        return date.sort(a, b);
      } else if (boolean.isBoolean(a) && boolean.isBoolean(b)) {
        return boolean.sort(a, b);
      } else if (number.isNumber(a) && number.isNumber(b)) {
        return number.sort(a, b);
      } else if (string.isString(a) && string.isString(b)) {
        return string.sort(a, b);
      } else {
        return other.sort(a, b);
      }
    } 
    

    render() {
      return (
        <WrappedComponent 
          {...this.props} 
          data={this.state.data}
          onTableHeaderClicked={this.onTableHeaderClicked}
        />
      )
    }
  }
  hoistNonReactStatics(TableSort, WrappedComponent)
  return compose(withDateFormat, withUserPreferences) (TableSort)
}