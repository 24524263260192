import React from "react";
import { connect } from "react-redux";
import {
  getRelationPart,
} from "../../../actions";
import hoistNonReactStatics from 'hoist-non-react-statics'


let loaded = false;

export const withRelationPart = (WrapperComponent) => {
  class WithRelationPart extends React.Component {
    componentDidMount() {
      const { getRelationPart } = this.props;
      if (loaded) return;
      loaded = true;
      getRelationPart();
    }
    getRelationPartOptions = () => undefined
    getRelationPartLocaleOptions = () => {
      const { relationPart } = this.props;
      return relationPart.map(({ created_at, ...rest }) => ({ ...rest }));
    }
    render() {
      return (
        <WrapperComponent 
          relationPartOptions={this.getRelationPartOptions()} 
          relationPartLocaleOptions={this.getRelationPartLocaleOptions()} 
          {...this.props} 
        />
      )
    }
  }
  const mapStateToProps = ({ relationPart }) => ({ relationPart })
  const WithRelationPartHOC = connect(mapStateToProps, {
    getRelationPart,
  })(WithRelationPart);
  hoistNonReactStatics(WithRelationPartHOC, WrapperComponent)
  return WithRelationPartHOC
}
