//* eslint eqeqeq: 0 */
/* eslint no-mixed-operators: 0 */
import React from "react";
import compose from "lodash/fp/compose";
import { Button, Icon } from "semantic-ui-react";
import styled from "styled-components";
import {
  Input,
  Select,
  Form,
  ColumnalSelect,
  ButtonLink,
} from "components/common/FormItems";
import FlashMessage from "components/common/FormItems/FlashMessage";
import {
  withCustomRouter,
  withTranslation,
  withPersonalInfo,
  withDataArea,
  withDocumentType,
  withLanguage,
  withOrganisation,
  withAttachmentRule,
  withAccount,
} from "components/hoc";
import { isEmptyValue } from "components/utils/checkValue"
import { excludeUnsetField } from "components/utils/excludeUnsetField";
import { getUserPreferences } from 'actions/userPreferences';
import { connect } from 'react-redux';

const Wrapper = styled.div`
`

const StyledButtonLink = styled(ButtonLink)`
  span {
    text-transform: uppercase;
  }
`

const RulesTitle = styled.h6`
  color: ${({ color }) => color || "#000"};
`

export const AddButton = ({ label, onClick, ...rest }) => (
  <Button 
    type="button" 
    icon 
    labelPosition="left" 
    onClick={onClick}
    {...rest}
  >
    {label}
    <Icon name="plus" />
  </Button>
);


const RemoveRuleButton = styled(Button)`
  i.icon {
    color: red !important;
  }
`

const personLabels = [
  {
    first_name: "First Name",
  },
  {
    last_name: "Last Name",
  },
];

const attRuleFormSchema = {
  description: {
    validation: "required|min:3",
  },
  data_area: {
    validation: "required",
  },
};


class AttachmentRule extends React.Component {

  state = {
    rules: [{}],
    submittingForm: false,
    loadingData: false,
  }

  async componentDidMount() {
    const { getAttachmentRuleById, routeParams, dispatch } = this.props;
    const params = routeParams
    
    const pref = await dispatch(getUserPreferences());
    // console.log(pref?.language)
    this.setState({ ...this.state, language: pref?.language });
    // console.log(this.state)

    if (params.id) {
      this.setState({ loadingData: true, language: pref?.language });
      try {
        const { rules, ...rest } = await getAttachmentRuleById(params.id) 
        this.setState({
          ...rest,
          rules: isEmptyValue(rules) ? [{}] : rules,
        })
      } finally {
        this.setState({ loadingData: false });
      }
    }
  }

  onChange = (e) => {
    if (this.formLoading) return;
    const { value, name } = e.target
    let options = {}
    if (name === "data_area") {
      options = { document_type: "" }
    }
    this.setState({ [name]: value, ...options });
  }

  onSubmit = async (e) => {
    const {
      addAttachmentRule,
      updateAttachmentRule,
      gotoDetailPath,
    } = this.props;
    let { id, error, rules, ...formData } = this.state;
    if (this.formLoading) return;
    if (!this.hasValidRules()) {
      this.setState({
        error: "A rule at least, must be provided",
      })
      return;
    }
    // removing empty rule
    rules = rules.filter(this.hasValidRules)
    formData = excludeUnsetField({ ...formData, rules })

    // allow for null values in reference month and reference year if value passed is an empty string //
    formData.reference_month = formData?.reference_month || null ;
    formData.reference_year = formData?.reference_year || null;

    this.setState({ submittingForm: true, error: "" })
    let data = {}
    try {
      if (isEmptyValue(id)) {
        data = await addAttachmentRule(formData);
      } else {
        data = await updateAttachmentRule(id, formData);
      }
      gotoDetailPath(data.id)
    } catch (e) {
      this.setState({ submittingForm: false })
    }
  } 
  
  get formLoading() {
    const { submittingForm, loadingData } = this.state
    return submittingForm || loadingData
  }

  handleAddRule = (e) => {
    if (this.formLoading || !this.hasValidRules()) return;
    this.setState({
      rules: [...this.state.rules, {}]
    })
  }

  handleDeleteRule = (index) => {
    let { rules } = this.state
    if (this.formLoading || rules.length < 2) return;
    rules = rules.filter((_, i) => i != index);
    this.setState({ rules })
  }

  hasValidRules = (rule) => {
    const getLastRule = () => {
      const { rules } = this.state
      const lastIndex = rules.length - 1;
      return rules[lastIndex]
    }
    const { sender, subject, text  } = rule || getLastRule()
    return (
        sender && sender.trim() ||
        subject && subject.trim() ||
        text && text.trim()
    )
  }

  onLineChange = (e, index) => {
    const { rules } = this.state
    if (this.formLoading) return;
    const { value, name } = e.target
    rules[index][name] = value
    this.setState({ rules })
  }

  render() {

    const { state, props } = this;

    const selectedDataArea = props.dataAreaLocaleOptions.find(d => d.id === state.data_area) || {}

    const isUserSpace = props.account.user.space_type === "P"
    const orgOptions = isUserSpace ? props.orgOptions: props.personalOptions; 

    const documentTypeOptions = props.documentTypeLocaleOptions
      .filter((d) => {
        return d.area === selectedDataArea.long_description_eng
      })

    const { basePath, tr } = props;

    return (
      <Wrapper className="form-wrapper">
        <div className="flex">
          <StyledButtonLink
            to={basePath}
            className="option-btn"
            icon="angle double left"
            name="All Entries"
          />
        </div>
        <div className="row">
          <div
            className="col-7 ml-10 mt-30 container"
            style={{
              position: 'relative',
              background: 'var(--admincat-color-grey-1)',
            }}
          >
          <br />

            <Form
              onSubmit={this.onSubmit} 
              formData={state}
              validationSchema={attRuleFormSchema}
              title={tr('Attachment Rule')}
              isLoading={state.submittingForm}
              loadingData={state.loadingData}
            >
              <Input
                name="description"
                value={state.description || ""}
                onChange={this.onChange}
                label={tr('Description')}
                placeholder={tr('Description')}
              />
              {state.rules.map((data, index) => {
                return (
                  <React.Fragment key={index}>
                    <div className="flex space-between">
                      <RulesTitle color="#666">
                        Attern. {index + 1} {' '}
                      </RulesTitle>
                      <RemoveRuleButton 
                        type="button" 
                        className="field-button" 
                        icon 
                        disabled={props.detailMode || state.loadingData}
                        onClick={()=>this.handleDeleteRule(index)}
                      >
                        <Icon name="delete calendar" />
                      </RemoveRuleButton>
                    </div>
                    <Input
                      onChange={(e) => this.onLineChange(e, index)}
                      value={data.sender}
                      name="sender"
                      label={tr('Sender')}
                      placeholder="@me.com;@them.com;domain@you.com"
                      tinyInfo="multiple entries can be delimited with ';' (semicolon)"
                    />
                    <Input
                      onChange={(e) => this.onLineChange(e, index)}
                      value={data.subject}
                      name="subject"
                      label={tr('Subject')}
                      placeholder={tr("Expenditure for this month")}
                    />
                    <Input
                      onChange={(e) => this.onLineChange(e, index)}
                      value={data.text}
                      name="text"
                      label={tr('Text')}
                      placeholder={tr("New successful transanction...")}
                    />
                    <hr />
                  </React.Fragment>
                )
              })}
              <AddButton
                style={{width: 'auto'}}
                label={tr('Add Rule')}
                onClick={this.handleAddRule}
                disabled={props.detailMode || state.loadingData}
              />
              <hr />
              <Select
                name="data_area"
                optionDefaultValue="Select Data Area"
                onChange={this.onChange}
                value={state.data_area || ""}
                label={tr('Data Area')}
                localeOptions={props.dataAreaLocaleOptions}
                sort={false}
              />
              <Select
                name="document_type"
                optionDefaultValue="Select Document Type"
                onChange={this.onChange}
                value={state.document_type || ""}
                label={tr("Document Type")}
                localeOptions={documentTypeOptions}
                disabled={!state.data_area}
              />
              <ColumnalSelect
                onChange={this.onChange}
                value={state.person || ""}
                name="person"
                label={tr("Person")}
                labels={personLabels}
                options={props.personalInfo.data}
              />
              <Select
                onChange={this.onChange}
                value={state.organisation}
                name="organisation"
                label={tr("Organisation")}
                options={orgOptions}
                optionDefaultValue="Organisation"
              />
              <Select
                name="language"
                optionDefaultValue={state.language}
                onChange={this.onChange}
                value={state.language || ""}
                label={tr("Language")}
                options={props.languageOptions}
              />
              <Input
                onChange={this.onChange}
                value={state.reference_year}
                name="reference_year"
                label={tr("Reference Year")}
                placeholder="Reference Year"
              />
              <Input
                onChange={this.onChange}
                value={state.reference_month}
                name="reference_month"
                label={tr("Reference Month")}
                placeholder="Reference Month"
              />
              {state.error && (
                <FlashMessage hasError={state.error}>
                  {state.error}
                </FlashMessage>
              )}
            </Form>   
          </div>
        </div>
      </Wrapper>
    )
  }
}

export default connect()(compose(
  withCustomRouter,
  withTranslation,
  withPersonalInfo,
  withDataArea,
  withDocumentType,
  withLanguage,
  withOrganisation,
  withAttachmentRule,
  withAccount,
)(AttachmentRule));

