import axios from '../../axios';
import * as types from "../types";
import { createTypes } from "../createTypes";
import { setMsgInfo } from "../";
import API from "../api";
import filterError from "../common/filterError";
import { getQueryParams } from '../common/getQueryParams'

const baseUrl = API + "/dashboard/time_dependent_name";

export const addTimeDependentName = (payload) => { 
  return (async (dispatch) => { 
    try {
      const { data } = await axios.post(`${baseUrl}`, payload);
      dispatch({ type: createTypes(types.ADD_TIME_DEPENDENT_NAME).success, payload: data });
      dispatch(setMsgInfo({
        success: true, 
        msg: ["Name added successfully"],
      }))
      return data
    }
    catch(err) {
      const errors = filterError(err);
      dispatch({ type: createTypes(types.ADD_TIME_DEPENDENT_NAME).failure, payload: errors });
      dispatch(setMsgInfo({
        success: false, 
        msg: errors,
      }));
    }
  });
}

export const updateTimeDependentName = (id, payload) => { 
  return (async (dispatch) => { 
    try {
      const { data } = await axios.put(`${baseUrl}/${id}`, payload);
      dispatch({ type: createTypes(types.UPDATE_TIME_DEPENDENT_NAME).success, payload: data });
      dispatch(setMsgInfo({
        success: true, 
        msg: ["Name updated successfully"],
      }))
      return data
    }
    catch(err) {
      const errors = filterError(err);
      dispatch({ type: createTypes(types.UPDATE_TIME_DEPENDENT_NAME).failure, payload: errors });
      dispatch(setMsgInfo({
        success: false, 
        msg: errors,
      }));
    }
  });
}

export const deleteTimeDependentNameById = (id, payload) => { 
  return (async (dispatch) => { 
    try {
      await axios.delete(`${baseUrl}/${id}`);
      dispatch({ type: createTypes(types.DELETE_TIME_DEPENDENT_NAME_BY_ID).success, payload });
      dispatch(setMsgInfo({
        success: true, 
        msg: ["Name deleted successfully"],
      }))
    }
    catch(err) {
      const errors = filterError(err);
      dispatch({ type: createTypes(types.DELETE_TIME_DEPENDENT_NAME_BY_ID).failure, payload: errors });
      dispatch(setMsgInfo({
        success: false, 
        msg: ["Name could not be deleted"],
      }));
    }
  });
}

export const getTimeDependentNames = (query) => { 
  return (async (dispatch) => { 
    try {
      const queryParams = getQueryParams(query)
      const { data } = await axios.get(`${baseUrl}${queryParams}`);
      dispatch({ type: createTypes(types.GET_TIME_DEPENDENT_NAME).success, payload: data });
      return data
    }
    catch(err) {
      const errors = filterError(err);
      dispatch({ type: createTypes(types.GET_TIME_DEPENDENT_NAME).failure, payload: errors });
      dispatch(setMsgInfo({
        success: false, 
        msg: errors,
      }));
    }
  });
}


export const getTimeDependentNameById = (id) => { 
  return (async (dispatch) => { 
    try {
      const { data } = await axios.get(`${baseUrl}/${id}`);
      return data;
    }
    catch(err) {
      const errors = filterError(err);
      dispatch(setMsgInfo({
        success: false, 
        msg: errors,
      }));
    }
  });
}

