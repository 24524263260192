/* eslint-disable */
import React from "react";
import compose from "lodash/fp/compose";
import { 
  withTranslation,
  withCustomRouter,
  withUserPreferences,
  withOrganisation,
} from "components/hoc";
import MyDataContentTemplate from 'components/Dashboard/MyData/common/MyDataContentTemplate';
import LottieLoader from 'components/common/LottieLoader';
import { withMembershipInfo } from "components/hoc/MembershipInfo";

const Membership = (props) => {
  
  const mapFields = () => {
    const { membershipInfo, trObj, fd, fn } = props;
    
    
    return membershipInfo.data.map((data) => {
      
      return { 
        ...data,
        id: data.id, 
        valid_from: fd(data?.valid_from),
        valid_to: fd(data?.valid_to),
        person: data?.person_detail?.first_name + ' ' + data?.person_detail?.last_name,
        organisation: data?.organisation_detail?.name,
      }
    });
  }

  const { 
    loaded,
    deleteBulkMembershipInfo,
    gotoCreatePath,
  } = props;


  if (!loaded) {
    return (
      <>
          <LottieLoader />
      </>)
  }

  return (
    <MyDataContentTemplate 
      tableName="membership"
      columns={[]}
      data={mapFields()}
      handleCreateForm={gotoCreatePath}
      bulkDelete={deleteBulkMembershipInfo}
      // viewName
    />
  )
}

export default compose(
  withTranslation,
  withMembershipInfo,
  withCustomRouter,
  withOrganisation,
  withUserPreferences,
)(Membership);

