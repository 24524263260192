import React from 'react';

/**
 * User Classification IDs
 * These values are determined by the backend and should NOT be modified.
 * Any changes here must be coordinated with the backend to ensure consistency.
 * This values corresond to the communication_type column on the user table
 */
const UserTypes = Object.freeze({
  UNVERIFIED_USERS: 0,
  TRIAL_USERS: 1,
  NON_RENEWED_TRIAL_USERS: 2,
  ACTIVE_SUBSCRIBERS: 3,
  AWAITING_RENEWAL: 4,
});

/**
 * Higher-Order Component (HOC) that provides UserTypes to wrapped components.
 * @param {React.Component} WrappedComponent - The component to be wrapped.
 * @returns {React.Component} A new component with UserTypes injected as a prop.
 */
export const withUserCommunicationTypes = (WrappedComponent) => {
  // Return a new functional component
  return (props) => {
    // Render the wrapped component with all its original props
    // and inject the UserTypes object as an additional prop
    return <WrappedComponent {...props} userTypes={UserTypes} />;
  };
};

export default withUserCommunicationTypes;
