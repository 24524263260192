import axios from '../axios';
import { GET_USER_LAYOUT_CHOICE, UPDATE_USER_LAYOUT_CHOICE } from './types';
import createAction from './createAction';
import { getQueryParams } from './common/getQueryParams';
import API from './api';

const baseUrl = `${API}/user-layout-choice/`;

export function getUserLayoutChoice(query) {
  return async (dispatch) => {
    try {
      const queryParams = getQueryParams(query);
      const { data } = await axios.get(`${baseUrl}${queryParams}`);
      dispatch({
        type: GET_USER_LAYOUT_CHOICE,
        payload: data,
      });
    } catch (error) {
      throw error;
    }
  };
}

export function updateUserLayoutChoice(payload) {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(`${baseUrl}bulk_create/`, payload);
      getUserLayoutChoice()(dispatch);
      // dispatch({
      //   type: UPDATE_USER_LAYOUT_CHOICE,
      //   payload: data,
      // });
    } catch (error) {
      throw error;
    }
  };
}
