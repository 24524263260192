import * as types from "../../actions/types";


const initialState = {
  data: { 
    description: "",
    main_area: "",
    type: "pv",
    jointLines: [],
    tables: [],
  },
  isLoading: false,
  error: null,
};

const getTable = (row) => {
  return { 
    ...row, 
    tableId: row.table_id, 
    zIndex: +row.z_index,
    width: +row.width,
    height: +row.height,
    x: +row.x,
    y: +row.y,
  };
}

const getTables = (tables) => {
  const mappedTables = tables.map(getTable).reduce((accum, curr) => {
    let accumTable = accum[curr.tableId] || []
    accumTable = [...accumTable, curr];
    accum = {...accum, [curr.tableId]: accumTable}
    return accum;
  }, {});
  return Object.values(mappedTables).filter(table => !!table.length);
}

// const getTablesAndMerge = (data) => {
//   return data.reduce((accum, curr) => {
//     const {start_table_row_detail, end_table_row_detail, ...rest} = curr;
//     return [ ...accum, getTable(start_table_row_detail), getTable(end_table_row_detail) ];
//   }, []);
// }

const mapJointLines = (data) => {
  return data.map(({start_table_row_detail, end_table_row_detail, ...rest}) => {
    return {
      id: rest.unique_id,
      x: +rest.x,
      y: +rest.y,
      finalPosX: +rest.final_pos_x,
      finalPosY: +rest.final_pos_y,
      startTableRow: getTable(start_table_row_detail),
      endTableRow: getTable(end_table_row_detail),
      ...rest
    }
  })
}

const joinBuilder = (state=initialState, action={}) => { 
  switch(action.type){ 
    case types.GET_JOIN_BUILDER_BY_ID_SUCCESS: 
      const { join_data_pos, tables_data, ...rest } = action.payload;
      return { 
        ...state, 
        data: { 
          ...state.data, 
          jointLines: mapJointLines(join_data_pos),
          tables: getTables(tables_data),
          ...rest
        }, 
        isLoading: false 
      };
    case types.ADD_JOIN_BUILDER_SUCCESS: 
    case types.UPDATE_JOIN_BUILDER_SUCCESS: 
      return { ...state, data: { ...state.data, ...action.payload }, isLoading: false };
    case types.ADD_JOIN_BUILDER_LOADING: 
    case types.GET_JOIN_BUILDER_BY_ID_LOADING: 
    case types.UPDATE_JOIN_BUILDER_LOADING: 
      return { ...state, isLoading: true };
    case types.ADD_JOIN_BUILDER_FAILURE: 
    case types.GET_JOIN_BUILDER_BY_ID_FAILURE: 
    case types.UPDATE_JOIN_BUILDER_FAILURE: 
      return { ...state, isLoading: false, error: action.payload };
    case types.SET_JOIN_BUILDER_STATE: 
      return { ...state, data: {...state.data, ...action.payload}, };
    default:
      return state
  }
}

const allJoinBuilder = (state={ ...initialState, data: [] }, action={}) => { 
  switch(action.type){ 
    case types.GET_ALL_JOIN_BUILDER_SUCCESS: 
      return { ...state, data: action.payload, isLoading: false };
    case types.DELETE_JOIN_BUILDER_SUCCESS: 
      return { data: state.data.filter((data) => {
        return data.id !== action.payload.id;
      }), isLoading: false };
    case types.DELETE_JOIN_BUILDER_LOADING: 
    case types.GET_ALL_JOIN_BUILDER_LOADING: 
      return { ...state, isLoading: true };
    case types.DELETE_JOIN_BUILDER_FAILURE: 
    case types.GET_ALL_JOIN_BUILDER_FAILURE: 
      return { ...state, isLoading: false, error: action.payload };
    default:
      return state
  }
}

export default { joinBuilder, allJoinBuilder };
