import { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { getContracts, getSingleContract } from '../actions';

let loaded = false;

export const useFetchContracts = (contractId, tableName) => {
  const dispatch = useDispatch();
  const { data, contract } = useSelector((state) => state.contract, shallowEqual);

  useEffect(() => {
    if (data.length) {
      loaded = true;
    }
    if (loaded) return;
    // use this condition to prevent cyclical loading of the contract components
    if (tableName != 'contract'){
      dispatch(getContracts());
    }
  }, [data.length, dispatch]);

  useEffect(() => {
    if (contractId) {
      dispatch(getSingleContract(contractId));
    }
  }, [dispatch, contractId]);

  return {
    contractList: data,
    contract,
  };
};
