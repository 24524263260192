import React from "react";
import { connect } from "react-redux";
import {
  getStreetDirection,
} from "../../../actions";
import hoistNonReactStatics from 'hoist-non-react-statics'


let loaded = false;

export const withStreetDirection = (WrapperComponent) => {
  class WithStreetDirection extends React.Component {
    componentDidMount() {
      const { getStreetDirection } = this.props;
      if (loaded) return;
      loaded = true;
      getStreetDirection();
    }
    getStreetDirectionOptions = () => undefined
    getStreetDirectionLocaleOptions = () => {
      const { streetDirection } = this.props;
      return streetDirection.map(({ created_at, ...rest }) => ({ ...rest }));
    }
    render() {
      return (
        <WrapperComponent 
          streetDirectionOptions={this.getStreetDirectionOptions()} 
          streetDirectionLocaleOptions={this.getStreetDirectionLocaleOptions()} 
          {...this.props} 
        />
      )
    }
  }
  const mapStateToProps = ({ streetDirection }) => ({ streetDirection })
  const WithStreetDirectionHOC = connect(mapStateToProps, {
    getStreetDirection,
  })(WithStreetDirection);
  hoistNonReactStatics(WithStreetDirectionHOC, WrapperComponent)
  return WithStreetDirectionHOC
}
