import React from 'react';
import { compose } from 'lodash/fp';
import TableHeader from './TableHeader';
import TableBody from './TableBody';
import styled from 'styled-components';
import { withTranslation, withTableSort } from '../../hoc';
import { Button } from '../FormItems';

const ActionButton = styled.button`
  padding: 10px 15px;
  border-radius: 4px;
  border: none;
  background-color: #007bff;
  color: white;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

const ContentTemplateWrapper = styled.div`
  div.table-responsive {
    height: auto;
    min-height: 500px;
    max-height: 700px;
    background: var(--admincat-color-grey-1);
  }
`;

const TableWrapper = styled.div.attrs(() => ({
  className: 'table-responsive',
}))`
  height: 250px;

  @media (max-width: 600px) {
    // min-height: 300px;
    // height: 400px;
  }
`;

export const Table = styled.table`
  width: 100%;

  thead,
  tbody {
    white-space: nowrap;
  }

  && th,
  && td {
    height: 36px;
    line-height: 40px;
    padding: 0 1rem;
  }

  th {
    cursor: pointer;
    user-select: none;
    background: ${({ darkTableHeaderBg }) => {
      return darkTableHeaderBg ? 'var(--admincat-color-grey-1)' : '#fff';
    }};

    position: sticky;
    top: 0px;
    z-index: 200;
    p {
      font-size: 1rem;
      display: inline-block;
      transform: translateY(1px);
    }
  }

  tbody tr {
    // background: #fff;
    background: var(--admincat-color-grey-1);
    transition: background 0.4s;
    ${
      '' /* text-align: right;
    padding-right: 33%;   */
    }
  }

  tbody tr:hover {
    background: rgba(0, 0, 0, 0.08);
  }
`;

const Header = styled.header`
  padding: 10px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.h2`
  font-size: 16px;
  color: #9aa0ac;
`;

const ContentTemplate = (props) => {
  const {
    className,
    tr,
    renderTableBody,
    renderTableHeader,
    darkTableHeaderBg,
    raisedTable,
    showHeader = true,
    onActionClick = () => {},
    showActionButton = false,
  } = props;

  return (
    <ContentTemplateWrapper className={className}>
      {(showHeader || props.title) && (
        <Header className="row">
          {props.title && <Title>{tr(props.title)}</Title>}
          {showActionButton && (
            <Button
              actionButton
              name={tr('Make Payment')}
              onClick={() => {
                onActionClick();
              }}
            />
          )}
        </Header>
      )}
      <TableWrapper className={`${raisedTable ? 'card' : ''}`}>
        <Table darkTableHeaderBg={darkTableHeaderBg} className="table">
          <thead>
            <tr>
              {renderTableHeader ? renderTableHeader(props) : props.tableHeader}
            </tr>
          </thead>
          <tbody>{renderTableBody ? renderTableBody(props) : props.tableBody}</tbody>
        </Table>
      </TableWrapper>
    </ContentTemplateWrapper>
  );
};

ContentTemplate.TableHeader = TableHeader;
ContentTemplate.TableBody = TableBody;

ContentTemplate.defaultProps = {
  renderTableHeader: (props) => <ContentTemplate.TableHeader {...props} />,
  renderTableBody: (props) => <ContentTemplate.TableBody {...props} />,
  raisedTable: true,
  darkTableHeaderBg: true,
};

export default compose(withTranslation, withTableSort)(ContentTemplate);
