import React from "react";
import { connect } from "react-redux";
import hoistNonReactStatics from 'hoist-non-react-statics'
import {
  bankAccountActions
} from "../../../actions";

let loaded = false;

export const withBankAccountType = (WrapperComponent) => {
  class Enhance extends React.Component {
    componentDidMount() {
      const { getBankAccountType } = this.props;
      if (!loaded) {
        getBankAccountType()
      }
      loaded = true;
    }
  
    render() {
      return (
        <WrapperComponent 
          {...this.props} 
        />
      )
    }
  }
  const mapStateToProps = ({ bankAccount: {  bankAccountTypes } }) => ({ bankAccountTypes })
  const WithBankAccountType = connect(mapStateToProps, {
    getBankAccountType: bankAccountActions.getBankAccountType
  })(Enhance);
  hoistNonReactStatics(WithBankAccountType, WrapperComponent)
  return WithBankAccountType
}
