import React from 'react';
import { useTranslation } from 'react-i18next';
import compose from 'lodash/fp/compose';
import { withUserPreferences, withTranslation, withAccount } from 'components/hoc';
import styled from 'styled-components';
import './index.css';


const UserTermWrapper = styled.div`
  margin-left: 5rem;
`;

const UserTerms = (props) => {
  const { userPreLang, tr} = props;
  const { t } = useTranslation();
  const { account: {user: { isAuth } }} = props;

  const userLanguage = JSON.parse(localStorage.getItem("userPreferences"))

  React.useEffect(() => {
    window.scroll(0,0);
  }, []);

  const colorModeStyle = {
    color: 'var(--admincat-color-grey-2)',
};
  return (
    <UserTermWrapper>
       {isAuth ? (
      <div className="credits-disclaimer" style={{ backgroundColor: 'var(--admincat-color-grey-4)'}}>
      
      <div className="disclaimer">
        <h1 style={colorModeStyle}>{tr("Terms and Conditions")}</h1>

        <div>
          <h3 style={colorModeStyle}>1. {tr("Scope and changes to these terms of use")}</h3>
          <br/>
          <p className="userterms-content" style={colorModeStyle}>
          1.1. {tr(`These terms of use regulate the service utilization based relationship between the customer and AdminCat GmbH (hereinafter "AdminCat" or "we"), Landgraf-Karl-Str. 21A, 34131 Kassel, Germany (E-mail:`)} <a style={{fontSize:'18px'}} href="mailto:admin@admincat.net">{tr("admin@admincat.net")}</a>) {tr(`as a provider of E-mail, data and document storage services.`)}<br/>
          <br/>
          1.2. {tr("AdminCat reserves the right to change these terms of use as well as the service description and prices. AdminCat shall inform the customer of any changes. If changes are made to the detriment of the customer, he may terminate the user relationship without notice, close his account and have the remaining prepaid amount refunded. AdminCat informs the customer of this right of termination in the notification of change.")}
          </p>
        </div>

        <div>
          <h3 style={colorModeStyle}>2. {tr("Usage relationship")}</h3>
          <br/>
          <p className="userterms-content" style={colorModeStyle}>
          2.1. {tr("AdminCat is an online platform that provides E-mail, document storage and data processing services. The contract for the use of AdminCat is established by an order from the customer in the form of an online registration, for which only an existing e-mail address is required.")}<br/>
          <br/>
          2.2. {tr("After the free trial period of three months, the user can decide to continue using AdminCat by paying a monthly or yearly subscription fee for the service. If the user forgoes the option to subscribe during the trial period, he or she will no longer have access to any Spaces, but will be kept as an inactive Space member as long as there are other paying members assigned to that Space, and the user continues to be able to subscribe and return to actively use AdminCat at a later point in time. However, if such an inactive user isn’t assigned to any Space anymore, he or she will get deleted automatically after a grace period of three months subsequent to the expiration of the last payment period. The user will receive alert notifications via e-mail before accordingly.")}
          </p>
        </div>
     
        <div>
          <h3 style={colorModeStyle}>3. {tr("Services")}</h3>
          <br/>
          <p className="userterms-content" style={colorModeStyle}>
          3.1. {tr("While (and even after) the registration, the customer may create a Space, that includes access to a same name e-mailbox. Every user is free to create up to three Spaces at a time. The creator of a Space automatically becomes its first administrator.")}<br/>
          <br/>
          {tr("Alternatively, a user can join another already existing Space during (and also after) registration, provided that the administrator of this Space approves this new membership request.")}<br/>
          <br/>
          {tr("After the establishment of a Space or the admission to another, the following services are available to the customer")}:<br/>
          <br/>
          <ul>
            -&nbsp;&nbsp; {tr(`One or, if several users have access, a common space e-mail address in the format`)}<br/>
             &nbsp;&nbsp;&nbsp; <a style={{fontSize:'18px'}} href="mailto:desiredname@admincat.net">{tr("desiredname@admincat.net")}</a><br/>
            -&nbsp;&nbsp; {tr("You can create a total of three spaces (including e-mail addresses) yourself")}<br/>
            -&nbsp;&nbsp; {tr("To join other spaces is limitless (of course only with the approval of the individual administrators)")}<br/>
            -&nbsp;&nbsp; {tr("Each space provides 1 GB storage capacity for messages, data and documents")}<br/>
            -&nbsp;&nbsp; {tr("Sending and receiving of e-mails with a total size of up to 20 MB")}<br/>
            -&nbsp;&nbsp; {tr("Upload of individual documents with a size of up to 10 MB and multiple documents at once with a")}<br/>
             &nbsp;&nbsp;&nbsp;  {tr("size of together up to 50 MB")}<br/>
            -&nbsp;&nbsp; {tr("Spam filter: e-mails suspected of being spam are not accepted by our system (reject)")}<br/>
            -&nbsp;&nbsp; {tr("Virus filter: Incoming e-mails that contain a virus are deleted and not delivered to the customer")}<br/>
          </ul>
          
          <br/>
          {tr("If the storage space of a Space is full, receiving messages or storing more documents is disabled. On the Space dashboard, the users can check the allocation of storage space for each Space. An extension of the storage on request is currently not available.")}<br/>
          <br/>
          3.2. {tr("AdminCat is usually available 24 hours a day. However, in cases of force majeure we cannot guarantee the uninterrupted accessibility and availability of all services. Of course we will announce planned maintenance work related downtimes via e-mail and on our website in advance.")}<br/>
          </p>
        </div>
        
        <div>
          <h3 style={colorModeStyle}>4. {tr("Usage fee")}</h3>
          <br/>
          <p className="userterms-content" style={colorModeStyle}>
          4.1. {tr("The use of AdminCat is available free of charge within the three months trial period without any special service restrictions. After that period a monthly or yearly paid subscription interval may be chosen on the user settings page (go to the subscription page to see the current subscription fees).")}
          </p>
        </div>

        <div>
          <h3 style={colorModeStyle}>5. {tr("Obligations of the customer")}</h3>
          <br/>
          <p className="userterms-content" style={colorModeStyle}>
          5.1. {tr("The customer will keep the access password to his account secret and change it immediately or have it changed by AdminCat if unauthorized third parties could have gained knowledge of it.")}<br/>
          <br/>
          5.2. {tr("The customer is also liable for third parties who use or have used services via his account, either authorized or unauthorized. This does not apply if the customer is not responsible for unauthorized use. The customer must provide evidence that he is not responsible for such use.")}<br/>
          <br/>
          5.3. {tr("The customer will not send any advertising by e-mail for commercial purposes via the mail service and will not use the mail service to send standardized e-mails to a large number of recipients.")}<br/>
          </p>
        </div>

        <div>
          <h3 style={colorModeStyle}>6. {tr("Abuse and protection of minors")}</h3>
          <br/>
          <p className="userterms-content" style={colorModeStyle}>
          6.1. {tr("The customer undertakes not to misuse AdminCat")}:<br/>
          <br/>
          <ul>
            - &nbsp;&nbsp;{tr("not to send spam (no more than 100 e-mails per hour and no more than 300 e-mails")}<br/>
              &nbsp;&nbsp;&nbsp;&nbsp;{tr("per day)")}<br/>
            - &nbsp;&nbsp;{tr("not to violate any laws (e.g. sending forbidden or harassing messages)")}<br/>
            - &nbsp;&nbsp;{tr("not to violate regulations for the protection of young people")}<br/>
            - &nbsp;&nbsp;{tr("not to use any mechanisms, software or scripts in connection with the use of the services that could")}<br/>
              &nbsp;&nbsp;&nbsp;&nbsp;{tr("impair or disrupt the functioning of AdminCat")}<br/>
          </ul>
          <br/>
          6.2. {tr("The customer is liable to AdminCat for damage caused by violating his obligations under 6.1., and releases AdminCat from any third party claims in this regard. This does not apply, if he is not responsible for the violation. The customer must provide evidence that he is not responsible for the violation.")}<br/>
          <br/>
          6.3. {tr("If the customer uses AdminCat contrary to the provisions of 6.1 and 5.3, the user account can get blocked by AdminCat without prior notice. The right to terminate the contract without notice remains unaffected.")}

          </p>
        </div>

        <div>
          <h3 style={colorModeStyle}>7. {tr("Privacy")}</h3>
          <br/>
          <p className="userterms-content" style={colorModeStyle}>
          7.1. {tr("AdminCat and the customer will comply with the statutory data protection requirements, in particular according to the Telemedia Act (TMG) and the Federal Data Protection Act (BDSG). Inventory and usage data of the customer are only collected, processed or used insofar as this is necessary for the execution of the contractual relationship.")}<br/>
          <br/>
          7.2. {tr("In order to ensure the service integrity and compliance with the Terms of Use of AdminCat, in cases of reasonable suspicion of misuse AdminCat is entitled to determine polluters and restrict the usage of services for these customers.")}<br/>
          <br/>
          7.3. {tr("Further information on the type, scope and purpose of the collection, processing and use of personal and other data can be found in the Privacy policy of AdminCat.")}
          </p>
        </div>
        <div>
          <h3 style={colorModeStyle}>8. {tr("Warranty and Liability")}</h3>
          <br/>
          <p className="userterms-content" style={colorModeStyle}>
          8.1. {tr("AdminCat encrypts and protects the managed data and documents on the live server as far as this is possible with the current state of the art. With regular backups, we also prevent major data loss from occurring. Development guidelines and internal processes make sure, that new features are thoroughly tested in a test environment and the users data isn’t get compromised by any updates. For the unlikely event of data loss however, we recommend users to keep digital copies on their local systems as a precaution.")}<br/>
          <br/>
          8.2. {tr("AdminCat does not guarantee that all of the services mentioned under item 3 are available and free of errors at all times. This applies in particular if access to these services is caused by disruptions that are beyond the control of AdminCat. We will immediately eliminate disruptions to the services mentioned under item 3 within the scope of the existing technical and operational possibilities.")} <br/>
          <br/>
          8.3. {tr("Any liability claim against us related to a possible data loss suffered or incorrect and/or harmful data processing needs to be documented or reasonably reproducible. Our support team is your first level contact, ready to analyze incidents, give advice or support with quick bug-fixing in case we identify design and processing errors in our responsibility.")}
          </p>
        </div>
        <div>
          <h3 style={colorModeStyle}>9. {tr("Termination, deletion of the account")}</h3>
          <br/>
          <p className="userterms-content" style={colorModeStyle}>
          9.1. {tr("The usage relationship can run for an indefinite period in principle, as long as the user subscribed and paid for the service in advance. It can actively get terminated by the customer at any time – not via email, but by deleting the user account directly under the user settings, in which case we unfortunately can’t provide a reimbursement for the remaining prepaid subscription. The right to terminate the contract without notice in accordance with section 1.2 or for an important reason (sections 5 and 6) remains unaffected.")}<br/>
          <br/>
          9.2. {tr("A deletion removes all sensitive details of a user, such as email, password, its Space memberships and payments. However, for technical reasons, user id and the freely defined user name will remain in the system. Inactive user accounts, whose subscription ended more than three months ago and which are also no longer members of a Space anymore will get deleted automatically by AdminCat.")}<br/>
          <br/>
          9.3. {tr("If the subscription of a Space admin expires, the admin role gets reassigned to any of the remaining active/paying users (if any) automatically, such that the Space continues to have an admin. If Space administrating users decide to actively delete themselves and the admin role was not consciously transferred to another user before, the admin role gets reassigned to any of the remaining active/paying (or in secondary priority inactive/non-paying) users automatically. If there is none or the payment periods of all relevant inactive users ended more than 3 months ago, the Space will get deleted automatically as well.")}
          </p>
        </div>

        <div>
          <h3 style={colorModeStyle}>10.	{tr("Governing Law")}</h3>
          <br/>
          <p className="userterms-content" style={colorModeStyle}>
          {tr('The law of the Federal Republic of Germany applies exclusively to all legal relationships between AdminCat and the customer. The law on the United Nations Convention on the International Sale of Goods ("CISG") does not apply.')}
          </p>
        <br/>
        <br/>
        <div>
          <p className="userterms-content" style={colorModeStyle}>
          {tr("Status: June 2024")}
          </p>
        </div>
        </div>

      </div>
      </div>
       )
       :
      (
      <div className="credits-disclaimer">
      
      <div className="disclaimer">
        <h1>{t("Terms and Conditions")}</h1>

        <div>
          <h3>1. {t("Scope and changes to these terms of use")}</h3>
          <br/>
          <p className="userterms-content">
          1.1. {t(`These terms of use regulate the service utilization based relationship between the customer and AdminCat GmbH (hereinafter "AdminCat" or "we"), Landgraf-Karl-Str. 21A, 34131 Kassel, Germany (E-mail:`)} <a style={{fontSize:'18px'}} href="mailto:admin@admincat.net">{t("admin@admincat.net")}</a>) {t(`as a provider of E-mail, data and document storage services.`)}<br/>
          <br/>
          1.2. {t("AdminCat reserves the right to change these terms of use as well as the service description and prices. AdminCat shall inform the customer of any changes. If changes are made to the detriment of the customer, he may terminate the user relationship without notice, close his account and have the remaining prepaid amount refunded. AdminCat informs the customer of this right of termination in the notification of change.")}
          </p>
        </div>

        <div>
          <h3>2. {t("Usage relationship")}</h3>
          <br/>
          <p className="userterms-content">
          2.1. {t("AdminCat is an online platform that provides E-mail, document storage and data processing services. The contract for the use of AdminCat is established by an order from the customer in the form of an online registration, for which only an existing e-mail address is required.")}<br/>
          <br/>
          2.2. {t("After the free trial period of three months, the user can decide to continue using AdminCat by paying a monthly or yearly subscription fee for the service. If the user forgoes the option to subscribe during the trial period, he or she will no longer have access to any Spaces, but will be kept as an inactive Space member as long as there are other paying members assigned to that Space, and the user continues to be able to subscribe and return to actively use AdminCat at a later point in time. However, if such an inactive user isn’t assigned to any Space anymore, he or she will get deleted automatically after a grace period of three months subsequent to the expiration of the last payment period. The user will receive alert notifications via e-mail before accordingly.")}
          </p>
        </div>
     
        <div>
          <h3>3. {t("Services")}</h3>
          <br/>
          <p className="userterms-content">
          3.1. {t("While (and even after) the registration, the customer may create a Space, that includes access to a same name e-mailbox. Every user is free to create up to three Spaces at a time. The creator of a Space automatically becomes its first administrator.")}<br/>
          <br/>
          {t("Alternatively, a user can join another already existing Space during (and also after) registration, provided that the administrator of this Space approves this new membership request.")}<br/>
          <br/>
          {t("After the establishment of a Space or the admission to another, the following services are available to the customer")}:<br/>
          <br/>
          <ul>
            -&nbsp;&nbsp; {t(`One or, if several users have access, a common space e-mail address in the format`)}<br/>
             &nbsp;&nbsp;&nbsp; <a style={{fontSize:'18px'}} href="mailto:desiredname@admincat.net">{t("desiredname@admincat.net")}</a><br/>
            -&nbsp;&nbsp; {t("You can create a total of three spaces (including e-mail addresses) yourself")}<br/>
            -&nbsp;&nbsp; {t("To join other spaces is limitless (of course only with the approval of the individual administrators)")}<br/>
            -&nbsp;&nbsp; {t("Each space provides 1 GB storage capacity for messages, data and documents")}<br/>
            -&nbsp;&nbsp; {t("Sending and receiving of e-mails with a total size of up to 20 MB")}<br/>
            -&nbsp;&nbsp; {t("Upload of individual documents with a size of up to 10 MB and multiple documents at once with a")}<br/>
             &nbsp;&nbsp;&nbsp;  {t("size of together up to 50 MB")}<br/>
            -&nbsp;&nbsp; {t("Spam filter: e-mails suspected of being spam are not accepted by our system (reject)")}<br/>
            -&nbsp;&nbsp; {t("Virus filter: Incoming e-mails that contain a virus are deleted and not delivered to the customer")}<br/>
          </ul>
          <br/>
          {t("If the storage space of a Space is full, receiving messages or storing more documents is disabled. On the Space dashboard, the users can check the allocation of storage space for each Space. An extension of the storage on request is currently not available.")}<br/>
          <br/>
          3.2. {t("AdminCat is usually available 24 hours a day. However, in cases of force majeure we cannot guarantee the uninterrupted accessibility and availability of all services. Of course we will announce planned maintenance work related downtimes via e-mail and on our website in advance.")}<br/>
          </p>
        </div>
        
        <div>
          <h3>4. {t("Usage fee")}</h3>
          <br/>
          <p className="userterms-content">
          4.1. {t("The use of AdminCat is available free of charge within the three months trial period without any special service restrictions. After that period a monthly or yearly paid subscription interval may be chosen on the user settings page (go to the subscription page to see the current subscription fees).")}
          </p>
        </div>

        <div>
          <h3>5. {t("Obligations of the customer")}</h3>
          <br/>
          <p className="userterms-content">
          5.1. {t("The customer will keep the access password to his account secret and change it immediately or have it changed by AdminCat if unauthorized third parties could have gained knowledge of it.")}<br/>
          <br/>
          5.2. {t("The customer is also liable for third parties who use or have used services via his account, either authorized or unauthorized. This does not apply if the customer is not responsible for unauthorized use. The customer must provide evidence that he is not responsible for such use.")}<br/>
          <br/>
          5.3. {t("The customer will not send any advertising by e-mail for commercial purposes via the mail service and will not use the mail service to send standardized e-mails to a large number of recipients.")}<br/>
          </p>
        </div>

        <div>
          <h3>6. {t("Abuse and protection of minors")}</h3>
          <br/>
          <p className="userterms-content">
          6.1. {t("The customer undertakes not to misuse AdminCat")}:<br/>
          <br/>
          <ul>
            - &nbsp;&nbsp;{t("not to send spam (no more than 100 e-mails per hour and no more than 300 e-mails")}<br/>
              &nbsp;&nbsp;&nbsp;&nbsp;{t("per day)")}<br/>
            - &nbsp;&nbsp;{t("not to violate any laws (e.g. sending forbidden or harassing messages)")}<br/>
            - &nbsp;&nbsp;{t("not to violate regulations for the protection of young people")}<br/>
            - &nbsp;&nbsp;{t("not to use any mechanisms, software or scripts in connection with the use of the services that could")}<br/>
              &nbsp;&nbsp;&nbsp;&nbsp;{t("impair or disrupt the functioning of AdminCat")}<br/>
          </ul>
          <br/>
          6.2. {t("The customer is liable to AdminCat for damage caused by violating his obligations under 6.1., and releases AdminCat from any third party claims in this regard. This does not apply, if he is not responsible for the violation. The customer must provide evidence that he is not responsible for the violation.")}<br/>
          <br/>
          6.3. {t("If the customer uses AdminCat contrary to the provisions of 6.1 and 5.3, the user account can get blocked by AdminCat without prior notice. The right to terminate the contract without notice remains unaffected.")}
          </p>
        </div>

        <div>
          <h3>7. {t("Privacy")}</h3>
          <br/>
          <p className="userterms-content">
          7.1. {t("AdminCat and the customer will comply with the statutory data protection requirements, in particular according to the Telemedia Act (TMG) and the Federal Data Protection Act (BDSG). Inventory and usage data of the customer are only collected, processed or used insofar as this is necessary for the execution of the contractual relationship.")}<br/>
          <br/>
          7.2. {t("In order to ensure the service integrity and compliance with the Terms of Use of AdminCat, in cases of reasonable suspicion of misuse AdminCat is entitled to determine polluters and restrict the usage of services for these customers.")}<br/>
          <br/>
          7.3. {t("Further information on the type, scope and purpose of the collection, processing and use of personal and other data can be found in the Privacy policy of AdminCat.")}
          </p>
        </div>
        <div>
          <h3>8. {t("Warranty and Liability")}</h3>
          <br/>
          <p className="userterms-content">
          8.1. {t("AdminCat encrypts and protects the managed data and documents on the live server as far as this is possible with the current state of the art. With regular backups, we also prevent major data loss from occurring. Development guidelines and internal processes make sure, that new features are thoroughly tested in a test environment and the users data isn’t get compromised by any updates. For the unlikely event of data loss however, we recommend users to keep digital copies on their local systems as a precaution.")}<br/>
          <br/>
          8.2. {t("AdminCat does not guarantee that all of the services mentioned under item 3 are available and free of errors at all times. This applies in particular if access to these services is caused by disruptions that are beyond the control of AdminCat. We will immediately eliminate disruptions to the services mentioned under item 3 within the scope of the existing technical and operational possibilities.")} <br/>
          <br/>
          8.3. {t("Any liability claim against us related to a possible data loss suffered or incorrect and/or harmful data processing needs to be documented or reasonably reproducible. Our support team is your first level contact, ready to analyze incidents, give advice or support with quick bug-fixing in case we identify design and processing errors in our responsibility.")}
          </p>
        </div>
        <div>
          <h3>9. {t("Termination, deletion of the account")}</h3>
          <br/>
          <p className="userterms-content">
          9.1. {t("The usage relationship can run for an indefinite period in principle, as long as the user subscribed and paid for the service in advance. It can actively get terminated by the customer at any time – not via email, but by deleting the user account directly under the user settings, in which case we unfortunately can’t provide a reimbursement for the remaining prepaid subscription. The right to terminate the contract without notice in accordance with section 1.2 or for an important reason (sections 5 and 6) remains unaffected.")}<br/>
          <br/>
          9.2. {t("A deletion removes all sensitive details of a user, such as email, password, its Space memberships and payments. However, for technical reasons, user id and the freely defined user name will remain in the system. Inactive user accounts, whose subscription ended more than three months ago and which are also no longer members of a Space anymore will get deleted automatically by AdminCat.")}<br/>
          <br/>
          9.3. {t("If the subscription of a Space admin expires, the admin role gets reassigned to any of the remaining active/paying users (if any) automatically, such that the Space continues to have an admin. If Space administrating users decide to actively delete themselves and the admin role was not consciously transferred to another user before, the admin role gets reassigned to any of the remaining active/paying (or in secondary priority inactive/non-paying) users automatically. If there is none or the payment periods of all relevant inactive users ended more than 3 months ago, the Space will get deleted automatically as well.")}
          </p>
        </div>

        <div>
          <h3>10.	{t("Governing Law")}</h3>
          <br/>
          <p className="userterms-content">
          {t('The law of the Federal Republic of Germany applies exclusively to all legal relationships between AdminCat and the customer. The law on the United Nations Convention on the International Sale of Goods ("CISG") does not apply.')}
          </p>
        <br/>
        <br/>
        <div>
          <p className="userterms-content">
          {t("Status: June 2024")}
          </p>
        </div>
        </div>

      </div>
      </div>
      )
}
    </UserTermWrapper>
  );
};

export default compose(withTranslation, withUserPreferences, withAccount)(UserTerms);
