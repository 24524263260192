import axios from '../../../axios';
import * as types from './types';

import { setMsgInfo, createAction } from '../..';
import filterError from '../../common/filterError';
import API from '../../api';
import { account } from 'reducers/account';

const baseUrl = `${API}/dashboard`;

function getBankAccountType() {
  return (dispatch) =>
    axios
      .get(`${API}/bank-account-type`)
      .then((data) => {
        dispatch(createAction(types.GET_BANK_ACCOUNT_TYPES, data.data));
        return data;
      })
      .catch((err) => {
        throw err;
      });
}

function getBankAccessType() {
  return (dispatch) =>
    axios
      .get(`${API}/bank-access-type`)
      .then((data) => {
        dispatch(createAction(types.GET_BANK_ACCESS_TYPES, data.data));
        return data;
      })
      .catch((err) => {
        throw err;
      });
}

function getOrganizationWithBIC(bic) {
  return (dispatch) => {
    dispatch(createAction(types.GET_ORGANIZATION_WITH_BIC_STARTED));
    return axios
      .get(`${API}/get-org-from-bic/${bic}`)
      .then((data) => {
        dispatch(createAction(types.GET_ORGANIZATION_WITH_BIC, data.data));
        return data;
      })
      .catch((err) => {
        dispatch(createAction(types.GET_ORGANIZATION_WITH_BIC, null));
        throw err;
      });
  };
}

function createBankAccount(formData) {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(`${baseUrl}/bank_account`, formData);
      console.log('data bank account', data);
      dispatch(createAction(types.CREATE_BANK_ACCOUNT, data));
      dispatch(
        setMsgInfo({
          success: true,
          msg: ['Bank Account created successfully'],
        }),
      );
      dispatch(createAction(types.BANK_ACCOUNT_FORM, { data: {}, showForm: false }));
      return false;
    } catch (err) {
      const errors = filterError(err);
      dispatch(
        setMsgInfo({
          success: false,
          msg: errors,
        }),
      );
      return err;
    }
  };
}

function getAllBankAccount() {
  return (dispatch) =>
    axios
      .get(`${baseUrl}/bank_account`)
      .then((data) => {
        dispatch(createAction(types.GET_ALL_BANK_ACCOUNT, data.data));
        return data;
      })
      .catch((err) => {
        throw err;
      });
}

function deleteBankAccountById(id) {
  return (dispatch) =>
    axios
      .delete(`${baseUrl}/bank_account/${id}`)
      .then((response) => {
        dispatch(createAction(types.DELETE_BANK_ACCOUNT, id));
        return response;
      })
      .catch((err) => {
        throw err;
      });
}

function deleteBulkBankAccount(ids) {
  return (dispatch) =>
    axios
      .post(`${baseUrl}/bank_account/bulk_delete`, { ids })
      .then((response) => {
        dispatch(
          setMsgInfo({
            success: true,
            msg: ['Bank Account deleted successfully'],
          }),
        );
        dispatch(createAction(types.DELETE_BULK_BANK_ACCOUNT, ids));
        return response;
      })
      .catch((err) => {
        throw err;
      });
}


// export const getBankAccountById = (id) => async (dispatch) => {
//   try {
//     dispatch(createAction(types.BANK_ACCOUNT_LOADER));
//     const { data } = await axios.get(`${baseUrl}/bank_account/${id}`);
//     dispatch(createAction(types.GET_BANK_ACCOUNT_BY_ID, data));
//     dispatch(createAction(types.BANK_ACCOUNT_LOADER));
//   } catch (err) {
//     dispatch(createAction(types.BANK_ACCOUNT_LOADER));
//     throw err;
//   }
// };


// export const getBankAccountById = (id) => async (dispatch) => {
//   try {
//     dispatch(createAction(types.BANK_ACCOUNT_LOADER));
//     const { data } = await axios.get(`${baseUrl}/bank_account/${id}`);
//     dispatch(createAction(types.GET_BANK_ACCOUNT_BY_ID, data));
//     dispatch(createAction(types.BANK_ACCOUNT_LOADER));
//   } catch (err) {
//     dispatch(createAction(types.BANK_ACCOUNT_LOADER));
//     throw err;
//   }
// };


const getBankAccountById = (id) => async(
  dispatch
) => {
  dispatch({
      type: types.GET_BANK_ACCOUNT_BY_ID,
      payload: { data: null, loading: true },
  });
  try {
      const { data } = await axios.get(
          `${baseUrl}/bank_account/${id}`
      );
      dispatch({
          type: types.GET_BANK_ACCOUNT_BY_ID,
          payload: { data, loading: false },
      });
  } catch (error) {
      dispatch({
          type: types.GET_BANK_ACCOUNT_BY_ID,
          payload: { data: null, loading: false },
      });
  }
};

const updateBankAccount = (updateData, id) => async (dispatch) => {
  try {
    const { data } = await axios.patch(`${baseUrl}/bank_account/${id}`, updateData);
    dispatch(createAction(types.UPDATE_BANK_ACCOUNT, data));
    dispatch(
      setMsgInfo({
        success: true,
        msg: ['Bank Account updated successfully'],
      }),
    );
    dispatch(createAction(types.BANK_ACCOUNT_FORM, { data: {}, showForm: false }));
    return false;
  } catch (err) {
    const errors = filterError(err);
    dispatch(
      setMsgInfo({
        success: false,
        msg: errors,
      }),
    );
    return err;
  }
};

export const bankAccountActions = {
  deleteBankAccountById,
  getAllBankAccount,
  createBankAccount,
  getOrganizationWithBIC,
  getBankAccountType,
  getBankAccessType,
  deleteBulkBankAccount,
  getBankAccountById,
  updateBankAccount,
};
