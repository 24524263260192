import React from 'react';
import { isEmptyValue } from 'components/utils'

export const withBulkDelete = (WrappedComponent) => {
  class WithBulkDelete extends React.Component {
    state = { ids: [] };

    isChecked = (id) => {
      const { ids } = this.state;
      return ids.includes(id);
    };

    onChecked = async (rowId) => {
      let { ids } = this.state;
      if (ids.includes(rowId)) {
        ids = ids.filter((id) => id !== rowId);
      } else {
        ids = [...ids, rowId];
      }
      await this.setState({ ids });
    };

    render() {

      const { hasBulkDelete } = this.props
      const useBulkDelete = !isEmptyValue(hasBulkDelete) ? hasBulkDelete : true

      return (
        <WrappedComponent
          {...this.props}
          hasBulkDelete={useBulkDelete}
          rowIds={this.state.ids}
          onRowChecked={this.onChecked}
          isRowChecked={this.isChecked}
        />
      );
    }
  }

  return WithBulkDelete;
};
