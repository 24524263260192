import {
  UPDATE_USER_PREFERENCES,
  GET_USER_PREFERENCES,
  USER_PREFERENCES_LOADER,
} from "../actions/types";

const initialState = {
  language: "",
  dateFormat: "",
  updating: false,
};

export default function userPreferences(state = initialState, action = {}) {
  switch (action.type) {
    case UPDATE_USER_PREFERENCES: {
      return {
        ...state,
        ...action.payload,
        isLoading: false,
      };
    }

    case USER_PREFERENCES_LOADER:
      return {
        ...state,
        isLoading: !state.isLoading,
      };

    case GET_USER_PREFERENCES: {
      return {
        ...state,
      
      };
    }

    default:
      return state;
  }
}
