import React from 'react';
import compose from "lodash/fp/compose";
import {
  withOrganisation,
  withAllOrganisationWorkspace,
} from "../../hoc";
import { 
  StyledSwitchery,
} from ".";
import {
  Input as AuthInput,
  Select as AuthSelect,
} from "../AuthFormItems";

class Organisation extends React.Component {

  onChangeOrgUserType = () => {
    const { newOrganisationUserType, onChange } = this.props;
    onChange()({
      newOrganisationUserType: !newOrganisationUserType,
    })
  }

  render(){
    const { props } = this;
    const { 
      onChange,
      Input = AuthInput,
      Select = AuthSelect,
    } = props;
    return(
      <React.Fragment>
        <StyledSwitchery 
          name="newOrganisationUserType" 
          id="organisationUserType.joinOrg"
          value={!props.newOrganisationUserType}
          onChange={this.onChangeOrgUserType}
          label="Join Organization Workspace"
        />
        <StyledSwitchery 
          name="newOrganisationUserType" 
          id="organisationUserType.createOrg"
          value={props.newOrganisationUserType}
          onChange={this.onChangeOrgUserType}
          label="Create Organization Workspace"
        />
        { 
          !props.newOrganisationUserType ? ( 
              <Input 
                icon="users"
                onChange={onChange}
                value={props.join_org_workspace || ""}
                name="join_org_workspace"
                optionDefaultValue="Choose Workspace"
                label="Choose Workspace"
                required
              />
          ) : (
            <React.Fragment>
              {/* <Select 
                icon="building"
                onChange={onChange}
                value={props.org_id}
                name="org_id"
                optionDefaultValue="Choose Organization"
                label="Choose Organization"
                required
                options={props.orgOptions}
              /> */}
              <Input 
                icon="users"
                onChange={onChange}
                value={props.create_org_workspace}
                name="create_org_workspace"
                label="Create Workspace"
                placeholder="Create Workspace"
                required
              />
            </React.Fragment>
          )
        }
      </React.Fragment>
    )
  }
}


export default compose(
  withAllOrganisationWorkspace,
  withOrganisation,
)(Organisation);
