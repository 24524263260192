import React from "react";
import { connect } from "react-redux";
import {
  getNumberFormat,
} from "actions";
import hoistNonReactStatics from 'hoist-non-react-statics'


let loaded = false;

export const withNumberFormat = (WrapperComponent) => {
  class WithNumberFormat extends React.Component {
    componentDidMount() {
      const { getNumberFormat } = this.props;
      if (loaded) return;
      loaded = true;
      getNumberFormat();
    }
    getNumberFormatOptions = () => undefined
    getNumberFormatLocaleOptions = () => {
      const { numberFormat } = this.props;
      return numberFormat.map(({ created_at, ...rest }) => ({ ...rest }));
    }
    render() {
      return (
        <WrapperComponent 
          numberFormatOptions={this.getNumberFormatOptions()} 
          numberFormatLocaleOptions={this.getNumberFormatLocaleOptions()} 
          {...this.props} 
        />
      )
    }
  }
  const mapStateToProps = ({ numberFormat }) => ({ numberFormat })
  const WithNumberFormatHOC = connect(mapStateToProps, {
    getNumberFormat,
  })(WithNumberFormat);
  hoistNonReactStatics(WithNumberFormatHOC, WrapperComponent)
  return WithNumberFormatHOC
}
