import Form from "./Form";
const path = "/dashboard/contact-us";

export const redirects = [
  {
    from: path,
    to: `${path}`
  },
  {
    from: `${path}/*`,
    to: `${path}`
  },
]

export default [
  {
    path: `${path}`,
    icon: "",
    component: Form,
    exact: true
  },
]
