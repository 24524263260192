import React from 'react';
import styled from 'styled-components';

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const PageHeader = ({ leftTitle, rightIcon }) => {
  return (
    <Header>
      {leftTitle}
      {rightIcon}
    </Header>
  );
};

export default PageHeader;
