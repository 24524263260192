import * as types from "../../actions/types";

const initialState = {
  data: {},
  showDetail: false,
  showForm: false,
};

export const fieldDetail = (state=initialState, action={}) => { 
  switch(action.type){
    case types.FIELD_DETAIL:
      const { data, showDetail } = action.payload;
      return {
        ...state,
        data: data || state.data,
        showDetail: showDetail,
      };
    default: return state;
  }
}

export const fieldForm = (state=initialState, action={}) => { 
  switch(action.type){
    case types.FIELD_FORM:
      const { data, showForm } = action.payload;
      return {
        ...state,
        data: data || state.data,
        showForm: showForm,
      };
    default: return state;
  }
}


export const fields = (state=[], actions={}) => { 
  switch(actions.type){ 
    case types.ADD_FIELDS: {
      return [
        actions.payload,
        ...state,
      ]
    }
    case types.UPDATE_FIELDS: {
      return state.map((data, index) => {
        if(data.id === actions.payload.id){
          return actions.payload;
        }
        return data;
      });
    }
    case types.UPDATE_FIELD_OPTION: {
      return state.map((data, index) => {
        if(data.field === actions.payload.field){
          return actions.payload;
        }
        return data;
      });
    }
    case types.DELETE_FIELDS: {
      return state.filter((data, index) => {
        return data.id !== actions.payload.id;
      });
    }
    case types.DELETE_BULK_FIELDS: {
      return state.filter((data, index) => {
        return !actions.payload.includes(data.id);
      });
    }
    case types.GET_FIELDS: {
      return state.find((data, index) => {
        return data.id === actions.payload.id;
      });
    }
    case types.GET_FORM_FIELDS: {
      return actions.payload;
    }
    case types.GET_ALL_FIELDS: {
      return actions.payload;
    }
    case types.EMPTY_FIELDS: {
      return actions.payload;
    }
    default:
      return state
  }
}
