import React from "react";
import compose from "lodash/fp/compose";
import { connect } from "react-redux";
import hoistNonReactStatics from "hoist-non-react-statics";
import { getSchoolGraduation } from "actions/preload";
import { BaseHOC, withBaseHOCProps } from "../common/BaseHOC"

let loaded = false

export const withSchoolGraduation = (WrappedComponent) => {
  class SchoolGraduation extends BaseHOC {

    async componentDidMount() {
      const { getSchoolGraduation } = this.props
      if (!loaded)  {
        loaded = true;
        await getSchoolGraduation();
      }
    }

    get schoolGraduationOptions() {
      const { schoolGraduation } = this.props;
      return this._getOptions(schoolGraduation, ['id'], true)
    }

    getSchoolGraduationByType = (type) => {
      const { schoolGraduation } = this.props;
      const options = schoolGraduation.filter(({ type: sType }) => {
        return sType === type
      })
      return this._getOptions(options, ['id'], true)
    }

    render() {
      return (
        <WrappedComponent 
          {...this.props} 
          schoolGraduationOptions={this.schoolGraduationOptions}
          getSchoolGraduationByType={this.getSchoolGraduationByType}
        />
      )
    }
  }
  const mapStateToProps = ({ schoolGraduation }) => ({ schoolGraduation })
  const WithSchoolGraduation = connect(mapStateToProps, { getSchoolGraduation })(SchoolGraduation);
  hoistNonReactStatics(WithSchoolGraduation, WrappedComponent)
  return compose(withBaseHOCProps)(WithSchoolGraduation);
}
