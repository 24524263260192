import { BaseActionClass } from './common/BaseActionClass';
import { ORGANISATION_PROPOSAL } from './types';

const path = '/organisation-proposal/';
const name = 'Organisation Proposal';

class OrganisationProposal extends BaseActionClass {
  constructor() {
    super(path, ORGANISATION_PROPOSAL, name);
  }

  addOrganisationProposal = this._add;
  getOrganisationProposal = this._getAll;
}

export const { addOrganisationProposal, getOrganisationProposal } =
  new OrganisationProposal();
