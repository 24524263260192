import Locale from "../../../locale/locale";
import React,  { Component } from "react";
import { NavLink } from "react-router-dom";

class Header extends Component{
  render(){
    return (
      <header className="_header">
        <div className="header-top">
          <div className="container">
            <span className="block title">
              <Locale value="std.ctrlrm" />
            </span>
            <span className="block input">
              <input 
                className="input" 
                placeholder="Fill-out area"
              />
            </span>
          </div>
        </div>
      </header>
    )
  }
}

export default Header;
