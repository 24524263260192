import React, { Component }  from 'react';
import styled from "styled-components";



const H5Div = styled.h5`
    font-weight: normal;
    font-size:1.5em;
    color: ${props => props.color ? props.color : "#666"};
    text-align: ${props => props.align ? props.align : 'left'};
    font-weight: ${props => props.fontWeight ? props.fontWeight : 'normal'};
    font-family: ${props => props.fontFamily ? props.fontFamily : 'poppins'};
    margin-bottom:10px;
    @media only screen and (max-width:750px){
        font-size:1em;
    }
`

const H5 = (props) => {
    const { children, color, align, ...rest } = props
    return <H5Div color={color} align={align} {...rest}>{children}</H5Div>
}

export default H5