import React from 'react';
import { withUserPreferences, withTranslation, withAccount } from 'components/hoc';
import { useTranslation } from 'react-i18next';
import compose from 'lodash/fp/compose';
import styled from 'styled-components';
import './index.css';


const PrivacyStatementWrapper = styled.div`
  margin-left: 5rem;
`;

const PrivacyStatement = (props) => {
  const { userPreLang, tr} = props;
  const { t } = useTranslation();
  const { account: {user: { isAuth } }} = props;

  
  // const userLanguage = JSON.parse(localStorage.getItem("userPreferences"))
  // // This change the language to default langage after login
  // const changeLanguageToDefault = (value) => {
  //   if(userLanguage && userLanguage.language === "deu"){
  //       localStorage.setItem("i18nextLng", "deu")
  //   }else{
  //       localStorage.setItem("i18nextLng", "eng")
  //   }
  // };

  React.useEffect(() => {
    window.scroll(0,0);
  }, []);

  const colorModeStyle = {
    color: 'var(--admincat-color-grey-2)',
};

const getMarginLeft = (text) => {
  const length = text.length;
  if (length == 3) return '28px';  
  return '6px';
};
 
  return (
    <PrivacyStatementWrapper>
      {isAuth ? (
        <div className="credits-disclaimer" style={{ backgroundColor: 'var(--admincat-color-grey-4)'}}>
          <div className="disclaimer">
            <h1 style={colorModeStyle}>{tr("Privacy Statement")}</h1>
            <div>
              <p style={colorModeStyle}>
              {tr("Thank you for your interest in our online service. The protection of your data is very important to us. We therefore apply the utmost care and state-of-the-art security standards to ensure maximum protection of your data. Below we will explain in detail, which data AdminCat is processing and how it protects this data.")}
              </p>
            </div>
            <div>
              <h3 style={colorModeStyle}>1.	{tr("The person responsible for data processing is")}:</h3>
              <p className="privacy-content" style={colorModeStyle}>
                AdminCat GmbH<br/>
                Landgraf-Karl-Str.21A <br /> 
                34131 Kassel<br/>
                Germany
              </p>
              <p className="privacy-content" style={colorModeStyle}>
              <span>{tr("E-Mail")}:</span> <a href="mailto:admin@admincat.net" style={{ fontSize: '18px' }}>admin@admincat.net</a>
              </p>
            </div>

            <div>
              <h3 style={colorModeStyle}>2.	{tr("Legal basis")}</h3>
              <p className="privacy-content" style={colorModeStyle}>
              {tr("The provision of our services requires the processing of data and takes place in accordance with the statutory provisions. For data protection, the General Data Protection Regulation (GDPR), the applicable Federal Data Protection Act (BDSG), the Telemedia Act (TMG) and telecommunications secrecy are decisive. AdminCat's services are hosted exclusively on servers in Germany (Frankfurt am Main).")}
              </p>
            </div>
        
          <div >
          
              <h3 style={colorModeStyle}>3.	{tr("Terms")}</h3>
              <p className="privacy-content" style={colorModeStyle}>
              {tr("The legislator requires that personal data be processed in a lawful manner, in good faith and in a manner that is understandable for the person concerned ('lawfulness, processing in good faith, transparency'). To ensure this, we inform you about the individual legal definitions that are also used in this data protection declaration")}:
              </p>
            
            <div>
              <h4 style={colorModeStyle}>(a) {tr("Personal data")}   </h4>
              <p className="privacy-content" style={colorModeStyle}>
              {tr('"Personal data" is all information that relates to an identified or identifiable natural person (hereinafter “data subject”); A natural person is regarded as identifiable who can be identified directly or indirectly, in particular by means of assignment to an identifier such as a name, an identification number, location data, an online identifier or one or more special features that express the physical, physiological, genetic, psychological, economic, cultural or social identity of this natural person.')}
              </p>
            </div>
            
            <div>
              <h4  style={colorModeStyle}>(b) {tr("Processing")}   </h4>
              <p className="privacy-content"  style={colorModeStyle}>
              {tr('"Processing" is any process carried out, with or without the help of automated processes, or any such series of processes in connection with personal data such as the collection, recording, organization, ordering, storage, adaptation or change, reading out, querying, use, disclosure by transmission, distribution or any other form of provision, comparison or linking, restriction, deletion or destruction.')}
              </p>
            </div>


            <div>
              <h4  style={colorModeStyle}>(c) {tr("Restriction of processing")}   </h4>
              <p className="privacy-content"  style={colorModeStyle}>
              {tr('"Restriction of processing" is the marking of stored personal data with the aim of restricting their future processing.')}
              </p>
            </div>

            <div>
              <h4 style={colorModeStyle}>(d) {tr("Profiling")}   </h4>
              <p className="privacy-content" style={colorModeStyle}>
              {tr('"Profiling" is any type of automated processing of personal data that consists of using this personal data to evaluate certain personal aspects relating to a natural person, in particular aspects relating to work performance, economic situation, health,to analyze or predict personal preferences, interests, reliability, behavior, whereabouts or relocation of this natural person.')}
              </p>
            </div>

            <div>
              <h4 style={colorModeStyle}>(e) {tr("Pseudonymization")}   </h4>
              <p className="privacy-content" style={colorModeStyle}>
              {tr('"Pseudonymization" is the processing of personal data in such a way that the personal data can no longer be assigned to a specific data subject without the use of additional information, provided that this additional information is stored separately and is subject to technical and organizational measures that ensure that the personal data cannot be assigned to an identified or identifiable natural person.')}
              </p>
            </div>

            <div>
              <h4 style={colorModeStyle}>(f) {tr("File system")}   </h4>
              <p className="privacy-content" style={colorModeStyle}>
              {tr('"File system" is any structured collection of personal data that is accessible according to certain criteria, regardless of whether this collection is managed centrally, decentralized or according to functional or geographical criteria')}
              </p>
            </div>

            <div>
              <h4 style={colorModeStyle}>(g) {tr('Person responsible')}   </h4>
              <p className="privacy-content" style={colorModeStyle}>
              {tr('"Person responsible" is a natural or legal person, authority, institution or other body that alone or jointly with others decides on the purposes and means of processing personal data; If the purposes and means of this processing are specified by Union law or the law of the member states, the person responsible or the specific criteria for his appointment can be provided for in accordance with Union law or the law of the member states.')}
              </p>
            </div>

            <div>
              <h3 style={colorModeStyle}>4.	{tr("Access Data")}</h3>
              <p className="privacy-content" style={colorModeStyle}>
                {tr("AdminCat collects data and information, some of which is personal data, each time the website is accessed. This data is stored in the server log files. The following can be recorded")}:
              </p>
              <p className="privacy-content" style={colorModeStyle}>
              <ul>
                <li> {tr("Web request and interaction with a service, e.g. PayPal")}</li>
                <li> {tr("The Internet Protocol address (IP address)")}</li>
                <li> {tr("Browser type and language of the browser")} </li>
                <li> {tr("Date and time of access to our website")}</li>
                <li> {tr("Time zone difference to Greenwich Mean Time (GMT)")}</li>
                <li> {tr("The email address of a Space, if a user is logged in there")}</li>
                <li> {tr("Access status/HTTPS status code")}</li>
              </ul>
              </p>
              <p className="privacy-content" style={colorModeStyle}>
              {tr("The legal basis for data processing is Art. 6 (1) p. 1 lit. f GDPR. Our legitimate interest is based on the following purposes for data collection")}:
              </p>
              <p className="privacy-content" style={colorModeStyle}>
              <ul>
                <li> {tr("Ensuring a smooth connection to the website")}</li>
                <li> {tr("Ensuring a convenient use of our website")}</li>
                <li> {tr("Evaluation of system security and stability, and")} </li>
                <li> {tr("For other administrative purposes")} </li>
              </ul>
              </p>

              <p className="privacy-content" style={colorModeStyle}>
                {tr("After the end of the communication process, the collected information is only evaluated for statistical purposes and in anonymized form. Under no circumstances do we use the data collected for the purpose of drawing conclusions about your person.")}
              </p>
            </div>

            <div>
              <h3  style={colorModeStyle}>5.	{tr("Cookies")}</h3>
              <p className="privacy-content" style={colorModeStyle}>
              {tr("AdminCat doesn't use any cookie. Therefore we also don't provide an integrated cookie consent tool on our site, which usually allows you to administrate your opt-in choices related to certain cookies and their features.")}
              </p>
            </div>

            <div>
              <h3 style={colorModeStyle}>6.	{tr("Inventory and payment data")}</h3>
              <p className="privacy-content" style={colorModeStyle}>
              {tr("All you need to create a user account is an e-mail address. We use this to send you a link to confirm your registration and to reset your password. We do not collect any other inventory data. Your email address is not passed on to third parties, nor do we use third-party data to enrich your email address with this data and create a profile about you.")}<br/>
              </p>
              <p className="privacy-content" style={colorModeStyle}>
              {tr("Although you can voluntarily enter or view extensive data in a Space to which you have been granted access (about people, addresses, health data, contract data and much more), the members of these Spaces are themselves responsible for the data managed there. AdminCat employees have virtually no access to this data (see explanations under 7. Content data).")}
              </p>
              <p className="privacy-content" style={colorModeStyle}>
              {tr("You can pay for our services, such as the paid subscription, via payment services such as PayPal. The amount, the subscription period and a PayPal reference in connection with your user account are stored in our database for you as confirmation and overview for each payment transaction processed via this service, but only in double-encrypted form, so that our employees have no access to this data.")}
              </p>
            </div>

            <div>
              <h3 style={colorModeStyle}>7.	{tr("Content data (email, documents and recorded data)")}</h3>
              <p className="privacy-content" style={colorModeStyle}>
              {tr("AdminCat manages emails, documents and extensive master and transaction data for you (insofar as you decide to use it). Only you as the user should have sovereignty and full control over this data. That is why we offer you maximum protection with our security concept")}:
              </p>
              <p className="privacy-content" style={colorModeStyle}>
              <ul>
                <li>
                  {tr("AdminCat is hosted on an AWS server in Frankfurt am Main (Germany) and is therefore subject to the strictest EU data protection regulations; AWS has ISO/IEC certifications 27001:2022, 27017:2015 and 27018:2019")}
                </li>
                <li>
                  {tr("We secure communication with your end device using the modern TLS 1.3 (Transport Layer Security) standard, which means that all data is transmitted in encrypted form and protected against manipulation and unauthorized access by third parties during transmission")}
                </li>
                <li>
                  {tr("We counter potential attackers with high password standards, automatic session termination after 1 hour of inactivity and optional two-factor authentication (2FA)")}
                </li>
                <li>
                  {tr("In addition to AWS encryption, your content data is stored on a second encrypted database and access to it is only permitted using keys assigned to your login data")}
                </li>
                <li>
                  {tr("The column names in the tables of our database are still masked in such a way that an unauthorized reader can hardly interpret or assign the content of the columns")}
                </li>
                <li>
                  {tr("In rare cases, complete encryption of the data is associated with unacceptable performance losses, e.g. for full-text searches in documents uploaded by you. Therefore, instead of secondary encryption, rigorous text splitting is used here, which means that the specific text content remains protected for you")}
                </li>
              </ul>
              </p>
            </div>
            <div>
              <h3 style={colorModeStyle}>8.	{tr("Service contact (contact form, email, phone)")}</h3>
              <p className="privacy-content" style={colorModeStyle}>
                {tr("You can contact us via a contact form (accessible from your user account), by email or by phone. These communication channels are generally open to you for your questions. We are also very interested in your feedback, what you like and your suggestions for improvements.")}<br/>
              </p>
              <p className="privacy-content" style={colorModeStyle}>
                {tr("Personal data is not required, unless you may wish to be called back or receive an answer by email. To analyze errors on the site, we ask you for a description of the error as detailed as possible, but we will never ask for personal data or even login data. Seven days after each request has been completed, we delete all related messages in our systems. Your telephone number will remain on a call list on one of our telephones for one month without a name reference until it is deleted; no additional storage or further processing will take place.")}
              </p>
            </div>

            <div>
              <h3 style={colorModeStyle}>9.	{tr("Disclosure of data")}</h3>
              <p className="privacy-content" style={colorModeStyle}>
              {tr("AdminCat does not pass on any data to third parties unless there is a legal obligation to do so or the transfer of the data is absolutely necessary for the provision of a service requested by the user (Art. 6 para. 1 lit. f GDPR). This section provides information about such services, our partners involved in them and what data is used for what purpose. Users who make use of these services on our website tacitly consent to the corresponding processing.")}
              </p>
              <p className="privacy-content" style={colorModeStyle}>
              {tr("AWS is our hosting partner, on whose servers our website is operated in Frankfurt am Main (Germany). This means that AWS is of course bound by the strict European data protection laws (GDPR), and under no circumstances may it access your data or pass data on to third parties. The open source software AWStats is used on its servers to analyze log files, which means that no cookies are required by your browser. AWS only uses anonymized IP address data for this purpose. This data cannot be assigned to a specific person and is not forwarded to third parties.")}
              </p>
              <p className="privacy-content" style={colorModeStyle}>
              {tr("If you take out a subscription on our website, you can use the service provider PayPal for payment. To do this, you need a corresponding user account with PayPal, for which you agree to the terms and conditions and privacy policy accordingly. For each payment process, AdminCat only transmits the absolutely necessary data such as provider, amount and currency and receives the status and a PayPal reference number of the payment from PayPal.")}
              </p>
              <p className="privacy-content" style={colorModeStyle}>
              {tr("You will find embedded videos on our pages that are provided by the YouTube service. YouTube is operated by Google Ireland Limited, Gordon House, 4 Barrow St, Dublin, D04 E5W5, Ireland. When you play a video, a direct connection to the Google servers is established. Your IP address and the address of the page from which you are watching the video will be transmitted. YouTube also uses a cookie that collects further user data in order to offer matching videos. If you have a Google user account and are currently logged in, YouTube can link data, such as the video you clicked on, to your Google user account. If you do not wish this to happen, you must log out of Google.")}
              </p>
              <p className="privacy-content" style={colorModeStyle}>
              {tr("We also use Google Fonts on our website, that are loaded onto your device from the Google Fonts servers by your browser. In this way, we can consistently present our texts in an appealing form (Art. 6 para. 1 lit. f GDPR). You can find more information about Google's data protection at")}
              <span><a style={{ fontSize: '18px' }} href='https://policies.google.com/privacy' target='_blank'> {tr('https://policies.google.com/privacy.')}</a></span>
              </p>
              <p className="privacy-content" style={colorModeStyle}>
              {tr("AdminCat does not use social media plugins (e.g. for Facebook, Instagram or X). We also do not use tracking and analysis services such as Google Analytics. Furthermore, we do not use user profiling and automated decisions based on this, nor do we display advertising or send newsletters.")}
              </p>
              
            </div>

        

            <div>
              <h3 style={colorModeStyle}>10.	{tr("Data protection rights of the data subject")}</h3>
              <div>
              <h4 style={colorModeStyle}>(a) {tr("Conditions for consent")} </h4>
              <p className="privacy-content" style={colorModeStyle}>
              {tr("If the processing of personal data is based on consent given, you have the right to revoke your consent at any time in accordance with")} <b>{tr("Art. 7 Paragraph 3 GDPR")}</b>. {tr("Revoking your consent does not affect the legality of the processing carried out on the basis of your consent up to the point of revocation.")}<br/>
              {tr("You can contact us at any time to exercise your right of withdrawal.")}
              </p>
            </div>

            <div>
              <h4 style={colorModeStyle}>(b) {tr("Right of access by the data subject")} </h4>
              <p className="privacy-content" style={colorModeStyle}>
              {tr("In accordance with")} <b>{tr("Art. 15 GDPR")}</b>, {tr("you have the right to request a confirmation from AdminCat as to whether we are processing personal data related to you. If that is the case, you have the right to information about this personal data, the details of the processing and comprehensive information about your rights. You can request confirmation at any time using the contact details above.")}
              </p>
            </div>
            
            <div>
              <h4 style={colorModeStyle}>(c) {tr("Right to rectification")} </h4>
              <p className="privacy-content" style={colorModeStyle}>
              {tr("In accordance with")} <b>{tr("Art. 16 GDPR")}</b>, {tr("you have the right to demand immediate rectification or completion of personal data about you that is administered by us.")}
              </p>
            </div>

            <div>
              <h4 style={colorModeStyle}>(d) {tr("Right to erasure (right to be forgotten)")} </h4>
              <p className="privacy-content" style={colorModeStyle}>
              {tr("In accordance with")} <b>{tr("Art. 17 GDPR")}</b>, {tr("you have the right to request the immediate deletion of personal data that AdminCat has stored about you, provided that it is no longer necessary for the purposes for which it was collected or otherwise processed.")}
              </p>
            </div>

            <div>
              <h4 style={colorModeStyle}>(e) {tr("Right to restriction of processing")} </h4>
              <p className="privacy-content" style={colorModeStyle}>
              {tr("According to")} <b>{tr("Art. 18 GDPR")}</b>, {tr("any data subject affected by the processing of personal data, has the right to require the person responsible for restricting the processing, if such information is incorrect, the processing illegitimate or inexpedient, or if an objection according to")} <b>{tr("Art. 21 paragraph 1")}</b> {tr("is present.")}<br/>
              </p>
              <p className="privacy-content" style={colorModeStyle}>
              {tr("In order to exercise the right to restriction of processing, the data subject can contact us at any time using the contact details given above.")}

              </p>
            </div>

            <div>
              <h4 style={colorModeStyle}>(f) {tr("Notification obligation to receiving third parties")} </h4>
              <p className="privacy-content" style={colorModeStyle}>
              {tr("if the person responsible is sharing personal data of the data subject with third parties, according to")} <b>{tr("Art. 19 GDPR")}</b>, {tr("he must notify these recipients when personal data have been corrected or deleted")}. {tr("you have the right to ask companies to whom this information was sent")}.<br/>
    
              </p>
            </div>
    
            <div>
              <h4 style={colorModeStyle}>(g) {tr("Right to data portability")} </h4>
              <p className="privacy-content" style={colorModeStyle}>
              {tr("If you have provided a company with personal data in an automated process, you have the right, in accordance with")} <b>{tr("Art. 20 GDPR")}</b>, {tr("to receive the personal data stored there about you in a structured, common and machine-readable format or to request that it be transmitted to another person responsible.At least as far as this is technically feasible.")}
              </p>
            </div>

            <div>
              <h4 style={colorModeStyle}>(h) {tr("Right to object")} </h4>
              <p className="privacy-content" style={colorModeStyle}>
              {tr("In accordance with")} <b>{tr("Art. 21 GDPR")}</b>, {tr("you have the right to object to processing of personal data at AdminCat at any time, unless there are compelling legitimate grounds for the processing.")}<br/>
              </p>
              <p className="privacy-content" style={colorModeStyle}>
              {tr("To exercise the right to object you can contact the relevant person responsible at any time.")}
              </p>
            </div>

            <div>
              <h4 style={colorModeStyle}>(i) {tr("Automated individual decision-making, including profiling")} </h4>
              <p className="privacy-content" style={colorModeStyle}>
              {tr("According to")} <b>{tr("Art. 22 GDPR")}</b>, {tr("every person affected by the processing of personal data has the right not to be subject to a decision based on automated processing - including profiling. Such processing is not used at AdminCat.")}

              </p>
            </div>

            <div>
              <h4 style={colorModeStyle}>(j) {tr("Communication of a personal data breach to the data subject")} </h4>
              <p className="privacy-content" style={colorModeStyle}>
              {tr("If a company saves your personal data, it must inform you immediately in accordance with")} <b>{tr("Art. 34 GDPR")}</b>. {tr("If the protection of this data has been breached, we will inform you about security-relevant incidents in which the security of your data stored at AdminCat could be violated by email and on our website and give you tips for suitable measures.")}
              </p>
            </div>

            <div>
              <h4 style={colorModeStyle}>(k){tr("Right to lodge a complaint with a supervisory authority")} </h4>
              <p className="privacy-content" style={colorModeStyle}>
              {tr("According to")} <b>{tr("Art. 77 GDPR")}</b>, {tr("you have the right to complain to a supervisory authority. The responsible supervisory authorities are")}:</p>
              <p className="privacy-content" style={colorModeStyle}>
                Der Bundesbeauftragte für den Datenschutz <br/>
                und die Informationsfreiheit (BfDI) <br />
                Husarenstr. 30<br /> 
                53117 Bonn<br/> 
                <span>{tr('Germany')}</span> 
              </p>

              <p className="privacy-content" style={colorModeStyle}>
              {tr("Affected persons have the right to complain to the competent technical supervisory authority in the event of data protection problems. The appropriate authority for us is")}:</p>
              <p className="privacy-content" style={colorModeStyle} >
                Der Hessische Beauftragte für  <br/>
                Datenschutz und Informationsfreiheit <br />
                Postfach 3163<br /> 
                65021 Wiesbaden<br/> 
                <span>{tr('Germany')}</span> 
              </p>
              <p className="privacy-content" style={colorModeStyle}>
                <span>{tr("E-Mail")}:</span> <a href="poststelle@datenschutz.hessen.de" style={{ fontSize: '18px' }}>poststelle@datenschutz.hessen.de</a>
              </p>

              <p className="privacy-content" style={colorModeStyle}>
                <span>{tr('Phone')}</span>: +49 611 1408 - 0<br/>


                <span>{tr('Fax')}:</span>
                  <span
                    style={{ display: 'inline-block', marginLeft: getMarginLeft(tr('Fax')) }}
                  ></span>
                 +49 611 1408 - 900 
              </p>

            </div>

            <div>
              <h4 style={colorModeStyle}>{tr("Topicality and changes to this privacy statement")} </h4>
              <p className="privacy-content" style={colorModeStyle}>
              {tr("This privacy statement is currently valid and is dated June 2024. Due to the further development of AdminCat or due to changed legal or official requirements, it may be necessary to change this privacy statement. In this case, we will inform our users in advance by email.")}
              </p>
            </div>


            </div>
            </div>
          </div>
        </div>
      )
        :
       ( 
        <div className="credits-disclaimer">
          <div className="disclaimer">
            <h1 >{t("Privacy Statement")}</h1>
            <div>
              <p >
              {t("Thank you for your interest in our online service. The protection of your data is very important to us. We therefore apply the utmost care and state-of-the-art security standards to ensure maximum protection of your data. Below we will explain in detail, which data AdminCat is processing and how it protects this data.")}
              </p>
            </div>
            <div>
              <h3>1.	{t("The person responsible for data processing is")}:</h3>
              <p className="privacy-content">
                AdminCat GmbH<br/>
                Landgraf-Karl-Str.21A <br /> 
                34131 Kassel<br/>
                Germany
              </p>
              <p className="privacy-content">
              <span>{t("E-Mail")}:</span> <a href="mailto:admin@admincat.net" style={{ fontSize: '18px' }}>admin@admincat.net</a>
              </p>
            </div>

            <div>
              <h3 >2.	{t("Legal basis")}</h3>
              <p className="privacy-content" >
              {t("The provision of our services requires the processing of data and takes place in accordance with the statutory provisions. For data protection, the General Data Protection Regulation (GDPR), the applicable Federal Data Protection Act (BDSG), the Telemedia Act (TMG) and telecommunications secrecy are decisive. AdminCat's services are hosted exclusively on servers in Germany (Frankfurt am Main).")}
              </p>
            </div>
        
          <div >
           
              <h3>3.	{t("Terms")}</h3>
              <p className="privacy-content">
              {t("The legislator requires that personal data be processed in a lawful manner, in good faith and in a manner that is understandable for the person concerned ('lawfulness, processing in good faith, transparency'). To ensure this, we inform you about the individual legal definitions that are also used in this data protection declaration")}:
              </p>
            
            <div>
              <h4>(a) {t("Personal data")}   </h4>
              <p className="privacy-content">
              {t('"Personal data" is all information that relates to an identified or identifiable natural person (hereinafter “data subject”); A natural person is regarded as identifiable who can be identified directly or indirectly, in particular by means of assignment to an identifier such as a name, an identification number, location data, an online identifier or one or more special features that express the physical, physiological, genetic, psychological, economic, cultural or social identity of this natural person.')}
              </p>
            </div>
            
            <div>
              <h4>(b) {t("Processing")}   </h4>
              <p className="privacy-content">
              {t('"Processing" is any process carried out, with or without the help of automated processes, or any such series of processes in connection with personal data such as the collection, recording, organization, ordering, storage, adaptation or change, reading out, querying, use, disclosure by transmission, distribution or any other form of provision, comparison or linking, restriction, deletion or destruction.')}
              </p>
            </div>


            <div>
              <h4 >(c) {t("Restriction of processing")}   </h4>
              <p className="privacy-content">
              {t('"Restriction of processing" is the marking of stored personal data with the aim of restricting their future processing.')}
              </p>
            </div>

            <div>
              <h4 >(d) {t("Profiling")} </h4>
              <p className="privacy-content">
              {t('"Profiling" is any type of automated processing of personal data that consists of using this personal data to evaluate certain personal aspects relating to a natural person, in particular aspects relating to work performance, economic situation, health,to analyze or predict personal preferences, interests, reliability, behavior, whereabouts or relocation of this natural person.')}
              </p>
            </div>

            <div>
              <h4>(e) {t("Pseudonymization")}   </h4>
              <p className="privacy-content">
              {t('"Pseudonymization" is the processing of personal data in such a way that the personal data can no longer be assigned to a specific data subject without the use of additional information, provided that this additional information is stored separately and is subject to technical and organizational measures that ensure that the personal data cannot be assigned to an identified or identifiable natural person.')}
              </p>
            </div>

            <div>
              <h4>(f) {t("File system")}   </h4>
              <p className="privacy-content">
              {t('"File system" is any structured collection of personal data that is accessible according to certain criteria, regardless of whether this collection is managed centrally, decentralized or according to functional or geographical criteria')}
              </p>
            </div>

            <div>
              <h4 >(g) {t('Person responsible')}   </h4>
              <p className="privacy-content">
              {t('"Person responsible" is a natural or legal person, authority, institution or other body that alone or jointly with others decides on the purposes and means of processing personal data; If the purposes and means of this processing are specified by Union law or the law of the member states, the person responsible or the specific criteria for his appointment can be provided for in accordance with Union law or the law of the member states.')}
              </p>
            </div>

            <div>
              <h3>4.	{t("Access Data")}</h3>
              <p className="privacy-content">
              {t("AdminCat collects data and information, some of which is personal data, each time the website is accessed. This data is stored in the server log files. The following can be recorded")}:
              </p>
              <p className="privacy-content">
              <ul>
                <li> {t("Web request and interaction with a service, e.g. PayPal")}</li>
                <li> {t("The Internet Protocol address (IP address)")}</li>
                <li> {t("Browser type and language of the browser")} </li>
                <li> {t("Date and time of access to our website")}</li>
                <li> {t("Time zone difference to Greenwich Mean Time (GMT)")}</li>
                <li> {t("The email address of a Space, if a user is logged in there")}</li>
                <li> {t("Access status/HTTPS status code")}</li>
              </ul>
              </p>
              <p className="privacy-content">
              {t("The legal basis for data processing is Art. 6 (1) p. 1 lit. f GDPR. Our legitimate interest is based on the following purposes for data collection")}:
              </p>
              <p className="privacy-content">
              <ul>
                <li> {t("Ensuring a smooth connection to the website")}</li>
                <li> {t("Ensuring a convenient use of our website")}</li>
                <li> {t("Evaluation of system security and stability, and")} </li>
                <li> {t("For other administrative purposes")} </li>
              </ul>
              </p>

              <p className="privacy-content">
                {t("After the end of the communication process, the collected information is only evaluated for statistical purposes and in anonymized form. Under no circumstances do we use the data collected for the purpose of drawing conclusions about your person.")}
              </p>
            </div>

            <div>
              <h3>5.	{t("Cookies")}</h3>
              <p className="privacy-content">
              {t("AdminCat doesn't use any cookie. Therefore we also don't provide an integrated cookie consent tool on our site, which usually allows you to administrate your opt-in choices related to certain cookies and their features.")}
              </p>
            </div>

            <div>
              <h3>6.	{t("Inventory and payment data")}</h3>
              <p className="privacy-content">
              {t("All you need to create a user account is an e-mail address. We use this to send you a link to confirm your registration and to reset your password. We do not collect any other inventory data. Your email address is not passed on to third parties, nor do we use third-party data to enrich your email address with this data and create a profile about you.")}<br/>
              </p>
              <p className="privacy-content">
              {t("Although you can voluntarily enter or view extensive data in a Space to which you have been granted access (about people, addresses, health data, contract data and much more), the members of these Spaces are themselves responsible for the data managed there. AdminCat employees have virtually no access to this data (see explanations under 7. Content data).")}
              </p>
              <p className="privacy-content">
              {t("You can pay for our services, such as the paid subscription, via payment services such as PayPal. The amount, the subscription period and a PayPal reference in connection with your user account are stored in our database for you as confirmation and overview for each payment transaction processed via this service, but only in double-encrypted form, so that our employees have no access to this data.")}
              </p>
            </div>

            <div>
              <h3>7.	{t("Content data (email, documents and recorded data)")}</h3>
              <p className="privacy-content">
              {t("AdminCat manages emails, documents and extensive master and transaction data for you (insofar as you decide to use it). Only you as the user should have sovereignty and full control over this data. That is why we offer you maximum protection with our security concept")}:
              </p>
              <p className="privacy-content">
              <ul>
                <li>
                  {t("AdminCat is hosted on an AWS server in Frankfurt am Main (Germany) and is therefore subject to the strictest EU data protection regulations; AWS has ISO/IEC certifications 27001:2022, 27017:2015 and 27018:2019")}
                </li>
                <li>
                  {t("We secure communication with your end device using the modern TLS 1.3 (Transport Layer Security) standard, which means that all data is transmitted in encrypted form and protected against manipulation and unauthorized access by third parties during transmission")}
                </li>
                <li>
                  {t("We counter potential attackers with high password standards, automatic session termination after 1 hour of inactivity and optional two-factor authentication (2FA)")}
                </li>
                <li>
                  {t("In addition to AWS encryption, your content data is stored on a second encrypted database and access to it is only permitted using keys assigned to your login data")}
                </li>
                <li>
                  {t("The column names in the tables of our database are still masked in such a way that an unauthorized reader can hardly interpret or assign the content of the columns")}
                </li>
                <li>
                  {t("In rare cases, complete encryption of the data is associated with unacceptable performance losses, e.g. for full-text searches in documents uploaded by you. Therefore, instead of secondary encryption, rigorous text splitting is used here, which means that the specific text content remains protected for you")}
                </li>
              </ul>
              </p>
            </div>
            <div>
              <h3>8.	{t("Service contact (contact form, email, phone)")}</h3>
              <p className="privacy-content">
                {t("You can contact us via a contact form (accessible from your user account), by email or by phone. These communication channels are generally open to you for your questions. We are also very interested in your feedback, what you like and your suggestions for improvements.")}<br/>
              </p>
              <p className="privacy-content">
                {t("Personal data is not required, unless you may wish to be called back or receive an answer by email. To analyze errors on the site, we ask you for a description of the error as detailed as possible, but we will never ask for personal data or even login data. Seven days after each request has been completed, we delete all related messages in our systems. Your telephone number will remain on a call list on one of our telephones for one month without a name reference until it is deleted; no additional storage or further processing will take place.")}
              </p>
            </div>

            <div>
              <h3 >9.	{t("Disclosure of data")}</h3>
              <p className="privacy-content">
              {t("AdminCat does not pass on any data to third parties unless there is a legal obligation to do so or the transfer of the data is absolutely necessary for the provision of a service requested by the user (Art. 6 para. 1 lit. f GDPR). This section provides information about such services, our partners involved in them and what data is used for what purpose. Users who make use of these services on our website tacitly consent to the corresponding processing.")}
              </p>
              <p className="privacy-content">
              {t("AWS is our hosting partner, on whose servers our website is operated in Frankfurt am Main (Germany). This means that AWS is of course bound by the strict European data protection laws (GDPR), and under no circumstances may it access your data or pass data on to third parties. The open source software AWStats is used on its servers to analyze log files, which means that no cookies are required by your browser. AWS only uses anonymized IP address data for this purpose. This data cannot be assigned to a specific person and is not forwarded to third parties.")}
              </p>
              <p className="privacy-content">
              {t("If you take out a subscription on our website, you can use the service provider PayPal for payment. To do this, you need a corresponding user account with PayPal, for which you agree to the terms and conditions and privacy policy accordingly. For each payment process, AdminCat only transmits the absolutely necessary data such as provider, amount and currency and receives the status and a PayPal reference number of the payment from PayPal.")}
              </p>
              <p className="privacy-content">
              {t("You will find embedded videos on our pages that are provided by the YouTube service. YouTube is operated by Google Ireland Limited, Gordon House, 4 Barrow St, Dublin, D04 E5W5, Ireland. When you play a video, a direct connection to the Google servers is established. Your IP address and the address of the page from which you are watching the video will be transmitted. YouTube also uses a cookie that collects further user data in order to offer matching videos. If you have a Google user account and are currently logged in, YouTube can link data, such as the video you clicked on, to your Google user account. If you do not wish this to happen, you must log out of Google.")}
              </p>
              <p className="privacy-content">
              {t("We also use Google Fonts on our website, that are loaded onto your device from the Google Fonts servers by your browser. In this way, we can consistently present our texts in an appealing form (Art. 6 para. 1 lit. f GDPR). You can find more information about Google's data protection at")}
              <span><a style={{ fontSize: '18px' }} href='https://policies.google.com/privacy' target='_blank'> {t('https://policies.google.com/privacy.')}</a></span>
              </p>
              <p className="privacy-content">
              {t("AdminCat does not use social media plugins (e.g. for Facebook, Instagram or X). We also do not use tracking and analysis services such as Google Analytics. Furthermore, we do not use user profiling and automated decisions based on this, nor do we display advertising or send newsletters.")}
              </p>
            </div>
            <div>
              <h3>10.	{t("Data protection rights of the data subject")}</h3>
              <div>
              <h4>(a) {t("Conditions for consent")} </h4>
              <p className="privacy-content">
              {t("If the processing of personal data is based on consent given, you have the right to revoke your consent at any time in accordance with")} <b>{t("Art. 7 Paragraph 3 GDPR")}</b>. {t("Revoking your consent does not affect the legality of the processing carried out on the basis of your consent up to the point of revocation.")}<br/>
              {t("You can contact us at any time to exercise your right of withdrawal.")}
              </p>
            </div>

            <div>
              <h4>(b) {t("Right of access by the data subject")} </h4>
              <p className="privacy-content">
              {t("In accordance with")} <b>{t("Art. 15 GDPR")}</b>, {t("you have the right to request a confirmation from AdminCat as to whether we are processing personal data related to you. If that is the case, you have the right to information about this personal data, the details of the processing and comprehensive information about your rights. You can request confirmation at any time using the contact details above.")}
              </p>
            </div>
            
            <div>
              <h4>(c) {t("Right to rectification")} </h4>
              <p className="privacy-content">
              {t("In accordance with")} <b>{t("Art. 16 GDPR")}</b>, {t("you have the right to demand immediate rectification or completion of personal data about you that is administered by us.")}
              </p>
            </div>

            <div>
              <h4>(d) {t("Right to erasure (right to be forgotten)")} </h4>
              <p className="privacy-content">
              {t("In accordance with")} <b>{tr("Art. 17 GDPR")}</b>, {t("you have the right to request the immediate deletion of personal data that AdminCat has stored about you, provided that it is no longer necessary for the purposes for which it was collected or otherwise processed.")}
              </p>
            </div>

            <div>
              <h4 >(e) {t("Right to restriction of processing")} </h4>
              <p className="privacy-content">
              {t("According to")} <b>{tr("Art. 18 GDPR")}</b>, {t("any data subject affected by the processing of personal data, has the right to require the person responsible for restricting the processing, if such information is incorrect, the processing illegitimate or inexpedient, or if an objection according to")} <b>{t("Art. 21 paragraph 1")}</b> {t("is present.")}<br/>
              </p>
              <p className="privacy-content">
              {t("In order to exercise the right to restriction of processing, the data subject can contact us at any time using the contact details given above.")}

              </p>
            </div>

            <div>
              <h4>(f) {t("Notification obligation to receiving third parties")} </h4>
              <p className="privacy-content">
              {t("If the person responsible is sharing personal data of the data subject with third parties, according to")} <b>{t("Art. 19 GDPR")}</b>, {t("he must notify these recipients when personal data have been corrected or deleted")}. {t("you have the right to ask companies to whom this information was sent")}.<br/>
    
              </p>
            </div>
    
            <div>
              <h4>(g) {t("Right to data portability")} </h4>
              <p className="privacy-content">
              {t("If you have provided a company with personal data in an automated process, you have the right, in accordance with")} <b>{t("Art. 20 GDPR")}</b>, {t("to receive the personal data stored there about you in a structured, common and machine-readable format or to request that it be transmitted to another person responsible. At least as far as this is technically feasible.")}
              </p>
            </div>

            <div>
              <h4>(h) {t("Right to object")} </h4>
              <p className="privacy-content">
              {t("In accordance with")} <b>{t("Art. 21 GDPR")}</b>, {t("you have the right to object to processing of personal data at AdminCat at any time, unless there are compelling legitimate grounds for the processing.")}<br/>
              </p>
              <p className="privacy-content">
              {t("To exercise the right to object you can contact the relevant person responsible at any time.")}
              </p>
            </div>

            <div>
              <h4>(i) {t("Automated individual decision-making, including profiling")} </h4>
              <p className="privacy-content">
              {t("According to")} <b>{t("Art. 22 GDPR")}</b>, {t("every person affected by the processing of personal data has the right not to be subject to a decision based on automated processing - including profiling. Such processing is not used at AdminCat.")}

              </p>
            </div>

            <div>
              <h4>(j) {t("Communication of a personal data breach to the data subject")} </h4>
              <p className="privacy-content" >
              {t("If a company saves your personal data, it must inform you immediately in accordance with")} <b>{t("Art. 34 GDPR")}</b>. {t("If the protection of this data has been breached, we will inform you about security-relevant incidents in which the security of your data stored at AdminCat could be violated by email and on our website and give you tips for suitable measures.")}
              </p>
            </div>

            <div>
              <h4>(k){t("Right to lodge a complaint with a supervisory authority")} </h4>
              <p className="privacy-content">
              {t("According to")} <b>{tr("Art. 77 GDPR")}</b>, {t("you have the right to complain to a supervisory authority. The responsible supervisory authorities are")}:</p>
              <p className="privacy-content">
                Der Bundesbeauftragte für den Datenschutz <br/>
                und die Informationsfreiheit (BfDI) <br />
                Husarenstr. 30<br /> 
                53117 Bonn<br/> 
                <span>{t('Germany')}</span> 
              </p>

              <p className="privacy-content">
              {t("Affected persons have the right to complain to the competent technical supervisory authority in the event of data protection problems. The appropriate authority for us is")}:</p>
              <p className="privacy-content">
                Der Hessische Beauftragte für  <br/>
                Datenschutz und Informationsfreiheit <br />
                Postfach 3163<br /> 
                65021 Wiesbaden<br/>
                <span>{t('Germany')}</span> 
              </p>

              <p className="privacy-content">
                <span>{t("E-Mail")}:</span> <a href="poststelle@datenschutz.hessen.de" style={{ fontSize: '18px' }}>poststelle@datenschutz.hessen.de</a>
              </p>

              <p className="privacy-content">
                <span>{t('Phone')}</span>: +49 611 1408 - 0<br/>
                  <span>{t('Fax')}:</span>
                  <span
                    style={{ display: 'inline-block', marginLeft: getMarginLeft(t('Fax')) }}
                  ></span>+49 611 1408 - 900 
                </p>
  

            </div>

            <div>
              <h4>{t("Topicality and changes to this privacy statement")} </h4>
              <p className="privacy-content">
              {t("This privacy statement is currently valid and is dated June 2024. Due to the further development of AdminCat or due to changed legal or official requirements, it may be necessary to change this privacy statement. In this case, we will inform our users in advance by email.")}
              </p>
            </div>


            </div>
            </div>
          </div>
        </div>
       )
      }
    </PrivacyStatementWrapper>
  );

};

export default compose(withTranslation, withUserPreferences, withAccount)(PrivacyStatement);
