import React, { Component } from 'react';
import styled from 'styled-components';
import compose from 'lodash/fp/compose';
// import { Icon } from 'semantic-ui-react';
import { withDocument, withTranslation } from '../../../hoc';
import { setMsgInfo } from '../../../../actions';
// import { useDispatch } from 'react-redux';
import { connect } from 'react-redux';

const DropzoneWrapper = styled.label`
  width: auto;
  height: 50px;
  // background-color: #fff;
  background-color: var(--admincat-color-grey-1);
  border: 1px dashed #bcb3b3;
  display: flex;
  box-sizing: border-box;
  border-radius: 9px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 32px;
  letter-spacing: -0.017em;
  color: var(--admincat-color-grey-12);
`;

const StyledFileInput = styled.input`
  display: none;
`;

class Dropzone extends Component {
  state = { highlight: false };
  files = [];

  onFilesAdded = (e) => {
    const files = Array.from(e.target.files || e.dataTransfer.files);
    const validFiles = this.validateFileTypes(files);
    
    if (validFiles.length > 0) {
      this.files = validFiles;
      const isValid = this.props.validateFileSize(this.files);
      if (isValid) {
        this.showForm();
      } else {
        this.files = [];
      }
    } else {
      this.files = [];
      this.props.setMsgInfo({
        success: false,
        msg: [this.props.tr('Only PDF, PNG, and JPEG files are allowed.')],
      });
    }
  };

  validateFileTypes = (files) => {
    const allowedTypes = ['application/pdf', 'image/png', 'image/jpeg'];
    return files.filter(file => allowedTypes.includes(file.type));
  };

  showForm() {
    const { toggleDocumentForm, showFormPage, sendDuplicateNotification } = this.props;
    toggleDocumentForm({ data: { files: this.files }, showForm: true });
    sendDuplicateNotification();
    showFormPage();
  }

  onDragOver = (e) => {
    e.preventDefault();
    this.setState({ highlight: true });
  };

  onDragLeave = () => {
    this.setState({ highlight: false });
  };

  onDrop = (e) => {
    e.preventDefault();
    this.onFilesAdded(e);
    this.setState({ highlight: false });
  };

  render() {
    const { props, state } = this;
    return (
      <DropzoneWrapper
        className={`dropzone ${state.highlight ? 'dropzone-highlight' : ''}`}
        onDragOver={this.onDragOver}
        onDragLeave={this.onDragLeave}
        onDrop={this.onDrop}
        htmlFor="files"
        style={{ cursor: props.disabled ? 'default' : 'pointer' }}
      >
        <StyledFileInput
          className="fileInput"
          type="file"
          multiple
          name="files"
          id="files"
          accept=".pdf,.png,.jpeg,.jpg"
          onChange={this.onFilesAdded}
        />
        <span>{props.tr('Drag and Drop files here or Upload files')}</span>
      </DropzoneWrapper>
    );
  }
}

const mapDispatchToProps = (dispatch, { tr }) => ({
  sendDuplicateNotification: () =>
    dispatch(
      setMsgInfo({
        success: true,
        msg: [tr('Please note duplicate files will be ignored')],
        navigationCounter: 1,
      })
    ),
  validateFileSize: (files, maxFileSizeInMb = 10, maxTotalSizeInMb = 50) => {
    const maxFileSizeInBytes = maxFileSizeInMb * 1024 * 1024;
    const maxTotalSizeInBytes = maxTotalSizeInMb * 1024 * 1024;
    let totalSize = 0;

    for (let file of files) {
      if (file.size > maxFileSizeInBytes) {
        dispatch(
          setMsgInfo({
            success: false,
            msg: [
              `${tr(
                'One or more files exceed the maximum allowed file size of'
              )} ${maxFileSizeInMb} MB`,
            ],
          })
        );
        return false;
      }
      totalSize += file.size;
    }

    if (totalSize > maxTotalSizeInBytes) {
      dispatch(
        setMsgInfo({
          success: false,
          msg: [
            tr(
              `The total size of the selected files exceeds the maximum allowed size of ${maxTotalSizeInMb} MB`
            ),
          ],
        })
      );
      return false;
    }
    return true;
  },
  setMsgInfo: (msgInfo) => dispatch(setMsgInfo(msgInfo)),
});

export default compose(
  withDocument,
  withTranslation,
  connect(null, mapDispatchToProps),
)(Dropzone);
