import React from 'react';
import { HiOutlineMoon, HiOutlineSun } from 'react-icons/hi2';
import { useDarkMode } from 'context/DarkModeContext';
import styled from 'styled-components';

const ButtonIcon = styled.button`
  background: none;
  border: none;
  padding: 0.3rem;
  border-radius: 5px;
  transition: all 0.2s;

  // &:hover {
  //   background-color: #fff;
  // }

  & svg {
    width: 1.7rem;
    height: 1.7rem;
    color: #739bcb;
    margin-top: 8px;
  }
`;

function DarkModeToggle() {
  const { isDarkMode, toggleDarkMode } = useDarkMode();

  return (
    <ButtonIcon onClick={toggleDarkMode}>
      {isDarkMode ? <HiOutlineSun /> : <HiOutlineMoon />}
    </ButtonIcon>
  );
}

export default DarkModeToggle;

// import React from 'react';
// import styled from 'styled-components';
// import { Switch } from '@mui/material';

// const StyleSwitch = styled(Switch)`
//   margin-top: 23px;
// `;

// function DarkModeToggle() {
//   //   const [checked, setChecked] = React.useState(true);

//   //   const handleChange = () => {
//   //     if (checked) {
//   //       setChecked(false);
//   //     } else {
//   //       setChecked(true);
//   //     }
//   //   };

//   return (
//     <StyleSwitch
//       //   checked={checked}
//       //   onChange={handleChange}
//       inputProps={{ 'aria-label': 'controlled' }}
//     />
//   );
// }

// export default DarkModeToggle;
