import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
// import Backend from "i18next-http-backend";
// import { initReactI18next } from "react-i18next";
import deu from './locale/deu';
import eng from './locale/eng';

// const options = {
//   order: ['localStorage'],
//   lookupQuerystring: 'lng',
//   lookupSessionStorage: 'i18nextLng',
//   caches: ['localStorage']
// }

// i18n
//   .use(Backend)
//   .use(initReactI18next)
//   .init({
//     resources: {
//           eng: eng,
//           deu: deu
//     },
//     lng: localStorage.getItem("lng") || "eng",
//     fallbackLng: "eng",
//   });

i18n.use(LanguageDetector).init({
  // we init with resources
  resources: {
    eng: eng,
    deu: deu,
  },
  fallbackLng: 'eng',
  debug: false,
  // detection: options,
  // lng: localStorage.getItem("lng") || "eng",
  // fallbackLng: "eng",
  defaultLocale: 'eng',
  cookie: 'lang',

  // have a common namespace used around the full app
  ns: ['translations'],
  defaultNS: 'translations',

  keySeparator: false, // we use content as keys
  nsSeparator: false,

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ',',
  },

  react: {
    wait: true,
  },
});

export default i18n;
