const initialState = {
  data: {},
  showForm: false,
  showView: false,
  isLoading: false,
};

export const baseViewForm = (actionType, modalType='form') => (state=initialState, action={}) => { 
  const showModalType = modalType === 'form' ? 'showForm' : 'showView'
  switch(action.type){
    case actionType:
      const { data } = action.payload;
      const show = action.payload[showModalType];
      return {
        ...state,
        data: data || state.data,
        [showModalType]: show,
      };
    default: return state;
  }
}

