import React from 'react';
import styled from 'styled-components';
import Form from '../Form';
import Button from '../Button';
import { withTranslation } from '../../../hoc';
import { ModalStyle } from '../styled';
import customStyle from './index.css';

const FormModalWrapper = styled.div`
  ${customStyle}
  ${ModalStyle}
  &&& {
    display: ${({ open }) => (open ? 'flex' : 'none')};
  }
`;

const StyledButton = styled(Button)`
  && {
    min-width: 80px;
  }
`;

const StyledDiv = styled.div``;

const FormModal = withTranslation((props) => {
  const {
    className,
    tr,
    onSubmit,
    title,
    actionButtonName,
    children,
    isLoading,
    toggleForm,
    render,
    renderActionButtons,
    open,
    validationSchema,
    formData,
    loadingData,
  } = props;

  return (
    <FormModalWrapper open={open} className={`modal-wrapper ${className}`}>
      <div style={props.styles} className="body">
        <span onClick={props.toggleForm} className="exit">
          <i className="fa fa-times"></i>
        </span>
        <Form
          onSubmit={onSubmit}
          title={title}
          validationSchema={validationSchema}
          formData={formData}
          children={children}
          loadingData={loadingData}
          renderActionButtons={
            renderActionButtons ||
            (() => (
              <div className="card-footer text-right">
                <div className="d-flex">
                  <StyledButton
                    type="button"
                    name={tr('Cancel')}
                    onClick={toggleForm}
                    className="_btn-link"
                  />
                  <StyledButton
                    type="submit"
                    className="btn-primary"
                    isLoading={isLoading}
                    name={tr(actionButtonName)}
                  />
                </div>
              </div>
            ))
          }
          render={render && render(props)}
        />
      </div>
    </FormModalWrapper>
  );
});

FormModal.defaultProps = {
  style: {},
  validationSchema: {},
  formData: {},
  style: {},
  open: false,
  className: '',
  isLoading: false,
  toggleForm: () => {},
  onSubmit: () => {},
  title: 'No title',
  children: <span>No children</span>,
  render: null,
  renderActionButtons: null,
  actionButtonName: 'Add',
};

export default withTranslation(FormModal);
