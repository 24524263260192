import { BaseActionClass } from "../common/BaseActionClass"
import { EDUCATION } from '../types'

const path = "/dashboard/education";
const name = "Education";

class Education extends BaseActionClass {

  constructor() { super(path, EDUCATION, name) }

  addEducation = this._add;

  updateEducation = this._update;

  deleteEducationById = this._deleteById;

  getEducation = (...props) => (dispatch) => {
    // clearing the store on education fetch byt school_type
    dispatch({ 
      type: this.createTypes(`GET_${this._TYPE}`).success, 
      payload: [] 
    });
    return this._getAll(...props)(dispatch)
  }

  getEducationById = this._getById;

  deleteBulkEducation = this._deleteBulk;
}

export const { 
  addEducation, 
  updateEducation, 
  deleteEducationById,
  getEducation,
  getEducationById,
  deleteBulkEducation,
} = new Education()

