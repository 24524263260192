import React from "react";
import { connect } from "react-redux";
import {
  getAnimal,
} from "../../../actions";
import hoistNonReactStatics from 'hoist-non-react-statics'


let loaded = false;

export const withAnimal = (WrapperComponent) => {
  class WithAnimal extends React.Component {
    componentDidMount() {
      const { getAnimal } = this.props;
      if (loaded) return;
      loaded = true;
      getAnimal();
    }
    
    getAnimalOptions = () => undefined

    getAnimalLocaleOptions = () => {
      const { animal } = this.props;
      return animal.map(({ created_at, ...rest }) => ({ ...rest }));
    }
    render() {
      return (
        <WrapperComponent 
          animalOptions={this.getAnimalOptions()} 
          animalLocaleOptions={this.getAnimalLocaleOptions()} 
          {...this.props} 
        />
      )
    }
  }
  const mapStateToProps = ({ animal }) => ({ animal })
  const WithAnimalHOC = connect(mapStateToProps, {
    getAnimal,
  })(WithAnimal);
  hoistNonReactStatics(WithAnimalHOC, WrapperComponent)
  return WithAnimalHOC
}