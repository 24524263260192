import React from 'react';
import DocumentWrapper from '../common/DocumentWrapper';
import routes, { redirects } from './routes';
import { MyDataDashboardTemplate } from '../common/MyDataDashboardTemplate';

import { withTranslation } from '../../../../components/hoc';


const Housing = (props) => {
  return (
    <DocumentWrapper formTitle="Housing" {...props}>
      <MyDataDashboardTemplate
        {...props}
        showSideBar
        routes={routes}
        redirects={redirects}
        render={(renderPropsData) => (
          <MyDataDashboardTemplate.Main {...renderPropsData} card={false} />
        )}
      />
    </DocumentWrapper>
  );
};

export default withTranslation(Housing);
