import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  getUserPersonalWorkspace,
} from "actions";
import hoistNonReactStatics from 'hoist-non-react-statics'

export const withUserPersonalWorkspace = (WrapperComponent) => {
  class WithUserPersonalWorkspace extends React.Component {
    componentDidMount() {
      const { getUserPersonalWorkspace } = this.props;
      getUserPersonalWorkspace();
    }
    getUserPersonalWorkspaceLocaleOptions = () => undefined
    getUserPersonalWorkspaceOptions = () => {
      const { userPersonalWorkspace } = this.props;
      return userPersonalWorkspace.map(({ created_at, space, ...rest }) => {
        return ({ id: space.space_name, name: space.space_name, ...rest   })
      });
    }
    render() {
      return (
        <WrapperComponent 
          userPersonalWorkspaceOptions={this.getUserPersonalWorkspaceOptions()} 
          userPersonalWorkspaceLocaleOptions={this.getUserPersonalWorkspaceLocaleOptions()} 
          {...this.props} 
        />
      )
    }
  }
  const mapStateToProps = ({ userPersonalWorkspace }) => ({ userPersonalWorkspace })
  const WithUserPersonalWorkspaceHOC = connect(mapStateToProps, {
    getUserPersonalWorkspace,
  })(WithUserPersonalWorkspace);
  hoistNonReactStatics(WithUserPersonalWorkspaceHOC, WrapperComponent)
  return WithUserPersonalWorkspaceHOC
}
