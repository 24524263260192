import React, { Component } from 'react';
import styled, { css } from "styled-components";


const ParagraphDiv = styled.p`
    color: ${props => props.color ? props.color : "#1C246D"};
    text-align: ${props => props.align ? props.align : 'left'};
    font-weight: ${props => props.fontWeight ? props.fontWeight : 'normal'};
    font-family: ${props => props.fontFamily ? props.fontFamily : 'Karla, sans-serif'};
    font-size: ${props => props.fontSize ? props.fontSize : '17px'};

    ${({ upperCase }) => upperCase && css`
        text-transform: uppercase;
    `}
`

const Paragraph = (props) => {
    const { children, color, align, upperCase, ...rest } = props
    return <ParagraphDiv upperCase={upperCase} color={color} align={align} {...rest}>{children}</ParagraphDiv>
}

export default Paragraph