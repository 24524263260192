import styled from 'styled-components';

export const DateContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-gap: 8px;
`;

export const ThreeColumns = styled.div`
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-gap: 8px;
`;

export const MultiColumns = styled.div`
  display: grid;
  grid-template-columns: ${({ col }) => `repeat(${col}, auto)`};
  grid-gap: 8px;
  padding-right: 15px;
`;
