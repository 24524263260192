import React from 'react';
import styled from 'styled-components';
import compose from 'lodash/fp/compose';
import ContentTemplate from 'components/common/ContentTemplate';
import { withUserLayoutChoice, withTranslation } from 'components/hoc';
import { DnDPortal } from 'components/common/FormItems/DnDPortal';

const ButtonWrapper = styled.div`
  margin-top: -6px;
`;

export const StyledContentTemplate = styled(ContentTemplate)`
  div.table-responsive {
    height: auto;
    min-height: 500px;
    max-height: 100%;
    width: auto;
    max-width: 93%;
    background: var(--admincat-color-grey-1);

    @media screen and (max-width: 768px) {
      height: auto;
      min-height: 500px;
      max-height: 100%;
      width: auto;
      max-width: 85%;
      background: var(--admincat-color-grey-1);
    }
    @media screen and (min-width: 1200px) {
      height: auto;
      min-height: 500px;
      max-height: 100%;
      width: auto;
      max-width: 93%;
      background: var(--admincat-color-grey-1);
    }
  }
`;

const ViewDataContent = (props) => {
  const {
    getLayoutColumnProps,
    tableName,
    frontendTableName: fTableName,
    backendTableName: bTableName,
    columns: defaultColumns,
    tr,
    msg,
    ...rest
  } = props;

  const frontendTableName = fTableName || tableName;
  const backendTableName = bTableName || tableName;

  const { columns, layoutColumns } = getLayoutColumnProps(
    frontendTableName,
    backendTableName,
    defaultColumns,
  );

  return (
    <React.Fragment>
      {msg}
      <ButtonWrapper className="flex">
        <DnDPortal tableName={frontendTableName} columns={layoutColumns} />
      </ButtonWrapper>
      <StyledContentTemplate {...rest} columns={columns} />
    </React.Fragment>
  );
};

ViewDataContent.defaultProps = {
  frontendTableName: null,
  backendTableName: null,
  columns: [],
};

export default compose(withUserLayoutChoice, withTranslation)(ViewDataContent);
