import React from 'react';
import check from 'check-types';
import compose from 'lodash/fp/compose';
import styled, { css } from 'styled-components';
import Button from '../Button';
import { Button as SemanticButton } from 'semantic-ui-react';
import {
  withTranslation,
  withFormValidation,
  withCustomRouter,
} from 'components/hoc';
import Loader from 'components/common/Loader';
import { FormValidationContext } from 'components/hoc/Preload/FormValidation';

export const StyledButton = styled(Button)`
  width: ${(props) => (props.width ? props.width : '8.0625rem')};
  height: 2.5rem;
  line-height: 1.25rem;
  font-weight: 500;
  font-size: 1rem;
  border: ${(props) => (props.border ? props.border : 'none')};
  border-radius: 7px;
  color: ${(props) => (props.color ? props.color : '#fff')};
  background: ${(props) => (props.background ? props.background : '#4C81BE')};
  transition: all ease-in-out 300ms;
  ${({ border }) =>
    border &&
    css`
      border: solid 1px #1d579b;
      border-radius: 7px;
    `}

  &:hover {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.29);
  }
`;

const ButtonFlex = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const Form = compose(
  withCustomRouter,
  withTranslation,
  withFormValidation,
)((props) => {
  const { validateForm, validateField, fieldValidationProps, setFormProps } =
    React.useContext(FormValidationContext);

  const {
    onSubmit,
    render,
    renderActionButtons,
    className,
    renderTitle,
    card,
    children,
    loadingData,
    detailMode,
    basePath,
    ...rest
  } = props;

  React.useEffect(() => {
    setFormProps({ disabled: detailMode || loadingData });
    return () => null;
  }, [loadingData, detailMode]);

  const style = {
    height: 'auto',
    boxShadow: 'none',
    background: 'var(--admincat-color-grey-1)',
  };

  return (
    <form
      style={style}
      onSubmit={validateForm(onSubmit)}
      className={`${card && 'card'} ${className}`}
    >
      {renderTitle && renderTitle(props)}

      <div className="card-body">
        {render &&
          render({
            validateField,
            fieldValidationProps,
          })}
        {check.function(children)
          ? children({
              validateField,
              fieldValidationProps,
              validateForm,
              disabled: loadingData || detailMode,
            })
          : children}
        {renderActionButtons && renderActionButtons(props)}
      </div>
      <br />
    </form>
  );
});

const FormActionButton = (props) => {
  const { tr, isLoading, loadingData, detailMode } = props;
  return (
    !detailMode && (
      <ButtonFlex>
        <StyledButton
          isLoading={isLoading}
          type="submit"
          name={tr('Save')}
          disabled={loadingData || detailMode}
        />
      </ButtonFlex>
    )
  );
};

const StyledLoader = styled(Loader)`
  margin-left: 20px;
  height: 30px;
  position: relative;
  top: -6px;
`;

const EditButton = styled(SemanticButton)`
  float: right;
`;

const FormTitle = ({
  tr,
  title,
  className,
  loadingData,
  detailMode,
  updateMode,
  handleEnterUpdateMode,
  handleEnterDetailMode,
}) =>
  title ? (
    <div className={`card-header ${className}`}>
      <h3 className="card-title" style={{ color: 'var(--admincat-color-grey-3)' }}>
        {tr(title)}{' '}
      </h3>
      {loadingData && <StyledLoader />}
      {detailMode && (
        <EditButton onClick={handleEnterUpdateMode} icon="edit" type="button" />
      )}
      {updateMode && (
        <EditButton onClick={handleEnterDetailMode} icon="eye" type="button" />
      )}
    </div>
  ) : null;

FormTitle.defaultProps = {
  loadingData: false,
  detailMode: false,
  handleEnterUpdateMode: () => {},
  handleEnterDetailMode: () => {},
  updateMode: false,
};

const StyledFormTitle = styled(FormTitle)`
  h3 {
    font-size: 25px;
    font-weight: 600;
    text-align: left;
  }
  justify-content: space-between;
`;

Form.defaultProps = {
  title: '',
  className: '',
  onSubmit: () => {},
  isLoading: false,
  renderTitle: (props) => <StyledFormTitle {...props} />,
  render: null,
  renderActionButtons: (props) => <FormActionButton {...props} />,
  children: null,
  card: true,
};

export default styled(Form)``;
