import React, { useState } from 'react';
import compose from 'lodash/fp/compose';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  getFavoriteOrg,
  addFavoriteOrg,
  searchOrganisation,
} from 'actions/organisation';
import {
  Switchery,
  InputDatePicker,
  Select,
  Input,
  Button,
  OrgForm,
  ButtonLink,
  NewEntriesSelect,
  Image,
  FileInput,
} from 'components/common/FormItems';
import SearchOrgResult from './SearchOrgResult';
import { getQueryParams } from './helpers/getQueryParams';
import {
  withCountry,
  withState,
  withCity,
  withLegalForm,
  withIndustryType,
  withOrganisation,
  withOrganisationMotivation,
  withPublicRespArea,
  withOrgFunctionAssignment,
  withCurrency,
  withTranslation,
  withCustomRouter,
  withUserPreferences,
  withLanguage,
} from 'components/hoc';
import { useParams } from 'react-router';
import API from '../../../../../utils/api';
import axios from '../../../../../axios';
import { RESPONSE_STRINGS } from '../../../../../constants';
import swal from 'sweetalert';


const ImageWrapper = styled.div`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
`;

const Wrapper = styled.div``;

const StyledButtonLink = styled(ButtonLink)`
  span {
    text-transform: uppercase;
  }
`;

const StyledButton = styled(Button)`
  // width: 8.0625rem;
  width: 11rem;
  height: 2.7rem;
  line-height: 1.25rem;
  font-weight: 500;
  font-size: 1rem;
  border: solid 1px #1d579b;
  background: #4c81be;
  color: #fff;
  transition: all ease-in-out 300ms;
  &:hover {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.29);
  }
`;

const status = {
  IDLE: 'IDLE',
  ONGOING: 'ONGOING',
  DONE: 'DONE',
};

const initialValues = {
  id: '',
  name: '',
  org_country: '',
  state: '',
  legal_form: '',
  register_institute: '',
  register_no: '',
  legal_personality: true,
  public: false,
  non_profit: false,
};

const searchOrgFormSchema = {
  name: {
    validation: 'required',
  },
};

const SearchOrgForm = ({
  trObj,
  account,
  resetForm,
  renderActionButtons,
  legalFormLocaleOptions,
  countryLocaleOptions,
  getStateByCountryId,
  getCityByStateId,
  stateLocaleOptions,
  cityLocaleOptions,
  currencyLocaleOptions,
  industryTypeOptions,
  orgOptions,
  orgMotivationLocaleOptions,
  tr,
  checkboxType,
  routeParams,
  baseSearchPath,
  basePath,
  userPreferences,
  activeLanguageOptions,
  detailMode,
  fetchingState,
  fetchingCity,
  routePathname,
  orgFunctionAssignment: { register_institute_options },
  ...rest
}) => {
  const [selectedOrgIds, setSelectedOrgIds] = useState([]);
  const { search } = useSelector((state) => state.organisation);
  const [formData, setFormData] = useState({ ...initialValues });
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [searchStatus, setSearchStatus] = useState(status.IDLE);
  const [addingFavoriteOrg, setAddingFavoriteOrg] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  const [showEntryForm, setShowEntryForm] = React.useState(false);
  const [entryValues, setEntryValues] = React.useState({
    value: '',
    language: userPreferences.language,
  });
  const [entrySubmitting, setEntrySubmitting] = React.useState(false);

  const createOrgForm = () => {
    const queryParams = new URLSearchParams(formData).toString();
    history.push(`/dashboard/data/base/organisation/create?${queryParams}`);
    // history.push(`/dashboard/data/base/organisation/create?q=${formData?.name}`);
  };

  const { id } = useParams();

  const ActionButtons = ({ selectedOrgIds, isLoading }) => (
    <div className="d-flex justify-content-between">
      {search.length === 0 && searchStatus === status.DONE ? (
        <StyledButton name="Propose creation" onClick={createOrgForm} />
      ) : (
        !detailMode && (
          <StyledButton
            name={`${selectedOrgIds.length > 0 ? 'Add Favorite' : 'Search'}`}
            type="submit"
            isLoading={isLoading}
          />
        )
      )}
    </div>
  );

  const onChange = (e) => {
    if (formSubmitting) return;
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSearch = async (formState) => {
    setSearchStatus(status.ONGOING);
    const query = getQueryParams(formState);
    await dispatch(searchOrganisation(query));
    setSearchStatus(status.DONE);
  };

  const addFavoriteOrganisation = async (favoriteOrgIds) => {
    setAddingFavoriteOrg(true);
    await dispatch(addFavoriteOrg(favoriteOrgIds));
    dispatch(getFavoriteOrg());
    setAddingFavoriteOrg(false);
    // resetForm();
  };

  const selectOrg = (orgId) => {
    if (selectedOrgIds.includes(orgId)) {
      setSelectedOrgIds([...selectedOrgIds.filter((id) => id !== orgId)]);
      return;
    }
    setSelectedOrgIds([...selectedOrgIds, orgId]);
  };

  const onSubmit = () => {
    if (selectedOrgIds.length > 0) {
      addFavoriteOrganisation(selectedOrgIds);
      setSelectedOrgIds([]);
      history.push(`${baseSearchPath}`);
      window.scrollTo(0, 0);
    } else {
      const { org_country, ...data } = formData;

      handleSearch(data);
    }
  };

  const registerInstituteOptions = React.useMemo(() => {
    // uniquelize the organisations
    const map = new Map();
    register_institute_options.forEach((props) => {
      const { name, org_country } = props;
      if (org_country == formData?.org_country) {
        map.set(name, props);
      }
    });
    return Array.from(map.values());
  }, [register_institute_options, formData?.org_country]);

  // React.useEffect(() => {
  //   if (id) {
  //     try {
  //       setFormLoading(true);
  //       axios
  //         .get(`${API}/favorite-org/${id}/`)
  //         .then((response) => {
  //           const { data } = response;
  //           setFormData(data?.organisation_data);
  //           setFormLoading(false);
  //         })
  //         .catch((e) => {
  //           setFormData({});
  //           setFormLoading(false);
  //         });
  //     } catch (e) {
  //       setFormData({});
  //       setFormLoading(false);
  //     }
  //   } else {
  //     setFormData({});
  //     setFormLoading(false);
  //   }
  // }, []);

  React.useEffect(() => {
    if (id) {
      try {
        setFormLoading(true);
        axios
          .get(`${API}/favorite-org/${id}/`)
          .then((response) => {
            const { data } = response;
            const { organisation_data } = data;
            setFormData(organisation_data);
            // Fetch state options based on the country
            if (organisation_data.org_country) {
              getStateByCountryId(organisation_data.org_country);
            }
            // Fetch city options based on the state
            if (organisation_data.state) {
              getCityByStateId(organisation_data.state);
            }
          })
          .catch((e) => {
            setFormData({});
          })
          .finally(() => {
            setFormLoading(false);
          });
      } catch (e) {
        setFormData({});
        setFormLoading(false);
      }
    } else {
      setFormData({});
      setFormLoading(false);
    }
  }, [id]);

  const moveToOrgFunction = () => {
    const pathArray = basePath.split('/');
    pathArray.pop();
    const pathway = `${pathArray.join('/')}/org_func_assign?q=${formData?.id}`;
    return pathway;
  };

  const toggleEntryField = () => {
    setShowEntryForm(!showEntryForm);
  };

  const onEntryChange = (e) => {
    const { value, name } = e.target;
    setEntryValues({ ...entryValues, [name]: value });
  };

  const validateAndSubmitEntry = async (entryType) => {
    if (!(entryValues.name && entryValues.language)) {
      swal(
        tr('Warning!'),
        tr(`Please ensure all required fields are populated.`),
        'warning',
      );
      return;
    }
    try {
      setEntrySubmitting(true);
      const payload = {
        name: entryValues.name,
        language: entryValues.language,
        entry_type: entryType,
      };
      const response = await axios.post(`${API}/propose/entry/`, payload);
      swal(
        tr('Success!'),
        tr(`${RESPONSE_STRINGS?.ENTRY_RESPONSE_SUCCESS_MESSAGE}`),
        'success',
      );
      setEntryValues({});
      setEntrySubmitting(false);
      setShowEntryForm(false);
      return;
    } catch (error) {
      setEntrySubmitting(false);
      setShowEntryForm(false);
    } finally {
      setEntrySubmitting(false);
      setShowEntryForm(false);
    }
  };

  return (
    
    <Wrapper className="form-wrapper">
      <div className="flex">
            <StyledButtonLink
              to={baseSearchPath}
              className="option-btn"
              icon="angle double left"
              name="All Entries"
            />
            <StyledButtonLink
              to={moveToOrgFunction()}
              className="option-btn"
              icon="angle double right"
              name={tr('Go to Org Function')}
            />
          </div>
          <div className="row">
            <div
              className="col-7 ml-10 mt-30 container"
              style={{
                position: 'relative',
                background: 'var(--admincat-color-grey-1)',
              }}
            >
          <br/>

          <OrgForm
            onSubmit={onSubmit}
            formData={formData}
            isLoading={formSubmitting}
            loadingData={formLoading}
            validationSchema={searchOrgFormSchema}
            toggleForm={() => {
              setSelectedOrgIds([]);
              resetForm();
            }}
            renderActionButtons={() =>
              renderActionButtons
                ? renderActionButtons({ selectedOrgIds, addingFavoriteOrg })
                : ActionButtons({
                    selectedOrgIds,
                    isLoading: searchStatus === status.ONGOING || addingFavoriteOrg,
                  })
            }
            title="Organisation"
          >
            {
                formData?.organisation_logo && (
                    <>
                     <ImageWrapper>
                      <Image src={formData?.organisation_logo} />
                    </ImageWrapper>
                    </>
                )
            }
            <Input
              onChange={onChange}
              name="name"
              id="name"
              placeholder="Organisation"
              label="Organisation"
              value={formData?.name || ''}
              showRequiredAsterisk
            />
            <Select
              optionDefaultValue="Choose Country"
              label="Country"
              value={formData?.org_country || ''}
              name="org_country"
              id="country"
              localeOptions={countryLocaleOptions}
              onChange={(e) => {
                onChange(e);
                getStateByCountryId(e.target.value);
              }}
              deselectable
            />
            <Select
              isLoading={fetchingState}
              value={formData?.state || ''}
              name="state"
              label="State"
              placeholder="State"
              optionDefaultValue="State"
              localeOptions={stateLocaleOptions}
              onChange={(e) => {
                onChange(e);
                getCityByStateId(e.target.value);
              }}
              disabled={!formData?.org_country}
            />
            <Select
              isLoading={fetchingCity}
              onChange={onChange}
              optionDefaultValue="Choose City"
              label="City Location"
              value={formData?.city_location || ''}
              name="city_location"
              id="city_location"
              localeOptions={cityLocaleOptions}
              disabled={!formData?.state}
              deselectable
            />
            <NewEntriesSelect
              value={formData?.legal_form || ''}
              name="legal_form"
              label={tr('Legal Form')}
              optionDefaultValue="Choose Legal Form"
              localeOptions={legalFormLocaleOptions}
              onChange={(e) => {
                if (e.target.value == 'proposeValue') {
                  toggleEntryField();
                  return;
                } else {
                  onChange(e);
                }
              }}
              entryDefaultLanguage={entryValues.language}
              entryValue={entryValues.name}
              entryLanguageValue={entryValues.language}
              entryValueName={tr('Legal form')}
              entryType={tr('Legal form')}
              entryLanguageName={'language'}
              onEntryChange={onEntryChange}
              onCreateNewEntry={(e) => {
                e.preventDefault();
                validateAndSubmitEntry('legal_form');
              }}
              add={showEntryForm}
              toggleEntryField={toggleEntryField}
              options={activeLanguageOptions}
              isLoading={entrySubmitting}
              closeEntryForm={() => {
                toggleEntryField();
              }}
            />

            <Select
              onChange={onChange}
              value={formData?.register_institute || ''}
              name="register_institute"
              label="Registration Institution"
              optionDefaultValue="Registration Institution"
              options={registerInstituteOptions}
              disabled={!formData?.org_country}
              deselectable
            />
            <Input
              onChange={onChange}
              value={formData?.register_no || ''}
              name="register_no"
              label="Official Register No"
              placeholder="Official Register No"
            />

            {
                !routePathname.includes('search') && (
            <>
              <InputDatePicker
                label="Liquidation"
                value={formData.liquidation}
                name="liquidation"
                onChange={onChange}
              />
              <InputDatePicker
                label="Foundation"
                value={formData.foundation}
                name="foundation"
                onChange={onChange}
              />
            </>
          )
        }
            <Switchery
              name="public"
              value={formData?.public || ''}
              onChange={onChange}
              label="Public"
              showRightLabel={false}
            />
            <Select
              onChange={onChange}
              value={formData?.org_motivation || ''}
              name="org_motivation"
              label="Org Motivation"
              optionDefaultValue="Choose Org Motivation"
              localeOptions={orgMotivationLocaleOptions}
              deselectable
            />
            <Switchery
              name="legal_personality"
              value={formData?.legal_personality || ''}
              onChange={onChange}
              label="Legal Personality"
              showRightLabel={false}
            />
            <Select
              onChange={onChange}
              value={formData?.parent_org || ''}
              name="parent_org"
              label="Parent Organisation"
              optionDefaultValue="Choose Parent Organisation"
              options={orgOptions}
              deselectable
              sort={false}
            />
            <Switchery
              name="non_profit"
              value={formData?.non_profit || ''}
              onChange={onChange}
              label="Non Profit"
              showRightLabel={false}
            />
            <Select
              optionDefaultValue="Choose Industry"
              label="Industry"
              name="industry"
              id="industry"
              value={formData?.industry || ''}
              options={industryTypeOptions}
              onChange={onChange}
              deselectable
              sort={false}
            />

            {
                !routePathname.includes('search') && (
              <>
                <Input
                onChange={onChange}
                value={formData.no_of_employees}
                name="no_of_employees"
                label="No. of Employees"
                placeholder="No. of Employees"
                fieldType="number"
              />
              <Input
                onChange={onChange}
                value={formData.revenue_approx}
                name="revenue_approx"
                label="Revenue approx."
                placeholder="Revenue approx."
                fieldType="number"
              />
              <Select
                onChange={onChange}
                value={formData.revenue_currency}
                name="revenue_currency"
                label="Revenue Currency"
                optionDefaultValue="Choose Revenue Currency"
                // options={props.currencyLocaleOptions.map(({ id }) => ({ id, name: id }))}
                localeOptions={currencyLocaleOptions}
                deselectable
              />
              <Input
                onChange={onChange}
                value={formData.website}
                name="website"
                label="Website"
                placeholder="Website"
              />
          </>
          )
        }

            <hr />

            {search.length > 0 && searchStatus === status.DONE && (
              <SearchOrgResult
                search={search}
                trObj={trObj}
                selectOrg={selectOrg}
                checkboxType={checkboxType}
              />
            )}
            {search.length === 0 && searchStatus === status.DONE && (
              <div
                className="d-flex justify-content-center mb-4"
                style={{ color: 'var(--admincat-color-grey-3)' }}
              >
                {tr('No Results Found')}
              </div>
            )}
          </OrgForm>
        </div>
      </div>
    </Wrapper>
  );
};

export default compose(
  withCountry,
  withState,
  withCity,
  withLegalForm,
  withIndustryType,
  withOrganisation,
  withOrganisationMotivation,
  withPublicRespArea,
  withOrgFunctionAssignment,
  withCurrency,
  withTranslation,
  withCustomRouter,
  withUserPreferences,
  withLanguage,
)(SearchOrgForm);
