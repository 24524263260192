import {
  SPACE_MEMBERS,
  UPDATE_SPACE_MEMBER,
  UPDATE_SPACE_MEMBERS,
} from '../actions/types';

export function spaceMembers(state = [], actions = {}) {
  switch (actions.type) {
    case SPACE_MEMBERS:
      return actions.payload;
    case UPDATE_SPACE_MEMBER:
      return state.map((data) => {
        if (data.id === actions.payload.id) {
          return actions.payload;
        }
        return data;
      });
    case UPDATE_SPACE_MEMBERS:
      // get the id of members who have be changed
      let updatedMemberIds = actions.payload.map((member) => member.id);
      // filter out those that were not updated
      let membersNotUpdated = state.filter(
        (member) => !updatedMemberIds.includes(member.id),
      );
      // return the complete list of members
      return [...membersNotUpdated, ...actions.payload];
    default:
      return state;
  }
}
