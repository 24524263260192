import React from "react";
import compose from "lodash/fp/compose";
import { 
  withTranslation,
  withPersonalInfo,
  withCustomRouter,
  withUserPreferences,
  withPersonGroup,
} from "components/hoc";
import MyDataContentTemplate from '../../common/MyDataContentTemplate';
import LottieLoader from 'components/common/LottieLoader';


const PersonGroup = (props) => {

  const mapFields = () => {
    const { personGroup, trObj, fd, fn } = props;
    return personGroup.data.map((data) => {
      return { 
        ...data, 
        space_name: data.space,
      }
    })
  }

  const { 
    // deletePersonGroupById, 
    // gotoPath,
    // gotoDetailPath,
    loaded,
    deleteBulkPersonGroup,
    gotoCreatePath,
  } = props;

  if (!loaded) {
    return (
      <>
          <LottieLoader />
      </>)
  }

  return (
    <MyDataContentTemplate 
      tableName="person_group"
      columns={[]}
      data={mapFields()}
      toggleForm={gotoCreatePath}
      bulkDelete={deleteBulkPersonGroup}
    />
  )
}

export default compose(
  withTranslation,
  withPersonalInfo,
  withCustomRouter,
  withUserPreferences,
  withPersonGroup,
)(PersonGroup);