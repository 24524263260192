import styled from "styled-components";
import DashboardTemplate from "components/common/DashboardTemplate";

export const MyDataDashboardTemplate = styled(DashboardTemplate)`
  &&& .DashboardTemplate {
    width: 98%;
  }
`

const MainWrapper = styled(DashboardTemplate.Main)`
  &&& button.data-icon {
    color: #444 !important;
  }

  && div.item-action {
    width: 80%;
    left: 0;
    transform: translateX(0);

    div.dropdown-menu-right {
      top: -6px;
      left: 0px;
      display: flex;
      border: none;
      font-size: 12px;
      height: 80%;
      max-width: 83px;
      background: none;

      a.dropdown-item {
        flex: 1;
        margin: 0;
        padding: 0;

        &:hover,
        &:active {
          color: #212529;
          background: none;
        }
      }
    }
  }
`

MyDataDashboardTemplate.Main = MainWrapper
