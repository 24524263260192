import React from 'react';
import { Form as BaseForm, withFormProps } from '../common/Form'
import {
  Select,
  Input,
  ColumnalSelect,
} from 'components/common/FormItems';
import { MultiColumns } from '../styles';
import { getProfessionOptions, professionLabels } from '../VocationalTraining/utils';

class Form extends BaseForm {

  educationFormSchema = {
    organisation: this.required,
  }


  renderUpperInputs = (state) => {
    return (
      <>
        <Select
          onChange={this.onChange}
          value={state.organisation}
          name="organisation"
          label="School"
          optionDefaultValue="Choose Organization"
          options={this.organisationOptions}
          showRequiredAsterisk
        />
        <Select
          onChange={this.onChange}
          value={this.getSchoolTypeValue(state.organisation)}
          name="school_type"
          label="School Type"
          optionDefaultValue="Choose School Type"
          options={this.schoolTypeOptions}
          disabled
        />
      </>
    )
  }

  renderLowerInputs = (state) => {
    const { trObj } = this.props
    return (
      <>
        <Select
          onChange={this.onChange}
          value={state.graduation_goal}
          name="graduation_goal"
          label="Aspired School Graduation (if any)"
          optionDefaultValue="Aspired school graduation"
          options={this.schoolGraduationOptions}
        />
        {/* <Select
          onChange={this.onChange}
          value={state.profession}
          name="profession"
          label="Profession"
          optionDefaultValue="Profession"
          options={this.studyFieldOptions}
          sort={false}
        /> */}
        <ColumnalSelect
          onChange={this.onChange}
          value={state.profession}
          name="profession"
          label="Profession"
          group
          optionDefaultValue="Choose Profession"
          labels={professionLabels}
          options={getProfessionOptions(this.professionOptions, trObj)}
          showRequiredAsterisk
          sort={false}
        />
        <MultiColumns col={2}>
          <Select
            onChange={this.onChange}
            value={state.degree_achieval}
            name="degree_achieval"
            label="Degree achieval"
            optionDefaultValue="Degree achieval"
            localeOptions={this.degreeOptions}
          sort={false}
          />
          <Input
            onChange={this.onChange}
            value={state.final_grade}
            name="final_grade"
            label="Final grade"
          />
        </MultiColumns>
      </>
    )
  }
}

export default withFormProps(Form)

