import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFinanceCategory } from '../actions';

export const useFinancialCategory = () => {
  const dispatch = useDispatch();
  const financialCategoryOptions = useSelector((state) => state.financialCategory);

  useEffect(() => {
    dispatch(getFinanceCategory());
  }, [dispatch]);

  return {
    financialCategoryOptions,
  };
};
