import axios from '../../axios';
import * as types from '../types';
import { setMsgInfo, createAction } from '../';
import filterError from '../common/filterError';
import API from '../api';

const baseUrl = API + '/dashboard';

export const addContactInfo = (payload) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(`${baseUrl}/contact_info`, payload);
      dispatch(createAction(types.ADD_CONTACT_INFO, data));
      dispatch(
        setMsgInfo({
          success: true,
          msg: ['Data added successfully'],
        }),
      );
      return data;
    } catch (err) {
      const errors = filterError(err);
      dispatch(
        setMsgInfo({
          success: false,
          msg: errors,
        }),
      );
      throw err;
    }
  };
};

export const getContactInfo = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(`${baseUrl}/contact_info`);
      dispatch(createAction(types.GET_ALL_CONTACT_INFO, data));
      return data;
    } catch (err) {
      throw err;
    }
  };
};

export const getContactInfoById = (id, payload) => {
  return async (dispatch) => {
    try {
      await axios.get(`${baseUrl}/contact_info/${id}`);
      dispatch(createAction(types.GET_CONTACT_INFO, payload));
      return payload;
    } catch (err) {
      throw err;
    }
  };
};

export const updateContactInfo = (id, payload) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.put(`${baseUrl}/contact_info/${id}`, payload);
      dispatch(createAction(types.UPDATE_CONTACT_INFO, data));
      dispatch(
        setMsgInfo({
          success: true,
          msg: ['Data updated successfully'],
        }),
      );
      return payload;
    } catch (err) {
      const errors = filterError(err);
      dispatch(
        setMsgInfo({
          success: false,
          msg: errors,
        }),
      );
      throw err;
    }
  };
};

export const deleteContactInfoById = (id, payload) => {
  return async (dispatch) => {
    try {
      await axios.delete(`${baseUrl}/contact_info/${id}`);
      dispatch(createAction(types.DELETE_CONTACT_INFO, payload));
      return payload;
    } catch (err) {
      throw err;
    }
  };
};

export const deleteBulkContactInfo = (payload) => {
  return async (dispatch) => {
    try {
      await axios.post(`${baseUrl}/contact_info/bulk_delete`, { ids: payload });
      dispatch(createAction(types.DELETE_BULK_CONTACT_INFO, payload));
      return payload;
    } catch (err) {
      throw err;
    }
  };
};

// import { BaseActionClass } from "../common/BaseActionClass"
// import { CONTACT_INFO } from '../types'

// const path = "/dashboard/contact_info";
// const name = "Contact";

// class ContactInfo extends BaseActionClass {

//   constructor() { super(path, CONTACT_INFO, name) }

//   addContactInfo = this._add;

//   updateContactInfo = this._update;

//   getContactInfo = this._getAll;

//   getContactInfoById = this._getById;

//   deleteContactInfoById = this._deleteById;

//   deleteBulkContactInfo = this._deleteBulk;
// }

// export const {
//   addContactInfo,
//   updateContactInfo,
//   getContactInfo,
//   getContactInfoById,
//   deleteContactInfoById,
//   deleteBulkContactInfo,
// } = new ContactInfo()
