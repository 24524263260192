import React from 'react';
import compose from 'lodash/fp/compose';
import ContentTemplate from 'components/common/ContentTemplate';
import matchPath from '../matchPath';
import {
  withDocument,
  withTranslation,
  withDateFormat,
  withUserPreferences,
  withUserLayoutChoice,
} from 'components/hoc';
import { DnDPortal } from 'components/common/FormItems/DnDPortal';

const defaultColumns = [
  {
    id: 'doc_type',
    label: 'Document Type',
  },
  {
    id: '_issuing_org',
    label: 'Issuing Organisation',
  },
  {
    id: '_city',
    label: 'City/Town',
  },
  {
    id: 'full_name',
    label: 'Concerned Person',
  },
  {
    id: '_issuing_date',
    label: 'Issuing Date',
  },
  {
    id: '',
    label: '',
  },
];

const options = ({ change, view, _delete }) => {
  return [
    {
      onClick: change,
      name: 'Change',
    },
    {
      onClick: view,
      name: 'View',
    },
    {
      onClick: _delete,
      name: 'Delete',
    },
  ];
};

const DocumentTemplate = (props) => {

  const {
    getLayoutColumnProps,
    toggleDocumentView,
    toggleDocumentForm,
  } = props;

  const toggleForm = (data) => {
    const {
      issuing_org_detail = {},
    } = data;
    const { org_country, state } = issuing_org_detail || {};
    data = { data: { ...data, country: org_country, state }, showForm: true };
    toggleDocumentForm(data);
  };

  const toggleView = (data) => {
    data = { data, showView: true };
    toggleDocumentView(data);
  };

  const tableName = "document";
  const frontendTableName = tableName;
  const backendTableName = tableName;
  const { columns, layoutColumns } = getLayoutColumnProps(
    frontendTableName, 
    backendTableName, 
    defaultColumns, 
  );

  const mapFields = () => {
    const { documents, location, trObj, fd } = props;
    return documents.data
      .map((data) => {
        const { first_name, last_name } = data.concerned_person_detail;
        return {
          ...data,
          space_name: data.space,
          full_name: first_name + ' ' + last_name,
          doc_type: trObj(data.document_type_detail),
          _city: trObj(data.city_detail),
          lang: trObj(data.language_detail),
          _issuing_org: data.issuing_org ? data.issuing_org_detail.name : '',
          // _issuing_org: data.issuing_org_detail.name,
          _issuing_date: fd(data.issuing_date),
          valid_from: fd(data.valid_from),
          valid_to: fd(data.valid_to),
          main_area: trObj(data.main_area_detail),
        };
      })
      .filter((data) => {
        return matchPath(data.document_type_detail.area, location.pathname);
      });
  };

  const { deleteDocumentById, title } = props;

  return (
    <>
      <DnDPortal 
        tableName={frontendTableName} 
        columns={layoutColumns} 
      />
      <ContentTemplate
        raisedTable
        columns={columns}
        title={title}
        data={mapFields()}
        options={options({
          change: toggleForm,
          view: toggleView,
          _delete: deleteDocumentById,
        })}
      />
    </>
  );
};

export default compose(
  withDocument,
  withTranslation,
  withDateFormat,
  withUserPreferences,
  withUserLayoutChoice,
)(DocumentTemplate);
