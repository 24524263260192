import React, { useState, useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';
import compose from 'lodash/fp/compose';
import { Icon } from 'semantic-ui-react';
import { withTranslation, withFieldError } from 'components/hoc';
import { inputStyle } from '../FormItems/Input';

const multiSelectInputStyle = css`
  ${inputStyle}
  height: auto; /* Override the fixed height for input style */
`;

// Styled components
const MultiSelectWrapper = styled.div`
  &&& {
    ${multiSelectInputStyle}
  }

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  min-height: 42px;
  padding: 2px 8px;
  position: relative;
  cursor: text;
  background: var(--admincat-color-grey-6);
  color: var(--admincat-color-grey-2);
  border: ${({ error }) =>
    error ? '1px solid red !important' : '1px solid var(--admincat-color-grey-19)'};

  &:focus {
    outline: none;
    box-shadow: none;
    border: 1px solid #4285f4;
  }
`;

const SelectedItem = styled.div`
  display: flex;
  align-items: center;
  background: var(--admincat-color-grey-6);
  color: var(--admincat-color-grey-2);
  border-radius: 2px;
  padding: 2px 6px;
  margin: 2px;
  font-size: 14px;

  button {
    border: none;
    background: none;
    color: var(--admincat-color-grey-2);
    margin-left: 4px;
    cursor: pointer;
  }
`;

const Dropdown = styled.div`
  position: absolute;
  top: 80%;
  left: 0;
  right: 0;
  border-radius: 5px;
  max-height: 200px;
  border: 1px solid #ccc;
  background: var(--admincat-color-grey-6);
  overflow-y: auto;
  z-index: 1000;
`;

const DropdownItem = styled.div`
  padding: 1.5px;
  padding-left: 10px;
  cursor: pointer;
  font-size: 14px;
  color: var(--admincat-color-grey-2);

  &:hover {
    background: var(--ck-color-focus-border);
    color: #fff;
  }
`;

const MultiSelect = compose(
  withFieldError,
  withTranslation,
)(
  ({
    tr,
    className,
    isLoading,
    error,
    label,
    value = [],
    name,
    onChange,
    onBlur,
    disabled,
    options = [],
    langOptions,
    localeOptions,
    trObj = (d) => d.name,
    sort = true,
    style,
    required,
    showRequiredAsterisk,
    ...props
  }) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const wrapperRef = useRef(null);

    // Close dropdown when clicking outside
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);

    // Toggle dropdown open/close
    const toggleDropdown = () => {
      if (!disabled) {
        setDropdownOpen(!dropdownOpen);
      }
    };

    // Add item to selected items
    const addItem = (item) => {
      if (!value.includes(item.id)) {
        const newValue = [...value, item.id];
        onChange({
          target: {
            name,
            value: newValue,
          },
        });
      }
    };

    // Remove item from selected items
    const removeItem = (itemId) => {
      const newValue = value.filter((id) => id !== itemId);
      onChange({
        target: {
          name,
          value: newValue,
        },
      });
    };

    // Process options using the same logic as SelectOptions
    const getOptionData = () => {
      const localeOpts = localeOptions || langOptions;
      const getLabel = localeOpts ? trObj : (d) => d.name;
      const optionData = localeOpts || options;

      const processedOptions = optionData
        .map((data) => ({
          ...data,
          label: getLabel(data),
        }))
        .sort((a, b) => {
          if (!sort) return 0;
          const labelA = a.label.toLowerCase();
          const labelB = b.label.toLowerCase();
          return labelA.localeCompare(labelB);
        });

      return processedOptions;
    };

    const optionData = getOptionData();

    return (
      <div
        className={`form-group w-100 ${className}`}
        style={{ ...style }}
        ref={wrapperRef}
      >
        {label && (
          <label className="form-label" htmlFor={name}>
            {tr(label)}
            {(required || showRequiredAsterisk) && (
              <span className="form-required">*</span>
            )}
            {isLoading && <Icon loading name="asterisk" />}
          </label>
        )}
        <MultiSelectWrapper
          onClick={toggleDropdown}
          error={error}
          tabIndex={0}
          disabled={disabled}
        >
          <div
            className="selected-items"
            style={{ display: 'flex', flexWrap: 'wrap', flex: 1 }}
          >
            {value.map((itemId) => {
              // Find the item in options
              const item = optionData.find((opt) => opt.id === itemId);
              const label = item ? item.label : itemId;
              return (
                <SelectedItem key={itemId}>
                  {label}
                  <button
                    type="button"
                    onClick={(e) => {
                      e.stopPropagation();
                      removeItem(itemId);
                    }}
                  >
                    &times;
                  </button>
                </SelectedItem>
              );
            })}
          </div>
          <div className="dropdown-arrow" style={{ marginLeft: 'auto' }}>
            <Icon name={dropdownOpen ? 'chevron up' : 'chevron down'} />
          </div>
        </MultiSelectWrapper>
        {dropdownOpen && (
          <Dropdown>
            {optionData
              .filter((item) => !value.includes(item.id))
              .map((item) => (
                <DropdownItem key={item.id} onClick={() => addItem(item)}>
                  {item.label}
                </DropdownItem>
              ))}
          </Dropdown>
        )}
        {error && (
          <small id={`${name}Help`} className="text-danger">
            {tr(error)}
          </small>
        )}
      </div>
    );
  },
);

export default MultiSelect;
