import * as types from '../actions/types';
import { createTypes } from '../actions';

const initialState = {
  data: [],
//   register_institute_options: [],
//   school_options: [],
//   medical_facility_options: [],
//   credit_card_options: [],
//   bank_options: [],
};

const orgFunctionProposal = (state = initialState, action = {}) => {
  switch (action.type) {

    case createTypes(types.ADD_ORG_FUNCTION_PROPOSAL).success:
      return {
        ...state,
        data: [action.payload, ...state.data],
      };

    case createTypes(types.ADD_ORG_FUNCTION_PROPOSAL).failure:

    default:
      return state;
  }
};

export default { orgFunctionProposal };
