import axios from '../../../../axios';

import {
  PERSONS,
  DOCUMENT_TYPE,
  DELETE_DOCUMENT,
  FILTER_BY_RANGE,
  CREATE_DOCUMENTS,
  DOCUMENT_DETAILS,
  LIST_ALL_DOCUMENTS,
  DOCUMENT_CATEGORIES,
  FILTER_BY_YEAR_RANGE,
  FILTER_BY_MONTH_RANGE,
  DOCUMENTS_FULL_TEXT_SEARCH,
  FILTER_BY_CONCERNED_PERSON,
  FILTER_BY_ISSUING_DATE_RANGE,
  FILTER_BY_ISSUING_ORGANIZATION,
  baseDocumentUrl,
} from 'utils/apiEndPoints';

export const createDocumentService = async (payload) => {
  try {
    const { data } = await axios.post(CREATE_DOCUMENTS, payload);

    return data;
  } catch (error) {
    throw error;
  }
};

export const filterCriteriaService = async (filter_param_key, filter_param) => {
  try {
    const { data } = await axios.get(
      `${baseDocumentUrl}/filter-criteria/?filter_key=${filter_param_key}&filter=${filter_param}`,
    );

    return data;
  } catch (error) {
    throw error;
  }
};

export const documentDetailsService = async (id) => {
  try {
    const { data } = await axios.get(DOCUMENT_DETAILS + `${id}`);

    return data;
  } catch (error) {
    throw error;
  }
};

export const documentFullTextSearchService = async (search_query) => {
  try {
    const { data } = await axios.get(DOCUMENTS_FULL_TEXT_SEARCH + search_query);

    return data;
  } catch (error) {
    throw error;
  }
};

export const getDocumentsService = async () => {
  try {
    const { data } = await axios.get(LIST_ALL_DOCUMENTS);

    return data;
  } catch (error) {
    throw error;
  }
};

export const deleteDocumentService = async (id) => {
  try {
    const response = await axios.delete(DELETE_DOCUMENT + `${id}`);

    return response;
  } catch (error) {
    throw error;
  }
};

export const getDocumentCategoryService = async () => {
  try {
    const { data } = await axios.get(DOCUMENT_CATEGORIES);

    return data;
  } catch (error) {
    throw error;
  }
};

export const getDocumentTypeService = async () => {
  try {
    const { data } = await axios.get(DOCUMENT_TYPE);

    return data;
  } catch (error) {
    throw error;
  }
};

export const getPersonsService = async () => {
  try {
    const { data } = await axios.get(PERSONS);

    return data;
  } catch (error) {
    throw error;
  }
};
