import React from 'react';
import { withRouter } from 'react-router-dom';
import { isEmptyValue } from 'components/utils/checkValue';
// import { relativeTimeRounding } from 'moment';

export const withCustomRouter = (WrappedComponent) => {
  class WithCustomRouter extends React.Component {
    get basePath() {
      let basePath = this.routePathname.split('/');
      if (
        basePath.includes('create') ||
        (!isEmptyValue(this.routeParams.id) && !basePath.includes('update'))
      ) {
        basePath.pop();
      } else if (basePath.includes('update')) {
        basePath.splice(basePath.length - 2, basePath.length);
      }
      return basePath.join('/');
    }

    get baseSearchPath() {
      let basePath = this.routePathname.split('/');
      if (
        basePath.includes('search') ||
        (!isEmptyValue(this.routeParams.id) && !basePath.includes('update'))
      ) {
        basePath.pop();
      } else if (basePath.includes('update')) {
        basePath.splice(basePath.length - 2, basePath.length);
      }
      return basePath.join('/');
    }

    get routePathname() {
      return this.props.location.pathname;
    }

    get routeParams() {
      return this.props.match.params;
    }

    get routeQueries() {
      const { location } = this.props;
      const regex = /\?/g;
      const { search } = location || {};
      return (search || '')
        .replace(regex, '')
        .split('&')
        .reduce((accum, str) => {
          const [key, value] = str.split('=');
          accum[key] = value;
          return accum;
        }, {});
    }

    get updateMode() {
      return this.routePathname.includes('update');
    }

    get reverseMode() {
      return this.routePathname.includes('reverse');
    }

    get reverseDetailMode() {
      const { id } = this.routeParams;
      return !isEmptyValue(id) && !this.reverseMode;
    }

    get detailMode() {
      const { id } = this.routeParams;
      return !isEmptyValue(id) && !this.updateMode;
    }

    handleEnterReverseMode = (locationState) => {
      const path = this.routePathname; //.replace("detail", "update")
      const { q } = this.routeQueries;
      if (q) {
        this.gotoRoute(`${path}/reverse?q=${q}`);
        return;
      }
      this.gotoRoute(`${path}/reverse`);
    };

    handleEnterUpdateMode = (locationState) => {
      const path = this.routePathname; //.replace("detail", "update")
      const { q } = this.routeQueries;
      if (q) {
        this.gotoRoute(`${path}/update?q=${q}`);
        return;
      }
      this.gotoRoute(`${path}/update`);
    };

    handleEnterDetailMode = () => {
      const path = this.routePathname.replace('/update', '');
      const { q } = this.routeQueries;
      if (q) {
        this.gotoRoute(`${path}?q=${q}`);
        return;
      }
      this.gotoRoute(path);
    };

    gotoRoute = (route = '', locationState) => {
      this.props.history.push(route, locationState);
    };

    gotoPath = (path = '', locationState) => {
      const { basePath } = this;
      if (basePath.split('/').length > 1) {
        if (basePath.split('/').pop() == 'contract-item') {
          this.gotoRoute(`${basePath}/${path}?q=${locationState?.contract}`);
          return;
        }
        if (basePath.split('/').pop() == 'org_func_assign') {
          this.gotoRoute(`${basePath}/${path}?q=${locationState?.organisationId}`);
          return;
        }
      }
      this.gotoRoute(`${basePath}/${path}`, locationState);
    };

    gotoUpdatePath = (id) => this.gotoPath(`${id}/update`);

    gotoDetailPath = this.gotoPath;

    gotoCreatePath = (locationState) => {
      this.gotoPath('create', locationState);
    };

    gotoSearchPath = (locationState) => {
      this.gotoPath('search', locationState);
    };

    render() {
      return (
        <WrappedComponent
          {...this.props}
          basePath={this.basePath}
          routeParams={this.routeParams}
          routeQueries={this.routeQueries}
          routePathname={this.routePathname}
          updateMode={this.updateMode}
          reverseMode={this.reverseMode}
          detailMode={this.detailMode}
          reverseDetailMode={this.reverseDetailMode}
          gotoRoute={this.gotoRoute}
          gotoPath={this.gotoPath}
          gotoUpdatePath={this.gotoUpdatePath}
          gotoDetailPath={this.gotoDetailPath}
          gotoCreatePath={this.gotoCreatePath}
          handleEnterUpdateMode={this.handleEnterUpdateMode}
          handleEnterDetailMode={this.handleEnterDetailMode}
          handleEnterReverseMode={this.handleEnterReverseMode}
          gotoSearchPath={this.gotoSearchPath}
          baseSearchPath={this.baseSearchPath}
        />
      );
    }
  }

  return withRouter(WithCustomRouter);
};
