import React from "react";
import compose from "lodash/fp/compose";
import styled from "styled-components";
import { ViewModal } from "../../../common/FormItems";
import { getFilenameFromLink } from "../../../utils";
import { toBase64 } from "../../../utils/toBase64"
import { 
  withDocument, 
  withDateFormat,
  withUserPreferences,
  withTranslation,
} from "../../../hoc";
import customStyle from "./index.css";

const StyledViewModal = styled(ViewModal)`
  ${customStyle}
`

const Attachment = ({ link, name }) => (
  <a 
    className="pdf_file-link capitalize block" 
    href={link} target="_blank">
    {name}
  </a>
)

const getAttLink = (data) => {
  if (data.file) {
    return <Attachment link={data.file} name={getFilenameFromLink(data.file)} />
  } else if (data.filename){
    return <Attachment link={toBase64(data)} name={data.filename} />
  }
  return "N/A"
}

const getColumns = (data, fd, trObj) => {
  return [
    {
      label: "Document Type",
      value: trObj(data.document_type_detail) || "N/A",
    },
    {
      label: "Issuing Organisation",
      value: data._issuing_org || "N/A",
    },
    {
      label: "City/Town",
      value: trObj(data.city_detail) || "N/A",
    },
    {
      label: "Main Area",
      value: trObj(data.main_area_detail) || "N/A",
    },
    {
      label: "Concerned Person",
      value: data.full_name || "N/A",
    },
    {
      label: "Issuing Date",
      value: fd(data.issuing_date),
    },
    {
      label: "Language",
      value: data.lang || "N/A",
    },
    {
      label: "Reference Year",
      value: data.reference_year || "N/A",
    },
    {
      label: "Reference Month",
      value: data.reference_month || "N/A",
    },
    {
      label: "Valid From",
      value: fd(data.valid_from),
    },
    {
      label: "Valid To",
      value: fd(data.valid_to),
    },
    {
      label: "File",
      value: getAttLink(data),
    },
  ];
};

const View = (props) => {

  const toggleView = () => {
    const { documentView } = props;
    props.toggleDocumentView({ showView: !documentView.showView });
  }

  const { documentView, fd, title, trObj } = props;
  return (
    <StyledViewModal
      open={documentView.showView}
      columns={getColumns(documentView.data, fd, trObj)}
      toggleView={toggleView}
      title={title}
    />
  )
}


export default compose(
  withDocument,
  withTranslation,
  withDateFormat,
  withUserPreferences,
)(View)
