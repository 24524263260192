import React from 'react';
import styled from 'styled-components';
import DashboardTemplate from 'components/common/DashboardTemplate';
import routes, { redirects } from './routes';

const SettingsWrapper = styled.div`
  margin-right: 5rem;
  margin-left: 3rem;
`;

const Settings = (props) => {
  const user = JSON.parse(localStorage.getItem('user'));
  return (
    <SettingsWrapper>
      <DashboardTemplate
        stripeHeaderTitle="WorkSpace Settings"
        showSideBar={true}
        routes={routes.filter((route) =>
          route.permission.includes(user.space_type)
        )}
        redirects={redirects}
        render={(renderPropsData) => (
          <DashboardTemplate.Main {...renderPropsData} />
        )}
      />
    </SettingsWrapper>
  );
};

export default Settings;
