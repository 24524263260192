import React from "react";
import {
  deleteConfirmation,
} from "../../../actions";

export const withDeleteConfirmation = (WrapperComponent) => {
  const DeleteConfirmation = (props) => {
    return (
      <WrapperComponent 
        {...props}
        deleteConfirmation={deleteConfirmation}
      />
    )
  }
  return DeleteConfirmation 
}
