import Contract from '../common/Contract';
import PreSchoolForm from './PreSchool/Form';
import PreSchoolList from './PreSchool/List';
import VocationalSchoolForm from './VocationalSchool/Form';
import VocationalSchoolList from './VocationalSchool/List';
import GeneralEducationForm from './GeneralEducation/Form';
import GeneralEducationList from './GeneralEducation/List';
import VocationalTrainingForm from './VocationalTraining/Form';
import VocationalTrainingList from './VocationalTraining/List';
import AcademicStudiesForm from './AcademicStudies/Form';
import AcademicStudiesList from './AcademicStudies/List';
import FurtherTrainingForm from './FurtherTraining/Form';
import FurtherTrainingList from './FurtherTraining/List';
import LanguagesForm from './Languages/Form';
import LanguagesList from './Languages/List';
// import ContractForm from '../common/Contract/ContractForm';
// import ContractItem from '../common/ContractItem';
// import ContractItemForm from '../common/ContractItem/Form';

const path = '/dashboard/data/education';

export default [
  // {
  //   name: 'Contract',
  //   path: `${path}/contract`,
  //   icon: 'user',
  //   component: Contract,
  //   exact: true
  // },
  // {
  //   name: 'Contract Form',
  //   path: [
  //     `${path}/contract/create`,
  //     `${path}/contract/:id`,
  //     `${path}/contract/:id/update`,
  //   ],
  //   exact: true,
  //   icon: 'user',
  //   component: ContractForm,
  //   hide: true,
  // },
  // {
  //   name: 'Contract Item',
  //   path: `${path}/contract-item`,
  //   icon: 'user',
  //   component: ContractItem,
  //   exact:true,
  // },
  // {
  //   name: 'Contract Form Item',
  //   path: [
  //     `${path}/contract-item/create`,
  //     `${path}/contract-item/:id`,
  //     `${path}/contract-item/:id/update`,
  //   ],
  //   exact: true,
  //   icon: 'user',
  //   component: ContractItemForm,
  //   hide: true,
  // },
  {
    name: 'Pre-School',
    path: `${path}/pre-school`,
    icon: 'university',
    component: PreSchoolList,
    exact: true,
  },
  {
    name: 'Pre-School',
    path: [
      `${path}/pre-school/create`,
      `${path}/pre-school/:id`,
      `${path}/pre-school/:id/update`,
    ],
    icon: 'university',
    component: PreSchoolForm,
    hide: true,
    exact: true,
  },
  {
    name: 'General Education',
    path: `${path}/general-education`,
    icon: 'university',
    component: GeneralEducationList,
    exact: true,
  },
  {
    name: 'General Education',
    path: [
      `${path}/general-education/create`,
      `${path}/general-education/:id`,
      `${path}/general-education/:id/update`,
    ],
    icon: 'university',
    component: GeneralEducationForm,
    hide: true,
    exact: true,
  },
  {
    name: 'Vocational School',
    path: `${path}/vocational-school`,
    icon: 'university',
    component: VocationalSchoolList,
    exact: true,
  },
  {
    name: 'Vocational School',
    path: [
      `${path}/vocational-school/create`,
      `${path}/vocational-school/:id`,
      `${path}/vocational-school/:id/update`,
    ],
    icon: 'university',
    component: VocationalSchoolForm,
    hide: true,
    exact: true,
  },
  {
    name: 'Vocational Training',
    path: `${path}/vocational-training`,
    icon: 'university',
    component: VocationalTrainingList,
    exact: true,
  },
  {
    name: 'Vocational Training',
    path: [
      `${path}/vocational-training/create`,
      `${path}/vocational-training/:id`,
      `${path}/vocational-training/:id/update`,
    ],
    icon: 'university',
    component: VocationalTrainingForm,
    hide: true,
    exact: true,
  },
  {
    name: 'Academic Studies',
    path: `${path}/academic-studies`,
    icon: 'university',
    component: AcademicStudiesList,
    exact: true,
  },
  {
    name: 'Academic Studies',
    path: [
      `${path}/academic-studies/create`,
      `${path}/academic-studies/:id`,
      `${path}/academic-studies/:id/update`,
    ],
    icon: 'university',
    component: AcademicStudiesForm,
    hide: true,
    exact: true,
  },
  {
    name: 'Further Training',
    path: `${path}/further-training`,
    icon: 'university',
    component: FurtherTrainingList,
    exact: true,
  },
  {
    name: 'Further Training',
    path: [
      `${path}/further-training/create`,
      `${path}/further-training/:id`,
      `${path}/further-training/:id/update`,
    ],
    icon: 'university',
    component: FurtherTrainingForm,
    hide: true,
    exact: true,
  },
  {
    name: 'Languages',
    path: `${path}/languages`,
    icon: 'university',
    component: LanguagesList,
    exact: true,
  },
  {
    name: 'Languages',
    path: [
      `${path}/languages/create`,
      `${path}/languages/:id`,
      `${path}/languages/:id/update`,
    ],
    icon: 'university',
    component: LanguagesForm,
    hide: true,
    exact: true,
  },
];

export const redirects = [
  {
    from: `${path}`,
    to: `${path}/contract`,
  },
  {
    from: `${path}/*`,
    to: `${path}/contract`,
  },
];
