import React from "react";
import { connect } from "react-redux";
import {
  getCommentCategory,
} from "../../../actions";
import hoistNonReactStatics from 'hoist-non-react-statics'


let loaded = false;

export const withCommentCategory = (WrapperComponent) => {
  class WithCommentCategory extends React.Component {
    componentDidMount() {
      const { getCommentCategory } = this.props;
      if (loaded) return;
      loaded = true;
      getCommentCategory();
    }
    getCommentCategoryOptions = () => undefined
    getCommentCategoryLocaleOptions = () => {
      const { commentCategory } = this.props;
      return commentCategory.map(({ created_at, ...rest }) => ({ ...rest }));
    }
    render() {
      return (
        <WrapperComponent 
          commentCategoryOptions={this.getCommentCategoryOptions()} 
          commentCategoryLocaleOptions={this.getCommentCategoryLocaleOptions()} 
          {...this.props} 
        />
      )
    }
  }
  const mapStateToProps = ({ commentCategory }) => ({ commentCategory })
  const WithCommentCategoryHOC = connect(mapStateToProps, {
    getCommentCategory,
  })(WithCommentCategory);
  hoistNonReactStatics(WithCommentCategoryHOC, WrapperComponent)
  return WithCommentCategoryHOC
}
