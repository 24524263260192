import React from "react";
import compose from "lodash/fp/compose";
import { connect } from "react-redux";
import hoistNonReactStatics from 'hoist-non-react-statics'
import * as spaceMemberActions from "actions/spaceMember"
import { BaseHOC, withBaseHOCProps } from "../common/BaseHOC"

let loaded = false

export const withSpaceMembers = (WrapperComponent) => {
  class SpaceMembers extends BaseHOC {

    async componentDidMount() {
      const { getSpaceMembers } = this.props
      if (!loaded)  {
        loaded = true;
        await getSpaceMembers()
      }
    }

    render() {
      return (
        <WrapperComponent 
          {...this.props} 
        />
      )
    }
  }
  const mapStateToProps = ({ spaceMembers }) => ({ spaceMembers })
  const WithSpaceMembers = connect(mapStateToProps, {
    ...spaceMemberActions,
  })(SpaceMembers);
  hoistNonReactStatics(WithSpaceMembers, WrapperComponent)
  return compose(withBaseHOCProps)(WithSpaceMembers);
}
