import Temp from './Temps';
import Personal from './Personal';
import Finance from './Finance';
import Health from './Health';
import Education from './Education';
import Mobility from './Mobility';
import Work from './Work';
import Leisure from './Leisure';
import Housing from './Housing';

const path = '/dashboard/data';

export default [
  {
    name: 'Base',
    path: `${path}/base`,
    icon: 'user',
    component: Personal,
  },
  {
    name: "Health",
    path: `${path}/health`,
    icon: "ambulance",
    component: Health,
  },
  {
    name: 'Housing',
    path: `${path}/housing`,
    icon: 'thermometer empty',
    component: Housing,
  },
  {
    name: 'Finance',
    path: `${path}/finance`,
    icon: "money bill alternate outline",
    component: Finance,
  },
  {
    name: "Education",
    path: `${path}/education`,
    icon: "university",
    component: Education,
  },
  {
    name: "Work",
    path: `${path}/work`,
    icon: "warehouse",
    component: Work,
  },
  {
    name: "Mobility",
    path: `${path}/mobility`,
    icon: "car",
    component: Mobility,
  },
  {
    name: "Leisure",
    path: `${path}/leisure`,
    icon: "building",
    component: Leisure,
  },
];

export const redirects = [
  {
    from: `${path}`,
    to: `${path}/base`,
  },
  {
    from: `${path}/*`,
    to: `${path}/base`,
  },
];
