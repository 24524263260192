import * as types from "../actions/types";
import { createTypes } from "../actions";

const initialState = {
  space: "",
  isLoading: false,
}

export default function userDefaultSpace (state=initialState, action={}){ 
  switch(action.type){ 
    case createTypes(types.USER_DEFAULT_SPACE).success:
      return { ...state, ...action.payload, isLoading: false }
    case createTypes(types.USER_DEFAULT_SPACE).loading:
      return { ...state, isLoading: true }
    default:
      return state
  }
}
