import React from "react";
import compose from "lodash/fp/compose";
import { connect } from "react-redux";
import hoistNonReactStatics from "hoist-non-react-statics";
import { getLanguageLevel } from "actions/preload";
import { BaseHOC, withBaseHOCProps } from "../common/BaseHOC";

let loaded = false

export const withLanguageLevel = (WrappedComponent) => {
  class LanguageLevel extends BaseHOC {

    async componentDidMount() {
      const { getLanguageLevel } = this.props
      if (!loaded)  {
        loaded = true;
        await getLanguageLevel();
      }
    }

    get languageLevelOptions() {
      const { languageLevel } = this.props;
      return this._getOptions(languageLevel, ['id'], true)
    }

    render() {
      return (
        <WrappedComponent 
          {...this.props} 
          languageLevelOptions={this.languageLevelOptions}
        />
      )
    }
  }
  const mapStateToProps = ({ languageLevel }) => ({ languageLevel })
  const WithLanguageLevel = connect(mapStateToProps, { getLanguageLevel })(LanguageLevel);
  hoistNonReactStatics(WithLanguageLevel, WrappedComponent)
  return compose(withBaseHOCProps)(WithLanguageLevel);
}
