import React from 'react';
import compose from 'lodash/fp/compose';
import { 
  withTranslation, 
  withBSPosition,
  withUserPreferences,
} from 'components/hoc';
import ViewDataContent from '../../common/ViewDataContent';
import LottieLoader from 'components/common/LottieLoader';
import { getFullName } from "components/utils";


const columns = [];

const AssetAccount = (props) => {
  const { bsPosition, fn, trObj, loaded } = props
  console.log(bsPosition)

  const mapFields = () => {
    return bsPosition.filter(({ account_category }) => {
      return account_category === "asset";
    }).map((data) => {
        return {
          ...data,
          // actual_value: getSignedNumber(data.actual_value, data, fn),
          person: getFullName(data.person_detail),
          actual_value: fn(data.actual_value),
          position_link: fn(data.position_link),
          account_category: trObj(data.account_category_detail),
          financial_account: trObj(data.financial_account_detail),
          // position_link: data.currency
        }
      })
  }

  const tableProps = {
    frontendTableName: "asset_account",
    backendTableName: "bs_position",
  }

  if (!loaded) {
    return (
      <>
          <LottieLoader />
      </>)
  }

  return (
    <ViewDataContent
      {...tableProps}
      columns={columns}
      data={mapFields() || []}
    />
  );
};

export default compose(
  withTranslation, 
  withBSPosition,
  withUserPreferences,
)(AssetAccount);

