import React from "react";
import compose from "lodash/fp/compose";
import { connect } from "react-redux";
import hoistNonReactStatics from 'hoist-non-react-statics'
import * as personGroupActions from "actions/dashboard/personGroup"
import { BaseHOC, withBaseHOCProps } from "../common/BaseHOC"

let loaded = false

export const withPersonGroup = (WrappedComponent) => {
  class PersonGroup extends BaseHOC {

    async componentDidMount() {
      const { getPersonGroup } = this.props
      if (!loaded)  {
        loaded = true;
        await getPersonGroup();
      }
    }

    get personGroupOptions() {
      const { personGroup } = this.props;
      return this._getOptions(personGroup.data, ['id', 'description'])
        .map(({ description, ...rest }) => ({ ...rest, name: description }))
    }

    deletePersonGroupById = async (data) => {
      const { deletePersonGroupById } = this.props
      await this._deleteById(deletePersonGroupById, data);
    }

    deleteBulkPersonGroup = async (data) => {
      const { deleteBulkPersonGroup } = this.props
      await this._deleteBulk(deleteBulkPersonGroup, data);
    }

    render() {
      return (
        <WrappedComponent 
          {...this.props} 
          deletePersonGroupById={this.deletePersonGroupById}
          deleteBulkPersonGroup={this.deleteBulkPersonGroup}
          personGroupOptions={this.personGroupOptions}
        />
      )
    }
  }
  const mapStateToProps = ({ personGroup }) => ({ personGroup })
  const WithPersonGroup = connect(mapStateToProps,personGroupActions)(PersonGroup);
  hoistNonReactStatics(WithPersonGroup, WrappedComponent)
  return compose(withBaseHOCProps)(WithPersonGroup);
}
