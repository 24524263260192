import axios from '../../../axios';
import * as types from './types';
import { setMsgInfo, createAction } from '../..';
import filterError from '../../common/filterError';
import API from '../../api';

const baseUrl = `${API}/dashboard`;

export const bankTransactionExtraction = (values) => async (dispatch) => {
  dispatch({ type: types.BANK_TRANSACTIONS_LOADING }); // Start loading

  try {
    const { data } = await axios.post(`${baseUrl}/bank_transaction_extraction`, values);
    
    dispatch({
      type: types.BANK_TRANSACTIONS_SUCCESS,
      payload: data,
    });

    dispatch(setMsgInfo({
      success: true,
      msg: ['Transactions extracted and saved successfully'],
    }));
  } catch (error) {
    dispatch({
      type: types.BANK_TRANSACTIONS_FAILURE,
      payload: error.message || 'Failed to extract transactions',
    });

    dispatch(setMsgInfo({
      success: false,
      msg: ['Problem extracting the transaction'],
    }));
  }
};

// export const bankTransactionExtraction = (values) => async (dispatch) => {
//   try {
//     const { data } = await axios.post(`${baseUrl}/bank_transaction_extraction`, values);
//     dispatch({
//       type: types.BANK_TRANSACTIONS,
//       payload: data,
//     });
//     dispatch(dispatch(setMsgInfo({
//       success: true,
//       msg: ['Transactions extracted and saved successfully'],
//     })));
//   } catch (error) {
//     dispatch(dispatch(setMsgInfo({
//       success: false,
//       msg: ['Problem extracting the transaction'],
//     })));
//     return error;
//   }
// };

