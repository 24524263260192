import check from "check-types"
import { isEmptyValue } from "./checkValue"

export const excludeUnsetField = (data={}) => {

  check.assert.object(data);

  return Object.entries(data)
    .filter(([key, value]) => check.boolean(value) || !isEmptyValue(value))
    .reduce((accum, [key, value]) => {
      accum[key] = value
      return accum
    }, {})
}
