import { useState, useEffect } from 'react';
import Validator from 'validatorjs';

export const useForm = ({
  validationSchema,
  initialValues = {},
  customValidationMessage,
  onSubmit = () => {},
}) => {
  const freezedInitialValue = Object.freeze({ ...initialValues });
  const [touched, setTouched] = useState({});
  const [values, setValues] = useState({ ...freezedInitialValue });
  const [errors, setErrors] = useState({});

  const resetForm = () => {
    setValues(freezedInitialValue);
  };

  const handleBlur = ({ target: { name, value } }) => {
    setTouched((prevValues) => ({ ...prevValues, [name]: true }));
    const validation = new Validator(
      { ...values, [name]: value },
      validationSchema,
      customValidationMessage,
    );
    if (validation.fails()) {
      setErrors(validation.errors);
      return;
    }
    setErrors(validation.errors);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(values, { setValues, setErrors, resetForm });
    const touchedFields = {};
    Object.keys(values).forEach((key) => {
      touchedFields[key] = true;
    });

    setTouched(touchedFields);
    if (validationSchema) {
      const validation = new Validator(
        values,
        validationSchema,
        customValidationMessage,
      );
      
      if (validation.fails()) {
        setErrors(validation.errors);
        return;
      }
      setErrors(validation.errors);
    }
  };

  const handleChange = ({ target: { name, value } }) => {
    setTouched((prevValues) => ({ ...prevValues, [name]: true }));
    setValues((prevValues) => ({ ...prevValues, [name]: value }));
    
    const validation = new Validator(
      { ...values, [name]: value },
      validationSchema,
      customValidationMessage,
    );
    if (validation.fails()) {
      setErrors(validation.errors);
      return;
    }
    setErrors(validation.errors);
  };

  return {
    handleSubmit,
    handleBlur,
    handleChange,
    touched,
    values,
    initialValues,
    setTouched,
    errors: errors?.errors ?? {},
    setErrors,
    setValues,
  };
};
