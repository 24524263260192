import * as types from '../../actions/types';
import { createTypes } from '../../actions';
import baseStore from './../utils/baseStore';

export const initialState = {
  data: [],
  showForm: false,
  error: null,
  vaccinationHistoryList: {
    data: [],
  },
  diseaseTypes: {
    data: [],
  },
  singleVaccinationHistory: {
    data: {},
  },
};

function vaccinationHistory(state = initialState, action = {}) {
  switch (action.type) {
    case types.ADD_VACCINATION_HISTORY:
      return {
        ...state,
        data: [action.payload, ...state.data],
      };

    case types.GET_VACCINATION_HISTORY:
      return {
        ...state,
        data: action.payload,
      };

    case types.UPDATE_VACCINATION_HISTORY:
      return {
        ...state,
        data: state.data.map((_data) => {
          if (_data.id === action.payload.id) return action.payload;
          return _data;
        }),
      };

    case types.DELETE_VACCINATION_HISTORY: 
      return { 
        ...state, 
        data: state.data.filter((_data) => { 
          return !action.payload.includes(_data.id);
        }), 
      };

    case types.ADD_VACCINATION_HISTORY:
    case types.UPDATE_VACCINATION_HISTORY:
    case types.GET_VACCINATION_HISTORY:
      return {
        ...state,
        error: null,
      };

    case types.ADD_VACCINATION_HISTORY:
    case types.UPDATE_VACCINATION_HISTORY:
    case types.GET_VACCINATION_HISTORY:
      return {
        ...state,
        error: action.payload,
      };

    case types.DELETE_VACCINATION_HISTORY: 
      return { 
        ...state, 
        error: action.payload 
      };


    case types.GET_DISEASE_TYPES:
      return {
        ...state,
        diseaseTypes: [...action.payload],
      };


    case types.GET_SINGLE_VACCINATION_HISTORY:
      return {
        ...state,
        singleVaccinationHistory: {
          ...action.payload.data,
        },
      };


    default:
      return state;
  }
}


export default { vaccinationHistory };
