/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import compose from 'lodash/fp/compose';
import {
  deleteBulkContract,
} from 'actions';
import {
  withTranslation,
  withBulkDelete,
  withDeleteConfirmation,
  withUserLayoutChoice,
  withCustomRouter,
  withCurrency,
  withMeasurementUnit
} from 'components/hoc';
import MyDataContentTemplate from '../MyDataContentTemplate';
import './style.css';
import LottieLoader from 'components/common/LottieLoader';
import axios from '../../../../../axios';
import API from '../../../../../utils/api';
import { useAccountCategory } from 'hooks/useAccountCategory';
import { useFinancialCategory } from 'hooks/useFinancialCategory';

const ContractItem = (props) => {
  
  const dispatch = useDispatch();
  const [contractItems, setContractItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [contract, setContract] = useState({});

  // const getPersonIs = (person_is) => {
  //   switch(person_is){
  //     case 'B':
  //       return tr('Buyer');
  //     case 'S':
  //       return tr('Seller');
  //     default:
  //       return '';
  //   }
  // }

  const { pathname }  = useLocation();
  const history = useHistory();

  const { q:contractId } = props.routeQueries;
    
  useEffect(()=>{

    if (contractId){
      try{
        setLoading(true);
        axios.get(`${API}/dashboard/contracts/${contractId}`)
        .then( response => {
          const { data } = response;
          setContractItems(data?.contract_items);
          setLoading(false);
          setContract(data);
        }).catch(e => {
          setContract({});
          setContractItems([]);
          setLoading(false);
        })
      }catch (e){
        setContract({});
        setContractItems([]);
        setLoading(false);
      }
    }else{
      setContract({})
      setContractItems([]);
      setLoading(false);
    }
    
  }, []);

  
  const { accountCategoryOptions } = useAccountCategory();
  const { financialCategoryOptions } = useFinancialCategory();


  const searchById = (term, listItems) => {
    for (const item of listItems){
      if (term == item?.id){
        return item;
      }
    }
    return null;
  }

  console.log('contractItems', contractItems)

    const mapFields = () => {
      const { trObj, tr, fd, timeMeasurementLocaleOptions, currency, measurementUnits } = props;
      return contractItems.map((contractItem) => {
        const fixPeriodUnit = searchById(contractItem?.fix_period_unit, timeMeasurementLocaleOptions);
        const varServiceUnit = searchById(contractItem?.var_service_unit, measurementUnits);
        const currencyUnit = searchById(contractItem?.currency, currency);
        const financialCategory = searchById(contractItem?.financial_category, financialCategoryOptions);
        const accountCategory = searchById(contractItem?.account_category, accountCategoryOptions);

        return { 
          ...contractItem,
          fix_or_var : contractItem?.fix_or_var == 'V' ? tr('Variable') : tr('Fix'),
          currency: trObj(currencyUnit),
          fix_period_unit: trObj(fixPeriodUnit),
          var_service_unit: trObj(varServiceUnit),
          financial_category: trObj(financialCategory),
          account_category: trObj(accountCategory),
          subject: contractItem?.subject_id_detail?.name,
          item_reference: trObj(contractItem?.item_reference_detail),

        }
      })
    }

  const handleBulkDelete = async (contractIds) => {
    const deleteBulk = () => dispatch(deleteBulkContract(contractIds));
    const { deleteConfirmation } = props;
    try {
      await deleteConfirmation.initBulk(deleteBulk, contractIds);
    } catch (e) {}
  };

  const tableName = "contract_item";

  const { gotoCreatePath, tr } = props;

  const executeOnRowClick = (detailContract) => {}

  const goBack = () => {
    const pathSegments = pathname.split('/');
    pathSegments.pop();
    let constructedPath = pathSegments.join('/');
    history.push(`${constructedPath}/contract/${contract?.id}`);
  };


  if (loading) {
    return (<><LottieLoader /></>);
  }

  return (
      <MyDataContentTemplate
        columns={[]}
        // title={`${contract?.description}`}
        data={mapFields()}
        contract={contract}
        showHeader={false}
        goBack
        handleGoBack={goBack}
        handleCreateForm={()=>{history.push(`${pathname}/create?q=${contractId}`);}}
        toggleForm={gotoCreatePath}
        bulkDelete={handleBulkDelete}
        tableName={tableName}
        executeOnRowClick={executeOnRowClick}
      />
      
  );
};

export default compose(
  withTranslation,
  withBulkDelete,
  withDeleteConfirmation,
  withUserLayoutChoice,
  withCustomRouter,
  withCurrency,
  withMeasurementUnit,
)(ContractItem);