import React from 'react';
import styled from 'styled-components';
import ArrowIcon from './icons/arrow.svg';
import { Link } from 'react-router-dom';
import { useDarkMode } from 'context/DarkModeContext';

const SubMenuDiv = styled.ul`
  width: 220px;
  list-stlye: none;
  position: absolute;
  margin: 0;
  top: 0;
  right: ${(props) => (props.right ? '-220px' : '-221px')};
  visibility: hidden;
  opacity: 0;
  z-index: 150;
  border-top: 1px solid var(--admincat-color-grey-1);
  border-right: 1px solid var(--admincat-color-grey-1);
  border-bottom: 1px solid var(--admincat-color-grey-1);
  // border-bottom: 1px solid #ddd;
`;

const SubMenuList = styled.a`
  display: flex;
  position: relative;
  padding: 10px;
  // background: #fff;
  background: var(--admincat-color-grey-1);
  transition: 300ms;

  a {
    display: flex;
    align-items: center;
  }

  &:hover {
    cursor: pointer;
    // background: #ddd;
    background: var(--admincat-color-grey-0);
  }

  &:hover > ul {
    visibility: visible;
    opacity: 1;
    right: -100%;
  }

  .title {
    position: absolute;
    color: var(--admincat-color-grey-3);
    left: 40px;
    opacity: 1;
  }
  .title.active {
    left: -200px;
    opacity: 0;
  }
  .icon {
    margin-right: 10px;
  }
`;

const ArrowDiv = styled.div`
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
`;

const SubMenu = ({ sidebar, background, setWidth, right, items, tr, color }) => {
  const { isDarkMode } = useDarkMode();

  return (
    <SubMenuDiv
      background={background}
      setWidth={setWidth}
      sidebar={sidebar}
      right={right}
    >
      {items.map((item) => (
        <SubMenuList href={item.path}>
          <Link to={item.path}>
            <div className="icon">
              {' '}
              <img src={isDarkMode ? item.iconDark : item.icon} />
            </div>
            <div className="title">{tr(item.title)}</div>
          </Link>
          {item.subNav && (
            <ArrowDiv>
              <img src={ArrowIcon} />
            </ArrowDiv>
          )}
          {item.subNav && (
            <SubMenuDiv
              background={background}
              setWidth={setWidth}
              sidebar={sidebar}
              right={right}
              color={color}
            >
              {item.subNav.map((subItem) => (
                <SubMenuList href={subItem.path}>
                  <Link to={subItem.path}>
                    <div className="icon">
                      <img src={isDarkMode ? subItem.iconDark : subItem.icon} />
                    </div>
                    <div className="title">{tr(subItem.title)}</div>
                  </Link>
                </SubMenuList>
              ))}
            </SubMenuDiv>
          )}
        </SubMenuList>
      ))}
    </SubMenuDiv>
  );
};

export default SubMenu;
