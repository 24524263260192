import axios from '../axios';
import API from "./api";
import createAction from "./createAction";
import { getQueryParams } from './common/getQueryParams'

const baseUrl = API;

const baseAction = (type, path) => (query) => {
  return async (dispatch) => {
    try {
      const queryParams = getQueryParams(query)
      const url = `${baseUrl}/${path}/${queryParams}`;
      const { data } = await axios.get(url);
      dispatch(createAction(type, data));
      return data;
    } catch (error) {
      throw error;
    }
  };
};

export default baseAction;
