import moment from 'moment';

export const getDateDistance = (
  distancePast,
  distanceFuture,
  allCurrencyRate,
  composedPreviousDate,
  composedFutureDate,
) => {
  if (distancePast <= distanceFuture) {
    const closestRate = allCurrencyRate.find(
      (rate) => rate.date == composedPreviousDate,
    );

    //Edge Cases where there is no record for the particular date
    if (closestRate == null || undefined) {
      const closestRate = allCurrencyRate.find(
        (rate) => composedFutureDate == rate.date,
      );
      return closestRate;
    } else if (closestRate == null || undefined) {
      return closestRate;
    } else if (closestRate == null || undefined) {
      return NaN;
    }

    return closestRate;
  } else {
    const closestRate = allCurrencyRate.find(
      (rate) => composedFutureDate == rate.date,
    );

    //Edge Cases where there is no record for the particular date
    if (closestRate == null || undefined) {
      const closestRate = allCurrencyRate.find(
        (rate) => rate.date == composedPreviousDate,
      );

      return closestRate;
    } else if (closestRate == null || undefined) {
      return closestRate;
    } else if (closestRate == null || undefined) {
      return 0.0;
    }

    return closestRate;
  }
};

export const formCurrencyConverter = (
  currency,
  closestCurrencyRate,
  DefaultSpaceCurrency,
  values,
) => {
  if (values.currency == DefaultSpaceCurrency) {
    return currency;
  } else if (DefaultSpaceCurrency == 'EUR') {
    return parseFloat(
      (currency / closestCurrencyRate?.[values.currency]) * 1,
    ).toFixed(3);
  } else if (values.currency == 'EUR') {
    return parseFloat(
      (currency / 1) * closestCurrencyRate?.[DefaultSpaceCurrency],
    ).toFixed(3);
  } else {
    return parseFloat(
      (currency / closestCurrencyRate?.[values.currency]) *
        closestCurrencyRate?.[DefaultSpaceCurrency],
    ).toFixed(3);
  }
};
