import {
  documentDetailsService,
  documentFullTextSearchService,
  filterCriteriaService,
  getDocumentsService,
  getDocumentCategoryService,
  getDocumentTypeService,
  getPersonsService,
} from 'components/Dashboard/Documents/services/documentServices';
import {
  getFilterCriteria,
  filteredDocuments,
  filterFailed,
  documentDetailsState,
  getDocumentDetailFailed,
  getSearchResult,
  searchResultState,
  getSearchResultFailed,
  getDocumentList,
  getPersons,
  listDocumentsState,
  getDocumentListFailed,
  onFiltering,
  getDocumentCategory,
  getDocumentTypes,
} from './documentSlice';

export const filterCriteriaThunk = (filter_param) => async (dispatch) => {
  try {
    const cleanedParams = Object.keys(filter_param)
      .filter(
        (key) => Array.isArray(filter_param[key]) && filter_param[key].length != 0,
      )
      .reduce((acc, key) => {
        acc[key] = filter_param[key];
        return acc;
      }, {});

    const filter_param_key = Object.keys(cleanedParams);
    const filter_param_value = [].concat(...Object.values(cleanedParams));

    dispatch(getFilterCriteria());

    const data = await filterCriteriaService(filter_param_key, filter_param_value);

    dispatch(filteredDocuments(data));
  } catch (error) {
    dispatch(filterFailed());
  }
};

export const onFilterCriteriaThunk = (isFiltering) => async (dispatch) => {
  try {
    dispatch(onFiltering(isFiltering));
  } catch (error) {
    throw error;
  }
};

export const documentDetailsThunk = (id) => async (dispatch) => {
  try {
    const data = await documentDetailsService(id);

    dispatch(documentDetailsState(data));
  } catch (error) {
    dispatch(getDocumentDetailFailed());
  }
};

export const documentClearDetailsThunk = () => async (dispatch) => {
  try {
    dispatch(documentDetailsState({}));
  } catch (error) {
    dispatch(getDocumentDetailFailed());
  }
};

export const fullTextSearchThunk = (search_query) => async (dispatch) => {
  try {
    dispatch(getSearchResult());

    const data = await documentFullTextSearchService(search_query);

    dispatch(searchResultState(data));
  } catch (error) {
    dispatch(getSearchResultFailed());
  }
};

export const listDocumentsThunk = () => async (dispatch) => {
  try {
    dispatch(getDocumentList());

    const data = await getDocumentsService();

    dispatch(listDocumentsState(data));
  } catch (error) {
    dispatch(getDocumentListFailed());
  }
};

export const listDocumentCategoriesThunk = () => async (dispatch) => {
  try {
    const data = await getDocumentCategoryService();

    dispatch(getDocumentCategory(data));
  } catch (error) {
    throw error;
  }
};

export const listDocumentTypesThunk = () => async (dispatch) => {
  try {
    const data = await getDocumentTypeService();

    dispatch(getDocumentTypes(data));
  } catch (error) {
    throw error;
  }
};

export const listPersonsThunk = () => async (dispatch) => {
  try {
    const data = await getPersonsService();

    dispatch(getPersons(data));
  } catch (error) {
    throw error;
  }
};
