import React from 'react';
import compose from 'lodash/fp/compose';
import { connect } from 'react-redux';
import hoistNonReactStatics from 'hoist-non-react-statics';
import * as orgProposalActions from 'actions/admin/orgProposal';
import { BaseHOC, withBaseHOCProps } from '../common/BaseHOC';

const initialState = {
  loaded: false,
};

export const withAdminOrgProposal = (WrappedComponent) => {
  class WithOrgProposal extends BaseHOC {
    state = { ...initialState };

    async componentDidMount() {
      const { getAllOrgProposals } = this.props;
      if (!this.state.loaded) {
        await getAllOrgProposals();
        this.setState({ ...initialState, loaded: true });
      }
    }

    getOrgProposal = async (id) => {
      const { getOrgProposal } = this.props;
      const data = await getOrgProposal(id);
      return data;
    };

    approveOrgProposal = async (id) => {
      const { approveOrgProposal } = this.props;
      await approveOrgProposal(id);
    };

    rejectOrgProposal = async (id) => {
      const { rejectOrgProposal } = this.props;
      await rejectOrgProposal(id);
    };

    bulkDeleteOrgProposals = async (ids) => {
      const { bulkDeleteOrgProposals } = this.props;
      await this._deleteBulk(bulkDeleteOrgProposals, ids);
    };

    getOrgProposalStatus = (proposal) => {
      return proposal ? proposal.status : '';
    };

    render() {
      return (
        <WrappedComponent
          {...this.props}
          loaded={this.state.loaded}
          getOrgProposal={this.getOrgProposal}
          approveOrgProposal={this.approveOrgProposal}
          rejectOrgProposal={this.rejectOrgProposal}
          bulkDeleteOrgProposals={this.bulkDeleteOrgProposals}
          getOrgProposalStatus={this.getOrgProposalStatus}
        />
      );
    }
  }

  const mapStateToProps = ({ adminOrgProposal }) => ({ adminOrgProposal });

  const AdminOrgProposal = connect(
    mapStateToProps,
    orgProposalActions,
  )(WithOrgProposal);

  hoistNonReactStatics(AdminOrgProposal, WrappedComponent);

  return compose(withBaseHOCProps)(AdminOrgProposal);
};

export default withAdminOrgProposal;
