import axios from '../../axios';
import * as types from "../types";
import { setMsgInfo, createAction } from "../";
import API from "../api";
import filterError from "../common/filterError";

const baseUrl = API + "/dashboard/fields";

export const getFieldByFormId = (id) => { 
  return async dispatch => { 
    try {
      const { data } = await axios.get(baseUrl + "/" + id + "/get_form_fields")
      dispatch(createAction(types.GET_FORM_FIELDS, data));
      return data;
    } catch (err) {
      const errors = filterError(err);
      dispatch(setMsgInfo({
        success: false, 
        msg: errors,
      }));
      throw err;
    }
  }
}

export const bulkFieldUpdate = (payload) => { 
  return async dispatch => { 
    try {
      const { data } = await axios.put(baseUrl + "/bulk_update", payload)
      dispatch(createAction(types.GET_FORM_FIELDS, data));
      dispatch(setMsgInfo({
        success: true, 
        msg: ["Fields updated successfully"],
      }));
      return data;
    } catch (err) {
      const errors = filterError(err);
      dispatch(setMsgInfo({
        success: false, 
        msg: errors,
      }));
      throw err;
    }
  }
}

export const getAllField = () => { 
  return async dispatch => { 
    try {
      const { data } = await axios.get(baseUrl)
      dispatch(createAction(types.GET_ALL_FIELDS, data));
      return data;
    } catch (err) {
      const errors = filterError(err);
      dispatch(setMsgInfo({
        success: false, 
        msg: errors,
      }));
      throw err;
    }
  }
}


/* Field Option Actions */

const baseFieldOptionUrl = API + "/dashboard/field-option";

export const updateFieldOption = (payload) => { 
  return async dispatch => { 
    try {
      const { data } = await axios.patch(`${baseFieldOptionUrl}/${payload.id}`, payload)
      dispatch(createAction(types.UPDATE_FIELDS, data));
      dispatch(setMsgInfo({
        success: true, 
        msg: ["Field Option configuration saved"],
      }));
      return data;
    } catch (err) {
      const errors = filterError(err);
      dispatch(setMsgInfo({
        success: false, 
        msg: errors,
      }));
      throw err;
    }
  }
}


