import React from 'react';
import compose from 'lodash/fp/compose';
import { connect } from 'react-redux';
import hoistNonReactStatics from 'hoist-non-react-statics';
import * as contractActions from 'actions/dashboard/contract';
import { BaseHOC, withBaseHOCProps } from '../common/BaseHOC';

// let loaded = false;

const initialState = {
  loaded: false,
};

export const withContract = (WrappedComponent) => {
  class WithContract extends BaseHOC {
    state = { ...initialState };

    async componentDidMount() {
      const { getContracts } = this.props;
      if (!this.state.loaded) {
        await getContracts();
        this.setState({ ...initialState, loaded: true });
        // loaded = true;
      }
    }

    get contractInfoOptions() {
      const { contract } = this.props;
      return contract.data.map(({ id, ...rest }) => {
        return {
          id,
          name: `${rest.description}`,
        }
      });
    }

 

    deleteContractById = async (data) => {
      const { deleteContractById } = this.props;
      await this._deleteById(deleteContractById, data);
    };

    deleteBulkContract = async (data) => {
      const { deleteBulkContract } = this.props;
      await this._deleteBulk(deleteBulkContract, data);
    };

    render() {
      return (
        <WrappedComponent
          {...this.props}
          loaded={this.state.loaded}
          contractInfoOptions={this.contractInfoOptions}
          deleteBulkContract={this.deleteBulkContract}
          deleteContractById={this.deleteContractById}
        />
      );
    }
  }
  const mapStateToProps = ({ contract }) => ({ contract });
  const Contract = connect(mapStateToProps, contractActions)(WithContract);
  hoistNonReactStatics(Contract, WrappedComponent);
  return compose(withBaseHOCProps)(Contract);
};
