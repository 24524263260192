/* eslint-disable camelcase */
import { useEffect } from 'react';
import { listIncomeTransaction } from 'actions';
import { useDispatch, useSelector } from 'react-redux';

export const useListTransaction = (category) => {
  const dispatch = useDispatch();
  const { income } = useSelector((state) => state.transaction);

  useEffect(() => {
    dispatch(listIncomeTransaction(category));
  }, [category, dispatch]);

  return income.filter(({ transaction_item }) => {
    return (
      transaction_item[0].account_category === category ||
      transaction_item[1].account_category === category
    );
  });
};
