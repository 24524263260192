import React from 'react'
import check from 'check-types'
import styled from 'styled-components'
import { isEmptyValue } from 'components/utils/checkValue'
import ImageLink from "./image_holder.svg";

export const Image = (props) => {

  const { src, ...rest } = props;
  const file = check.array(src) ? src[0] : src;
  const [imgSrc, setImgSrc] = React.useState({ src: ImageLink });

  React.useEffect(() => {
    if (!check.string(file) && !isEmptyValue(file)) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function() {
        setImgSrc({ src: reader.result });
      };
    } else if (check.string(file) && imgSrc.src !== file) {
      setImgSrc({ src: file });
    }
  }, [file]);


  return (
    <StyledImage {...rest} {...imgSrc} loading="lazy" />
  )
}

const StyledImage = styled.img`
  width: 100%;
  overflow: hidden;
  display: inline-block;
  object-fit: contain;
`
