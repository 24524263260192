import React from 'react';
import compose from 'lodash/fp/compose';
import styled from 'styled-components';

import { Input, Select, FileInput } from '../../common/FormItems';

import {
  withCountry,
  withTranslation,
  withLegalForm,
  withDocumentType,
  withState,
} from '../../hoc';

const CreateOrgForm = ({
  onChangeOrg,
  orgFormData,
  countryLocaleOptions,
  legalFormLocaleOptions,
  documentTypeLocaleOptions,
  getLegalFormByCountryId,
  getStateByCountryId,
  stateLocaleOptions,
  getRegisterInstitute,
  registerInstitutesOptions,
  fetchingState,
}) => {
  return (
    <React.Fragment>
      <Input
        onChange={onChangeOrg}
        value={orgFormData.name || ""}
        name="name"
        label={"Organisation"}
        placeholder={"Organisation"}
        required
      />
      <Select
        onChange={(e) => {
          onChangeOrg(e);
          getStateByCountryId(e.target.value);
          getLegalFormByCountryId(e.target.value);
        }}
        value={orgFormData.org_country || ""}
        name="org_country"
        label={"Country"}
        required
        optionDefaultValue={"Country"}
        localeOptions={countryLocaleOptions}
      />
      <Select
        isLoading={fetchingState}
        onChange={(e) => {
          onChangeOrg(e);
          getRegisterInstitute(e.target.value);
        }}
        value={orgFormData.state || ""}
        name="state"
        label={"State"}
        required
        optionDefaultValue={"State"}
        localeOptions={stateLocaleOptions}
      />
      <Select
        onChange={onChangeOrg}
        required
        value={orgFormData.legal_form || ""}
        name="legal_form"
        label={"Legal Form"}
        optionDefaultValue={"Choose Legal Form"}
        localeOptions={legalFormLocaleOptions}
      />
      <Select
        onChange={onChangeOrg}
        required
        value={orgFormData.register_institute || ""}
        name="register_institute"
        label={"Registration Institution"}
        optionDefaultValue={"Registration Institution"}
        localeOptions={registerInstitutesOptions}
      />

      <Input
        onChange={onChangeOrg}
        required
        value={orgFormData.register_no || ""}
        name="register_no"
        label={"Official Register No"}
        placeholder={"Official Register No"}
      />

      <Select
        onChange={onChangeOrg}
        required
        value={orgFormData.register_doc_type || ""}
        name="register_doc_type"
        label={'Register Document Type'}
        optionDefaultValue={'Register Document Type'}
        localeOptions={documentTypeLocaleOptions.filter(
          (doc) => doc.group === 'register_doc'
        )}
      />

      <FileInput
        onChange={onChangeOrg}
        required
        value={orgFormData.register_doc}
        name="register_doc"
        label={"Register Document"}
      />

      <Input
        icon="lock"
        onChange={onChangeOrg}
        required
        value={orgFormData.org_password || ""}
        name="org_password"
        label={"Organisation Password"}
        placeholder={"Organisation Password"}
        type="password"
        fieldType="password"
      />
      
    </React.Fragment>
  );
};

export default compose(
  withCountry,
  withTranslation,
  withLegalForm,
  withLegalForm,
  withState,
  withDocumentType
)(CreateOrgForm);
