import React from "react";
import { connect } from "react-redux";
import compose from "lodash/fp/compose";
import * as joinBuilderActions from "../../../actions/dashboard/joinBuilder";
import hoistNonReactStatics from 'hoist-non-react-statics'
import { 
  withDeleteConfirmation,
} from "..";
import { 
  createAction,
  SET_JOIN_BUILDER_STATE,
} from "../../../actions";


let loaded = false;

export const withJoinBuilder = (WrapperComponent) => {
  class JoinBuilder extends React.Component {
    componentDidMount() {
      const { getAllJoinBuilder } = this.props;
      if (loaded) return;
      loaded = true;
      getAllJoinBuilder();
    }
    getJoinBuilderOptions = () => {
      const { data } = this.props.allJoinBuilder;
      return data.map(({ id, description }) => ({ id, name: description }));
    }
    deleteJoinBuilderById = async (data) => {
      const { deleteConfirmation, deleteJoinBuilderById } = this.props;
      try {
        await deleteConfirmation.init(deleteJoinBuilderById, data)
      } catch (err) {}
    }
    render() {
      return (
        <WrapperComponent 
          {...this.props} 
          joinBuilderOptions={this.getJoinBuilderOptions()} 
          deleteJoinBuilderById={this.deleteJoinBuilderById}
        />
      )
    }
  }
  const mapStateToProps = ({ joinBuilder, allJoinBuilder }) => ({ joinBuilder, allJoinBuilder })
  const WithJoinBuilder = connect(mapStateToProps, {
    ...joinBuilderActions,
    setJoinBuilderState: payload => createAction(SET_JOIN_BUILDER_STATE, payload),
  })(JoinBuilder);
  hoistNonReactStatics(WithJoinBuilder, WrapperComponent)
  return compose(withDeleteConfirmation)(WithJoinBuilder)
}
