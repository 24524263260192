import API from './api';

//Documents API endpoints
export const baseDocumentUrl = API + '/dashboard/documents';

export const FILTER_CRITERIA = `${baseDocumentUrl}/filter-criteria/?filter=`;
export const DOCUMENT_DETAILS = `${baseDocumentUrl}/`;
export const DOCUMENTS_FULL_TEXT_SEARCH = `${baseDocumentUrl}/search/?search=`;
export const LIST_ALL_DOCUMENTS = baseDocumentUrl;
export const CREATE_DOCUMENTS = baseDocumentUrl;
export const DELETE_DOCUMENT = `${baseDocumentUrl}/`;
export const DOCUMENT_CATEGORIES = `${API}/document-category/`;
export const DOCUMENT_TYPE = `${API}/document-type/`;

//Notificaion API endpoints
const baseNotificationUrl = API + '/dashboard/notifications';

export const LIST_ALL_NOTIFICATIONS = baseNotificationUrl;
export const DELETE_NOTIFICATION = `${baseNotificationUrl}/`;

//Personal info endpoints
const basePeronalInfoUrl = API + '/dashboard/personal_info';

export const PERSONS = basePeronalInfoUrl;

//Dashboard Api endpoints
const baseDashboardUrl = API + '/dashboard/dashboard_summary/';

export const DASHBOARD_SUMMARY = baseDashboardUrl;
