import * as types from "../../actions/types";


const initialState = {
  data: {},
  showPopup: false,
  isLoading: false,
  error: null,
};

const comment = (state=initialState, action={}) => { 
  switch(action.type){ 
    case types.ADD_COMMENT_SUCCESS: 
      return { ...state, data: action.payload, isLoading: false, showPopup: true };
    case types.COMMENT_POPUP: 
      return { ...state, showPopup: action.payload, };
    case types.ADD_COMMENT_LOADING: 
      return { ...state, isLoading: true };
    case types.ADD_COMMENT_FAILURE: 
      return { ...state, isLoading: false, error: action.payload };
    default:
      return state
  }
}


export default { comment };
