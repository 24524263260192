import { AUTH, ENABLE_USER_SUBSCRIPTION_PLAN, createTypes } from '../actions';

const initialState = {
  user: {},
  isLoading: false,
};

export const account = (state = initialState, action = {}) => {
  switch (action.type) {
    case createTypes(AUTH).success:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
      };
    case createTypes(AUTH).loading:
      return {
        ...state,
        isLoading: action.payload,
      };
    case ENABLE_USER_SUBSCRIPTION_PLAN:
      return {
        ...state,
        user: { ...state.user, has_active_plan: true },
        isLoading: false,
      };
    default:
      return state;
  }
};
