import React from 'react';
import check from 'check-types';
import { connect } from 'react-redux';
import { addComment } from '../../../actions';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { isEmptyValue } from 'components/utils/checkValue';
// import { excludeUnsetField } from 'components/utils/excludeUnsetField';
import { withMsgInfo, withTranslation } from '..';
import { withRouter } from 'react-router-dom';

export const withComment = (WrapperComponent) => {
  class Comment extends React.Component {
    state = {
      isLoading: false,
    };

    onChange = ({ target }) => {
      const { name, value } = target;
      this.setState({ [name]: value });
    };

    onSubmit = async () => {
      const payload = { ...this.state };
      const { addComment, setMsgInfo, tr, history } = this.props;

      const formData = new FormData();

      // Object.entries(payload)
      //   .forEach(([key, value]) => {
      //     formData.append(key, value);
      //   })

      Object.entries(payload).forEach(([key, value]) => {
        if (key === 'document') {
          // exluding person_picture if it's string or null, meaning no
          // change made to the field
          if (!check.string(value) && !isEmptyValue(value)) {
            formData.append(key, value);
          }
        } else {
          formData.append(key, value);
        }
      });

      try {
        this.setState({ isLoading: true });
        await addComment(formData);
        setMsgInfo({ success: true, msg: ['Comment sent successfully'] });
        this.setState(this.clearState());
        history.push(`dashboard/`);
      } catch (e) {
        this.setState({ isLoading: false });
      }
    };

    clearState = () => {
      return Object.entries(this.state).reduce((accum, [key, value]) => {
        if (check.string(value)) {
          accum[key] = '';
        } else {
          accum[key] = null;
        }
        return accum;
      }, {});
    };
    render() {
      return (
        <WrapperComponent
          {...this.props}
          isLoading={this.state.isLoading}
          commentFormData={this.state}
          onChangeComment={this.onChange}
          onSubmitComment={this.onSubmit}
          commentFormSubmitting={this.state.isLoading}
        />
      );
    }
  }
  // const mapStateToProps = ({ comment }) => ({ comment })
  const WithComment = connect(null, {
    addComment,
  })(Comment);
  hoistNonReactStatics(WithComment, WrapperComponent);
  return withMsgInfo(WithComment, withTranslation, withRouter);
};
