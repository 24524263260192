import React from 'react';
import compose from 'lodash/fp/compose';
import { connect } from 'react-redux';
import hoistNonReactStatics from 'hoist-non-react-statics';
import * as entryProposalActions from 'actions/admin/entryProposal';
import { BaseHOC, withBaseHOCProps } from '../common/BaseHOC';

const initialState = {
  loaded: false,
};

export const withAdminEntryProposal = (WrappedComponent) => {
  class WithEntryProposal extends BaseHOC {
    state = { ...initialState };

    async componentDidMount() {
      const { getAllEntryProposals } = this.props;
      if (!this.state.loaded) {
        await getAllEntryProposals();
        this.setState({ ...initialState, loaded: true });
      }
    }

    getEntryProposal = async (id) => {
      const { getEntryProposal } = this.props;
      const data = await getEntryProposal(id);
      return data;
    };

    approveProposal = async (payload) => {
      const { approveProposal } = this.props;
      await approveProposal(payload);
    };

    rejectProposal = async (id) => {
      const { rejectProposal } = this.props;
      await rejectProposal(id);
    };

    bulkDeleteProposals = async (ids) => {
      const { bulkDeleteProposals } = this.props;
      await this._deleteBulk(bulkDeleteProposals, ids);
    };

    getProposalStatus = (proposal) => {
      return proposal ? proposal.status : '';
    };

    render() {
      return (
        <WrappedComponent
          {...this.props}
          loaded={this.state.loaded}
          getEntryProposal={this.getEntryProposal}
          approveProposal={this.approveProposal}
          rejectProposal={this.rejectProposal}
          bulkDeleteProposals={this.bulkDeleteProposals}
          getProposalStatus={this.getProposalStatus}
        />
      );
    }
  }

  const mapStateToProps = ({ adminEntryProposal }) => ({ adminEntryProposal });

  const AdminEntryProposal = connect(
    mapStateToProps,
    entryProposalActions,
  )(WithEntryProposal);

  hoistNonReactStatics(AdminEntryProposal, WrappedComponent);

  return compose(withBaseHOCProps)(AdminEntryProposal);
};

export default withAdminEntryProposal;
