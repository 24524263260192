import * as types from "actions/types";

const initialState = {
  data: [],
};

const userLayoutChoice = (state=initialState, action={}) => { 
  switch(action.type){ 
    case types.GET_USER_LAYOUT_CHOICE: 
      return { ...state, data: action.payload,  };
    default:
      return state
  }
}

export default { userLayoutChoice };

