import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getExpenseItemReference } from '../actions';

export const useExpenseItemReference = () => {
  const dispatch = useDispatch();
  const expenseItemReferenceOptions = useSelector((state) => state.expenseItemReference);

  useEffect(() => {
    dispatch(getExpenseItemReference());
  }, [dispatch]);
  return expenseItemReferenceOptions ;
};
