import React from 'react';
import styled from 'styled-components';

const Table = styled.table`
  width: 100%;
  background: var(--admincat-color-grey-1);
  thead,
  tbody {
    white-space: nowrap;
  }
  && th,
  && td {
    height: 36px;
    line-height: 40px;
    padding: 0 1rem;
  }

  th {
    cursor: pointer;
    user-select: none;
    // background: #eee;
    background: var(--admincat-color-grey-1);

    position: sticky;
    top: 0px;
    z-index: 50;
    p {
      font-size: 1rem;
      display: inline-block;
      transform: translateY(1px);
    }
  }

  tbody tr {
    // background: #fff;
    background: var(--admincat-color-grey-1);
    transition: background 0.4s;
  }

  tbody tr:hover {
    background: rgba(0, 0, 0, 0.08);
  }
`;

const SearchOrgResult = ({ search, trObj, selectOrg, checkboxType }) => {
  console.log(search);
  return (
    <div className="card" style={{ background: 'var(--admincat-color-grey-1)' }}>
      <div className="table-responsive">
        <Table className="table">
          <thead>
            <tr>
              <th />
              <th>Organisation</th>
              <th>Legal Form</th>
              <th>Country</th>
              <th>State</th>
              <th>Register Institute</th>
              <th>Register No.</th>
              <th>Industry Type</th>
            </tr>
          </thead>
          <tbody style={{ color: 'var(--admincat-color-grey-3)' }}>
            {search.map((org) => (
              <tr key={org.id}>
                <td>
                  <input
                    name="orgSearchResult"
                    type={checkboxType}
                    onChange={() => selectOrg(org.id)}
                  />
                </td>
                <td>{org.name}</td>
                <td>{trObj(org.legal_form_detail)}</td>
                <td>{trObj(org.org_country_detail)}</td>
                <td>{trObj(org.state_detail)}</td>
                <td>{org.register_institute_detail.name}</td>
                <td>{org.register_no}</td>
                <td>{trObj(org.industry_type_detail)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

SearchOrgResult.defaultProps = {
  checkboxType: 'checkbox',
};

export default SearchOrgResult;
