/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import styled from 'styled-components';
import { Route, Switch, Redirect } from 'react-router-dom';

const RouteRedirectWrapper = styled.div`
  flex: 1;
  width: 100%;
  // background: #fff;
`;

const RouteRedirect = ({ routes, redirects, className }) => (
  <RouteRedirectWrapper className={`${className} RouteRedirect`}>
    <Switch>
      {routes.map(({ component: Component, subRoutes, ...route }, index) =>
        subRoutes ? (
          subRoutes.map((subRoute, keyIndex) => (
            <Route
              key={keyIndex}
              path={subRoute.path}
              exact={subRoute.exact}
              render={(props) => <subRoute.component {...props} />}
            />
          ))
        ) : (
          <Route
            key={index}
            path={route.path}
            exact={route.exact}
            render={(props) => Component && <Component {...props} />}
          />
        ),
      )}
      {redirects.map((redirect, index) => (
        <Redirect key={index} from={redirect.from} to={redirect.to} />
      ))}
    </Switch>
  </RouteRedirectWrapper>
);

RouteRedirect.defaultProps = {
  routes: [],
  redirects: [],
};

export default RouteRedirect;
