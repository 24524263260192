import React from 'react';
import 'chart.js/auto';
import { Doughnut } from 'react-chartjs-2';

import compose from 'lodash/fp/compose';
import styles from './chart.module.css';
import { withTranslation, withUserPreferences } from 'components/hoc';

const ExpenseChart = ({
  tr,
  dataList_1_year,
  dataList_3_year,
  dataList_5_year,
  dataList_100_year,
  fn,
}) => {
  const [interval, setInterval] = React.useState('year_1');

  const dataList =
    interval == 'year_1'
      ? dataList_1_year
      : interval == 'year_3'
      ? dataList_3_year
      : interval == 'year_5'
      ? dataList_5_year
      : interval == 'year_10'
      ? dataList_100_year
      : [];

  const options = {
    tooltips: {
      enabled: true,
    },
    responsive: true,
    maintainAspectRatio: false,
    title: {
      display: true,
      fontSize: 20,
    },
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        align: 'start',
        labels: {
          // This more specific font property overrides the global property
          font: {
            size: 14,
          },
        },
      },
    },
  };

  const data = {
    labels: dataList?.map((expense) => tr(expense?.financial_account_name)),

    datasets: [
      {
        label: '# Amount of Expsense',
        data: dataList?.map((expense) => expense?.amount.toFixed(2)),
        type: 'doughnut',
        hoverOffset: 40,
        cutout: 100,
        radius: '80%',
        backgroundColor: [
          'rgb(255, 99, 132)',
          'rgb(54, 162, 235)',
          'rgb(255,140,0)',
          'rgb(255, 0, 0)',
          'rgb(64, 0, 255)',
          '#788087',
          '#E0B0FF	',
          '#002147',
          '#014421',
          'rgb(128,0,128)',
          'rgb(0,100,0)',
          '#3C1414',
          'rgb(205,92,92)',
          'rgb(139,69,19)',
          '#01995C',
          '#CA1F7B',
          'rgb(255, 255, 0)',
          '#4B0082',
          'rgb(0,139,139)',
        ],
      },
    ],
  };

  return (
    <>
      <div className={styles['interval_btn_container']}>
        <select
          value={interval}
          onChange={({ target }) => setInterval(target.value)}
          style={{
            height: 38,
            transition: 'all ease-in-out 300ms',
            borderRadius: 2,
            background: '#d5e4f7',
            color: '#4576b3',
            fontSize: 14,
            overflow: 'hidden',
            fontWeight: 500,
            padding: 10,
            border: 0,
          }}
        >
          <option value="year_1">{tr('1 year')}</option>
          <option value="year_3">{tr('3 years')}</option>
          <option value="year_5">{tr('5 years')}</option>
          <option value="year_10">{tr('10 years')}</option>
        </select>
      </div>

      <Doughnut data={data} options={options} />
    </>
  );
};

export default compose(withTranslation, withUserPreferences)(ExpenseChart);
