import React from 'react';
import compose from 'lodash/fp/compose';
import styled from 'styled-components';
import { withTranslation, withFieldError } from '../../../hoc';
import { inputStyle, InputWrapper } from '../Input';

const StyledTextArea = styled.textarea`
  ${inputStyle}
  height: 150px;
  resize: none;
  outline: none;
  width: 100%;
`;

const TextArea = compose(
  withFieldError,
  withTranslation,
)((props) => {
  const { className, label, required, name, tr, placeholder, ...rest } = props;
  return (
    <InputWrapper className={className}>
      {label && (
        <label className="form-label" htmlFor={name}>
          {tr(label || '')}
          {required && <span className="form-required">*</span>}
        </label>
      )}
      <StyledTextArea
        name={name}
        rows={30}
        placeholder={tr(placeholder)}
        {...rest}
      />
    </InputWrapper>
  );
});

TextArea.defaultProps = {
  className: '',
};

export default TextArea;
