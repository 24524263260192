import { getCurrencyRate } from 'actions/dashboard/currencyRate';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFinanceCategory } from '../actions';

export const useCurrencyRate = () => {
  const dispatch = useDispatch();
  const currencyRate =useSelector((state) => state.currencyRate.data);

  useEffect(() => {
    dispatch(getCurrencyRate());
  }, [dispatch]);

  return {
    currencyRate,
  };
};
