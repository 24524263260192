import * as types from '../../../actions/dashboard/finance/types';

const initialSate = {
  income: [],
  loading: false,
  item: {},
};

export function transaction(state = initialSate, action = {}) {
  switch (action.type) {
    case types.CREATE_INCOME_TRANSACTION:
      return {
        ...state,
        income: [...state.income, action.payload],
      };
    case types.LIST_INCOME_TRANSACTION:
      return {
        ...state,
        loading: action.payload.loading,
        income: action.payload.data,
      };
    case types.DELETE_BULK_INCOME_TRANSACTION:
      return {
        ...state,
        income: [
          ...state.income.filter((item) => !action.payload.includes(item.id)),
        ],
      };

    case types.DELETE_INCOME_TRANSACTION:
      return {
        ...state,
        income: [...state.income.filter((item) => item.id !== action.payload)],
      };
    case types.GET_INCOME_TRANSACTION:
      return {
        ...state,
        item: action.payload,
      };
    case types.UPDATE_INCOME_TRANSACTION:
      return {
        ...state,
        income: [...state.income].map((item) => {
          if (item.id === action.payload.id) {
            return action.payload;
          }
          return item;
        }),
      };
    default:
      return state;
  }
}
