import React from 'react'
import styled from 'styled-components'

const Info = styled.i`
  text-decoration: underline;
`

const FlexBox = styled.div`
  display: flex;
  flex-direction: column;
`

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  border: 1px solid #ff0000;
  border-radius: 4px;
  background-color: #fff0f0;
`;

const ErrorMessage = styled.h3`
  color: #ff0000;
`;

const ErrorDetails = styled.pre`
  white-space: pre-wrap;
  word-wrap: break-word;
  font-size: 14px;
`;

export class ErrorBoundary extends React.Component {
  state = { hasError: false }

  static getDerivedStateFromError() {    
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {    
    if (process.env.NODE_ENV === 'development') {
      console.error('Error:', error);
      console.error('Error Info:', errorInfo);
    }
    this.setState({ error, errorInfo })
  }

  render() {
    const { hasError, error, errorInfo } = this.state
    if (hasError) {
      // Render error UI only in development mode
      if (process.env.NODE_ENV === 'development') {
        return (
          <ErrorContainer>
            <ErrorMessage>Something went wrong</ErrorMessage>
            <ErrorDetails>{error && error.toString()}</ErrorDetails>
            <ErrorDetails>{errorInfo && errorInfo.componentStack}</ErrorDetails>
          </ErrorContainer>
        );
      }
      // In production, render a user-friendly error message
      return <ErrorMessage>Oops! Something went wrong. Please try again later.</ErrorMessage>;
    }
    // if (hasError) {      
    //   return (
    //     <FlexBox>
    //       <h3>Something went wrong: <Info>{error?.message}</Info></h3>
    //       <pre>{errorInfo?.componentStack}</pre>
    //     </FlexBox>
    //   )
    // }
    return this.props.children 
  }
}

// export const withErrorBoundary = (WrappedComponet) => (props) => {
//   return (
//     <ErrorBoundary>
//       <WrappedComponet {...props} /> 
//     </ErrorBoundary>
//   )
// }

export const withErrorBoundary = (WrappedComponent) => {
  const WithErrorBoundary = (props) => (
    <ErrorBoundary>
      <WrappedComponent {...props} />
    </ErrorBoundary>
  );
  WithErrorBoundary.displayName = `WithErrorBoundary(${getDisplayName(WrappedComponent)})`;
  return WithErrorBoundary;
};

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}
