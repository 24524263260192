import React, { Component } from 'react';
import NavBar from './navBar';
import H3 from './typography/h3';
import H4 from './typography/h4';
import H5 from './typography/h5';
import Paragraph from './typography/p';
import Img from './Img';
import Icon from './icons';
import { SectionOne, SectionTwo, SectionThree } from './style';
import Testimonial from './testimonials';
import LaptopImage from './images/laptop-screen.png';
import Circle from './circle';
import HereForYou from './hereForYou';
import { useTranslation } from 'react-i18next';
import ScrollAnimation from 'react-animate-on-scroll';
import finance from './images/finance.svg';
import documents from './images/document.svg';
import shield from './images/shield.svg';
import envelope from './images/envelope.svg';
import calendar from './images/calendar.svg';
import family from './images/user-friends.svg';
import shapeTwo from './images/hero-shape-1.svg';
import shapeOne from './images/hero-shape-2.png';

const HomePage = ({ tr }) => {
  const { t } = useTranslation();
  
  return (
    <div>
      <SectionOne>
        <div className="shape-one">
          <Img src={shapeOne} />
        </div>
        <div className="shape-two">
          <Img src={shapeTwo} />
        </div>
        <H3
          color="#000"
          fontFamily="'Gothic A1', sans-serif"
          fontWeight="normal"
          align="center"
        >
          {' '}
          {t('Welcome to your digital workspace!')}{' '}
        </H3>

        <div className="div-image">
          <ScrollAnimation animateIn="fadeInUp">
            <Img src={LaptopImage} />
          </ScrollAnimation>
        </div>

        <div className="card-wrapper">
          <ScrollAnimation animateIn="fadeInRight">
            <div className="card-content card-content-border">
              <Circle />
              <H5
                color="#000"
                fontWeight="bold"
                fontFamily="Finger Paint"
                align="center"
              >
                {' '}
                {t('Simplify bureaucracy!')}{' '}
              </H5>{' '}
              <br />
              <Paragraph color="#000" align="center">
                {' '}
                {t(
                  'We provide features that make your private administration easy and fun.',
                )}{' '}
              </Paragraph>
            </div>
          </ScrollAnimation>

          <ScrollAnimation animateIn="fadeInUp">
            <div className="card-content card-content-border">
              <Circle color="#50E3C2" background="rgba(80, 163, 227, 0.06)" />
              <H5
                color="#000"
                fontWeight="bold"
                fontFamily="Finger Paint"
                align="center"
              >
                {' '}
                {t('The control station for your life!')}{' '}
              </H5>{' '}
              <br />
              <Paragraph color="#000" align="center">
                {' '}
                {t('Our dashboard enables you to take control like a captain.')}{' '}
              </Paragraph>
            </div>
          </ScrollAnimation>

          <ScrollAnimation animateIn="fadeInLeft">
            <div className="card-content">
              <Circle color="#A665FB" background="rgba(166, 101, 251, 0.06)" />
              <H5
                color="#000"
                fontWeight="bold"
                fontFamily="Finger Paint"
                align="center"
              >
                {' '}
                {t('All you need in one App!')}{' '}
              </H5>{' '}
              <br />
              <Paragraph color="#000" align="center">
                {' '}
                {t(
                  'No more headache with incompatible apps. We provide a seamless all-in-one-solution.',
                )}{' '}
              </Paragraph>
            </div>
          </ScrollAnimation>
        </div>
      </SectionOne>

      <SectionTwo>
        <div className="sec-two-header">
          <H4 align="center" fontFamily="Finger Paint" color="#fff">
            {' '}
            {t('Here are some of our wonderful features')}{' '}
          </H4>
        </div>
        <div className="text-wrapper">
          <div className="flex">
            <div className="text-card">
              <ScrollAnimation animateIn="fadeInRight">
                <div className="fixed-flex">
                  <div className="text-card-icon">
                    <Icon background="#4d80b8" src={documents} />
                  </div>
                  <div className="text-card-texts">
                    <H5 fontWeight="normal" color="#fff">
                      {' '}
                      {t('Simply find everything')}{' '}
                    </H5>
                    <Paragraph color="#BBC6D2">
                      {' '}
                      {t(
                        'Digitalize your documents and easily manage them online.',
                      )}{' '}
                    </Paragraph>
                  </div>
                </div>
              </ScrollAnimation>
            </div>

            <div className="text-card">
              <ScrollAnimation animateIn="fadeInLeft">
                <div className="fixed-flex">
                  <div className="text-card-icon">
                    <Icon background="#4d80b8" src={calendar} />
                  </div>
                  <div className="text-card-texts">
                    <H5 color="#fff" fontWeight="normal">
                      {' '}
                      {t('Stay up to date')}
                    </H5>
                    <Paragraph color="#BBC6D2">
                      {t('Don‘t worry about deadlines - we‘ll remind you!')}
                    </Paragraph>
                  </div>
                </div>
              </ScrollAnimation>
            </div>
          </div>

          <div className="flex">
            <div className="text-card">
              <ScrollAnimation animateIn="fadeInRight">
                <div className="fixed-flex">
                  <div className="text-card-icon">
                    <Icon background="#4d80b8" src={finance} />
                  </div>
                  <div className="text-card-texts">
                    <H5 color="#fff" fontWeight="normal">
                      {t('Financial control')}
                    </H5>
                    <Paragraph color="#BBC6D2">
                      {t('Wanna keep an overview on your finances? We got it!')}
                    </Paragraph>
                  </div>
                </div>
              </ScrollAnimation>
            </div>

            <div className="text-card">
              <ScrollAnimation animateIn="fadeInLeft">
                <div className="fixed-flex">
                  <div className="text-card-icon">
                    <Icon background="#4d80b8" src={shield} />
                  </div>
                  <div className="text-card-texts">
                    <H5 color="#fff" fontWeight="normal">
                      {t('Safety first')}
                    </H5>
                    <Paragraph color="#BBC6D2">
                      {t('Centralize your data and documents in one safe place')}
                    </Paragraph>
                  </div>
                </div>
              </ScrollAnimation>
            </div>
          </div>

          <div className="flex">
            <div className="text-card">
              <ScrollAnimation animateIn="fadeInRight">
                <div className="fixed-flex">
                  <div className="text-card-icon">
                    <Icon background="#4d80b8" src={envelope} />
                  </div>
                  <div className="text-card-texts">
                    <H5 color="#fff" fontWeight="normal">
                      {t('Smart Email')}
                    </H5>
                    <Paragraph color="#BBC6D2">
                      {t(
                        'Receive emails and automatically save attachments in the relevant folders.',
                      )}
                    </Paragraph>
                  </div>
                </div>
              </ScrollAnimation>
            </div>

            <div className="text-card">
              <ScrollAnimation animateIn="fadeInLeft">
                <div className="fixed-flex">
                  <div className="text-card-icon">
                    <Icon background="#4d80b8" src={family} />
                  </div>
                  <div className="text-card-texts">
                    <H5 color="#fff" fontWeight="normal">
                      {t('Family-friendly')}
                    </H5>
                    <Paragraph color="#BBC6D2">
                      {t('All data of your family integrated - voilá!')}
                    </Paragraph>
                  </div>
                </div>
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </SectionTwo>

      <SectionThree>
        <div className="wrapper" >
          <div className="sec-three-header">
            <H4 align="center" fontFamily="Finger Paint">
              {t('Customers Trust Us')}
            </H4>
            <Paragraph align="center">
              {t('Here are some of our reviews from our users')}
            </Paragraph>
          </div>
          <Testimonial />
        </div>
      </SectionThree>

      <HereForYou />
    </div>
  );
};

export default HomePage;
