import React from "react";
import compose from "lodash/fp/compose";
import { connect } from "react-redux";
import hoistNonReactStatics from 'hoist-non-react-statics'
import * as citizenshipActions from "actions/dashboard/citizenshipInfo"
import { BaseHOC, withBaseHOCProps } from "../common/BaseHOC"


const initialState = {
  loaded: false,
}

export const withCitizenshipInfo = (WrappedComponent) => {
  class CitizenshipInfo extends BaseHOC {

    state = { ...initialState }

    async componentDidMount() {
      const { getCitizenshipInfo } = this.props
      if (!this.state.loaded)  {
        await getCitizenshipInfo();
        this.setState({ ...initialState, loaded: true })
        // loaded = true;
      }
    }

    // get citizenshipOptions() {
    //   const { citizenshipInfo } = this.props;
    //   return this._getOptions(citizenshipInfo.data, ['id', 'description'])
    //     .map(({ description, ...rest }) => ({ ...rest, name: description }))
    // }

    deleteCitizenshipInfoById = async (data) => {
      const { deleteCitizenshipInfoById } = this.props
      await this._deleteById(deleteCitizenshipInfoById, data);
    }

    deleteBulkCitizenshipInfo = async (data) => {
      const { deleteBulkCitizenshipInfo } = this.props
      await this._deleteBulk(deleteBulkCitizenshipInfo, data);
    }

    render() {
      return (
        <WrappedComponent 
          {...this.props}
          loaded={this.state.loaded}
          deleteCitizenshipInfoById={this.deleteCitizenshipInfoById}
          deleteBulkCitizenshipInfo={this.deleteBulkCitizenshipInfo}
          // citizenshipOptions={this.citizenshipOptions}
        />
      )
    }
  }
  const mapStateToProps = ({ citizenshipInfo }) => ({ citizenshipInfo })
  const WithCitizenshipInfo = connect(mapStateToProps, citizenshipActions)
  (CitizenshipInfo);
  hoistNonReactStatics(WithCitizenshipInfo, WrappedComponent)
  return compose(withBaseHOCProps)(WithCitizenshipInfo);
}