import React from 'react';
import compose from "lodash/fp/compose";
import {
  withAllPersonalWorkspace,
} from "../../hoc";
import { 
  StyledSwitchery,
} from ".";
import {
  Input as AuthInput,
} from "../AuthFormItems";
import { withTranslation, Trans } from "react-i18next";


const workspaceTypes = {
  OU: 'Organisation User',
  PU: 'Personal User',
  JOU: 'Join Organisation User',
  COU: 'Create Organisation User',
  JPU: 'Join Personal User',
  CPU: 'Create Personal User',
};

class Personal extends React.Component {

  onChangePersonalUserType = () => {
    const { newPersonalUserType, onChange } = this.props;
    onChange()({
      newPersonalUserType: !newPersonalUserType,
    })
  }

  render(){
    const { props } = this;
    const { 
      onChange,
      Input = AuthInput,
    } = props;
    
    return (
      <React.Fragment>
        <StyledSwitchery 
          name="newPersonalUserType" 
          id="personalUserType.joinOrg"
          value={!props.newPersonalUserType}
          onChange={this.onChangePersonalUserType}
          label={props.t("join personal workspace")}
        />
        <StyledSwitchery 
          name="newPersonalUserType" 
          id="personalUserType.createOrg"
          value={props.newPersonalUserType}
          onChange={this.onChangePersonalUserType}
          label={props.t("create personal workspace")}
        />

      { 
        !props.newPersonalUserType ? 
          ( 
            <Input 
              icon="users"
              onChange={onChange}
              value={props.join_personal_workspace}
              name="join_personal_workspace"
              optionDefaultValue="Choose Workspace"
              label="Choose Workspace"
              required
            />
          ) : (
            <React.Fragment>
              <Input 
                icon="users"
                onChange={onChange}
                value={props.create_personal_workspace}
                name="create_personal_workspace"
                label="Create Workspace"
                placeholder="Create Workspace"
                required
              />
            </React.Fragment>
          )
      }
    </React.Fragment>
    )
  }
}

export default compose(
  withAllPersonalWorkspace,withTranslation()
)(Personal);

