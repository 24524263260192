import React, { useEffect } from 'react';
import compose from 'lodash/fp/compose';
import { useSelector, useDispatch } from 'react-redux';
import Loader from '../../../components/loader';
import { getOrganisationById } from '../../../actions';

import {
  withOrganisation,
  withCountry,
  withTranslation,
  withLegalForm,
  withState,
  withDocumentType,
  withCurrency,
  withIndustryType,
} from '../../hoc';
import {
  Form,
  Input,
  Select,
  FileInput,
  InputDatePicker,
} from '../../common/FormItems';

const orgFormSchema = {
  name: {
    validation: 'required|min:3',
    message: {
      required: 'Organisation name is required',
      min: 'Organisation name must be at least 3 characters',
    },
  },
  org_country: {
    validation: 'required',
    message: {
      required: 'Organisation country is required',
    },
  },
};

const OrganisationForm = (props) => {
  const {
    countryLocaleOptions,
    legalFormLocaleOptions,
    documentTypeLocaleOptions,
    currencyLocaleOptions,
    industryTypeOptions,
    organisation,
    onChangeOrg,
    onSubmitOrg,
    orgFormSubmitting,
    getRegisterInstitute,
    stateLocaleOptions,
    getLegalFormByCountryId,
    registerInstitutesOptions,
  } = props;

  const dispatch = useDispatch();
  const { singleOrganisation } = useSelector((state) => state.organisation);
  const { user } = useSelector((state) => state.account);
  useEffect(() => {
    if (user.org_id) {
      dispatch(getOrganisationById(user.org_id, 'updateForm'));
    }
  }, [user]);

  const { orgFormData } = organisation;

  if (!user.org_id) {
    return <Loader />;
  }

  const getRegisterDocPreview = () => {
    if (singleOrganisation.register_doc) {
      const pattern = new RegExp('register_docs/..*.pdf');
      return singleOrganisation.register_doc.match(pattern)[0].split('/')[1];
    }
    return '';
  };

  return (
    <Form
      onSubmit={(e) => onSubmitOrg(e, user.org_id)}
      isLoading={orgFormSubmitting}
      title="Update Organisation"
    >
      <Input
        onChange={onChangeOrg}
        value={orgFormData.name || ''}
        name="name"
        label={'Organisation'}
        placeholder={'Organisation'}
      />
      <Select
        onChange={(e) => {
          onChangeOrg(e);
          props.getStateByCountryId(e.target.value);
          getLegalFormByCountryId(e.target.value);
        }}
        value={orgFormData.org_country || ''}
        name="org_country"
        label={'Country'}
        optionDefaultValue={'Choose Country'}
        localeOptions={countryLocaleOptions}
      />
      <Select
        isLoading={props.fetchingState}
        onChange={(e) => {
          onChangeOrg(e);
          getRegisterInstitute(e.target.value);
        }}
        value={orgFormData.state || ''}
        name="state"
        label={'State'}
        placeholder={'State'}
        optionDefaultValue={'State'}
        localeOptions={stateLocaleOptions}
      />
      <Select
        onChange={onChangeOrg}
        value={orgFormData.industry || ''}
        name="industry"
        label={'Industry'}
        optionDefaultValue={'Industry'}
        options={industryTypeOptions}
      />
      <Select
        onChange={onChangeOrg}
        value={orgFormData.legal_form || ''}
        name="legal_form"
        label={'Legal Form'}
        optionDefaultValue={'Choose Legal Form'}
        localeOptions={legalFormLocaleOptions}
      />
      <Select
        onChange={onChangeOrg}
        value={orgFormData.register_institute || ''}
        name="register_institute"
        label={'Registration Institution'}
        optionDefaultValue={'Registration Institution'}
        localeOptions={registerInstitutesOptions}
      />
      <Input
        onChange={onChangeOrg}
        value={orgFormData.register_no || ''}
        name="register_no"
        label={'Official Register No'}
        placeholder={'Official Register No'}
      />
      <InputDatePicker
        label="Foundation"
        value={orgFormData.foundation || ''}
        name="foundation"
        onChange={onChangeOrg}
      />
      <InputDatePicker
        label="Liquidation"
        value={orgFormData.liquidation || ''}
        name="liquidation"
        onChange={onChangeOrg}
      />
      <Input
        value={orgFormData.no_of_employees || ''}
        onChange={onChangeOrg}
        name="no_of_employees"
        id="no_of_employees"
        placeholder="Number of Employees"
        label="Number of Employees"
        type="number"
      />
      <Select
        onChange={onChangeOrg}
        value={orgFormData.revenue_currency || ''}
        name="revenue_currency"
        label={'Revenue Currency'}
        optionDefaultValue={'Revenue Currency'}
        localeOptions={currencyLocaleOptions}
      />
      <Input
        value={orgFormData.revenue_approx || ''}
        onChange={onChangeOrg}
        name="revenue_approx"
        id="revenue_approx"
        placeholder="Revenue Aprroximation"
        label="Revenue Aprroximation"
        type="number"
      />
      <Select
        onChange={onChangeOrg}
        value={orgFormData.register_doc_type || ''}
        name="register_doc_type"
        label={'Register Document Type'}
        optionDefaultValue={'Register Document Type'}
        localeOptions={documentTypeLocaleOptions.filter(
          (doc) => doc.group === 'register_doc'
        )}
      />
      <FileInput
        onChange={onChangeOrg}
        required
        preview={getRegisterDocPreview()}
        value={orgFormData.register_doc}
        name="register_doc"
        label={'Register Document'}
      />
      <Input
        onChange={onChangeOrg}
        value={orgFormData.website || 'http://'}
        name="website"
        label={'Website'}
        placeholder={'Website'}
        type="url"
      />
      <Input
        icon="lock"
        onChange={onChangeOrg}
        value={orgFormData.org_password || ''}
        name="org_password"
        label={'Organisation Password'}
        placeholder={'Organisation Password'}
        type="password"
        fieldType="password"
      />
    </Form>
  );
};

export default compose(
  withOrganisation,
  withCountry,
  withLegalForm,
  withTranslation,
  withState,
  withDocumentType,
  withCurrency,
  withIndustryType,
)(OrganisationForm);

