import axios from '../../../axios';
import * as types from './types';

import { setMsgInfo, createAction } from '../..';
import API from '../../api';

const baseUrl = `${API}/dashboard`;

export const listCreditCard = () => async (dispatch) => {
  try {
    dispatch(createAction(types.LIST_CREDIT_CARD, {data: [], loading: true}));
    const { data } = await axios.get(`${baseUrl}/credit_card`);
    dispatch(createAction(types.LIST_CREDIT_CARD, {data, loading: false}));
  } catch (error) {
    dispatch(createAction(types.LIST_CREDIT_CARD, {data: [], loading: false}));
    dispatch(dispatch(setMsgInfo({
      success: false,
      msg: ['Problem fetching credit card'],
    })));
  }
};

export const getSingleCreditCard = (id) => async (dispatch) => {
  try {
    const { data } = await axios.get(`${baseUrl}/credit_card/${id}`);
    dispatch(createAction(types.GET_CREDIT_CARD, data));
  } catch (error) {
    dispatch(dispatch(setMsgInfo({
      success: false,
      msg: ['Problem fetching credit card'],
    })));
  }
};

export const deleteCreditCard = (id) => async (dispatch) => {
  await axios.delete(`${baseUrl}/credit_card/${id}`);
  dispatch({
    type: types.DELETE_CREDIT_CARD,
    payload: [id],
  });
};

export const deleteBulkCreditCard = (creditCardIds) => async (dispatch) => {
  await axios.post(`${baseUrl}/credit_card/bulk_delete`, { credit_card_ids: creditCardIds });
  dispatch({
    type: types.DELETE_CREDIT_CARD,
    payload: creditCardIds,
  });
};

export const createCreditCard = (values) => async (dispatch) => {
  try {
    const { data } = await axios.post(`${baseUrl}/credit_card`, values);
    dispatch({
      type: types.CREATE_CREDIT_CARD,
      payload: data,
    });
    dispatch(dispatch(setMsgInfo({
      success: true,
      msg: ['Credit card created successfully'],
    })));
  } catch (error) {
    dispatch(dispatch(setMsgInfo({
      success: false,
      msg: ['Problem creating credit card'],
    })));
    return error;
  }
};

export const updateCreditCard = (id, values) => async (dispatch) => {
  try {
    const { data } = await axios.put(`${baseUrl}/credit_card/${id}`, values);
    dispatch({
      type: types.UPDATE_CREDIT_CARD,
      payload: data,
    });
    dispatch(dispatch(setMsgInfo({
      success: true,
      msg: ['Credit card updated successfully'],
    })));
  } catch (error) {
    dispatch(dispatch(setMsgInfo({
      success: false,
      msg: ['Problem updating credit card'],
    })));
    return error;
  }
};
