/* eslint-disable */
import React from 'react';
import compose from 'lodash/fp/compose';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { eng, deu } from 'locale/index';
import { withUserPreferences } from 'components/hoc/index';
// import { Dropdown as DropDownButton  } from 'semantic-ui-react';

export const withTranslation = (WrapperComponent) => {
  class Translation extends React.Component {
    locales = { eng, deu };

    translate = (key) => {
      const locale = this.getLocale();
      const localeData = this.locales[locale] || {};
      try {
        const translatedData = localeData[key.toLowerCase()] || key;
        return translatedData;
      } catch (e) {
        return key;
      }
    };

    translateObject = (obj = {}) => {
      const locale = this.getLocale();
      let description = 'N/A';
      if (!obj) return description;
      if (locale === 'eng') {
        description = obj.long_description_eng;
      } else {
        description = obj.long_description_deu;
      }
      return description;
    };

    getLocale() {
      const { userPreLang } = this.props;
      let locale = userPreLang || 'eng';
      if (locale == 'en') {
        locale = 'eng';
      } else if (locale == 'de') {
        locale = 'deu';
      }
      return locale;
    }

    render() {
      return (
        <WrapperComponent
          {...this.props}
          tr={this.translate}
          trObj={this.translateObject}
        />
      );
    }
  }
  hoistNonReactStatics(Translation, WrapperComponent);
  return compose(withUserPreferences)(Translation);
};
