import * as types from "../../actions/types";
import { createTypes } from "../../actions";
import { baseViewForm } from '../utils/baseViewForm';

const initialState = {
  data: [],
};

const attachmentRuleForm = baseViewForm(types.ATTACHMENT_RULE_FORM)
const attachmentRuleView = baseViewForm(types.ATTACHMENT_RULE_VIEW, 'view')

const attachmentRules = (state=initialState, action={}) => { 
  switch(action.type){ 
    case createTypes(types.GET_ATTACHMENT_RULE).success: 
      return { ...state, data: action.payload,  };
    case createTypes(types.ADD_ATTACHMENT_RULE).success: 
      return { 
        ...state, 
        data: [ action.payload, ...state.data ], 
    };
    case createTypes(types.UPDATE_ATTACHMENT_RULE).success: 
      return { 
        ...state, 
        data: state.data.map((_data) => { 
          if(_data.id === action.payload.id) return action.payload;
          return _data;
        }), 
         
    };
    case createTypes(types.DELETE_ATTACHMENT_RULE_BY_ID).success: 
      return { 
        ...state, 
        data: state.data.filter((_data) => { 
          return _data.id !== action.payload.id;
        }), 
         
    };
    
    case createTypes(types.DELETE_BULK_ATTACHMENT_RULE).success: 
      return { 
        data: state.data.filter((data, index) => {
          return !action.payload.includes(data.id);
        })
      };


    case createTypes(types.ADD_ATTACHMENT_RULE).failure: 
    case createTypes(types.UPDATE_ATTACHMENT_RULE).failure: 
    case createTypes(types.GET_ATTACHMENT_RULE).failure: 
    case createTypes(types.DELETE_ATTACHMENT_RULE_BY_ID).failure: 
    case createTypes(types.DELETE_BULK_ATTACHMENT_RULE).failure: 
      return { ...state, error: action.payload };
    default:
      return state
  }
}


export default { attachmentRuleForm, attachmentRuleView, attachmentRules };
