import styled from "styled-components";


export const TestimonialDiv = styled.div`
    width: 100%;
    margin: 20px auto;

    @media only screen and (max-width:750px){
        width:100%;
    }

.desktop{
    display:flex;
    @media only screen and (max-width:750px){
        display:none;
    }

    .testimonial-card{
        padding:30px;
    }
}

.mobile{
    display: none;
    @media only screen and (max-width:750px){
        display:block;
    }

}
`
