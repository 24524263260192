export const GET_BANK_ACCOUNT_TYPES = 'GET_BANK_ACCOUNT_TYPES';
export const GET_BANK_ACCESS_TYPES = 'GET_BANK_ACCESS_TYPES';
export const GET_ORGANIZATION_WITH_BIC = 'GET_ORGANIZATION_WITH_BIC';
export const GET_ORGANIZATION_WITH_BIC_STARTED = 'GET_ORGANIZATION_WITH_BIC_STARTED';
export const GET_ALL_BANK_ACCOUNT = 'GET_ALL_BANK_ACCOUNT';
export const DELETE_BULK_BANK_ACCOUNT = 'DELETE_BULK_BANK_ACCOUNT';
export const DELETE_BANK_ACCOUNT = 'DELETE_BANK_ACCOUNT';
export const BANK_ACCOUNT_FORM = 'BANK_ACCOUNT_FORM';
export const BANK_ACCOUNT_FORM_VIEW = 'BANK_ACCOUNT_FORM_VIEW';
export const GET_BANK_ACCOUNT_BY_ID = 'GET_BANK_ACCOUNT_BY_ID';
export const UPDATE_BANK_ACCOUNT = 'UPDATE_BANK_ACCOUNT';
export const CLEAR_SINGLE_BANK_ACCOUNT_DATA = 'CLEAR_SINGLE_BANK_ACCOUNT_DATA';
export const CREATE_BANK_ACCOUNT = 'CREATE_BANK_ACCOUNT';
export const BANK_ACCOUNT_LOADER = 'BANK_ACCOUNT_LOADER';

// CREDIT CARD TYPES
export const CREATE_CREDIT_CARD = 'CREATE_CREDIT_CARD';
export const UPDATE_CREDIT_CARD = 'UPDATE_CREDIT_CARD';
export const LIST_CREDIT_CARD = 'LIST_CREDIT_CARD';
export const GET_CREDIT_CARD = 'GET_CREDIT_CARD';
export const DELETE_CREDIT_CARD = 'DELETE_CREDIT_CARD';
export const DELETE_BULK_CREDIT_CARD = 'DELETE_CREDIT_CARD';

// TRANSACTION
export const CREATE_INCOME_TRANSACTION = 'CREATE_INCOME_TRANSACTION';
export const LIST_INCOME_TRANSACTION = 'INCOME_TRANSACTION';
export const UPDATE_INCOME_TRANSACTION = 'UPDATE_INCOME_TRANSACTION';
export const GET_INCOME_TRANSACTION = 'GET_INCOME_TRANSACTION';
export const DELETE_INCOME_TRANSACTION = 'DELETE_INCOME_TRANSACTION';
export const DELETE_BULK_INCOME_TRANSACTION = 'DELETE_BULK_INCOME_TRANSACTION';


export const BANK_TRANSACTIONS = 'BANK_TRANSACTIONS';

export const BANK_TRANSACTIONS_LOADING = 'BANK_TRANSACTIONS_LOADING';
export const BANK_TRANSACTIONS_SUCCESS = 'BANK_TRANSACTIONS_SUCCESS';
export const BANK_TRANSACTIONS_FAILURE = 'BANK_TRANSACTIONS_FAILURE';
// export const GET_TRANSACTIONS_REQUEST = 'GET_TRANSACTIONS_REQUEST';
// export const GET_TRANSACTIONS_SUCCESS = 'GET_TRANSACTIONS_SUCCESS';
// export const GET_TRANSACTIONS_FAILURE = 'GET_TRANSACTIONS_FAILURE';

