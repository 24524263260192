import axios from '../../axios';
import * as types from '../types';
import { setMsgInfo } from '../msgInfo';
import createAction from '../createAction';
import filterError from '../common/filterError';
import API from '../api';

const baseUrl = `${API}/dashboard`;

export function getVaccinationHistory() {
  return (dispatch) => {
    return axios
      .get(`${baseUrl}/vaccination_history`)
      .then(({ data }) => {
        dispatch(
          createAction(types.GET_VACCINATION_HISTORY, data)
        );
        return data;
      })
      .catch((err) => {
        let errors;
        if (err.response?.status === 500) {
          errors = 'Server error please try again';
        } else {
          errors = err;
        }
        const errorMessages = filterError(errors);
        dispatch(
          setMsgInfo({
            success: false,
            msg: errorMessages,
          })
        );
        return err;
      });
  };
}


export const addVaccinationHistory = (formValues) => async (dispatch) => {
  try {
    const { data } = await axios.post(`${baseUrl}/vaccination_history`, formValues);
    dispatch({
      type: types.ADD_VACCINATION_HISTORY,
      payload: data,
    });
    return data;
  } catch (err) {
    let errors;
    if (err.response?.status === 500) {
      errors = 'Server error please try again';
    } else {
      errors = err;
    }
    const errorMessages = filterError(errors);
    dispatch(
      setMsgInfo({
        success: false,
        msg: errorMessages,
      })
    );
    return err;
  }
};


export const getVaccinationHistoryById = (vaccinationHistoryId) => async (
  dispatch
) => {
  try {
    const { data } = await axios.get(
      `${baseUrl}/vaccination_history/${vaccinationHistoryId}`
    );
    return data;
  } catch (error) {
    dispatch({
      type: types.GET_SINGLE_VACCINATION_HISTORY,
      payload: { data: null, loading: false },
    });
  }
};


export const updateVaccinationHistory = (vaccinationHistoryId, formValues) => async (
  dispatch
) => {
  try {
    const { data } = await axios.patch(
      `${baseUrl}/vaccination_history/${vaccinationHistoryId}`, formValues
    );
    dispatch({
      type: types.UPDATE_VACCINATION_HISTORY,
      payload: data,
    });
    return data;
  } catch (err) {
    let errors;
    if (err.response?.status === 500) {
      errors = 'Server error please try again';
    } else {
      errors = err;
    }
    const errorMessages = filterError(errors);
    dispatch(
      setMsgInfo({
        success: false,
        msg: errorMessages,
      })
    );
    return err;
  }
};


export const deleteVaccinationHistoryById = (vaccinationHistoryId) => async (
  dispatch
) => {
  await axios.delete(`${baseUrl}/vaccination_history/${vaccinationHistoryId}`);
  dispatch({
    type: types.DELETE_VACCINATION_HISTORY,
    payload: [vaccinationHistoryId],
  });
};


export const deleteBulkVaccinationHistory = (vaccinationHistoryIds) => async (
  dispatch
) => {
  await axios.post(`${baseUrl}/vaccination_history/bulk_delete`, {
    ids: vaccinationHistoryIds,
  });
  dispatch({
    type: types.DELETE_VACCINATION_HISTORY,
    payload: [...vaccinationHistoryIds],
  });
};
