import React, { Component } from 'react'
import compose from 'lodash/fp/compose'
import { Link } from 'react-router-dom'
import { withTranslation, withAccount, withMsgInfo } from '../hoc'
import { passwordStrength } from "../utils";
import {
  Input,
  Form,
  Button,
} from '../common/AuthFormItems'

import {Container, ImageDiv, Flex} from "./style"


const changePwFormSchema = {
  password: {
    validation: 'required|min:14',
  },
  cpassword: {
    validation: 'required|min:14',
  },
};

class ChangePassword extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isInitializing: true,
      isLoading: false,
      code: '',
      password: '',
      cpassword: ''
    }

    this.onSubmit = this.onSubmit.bind(this)
    this.onChange = this.onChange.bind(this)
  }

  componentWillMount() {
    let code = decodeURI(this.props.location.search)
    if (!code) {
      this.props.history.push('/login', null)
      return
    }

    this.props
      .verifyChangePasswordLink(code)
      .then(data => {
        code = code.split('=')[1]
        this.setState({ code, isInitializing: false })
      })
      .catch(err => {
        this.setState({ isInitializing: false })
      })
  }

  isValidData(data) {
    let errors = []
    if (!data.password || !data.cpassword) {
      errors = [...errors, 'All fields are required']
    }

    if (data.password !== data.cpassword) {
      errors = [...errors, 'Passwords must match']
    }

    if (errors.length) {
      this.props.setMsgInfo({
        success: false,
        msg: errors
      })

      return false
    }
    return true
  }

  onSubmit(e) {
    e.preventDefault()

    const { isLoading, password } = this.state;

    if (
      isLoading 
      || passwordStrength(password).hasError
      || !this.isValidData(this.state)) {
      return
    }

    this.setState({ isLoading: true })
    this.props
      .verifyPasswordChange(this.state)
      .then(data => {
        this.setState({
          code: '',
          password: '',
          cpassword: '',
          isLoading: false
        })
      })
      .catch(data => {
        this.setState({
          isLoading: false
        })
      })
  }

  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  render() {
    const { password, cpassword, isLoading } = this.state

    const { tr } = this.props

    return (
      <Container>
        <Flex mobile>
            <ImageDiv />
        </Flex>
        
        <Flex>
            <Form 
              title="Change Password"
              onSubmit={this.onSubmit}
              renderActionButtons={() => null}
              isLoading={isLoading}
              validationSchema={changePwFormSchema}
              formData={this.state}
            >
              <Input 
                icon='lock'
                type="password"
                value={password}
                name="password"
                onChange={this.onChange}
                fieldType='password'
                label='New Password'
                placeholder={tr('New Password')}
              />
              <Input 
                icon='lock'
                type="password"
                value={cpassword}
                name="cpassword"
                onChange={this.onChange}
                label='Confirm Password'
                placeholder={tr('Confirm Password')}
              />
              <Button
                type="submit"
                name={tr("Change My Password")}
                isLoading={isLoading}
              />
              <div>
                <p
                  className="font-small 
                  black-text d-flex justify-content-end"
                >
                  {tr('have an account?')}
                  <Link
                    to="/login"
                    className="blue-text ml-1 font-weight-bold"
                  >
                    {tr('Log In')}
                  </Link>
                </p>
              </div>
            </Form>
        </Flex>
      </Container>
    )
  }
}

export default compose(
  withAccount,
  withMsgInfo,
  withTranslation
)(ChangePassword)
