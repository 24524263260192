import React from "react";
import { connect } from "react-redux";
import {
  getCurrency,
} from "../../../actions";
import hoistNonReactStatics from 'hoist-non-react-statics'


let loaded = false;

export const withCurrency = (WrapperComponent) => {
  class WithCurrency extends React.Component {
    componentDidMount() {
      const { getCurrency } = this.props;
      if (loaded) return;
      loaded = true;
      getCurrency();
    }
    getCurrencyOptions = () => undefined

    getCurrencyLocaleOptions = () => {
      const { currency } = this.props;
      return currency.map(({ created_at, ...rest }) => ({ ...rest }));
    }
    
    render() {
      return (
        <WrapperComponent 
          currencyOptions={this.getCurrencyOptions()} 
          currencyLocaleOptions={this.getCurrencyLocaleOptions()} 
          {...this.props} 
        />
      )
    }
  }
  const mapStateToProps = ({ currency }) => ({ currency })
  const WithCurrencyHOC = connect(mapStateToProps, {
    getCurrency,
  })(WithCurrency);
  hoistNonReactStatics(WithCurrencyHOC, WrapperComponent)
  return WithCurrencyHOC
}