/*eslint no-useless-escape: 0*/
import i18next from 'i18next';

const isValidLowerCase = (password) => {
  let count = 0;
  password.split('').forEach((ch) => {
    if (/[a-z]/.test(ch)) {
      count++;
    }
  });
  return count < 2;
};

const isValidUpperCase = (password) => {
  let count = 0;
  password.split('').forEach((ch) => {
    if (/[A-Z]/.test(ch)) {
      count++;
    }
  });
  return count < 2;
};

const isValidNumberic = (password) => {
  let count = 0;
  password.split('').forEach((ch) => {
    if (/[0-9]/.test(ch)) {
      count++;
    }
  });
  return count < 2;
};

export const passwordStrength = (pw) => {
  let perct = 0;
  let strength = '';
  let errors = [];

  if (!/.{14,}/g.test(pw)) {
    perct += 20;
    errors = [...errors, i18next.t('Password length must not be less than 14')];
  }

  if (isValidNumberic(pw)) {
    perct += 20;
    errors = [...errors, i18next.t('Password must contain at least two(2) numbers')];
  }

  if (isValidLowerCase(pw)) {
    perct += 20;
    errors = [
      ...errors,
      i18next.t('Password must contain at least two(2) lowercase letter'),
    ];
  }

  if (isValidUpperCase(pw)) {
    perct += 20;
    errors = [
      ...errors,
      i18next.t('Password must contain at least two(2) uppercase letter'),
    ];
  }

  if (!/(?=[!@#$&*%^\':\"§\/\(\)\?\=\\\+\<\>±]{1,}).{1}/g.test(pw)) {
    perct += 20;
    errors = [
      ...errors,
      i18next.t('Password must contain at least one(1) special character'),
    ];

  }

  if (!pw) {
    strength = '';
  } else if (100 - perct <= 40) {
    strength = 'weak';
  } else if (100 - perct <= 80) {
    strength = 'medium';
  } else if (100 - perct > 80) {
    strength = 'strong';
  }

  return {
    strength,
    errors,
    hasError: !!errors.length,
  };
};
