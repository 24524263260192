import React from 'react';
import { Select, Button, Form, Input } from 'components/common/FormItems';
import Card from 'components/common/Card';
import compose from 'lodash/fp/compose';
import { CenterContentDiv, FormLabel } from '../SettingsFormUtils';
import {
  withLanguage,
  withWorkspace,
  withAccount,
  withSpace,
  withUserDefaultSpace,
  withCurrency,
  withPersonGroup,
  withUserPersonalWorkspace,
  withUserOrganisationWorkspace,
  withUserPreferences,
  withAuth,
} from 'components/hoc';
import PersonalComponent from '../PersonalComponent';
import { useDispatch } from 'react-redux';
import { setDefaultSpaceCurrency } from 'actions';

const DefaultSpaceSelect = (props) => {
  const { changeUserDefaultSpace, userDefaultSpace, workspaces } = props;
  const [defaultSpace, setDefaultSpace] = React.useState(userDefaultSpace.space);
  const loading = React.useRef(false);

  const handleChange = async ({ target }) => {
    const { value } = target;
    // if there is no change, do not trigger an api call

    if (value == defaultSpace || value == userDefaultSpace.space) {
      return;
    }
    setDefaultSpace(value);
    if (!loading.current) {
      await changeUserDefaultSpace(value);
      loading.current = true;
    }
  };

  return (
    <Select
      name="space"
      value={defaultSpace || userDefaultSpace.space}
      optionDefaultValue="Change Workspace"
      onChange={handleChange}
      options={workspaces}
    />
  );
};

const CurrencySelect = (props) => {
  const { currency, currencyLocaleOptions, spaceAdmin, setCurrency } = props;

  return (
    <Select
      name="currency"
      optionDefaultValue="Select Currency"
      onChange={({ target }) => setCurrency(target.value)}
      // onChange={handleChange}
      value={currency}
      // label="Currency"
      localeOptions={currencyLocaleOptions}
      disabled={!spaceAdmin}
    />
  );
};

const PersonGroupSelect = (props) => {
  const { personGroup, spaceAdmin, setPersonGroup, personGroupOptions } = props;
  return (
    <Select
      name="person_group"
      optionDefaultValue="Select Person Group"
      onChange={({ target }) => setPersonGroup(target.value)}
      value={personGroup || ''}
      // label="Person Group"
      options={personGroupOptions}
      disabled={!spaceAdmin}
    />
  );
};

const UpdateSpaceAdmin = (props) => {
  const {
    spaceMember,
    assignSpaceAdmin,
    getUser,
    tr,
    toggleSpaceAdmin,
    setMySpaceAdmin,
  } = props;
  const dispatch = useDispatch();

  const currentAdmin = React.useMemo(() => {
    return spaceMember.data.find(({ is_space_admin }) => is_space_admin)?.id;
  }, [spaceMember.data]);

  const [newAdmin, setNewAdmin] = React.useState(currentAdmin);
  const [loading, setLoading] = React.useState(false);

  const options = React.useMemo(() => {
    return spaceMember.data.map(({ id, username }) => ({
      id: String(id),
      name: username,
    }));
  }, [spaceMember.data]);

  // const handleChange = async ({ target }) => {
  //   const { value } = target;
  //   // if there is no change, do not trigger an api call
  //   if (value == !newAdmin || String(currentAdmin) === String(newAdmin)) return;

  //   setDefaultSpace(value);
  //   if (!loading.current) {
  //     await changeUserDefaultSpace(value);
  //     loading.current = true;
  //   }
  // };

  const handleChangeAdmin = async () => {
    if (!newAdmin || String(currentAdmin) === String(newAdmin)) return;
    try {
      setLoading(true);
      await assignSpaceAdmin({
        new_admin: newAdmin,
      });
      await getUser();
      const dataOption = options.find(
        (option) => String(option?.id) == String(newAdmin),
      );
      setMySpaceAdmin(dataOption?.name);
      toggleSpaceAdmin();
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  return (
    <>
      <Select
        options={options}
        name="newAdmin"
        value={newAdmin || currentAdmin}
        optionDefaultValue="Choose New Admin"
        onChange={({ target }) => setNewAdmin(target.value)}
        // label="Assign New Admin"
      />
      <CenterContentDiv>
        <Button
          actionButton
          name={tr('Save')}
          style={{ width: '130px' }}
          onClick={handleChangeAdmin}
          // onClick={()=>{alert('New Games')}}
          isLoading={loading}
        />
      </CenterContentDiv>
    </>
  );
};

const ReminderPeriod = (props) => {
  const { setReminder, reminder, tr } = props;

  const options = new Array(10).fill().map((_, i) => {
    return { id: i + 1, name: i + 1 };
  });

  options.sort((first, last) => {
    return first.name - last.name;
  });

  return (
    <Select
      optionDefaultValue="Choose Contract/Document  Reminder (Weeks)"
      // label="Document / Contract Reminder Alert (Weeks)"
      value={reminder}
      name="contract_alert"
      options={options}
      onChange={({ target }) => setReminder(target.value)}
      deselectable
      sort={false}
    />
  );
};

const SettingsWorkspaceTab = (props) => {
  const [values, setValues] = React.useState({});
  const dispatch = useDispatch();

  const {
    updateSpace,
    userDefaultSpace,
    getUserDefaultSpace,
    personGroupOptions,
    tr,
    account,
    spaceMember,
    userPersonalWorkspaceOptions,
    userOrganisationWorkspaceOptions,
    changeUserDefaultSpace,
    user,
  } = props;
  
  const [changeSpaceAdmin, setChangeSpaceAdmin] = React.useState(false);
  const workspace_type =
    account.user.space_type === 'P' ? 'personal' : 'organisation';

  const spaceAdmin =
    userDefaultSpace?.space_detail?.creator === account?.user?.user_id;
  const spacePersonGroup = userDefaultSpace?.space_detail?.person_group;
  const spaceCurrency = userDefaultSpace?.space_detail?.currency;
  const spaceReminder = userDefaultSpace?.space_detail?.reminder_period;
  const newProps = { ...props, spaceAdmin };
  const spaceAdmins = (spaceMember?.data || []).filter(
    (member) => member?.is_space_admin,
  );
  const [mySpaceAdmin, setMySpaceAdmin] = React.useState('');
  const userData = user?.data.length > 0 ? user?.data[0] : {};

  const [personGroup, setPersonGroup] = React.useState(spacePersonGroup);
  const [currency, setCurrency] = React.useState(spaceCurrency);
  const [updateSpaceAdmin, setUpdateSpaceAdmin] = React.useState(spaceCurrency);
  const [reminders, setReminder] = React.useState(spaceReminder);
  const [loading, setLoading] = React.useState(false);

  const workspaces = (
    workspace_type === 'personal'
      ? userPersonalWorkspaceOptions
      : userOrganisationWorkspaceOptions
  ).map(({ name }) => ({
    id: name,
    name,
  }));

  const onSubmit = async () => {
    setLoading(true);
    try {
      const data = await updateSpace(userDefaultSpace.space, {
        person_group: personGroup,
        currency,
        reminder_period: reminders,
      });
      
      // fetch the space currency and update
      // it to the store state
      const updatedSpace = await getUserDefaultSpace();
      setDefaultSpaceCurrency(updatedSpace);
      setLoading(false);
    } catch (error) {
      throw error;
    } finally {
      // window.location.reload(true);
      // window.scroll(0, 0);
    }
  };

  return (
    <>
      <Card
        cardHeader=""
        width="100%"
        paddingRight="7%"
        paddingLeft="3%"
        paddingTop="3%"
        paddingBottom="3%"
      >
        <FormLabel>{tr('Change Workspace')}</FormLabel>
        <DefaultSpaceSelect
          changeUserDefaultSpace={changeUserDefaultSpace}
          userDefaultSpace={userDefaultSpace}
          workspaces={workspaces}
        />
        <FormLabel>{tr('Space Admin')}</FormLabel>
        <div style={{ display: 'flex' }}>
          <div style={{ flexGrow: 2, marginRight: '1%' }}>
            <Input
              // onChange={onChange}
              name="name"
              id="name"
              value={
                mySpaceAdmin ||
                (spaceAdmins.length > 0 ? spaceAdmins[0]?.username : '')
              }
              disabled
              required
            />
          </div>
          {userData.is_space_admin && (
            <div style={{ flexShrink: 1 }}>
              <Button
                actionButton
                name={tr('Change Space Admin')}
                onClick={() => {
                  setChangeSpaceAdmin(!changeSpaceAdmin);
                }}
                style={{ float: 'right', height: '45px' }}
                isLoading={loading}
              />
            </div>
          )}
        </div>
      </Card>

      {changeSpaceAdmin && (
        <Card
          cardHeader=""
          width="100%"
          paddingRight="7%"
          paddingLeft="3%"
          paddingTop="3%"
          paddingBottom="3%"
        >
          <FormLabel>{tr('Assign Space Admin')}</FormLabel>
          <UpdateSpaceAdmin
            {...newProps}
            setPersonGroup={setPersonGroup}
            personGroup={personGroup || spacePersonGroup}
            toggleSpaceAdmin={() => setChangeSpaceAdmin(!changeSpaceAdmin)}
            setMySpaceAdmin={setMySpaceAdmin}
          />
        </Card>
      )}

      <Card
        cardHeader=""
        width="100%"
        paddingRight="7%"
        paddingLeft="3%"
        paddingTop="3%"
        paddingBottom="3%"
      >
        <FormLabel>{tr('Person Group')}</FormLabel>
        <PersonGroupSelect
          {...newProps}
          setPersonGroup={setPersonGroup}
          personGroup={personGroup || spacePersonGroup}
        />
        <FormLabel>{tr('Currency')}</FormLabel>
        <CurrencySelect
          {...newProps}
          setCurrency={setCurrency}
          currency={currency || spaceCurrency}
        />
        <FormLabel>
          {tr('Heads-up period (in weeks) for documents and contracts')}
        </FormLabel>
        <ReminderPeriod
          {...newProps}
          setReminder={setReminder}
          reminder={reminders || spaceReminder}
        />
        <CenterContentDiv>
          <Button
            actionButton
            name={tr('Save')}
            style={{ width: '130px' }}
            // onClick={onSubmit}
            onClick={() => {
              onSubmit();
            }}
            isLoading={loading}
          />
        </CenterContentDiv>
      </Card>
      <Card
        cardHeader=""
        width="100%"
        paddingRight="7%"
        paddingLeft="3%"
        paddingTop="3%"
        paddingBottom="3%"
      >
        <PersonalComponent />
      </Card>
    </>
  );
};

export default compose(
  withLanguage,
  withCurrency,
  withWorkspace,
  withAccount,
  withSpace,
  withUserDefaultSpace,
  withPersonGroup,
  withUserPersonalWorkspace,
  withUserOrganisationWorkspace,
  withAccount,
  withUserPreferences,
  withAuth,
)(SettingsWorkspaceTab);
