import React, { useEffect } from 'react';
import compose from 'lodash/fp/compose';
import styled from 'styled-components';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './style.css';

import {
  withAccount,
  withTranslation,
  withUserDefaultSpace,
  withFormValidation,
} from '../../../hoc';
import { FormValidationContext } from '../../../hoc/Preload/FormValidation';
import {
  FormModal,
  Input,
  // FileInput,
  Button,
  TextArea,
  TextEditor,
  RichTextEditor,
} from '../../../common/FormItems';

const Flex = styled.div`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
`;

Flex.displayName = 'Flex';

const InputSection = styled(Flex)`
  margin: 20px 0;
`;

const InputWrapper = styled(Flex)`
  margin-right: 5px;
  justify-content: space-between;
  max-width: 100%;
  align-items: center;
  flex-wrap: wrap;

  > div {
    flex: 1;
  }

  textarea,
  input {
    min-width: 200px;
  }
`;

const Label = styled.label`
  color: var(--admincat-dark-color);
  font-size: 1rem;
  font-weight: 600;
  margin-right: 10px;
  align-self: start;
  width: 75px;
`;

const StyledButton = styled(Button)`
  && {
    // color: var(--admincat-dark-color) !important;
    text-transform: capitalize;
    width: 8.0625rem;
    height: 2.5rem;
    font-weight: 500;
    border-radius: 7px;
    transition: all ease-in-out 300ms;
    background: #4c81be;
    border: 1px solid #1d579b;
    box-sizing: border-box;
    font-style: normal;
    font-size: 1rem;
    line-height: 1rem;
    color: #ffffff;
    cursor: pointer;
    margin-right: 1rem;
  }
`;

const mailFormSchema = {
  from_mail: {
    validation: 'required|email',
    message: {
      email: "Sender's email is invalid",
      required: "Sender's email is required",
    },
  },
  to_mail: {
    validation: 'required|email',
    message: {
      email: "Reciever's email is invalid",
      required: "Reciever's email is required",
    },
  },
  subject: {
    validation: 'required|min:3',
  },
  message: {
    validation: 'required|min:3',
  },
};

const getFields = (formData) => {
  return [
    {
      type: 'email',
      name: 'from_mail',
      label: 'From',
      placeholder: 'Your email',
      disabled: true,
    },
    {
      type: 'email',
      name: 'to_mail',
      label: 'To',
      placeholder: "Reciever's email",
    },
    {
      type: 'text',
      name: 'subject',
      label: 'Subject',
      placeholder: 'Subject',
    },
    {
      type: 'textarea',
      name: 'message',
      label: 'Message',
      placeholder: 'Your message...',
    },
  ].map((data) => ({
    ...formData,
    ...data,
    value: formData[data.name],
  }));
};

const InputFieldType = {
  email: Input,
  text: Input,
  textarea: TextEditor,
};

const Fields = (props) =>
  getFields({ ...props }).map(({ type, label, tr, ...rest }) => {
    const InputField = InputFieldType[type];
    return (
      <InputWrapper key={label}>
        <Label>{tr(label)}:</Label>
        <InputField type={type} {...rest} />
      </InputWrapper>
    );
  });

const Compose = (props) => {
  const {
    openComposeForm,
    toggleComposeForm,
    onMailChange: onChange,
    mailFormData,
    sendMail,
    sendMailLoading,
    account: {
      user: { email: personalEmail },
    },
    userDefaultSpace: { space_detail },
    tr,
  } = props;

  const spaceEmail = space_detail && space_detail.email;

  const { validateForm } = React.useContext(FormValidationContext);

  const from_mail = spaceEmail || personalEmail;

  useEffect(() => {
    if (spaceEmail) {
      onChange({ target: { name: 'from_mail', value: from_mail } });
    }
    return () => null;
  }, [spaceEmail, mailFormData.from_mail]);

  return (
    <FormModal
      title="Compose Mail"
      open={openComposeForm}
      toggleForm={() => toggleComposeForm(null)}
      formData={mailFormData}
      validationSchema={mailFormSchema}
      renderActionButtons={() => null}
    >
      <Flex>
        <StyledButton
          onClick={validateForm(sendMail)}
          name="Send"
          isLoading={sendMailLoading}
        />
        <StyledButton name="Attach" disabled={sendMailLoading} />
      </Flex>
      <InputSection flexDirection="column">
        <Fields
          {...mailFormData}
          tr={tr}
          onChange={onChange}
          key={openComposeForm}
        />
      </InputSection>
    </FormModal>
  );
};

export default compose(
  withAccount,
  withTranslation,
  withUserDefaultSpace,
  withFormValidation,
)(Compose);
