import React from "react";
import compose from "lodash/fp/compose";
import { connect } from "react-redux";
import hoistNonReactStatics from "hoist-non-react-statics";
import { getSchoolSubject } from "actions/preload";
import { BaseHOC, withBaseHOCProps } from "../common/BaseHOC";

let loaded = false

export const withSchoolSubject = (WrappedComponent) => {
  class SchoolSubject extends BaseHOC {

    async componentDidMount() {
      const { getSchoolSubject } = this.props;
      if (!loaded)  {
        loaded = true;
        await getSchoolSubject();
      }
    }

    get schoolSubjectOptions() {
      const { schoolSubject } = this.props;
      return this._getOptions(schoolSubject, ['id'], true)
    }

    render() {
      return (
        <WrappedComponent 
          {...this.props} 
          schoolSubjectOptions={this.schoolSubjectOptions}
        />
      )
    }
  }
  const mapStateToProps = ({ schoolSubject }) => ({ schoolSubject });
  const WithSchoolSubject = connect(mapStateToProps, { getSchoolSubject })(SchoolSubject);
  hoistNonReactStatics(WithSchoolSubject, WrappedComponent)
  return compose(withBaseHOCProps)(WithSchoolSubject);
}

