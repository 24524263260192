/* eslint-disable camelcase */
/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import compose from 'lodash/fp/compose';
import { useForm } from 'hooks/useForm';
import { createCreditCard, updateCreditCard, getSingleCreditCard } from 'actions';
import { removeEmptyProperties } from 'helpers/removeEmptyProperties';
// import { excludeUnsetField } from 'components/utils/excludeUnsetField';
import moment from 'moment';
import {
  withPersonalInfo,
  withTranslation,
  withOrgFunctionAssignment,
  withCustomRouter,
} from 'components/hoc';
import {
  Input,
  Select,
  InputDatePicker,
  ColumnalSelect,
  Form,
  ButtonLink,
} from 'components/common/FormItems';
import { useReferenceCheckingAccount } from 'hooks/useReferenceCheckingAccount';
// import { Modal, FormArea, EditButton } from '../../common/style';


const Wrapper = styled.div`
`

const StyledButtonLink = styled(ButtonLink)`
  span {
    text-transform: uppercase;
  }
`;

const personLabels = [
  {
    first_name: 'First Name',
  },
  {
    last_name: 'Last Name',
  },
];

const initialValues = {
  person: '',
  valid_to: '9999-12-31',
  license_org: '',
  account_ref: '',
  card_type: '',
  number: '',
  valid_thru: '',
  issuing_org: '',
  cvc: '',
};

const cardTypeOptions = [
  {
    id: 'C',
    long_description_deu: 'Kreditkarte',
    long_description_eng: 'Credit',
  },
  {
    id: 'D',
    long_description_deu: 'Debitkarte',
    long_description_eng: 'Debit',
  },
];

const validationSchema = {
  person: 'required',
  card_type: 'required',
  number: 'required',
  license_org: 'required',
  account_ref: [{ required_if: ['card_type', 'D'] }],
  valid_thru: 'required',
  issuing_org: 'required',
  cvc: 'required',
};

const creditCardFormSchema = {
  person: {
    validation: 'required',
  },
  card_type: {
    validation: 'required',
  },
  number: {
    validation: 'required',
  },
  license_org: {
    validation: 'required',
  },
  // account_ref: {
  //   validation: 'required',
  // },
  valid_thru: {
    validation: 'required',
  },
  issuing_org: {
    validation: 'required',
  },
  cvc: {
    validation: 'required',
  },
};

const CreditCardForm = (props) => {
  const { tr, orgFunctionAssignment, basePath, routeParams } = props;

  const dispatch = useDispatch();
  const history = useHistory();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formLoading, setFormLoading] = useState(false);

  const onSubmit = async (formValues, { resetForm }) => {
    setIsSubmitting(true);
    let err;
    try {
      const payload = removeEmptyProperties(formValues);
      payload.valid_thru = moment(payload.valid_thru).format('MM/YY');
      let credit_card = {};
      if (formValues.id) {
        credit_card = await dispatch(updateCreditCard(formValues.id, payload));
      } else {
        credit_card = await dispatch(createCreditCard(payload));
      }
      history.push(basePath);
      window.scrollTo(0, 0);
    } catch (e) {
    } finally {
      setIsSubmitting(false);
    }
  };

  const {
    handleChange,
    handleBlur,
    touched,
    values,
    errors,
    setValues,
    handleSubmit,
  } = useForm({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const referenceCheckingAccount = useReferenceCheckingAccount();
  const { item } = useSelector((state) => state.creditCard);
  const { id: creditCardId } = routeParams;

  const fetchData = () => {
    if (creditCardId) {
      try {
        setFormLoading(true);
        const { ...data } = dispatch(getSingleCreditCard(creditCardId));
        setValues({ data });
      } finally {
        setFormLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchData();
    return () => null;
  }, []);

  // const onSubmit = async (formValues, { resetForm }) => {
  //   setIsSubmitting(true);
  //   let err;
  //   try {
  //     const payload = removeEmptyProperties(formValues);
  //     payload.valid_thru = moment(payload.valid_thru).format('MM/YY');
  //     if (formValues.id) {
  //       err = await dispatch(updateCreditCard(formValues.id, payload));
  //     } else {
  //       err = await dispatch(createCreditCard(payload));
  //     }
  //     setIsSubmitting(false);
  //     if (!err) {
  //       // toggleForm();
  //       // resetForm();
  //       history.push(`basePath`);
  //       // setdetailMode('view');
  //     }
  //   } catch (error) {
  //     setIsSubmitting(false);
  //   }
  // };

  useEffect(() => {
    if (item) {
      const formValues = {};
      Object.keys(initialValues).forEach((key) => {
        formValues[key] = item[key];
      });
      setValues({ ...formValues, id: item.id });
      return;
    }
    setValues(initialValues);
  }, [item, setValues]);

  const isError = (touchedObj, error) =>
    touchedObj && error?.length > 0 ? '  ' : '';

  const getValidThru = (valid_thru) => {
    if (valid_thru && valid_thru.length <= 5) {
      valid_thru = valid_thru.split('/').join('/01/');
      return moment(new Date(valid_thru)).format('YYYY-MM-DD');
    }
    return valid_thru;
  };

  return (
    <Wrapper className="form-wrapper">
    <div className="flex">
      <StyledButtonLink
        to={basePath}
        className="option-btn"
        icon="angle double left"
        name="All Entries"
      />
    </div>
    <div className="row">
      <div
        className="col-7 ml-10 mt-30 container"
        style={{
          position: 'relative',
          background: 'var(--admincat-color-grey-1)',
        }}
      >
        <br />
          <Form
            onSubmit={handleSubmit}
            formData={values}
            isLoading={isSubmitting}
            loadingData={formLoading}
            validationSchema={creditCardFormSchema}
            title="Credit Card"
          >
            <Select
              showRequiredAsterisk
              name="card_type"
              label="Debit or Credit"
              optionDefaultValue={tr('Debit or Credit')}
              value={values.card_type}
              onChange={handleChange}
              localeOptions={cardTypeOptions}
              onBlur={handleBlur}
              error={isError(touched?.card_type, errors?.card_type?.[0])}
              // disabled={disabled}
              sort={false}
            />
            <Input
              showRequiredAsterisk
              name="number"
              label="Card No"
              value={values.number}
              placeholder="Card No"
              onChange={handleChange}
              onBlur={handleBlur}
              error={isError(touched?.number, errors?.number?.[0])}
            />
            <Select
              showRequiredAsterisk
              name="license_org"
              label="Credit Card Licenser"
              value={values.license_org}
              optionDefaultValue={tr('Credit Card Licenser')}
              onChange={handleChange}
              options={orgFunctionAssignment?.credit_card_options}
              // localeOptions={orgFunctionAssignment?.credit_card_options}
              onBlur={handleBlur}
              error={isError(touched?.license_org, errors?.license_org?.[0])}
            />
            <InputDatePicker
              name="valid_thru"
              label="Valid to"
              showMothYear
              value={getValidThru(values.valid_thru)}
              placeholder="MM/YY"
              placeholderText
              showMonthYearPicker
              onChange={handleChange}
              showRequiredAsterisk
            />
            <Input
              name="cvc"
              label="Card Verification Code (CVC)"
              value={values.cvc}
              placeholder="Card Verification Code (CVC)"
              onChange={handleChange}
              showRequiredAsterisk
            />
            <Select
              name="issuing_org"
              label="Issuing Bank Organization"
              value={values.issuing_org}
              optionDefaultValue={tr('Issuing organization')}
              onChange={handleChange}
              options={orgFunctionAssignment.bank_options}
              onBlur={handleBlur}
              showRequiredAsterisk
              error={isError(touched?.issuing_org, errors?.issuing_org?.[0])}
            />
            <ColumnalSelect
              onChange={handleChange}
              value={values.person}
              name="person"
              label="person"
              labels={personLabels}
              options={props.personalInfo.data}
              error={isError(touched?.person, errors?.person?.[0])}
              onBlur={handleBlur}
              showRequiredAsterisk
            />

            <ColumnalSelect
              label="reference checking account"
              value={Number.parseInt(values.account_ref, 10)}
              name="account_ref"
              options={referenceCheckingAccount}
              columns={['org_name', 'BIC', 'account_no']}
              labels={[
                { org_name: 'Organisation' },
                { BIC: 'BIC' },
                { account_no: 'Account Number' },
              ]}
              onChange={handleChange}
              required={values.card_type === 'D'}
            />
          </Form>
        </div>
      </div>
    </Wrapper>
  );
};

export default compose(
  withPersonalInfo,
  withTranslation,
  withOrgFunctionAssignment,
  withCustomRouter,
)(CreditCardForm);
