import {
    WAITING_LIST_FAILURE,
    WAITING_LIST_SUCCESS,
    WAITING_LIST_LOADING
  } from "../actions/types";
  
  const initialState = {
    data: [],
    isLoading: false,
    error: null,
  };
  
  export const waitingList = (state = initialState, action = {}) => {
    switch (action.type) {
      case WAITING_LIST_SUCCESS:
        return {
          ...state,
          data: action.payload,
          isLoading: false
        };
  
      case WAITING_LIST_LOADING:
        return {
          ...state,
          isLoading: true
        };
  
      case WAITING_LIST_FAILURE:
        return {
          ...state,
          isLoading: false,
          error: action.payload
        };
      default:
        return state
    }
  }
  
  export default waitingList
  
  