import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { withAccount, withUserPreferences, withTranslation, withMsgInfo } from '../hoc';
import { Input, Button, Form } from '../common/AuthFormItems';
import { WaitingListBody, WaitingListDivChild, WaitingListDivImage } from './style';
import { compose } from 'lodash/fp';
import { useDispatch } from 'react-redux';
import waitingLIstAction from '../../actions/waitingList';


const StyledMessage = styled.p`
  max-width: 500px; 
  white-space: normal; 
  word-wrap: break-word; 
  text-align: center; 
  margin: 0 auto; 
`;

const BreakLine = styled.span`
  display: block;
`;

const WaitingListInputs = ({ email, onChange }) => {
  const { t } = useTranslation();

  return (
    <>
      <Input
        icon="envelope"
        onChange={onChange}
        value={email}
        name="email"
        label="Email"
        // placeholder={t('Email')}
        required
      />
    </>
  );
};

const WaitingListActionButtons = ({ isLoading }) => {
  const { t } = useTranslation();

  return (
    <>
      <Button type="submit" name={t('Submit')} isLoading={isLoading} />
    </>
  );
};

const WaitingList = (props) => {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);  // State to track form success
  const dispatch = useDispatch();
  const { account } = props;
  const { t } = useTranslation();

  useEffect(() => {
    const storedEmail = localStorage.getItem('waitingListEmail');
    if (storedEmail) {
      setEmail(storedEmail);
      localStorage.removeItem('waitingListEmail'); // Clear the stored email after using it
    }
  }, []);

  const onChange = (e) => {
    const { name, value } = e.target;
    if (name === 'email') {
      setEmail(value);
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (!email) {
      props.setMsgInfo({
        success: false,
        msg: ['Enter a valid email'],
      });
      setIsLoading(false);
      return;
    }
    const dispatchEmail = {
      email,
    };
    dispatch(waitingLIstAction(dispatchEmail));
    setIsLoading(false);
    props.setMsgInfo({
      success: true,
      msg: [<span >
        {t('Thank you for registering on the waiting list.')}{' '}
        <BreakLine>{t('We will contact you as soon as new registrations are possible.')} </BreakLine>
      </span>],
    });
    setIsSuccess(true);  
  };

  return (
    <WaitingListBody>
      <WaitingListDivImage />
      <WaitingListDivChild>
        {isSuccess ? (  
          <div>
            <StyledMessage>
                {t('Thank you for registering on the waiting list.')}{' '}
                {t('We will contact you as soon as new registrations are possible.')}
            </StyledMessage>
          </div>
        ) : (  
          <Form
            title={t('Join Waiting List')}
            onSubmit={onSubmit}
            render={() => <WaitingListInputs email={email} onChange={onChange} />}
            renderActionButtons={() => <WaitingListActionButtons isLoading={account.isLoading} />}
          />
        )}
      </WaitingListDivChild>
    </WaitingListBody>
  );
};

export default compose(withAccount, withTranslation, withUserPreferences, withMsgInfo)(WaitingList);
