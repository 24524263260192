import React from 'react'
import { default as BaseList } from '../common/List'

const List = () => {
  const tableProps = {
    frontendTableName: 'vocational_training',
    backendTableName: 'education',
  }
  return <BaseList {...tableProps} />
};

export default List;
