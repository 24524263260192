/* eslint-disable camelcase */
import { useSelector } from 'react-redux';
import { useFavoriteOrganisation } from 'hooks/useFavoriteOrganisation';
import { useReferenceCheckingAccount } from 'hooks/useReferenceCheckingAccount';
import { useListCreditCard } from 'hooks/useListCreditCard';

export const useAccountCategoryRef = ({
  financialAccountCat,
  amount,
  currency,
  rec_op_id,
}) => {
  const favoriteOrganisation = useFavoriteOrganisation();
  const referenceCheckingAccount = useReferenceCheckingAccount();
  const creditCardOptions = useListCreditCard();

  const persons = useSelector((state) => state.personalInfo.data);

  const personOptions = persons.map(({ id, first_name, last_name }) => ({
    id,
    name: `${first_name} ${last_name}`,
    type: '',
  }));

  if (['receive', 'payable'].includes(financialAccountCat)) {
    // return rec_op_id === 'P' ?  favoriteOrganisation:personOptions;
    return [...personOptions, ...favoriteOrganisation];
  }

  if (financialAccountCat === 'bank') {
    return referenceCheckingAccount.map(({ id, account_no, org_name }) => ({
      id,
      name: `${org_name} - ${account_no}`,
    }));
  }

  if (financialAccountCat === 'cash') {
    return [
      {
        id: amount,
        name: `${currency} - ${amount}`,
      },
    ];
  }
  
  if (financialAccountCat === 'credit') {
    return creditCardOptions.map(({ id, personal_detail, number }) => ({
      id,
      name: `${personal_detail.first_name} - ${number}`,
    }));
  }
  return [];
};
