import axios from './axios';

export default (token) => {
  // axios.defaults.headers.common['Accept-Language'] = 'de';
  // axios.defaults.headers.common['Accept-Language'] = 'en';
  if (token) {
    axios.defaults.headers.common['Authorization'] = `Token ${token}`;
    localStorage.setItem('token', token);
  } else {
    delete axios.defaults.headers.common['Authorization'];
    localStorage.removeItem('token');
  }
};

// axios.defaults.headers.common['Accept-Language'] = 'en de';
// axios.defaults.headers.common = {
//   Accept-Language: da, en-gb, en
// }
