import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDataArea } from '../actions';

export const useDataArea = () => {
  const dispatch = useDispatch();
  const [dataArea, setDataArea] = useState('');
  const dataAreaOptions = useSelector((state) => state.dataArea);

  useEffect(() => {
    dispatch(getDataArea());
  }, []);

  useEffect(() => {
    // const dashboardArea = window.location.pathname.split('/')[3];
    const area = dataAreaOptions.find(
      (item) => item.long_description_eng.toLowerCase(),
      // === dashboardArea
    );
    setDataArea(area?.id);
  }, [dataAreaOptions]);

  return {
    dataAreaOptions,
    dataArea,
  };
};
