import React from 'react';
import compose from 'lodash/fp/compose';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { excludeUnsetField } from 'components/utils/excludeUnsetField';
import {
  withCountry,
  withCity,
  withState,
  withAddressType,
  withStreetDirection,
  withAddressInfo,
  withCustomRouter,
} from 'components/hoc';
import { Select, Input, Form, ButtonLink } from 'components/common/FormItems';

const Wrapper = styled.div``;

const StyledButtonLink = styled(ButtonLink)`
  span {
    text-transform: uppercase;
  }
`;

const addressFormSchema = {
  country: {
    validation: 'required',
  },
};

const AddressForm = (props) => {
  const {
    addAddressInfo,
    getAddressInfo,
    getAddressInfoById,
    updateAddressInfo,
    routeParams,
    gotoDetailPath,
    basePath,
  } = props;

  const history = useHistory();

  const { id: addressId } = routeParams;

  const [formData, setFormData] = React.useState({});
  const [formSubmitting, setFormSubmitting] = React.useState(false);
  const [formLoading, setFormLoading] = React.useState(false);

  // const fetchData = async () => {
  //   if (addressId) {
  //     try {
  //       setFormLoading(true);
  //       const { ...data } = await getAddressInfoById(addressId);
  //       setFormData(data);
  //     } finally {
  //       setFormLoading(false);
  //     }
  //   }
  // };


  // const fetchData = async () => {
  //   if (addressId) {
  //     try {
  //       setFormLoading(true);
  //       const { ...data } = await getAddressInfoById(addressId);
  //       setFormData(data);
  
  //       // Fetch the city based on the state
  //       if (data.state) {
  //         props.getCityByStateId(data.state);
  //       }
  //     } finally {
  //       setFormLoading(false);
  //     }
  //   }
  // };

  const fetchData = async () => {
    if (addressId) {
      try {
        setFormLoading(true);
        const { ...data } = await getAddressInfoById(addressId);
        setFormData(data);
  
        // Fetch state options based on the country
        if (data.country) {
          props.getStateByCountryId(data.country);
        }
  
        // Fetch city options based on the state
        if (data.state) {
          props.getCityByStateId(data.state);
        }
      } finally {
        setFormLoading(false);
      }
    }
  };

  React.useEffect(() => {
    fetchData();
    return () => null;
  }, []);

  const onChange = (e) => {
    if (formSubmitting) return;
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const onSubmit = async (e) => {
    if (formSubmitting) return;
    let payload = excludeUnsetField(formData);

    try {
      setFormSubmitting(true);
      let address = {};
      if (!formData.id) {
        address = await addAddressInfo(payload);
      } else {
        address = await updateAddressInfo(formData.id, payload);
      }
      // await getAddressInfo()
      // gotoDetailPath(address.id)
      history.push(basePath);
      window.scrollTo(0, 0);
    } catch (e) {
    } finally {
      setFormSubmitting(false);
    }
  };

  return (
    <Wrapper className="form-wrapper">
    <div className="flex">
      <StyledButtonLink
        to={basePath}
        className="option-btn"
        icon="angle double left"
        name="All Entries"
      />
    </div>
    <div className="row">
      <div
        className="col-7 ml-10 mt-30 container"
        style={{
          position: 'relative',
          background: 'var(--admincat-color-grey-1)',
        }}
      >
        <br />


          <Form
            onSubmit={onSubmit}
            formData={formData}
            isLoading={formSubmitting}
            validationSchema={addressFormSchema}
            loadingData={formLoading}
            title="Address"
          >
            <Select
              optionDefaultValue="Choose Country"
              label="Country"
              value={formData.country || ''}
              name="country"
              id="country"
              localeOptions={props.countryLocaleOptions}
              onChange={(e) => {
                onChange(e);
                props.getStateByCountryId(e.target.value);
              }}
              showRequiredAsterisk
            />
            <Select
              // isLoading={props.fetchingState}
              optionDefaultValue="Choose State"
              label="State"
              value={formData.state || ''}
              name="state"
              id="state"
              localeOptions={props.stateLocaleOptions}
              onChange={(e) => {
                onChange(e);
                props.getCityByStateId(e.target.value);
              }}
              disabled={!props.countryLocaleOptions}
            />

            <Select
              isLoading={props.fetchingCity}
              optionDefaultValue="Choose City"
              label="City"
              value={formData.city || ''}
              name="city"
              id="city"
              localeOptions={props.cityLocaleOptions}
              onChange={onChange}
              disabled={!props.stateLocaleOptions}
            />

            {/* <Select
              isLoading={props.fetchingCity}
              optionDefaultValue="Choose City"
              label="City"
              value={formData.city || ''}
              name="city"
              id="city"
              localeOptions={props.cityLocaleOptions}
              onChange={onChange}
              disabled={!formData.state}
            /> */}
            <Input
              value={formData.local_muncipality || ''}
              onChange={onChange}
              name="local_muncipality"
              id="local_muncipality"
              placeholder="Local Muncipality"
              label="Local Muncipality"
            />
            <Input
              value={formData.post_area || ''}
              onChange={onChange}
              name="post_area"
              id="post_area"
              placeholder="Postal Area"
              label="Postal Area"
            />
            <Input
              value={formData.building_name || ''}
              onChange={onChange}
              name="building_name"
              id="building_name"
              placeholder="Building Name"
              label="Building Name"
            />
          
            <Input
              value={formData.street_name || ''}
              onChange={onChange}
              name="street_name"
              id="street_name"
              placeholder="Street Name"
              label="Street Name"
            />
            <Input
              value={formData.street_no || ''}
              onChange={onChange}
              name="street_no"
              id="street_no"
              placeholder="Street Number"
              label="Street Number"
              type="number"
            />
            <Input
              value={formData.street_no_suffix || ''}
              onChange={onChange}
              name="street_no_suffix"
              id="street_no_suffix"
              placeholder="Street Number Suffix"
              label="Street Number Suffix"
            />
          </Form>
        </div>
      </div>
    </Wrapper>
  );
};

export default compose(
  withAddressInfo,
  withCountry,
  withCity,
  withState,
  withAddressType,
  withStreetDirection,
  withCustomRouter,
)(AddressForm);
