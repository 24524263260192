const validator = {
  errors: {},
  validate: function(data, rules){
    this.rules = rules;
    this.errors = this.validateData(data)
    return {
      isValid: this.isValid(),
      errors: this.errors
    }
  },

  validateData: function(data) {
    let errors = {};
    if(Array.isArray(data)) {
      errors = this.validateArray(data);
       return errors
    }
    Object.keys(data).forEach(key => {
      if (typeof data[key] === "object") {
        errors[key] = this.validateNestedFields({ [key]: data[key]});
      }
      else {
        let errorArray = this.getErrorMessage(key, data[key]);
        if (errorArray) {
          errors[key] = errorArray;
        }
      }
    });
    return errors;
  },

  isValid: function() {
    if(Array.isArray(this.errors)){
      let errors = []
      this.errors.forEach(item => {
        errors.push(Object.keys(item).length);
      })
      return errors.reduce((a, b) => a + b, 0) === 0;
    }
    return Object.keys(this.errors).length === 0;
  },

  validateObject: function(key, obj) {
    let error = {};
    Object.keys(obj[key]).forEach(objKey => {
      if (obj[key][objKey] !== "object") {
        const errorArray = this.getErrorMessage(objKey, obj[key][objKey]);
        if (errorArray) {
          error[objKey] = errorArray;
        }
      }
      error[objKey] = this.validateObject(key, [key][objKey]);
    });
    return error;
  },

  validateNestedFields: function(item) {
    let error = {};
    Object.keys(item).forEach(key => {
      if (Array.isArray(item[key])) {
        error = this.validateArray(key, item);
        return error;
      }
      if (typeof item[key] !== "object") {
        const errorArray = this.getErrorMessage(key, item[key]);
        if (error) {
          error[key] = errorArray;
        }
        return error;
      }
       error = this.validateObject(key, item);
    });
    return error;
  },
  
  
 validateArray: function(data) {
    let errors = [];
    data.forEach(item => {
      errors.push(this.validateData(item));
    });
    return errors;
  },

  getErrorMessage: function(key, value){
  let rules = this.rules[key];
  if (!rules) return;
  let msg = "";
  rules.split("|").forEach(rule => {
    let lengthValidation = rule.match(/(\bm..*)\d+|(\blength..*)\d+$/);
      if(rule === 'required' && value === ''){
        msg = `${key} can't be empty`.replace(/-\d+/, '');
        return
      }
      if(lengthValidation){
        let length = lengthValidation[0].split(':')[1];
        let target = lengthValidation[0].split(':')[0]
        if(rule === 'length' && value.length < target) {
          msg = `${key} should have ${length} characters`;
        } else if(target === 'min' && value.length < length) {
          msg = `${key} should have at least ${length} characters`;
          return;
        } else if(target === 'max' && value.length > length) {
          msg = `${key} should not have more than ${length} characters`;
        }
      }
  });
  return msg;
}
};

export default validator;
