
import styled from "styled-components"

export const Div = styled.div`
    padding:40px;
    position:relative;
    height: 70vh;
    margin-bottom:20px;
    background: #fff;
    .rec-one{
        width:90%;
        height: 100%;
        background: #295586;
        position:absolute;
        top:0;
        left: 50%;
        transform:translateX(-50%);
        border-radius:10px;
        transform: translateX(-50%) rotate(-2.5deg);
        @media only screen and (max-width:750px){
            width:100%;
        }
    }
    .rec-two{
        width:90%;
        height: 100%;
        background: #000;
        position:absolute;
        top:0;
        left: 50%;
        transform:translateX(-50%);
        border-radius:10px;
        z-index:-1;
        @media only screen and (max-width:750px){
            width:100%;
        }
    }
    .wrapper{
        max-width:600px;
        height:100%;
        margin:auto;
        z-index:10;
        position:absolute;
        top:0;
        left: 50%;
        transform:translateX(-50%);

        @media only screen and (max-width:750px){
            width:95%;
        }
    }
    .content{
        height:100%;
        display:flex;
        flex-direction: column;
        justify-content:space-around;
        align-items: center;
        width:95%;
        padding: 30px;
    }

    a{
        display:inline-block;
        padding: 20px 50px;
        min-width:226px;
        height:60px; 
        border:solid 2px #fff;
        color: #fff;
        text-decoration:none;
        border-radius: 3px;
        text-align: center;
        font-size:18px;
        line-height:21px;
        letter-spacing:-1px;
        box-sizing: border-box;
        letter-spacing:1.5px;
        
        &:hover{
            color: #fff;
        }
    }
`
