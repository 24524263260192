import React from 'react';
import compose from 'lodash/fp/compose';
import { connect } from 'react-redux';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { excludeUnsetField } from 'components/utils/excludeUnsetField';
import * as actions from 'actions/dashboard/relationInfo';
import { withDeleteConfirmation } from '..';
import { RELATION_INFO_FORM, RELATION_INFO_VIEW } from 'actions/types';
import { createAction } from 'actions';
import { T } from 'lodash/fp';

// let loaded = false;

const initialState = {
  loaded: false,
};

const relationPart = {
  info: 'info',
  marr: 'marr',
  civi: 'civi',
  pach: 'prnt',
  prnt: 'pach',
  adpa: 'fopa',
  fopa: 'adpa',
  adch: 'foch',
  foch: 'adch',
};

const getRelatedToData = (payload) => {
  return {
    ...payload,
    person: payload.relation_to,
    relation_to: payload.person,
    relation_part: relationPart[payload.relation_part],
  };
};

export const withRelationInfo = (WrappedComponent) => {
  class WithRelationInfo extends React.Component {
    state = { ...initialState };

    async componentDidMount() {
      const { getRelationInfo } = this.props;
      if (this.state.loaded) return;
      await getRelationInfo();
      this.setState({ ...initialState, loaded: true });
      // loaded = true;
    }

    onSubmit = async (e) => {
      const { formSubmitting } = this.state;
      const {
        addRelationInfo,
        updateRelationInfo,
        relationInfoForm,
        relationInfoForm: { data: relationInfoFormData },
      } = this.props;

      if (formSubmitting) return;

      const personData = excludeUnsetField(relationInfoFormData);
      const relatedToData = getRelatedToData(personData);
      const payload = [personData, relatedToData];

      this.setState({ formSubmitting: true });

      try {
        if (!relationInfoFormData.id) {
          await addRelationInfo(payload);
        } else {
          await updateRelationInfo(relationInfoFormData.id, payload);
        }
        // this.toggleRelationInfoForm()
        // history.pushState(basePath)
      } finally {
        this.setState({ formSubmitting: false });
      }
    };

    toggleRelationInfoView = (data = {}) => {
      const { relationInfoView, setViewData } = this.props;
      data = { data, showView: !relationInfoView.showView };
      setViewData(data);
    };

    toggleRelationInfoForm = (data = {}) => {
      const { relationInfoForm, setFormData } = this.props;
      setFormData({ showForm: !relationInfoForm.showForm, data });
    };

    setDataToForm = (data = {}) => {
      const { setFormData } = this.props;
      setFormData({ data, showForm: true });
    };

    onChange = ({ target }) => {
      const { name, value } = target;
      const {
        setFormData,
        relationInfoForm: { data: relationInfoFormData },
      } = this.props;
      let data = { ...relationInfoFormData, [name]: value };

      // Making sure "person and relation_to fields" do not have same value. Because a person can
      // not be related to himself
      const { person, relation_to } = data;
      if (person && relation_to && +person === +relation_to) {
        data = { ...data, relation_to: '' };
      }
      setFormData({ data, showForm: true });
    };

    deleteRelationInfoById = (data) => {
      const { deleteConfirmation, deleteRelationInfoById } = this.props;
      deleteConfirmation.init(deleteRelationInfoById, data);
    };

    deleteBulkRelationInfo = (data) => {
      const { deleteConfirmation, deleteBulkRelationInfo } = this.props;
      deleteConfirmation.initBulk(deleteBulkRelationInfo, data);
    };
    render() {
      const { setFormData: _, ...rest } = this.props;
      return (
        <WrappedComponent
          {...rest}
          loaded={this.state.loaded}
          relationInfoFormSubmitting={this.state.formSubmitting}
          toggleRelationInfoForm={this.toggleRelationInfoForm}
          toggleRelationInfoView={this.toggleRelationInfoView}
          onRelationInfoChange={this.onChange}
          onRelationInfoSubmit={this.onSubmit}
          setDataToForm={this.setDataToForm}
          deleteBulkRelationInfo={this.deleteBulkRelationInfo}
          deleteRelationInfoById={this.deleteRelationInfoById}
        />
      );
    }
  }
  const mapStateToProps = ({ relationInfo, relationInfoForm, relationInfoView }) => {
    return {
      relationInfo,
      relationInfoView,
      relationInfoForm,
    };
  };
  const RelationInfo = connect(mapStateToProps, {
    ...actions,
    setViewData: (data) => createAction(RELATION_INFO_VIEW, data),
    setFormData: (data) => createAction(RELATION_INFO_FORM, data),
  })(WithRelationInfo);
  hoistNonReactStatics(RelationInfo, WrappedComponent);
  return compose(withDeleteConfirmation)(RelationInfo);
};
