import React from "react";
import { connect } from "react-redux";
import {
  getAllPersonalWorkspace,
} from "../../../actions";
import hoistNonReactStatics from 'hoist-non-react-statics'


let loaded = false;

export const withAllPersonalWorkspace = (WrapperComponent) => {
  class Enhance extends React.Component {
    componentDidMount() {
      const { getAllPersonalWorkspace } = this.props;
      if (loaded) return;
      loaded = true;
      getAllPersonalWorkspace();
    }
    getAllPersonalWorkspaceLocaleOptions = () => undefined
    getAllPersonalWorkspaceOptions = () => {
      const { allPersonalWorkspace } = this.props;
      return allPersonalWorkspace;
    }
    render() {
      return (
        <WrapperComponent 
          allPersonalWorkspaceOptions={this.getAllPersonalWorkspaceOptions()} 
          allPersonalWorkspaceLocaleOptions={this.getAllPersonalWorkspaceLocaleOptions()} 
          {...this.props} 
        />
      )
    }
  }
  const mapStateToProps = ({ allPersonalWorkspace }) => ({ allPersonalWorkspace })
  const WithAllPersonalWorkspace = connect(mapStateToProps, {
    getAllPersonalWorkspace,
  })(Enhance);
  hoistNonReactStatics(WithAllPersonalWorkspace, WrapperComponent)
  return WithAllPersonalWorkspace
}
