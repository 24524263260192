import React from "react";
import compose from "lodash/fp/compose";
import { connect } from "react-redux";
import hoistNonReactStatics from "hoist-non-react-statics";
import * as membershipInfoActions from "actions/dashboard/membershipInfo";
import { BaseHOC, withBaseHOCProps } from "../common/BaseHOC";


const initialState = {
  loaded: false,
}

export const withMembershipInfo = (WrappedComponent) => {

  class WithMembershipInfo extends BaseHOC {

    state = { ...initialState }

    async componentDidMount() {
      const { getMembershipInfo } = this.props
      if (!this.state.loaded)  {
        await getMembershipInfo();
        this.setState({...initialState, loaded: true });
        // loaded = true;
      }
    }

    deleteMembershipInfoById = async (data) => {
      const { deleteMembershipInfoById } = this.props
      await this._deleteById(deleteMembershipInfoById, data);
    }

    deleteBulkMembershipInfo = async (data) => {
      const { deleteBulkMembershipInfo } = this.props
      await this._deleteBulk(deleteBulkMembershipInfo, data);
    }

    render() {
      return (
        <WrappedComponent 
          {...this.props} 
          loaded={this.state.loaded}
          deleteBulkMembershipInfo={this.deleteBulkMembershipInfo}
          deleteMembershipInfoById={this.deleteMembershipInfoById}
        
        />
      )
    }
  }
  const mapStateToProps = ({ membershipInfo }) => ({ membershipInfo })
  const MembershipInfo = connect(mapStateToProps, membershipInfoActions)(WithMembershipInfo);
  hoistNonReactStatics(MembershipInfo, WrappedComponent)
  return compose(withBaseHOCProps)(MembershipInfo);
}
