import React from "react";
import compose from "lodash/fp/compose";
// import { withRouter } from "react-router-dom";
import hoistNonReactStatics from 'hoist-non-react-statics'


export const withEnhance = (props) => (WrapperComponent) => {
  class Enhance extends React.Component {
    render() {
      return (
        <WrapperComponent 
          {...props}
          {...this.props} 
        />
      )
    }
  }
  hoistNonReactStatics(Enhance, WrapperComponent)
  return compose()(Enhance)
}
