/* eslint-disable */
import { BaseActionClass } from "../common/BaseActionClass"
import { PERSON_GROUP_MEMBER } from '../types'
import axios from "../../axios";

const path = "/dashboard/person_group_member";
const name = "Person Group Member";

class PersonGroupMember extends BaseActionClass {

  constructor() { super(path, PERSON_GROUP_MEMBER, name) }

  addPersonGroupMember = this._add;

  updatePersonGroupMember = this._update;

  deletePersonGroupMemberById = this._deleteById;

  getPersonGroupMember = this._getAll;

  getPersonGroupMemberById = this._getById;

  deleteBulkPersonGroupMember = this._deleteBulk;

  insertBulkPersonGroupMember = (payload) => {
    return (async (dispatch) => { 
      try {
        const { data } = await axios.post(`${this._baseUrl}/bulk_insert`, payload)
        return data
      } catch (err) {
        this._handleError(err)
      }

    });
  }
}

export const { 
  addPersonGroupMember, 
  updatePersonGroupMember, 
  deletePersonGroupMemberById,
  getPersonGroupMember,
  getPersonGroupMemberById,
  deleteBulkPersonGroupMember,
  insertBulkPersonGroupMember,
} = new PersonGroupMember()

