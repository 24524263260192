import * as types from "../../actions/types";
import { createTypes } from "../../actions";

const initialState = {
  data: [],
};


const timeDependentName = (state=initialState, action={}) => { 
  switch(action.type){ 
    case createTypes(types.GET_TIME_DEPENDENT_NAME).success: 
      return { ...state, data: action.payload,  };
    case createTypes(types.ADD_TIME_DEPENDENT_NAME).success: 
      return { 
        ...state, 
        data: [ action.payload, ...state.data ], 
    };
    case createTypes(types.UPDATE_TIME_DEPENDENT_NAME).success: 
      return { 
        ...state, 
        data: state.data.map((_data) => { 
          if(_data.id === action.payload.id) return action.payload;
          return _data;
        }), 
    };
    case createTypes(types.DELETE_TIME_DEPENDENT_NAME_BY_ID).success: 
      return { 
        ...state, 
        data: state.data.filter((_data) => { 
          return _data.id !== action.payload.id;
        }), 
    };
    case createTypes(types.ADD_TIME_DEPENDENT_NAME).failure: 
    case createTypes(types.UPDATE_TIME_DEPENDENT_NAME).failure: 
    case createTypes(types.GET_TIME_DEPENDENT_NAME).failure: 
    case createTypes(types.DELETE_TIME_DEPENDENT_NAME_BY_ID).failure: 
      return { ...state, error: action.payload };
    default:
      return state
  }
}

export default { timeDependentName };

