import React from 'react';
import compose from "lodash/fp/compose";
import {
    withMail,
    withBulkDelete,
    withTranslation,
    withDataArea,
    withUserLayoutChoice,
    withUserDefaultSpace,
    withUserPreferences,
  } from 'components/hoc';
import withFileSize from 'components/hoc/FileSizeChecker/File';
import MailBox from '../MailInbox';

  

const Trash = (props) => {

    return (
        <>
            <MailBox
              filterBy='TRASH'
              {...props}
            />
        </>
    );
};


export default compose(
  withMail,
  withBulkDelete,
  withTranslation,
  withDataArea,
  withUserLayoutChoice,
  withUserDefaultSpace,
  withUserPreferences,
  withFileSize
  )(Trash);



// import React from 'react';
// import compose from "lodash/fp/compose";
// import MailList from "../common/MailList"
// import { DnDPortal } from 'components/common/FormItems/DnDPortal';
// import {
//   withMail,
//   withUserLayoutChoice,
// } from "../../../hoc"
// import { layoutColumnProps } from "../common/layoutColumnProps";

// const options = ({ _delete }) => {
//   return [
//     {
//       onClick: _delete,
//       name: "Delete Permanently",
//     },
//   ];
// }

// const Trash = (props) => {

//   const { 
//     deleteMailById,
//     getLayoutColumnProps,
//   } = props;

//   const frontendTableName = "trash";
//   const { columns, layoutColumns } = getLayoutColumnProps(
//     ...layoutColumnProps(frontendTableName)
//   );

//   return (
//     <>
//       <DnDPortal 
//         tableName={frontendTableName} 
//         columns={layoutColumns} 
//       />
//       <MailList 
//         options={options({
//           _delete: deleteMailById,
//         })}
//         hasBulkDelete={false}
//         filterBy="TRASH"
//         columns={columns}
//       />
//     </>
//   )
// };

// export default compose(
//   withMail,
//   withUserLayoutChoice,
// )(Trash);

