import { BaseActionClass } from "../common/BaseActionClass"
import { WORK_EXPERIENCE_INFO } from '../types'

const path = "/dashboard/work_experience";
const name = "Work Experience";

class WorkExperienceInfo extends BaseActionClass {

  constructor() { super(path, WORK_EXPERIENCE_INFO, name) }

  addWorkExperience = this._add;

  updateWorkExperience = this._update;

  deleteWorkExperienceById = this._deleteById;

  getWorkExperience = this._getAll;

  getWorkExperienceById = this._getById;

  deleteBulkWorkExperience = this._deleteBulk;
}

export const { 
  addWorkExperience, 
  updateWorkExperience, 
  deleteWorkExperienceById,
  getWorkExperienceById, 
  getWorkExperience,
  deleteBulkWorkExperience,
} = new WorkExperienceInfo()

