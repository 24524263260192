/* eslint-disable require-jsdoc */
import React from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import compose from 'lodash/fp/compose';
import { connect } from 'react-redux';
import { SPACE_TYPES } from '../../../constants';
import {
  getOrganisations,
  addOrganisation,
  updateOrganisation,
  createAction,
  ORGANISATION_FORM,
  getRegisterInstitute,
  getFavoriteOrg,
} from 'actions';

let loaded = false;

export const withOrganisation = (WrapperComponent) => {
  class Organisation extends React.Component {
    state = {
      spaceType: '',
    };
    async componentDidMount() {
      const { getOrganisations, getFavoriteOrg } = this.props;
      if (loaded) return;
      loaded = true;
      const user = JSON.parse(localStorage.getItem('user'));

      if (user && user.space_type === SPACE_TYPES.PERSON) {
        await getFavoriteOrg();
      } else {
        await getOrganisations();
      }
    }

    getOrgById = (organisationId) => {
      const orgId = Number.parseInt(organisationId, 10);
      const org = this.orgOptions.find(({ id }) => id === orgId);
      return org || {};
    };

    get orgOptions() {
      const {
        organisation: { favorites, data },
        account,
      } = this.props;

      if (account.user.space_type === SPACE_TYPES.PERSON) {
        return favorites.map(({ id, organisation_data: { name, ...rest } }) => ({
          id,
          name,
          ...rest,
        }));
      }
      return data.map(({ id, name, ...rest }) => ({
        id,
        name,
        ...rest,
      }));
    }

    getRegisterInstituteOptions = () => {
      const {
        organisation: { registerInstitutes },
      } = this.props;
      return registerInstitutes.map(({ id, org: { name } }) => ({
        id,
        long_description_eng: name,
        long_description_deu: name,
      }));
    };

    getOrgLocaleOptions = () => undefined;

    onChange = ({ target: { name, value } }) => {
      const {
        setValue,
        organisation: { orgFormData },
      } = this.props;
      const data = { ...orgFormData, [name]: value };
      if (name === 'org_country' && orgFormData.state) {
        getRegisterInstitute(value, orgFormData.state);
      }
      setValue({ orgFormData: data });
    };

    getRegisterInstitute = (org_state) => {
      const {
        organisation: {
          orgFormData: { org_country },
        },
      } = this.props;
      const { getRegisterInstitute } = this.props;
      getRegisterInstitute(org_country, org_state);
    };

    onSubmit = async (e, orgId) => {
      e.preventDefault();
      const {
        addOrganisation,
        updateOrganisation,
        organisation: { orgFormData },
      } = this.props;
      const formData = new FormData();
      Object.entries(orgFormData).forEach(([key, value]) => {
        formData.append(key, value);
      });
      const { isLoading } = this.state;
      const submitAction = orgId
        ? () => updateOrganisation(orgId, formData)
        : () => addOrganisation(formData);

      if (!isLoading) {
        try {
          this.setState({ isLoading: true });
          await submitAction(formData);
          this.setState({ isLoading: false });
        } catch (e) {
          this.setState({ isLoading: false });
        }
      }
    };
    render() {
      const { setValue: _, ...rest } = this.props;
      return (
        <WrapperComponent
          {...rest}
          orgOptions={this.orgOptions}
          orgLocaleOptions={this.getOrgLocaleOptions()}
          getOrgById={this.getOrgById}
          onSubmitOrg={this.onSubmit}
          onChangeOrg={this.onChange}
          orgFormSubmitting={this.state.isLoading}
          getRegisterInstitute={this.getRegisterInstitute}
          registerInstitutesOptions={this.getRegisterInstituteOptions()}
        />
      );
    }
  }
  const mapStateToProps = ({ organisation, account }) => {
    return {
      organisation,
      account,
    };
  };

  const WithOrganisation = connect(mapStateToProps, {
    getOrganisations,
    addOrganisation,
    updateOrganisation,
    getFavoriteOrg,
    getRegisterInstitute,
    toggleOrgForm: (data) =>
      createAction(ORGANISATION_FORM, {
        showForm: data,
        orgFormData: {},
      }),
    setValue: (data) => createAction(ORGANISATION_FORM, data),
  })(Organisation);
  hoistNonReactStatics(WithOrganisation, WrapperComponent);
  return compose()(WithOrganisation);
};
