import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getExpenseCategory } from '../actions';

export const useExpenseCategory = () => {
  const dispatch = useDispatch();
  const expenseCategoryOptions = useSelector((state) => state.expenseCategory);

  useEffect(() => {
    dispatch(getExpenseCategory());
  }, [dispatch]);
  return expenseCategoryOptions;
};
