import { RESET_PASSWORD } from "./types"

import axios from '../axios';
import API from './api'
import { createTypes } from './createTypes';
import filterError from './common/filterError';
import { setMsgInfo } from "./msgInfo";


const baseUrl = API + '/password/reset'

const fogotPasswordAction = payload =>{
    return async (dispatch) =>{
        dispatch({
            type:createTypes(RESET_PASSWORD).loading
        })
        try{
            const { data } = await axios.post(`${baseUrl}/`, payload);
            dispatch({
                type: createTypes(RESET_PASSWORD).success,
                payload: data
            })

        }catch (err){
            const errors = filterError(err);
            dispatch({
                type: createTypes(RESET_PASSWORD).failure,
                payload: errors,
              });
            dispatch(
                setMsgInfo({
                  success: false,
                  msg: errors,
                })
              );
            //   throw new Error(err);
        }
    }
}

export default fogotPasswordAction