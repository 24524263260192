import React from "react";
import compose from "lodash/fp/compose";
import { 
  withTranslation,
  withDateFormat,
  withUserPreferences,
  withResidenceInfo,
  withCustomRouter
} from "components/hoc";
import MyDataContentTemplate from '../../common/MyDataContentTemplate'
import { getFullName, isEmptyValue } from "components/utils";
import LottieLoader from 'components/common/LottieLoader';



const Residence = (props) => {

  const { residenceInfo, trObj, fd } = props;

  // const getAddress = (address_detail) => {
  //   return [
  //     trObj(address_detail.country_detail),
  //     trObj(address_detail.state_detail),
  //     address_detail.street_name,
  //     address_detail.street_no,
  //     address_detail.street_no_suffix,
  //   ]
  //     .filter(v => !isEmptyValue(v))
  //     .join(", ")
  // }


  const getAddress = (address_detail) => {
    const streetDetails = [address_detail.street_name, address_detail.street_no, address_detail.street_no_suffix]
      .filter(v => !isEmptyValue(v))
      .join(" ");

    return [
      trObj(address_detail.country_detail),
      trObj(address_detail.city_detail),
      streetDetails,
    ]
      .filter(v => !isEmptyValue(v))
      .join(", ");
  }


  const mapFields = () => {
    return residenceInfo.data.map((data) => {
      return { 
        ...data, 
        space_name: data.space,
        _resident: getFullName(data.resident_detail),
        _address: getAddress(data.address_detail),
        _valid_from: fd(data.valid_from),
        _valid_to: fd(data.valid_to),
        _principal_indicator: data.principal_indicator ? "\u2713" : "\u2717",
      }
    })
  };

  const { 
    // deleteResidenceInfoById, 
    deleteBulkResidenceInfo,
    gotoCreatePath,
    // gotoDetailPath,
    loaded,
  } = props;


  if (!loaded) {
    return (
      <>
          <LottieLoader />
      </>)
  }

  return (
    <React.Fragment>
      <MyDataContentTemplate 
        columns={[]}
        tableName="residence"
        data={mapFields()}
        toggleForm={gotoCreatePath}
        bulkDelete={deleteBulkResidenceInfo}
      />
    </React.Fragment>
  )
}

export default compose(
  withTranslation,
  withUserPreferences,
  withDateFormat,
  withResidenceInfo,
  withCustomRouter
)(Residence);


