import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { FaPlus, FaMinus, FaTimes } from 'react-icons/fa';
import FilterButton from 'components/common/FormItems/Button';
import { Select, Input } from 'components/common/FormItems';
import { Funnel } from 'react-bootstrap-icons';
import { withUserPreferences } from 'components/hoc';
import compose from 'lodash/fp/compose';

const GridHeader = styled.div`
  font-weight: bold;
  color: #9aa0ac;
  padding: 10px 0;
  position: sticky;
  top: 0;
  background: var(--admincat-color-grey-1);
  z-index: 1;
`;

const StyledSelect = styled(Select)`
  width: 100%;
`;

const Button = styled.button`
  background: var(--admincat-color-grey-1);
  color: #9aa0ac;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledFilterButton = styled(FilterButton)`
  width: ${(props) => (props.width ? props.width : '10.0625rem')};
  height: 2.5rem;
  line-height: 1.25rem;
  font-weight: 500;
  font-size: 1rem;
  border: ${(props) => (props.border ? props.border : 'none')};
  border-radius: 7px;
  color: ${(props) => (props.color ? props.color : '#fff')};
  background: ${(props) => (props.background ? props.background : '#4C81BE')};
  transition: all ease-in-out 300ms;
  display: block;
  ${({ border }) =>
    border &&
    css`
      border: solid 1px #1d579b;
      border-radius: 7px;
    `}

  &:hover {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.29);
  }
`;

const Badge = styled.span`
  position: absolute;
  top: -8px;
  right: -8px;
  background-color: red;
  color: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  line-height: 1;
`;

const StyledIconButton = styled(FilterButton)`
  background: var(--admincat-color-grey-6);
  border-radius: 2px;
  height: 38px;
  color: ${(props) => props.color || 'var(--admincat-color-grey-7)'};
  border: none;
  font-size: 14px;
  line-height: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const FilterIconWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;

  .badge {
    position: absolute;
    top: -5px;
    right: -5px;
    background-color: red;
    color: white;
    border-radius: 50%;
    padding: 12px 15px;
    font-size: 12px;
  }

  .filter-text {
    margin-right: 8px;
    font-size: 16px;
    color: var(--admincat-color-grey-7);
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: var(--admincat-color-grey-1);
  border-radius: 8px;
  padding: 20px;
  width: 95%;
  max-width: 1000px;
  height: 85vh;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const ModalTitle = styled.h2`
  font-size: 18px;
  color: #9aa0ac;
  margin: 0;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: #999;
  font-size: 24px;
`;

const FilterGridContainer = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;

const FilterGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr auto;
  gap: 10px;
  align-items: start;
`;

const GridRow = styled.div`
  display: contents;
`;

const GridCell = styled.div`
  min-height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const ValueFieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
`;

const StyledInput = styled(Input)`
  margin-bottom: -2px;
  margin-right: 2%;
  width: 98%;
`;

const ActionButtonsContainer = styled.div`
  display: flex;
  gap: 5px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
`;

const countFiltersWithValues = (filters) => {
  return filters.filter(
    (filter) => filter.value || filter.startValue || filter.endValue,
  ).length;
};

export const parseDate = (dateString, format) => {
  const formatRegex = {
    'DD-MM-YYYY': /^(\d{2})-(\d{2})-(\d{4})$/,
    'DD.MM.YYYY': /^(\d{2})\.(\d{2})\.(\d{4})$/,
    'DD/MM/YYYY': /^(\d{2})\/(\d{2})\/(\d{4})$/,
    'MM-DD-YYYY': /^(\d{2})-(\d{2})-(\d{4})$/,
    'MM.DD.YYYY': /^(\d{2})\.(\d{2})\.(\d{4})$/,
    'MM/DD/YYYY': /^(\d{2})\/(\d{2})\/(\d{4})$/,
    'YYYY-MM-DD': /^(\d{4})-(\d{2})-(\d{2})$/,
    'YYYY.MM.DD': /^(\d{4})\.(\d{2})\.(\d{2})$/,
    'YYYY/MM/DD': /^(\d{4})\/(\d{2})\/(\d{2})$/,
  };

  const match = dateString.match(formatRegex[format]);

  if (!match) {
    throw new Error(`Date string ${dateString} does not match format ${format}`);
  }

  let day, month, year;

  switch (format) {
    case 'DD-MM-YYYY':
    case 'DD.MM.YYYY':
    case 'DD/MM/YYYY':
      day = match[1];
      month = match[2];
      year = match[3];
      break;
    case 'MM-DD-YYYY':
    case 'MM.DD.YYYY':
    case 'MM/DD/YYYY':
      month = match[1];
      day = match[2];
      year = match[3];
      break;
    case 'YYYY-MM-DD':
    case 'YYYY.MM.DD':
    case 'YYYY/MM/DD':
      year = match[1];
      month = match[2];
      day = match[3];
      break;
    default:
      throw new Error(`Unsupported date format: ${format}`);
  }

  return new Date(`${year}-${month}-${day}`);
};

export const FilterButtonWithBadge = ({ filters, onClick, tr }) => {
  const filterCount = countFiltersWithValues(filters);

  return (
    <FilterIconWrapper>
      <StyledIconButton
        className="option-btn"
        onClick={onClick}
        reactIcon={<Funnel color="#4C818E" size={16} className="mr-3" />}
        name={tr('Filter')}
      />
      {filterCount > 0 && <Badge>{filterCount}</Badge>}
    </FilterIconWrapper>
  );
};

const defaultApplyFilter = (
  filters,
  data, // the data to be filtered
  onDataFiltered, // function to be called after the data has been filtered
  dateFormat = '', // user preferred date format, this is necessary for date filtering
) => {
  const initialData = data;

  const parseDateWithFormat = (dateStr) => {
    try {
      return parseDate(dateStr, dateFormat);
    } catch (error) {
      console.warn(`Error parsing date: ${dateStr}`, error);
      return null;
    }
  };

  const filterFunctions = {
    contains: (value, filterValue) =>
      value?.toLowerCase().includes(filterValue?.toLowerCase()),
    exact: (value, filterValue) =>
      value?.toLowerCase() === filterValue?.toLowerCase(),
    greater_than: (value, filterValue, columnType) =>
      columnType === 'date'
        ? parseDateWithFormat(value) > new Date(filterValue)
        : value > filterValue,
    less_than: (value, filterValue, columnType) =>
      columnType === 'date'
        ? parseDateWithFormat(value) < new Date(filterValue)
        : value < filterValue,
    equal: (value, filterValue) => value == filterValue,
    before: (value, filterValue) =>
      parseDateWithFormat(value) < new Date(filterValue),
    after: (value, filterValue) =>
      parseDateWithFormat(value) > new Date(filterValue),
    on: (value, filterValue) => {
      const parsedValue = parseDateWithFormat(value);
      const filterDate = new Date(filterValue);
      return (
        parsedValue &&
        filterDate &&
        parsedValue.toDateString() === filterDate.toDateString()
      );
    },
    range: (value, startValue, endValue, columnType) => {
      if (columnType === 'date') {
        const parsedValue = parseDateWithFormat(value);
        const startDate = new Date(startValue);
        const endDate = new Date(endValue);
        return (
          parsedValue &&
          startDate &&
          endDate &&
          parsedValue >= startDate &&
          parsedValue <= endDate
        );
      } else {
        const adjustedEndValue = endValue
          ? endValue.slice(0, -1) +
            String.fromCharCode(endValue.charCodeAt(endValue.length - 1) + 1)
          : undefined;
        return (
          value >= startValue && (adjustedEndValue ? value < adjustedEndValue : true)
        );
      }
    },
  };

  // Return all data if filters array is empty or invalid
  if (!Array.isArray(filters) || filters.length === 0) {
    onDataFiltered(initialData);
    return;
  }

  const filteredData = initialData.filter((item) => {
    return filters.every((filter) => {
      // If no condition is provided, consider it a match
      if (!filter.condition || !filterFunctions[filter.condition]) {
        console.warn(`Invalid or missing filter condition: ${filter.condition}`);
        return true;
      }

      let value = item[filter.column];
      const filterValue = filter.value;
      let startValue = filter.value;
      let endValue = filter.endValue;

      // If no value is provided for the filter, consider it a match
      if (
        filterValue === undefined &&
        startValue === undefined &&
        endValue === undefined &&
        (!filter.additionalValues ||
          (filter.additionalValues.start.length === 0 &&
            filter.additionalValues.end.length === 0))
      ) {
        return true;
      }

      // Function to check a single condition
      const checkCondition = (start, end) => {
        if (filter.condition === 'range') {
          return filterFunctions[filter.condition](
            value,
            start,
            end,
            filter.columnType,
          );
        } else {
          return (
            filterFunctions[filter.condition](value, start, filter.columnType) ||
            (end && filterFunctions[filter.condition](value, end, filter.columnType))
          );
        }
      };

      // Check primary filter condition
      try {
        if (checkCondition(startValue, endValue)) {
          return true;
        }
      } catch (error) {
        console.warn(`Error applying filter condition: ${filter.condition}`, error);
        return true; // Consider it a match if there's an error
      }

      // Check additional values
      if (filter.additionalValues && Array.isArray(filter.additionalValues.start)) {
        for (let i = 0; i < filter.additionalValues.start.length; i++) {
          const additionalStart = filter.additionalValues.start[i];
          const additionalEnd = filter.additionalValues.end[i];

          try {
            if (checkCondition(additionalStart, additionalEnd)) {
              return true;
            }
          } catch (error) {
            console.warn(
              `Error applying additional filter condition: ${filter.condition}`,
              error,
            );
            return true; // Consider it a match if there's an error
          }
        }
      }

      return false;
    });
  });

  onDataFiltered(filteredData);
};

const FilterModal = ({
  onClose,
  tr,
  filters,
  setFilters,
  columns,
  filterableColumns,
  data = [],
  onDataFiltered = () => {},
  applyFilter = defaultApplyFilter,
  dateFormat,
}) => {
  const filterEntries = (filterableColumns, columns) => {
    return columns
      .filter((column) =>
        filterableColumns.some((filterable) => filterable.columnId === column.id),
      )
      .map((column) => {
        const matchingFilterable = filterableColumns.find(
          (filterable) => filterable.columnId === column.id,
        );
        return {
          ...column,
          name: tr(column.label),
          value: column.label,
          ...(matchingFilterable && {
            columnType: matchingFilterable.columnDataType,
          }),
        };
      });
  };

  const getConditionOptions = (columnType) => {
    switch (columnType) {
      case 'text':
        return [
          { value: 'contains', name: tr('contains'), label: tr('contains') },
          { value: 'exact', name: tr('exact'), label: tr('exact') },
          { value: 'range', name: tr('range'), label: tr('range') },
        ];
      case 'number':
        return [
          {
            value: 'greater_than',
            label: tr('greater than'),
            name: tr('greater than'),
          },
          { value: 'less_than', label: tr('less than'), name: tr('less than') },
          { value: 'equal', label: tr('equal'), name: tr('equal') },
        ];
      case 'date':
        return [
          { value: 'before', label: tr('before'), name: tr('before') },
          { value: 'after', label: tr('after'), name: tr('after') },
          { value: 'on', label: tr('on'), name: tr('on') },
          { value: 'range', name: tr('range'), label: tr('range') },
        ];
      default:
        return [];
    }
  };

  const addFilter = () => {
    setFilters([
      ...filters,
      {
        id: Date.now(),
        column: '',
        condition: '',
        value: '',
        endValue: '',
        additionalValues: { start: [], end: [] },
      },
    ]);
  };

  const removeFilter = (id) => {
    setFilters(filters.filter((filter) => filter.id !== id));
  };

  const addValueField = (filterId) => {
    setFilters(
      filters.map((filter) =>
        filter.id === filterId
          ? {
              ...filter,
              additionalValues: {
                start: [...filter.additionalValues.start, ''],
                end: [...filter.additionalValues.end, ''],
              },
            }
          : filter,
      ),
    );
  };

  const removeValueField = (filterId) => {
    setFilters(
      filters.map((filter) =>
        filter.id === filterId
          ? {
              ...filter,
              additionalValues: {
                start: filter.additionalValues.start.slice(0, -1),
                end: filter.additionalValues.end.slice(0, -1),
              },
            }
          : filter,
      ),
    );
  };

  const handleFilterChange = (filterId, field, value, columnType) => {
    setFilters(
      filters.map((filter) =>
        filter.id === filterId ? { ...filter, [field]: value, columnType } : filter,
      ),
    );
  };

  const handleConditionChange = (
    filterId,
    conditionTranslation,
    conditionValue,
    columnType,
  ) => {
    // conditionValue will always be the english value of the condition like 'contains', 'exact' etc
    // conditionTranslation value is the conditionValue but translated based on the user language preference like german or english
    // this is to enable the dropdown show its values based on the user language preference without
    // without breaking the filtering condition
    setFilters(
      filters.map((filter) =>
        filter.id === filterId
          ? {
              ...filter,
              conditionTranslation: conditionTranslation, // Update with the translation of the condition
              condition: conditionValue, // Update the actual condition value
              columnType,
            }
          : filter,
      ),
    );
  };

  const handleMultipleValueChange = (filterId, index, value, type) => {
    setFilters(
      filters.map((filter) =>
        filter.id === filterId
          ? {
              ...filter,
              additionalValues: {
                ...filter.additionalValues,
                [type]: filter.additionalValues[type].map((v, i) =>
                  i === index ? value : v,
                ),
              },
            }
          : filter,
      ),
    );
  };

  const filteredColumns = filterEntries(filterableColumns, columns);

  return (
    <ModalOverlay>
      <ModalContent>
        <ModalHeader>
          <ModalTitle>{tr('Filter')}</ModalTitle>
          <CloseButton onClick={onClose}>
            <FaTimes />
          </CloseButton>
        </ModalHeader>
        <FilterGridContainer>
          <FilterGrid>
            <GridHeader>{tr('Select Column')}</GridHeader>
            <GridHeader>{tr('Select Condition')}</GridHeader>
            <GridHeader>{tr('Start Value')}</GridHeader>
            <GridHeader>{tr('End Value')}</GridHeader>
            <GridHeader>{tr('Actions')}</GridHeader>
            {filters.map((filter, index) => {
              const selectedColumn = filteredColumns.find(
                (col) => col.id === filter.column,
              );
              return (
                <GridRow key={filter.id}>
                  <GridCell>
                    <StyledSelect
                      value={filter.column}
                      onChange={(e) =>
                        handleFilterChange(
                          filter.id,
                          'column',
                          e.target.value,
                          selectedColumn?.columnType,
                        )
                      }
                      options={filteredColumns}
                      sort={true}
                    />
                  </GridCell>
                  <GridCell>
                    <StyledSelect
                      value={filter.conditionTranslation}
                      onChange={(e) => {
                        let opts = getConditionOptions(selectedColumn?.columnType);
                        let value = e.target.value;
                        let selectedOpt = opts.find((opt) => opt.name == value);
                        handleConditionChange(
                          filter.id, // The unique identifier for the filter
                          value, // The display value from the select dropdown (e.g., 'contains' in English, 'Enthält' in German)
                          selectedOpt?.value, // The actual condition to match in the code logic (e.g., 'contains')
                          selectedColumn?.columnType, // The type of the column being filtered (e.g., 'string', 'number')
                        );
                      }}
                      options={getConditionOptions(selectedColumn?.columnType)}
                      sort={false}
                    />
                  </GridCell>
                  <GridCell>
                    <ValueFieldsContainer>
                      <StyledInput
                        type={
                          selectedColumn?.columnType === 'date' ? 'date' : 'text'
                        }
                        placeholder={tr('Start Value')}
                        value={filter.value}
                        onChange={(e) =>
                          handleFilterChange(
                            filter.id,
                            'value',
                            e.target.value,
                            selectedColumn?.columnType,
                          )
                        }
                      />
                      {filter.additionalValues.start.map((value, valueIndex) => (
                        <StyledInput
                          key={valueIndex}
                          type={
                            selectedColumn?.columnType === 'date' ? 'date' : 'text'
                          }
                          placeholder={tr('Start Value')}
                          value={value}
                          onChange={(e) =>
                            handleMultipleValueChange(
                              filter.id,
                              valueIndex,
                              e.target.value,
                              'start',
                            )
                          }
                        />
                      ))}
                    </ValueFieldsContainer>
                  </GridCell>
                  <GridCell>
                    <ValueFieldsContainer>
                      {(filter.condition === 'range' ||
                        filter.condition === 'between') && (
                        <>
                          <StyledInput
                            type={
                              selectedColumn?.columnType === 'date' ? 'date' : 'text'
                            }
                            placeholder={tr('End Value')}
                            value={filter.endValue}
                            onChange={(e) =>
                              handleFilterChange(
                                filter.id,
                                'endValue',
                                e.target.value,
                                selectedColumn?.columnType,
                              )
                            }
                          />
                          {filter.additionalValues.end.map((value, valueIndex) => (
                            <StyledInput
                              key={valueIndex}
                              type={
                                selectedColumn?.columnType === 'date'
                                  ? 'date'
                                  : 'text'
                              }
                              placeholder={tr('End Value')}
                              value={value}
                              onChange={(e) =>
                                handleMultipleValueChange(
                                  filter.id,
                                  valueIndex,
                                  e.target.value,
                                  'end',
                                )
                              }
                            />
                          ))}
                        </>
                      )}
                    </ValueFieldsContainer>
                  </GridCell>
                  <GridCell>
                    <ActionButtonsContainer>
                      <Button onClick={() => addValueField(filter.id)}>
                        <FaPlus size={18} />
                      </Button>
                      {(filter.additionalValues.start.length > 0 || index > 0) && (
                        <Button
                          onClick={() => {
                            if (filter.additionalValues.start.length > 0) {
                              removeValueField(filter.id);
                            } else {
                              removeFilter(filter.id);
                            }
                          }}
                        >
                          <FaMinus size={18} />
                        </Button>
                      )}
                    </ActionButtonsContainer>
                  </GridCell>
                </GridRow>
              );
            })}
          </FilterGrid>
        </FilterGridContainer>
        <ButtonContainer>
          <StyledFilterButton name={tr('Add Row')} onClick={addFilter} />
          <StyledFilterButton
            name={tr('Clear Filter')}
            background={'#ddd'}
            color={'#000'}
            onClick={() => {
              applyFilter([], data, onDataFiltered, dateFormat);
              setFilters([
                {
                  id: Date.now(),
                  column: '',
                  condition: '',
                  value: '',
                  endValue: '',
                  additionalValues: { start: [], end: [] },
                },
              ]);
              onClose();
            }}
          />
          <StyledFilterButton
            name={tr('Apply Filter')}
            onClick={() => {
              applyFilter(filters, data, onDataFiltered, dateFormat);
              onClose();
            }}
          />
        </ButtonContainer>
      </ModalContent>
    </ModalOverlay>
  );
};

export default compose(withUserPreferences)(FilterModal);
