import React from "react";
import styled from "styled-components";
import { withTranslation } from "../../../hoc";
import BaseMultiSelect from "../../BaseMultiSelect";

const RadioButton = (props) => {
  const { 
    tr, 
    label, 
    name, 
    value, 
    onChange, 
    className, 
    required,
    checked,
  } = props;
  return (
    <label 
      className={`custom-control custom-radio custom-control-inline ${className}`}>
      <input 
        onChange={(e) => onChange({
          target: {
            name,
            value,
          }
        })}
        type="radio" 
        className="custom-control-input" 
        name={name} 
        checked={checked}
      />
      <span className="custom-control-label">
        {tr(label)}
        {required && (<span className="form-required">*</span>)}
      </span>
    </label>
  )
}

RadioButton.defaultProps = {
  name: null,
  label: "",
  className: "",
  required: false,
}


const StyledRadioButtonArray = styled.div`
  margin: 1rem 0;
`

class RadioButtonArray extends BaseMultiSelect { 
  render() {
    const { selected } = this.state;
    const { 
      tr, 
      label, 
      required, 
      selectableItems, 
      value,
      className,
      renderRadioButton,
      ...rest
    } = this.props;
    return (
      <StyledRadioButtonArray
        className={`form-group ${className}`}
      >
        {
          label && (
            <label className="form-label">
              {tr(label)}
              {required && (<span className="form-required">*</span>)}
            </label>
          )
        }
        <div className="custom-controls-stacked">
          {selectableItems.map(({label, value}) => (
            renderRadioButton({ 
              key: label,
              tr,
              onChange: this.onChange,
              label: label,
              value: value,
              checked: (selected === value),
              ...rest
            })
          ))}
        </div>
      </StyledRadioButtonArray>
    )
  }
}

RadioButtonArray.RadioButton = RadioButton;

RadioButtonArray.defaultProps = {
  renderRadioButton: (props) => <RadioButton {...props} />
}

export default withTranslation(RadioButtonArray);


